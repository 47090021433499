import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import "../styles/ClientDemandesPage.css";
import { Card, Container, Row, Col, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { FaEye } from "react-icons/fa";
import { API_GATEWAY_URL } from "../constants";
import ClientCard from "./ClientCard";
import LoadingSpinner from "./LoadingSpinner";
import Navbar from "./Navbarr";
import "../index.css";
import "../styles/Buttons.css";

const DemandeAttenteMatching = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [clientData, setClientData] = useState(null);
  const [demandes, setDemandes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDemande, setSelectedDemande] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchClientData = async () => {
      setLoading(true);
      try {
        const profileResponse = await fetch(
          `${API_GATEWAY_URL}/client-profile/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );

        if (!profileResponse.ok)
          throw new Error(
            "Erreur lors de la récupération des données du profil."
          );

        const profileData = await profileResponse.json();
        setClientData(profileData);

        const demandesResponse = await fetch(
          `${API_GATEWAY_URL}/client-demandes/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );

        if (!demandesResponse.ok)
          throw new Error("Erreur lors de la récupération des demandes.");

        const demandesData = await demandesResponse.json();
        setDemandes(demandesData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (user && user.access_token) {
      fetchClientData();
    }
  }, [user]);

  const handleDelete = async (demandeId) => {
    Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Vous ne pourrez pas annuler cette action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#AF1B21",
      cancelButtonColor: "#273655",
      confirmButtonText: "Confirmer",
      cancelButtonText: "Retour",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(
            `${API_GATEWAY_URL}/client-demande/${demandeId}/`,
            {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (!response.ok) {
            throw new Error("Failed to cancel demande");
          }

          setDemandes(demandes.filter((demande) => demande.id !== demandeId));

          Swal.fire("Annulé!", "Votre demande a été annulée.", "success");
        } catch (err) {
          setError(err.message);
          Swal.fire(
            "Erreur!",
            "Une erreur s'est produite lors de l'annulation de la demande.",
            "error"
          );
        }
      }
    });
  };

  const handleSelectResources = (demandeId) => {
    navigate(`/select-resources/${demandeId}`);
  };

  const handleViewDetails = (demande) => {
    setSelectedDemande(demande);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDemande(null);
  };

  const getStatusCircle = (statut) => {
    let color;
    switch (statut) {
      case "WC":
        color = "blue";
        break;
      case "RD":
        color = "blue";
        break;
      case "WM":
        color = "blue";
        break;
      case "C":
        color = "orange";
        break;
      case "WS":
        color = "orange";
        break;
      case "WP":
        color = "green";
        break;
      case "R":
        color = "green";
        break;
      case "WR":
        color = "red";
        break;
      default:
        color = "grey";
    }
    return (
      <div
        style={{
          width: "15px",
          height: "15px",
          borderRadius: "50%",
          backgroundColor: color,
          position: "absolute",
          top: "10px",
          right: "10px",
        }}
        title={statut}
      />
    );
  };

  const renderDemandes = (demandes, statut) => {
    return demandes
      .filter((demande) => demande.statut === statut)
      .map((demande) => (
        <div
          key={demande.id}
          className="MediumShadowContainer"
          style={{ position: "relative" }}
        >
          {getStatusCircle(demande.statut)}
          <Card.Body>
            <div className="text-fluid-xs font-body text-primary-800">
              <FaEye
                className="fa-eye-icon text-fluid-sm"
                onClick={() => handleViewDetails(demande)}
              />
              <Card.Title>
                <strong>Poste demandé :</strong> {demande.posteTitle}
              </Card.Title>
              <Card.Text>
                <strong>Type de Projet:</strong> {demande.project_type_readable}
                <br />
                <strong>Niveau d'Expertise:</strong>{" "}
                {demande.expertise_level_readable}
                <br />
                <strong>Statut:</strong> {demande.statut_readable}
                <br />
                <strong>Date de Début:</strong> {demande.date_debut}
                <br />
                <strong>Date de Fin:</strong> {demande.date_fin}
              </Card.Text>
              <div
                className={`button-container ${
                  demande.statut === "WS"
                    ? "with-select-resources"
                    : demande.statut !== "WC"
                    ? "without-modify"
                    : ""
                }`}
              >
                <button
                  className="btn-red"
                  onClick={() => handleDelete(demande.id)}
                >
                  {demande.statut === "WC"
                    ? "Supprimer"
                    : "Annuler la demande "}
                </button>
                {demande.statut === "WS" && (
                  <button
                    className="btn-blue"
                    onClick={() => handleSelectResources(demande.id)}
                  >
                    Sélectionner une Ressource
                  </button>
                )}
              </div>
            </div>
          </Card.Body>
        </div>
      ));
  };

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  return (
    <div className="pw-page-layout">
      <Navbar />
      <div className="w-full flex flex-col p-4">
        <ClientCard />

        <div className="flex flex-col justify-center items-center ">
          <div>
            <h2 className="max-sm:ml-12 sm:ml-12  mt-4 font-display font-bold text-primary-800 max-2xl:text-fluid-md 2xl:text-fluid-lg">
              Mes Demandes en attente de matching
            </h2>
            <Row>
              <Col className="CDPdemandes-column">
                <div className="CDPdemandes-cards-container">
                  {renderDemandes(demandes, "WM")}
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <h className="font-bold text-fluid-sm text-primary-800 font-display">
              Détails de la demande
            </h>
          </Modal.Header>
          <Modal.Body className="text-primary-800 font-body text-fluid-xs">
            {selectedDemande && (
              <>
                <p className="max-2xl:text-fluid-ms 2xl:text-fluid-sm">
                  <strong>Poste demandé:</strong>
                  {selectedDemande.posteTitle}
                </p>
                <p>
                  <strong>Type de Projet:</strong>{" "}
                  {selectedDemande.project_type_readable}
                </p>
                <p>
                  <strong>Niveau d'Expertise:</strong>{" "}
                  {selectedDemande.expertise_level_readable}
                </p>
                <p>
                  <strong>Statut:</strong> {selectedDemande.statut_readable}
                </p>
                <p>
                  <strong>Date de Début:</strong> {selectedDemande.date_debut}
                </p>
                <p>
                  <strong>Date de Fin:</strong> {selectedDemande.date_fin}
                </p>
                <p>
                  <strong>Description:</strong> {selectedDemande.description}
                </p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              variant="secondary"
              className="SignUpButton"
              onClick={handleCloseModal}
            >
              Fermer
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default DemandeAttenteMatching;
