import React, { useState, useEffect } from "react";
import { API_GATEWAY_URL } from "../../constants";
import ClientCard from "../ClientCard";
import Navbarr from "../Navbarr";
import { useAuth } from "../AuthContext";
import {useNavigate} from 'react-router-dom';
import LoadingSpinner from "../LoadingSpinner";
import {ReactComponent as DownloadIcon} from '../../assets/paiement/download.svg';
import {ReactComponent as ArrowIcon} from '../../assets/paiement/Double Arrow Right.svg';

const TransactionsTable = () => {
  const [transactions, setTransactions] = useState([]);
  const [clientData, setClientData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!user) {
        console.log("User is not authenticated.");
        return;
      }

      setLoading(true); // Start loading
      try {
        const profileResponse = await fetch(
          `${API_GATEWAY_URL}/client-profile/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );

        if (!profileResponse.ok) throw new Error("Erreur lors de la récupération des données du profil.");

        const profileData = await profileResponse.json();
        setClientData(profileData);

        const user_id = profileData.user;

        const transactionsResponse = await fetch(
          `${API_GATEWAY_URL}/get-user-transactions/?user=${user_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );

        if (!transactionsResponse.ok) throw new Error("Erreur lors de la récupération des transactions.");

        const transactionsData = await transactionsResponse.json();
        setTransactions(transactionsData);
      } catch (err) {
        console.error('Erreur:', err);
        setError(err.message); // Update error state
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchData();
  }, [user]);

  const handleClickSuivi = async (paymentIntentId) => {
    console.log('paymentIntentId:', paymentIntentId);
    navigate('/suivi', { state: { paymentIntentId } });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const transactionsPerPage = 10;

  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  const handleNextPage = () => {
    if (indexOfLastTransaction < transactions.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <div className="pw-page-layout font-body max-2xl:text-fluid-xs 2xl:text-fluid-sm text-primary-800">
      <Navbarr title={'Ajouter'} />
      <div className="flex flex-col w-full">
        <ClientCard />
        {error && <div className="text-red-500">{error}</div>}
        
        {loading ? ( // Show spinner while loading
          <div className="flex flex-col items-center justify-center p-4">
            <LoadingSpinner isLoading={loading}/>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center p-4">
            <div className="w-full max-w-screen-xl lg:px-10">
              <h3 className="font-semibold text-gray-800 text-md font-Poppins">Historique des transactions</h3>
              <table className="w-full bg-white border border-[#a0a0a0] rounded mt-10">
                <thead>
                  <tr className="text-sm font-medium leading-normal text-gray-600 bg-gray-100 font-Poppins">
                    <th className="px-6 py-3 text-left">Référence</th>
                    <th className="px-6 py-3 text-left">Ressource</th>
                    <th className="px-6 py-3 text-left">Prix (€)</th>
                    <th className="hidden px-6 py-3 text-left md:table-cell">Date</th>
                    <th className="hidden px-6 py-3 text-left md:table-cell">Type</th>
                    <th className="px-6 py-3 text-left">État</th>
                    <th className="hidden px-6 py-3 text-left md:table-cell">Facture</th>
                  </tr>
                </thead>
                <tbody className="text-sm font-light text-gray-600 font-Poppins">
                  {currentTransactions.length > 0 ? (
                    currentTransactions.map((transaction, index) => (
                      <tr
                        key={transaction.id}
                        className="border-b border-gray-200 hover:bg-gray-100"
                      >
                        <td className="px-6 py-3 text-left">{index + 1 + indexOfFirstTransaction}</td>
                        <td className="px-6 py-3 text-left">Ressource</td>
                        <td className="px-6 py-3 text-left">{transaction.amount}</td>
                        <td className="hidden px-6 py-3 text-left md:table-cell">{transaction.created_at.slice(0, 10)}</td>
                        <td className="hidden px-6 py-3 text-left md:table-cell">{transaction.type.slice(2, 6)}</td>
                        <td className="px-6 py-3 text-left">
                          <span >
                            {transaction.status === "succeeded"
                              ? "Succes"
                              : transaction.status === "failed"
                                ? "Echec"
                                : "En cours"}
                          </span>
                        </td>
                        <td className="justify-between hidden px-6 py-3 text-left sm:flex">
                          <div className="relative group ml-2 text-gray-600 hover:text-gray-900 w-[20px] cursor-pointer">
                            <DownloadIcon fill="currentColor" className="text-gray-600 group-hover:text-black" />
                            <span className="absolute hidden px-2 py-1 mb-2 text-xs text-white transform -translate-x-1/2 bg-gray-800 rounded bottom-full left-1/2 group-hover:block">Télécharger</span>
                          </div>
                          <div className="relative group ml-2 text-gray-600 hover:text-gray-900 w-[24px] cursor-pointer" onClick={() => handleClickSuivi(transaction.paymentIntentId)}>
                            <ArrowIcon fill="currentColor" className="text-gray-600 group-hover:text-black" />
                            <span className="absolute hidden px-2 py-1 mb-2 text-xs text-white transform -translate-x-1/2 bg-gray-800 rounded bottom-full left-1/2 group-hover:block">Détails</span>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="px-6 py-3 text-center">Aucune transaction trouvée.</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="flex justify-between mt-4">
                <button
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  className="px-4 py-2 text-white bg-blue-500 rounded disabled:opacity-50"
                >
                  Previous
                </button>
                <button
                  onClick={handleNextPage}
                  disabled={indexOfLastTransaction >= transactions.length}
                  className="px-4 py-2 text-white bg-blue-500 rounded disabled:opacity-50"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}


export default TransactionsTable;