import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_GATEWAY_URL } from "../../constants";
import { useAuth } from "../AuthContext";

export const SuccessScreen = (props) => {
  const [personalInfo, setPersonalInfo] = useState(props.personalInfo);
  const [amount, setAmount] = useState(props.amount);
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleClickSuivi = async () => {
    // rediriger l'utilisateur vers la page de suivi en passant le props.paymentIntentId avec lui
    console.log('paymentIntentId:', props.paymentIntentId);
    navigate('/suivi', { state: { paymentIntentId: props.paymentIntentId } });
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleClick = async () => {
    const currentDate = formatDate(new Date());
  
    try {
      // Récupérer le dernier numéro de facture dans le stockage local
      let lastInvoiceNumber = localStorage.getItem('lastInvoiceNumber');
      lastInvoiceNumber = lastInvoiceNumber ? parseInt(lastInvoiceNumber, 10) : 0;
  
      // Incrémenter le numéro de facture
      const newInvoiceNumber = lastInvoiceNumber + 1;
  
      // Enregistrer le nouveau numéro de facture dans le stockage local
      localStorage.setItem('lastInvoiceNumber', newInvoiceNumber);
  
      const queryParams = new URLSearchParams({
        entreprise_nom: "Nom de l'entreprise",
        entreprise_adresse: "Adresse de l'entreprise",
        entreprise_ville_code_postal: "Ville et code postal",
        entreprise_email: "email@exemple.com",
        entreprise_telephone: "0123456789",
        entreprise_numero_tva: "TVA123456",
        client_nom: personalInfo.fullName,
        client_adresse: personalInfo.address,
        client_ville_code_postal: "Ville et code postal",
        client_numero_tva: personalInfo.tva,
        client_telephone: personalInfo.phone,
        facture_numero: newInvoiceNumber.toString(),
        facture_date: currentDate,
        facture_date_echeance: currentDate,
        service1_description: "Description du service 1",
        service1_montant: "100",
        service2_description: "Description du service 2",
        service2_montant: "200",
        total: amount,
        template_name: "factureEU.html"
      }).toString();
  
      const response = await fetch(
        `${API_GATEWAY_URL}/pdf/?${queryParams}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
  
      // Vérifiez si la réponse est OK
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération du PDF: ' + response.statusText);
      }
  
      // Vérifiez le type de contenu
      console.log('Content-Type:', response.headers.get('Content-Type'));
  
      // Convertir la réponse en Blob
      const blob = await response.blob();
      console.log('Blob Size:', blob.size); // Vérifiez la taille du blob
  
      // Ouvrir le PDF dans un nouvel onglet pour le tester
      const url = window.URL.createObjectURL(blob);
      window.open(url); // Ouvre le PDF dans un nouvel onglet
  
      // Télécharger le PDF
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'facture.pdf'); // Nom du fichier à télécharger
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url); // Nettoyage de l'URL blob
  
      console.log('PDF téléchargé avec succès');
    } catch (error) {
      console.error('Erreur lors du téléchargement du PDF:', error);
    }
  };
  

  return (
    <div className="h-full">
      <div className="flex flex-col h-[50%] items-center justify-between pb-10">
        <div className="flex flex-col items-center justify-center gap-4">
          <img src={require('../../assets/paiement/shieldIcon.svg').default} alt="Success" />
          <div className="text-xl font-normal leading-9 text-center text-black font-Poppins">
            Opération terminée avec succès
          </div></div>
        <div className="flex flex-col items-center justify-center gap-8">
          <img src={require('../../assets/paiement/Vector 2.svg').default} alt="line" />
          {props.method === "sepa" ? (

            <div className="w-full sm:w-[60%] px-10 py-8 bg-white rounded border border-[#0056b3] justify-between items-center inline-flex" onClick={handleClickSuivi}>
              <div className="grow shrink basis-0 text-[#1e1e1e] text-md font-medium font-Poppins ">
                Suivre l’état de votre Virement SEPA
              </div>
              <img src={require('../../assets/paiement/Arrow Right.svg').default} alt="Arrow right" />
            </div>
          ) : (
            <div className="space-y-8 w-full sm:w-[60%] flex flex-col" >
              <div className=" px-10 py-8 bg-white rounded border border-[#0056b3] justify-between items-center inline-flex" onClick={handleClick}>
                <div className="grow shrink basis-0 text-[#1e1e1e] text-md font-medium font-Poppins cursor-pointer">
                  Consulter ou télécharger la facture
                </div>
                <img
                  src={require('../../assets/paiement/download.svg').default}
                  alt="download"
                  style={{ width: '20px', height: '20px', color: '#0056b3' }}
                />

              </div>
              <div className="px-10 py-8 bg-white rounded border border-[#0056b3] justify-between items-center inline-flex">
                <div className="grow shrink basis-0 text-[#1e1e1e] text-md font-medium font-Poppins ">
                  Envoyer facture par e-mail
                </div>
                <img src={require('../../assets/paiement/Paper Plane.svg').default} alt="send" style={{ width: '20px', height: '20px', color: '#0056b3' }} />
              </div></div>
          )}
        </div>
      </div>
    </div>
  );
};
