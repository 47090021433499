import Layout from "../components/Layout";
import { useAuth } from "./AuthContext";
import illustration from "../assets/illustration.png";
import HeroSection from "./HeroSectionClient";
import ClientSecondSection from "./ClientSecondSection";
import ClientThirdSection from "./ClientThirdSection";
import FAQClient from "./FAQClient";
import FeedbackClient from "./FeedbackClient";
import FAQClient2 from "./FAQClient2";
import { Link } from "react-router-dom";

const WelcomeComponent = () => {
  const { user } = useAuth();
  const url = "https://test.teratalent.com";
  console.log("User:", user);

  return (
    <div className="BluelandingImage">
      <Layout title="TeraTalenT" content="Accueil">
        {/* Test button to toggle between the two landing pages*/}
        <Link to="/businesspartner-welcome">
          <button className="absolute bg-red-300 mt-32 right-5 w-16 h-16 rounded-full">
            Test
          </button>
        </Link>
        <section id="acceuil" >
          <HeroSection />
        </section>
        <section id="services">
          <ClientSecondSection />
        </section>
        <section id="">
          <ClientThirdSection />
        </section>
        <section id="apropos">
          <FAQClient />
        </section>
        <section id="feedback">
          {" "}
          <FeedbackClient />
        </section>
        <section id="faq">
          <FAQClient2 />
        </section>
      </Layout>
    </div>
  );
};

export default WelcomeComponent;
