import React from 'react';
import PropTypes from 'prop-types';
import  icon1 from '../icons/icon1.png'
import  icon2 from '../icons/icon2.png'
import  icon3 from '../icons/icon3.png'
import '../index.css';


// FeatureCard Component
const FeatureCard = ({ imageSrc, title, description }) => {
  return (
    <section className="RedLandingcard flex flex-col text-center grow w-full max-md:mt-4">
      <img loading="lazy" src={imageSrc} alt="" className="object-contain self-center aspect-[1.21] w-[70px]" />
      <h2 className="font-display mt-2.5 text-fluid-md 2xl:text-fluid-lg font-bold max-md:mr-1 ">{title}</h2>
      <p className="font-body  text-primary-800 max-md:max-w-full  text-fluid-sm mt-4">{description}</p>
    </section>
  );
};

// Define prop types for FeatureCard
FeatureCard.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

// FeatureSection Component
const features = [
  {
    imageSrc: icon1,
    title: "Adaptez la taille de vos équipes selon vos besoins",
    description: "Renforcez vos effectifs en périodes de pic d’activités et réduisez-les pour optimiser vos coûts, tout en maintenant performance et qualité."
  },
  {
    imageSrc: icon2,
    title: "Elargissez la palette des compétences de vos équipes",
    description: "Intégrez des consultants qualifiés pour compléter les compétences de vos équipes existantes afin de mieux satisfaire les attentes de vos clients."
  },
  {
    imageSrc: icon3,
    title: "Optez pour l'outsourcing pour les activités non-core",
    description: "Externalisez des fonctions annexes pour mieux vous concentrer sur les activités stratégiques en réalisant des économies substantielles."
  }
];

const ServicesPartner = () => {
  return (
    <div  className='flex flex-col  bg-white items-center justify-center max-2xl:py-6 py-24'>
    <div id="Apropos" className= 'text-center '>
    <h2 className="font-display text-secondary-500 font-bold text-fluid-xlg 2xl:text-fluid-xl">
    Pourquoi TeraTalenT
    </h2> 
    <p className='font-body text-secondary-500 max-md:max-w-full  text-fluid-sm 2xl:text-fluid-md  mt-4'>
    TeraTalenT simplifie l’accès aux meilleurs talents algériens pour concrétiser vos projets avec facilité
    </p>
    </div>
    <div className='flex lg:flex-row  px-16 max-sm:px-4 sm:px-32'>
    <main className="w-full max-md:mt-10 max-md:max-w-full">
      <div className="flex gap-3 max-md:flex-col">
        {features.map((feature, index) => (
          <div key={index} className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
            <FeatureCard
              imageSrc={feature.imageSrc}
              title={feature.title}
              description={feature.description}
            />
          </div>
        ))}
      </div>
    </main>
    </div>
    </div>
  );
};

export default ServicesPartner;
