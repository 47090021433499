import { styles } from "../assets/styles";
import { Link } from "react-router-dom";
import { useAuth } from "./AuthContext";
import illustration from "../assets/illustration.png";
import { useEffect, useState } from "react";
import "../styles/Buttons.css";

const Text1 = "Votre Partenaire IT Nearshore pour";
const subtitles = [
  "  des prestations à la demande",
  " de nouvelles compétences",
  " un budget maîtrisé",
];

const Text5 = (
  <p>
    Nous sélectionnons pour vous les meilleurs talents IT algériens, en tirant
    parti de la proximité culturelle et linguistique, avec une économie pouvant
    atteindre plus de <span className="font-bold text-lg 2xl:text-fluid-lg ">50%</span>.
  </p>
);

const HeroSection = () => {
  const { user } = useAuth();
  const [currentSubtitle, setCurrentSubtitle] = useState("• ");
  const [subtitleIndex, setSubtitleIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    const currentText = subtitles[subtitleIndex];
    let charIndex = isTyping ? 0 : currentText.length;

    const interval = setInterval(() => {
      if (isTyping) {
        // Typing effect
        if (charIndex < currentText.length) {
          setCurrentSubtitle((prev) => prev + currentText.charAt(charIndex));
          charIndex++;
        } else {
          setIsTyping(false);
          clearInterval(interval);
        }
      } else {
        // Erasing effect
        if (charIndex > 0) {
          setCurrentSubtitle((prev) => prev.slice(0, -1));
          charIndex--;
        } else {
          clearInterval(interval);
          setCurrentSubtitle("• "); // Keep the point
          setSubtitleIndex((prev) => (prev + 1) % subtitles.length); // Move to the next subtitle
          setIsTyping(true); // Start typing again
        }
      }
    }, isTyping ? 100 : 50); // Typing and erasing speeds

    return () => clearInterval(interval);
  }, [subtitleIndex, isTyping]);

  return (
    <div className="flex flex-wrap max-sm:px-3 sm:px-32 max-xl:pt-14 xl:pt-14 2xl:pt-48 2xl:pb-20 items-center">
      <div className="xl:w-1/2 space-y-2 max-xl:text-center">
        {user && <div className="h-10"></div>}
        <h1 className="animate-slidein300 lg:mb-5 font-display text-white font-extrabold 2xl:text-fluid-xxl">
          {Text1}
        </h1>

        <p className="animate-slidein400 flex flex-col font-body text-white font-bold text-fluid-md 2xl:text-fluid-xlg fixed-height ">
          {currentSubtitle}
        </p>

        <p className="animate-slidein400 flex flex-col font-body text-white text-fluid-sm 2xl:text-fluid-md">
          {Text5}
        </p>

        {!user && (
          <Link
            to="/type_de_profile"
            className="animate-slidein500 register-button btn-red text-fluid-lg my-4 btn-sans-serif"
          >
            Rejoignez-nous
          </Link>
        )}

        {user && <div className="lg:h-20"></div>}
      </div>

      <div className="animate-slidein500 xl:w-1/2 pt-5 max-sm:hidden w-full">
        <img
          src={illustration}
          style={styles.welcomeImage}
          alt="illustration"
          className="animate-float object-contain lg:w-[100%] aspect-[1.96] min-h-[540px] max-md:mt-6 max-md:max-w-full"
        />
      </div>
    </div>
  );
};

export default HeroSection;
