import React, { useState } from 'react';
import { useAuth } from '../AuthContext';
import { API_GATEWAY_URL } from '../../constants';
import { useEffect } from 'react';


export const PersonalForm= (props)=> {
    
        // State for input fields
        const [fullName, setFullName] = useState('');
        const [companyName, setCompanyName] = useState('');
        const [address, setAddress] = useState('');
        const [phone, setPhone] = useState('');
        const [tva, setTva] = useState('');
        const [rememberMe, setRememberMe] = useState(false);
    
        // State for errors
        const [errors, setErrors] = useState({
            fullName: '',
            companyName: '',
            address: '',
            phone: '',
            tva: '',
        });
    
        const [userId, setUserId] = useState('');
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState(null);
        const { user } = useAuth();
    
        // Regular expression to allow only numbers and optionally a leading '+'
        const phoneRegex = /^\+?\d*$/;
    
        // Sync props.personalInfo with the state when props change
        useEffect(() => {
            if (props.personalInfo) {
                setFullName(props.personalInfo.fullName || '');
                setCompanyName(props.personalInfo.companyName || '');
                setAddress(props.personalInfo.address || '');
                setPhone(props.personalInfo.phone || '');
                setTva(props.personalInfo.tva || '');
                setRememberMe(props.personalInfo.rememberMe || false);
            }            
               
        }, [props.personalInfo]);

        useEffect(() => {
            setUserId(props.user);
        }, [props.user]);
        

    // Handler for form submission
    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission behavior

        // Validate inputs before submission
        if (!validateForm()) {
            return; // If validation fails, do not submit
        }
        props.onNext(
            {
            'personalInfo': {
            fullName,
            companyName,
            address,
            phone,
            tva,
            rememberMe
        }})
        // If validation passes, proceed with form submission
    };

    // Function to validate the form
    const validateForm = () => {
        let valid = true;

        // Validate fullName
        if (fullName.trim() === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fullName: 'Le nom complet est requis'
            }));
            valid = false;
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fullName: ''
            }));
        }

        // Validate companyName (optional)

        // Validate address
        if (address.trim() === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                address: 'L\'adresse est requise'
            }));
            valid = false;
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                address: ''
            }));
        }
        if (tva.trim() === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                tva: 'Le numéro de TVA est requis'
            }));
            valid = false;
        }
        // Validate phone
        if (phone.trim() === '' || !phoneRegex.test(phone)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: 'Numéro de téléphone invalide'
            }));
            valid = false;
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: ''
            }));
        }

        return valid;
    };


    // handleClick for "Remember me"
    const handleClick = async () => {
        console.log("handleClick called");
        console.log('user', userId);
        if (!userId) return; 

        setLoading(true); // Start loading
        try {
            const response = await fetch(`${API_GATEWAY_URL}/user-information/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user.access_token}`,
                },
                body: JSON.stringify({
                    user: userId,
                    nom: fullName,
                    entreprise: companyName,
                    adresse: address,
                    telephone: phone,  
                    tva: tva,
                }),
            });
            console.log(response);  
            if (!response.ok) throw new Error("Erreur lors de l'enregistrement des informations utilisateur.");
        } catch (err) {
            console.error('Erreur:', err);
            setError(err.message);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <div className="bg-white sm:pr-8 sm:flex-col sm:flex sm:h-full">
            
            <form onSubmit={handleSubmit} className="flex flex-col justify-between h-full">
            <div>
            <div className="text-[#1e1e1e] text-lg font-bold font-Poppins mt-1">
                Informations de paiement
            </div>
            <div className="text-[#1e1e1e] text-xs font-normal font-Poppins mb-3">
                Veuillez saisir vos informations pour générer votre facture
            </div>
                <div>
                    <div className="mb-2">
                        <label className="block text-[#1e1e1e] text-sm font-medium font-Poppins mb-2">
                            Nom complet
                        </label>
                        <input
                            type="text"
                            className={`w-full border rounded-md px-3 py-2 hover:border-[#0056b3] focus:outline-[#0056b3] ${
                                errors.fullName && 'border-red-500'
                            }`}
                            placeholder="Nom complet"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                        />
                        {errors.fullName && (
                            <p className="mt-1 text-xs text-red-500">{errors.fullName}</p>
                        )}
                    </div>
                    <div className="mb-4">
                        <label className="block text-[#1e1e1e] text-sm font-medium font-Poppins mb-2">
                            Nom de l’entreprise
                        </label>
                        <input
                            type="text"
                            className="w-full border rounded-md px-3 py-2 hover:border-[#0056b3] focus:outline-[#0056b3]"
                            placeholder="Nom de l’entreprise"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
                        {/* Error message for companyName if needed */}
                    </div>
                    <div className="mb-4">
                        <label className="block text-[#1e1e1e] text-sm font-medium font-Poppins mb-2">
                            Adresse
                        </label>
                        <input
                            type="text"
                            className={`w-full border rounded-md px-3 py-2 hover:border-[#0056b3] focus:outline-[#0056b3] ${
                                errors.address && 'border-red-500'
                            }`}
                            placeholder="Entrez l'adresse"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                        {errors.address && (
                            <p className="mt-1 text-xs text-red-500">{errors.address}</p>
                        )}
                    </div>
                    <div className="flex items-center justify-center w-full mb-4 space-x-2 fl-row sm:space-x-8">
                    <div className='w-full'>
                        <label className="block text-[#1e1e1e] text-sm font-medium font-Poppins mb-2">
                            Numéro de téléphone
                        </label>
                        <input
                            type="text"
                            className={`w-full border rounded-md px-3 py-2 hover:border-[#0056b3] focus:outline-[#0056b3] ${
                                errors.phone && 'border-red-500'
                            }`}
                            placeholder="Ex : +213 568920201"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        {errors.phone && (
                            <p className="mt-1 text-xs text-red-500">{errors.phone}</p>
                        )}
                        </div>
                        <div className='w-full'>
                        <label className="block text-[#1e1e1e] text-sm font-medium font-Poppins mb-2">
                            Numéro de TVA
                        </label>
                        <input
                            type="text"
                            className={`w-full border rounded-md px-3 py-2 hover:border-[#0056b3] focus:outline-[#0056b3] ${
                                errors.tva && 'border-red-500'
                            }`}
                            placeholder="Numéro de TVA"
                            value={tva}
                            onChange={(e) => setTva(e.target.value)}
                        />
                        </div>
                    </div>
                    <div className="inline-flex items-center justify-start h-6 gap-2">
                        <input
                            type="checkbox"
                            className="leading-tight cursor-pointer"
                            checked={rememberMe}
                            onChange={(e) => {
                                setRememberMe(e.target.checked);
                                handleClick(); 
                            }}
                            disabled={
                                props.personalInfo && 
                                (props.personalInfo.fullName || 
                                 props.personalInfo.companyName || 
                                 props.personalInfo.address || 
                                 props.personalInfo.phone || 
                                 props.personalInfo.tva)
                            }  
                        />
                        <div className="text-[#1e1e1e] text-xs font-normal font-Poppins">
                            Se souvenir de moi
                        </div>
                    </div>
                </div>
                </div>
                <div className="flex justify-end">
                    <button
                        type="submit"
                        className="hover:bg-[#4179B5] w-40 h-10 px-5 py-2 bg-[#0056b3] rounded-lg text-white font-medium font-Poppins text-sm"
                    >
                        Continuer
                    </button>
                </div>
            </form>
        </div>
    );
}

export default PersonalForm;