import React from 'react';

function SuccessPage() {
    return (
        <div>
            <h1>Votre compte a été vérifié avec succès !</h1>
            <p>Votre email a été vérifié. Vous pouvez maintenant utiliser toutes les fonctionnalités de notre site.</p>
            <a href="/">Retour à la page d'accueil</a>
        </div>
    );
}

export default SuccessPage;
