import React from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const Job = ({jobTitle, dateDebut, dateFin, company, responsibilities, description}) => {
    return (
        <div className='flex flex-col'>
            <div>
                <div className="flex justify-between items-center">
                    <h3
                        style={{
                            fontFamily: 'var(--ff-heading)',
                            fontSize: 'var(--fs-h5)',
                            fontWeight: 'var(--fw-semibold)',
                            textTransform: 'capitalize',
                            color: 'var(--clr-primary-500)'
                        }}
                    >
                        {jobTitle}
                    </h3>
                    <p>
                        <div
                            className='flex items-center'
                            style={{
                                fontFamily: 'var(--ff-body)',
                                fontSize: '1rem',
                                fontWeight: 'var(--fw-medium)',                        
                            }}
                        >
                            {dateDebut} <span> <ArrowRightIcon style={{color: 'var(--clr-primary-500)'}}/> </span> {dateFin}
                        </div>
                    </p>
                </div>
                <p 
                    style={{
                        fontFamily: 'var(--ff-body)',
                        fontSize: '1rem',
                        fontWeight: 'var(--fw-medium)',
                        textTransform: 'uppercase',
                    }}
                >
                    {company}
                </p>
            </div>
            <div className='pl-4'>
            {
                responsibilities && (
                    <ul 
                        className="flex flex-col"
                        style={{ listStyleType: 'disc', paddingLeft: '20px' }}
                    >
                        {
                            responsibilities.map((responsibility, index) => (
                                <li 
                                    key={index}
                                    style={{
                                        fontFamily: 'var(--ff-body)',
                                        fontSize: '1rem',
                                        fontWeight: 'var(--fw-regular)',
                                    }}
                                >
                                    {responsibility}
                                </li>
                            ))
                        }
                    </ul>
                )
            }
            {
                description && (
                    <p
                        style={{
                            fontFamily: 'var(--ff-body)',
                            fontSize: '1rem',
                            fontWeight: 'var(--fw-regular)',
                        }}
                    >
                        {description}
                    </p>
                )
            }
            </div>
        </div>
    )
}

export default Job;