///////// MultiStepForm.js

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Alert } from "react-bootstrap";
import {
  setFreelancerInscriptionFormData,
  freelancerNextStep,
  freelancerPrevStep,
  resetFreelancerInscriptionFormData,
  setFreelancerStep,
  updateExpertiseLevel,
  addExperienceProfessionnel,
  removeExperienceProfessionnel,
  updateExperienceProfessionnel,
  setFreelancerInscriptionFormDataWithCustomValues,
  setDescription,
} from "../../../redux/slices/freelancerInscriptionFormSlice";
import "../../../styles/FreelancerMultiStepForm.css";
import "../../../index.css";
import "../../../styles/Buttons.css";
// import 'select2';
import Select from "react-select";
import "select2/dist/css/select2.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  FaPlus,
  FaTimes,
  FaSearch,
  FaCheckCircle,
  FaCircle,
  FaTrash,
  FaLinkedin,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import { BiUpload, BiPencil } from "react-icons/bi";
import { SlArrowRight } from "react-icons/sl";

// import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
import $ from "jquery";
// import 'bootstrap-datepicker';

import { format, set } from "date-fns";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector"; // Importer directement les composants nécessaires
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker"; // Importation du composant DatePicker
import "react-datepicker/dist/react-datepicker.css"; // Importation des styles pour DatePicker
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { fr } from "date-fns/locale/fr";
import langues from "../../../data/langues";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import logo from "../../../assets/logoWhite.png";
import countryList from "country-list";
import { API_GATEWAY_URL } from "../../../constants";
import { useLocation } from "react-router-dom";

//-------------------------------------------------------------------------

//-------------------------------------------------------------------------

registerLocale("fr", fr);

// const  langues = [
//   "Arabe",
//   "Français",
//   "Anglais",
//   "Espagnol",
//   "Allemand",

const langs = [
  { code: "AR", label: "Arabe" },
  { code: "FR", label: "Français" },
  { code: "AN", label: "Anglais" },
  { code: "ES", label: "Espagnol" },
  { code: "AL", label: "Allemand" },
  { code: "IT", label: "Italien" },
  { code: "PO", label: "Portugais" },
  { code: "RU", label: "Russe" },
  { code: "CH", label: "Chinois" },
  { code: "JA", label: "Japonais" },
];

// ];
const languesLevels = [
  { code: 1, label: "Élémentaire" },
  { code: 2, label: "Limité professionnel" },
  { code: 3, label: "Professionnel complet" },
  { code: 4, label: "Professionnel natif ou bilingue" },
];

const niveau_freelance = [
  { code: "NONE", label: "Je n'ai jamais réalisé de mission en freelance." },
  { code: "MANY", label: "J'ai déjà réalisé plusieurs missions en freelance." },
  {
    code: "FULL",
    label: "J'ai l'habitude de réaliser des missions en freelance.",
  },
];

const languageOptions = langs.map((lang) => ({
  value: lang.code,
  label: lang.label,
}));

const niveauFreelanceArray = niveau_freelance.map((option) => ({
  value: option.code,
  label: option.label,
}));

const FreelancerMultiStepForm = () => {
  const dispatch = useDispatch();
  const { freelancerStep, freelancerInscriptionFormData } = useSelector(
    (state) => state.freelancerInscriptionForm
  );
  const ExtractedSelectedExpertises = useSelector(
    (state) =>
      state.freelancerInscriptionForm.freelancerInscriptionFormData
        .selectedExpertises
  );
  const totalSteps = 6; // Nombre total d'étapes dans votre formulaire
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedExpertises, setSelectedExpertises] = useState(
    ExtractedSelectedExpertises
  );
  const [apiExpertises, setApiExpertises] = useState([]); // Pour stocker les expertises de l'API
  const [expertises, setExpertises] = useState([]);
  const [birthdayDate, setBirthdayDate] = useState("");

  const levelsOptions = languesLevels.map((level) => ({
    value: level.code,
    label: level.label,
  }));
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [apiSearchExecuted, setApiSearchExecuted] = useState(false);
  const [originalApiExpertises, setOriginalApiExpertises] = useState([]);
  const [apiExpertisesList, setApiExpertisesList] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false);
  const [displayedProfilTitle, setDisplayedProfilTitle] = useState("");
  const [enableFiltering, setEnableFiltering] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isExtracting, setIsExtracting] = useState(false);

  const [profilTitle, setProfilTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Nouvel état pour suivre l'état de chargement

  const navigate = useNavigate();
  const [config, setConfig] = useState({
    ia_client_search: true,
    ia_profile_search: true,
    cv_extraction_enabled: true,
    linkedin_extraction_enabled: true,
  });

  useEffect(() => {
    setFormErrors({});
  }, [freelancerStep]);

  useEffect(() => {
    const fetchExpertisesList = async () => {
      try {
        const { data } = await axios.get(`${API_GATEWAY_URL}/expertises/`);
        setExpertises(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des expertises:", error);
      } finally {
        console.log("Chargement des expertises terminé");
      }
    };

    fetchExpertisesList();
    //get actual global config
    axios
      .get(`${API_GATEWAY_URL}/config/`)
      .then((response) => {
        setConfig(response.data);
      })
      .catch((error) => {
        console.error("Error fetching config:", error);
        setErrorMessage("Failed to load configuration");
      });
  }, []);

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    birthdayDate: "",
    country: "",
    commune: "",
    ville: "",
    Address: "",
    codePostal: "",
    description: "",
    profilTitle: "",
    selectedExpertises: "",
    languages: "",
    niveauFreelance: "",

    email: "",
    phoneNumber: "",
    password: "",
  });

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const validatePassword = (password) => {
    // Vérifie si le mot de passe a au moins 8 caractères, au moins une lettre majuscule, au moins un chiffre, et au moins un caractère spécial
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "codePostal" && value.length > 5) {
      return; // Ne pas mettre à jour l'état si la longueur dépasse 5
    }
    dispatch(setFreelancerInscriptionFormData({ [name]: value }));
  };

  const countries = require("country-list"); // Assure-toi que cette fonction retourne la liste complète des pays

  const handlePhoneNumberChange = (value, country) => {
    dispatch(setFreelancerInscriptionFormData({ ["phoneNumber"]: value }));
  };

  const handleChangeDescription = (e) => {
    const maxLength = 300; // Nombre maximal de caractères autorisés
    const { name, value } = e.target;

    if (value.length <= maxLength) {
      dispatch(setFreelancerInscriptionFormData({ [name]: value }));
    } else {
      // Tronquer le texte à la longueur maximale

      dispatch(
        setFreelancerInscriptionFormData({ [name]: value.slice(0, maxLength) })
      );
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    // Handle form submission here
    console.log("Form data:", freelancerInscriptionFormData);

    // Validation des champs pour l'étape 4
    let errors = {};
    if (freelancerStep === 6) {
      if (!freelancerInscriptionFormData.email) {
        errors.email = "Veuillez entrer votre email.";
      }

      if (!freelancerInscriptionFormData.phoneNumber) {
        errors.phoneNumber = "Veuillez entrer votre numéro de téléphone.";
      }

      if (!freelancerInscriptionFormData.password) {
        errors.password = "Veuillez entrer votre mot de passe.";
      } else {
        // Valide le mot de passe

        const isValidPassword = validatePassword(
          freelancerInscriptionFormData.password
        );
        if (!isValidPassword) {
          errors.password =
            "Le mot de passe doit contenir au moins 8 caractères, dont une lettre majuscule, un chiffre et un caractère spécial.";
        }
      }
    }
    // Si des erreurs sont détectées, arrêtez la soumission et affichez les erreurs
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setIsLoading(false);
      return;
    }

    // *****************************

    // TO WORK WITH
    const apiURL = `${API_GATEWAY_URL}/signUp-freelancer/`;
    console.log(apiURL);

    try {
      const formData = new FormData();

      formData.append("firstName", freelancerInscriptionFormData.firstName);
      formData.append("lastName", freelancerInscriptionFormData.lastName);
      formData.append(
        "birthdayDate",
        freelancerInscriptionFormData.birthdayDate
      );
      formData.append("country", freelancerInscriptionFormData.country);
      formData.append("commune", freelancerInscriptionFormData.commune);
      formData.append("ville", freelancerInscriptionFormData.ville);
      formData.append("codePostal", freelancerInscriptionFormData.codePostal);
      formData.append("Address", freelancerInscriptionFormData.Address);
      if (freelancerInscriptionFormData.profilePictureFile) {
        formData.append(
          "profilePicture",
          freelancerInscriptionFormData.profilePictureFile
        );
      }
      formData.append("linkedinUrl", freelancerInscriptionFormData.linkedinUrl);
      formData.append("description", freelancerInscriptionFormData.description);
      formData.append("profilTitle", freelancerInscriptionFormData.profilTitle);
      formData.append(
        "niveauFreelance",
        freelancerInscriptionFormData.niveauFreelance
      );
      if (freelancerInscriptionFormData.freelancerCVFile) {
        formData.append(
          "freelancerCV",
          freelancerInscriptionFormData.freelancerCVFile
        );
      }
      formData.append("email", freelancerInscriptionFormData.email);
      formData.append("phoneNumber", freelancerInscriptionFormData.phoneNumber);
      formData.append("password", freelancerInscriptionFormData.password);

      // Ajoutez les expertises sélectionnées
      // const selectedExpertises = [{ expertise: '', level: '' }]; // Modifiez cela selon les langues sélectionnées
      if (freelancerInscriptionFormData.selectedExpertises.length > 0) {
        formData.append(
          "selectedExpertises",
          JSON.stringify(freelancerInscriptionFormData.selectedExpertises)
        );
      }
      // Ajoutez les langues
      // const languages = [{ language: 'Français', level: '' }]; // Modifiez cela selon les langues sélectionnées
      if (freelancerInscriptionFormData.languages.length > 0) {
        formData.append(
          "languages",
          JSON.stringify(
            freelancerInscriptionFormData.languages.map((lang) => ({
              ...lang,
              language: lang.language, // assuming 'language' holds the code like 'FR'
            }))
          )
        );
      }

      // Ajoutez les expériences professionnelles
      // const parcoursProfessionnel = [
      //   { dateDebut: null, dateFin: null, nomEntreprise: '', posteDansEntreprise: '', descriptionDePoste: '' }
      // ]; // Modifiez cela selon les expériences professionnelles
      if (freelancerInscriptionFormData.parcoursProfessionnel.length > 0) {
        formData.append(
          "parcoursProfessionnel",
          JSON.stringify(freelancerInscriptionFormData.parcoursProfessionnel)
        );
      } else {
        formData.append(
          "parcoursProfessionnel",
          JSON.stringify(
            freelancerInscriptionFormData.parcoursProfessionnel || []
          )
        );
      }

      const response = await fetch(`${API_GATEWAY_URL}/signUp-freelancer/`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (response.ok) {
        console.log("Data sent successfully!");
        navigate("/freelancer-validation-email");
      } else {
        console.error("Failed to send data to Django.");
        console.error(data.message);
        setErrorMessage(data.message);
      }
    } catch (error) {
      setErrorMessage("Erreur technique.");
      console.log("Erreur technique");

      console.error(error);
    } finally {
      setIsLoading(false); // Désactiver l'indicateur de chargement une fois la soumission terminée
    }

    // ****************************
    // dispatch(resetFreelancerInscriptionFormData()); // Réinitialise le formulaire après la soumission
    // navigate('/freelancer-validation-email');
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (freelancerStep < 6) {
        handleNextStep();
      }
      if (freelancerStep == 6) {
        document.getElementById("submitButton").click();
      }
    }
  };

  // Ajoutez ces méthodes après la méthode handleSubmit
  // Fonction pour convertir la réponse de l'API en un tableau JSON
  const convertResponseToJsonArray = (responseString) => {
    const lines = responseString.trim().split("\n");
    return lines.map((line) => JSON.parse(line));
  };

  const fetchExpertisesFromOpenAI = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://jobmarketplace-apigateway-test.azurewebsites.net/apigatewayservice/query-openai/${profilTitle}`
      );
      const expertisesArray = data.split("\n").map((line) => JSON.parse(line));
      const expertisesData = expertisesArray.map((exp) => ({
        id: parseInt(exp.C),
        name: exp.N,
      }));
      setApiExpertises(expertisesData);
      setApiExpertisesList(expertisesData); // Stocker les expertises obtenues de l'API
      setApiSearchExecuted(true);

      // Mise à jour des nouveaux états
      setSearchClicked(true);
      setProfilTitle(profilTitle);
      setDisplayedProfilTitle(profilTitle); // Mise à jour du titre du profil affiché
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des expertises via OpenAI:",
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleProfilTitleChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      if (!apiSearchExecuted) {
        setEnableFiltering(false);
      } else {
        setEnableFiltering(true);
      }
    } else {
      setEnableFiltering(true);
    }
    setProfilTitle(value);
    dispatch(setFreelancerInscriptionFormData({ ["profilTitle"]: value }));
    if (!apiSearchExecuted) {
      setDisplayedProfilTitle(value);
    }
  };

  // Gestion de l'appui sur la touche "Entrée"
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchExpertisesFromOpenAI();
      e.preventDefault(); // Empêche le formulaire de se soumettre
    }
  };
  const LoadingModal = () => (
    <Modal
      show={isLoading}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="text-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Chargement...</span>
          </div>
          <p className="mt-2">Veuillez patienter...</p>
        </div>
      </Modal.Body>
    </Modal>
  );

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   // Handle form submission here
  //   console.log('Form data:', freelancerInscriptionFormData);

  //   // Validation des champs pour l'étape 4
  //   let errors = {};
  //   if (freelancerStep === 4) {
  //
  //
  //       if (!freelancerInscriptionFormData.email) {
  //         errors.email = 'Veuillez entrer votre email.';
  //       }
  //
  //       if (!freelancerInscriptionFormData.password) {
  //         errors.password = 'Veuillez entrer votre mot de passe.';
  //       } else {
  //         // Valide le mot de passe
  //         const isValidPassword = validatePassword(freelancerInscriptionFormData.password);
  //         if (!isValidPassword) {
  //           errors.password =
  //             "Le mot de passe doit contenir au moins 8 caractères, dont une lettre majuscule et un chiffre.";
  //         }
  //       }
  //   }
  //   // Si des erreurs sont détectées, arrêtez la soumission et affichez les erreurs
  //   if (Object.keys(errors).length > 0) {
  //       setFormErrors(errors);
  //       return;
  //   }
  //   // send data to django backend
  //   try {
  //     const formData = new FormData();
  //     for (const key in freelancerInscriptionFormData) {
  //       formData.append(key, freelancerInscriptionFormData[key]);
  //     }

  //     const response = await fetch('url_de_votre_api_django', {
  //       method: 'POST',
  //       body: formData,
  //     });

  //     // Convertissez la réponse en JSON
  //     const data = await response.json();
  //     if (response.ok) {
  //       console.log('Data sent successfully to Django.');
  //       dispatch(resetFreelancerInscriptionFormData()); // Réinitialise le formulaire après une réponse réussie
  //     } else {
  //       console.error('Failed to send data to Django.');
  //     }
  //   } catch (error) {
  //     console.error('Error sending data to Django:', error);
  //   }
  // };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Si la recherche est vide et que l'API OpenAI a été exécutée, réinitialiser les expertises affichées
    if (value === "" && apiSearchExecuted) {
      setApiExpertises(apiExpertisesList); // Réinitialiser à apiExpertisesList
    }
    // Sinon, effectuer une recherche d'expertise
    else {
      const filtered = expertises.filter((expertise) =>
        expertise.name.toLowerCase().includes(value.toLowerCase())
      );
      setApiExpertises(value ? filtered : expertises);
    }
  };

  // const handleAddExpertise = (expertise) => {
  //   setSelectedExpertises([...selectedExpertises, expertise]);
  //   dispatch(setFreelancerInscriptionFormData({ selectedExpertises: [...selectedExpertises, expertise] }));
  // };

  const handleAddExpertise = (expertise) => {
    console.log(selectedExpertises);
    const isAlreadyAdded = selectedExpertises.some(
      (item) => item.id === expertise.id
    );
    if (!isAlreadyAdded) {
      // Ajoutez un champ level à chaque expertise

      setSelectedExpertises([
        ...selectedExpertises,
        { ...expertise, level: "" },
      ]);
      dispatch(
        setFreelancerInscriptionFormData({
          selectedExpertises: [
            ...selectedExpertises,
            { ...expertise, level: "" },
          ],
        })
      );

      // Mettre à jour apiExpertises
      setApiExpertises((prev) => {
        const next = prev.filter((item) => item.id !== expertise.id);
        if (next.length < 10 && expertises.length > 10 && !apiSearchExecuted) {
          const nextExpertise = expertises.find(
            (item) =>
              !selectedExpertises.some((exp) => exp.id === item.id) &&
              !next.some((exp) => exp.id === item.id)
          );
          if (nextExpertise) {
            next.push(nextExpertise);
          }
        }
        return next;
      });
    }
  };

  const handleRemoveExpertise = (id) => {
    const updatedExpertises = selectedExpertises.filter(
      (item) => item.id !== id
    );
    setSelectedExpertises(updatedExpertises);
    dispatch(
      setFreelancerInscriptionFormData({
        selectedExpertises: updatedExpertises,
      })
    );

    // Mettre à jour apiExpertises
    const removedExpertise = expertises.find((item) => item.id === id);
    if (removedExpertise) {
      // Ajoutez cette vérification
      setApiExpertises((prev) => {
        // Vérifie si l'expertise supprimée est déjà dans apiExpertises
        // ou si elle ne correspond pas au terme de recherche actuel
        if (
          prev.some((expertise) => expertise.id === removedExpertise.id) ||
          !removedExpertise.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ) {
          return prev; // Si c'est le cas, retourne apiExpertises sans modification
        } else {
          const next = [...prev, removedExpertise];
          next.sort((a, b) => a.id - b.id);
          return next.slice(0, 10);
        }
      });
    }
  };

  const filteredExpertises = searchTerm
    ? expertises.filter((expertise) =>
        expertise.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : apiExpertises;

  // errors

  const handleNextStep = () => {
    let errors = {};
    if (freelancerStep === 1) {
      const firstNameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/;
      const lastNameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/;

      if (!freelancerInscriptionFormData.firstName) {
        errors.firstName = "Veuillez entrer votre prénom.";
      } else if (
        !firstNameRegex.test(freelancerInscriptionFormData.firstName)
      ) {
        errors.firstName =
          "Le prénom ne doit contenir que des caractères alphabétiques.";
      }

      if (!freelancerInscriptionFormData.lastName) {
        errors.lastName = "Veuillez entrer votre nom.";
      } else if (!lastNameRegex.test(freelancerInscriptionFormData.lastName)) {
        errors.lastName =
          "Le nom ne doit contenir que des caractères alphabétiques.";
      }

      if (!freelancerInscriptionFormData.birthdayDate) {
        errors.birthdayDate = "Veuillez sélectionner votre date de naissance.";
      } else {
        const birthdayTimestamp = new Date(
          freelancerInscriptionFormData.birthdayDate
        ); // Convertir le timestamp en objet Date
        console.log(birthdayTimestamp);

        const now = new Date();
        const differenceInMilliseconds = now - birthdayTimestamp;
        const differenceInYears =
          differenceInMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Approximation avec une année moyenne

        // Obtenir l'âge minimum requis selon le pays
        const minAge =
          freelancerInscriptionFormData.country === "MA"
            ? 15
            : freelancerInscriptionFormData.country === "DZ" ||
              freelancerInscriptionFormData.country === "TN"
            ? 16
            : 0; // Cas par défaut si aucun pays n'est sélectionné ou pays inconnu

        // Vérifier si l'âge est conforme à l'âge minimum avec une précision de jours
        if (differenceInYears >= minAge) {
          dispatch(
            setFreelancerInscriptionFormData({
              birthdayDate: freelancerInscriptionFormData.birthdayDate,
            })
          );
        } else {
          // Calculer l'âge exact au jour près pour le message d'erreur
          const ageNextBirthday = new Date(
            birthdayTimestamp.getFullYear() + minAge,
            birthdayTimestamp.getMonth(),
            birthdayTimestamp.getDate()
          );
          const daysUntilMinAge = Math.ceil(
            (ageNextBirthday - now) / (1000 * 60 * 60 * 24)
          );

          errors.birthdayDate = `Vous devez avoir au moins ${minAge} ans pour travailler dans ce pays. Il reste ${daysUntilMinAge} jours avant que vous atteigniez l'âge minimum.`;
        }
      }

      if (!freelancerInscriptionFormData.country) {
        errors.country = "Veuillez sélectionner votre pays.";
      }
      if (!freelancerInscriptionFormData.commune) {
        errors.commune = "Veuillez entrer votre commune.";
      }
      if (!freelancerInscriptionFormData.Address) {
        errors.Address = "Veuillez entrer votre adresse.";
      }
      if (!freelancerInscriptionFormData.ville) {
        errors.ville = "Veuillez entrer votre ville.";
      }
      if (!freelancerInscriptionFormData.codePostal) {
        errors.codePostal = "Veuillez entrer votre code postal.";
      } else {
        // Expression régulière pour vérifier si le code postal est numérique
        const regexNumeric = /^\d+$/;

        if (!regexNumeric.test(freelancerInscriptionFormData.codePostal)) {
          errors.codePostal = "Le code postal doit être numérique.";
        }
      }

      if (!freelancerInscriptionFormData.description) {
        errors.description =
          "Veuillez entrer une brève description qui vous met en valeur.";
      } else if (freelancerInscriptionFormData.description.length < 40) {
        errors.description =
          "La description doit faire au moins 40 caractères.";
      }
    }
    // Validation des champs pour l'étape 2
    if (freelancerStep === 2) {
      if (!freelancerInscriptionFormData.profilTitle) {
        errors.profilTitle = "Veuillez entrer le titre de votre profil.";
      }

      if (!freelancerInscriptionFormData.selectedExpertises.length) {
        errors.selectedExpertises =
          "Veuillez sélectionner au moins une expertise.";
      } else {
        // Vérifiez si chaque expertise a un niveau défini
        if (
          !freelancerInscriptionFormData.selectedExpertises.every(
            (expertise) => expertise.level
          )
        ) {
          errors.selectedExpertises = "Veuillez renseigner tous les niveaux.";
        }
      }
    }

    if (freelancerStep === 3) {
      if (!freelancerInscriptionFormData.languages.length) {
        errors.languages = "Veuillez sélectionner au moins une langue.";
      } else {
        // Vérifiez si chaque langue a un niveau défini
        freelancerInscriptionFormData.languages.forEach((language) => {
          if (!language.language || !language.level) {
            errors.languages =
              "Veuillez spécifier la langue et le niveau pour toutes les champs ajoutés.";
          }
        });
      }

      if (!freelancerInscriptionFormData.niveauFreelance) {
        errors.niveauFreelance = "Veuillez sélectionner le type de projet.";
      }
    }

    // Si des erreurs sont détectées, arrêtez la progression et affichez les erreurs
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Si aucune erreur, passez à l'étape suivante
    dispatch(freelancerNextStep());
  };

  // const handleBirthdayChange = (date) => {

  //   dispatch(setFreelancerInscriptionFormData({ birthdayDate: date }));
  // };

  const handleBirthdayChange = (date) => {
    // Vérifiez si date est une instance de Date valide
    if (date instanceof Date && !isNaN(date.getTime())) {
      // Convertir la date en timestamp avant de la stocker dans l'état Redux
      const birthdayTimestamp = date.getTime();
      dispatch(
        setFreelancerInscriptionFormData({ birthdayDate: birthdayTimestamp })
      );
    } else {
      console.error("La date fournie n'est pas valide.");
    }
  };

  const countryOptions = [
    { code: "DZ", name: "Algérie" },
    { code: "TN", name: "Tunisie" },
    { code: "MA", name: "Maroc" },
  ];

  const handleCountryChange = (event) => {
    // Extraire la valeur du code pays depuis l'élément select
    const country = event.target.value;
    dispatch(setFreelancerInscriptionFormData({ country: country }));
  };

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    dispatch(setFreelancerInscriptionFormData({ profilePicture: imageUrl }));
    dispatch(setFreelancerInscriptionFormData({ profilePictureFile: file }));
  };
  const handleDeleteProfilePicture = () => {
    dispatch(setFreelancerInscriptionFormData({ profilePicture: null }));
    dispatch(setFreelancerInscriptionFormData({ profilePictureFile: null }));
  };

  const handleExpertiseLevelChange = (id, level) => {
    console.log(id, level);
    dispatch(updateExpertiseLevel({ id, level }));

    // Update local state to keep it in sync with Redux
    setSelectedExpertises((current) => {
      return current.map((item) => {
        if (item.id === id) {
          return { ...item, level };
        }
        return item;
      });
    });
  };
  // ****************************************
  const handleLanguageChange = (selectedOption, index) => {
    // Assuming languages is an array of objects like { language: 'FR', level: 1 }
    const updatedLanguages = [...freelancerInscriptionFormData.languages];
    updatedLanguages[index] = {
      ...updatedLanguages[index],
      language: selectedOption.value, // Ensure it's setting the 'value', not the 'label'
    };
    dispatch(setFreelancerInscriptionFormData({ languages: updatedLanguages }));
  };

  const handleLevelChange = (selectedOption, index) => {
    const updatedLanguages = [...freelancerInscriptionFormData.languages];
    updatedLanguages[index] = {
      ...updatedLanguages[index],
      level: selectedOption.value,
    };
    dispatch(setFreelancerInscriptionFormData({ languages: updatedLanguages }));
  };

  const handleAddLanguage = () => {
    const updatedLanguages = [
      ...freelancerInscriptionFormData.languages,
      { language: "", level: "" },
    ];
    dispatch(setFreelancerInscriptionFormData({ languages: updatedLanguages }));
  };

  const handleRemoveLanguage = (index) => {
    console.log(index);
    console.log(freelancerInscriptionFormData.languages);
    const updatedLanguages = freelancerInscriptionFormData.languages.filter(
      (item, idx) => idx !== index
    );
    console.log(updatedLanguages);
    dispatch(setFreelancerInscriptionFormData({ languages: updatedLanguages }));
  };

  const isLanguageSelected = (languageValue) => {
    return freelancerInscriptionFormData.languages.some(
      (language) => language.language === languageValue
    );
  };

  // ****************************

  const handleCVImport = () => {
    // Ouvrir une boîte de dialogue de sélection de fichier
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf,.doc,.docx"; // Définir les types de fichiers acceptés (PDF, Word, etc.)

    // Définir le gestionnaire d'événements pour l'événement de sélection de fichier
    input.onchange = (event) => {
      const file = event.target.files[0]; // Récupérer le fichier sélectionné
      if (file) {
        // Stocker à la fois le fichier et son nom dans l'état Redux
        const fileUrl = URL.createObjectURL(file);
        console.log(file.name);
        dispatch(
          setFreelancerInscriptionFormData({
            freelancerCV: { file: fileUrl, fileName: file.name },
          })
        );
        dispatch(setFreelancerInscriptionFormData({ freelancerCVFile: file }));
        // Passer à l'étape suivante
        dispatch(freelancerNextStep());
      }
    };

    // Cliquer sur l'élément input pour ouvrir la boîte de dialogue de sélection de fichier
    input.click();
  };

  // Fonction pour gérer le clic sur le bouton d'importation
  const handleClick = () => {
    handleCVImport(); // Appeler la fonction pour gérer l'importation du CV
    // Après l'importation, vous pouvez gérer le passage à l'étape suivante ici si nécessaire
  };
  //-------------------------------------------------------------------------------------

  function convertProxyCurlDateToTimestamp(date, fin) {
    if (!date) {
      return null;
    }
    if (date instanceof Date) {
      return date.getTime();
    }
    if (typeof date === "object") {
      const year = date.year;
      const month = date.month;
      let day;

      if (fin === false) {
        day = 1;
      } else {
        if (month === 2) {
          day = 28;
        } else {
          day = 30;
        }
      }
      return new Date(year, month - 1, day).getTime();
    }

    return null;
  }

  const handleExperienceProfessionnelChange = (value, index, field) => {
    let updatedValue = value;

    if (field === "dateDebut") {
      updatedValue = convertProxyCurlDateToTimestamp(value, false);
    }
    if (field === "dateFin") {
      updatedValue = convertProxyCurlDateToTimestamp(value, true);
    }

    dispatch(
      updateExperienceProfessionnel({ index, field, value: updatedValue })
    );
  };

  const handleAddExperienceProfessionnel = () => {
    const updatedParcoursProfessionnel = [
      ...freelancerInscriptionFormData.parcoursProfessionnel,
      {
        dateDebut: null,
        dateFin: null,
        nomEntreprise: "",
        posteDansEntreprise: "",
        descriptionDePoste: "",
      },
    ];
    dispatch(
      setFreelancerInscriptionFormData({
        parcoursProfessionnel: updatedParcoursProfessionnel,
      })
    );

    // dispatch(addExperienceProfessionnel());
  };

  const handleRemoveExperienceProfessionnel = (index) => {
    dispatch(removeExperienceProfessionnel(index));
  };

  //------------------------------------------------------------------------------------
  const DONOTHING = false;

  const saveFormDataToLocalStorage = () => {
    localStorage.setItem(
      "freelancerFormData",
      JSON.stringify(freelancerInscriptionFormData)
    );
  };

  const handleLinkedInAuth = () => {
    saveFormDataToLocalStorage();
    const clientId = "77yr4repgzv1hn";
    const redirectUri = `${API_GATEWAY_URL}/linkedin/callback/`;
    const linkedinUrl = encodeURIComponent(
      freelancerInscriptionFormData.linkedinUrl
    );

    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=openid%20profile%20email&state=${linkedinUrl}`;
  };

  const fetchLinkedInProfile = async (linkedinURL, access_token) => {
    try {
      const response = await axios.post(
        `${API_GATEWAY_URL}/fetch-linkedin-profile/`,
        {
          linkedin_url: linkedinURL,
          access_token: access_token,
        }
      );

      if (response.status === 200 && response.data.status === "success") {
        return response.data;
      } else {
        console.error(
          "Error fetching LinkedIn profile:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error fetching LinkedIn profile:", error);
    }
  };

  const handleLinkedInImport = async (linkedinUrl, access_token) => {
    if (!linkedinUrl) {
      console.log("LinkedIn URL is required");
    }

    try {
      const ResponseData = await fetchLinkedInProfile(
        linkedinUrl,
        access_token
      );
      const profileData = ResponseData.data;
      const mode = ResponseData.mode;
      // Update the state with the fetched profile data
      let updatedParcoursProfessionnel;

      if (mode == true) {
        updatedParcoursProfessionnel = profileData.experiences.map(
          (position) => ({
            dateDebut: convertProxyCurlDateToTimestamp(
              position.starts_at,
              false
            ),
            dateFin: convertProxyCurlDateToTimestamp(position.ends_at, true),
            nomEntreprise: position.company,
            posteDansEntreprise: position.title,
            descriptionDePoste: position.description,
          })
        );
      } else {
        updatedParcoursProfessionnel = profileData.position.map((position) => ({
          dateDebut: convertProxyCurlDateToTimestamp(
            position.startMonthYear,
            false
          ),
          dateFin: convertProxyCurlDateToTimestamp(position.endMonthYear, true),
          nomEntreprise: position.company,
          posteDansEntreprise: position.title,
          descriptionDePoste: position.description,
        }));
      }

      dispatch(
        setFreelancerInscriptionFormData({
          parcoursProfessionnel: updatedParcoursProfessionnel,
        })
      );
    } catch (error) {
      console.log("Failed to fetch LinkedIn profile data ");
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get("status");
    const access_token = urlParams.get("access_token");
    const step = urlParams.get("step");

    let linkedinUrl;
    const savedFormData = localStorage.getItem("freelancerFormData");
    if (savedFormData) {
      dispatch(setFreelancerInscriptionFormData(JSON.parse(savedFormData)));
      linkedinUrl = JSON.parse(savedFormData).linkedinUrl;

      localStorage.removeItem("freelancerFormData");
    }

    if (status === "success") {
      try {
        dispatch(setFreelancerStep(Number(step)));
        handleLinkedInImport(linkedinUrl, access_token);
      } catch (error) {
        console.error("Error parsing profile data:", error);
      }
    } else if (status === "fail") {
      dispatch(setFreelancerStep(Number(step)));
    }
  }, [dispatch]);

  // Extraction Automatique

  const [aiExtraction, setAiExtraction] = useState(false);
  const [aiDescription, setAiDescription] = useState("");

  const reorderLanguages = (languages) => {
    const frIndex = languages.findIndex((lang) => lang.language === "FR");

    if (frIndex !== -1) {
      const [frEntry] = languages.splice(frIndex, 1);
      return [frEntry, ...languages];
    } else {
      return [{ language: "FR", proficiency_level: 1 }, ...languages];
    }
  };

  const updateDescriptionAI = () => {
    dispatch(setDescription(aiDescription));
  };

  let extractedFreelancerInscriptionFormData = {
    firstName: "",
    lastName: "",
    birthdayDate: null,
    country: "",
    commune: "",
    ville: "",
    codePostal: "",
    Address: "",
    profilePictureFile: null,
    profilePicture: "",
    linkedinUrl: "",
    description: "",
    profilTitle: "",
    selectedExpertises: [],
    languages: [{ language: "FR", level: "" }],
    niveauFreelance: "",
    freelancerCVFile: null,
    freelancerCV: "",
    parcoursProfessionnel: [],
    email: "",
    phoneNumber: "",
    password: "",
  };

  const [errorModelExtract, setErrorModelExtract] = useState(false);

  const LLMErrorMessage = ({ errorModelExtract }) => {
    if (!errorModelExtract) return null;

    return (
      <div
        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
      >
        <strong className="font-bold">Erreur:</strong>
        <span className="block sm:inline">
          Lors de l'extraction de vos informations, veuillez vérifier les
          propriétés de votre CV (taille...).
        </span>
      </div>
    );
  };

  const handleCustomInitialValues = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf";
    input.onchange = (event) => {
      setIsExtracting(true);

      const file = event.target.files[0];
      if (file) {
        let formData = new FormData();
        formData.append("pdf_file", file); // Append the file to form data
        // formData.append('model', 'Gemini');
        // formData.append('gpt_model', '/');

        // Configure the Axios request
        let config = {
          method: "post",
          url: `${API_GATEWAY_URL}/extract-cv-infos/`,
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
          },
          data: formData,
        };

        // Send the request
        axios(config)
          .then((response) => {
            console.log(response.data);
            extractedFreelancerInscriptionFormData.firstName =
              response.data["first_name"];
            extractedFreelancerInscriptionFormData.lastName =
              response.data["last_name"];
            extractedFreelancerInscriptionFormData.Address =
              response.data["address"];
            extractedFreelancerInscriptionFormData.birthdayDate =
              response.data["birth_date"] &&
              !isNaN(new Date(response.data["birth_date"]).getTime())
                ? new Date(response.data["birth_date"])
                : null;
            if (["DZ", "MA", "TN"].includes(response.data["country"])) {
              extractedFreelancerInscriptionFormData.country =
                response.data["country"];
            }
            extractedFreelancerInscriptionFormData.commune =
              response.data["community"];
            extractedFreelancerInscriptionFormData.codePostal =
              Number.isInteger(Number(response.data["postal_code"]))
                ? response.data["postal_code"]
                : extractedFreelancerInscriptionFormData.codePostal;
            extractedFreelancerInscriptionFormData.description =
              response.data["description"];
            setAiDescription(response.data["AI_description"]);
            extractedFreelancerInscriptionFormData.email =
              response.data["email"];
            extractedFreelancerInscriptionFormData.phoneNumber =
              response.data["phone number"];
            extractedFreelancerInscriptionFormData.selectedExpertises =
              response.data["skills"];
            setSelectedExpertises(response.data["skills"]);
            extractedFreelancerInscriptionFormData.parcoursProfessionnel =
              response.data["professional_experience"].map((experience) => ({
                dateDebut:
                  experience.start_date &&
                  !isNaN(new Date(experience.start_date).getTime())
                    ? new Date(experience.start_date)
                    : null,
                dateFin:
                  experience.end_date &&
                  !isNaN(new Date(experience.end_date).getTime())
                    ? new Date(experience.end_date)
                    : null,
                nomEntreprise: experience.company_name,
                posteDansEntreprise: experience.occupied_post,
                descriptionDePoste: experience.post_description,
              }));
            extractedFreelancerInscriptionFormData.profilTitle =
              response.data["profile_title"];
            if (
              ["NONE", "MANY", "FULL"].includes(
                response.data["freelance_experience"]
              )
            ) {
              extractedFreelancerInscriptionFormData.niveauFreelance =
                response.data["freelance_experience"];
            }
            extractedFreelancerInscriptionFormData.ville =
              response.data["city"];
            extractedFreelancerInscriptionFormData.languages = reorderLanguages(
              response.data["spoken_languages"]
            ).map((spokenLanguage) => ({
              level: +spokenLanguage.proficiency_level,
              language: spokenLanguage.language,
            }));
            setAiExtraction(true);
            dispatch(
              setFreelancerInscriptionFormData(
                extractedFreelancerInscriptionFormData
              )
            );
            setErrorModelExtract(false);
          })
          .catch((error) => {
            setErrorModelExtract(true);
          })
          .finally(() => {
            // Remettre l'état du bouton à l'état initial
            setIsExtracting(false);
          });
      } else {
        setIsExtracting(false); // Remettre l'état si aucun fichier n'est sélectionné
      }
    };
    // Cliquer sur l'élément input pour ouvrir la boîte de dialogue de sélection de fichier
    input.click();
  };

  //------------------------------------------------------------------------------------

  return (
    <div className="flex flex-col justify-center items-center">
      <Link className="loginlogo ml-[70px] mb-6" to="/">
        <img className="max-2xl:w-[200px] 2xl:w-[280px]" src={logo} />
      </Link>
      <LoadingModal />
      <div className="MediumContainer font-body text-primary-800 ">
        <div className="row  pt-3 m-0 ">
          <div className="col-md-9 col-sm-12 step-title  d-flex flex-wrap align-items-center  justify-content-start mb-3 ">
            <span className="step-title me-5">
              {freelancerStep === 1 ? (
                <FaCircle
                  className="step-icon"
                  style={{ fill: "#AF1B21", fontSize: "25px" }}
                />
              ) : freelancerStep > 1 ? (
                <FaCheckCircle
                  className="step-icon"
                  style={{ fill: "#AF1B21", fontSize: "25px" }}
                />
              ) : (
                <FaCircle
                  className="step-icon"
                  style={{ fill: "gray", fontSize: "25px" }}
                />
              )}{" "}
              Mes coordonnées <SlArrowRight style={{ fontSize: "10px" }} />
            </span>

            <span className="step-title me-5">
              {freelancerStep >= 2 && freelancerStep <= 5 ? (
                <FaCircle
                  className="step-icon"
                  style={{ fill: "#AF1B21", fontSize: "25px" }}
                />
              ) : freelancerStep > 5 ? (
                <FaCheckCircle
                  className="step-icon"
                  style={{ fill: "#AF1B21", fontSize: "25px" }}
                />
              ) : (
                <FaCircle
                  className="step-icon"
                  style={{ fill: "gray", fontSize: "25px" }}
                />
              )}{" "}
              Expérience professionnelle{" "}
              <SlArrowRight style={{ fontSize: "10px" }} />
            </span>

            <span className="step-title">
              {freelancerStep === 6 ? (
                <FaCircle
                  className="step-icon"
                  style={{ fill: "#AF1B21", fontSize: "25px" }}
                />
              ) : freelancerStep > 6 ? (
                <FaCheckCircle
                  className="step-icon"
                  style={{ fill: "#AF1B21", fontSize: "25px" }}
                />
              ) : (
                <FaCircle
                  className="step-icon"
                  style={{ fill: "gray", fontSize: "25px" }}
                />
              )}
              Sécurité du Compte <SlArrowRight style={{ fontSize: "10px" }} />
            </span>

            {/* Titre et icône pour chaque étape */}
          </div>

          <div className=" col-md-3 ms-auto col-sm-6 d-flex flex-column align-items-end justify-content-center mb-3 ">
            {/* <label className='ProgressBar-label' style={{fontSize:'13px'}} >Pourcentage accompli: {((freelancerStep / totalSteps) * 100).toFixed(1)}  %</label> */}
            <ProgressBar
              className="w-75"
              now={(freelancerStep / totalSteps) * 100}
            />
          </div>
        </div>

        <div className="font-body max-2xl:text-fluid-ms 2xl:text-fluid-md ">
          <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
            {freelancerStep === 1 && (
              <>
                <h2 className="font-bold">Bienvenue sur TeraTalenT !</h2>
                <p className="mb-5">
                  Merci d'avoir choisi TeraTalenT. Prenez un moment pour nous
                  faire part de vos compétences et services. Cela nous permettra
                  de personnaliser nos offres de mission selon vos talents.
                </p>
                {config.cv_extraction_enabled && (
                  <button
                    className="Redbutton"
                    onClick={handleCustomInitialValues}
                    disabled={isExtracting}
                  >
                    {isExtracting
                      ? "En cours d'extraction..."
                      : "Extraire mes informations automatiquement depuis mon CV"}
                  </button>
                )}
                <LLMErrorMessage errorModelExtract={errorModelExtract} />
                <div className="mb-3 mt-5 ">
                  <label htmlFor="lastName" className="font-bold">
                    Nom*
                  </label>
                  <input
                    type="text"
                    className="form"
                    id="lastName"
                    name="lastName"
                    value={freelancerInscriptionFormData.lastName}
                    onChange={handleChange}
                    required
                  />
                  {formErrors.lastName && (
                    <p style={{ color: "red" }}>{formErrors.lastName}</p>
                  )}
                </div>
                <div className="mb-3 ">
                  <label htmlFor="firstName" className="font-bold">
                    Prénom*
                  </label>
                  <input
                    type="text"
                    className="form"
                    id="firstName"
                    name="firstName"
                    value={freelancerInscriptionFormData.firstName}
                    onChange={handleChange}
                    required
                  />
                  {formErrors.firstName && (
                    <p style={{ color: "red" }}>{formErrors.firstName}</p>
                  )}
                </div>

                <div className="mb-3  ">
                  <label htmlFor="birthdayDate" className="font-bold">
                    Date de naissance*
                  </label>
                  <div className="row px-3  ">
                    <DatePicker
                      className="form"
                      selected={freelancerInscriptionFormData.birthdayDate}
                      onChange={(date) => handleBirthdayChange(date)}
                      dateFormat="dd/MM/yyyy" // Format de date souhaité
                      locale="fr"
                    />
                  </div>
                  {formErrors.birthdayDate && (
                    <p style={{ color: "red" }}>{formErrors.birthdayDate}</p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="country" className="font-bold">
                    Pays*
                  </label>
                  <select
                    id="country"
                    name="country"
                    value={freelancerInscriptionFormData.country}
                    onChange={handleCountryChange}
                    required
                    className="form"
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Sélectionnez un pays
                    </option>
                    {countryOptions.map((option) => (
                      <option key={option.code} value={option.code}>
                        {option.name}
                      </option>
                    ))}
                  </select>

                  {formErrors.country && (
                    <p style={{ color: "red" }}>{formErrors.country}</p>
                  )}
                </div>
                <div className="mb-3 ">
                  <label htmlFor="Address" className="font-bold">
                    Adresse*
                  </label>
                  <input
                    type="text"
                    className="form "
                    id="Address"
                    name="Address"
                    value={freelancerInscriptionFormData.Address}
                    onChange={handleChange}
                    required
                  />
                  {formErrors.Address && (
                    <p style={{ color: "red" }}>{formErrors.Address}</p>
                  )}
                </div>

                <div className="mb-3 ">
                  <label htmlFor="commune" className="font-bold">
                    Commune*
                  </label>
                  <input
                    type="text"
                    className="form"
                    id="commune"
                    name="commune"
                    value={freelancerInscriptionFormData.commune}
                    onChange={handleChange}
                    required
                  />
                  {formErrors.commune && (
                    <p style={{ color: "red" }}>{formErrors.commune}</p>
                  )}
                </div>

                <div className="mb-3 ">
                  <label htmlFor="ville" className="font-bold">
                    Ville*
                  </label>
                  <input
                    type="text"
                    className="form"
                    id="ville"
                    name="ville"
                    value={freelancerInscriptionFormData.ville}
                    onChange={handleChange}
                    required
                  />
                  {formErrors.ville && (
                    <p style={{ color: "red" }}>{formErrors.ville}</p>
                  )}
                </div>

                <div className="mb-3 ">
                  <label htmlFor="codePostal" className="font-bold">
                    Code Postal*
                  </label>
                  <input
                    type="number"
                    className="form"
                    id="codePostal"
                    name="codePostal"
                    value={freelancerInscriptionFormData.codePostal}
                    onChange={handleChange}
                    required
                  />
                  {formErrors.codePostal && (
                    <p style={{ color: "red" }}>{formErrors.codePostal}</p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="profilePicture" className="font-bold">
                    Photo de profil
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    className="form"
                    id="profilePicture"
                    onChange={handleProfilePictureChange}
                  />
                  {freelancerInscriptionFormData.profilePicture && (
                    <div
                      className="mb-3 mt-3 position-relative"
                      style={{ width: "100px", height: "100px" }}
                    >
                      <img
                        src={freelancerInscriptionFormData.profilePicture}
                        alt="Photo de profil"
                        className="img-fluid"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                      <button
                        className="btn btn-sm btn-danger position-absolute top-0 end-0 m-1"
                        onClick={handleDeleteProfilePicture}
                        style={{ zIndex: 1 }}
                      >
                        <FaTimes />
                      </button>
                    </div>
                  )}
                </div>

                <div className="mb-3 mt-3">
                  <label htmlFor="linkedinUrl" className="font-bold">
                    URL LinkedIn (optionnel)
                  </label>
                  <input
                    type="url"
                    className="form"
                    id="linkedinUrl"
                    name="linkedinUrl"
                    value={freelancerInscriptionFormData.linkedinUrl || ""}
                    onChange={handleChange}
                    placeholder="https://www.linkedin.com/in/votre-profil"
                  />
                </div>

                <div className="mb-3 mt-5">
                  <label htmlFor="description" className="font-bold">
                    Brève description qui vous met en valeur*
                  </label>
                  <textarea
                    className="form"
                    id="description"
                    name="description"
                    value={freelancerInscriptionFormData.description}
                    onChange={handleChangeDescription}
                    rows="5"
                  />
                  {aiExtraction ? (
                    <button
                      className="nextStep btn"
                      onClick={updateDescriptionAI}
                    >
                      Description avec IA
                    </button>
                  ) : null}
                  {formErrors.description && (
                    <p style={{ color: "red" }}>{formErrors.description}</p>
                  )}
                </div>

                <div className="d-grid gap-2 d-flex justify-content-end mt-5">
                  <button className="Redbutton" onClick={handleNextStep}>
                    Suivant
                  </button>
                </div>
              </>
            )}

            {freelancerStep === 2 && (
              <>
                <div className="mb-3 ">
                  <label htmlFor="profilTitle" className="font-bold">
                    Quel est le titre de votre profil?
                  </label>
                  <input
                    type="text"
                    className="form"
                    id="profilTitle"
                    name="profilTitle"
                    value={freelancerInscriptionFormData.profilTitle}
                    onChange={handleProfilTitleChange}
                    onKeyPress={handleKeyPress}
                    placeholder="Titre du profil / métier"
                    required
                  />
                  {config.ia_profile_search && (
                    <button
                      onClick={fetchExpertisesFromOpenAI}
                      className="Redbutton mt-3 mb-3"
                    >
                      Rechercher les compétences
                    </button>
                  )}

                  {formErrors.profilTitle && (
                    <p style={{ color: "red" }}>{formErrors.profilTitle}</p>
                  )}
                  <p>
                    <a>Exemple de titre:</a>{" "}
                  </p>
                  <p className="ps-2">
                    - Ingénieur systèmes d'information.
                    <br />- Ingénieur réseau.
                    <br />- Ingénieur sécurité informatique.{" "}
                  </p>
                </div>

                <div>
                  <div>
                    <label
                      htmlFor="posteTitle"
                      className="form-label mb-3 text-fluid-sm font-bold"
                    >
                      Quelles sont vos compétences?
                    </label>
                    <div className="flex items-center form overflow-hidden mb-3">
                      <span className="">
                        <FaSearch />
                      </span>
                      <input
                        type="text"
                        value={searchTerm}
                        onChange={enableFiltering ? handleSearchChange : null}
                        className="w-full px-4 focus:outline-none"
                        id="search"
                        placeholder="Recherche d'expertise..."
                        aria-describedby="searchButton"
                        required
                      />
                    </div>
                    {formErrors.selectedExpertises && (
                      <p style={{ color: "red" }}>
                        {" "}
                        {formErrors.selectedExpertises}
                      </p>
                    )}

                    {freelancerInscriptionFormData.selectedExpertises.length >
                      0 && (
                      <>
                        <label className="mt-3 mb-2">
                          Compétences sélectionnées:
                        </label>
                        <div className="expertisesSelectedListe mb-5">
                          {selectedExpertises.map((expertiseItem, index) => (
                            <div key={index} className="ms-3 mt-3">
                              <div className="expertiseselected">
                                <span>{expertiseItem.name}</span>
                                <FaTimes
                                  onClick={() =>
                                    handleRemoveExpertise(expertiseItem.id)
                                  }
                                />
                              </div>
                              <select
                                className="form-select"
                                value={expertiseItem.level}
                                onChange={(e) =>
                                  handleExpertiseLevelChange(
                                    expertiseItem.id,
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">Niveau</option>
                                <option value="JR">Junior</option>
                                <option value="CF">Confirmé</option>
                                <option value="SR">Senior</option>
                              </select>
                            </div>
                          ))}
                        </div>
                      </>
                    )}

                    {apiSearchExecuted && (
                      <label className=" mt-3 mb-3 ">
                        {" "}
                        Compétences populaires pour :{" "}
                        <b>{displayedProfilTitle}</b>
                      </label>
                    )}
                    <div className="expertisesFiltredListe  ms-3">
                      {!enableFiltering ? (
                        <div>
                          <div>
                            {!enableFiltering && (
                              <div
                                style={{
                                  marginTop: "10px",
                                  fontWeight: 600,
                                }}
                              >
                                Merci d'introduire le titre de votre profil afin
                                de pouvoir sélectionner de nouvelles compétences
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        apiExpertises.slice(0, 10).map((expertise, index) => {
                          // Vérifie si l'expertise n'est pas déjà sélectionnée
                          const isNotSelected =
                            !freelancerInscriptionFormData.selectedExpertises.some(
                              (selectedExpertise) =>
                                selectedExpertise.name === expertise.name
                            );

                          // Si l'expertise et le niveau ne sont pas déjà sélectionnés, affichez-les
                          if (isNotSelected) {
                            return (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleAddExpertise({
                                    id: expertise.id,
                                    name: expertise.name,
                                  })
                                }
                                className="expertisefiltred"
                                key={index}
                              >
                                {expertise.name}
                                <FaPlus className="ms-2 cursor-pointer" />
                              </div>
                            );
                          }

                          // Sinon, ne rien afficher
                          return null;
                        })
                      )}
                    </div>
                  </div>
                </div>

                <div className="d-grid gap-2 d-flex justify-content-end mt-5">
                  <button
                    className="previousStep max-2xl:!text-fluid-ms"
                    onClick={() => dispatch(freelancerPrevStep())}
                  >
                    Précédent
                  </button>{" "}
                  <button
                    className="nextStep max-2xl:!text-fluid-ms"
                    onClick={handleNextStep}
                  >
                    Suivant
                  </button>
                </div>
              </>
            )}

            {freelancerStep === 3 && (
              <>
                <div className="mb-5 ">
                  <label className="font-bold mb-0">
                    Quelles sont les langues que vous maîtrisez ?*
                  </label>
                  {formErrors.languages && (
                    <p style={{ color: "red" }}> {formErrors.languages}</p>
                  )}
                  <div className="mt-3">
                    <div
                      className="row justify-content-start"
                      key="0"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      <div class="col-md-4 mb-2">
                        <Select
                          className="me-2"
                          options={languageOptions}
                          value={
                            freelancerInscriptionFormData.languages[0].language
                          }
                          // onChange={(selectedOption) => handleLanguageChange(selectedOption, index)}
                          placeholder={
                            languageOptions.find(
                              (lang) =>
                                lang.value ===
                                freelancerInscriptionFormData.languages[0]
                                  .language
                            )?.label || "Sélectionnez une langue"
                          }
                          isDisabled
                        />
                      </div>
                      <div class="col-md-6 d-flex align-items-center mb-2 ">
                        <Select
                          options={levelsOptions}
                          // value={freelancerInscriptionFormData.languages[0].level}
                          value={levelsOptions.find(
                            (option) =>
                              option.value ===
                              freelancerInscriptionFormData.languages[0].level
                          )}
                          onChange={(selectedOption) =>
                            handleLevelChange(selectedOption, 0)
                          }
                          placeholder="Niveau"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: "300px", // Cette propriété définira la largeur du select en fonction de son contenu
                            }),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    {freelancerInscriptionFormData.languages
                      .slice(1)
                      .map((language, index) => (
                        <div
                          className="row justify-content-start"
                          key={index + 1}
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <div class="col-md-4 mb-2  ">
                            <Select
                              className="me-2 language-select"
                              options={languageOptions.map((option) => ({
                                ...option,
                                isDisabled: isLanguageSelected(option.value),
                              }))}
                              value={languageOptions.find(
                                (option) => option.value === language.language
                              )}
                              onChange={(selectedOption) =>
                                handleLanguageChange(selectedOption, index + 1)
                              }
                              placeholder="Langue"
                            />
                          </div>
                          <div class="col-md-6 d-flex align-items-center mb-2 ">
                            <Select
                              className="me-2 language-level-select"
                              options={levelsOptions}
                              value={levelsOptions.find(
                                (option) => option.value === language.level
                              )}
                              onChange={(selectedOption) =>
                                handleLevelChange(selectedOption, index + 1)
                              }
                              placeholder="Niveau"
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  width: "300px", // Cette propriété définira la largeur du select en fonction de son contenu
                                }),
                              }}
                            />
                            {/* <Button onClick={() => handleRemoveLanguage(index+1)}>Supprimer</Button> */}
                            <FaTrash
                              onClick={() => handleRemoveLanguage(index + 1)}
                              style={{ color: "#AF1B21", cursor: "pointer" }}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                  <button
                    onClick={handleAddLanguage}
                    className="Redbutton flex flex-row items-center justify-center gap-3"
                  >
                    <FaPlus style={{ marginRight: "3px" }} />
                    Ajouter une langue
                  </button>
                </div>

                <div className="mb-3 ">
                  <label className="font-bold mb-0">
                    Quel est votre niveau d'expérience en tant que freelance ?*
                  </label>
                  {formErrors.niveauFreelance && (
                    <p style={{ color: "red" }}>{formErrors.niveauFreelance}</p>
                  )}
                  <div className="ps-5 mt-3">
                    {niveauFreelanceArray.map((option, index) => (
                      <div key={index} className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="niveauFreelance"
                          id={`niveauFreelance-${index}`}
                          value={option.value}
                          checked={
                            freelancerInscriptionFormData.niveauFreelance ===
                            option.value
                          }
                          onChange={handleChange}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`niveauFreelance-${index}`}
                        >
                          {option.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="d-grid gap-2 d-flex justify-content-end mt-5">
                  <button
                    className="previousStep max-2xl:!text-fluid-ms"
                    onClick={() => dispatch(freelancerPrevStep())}
                  >
                    Précédent
                  </button>{" "}
                  <button
                    className="nextStep max-2xl:!text-fluid-ms"
                    onClick={handleNextStep}
                  >
                    Suivant
                  </button>
                </div>
              </>
            )}

            {freelancerStep === 4 && (
              <>
                <h5 className="mt-5 font-bold text-fluid-sm">
                  Comment préférez-vous nous présenter vos antécédents
                  professionnels ?
                </h5>
                <p className="mb-5">
                  Vous avez le choix entre trois options : Saisir manuellement
                  votre parcours, Importer votre profil LinkedIn pour gagner du
                  temps, ou Télécharger votre CV. Les informations extraites
                  peuvent être ajustées avant la publication de votre profil
                </p>

                {config.linkedin_extraction_enabled && (
                  <div className="text-center mb-3 text-primary-800">
                    {DONOTHING === false ? (
                      <Link
                        className="form flex flex-row items-center justify-center linkedin-link div-lien-linkedin"
                        to="#"
                        onClick={handleLinkedInAuth}
                      >
                        <FaLinkedin className="linkedin-icon me-2" />
                        <span>Importer depuis LinkedIn</span>
                      </Link>
                    ) : (
                      <span className="linkedin-link div-lien-linkedin-disabled">
                        <FaLinkedin className="linkedin-icon-disabled me-2" />
                        <span>Importer depuis LinkedIn</span>
                      </span>
                    )}
                  </div>
                )}

                <div className="text-center mb-3 ">
                  <button
                    className="form flex flex-row items-center justify-center div-lien-linkedin"
                    onClick={() => dispatch(freelancerNextStep())}
                  >
                    <BiPencil className="linkedin-icon me-2" />
                    Remplir manuellement.
                  </button>
                </div>

                <div className="d-grid gap-2 d-flex justify-content-end mt-5">
                  <button
                    className="previousStep"
                    onClick={() => dispatch(freelancerPrevStep())}
                  >
                    Précédent
                  </button>{" "}
                  {/* <Button variant="primary" className="nextStep"  onClick={handleNextStep}>Suivant</Button> */}
                </div>
              </>
            )}

            {freelancerStep === 5 && (
              <>
                <div className="mb-3 mt-3 max-2xl:text-fluid-ms 2xl:text-fluid-md">
                  {freelancerInscriptionFormData.freelancerCV ? (
                    <h5 className="form-label mb-3 ">
                      Voici les expériences que nous avons obtenues à partir de
                      votre CV. Y a-t-il autre chose à ajouter ?
                    </h5>
                  ) : (
                    <h3 className="form-label mb-3 font-bold max-2xl:text-fluid-ms 2xl:text-fluid-sm ">
                      Expériences professionnelles:
                    </h3>
                  )}

                  <div className="">
                    {freelancerInscriptionFormData.parcoursProfessionnel.map(
                      (experience, index) => (
                        <div
                          className="row  div-expProfessionnel px-5 py-2 mb-3"
                          key={index}
                        >
                          <label className="font-bold">Date de début:</label>
                          <DatePicker
                            className="form"
                            selected={
                              experience.dateDebut
                                ? new Date(experience.dateDebut)
                                : null
                            }
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) =>
                              handleExperienceProfessionnelChange(
                                date,
                                index,
                                "dateDebut"
                              )
                            }
                            locale="fr"
                          />
                          {/*<DatePicker
                    className="form-control"
                    selected={experience.dateDebut}
                    dateFormat="dd/MM/yyyy" // Format de date souhaité
                    onChange={(date) => handleExperienceProfessionnelChange(date.getTime(), index, 'dateDebut')}
                    locale="fr"
                    />*/}

                          {/* Utilisation de DatePicker pour dateFin */}
                          <label className="font-bold">Date de fin:</label>
                          <DatePicker
                            className="form"
                            selected={
                              experience.dateFin
                                ? new Date(experience.dateFin)
                                : null
                            }
                            onChange={(date) =>
                              handleExperienceProfessionnelChange(
                                date,
                                index,
                                "dateFin"
                              )
                            }
                            dateFormat="dd/MM/yyyy"
                            locale="fr"
                          />
                          {/*<DatePicker
                    className="form-control"
                    selected={experience.dateFin}
                    // j ai ajouté .getTime() pour que l envoi de la date soit serializable
                    onChange={(date) => handleExperienceProfessionnelChange(date.getTime(), index, 'dateFin')}
                    dateFormat="dd/MM/yyyy" // Format de date souhaité
                    locale="fr"
                    />*/}
                          <label className="font-bold">
                            Nom de l'entreprise:
                          </label>
                          <input
                            type="text"
                            className="form"
                            value={experience.nomEntreprise}
                            onChange={(e) =>
                              handleExperienceProfessionnelChange(
                                e.target.value,
                                index,
                                "nomEntreprise"
                              )
                            }
                          />

                          <label className="font-bold">Poste occupé:</label>
                          <input
                            type="text"
                            className="form"
                            value={experience.posteDansEntreprise}
                            onChange={(e) =>
                              handleExperienceProfessionnelChange(
                                e.target.value,
                                index,
                                "posteDansEntreprise"
                              )
                            }
                          />

                          <label className="font-bold">
                            Description du poste:
                          </label>
                          <textarea
                            className="form"
                            rows="3"
                            value={experience.descriptionDePoste}
                            onChange={(e) =>
                              handleExperienceProfessionnelChange(
                                e.target.value,
                                index,
                                "descriptionDePoste"
                              )
                            }
                          />

                          {/* <Button  onClick={() => handleRemoveExperienceProfessionnel(index)}>Supprimer</Button> */}
                          <div
                            key={index}
                            className="mb-3 mt-3 d-flex justify-content-end"
                          >
                            {/* Vos autres champs et labels */}
                            <button
                              className="btn btn-danger"
                              onClick={() =>
                                handleRemoveExperienceProfessionnel(index)
                              }
                              style={{
                                borderRadius: "15px",
                                width: "fit-content",
                                height: "fit-content",
                              }}
                            >
                              <div className="flex flex-row justify-center items-center">
                                <span>Supprimer</span>
                                <FaTrash className="ms-2" />
                              </div>
                            </button>
                          </div>
                        </div>
                      )
                    )}

                    <button
                      onClick={handleAddExperienceProfessionnel}
                      className="Redbutton flex flex-row items-center justify-center gap-3"
                    >
                      <FaPlus />
                      Ajouter une expérience
                    </button>
                  </div>
                </div>

                <div className="d-grid gap-2 d-flex justify-content-end mt-5">
                  <button
                    className="previousStep max-2xl:!text-fluid-ms"
                    onClick={() => dispatch(freelancerPrevStep())}
                  >
                    Précedent
                  </button>{" "}
                  <button
                    className="nextStep max-2xl:!text-fluid-ms"
                    onClick={handleNextStep}
                  >
                    Suivant
                  </button>
                </div>
              </>
            )}

            {freelancerStep === 6 && (
              <>
                <div className="mb-3 ">
                  <label htmlFor="email" className="font-bold ">
                    Email*
                  </label>

                  <input
                    type="email"
                    className="form"
                    id="email"
                    name="email"
                    value={freelancerInscriptionFormData.email}
                    onChange={handleChange}
                  />
                  {formErrors.email && (
                    <p style={{ color: "red" }}>{formErrors.email}</p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="phoneNumber" className="font-bold">
                    Numéro de téléphone*
                  </label>

                  <div>
                    <PhoneInput
                      country={(
                        freelancerInscriptionFormData.country || ""
                      ).toLowerCase()}
                      value={freelancerInscriptionFormData.phoneNumber}
                      onChange={handlePhoneNumberChange}
                      name="phoneNumber"
                      inputClass="form-control w-100"
                      placeholder=""
                      className="form"
                      containerStyle={{
                        borderRadius: "30px",
                      }}
                      inputStyle={{
                        border: "0px",
                        fontSize: "23px",
                        width: "100%",
                        outline: "none",
                        boxShadow: "none", // to remove any focus ring or shadow
                      }}
                      buttonStyle={{
                        background: "transparent",
                        border: "0px",
                        borderRadius: "30px",
                      }}
                      dropdownStyle={{
                        border: "1px solid #ccc", // Add border to dropdown if needed
                        borderRadius: "8px", // Adjust border radius for dropdown
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Optional shadow
                      }}
                    />
                  </div>
                  {formErrors.phoneNumber && (
                    <p style={{ color: "red" }}>{formErrors.phoneNumber}</p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="password" className="font-bold">
                    Mot de passe*
                  </label>
                  <div style={{ position: "relative" }}>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      className="form"
                      id="password"
                      name="password"
                      value={freelancerInscriptionFormData.password}
                      onChange={handleChange}
                      style={{ paddingRight: "2.5rem" }}
                    />
                    <span
                      className="password-toggle mx-4"
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "8px",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                  {formErrors.password && (
                    <p style={{ color: "red" }}>{formErrors.password}</p>
                  )}
                </div>

                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-5">
                  <button
                    className="previousStep me-md-2"
                    onClick={() => dispatch(freelancerPrevStep())}
                  >
                    Précédent
                  </button>
                  <button
                    id="submitButton"
                    className="nextStep"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {" Enregistrement..."}
                      </>
                    ) : (
                      "Enregistrer"
                    )}
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default FreelancerMultiStepForm;
