import Swal from 'sweetalert2';

const PaymentPanel = ({ onConfirm, onCancel }) => {
  return Swal.fire({
    title: 'Paiement requis',
    text: 'Vous devez effectuer un paiement avant de pouvoir sélectionner cette ressource.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Payer',
    cancelButtonText: 'Annuler',
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm();
    } else {
      onCancel();
    }
  });
};

export default PaymentPanel;
