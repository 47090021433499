import React, { useState } from 'react';
import '../../index.css';

const ReadMore = ({ children, characterLimit = 300 }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const text = children;
    const isLongText = text.length > characterLimit;
    const firstPart = isLongText ? text.substring(0, characterLimit) : text;
    const secondPart = isLongText ? text.substring(characterLimit) : '';
    
    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };
    if (!children) return null;

    return (
        <div className={`add-read-more ${isExpanded ? 'show-more-content' : 'show-less-content'}`}>
        <span
            style={{
                fontFamily: "var(--ff-body)",
                fontWeight: "var(--fw-regular)",
                fontSize: "var(--fs-p-small)",
            }}
        >
            {firstPart}
        </span>
        {isLongText && (
            <>
                    {isExpanded === false ? <span > ... </span> : null}
                    <span className="read-more"
                        onClick={handleToggle}
                        title="Click to Show More"
                        style={{
                            fontFamily: "var(--ff-body)",
                            fontWeight: "var(--fw-medium)",
                            fontSize: "var(--fs-p-small)",
                        }}
                    >
                        {'lire plus'}
                    </span>
                    <span className="second-section"
                        style={{
                            fontFamily: "var(--ff-body)",
                            fontWeight: "var(--fw-regular)",
                            fontSize: "var(--fs-p-small)",
                        }}
                    >
                        {secondPart}.
                    </span>
                    <span className="read-less" onClick={handleToggle} title="Click to Show Less"
                        style={{
                            fontFamily: "var(--ff-body)",
                            fontWeight: "var(--fw-medium)",
                            fontSize: "var(--fs-p-small)",
                            textTransform: "capitalize",
                        }}
                    >
                        {' lire moins'}
                    </span>
            </>
        )}
        </div>
    );
};

const ProfileDescription = ({ description }) => {
    return (
        <ReadMore characterLimit={200}>
            {description}
        </ReadMore>
    );
}

export default ProfileDescription;
