import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  freelancerStep: 1,
  freelancerInscriptionFormData: {
    firstName: '',
    lastName: '',
    // Convertir la date en timestamp avant de la stocker dans l'état Redux pour qu on aurras pas probléme de dispatch
    birthdayDate: null, // Utiliser null comme valeur initiale pour les dates, 
    country: '',
    commune:'',
    ville:'',
    codePostal:'',
    Address: '',
    profilePictureFile: null,
    profilePicture: '',
    linkedinUrl:'',
    description: '',
    profilTitle: '',
    selectedExpertises: [],
    languages: [{ language: 'FR', level: '' }],
    niveauFreelance: '',
    freelancerCVFile: null,
    freelancerCV: '',
    parcoursProfessionnel: [],
    email: '',
    phoneNumber: '',
    password: ''
  }
};

export const freelancerInscriptionFormSlice = createSlice({
  name: 'freelancerInscriptionForm',
  initialState,
  reducers: {
    setFreelancerInscriptionFormData: (state, action) => {
      state.freelancerInscriptionFormData = { ...state.freelancerInscriptionFormData, ...action.payload };
    },
    setFreelancerInscriptionFormDataWithCustomValues: (state, action) => {
      state.freelancerInscriptionFormData = { ...initialState.freelancerInscriptionFormData, ...action.payload };
    },
    freelancerNextStep: state => {
      state.freelancerStep++;
    },
    freelancerPrevStep: state => {
      state.freelancerStep--;
    },
    resetFreelancerInscriptionFormData: state => {
      state.freelancerStep = 0;
      state.freelancerInscriptionFormData = {
        firstName: '',
        lastName: '',
        birthdayDate: null,
        country: '',
        commune:'',
        ville:'',
   		  codePostal:'',
        Address: '',
        profilePictureFile: null,
        profilePicture: '',
        linkedinUrl:'',
        description: '',
        profilTitle: '',
        selectedExpertises: [],
        languages: [{ language: 'FR', level: '' }],
        niveauFreelance: '',
        freelancerCVFile: null,
        freelancerCV: '',
        parcoursProfessionnel: [],
        email: '',
        phoneNumber: '',
        password: ''
      };
    },
    setFreelancerStep: (state, action) => {
      state.freelancerStep = action.payload;
    },
    updateExpertiseLevel: (state, action) => {
      const { id, level } = action.payload;
      const index = state.freelancerInscriptionFormData.selectedExpertises.findIndex(item => item.id === id);
      if (index !== -1) {
        state.freelancerInscriptionFormData.selectedExpertises[index].level = level;
      }
    },
    setDescription: (state, action) => {
      state.freelancerInscriptionFormData.description = action.payload;
    },

    removeExperienceProfessionnel: (state, action) => {
      state.freelancerInscriptionFormData.parcoursProfessionnel.splice(action.payload, 1);
    },
    updateExperienceProfessionnel: (state, action) => {
      const { index, field, value } = action.payload;
      state.freelancerInscriptionFormData.parcoursProfessionnel[index][field] = value;
    },
    addExperienceProfessionnel: state => {
      state.freelancerInscriptionFormData.parcoursProfessionnel.push({ dateDebut: null, dateFin: null, nomEntreprise: '', posteDansEntreprise: '', descriptionDePoste: '' });
    }
  }
});

export const {
  setFreelancerInscriptionFormData,
  setFreelancerInscriptionFormDataWithCustomValues,
  freelancerNextStep,
  freelancerPrevStep,
  resetFreelancerInscriptionFormData,
  setFreelancerStep,
  updateExpertiseLevel,
  addExperienceProfessionnel,
  removeExperienceProfessionnel,
  updateExperienceProfessionnel,
  setDescription,
} = freelancerInscriptionFormSlice.actions;

export default freelancerInscriptionFormSlice.reducer;
