import { useState } from "react";
import Accordion from "./Accordion";
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import AccordionProvider from "./AccordionProvider";
import "../index.css";
import "../styles/Buttons.css";

//last FAQ section +  call to action section
const FAQPartner2 = () => {
  const { user } = useAuth();

  const faqData = [
    {
      question: "Comment sélectionnez-vous les consultants ?",
      answer:
        "Nous sélectionnons rigoureusement les consultants en fonction de leurs expertises, leurs savoir être et de leurs adéquation avec les besoins spécifiques de chaque client. Des entretiens individuels et tests techniques sont effectués avec chaque intervenant avant de l’inclure sur notre plateforme.",
    },
    {
      question:
        "Dans quelle région se trouvent les consultants de la plateforme ?",
      answer:
        "Nous avons une très grande présence en Algérie. Nous avons comme projet de s’étendre sur d’autres pays du Maghreb. Vous bénéficiez d'une proximité culturelle et linguistique, tout en optimisant vos coûts grâce à des tarifs compétitifs.",
    },
    {
      question: "Quels sont les délais pour obtenir une réponse ?",
      answer:
        "Une fois que vous soumettez votre demande sur notre plateforme, nous nous engageons à vous fournir une réponse dans un délai de 48 heures. Ce temps nous permet de vérifier la disponibilité et de sélectionner les profils les plus adaptés parmi nos experts IT.",
    },
    {
      question: "Comment garantissez-vous la qualité du travail livré ?",
      answer:
        "Nous assurons un suivi rigoureux à chaque étape du déroulement de la prestation et mettons en place des processus de contrôle qualité pour garantir un très bon niveau de satisfaction.",
    },

    {
      question: "Quel est le niveau d'expertise des consultants ?",
      answer:
        "Nous disposons d'un vaste réseau de professionnels IT, allant des jeunes talents prometteurs aux experts chevronnés avec des années d'expérience. En fonction de vos exigences, nous sélectionnons les profils les plus pertinents pour vous garantir un service sur mesure.",
    },
    {
      question: "Avez-vous des offres pour des contrats longues durées ?",
      answer: (
        <>
          "Oui, nous proposons des formules spécialement conçues pour des
          besoins permanents en substitution d’un recrutement en interne en CDI.
          Nos offres mensuelles sont disponibles à des tarifs dégressifs et
          seront assurées par notre partenaire Extrinsec. Nous vous invitons à
          consulter le site internet :
          <a
            href="https://www.extrinsec.fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.extrinsec.fr
          </a>
        </>
      ),
    },

    {
      question: "Etes-vous conforme au RGPD ?",
      answer:
        "Oui, nous sommes pleinement conformes au Règlement Général sur la Protection des Données. Nous appliquons des mesures strictes pour assurer la confidentialité et la sécurité des données personnelles, conformément aux exigences du cadre juridique européen.",
    },
    {
      question: "Votre entreprise est-elle domiciliée en France ?",
      answer:
        "Oui, notre entreprise est enregistrée en France. Nous sommes conformes aux lois et régulations du droit français. Ceci offre à nos client une gestion administrative et commerciale simplifiée.",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null); // Nouveau state pour suivre l'index ouvert

  const handleAccordionToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Ouvre ou ferme l'accordéon
  };

  return (
    <div className="flex flex-col items-center justify-center">
      {/* FAQ section */}
      <div className="text-center py-10 2xl:py-20">
        <h2 className="font-display text-white font-bold text-fluid-xlg 2xl:text-fluid-xl px-1 ">
          Questions fréquemment posées
        </h2>
      </div>
      <div className="flex flex-col max-sm:px-2 max-2xl:px-32 2xl:px-56 space-x-6 max-md:space-x-0 ">
        <div className="flex flex-col">
          {faqData.map((item, index) => (
            <AccordionProvider
              key={index}
              question={item.question}
              answer={item.answer}
              isOpen={openIndex === index} // Passe l'état d'ouverture
              onToggle={() => handleAccordionToggle(index)} // Gère le toggle
            />
          ))}
        </div>
      </div>

      {/* Call to action section */}
      <div className="flex flex-col bg-white rounded-[30px] text-primary-800 w-[85%] max-md:w-[95%] max-md:px-5 p-10 mt-24 ">
        <span className="font-display max-2xl:text-fluid-md 2xl:text-fluid-xl font-bold">
          Voulez-vous découvrir la plateforme ? Une demi-journée offerte
        </span>
        <span className="font-body max-2xl:text-fluid-sm 2xl:text-fluid-lg">
          Inscrivez-vous vite et réservez votre créneau d’une demi-journée de
          prestation offerte avec un de nos experts IT dans un des domaines
          suivants :
        </span>
        <div className="flex justify-center items-stretch  max-lg:flex-col max-lg:space-y-3 max-lg:space-x-0 space-x-5 font-body max-2xl:text-fluid-sm 2xl:text-fluid-md mt-6 font-bold text-center ">
          <span className="RedLandingcard flex-1 ">Systèmes et réseaux</span>
          <span className="RedLandingcard flex-1 ">Data analyse et BI</span>
          <span className="RedLandingcard flex-1 ">Développement web</span>
        </div>
        {/* Button */}
        {!user && (
          <Link
            to="/type_de_profile"
            className="btn-red self-end mt-4 max-2xl:text-fluid-sm 2xl:text-fluid-md btn-sans-serif"
          >
            S’inscrire MAINTENANT
          </Link>
        )}
      </div>
    </div>
  );
};

export default FAQPartner2;
