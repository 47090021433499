import React, { useState, useEffect } from "react";
import { API_GATEWAY_URL } from "../constants";
import { useAuth } from "./AuthContext";
import { CountryDropdown } from "react-country-region-selector";
import { useNavigate } from "react-router-dom";
import ProviderCard from "./ProviderCard";
import axios from "axios";

import "../styles/ProviderUpdateForme.css";
import LoadingSpinner from "./LoadingSpinner";

const ProviderUpdateForm = () => {
  const { user } = useAuth();
  const [businessName, setBusinessName] = useState("");
  const [nis, setNIS] = useState("");
  const [nif, setNIF] = useState("");
  const [nrc, setNRC] = useState("");

  const [providerStatus, setProviderStatus] = useState("");
  const [address, setAddress] = useState("");
  const [originalAddress, setOriginalAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [size, setSize] = useState("");

  const [city, setCity] = useState("");
  const [commune, setCommune] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [manager_name, setManager_name] = useState("");
  const [manager_phone, setManager_phone] = useState("");
  const [manager_email, setManager_email] = useState("");
  const [managerFirstName, setManagerFirstName] = useState("");
  const [providerData, setProviderData] = useState(null);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  // Add new fields as needed

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState("");
  const [removeLogo, setRemoveLogo] = useState(false);
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  const [config, setConfig] = useState({
    ia_client_search: true,
    ia_profile_search: true,
    cv_extraction_enabled: true,
    linkedin_extraction_enabled: true,
  });

  useEffect(() => {
    const fetchProviderData = async () => {
      try {
        const response = await fetch(`${API_GATEWAY_URL}/provider-profile/`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setBusinessName(data.name);
          setNIS(data.nis);
          setNIF(data.nif);
          setNRC(data.nrc);

          setProviderStatus(data.provider_status);
          setAddress(data.address);
          setOriginalAddress(data.address);
          setPostalCode(data.postalCode);
          setSize(data.size);

          setCity(data.city);
          setCommune(data.commune);
          setCountry(data.country);
          setPhone(data.phone);
          setManager_name(data.manager_name);
          setManager_phone(data.manager_phone);
          setManager_email(data.manager_email);
          setManagerFirstName(data.managerFirstName);
          // ... Make sure you have a corresponding state setter for each new field
          setEmail(data.email);
          setLogoUrl(data.logo);
        } else {
          throw new Error("Failed to fetch provider data");
        }
      } catch (error) {
        setApiError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProviderData();
    axios
      .get(`${API_GATEWAY_URL}/config/`)
      .then((response) => {
        setConfig(response.data);
      })
      .catch((error) => {
        console.error("Error fetching config:", error);
      });
  }, [user.access_token]);

  const fetchProviderData = async () => {
    try {
      const response = await fetch(`${API_GATEWAY_URL}/provider-profile/`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch provider data.");
      }
      const data = await response.json();
      setProviderData(data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (user && user.access_token) {
      fetchProviderData(); // Fetch provider data on component mount
    }
  }, [user]);

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }
  const validateEmail = (email) => {
    // Assurez-vous que l'email n'est pas undefined ou vide avant de continuer
    if (typeof email !== "undefined" && email.trim() !== "") {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email.toLowerCase());
    }
    // Retourne false si l'email est undefined ou vide
    return false;
  };

  const isNumeric = (value) => {
    return /^\d+$/.test(value);
  };

  const validatePassword = (password) => {
    return password.length >= 8;
  };

  const checkErrors = () => {
    const newErrors = {};
    if (!businessName.trim()) {
      newErrors.businessName = "Le nom de l'entreprise est requis.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRemoveLogoChange = (e) => {
    setRemoveLogo(e.target.checked);
    if (e.target.checked) {
      setLogo(null);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Email avant validation:", email); // Log pour diagnostiquer le problème
    if (!checkErrors()) {
      console.log("Validation failed", errors);
      return;
    }
    if (checkErrors()) {
      const formData = new FormData();
      formData.append("name", businessName);
      formData.append("nis", nis);
      formData.append("nif", nif);
      formData.append("nrc", nrc);

      formData.append("provider_status", providerStatus);
      formData.append("postalCode", postalCode);
      formData.append("size", size);
      formData.append("city", city);
      formData.append("commune", commune);
      formData.append("country", country);
      formData.append("phone", phone);
      formData.append("manager_name", manager_name);
      formData.append("manager_phone", manager_phone);
      formData.append("manager_email", manager_email);
      formData.append("managerFirstName", managerFirstName);
      if (address !== originalAddress) {
        // Vérifiez si l'adresse a été modifiée
        formData.append("new_address", address); // Utilisez l'adresse saisie comme nouvelle adresse
      }

      formData.append("email", email);
      if (password) {
        formData.append("password", password);
      }
      if (logo) {
        formData.append("logo", logo);
      }
      if (removeLogo) {
        formData.append("remove_logo", "true");
      }

      try {
        const response = await fetch(
          `${API_GATEWAY_URL}/provider-profile-update/`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setSuccessMessage("Profil mis à jour avec succès.");
          // Navigate to the provider-profile page after a short delay
          setTimeout(() => {
            navigate("/provider-profile");
          }, 2000); // Adjust the delay as needed
        } else {
          setApiError(
            data.message || "Erreur lors de la mise à jour du profil."
          );
        }
      } catch (error) {
        setApiError("Erreur lors de la communication avec le serveur.");
      }
    }
  };

  return (
    <div className="provider-updateform-container">
      {providerData && <ProviderCard providerData={providerData} />}
      <form onSubmit={handleSubmit} className="provider-updateform">
        {apiError && <div className="error-message">{apiError}</div>}
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}

        <div className="company-info">
          {/* Company Info Fields */}
          <h2>Informations de l'entreprise</h2>
          <div>
            <label>Nom de l'entreprise:</label>
            <input
              type="text"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
              placeholder="Nom de l'entreprise"
              disabled={isLoading}
            />
            {errors.businessName && (
              <div className="error-message">{errors.businessName}</div>
            )}
          </div>
          <div>
            <label>NIS de l'entreprise:</label>
            <input
              type="text"
              value={nis}
              onChange={(e) => setNIS(e.target.value)}
              placeholder="NIS"
              disabled={isLoading}
            />
            {errors.nis && <div className="error-message">{errors.nis}</div>}
          </div>

          <div>
            <label>NIF de l'entreprise:</label>
            <input
              type="text"
              value={nif}
              onChange={(e) => setNIF(e.target.value)}
              placeholder="NIF"
              disabled={isLoading}
            />
            {errors.nif && <div className="error-message">{errors.nif}</div>}
          </div>

          <div>
            <label>NRC de l'entreprise:</label>
            <input
              type="text"
              value={nrc}
              onChange={(e) => setNRC(e.target.value)}
              placeholder="NRC"
              disabled={isLoading}
            />
            {errors.nrc && <div className="error-message">{errors.nrc}</div>}
          </div>

          <div>
            <label>Statut du fournisseur:</label>
            <select
              value={providerStatus}
              onChange={(e) => setProviderStatus(e.target.value)}
              disabled={isLoading}
            >
              <option value="">Sélectionnez le statut</option>
              <option value="SARL">SARL</option>
              <option value="EURL">EURL</option>
              <option value="SPA">SPA</option>
              <option value="SNC">SNC</option>
              <option value="Personne Physique">Personne Physique</option>
            </select>
            {errors.providerStatus && (
              <div className="error-message">{errors.providerStatus}</div>
            )}
          </div>
          <div>
            <label>Adresse:</label>
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Adresse"
              disabled={isLoading}
            />
            {errors.address && (
              <div className="error-message">{errors.address}</div>
            )}
          </div>

          <div>
            <label>Code Postal:</label>
            <input
              type="text"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              placeholder="Code Postal"
              disabled={isLoading}
            />
            {errors.postalCode && (
              <div className="error-message">{errors.postalCode}</div>
            )}
          </div>

          <div>
            <label>Ville:</label>
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              placeholder="Ville"
              disabled={isLoading}
            />
            {errors.city && <div className="error-message">{errors.city}</div>}
          </div>

          <div>
            <label>Commune:</label>
            <input
              type="text"
              value={commune}
              onChange={(e) => setCommune(e.target.value)}
              placeholder="Commune"
              disabled={isLoading}
            />
            {errors.commune && (
              <div className="error-message">{errors.commune}</div>
            )}
          </div>

          <div>
            <label>Pays:</label>
            <CountryDropdown
              classes="form-control"
              value={country}
              onChange={setCountry}
              disabled={isLoading}
            />
            {errors.country && (
              <div className="error-message">{errors.country}</div>
            )}
          </div>

          {/* Business name, NIS, NIF, NRC, Provider Status, etc. */}
        </div>

        <div className="contact-info">
          {/* Contact Info Fields */}
          <h2>Informations de contact</h2>
          <div>
            <label>Téléphone:</label>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Téléphone"
              disabled={isLoading}
            />
            {errors.phone && (
              <div className="error-message">{errors.phone}</div>
            )}
          </div>

          <div>
            <label>Nom du Gérant:</label>
            <input
              type="text"
              value={manager_name}
              onChange={(e) => setManager_name(e.target.value)}
              placeholder="Nom du Gérant"
              disabled={isLoading}
            />
            {errors.managerName && (
              <div className="error-message">{errors.managerName}</div>
            )}
          </div>

          <div>
            <label>Prénom du Gérant:</label>
            <input
              type="text"
              value={managerFirstName}
              onChange={(e) => setManagerFirstName(e.target.value)}
              placeholder="Prénom du Gérant"
              disabled={isLoading}
            />
            {errors.managerFirstName && (
              <div className="error-message">{errors.managerFirstName}</div>
            )}
          </div>

          <div>
            <label>Email du Gérant:</label>
            <input
              type="text"
              value={manager_email}
              onChange={(e) => setManager_email(e.target.value)}
              placeholder="Email du Gérant"
              disabled={isLoading}
            />
            {errors.manager_email && (
              <div className="error-message">{errors.manager_email}</div>
            )}
          </div>
          {/* Address, Postal Code, City, Commune, Country, Phone */}
        </div>

        <div className="credentials-info">
          {/* Credentials Fields */}
          <h2>Email et mots de passe</h2>
          <div>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              disabled={isLoading}
            />
            {errors.email && (
              <div className="error-message">{errors.email}</div>
            )}
          </div>

          <div>
            <label>Mot de passe:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Mot de passe"
              disabled={isLoading}
            />
            {errors.password && (
              <div className="error-message">{errors.password}</div>
            )}
          </div>

          <div>
            <label>Confirmez le mot de passe:</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirmez le mot de passe"
              disabled={isLoading}
            />
            {errors.confirmPassword && (
              <div className="error-message">{errors.confirmPassword}</div>
            )}
          </div>
          {/* Manager's Name, Manager's Email, Email, Password, Confirm Password */}
        </div>

        {/* Submission Button */}
        <button type="submit" disabled={isLoading} className="submit-btn">
          Mettre à jour le profil
        </button>
      </form>
    </div>
  );
};

export default ProviderUpdateForm;
