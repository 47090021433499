import React, { useState, useEffect } from 'react';
import { momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/fr';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import { API_GATEWAY_URL } from '../../../src/constants.js';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Checkbox,
    MenuItem,
    Select,
    TextField,
    InputLabel,
    FormGroup,
    FormLabel,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

moment.locale('fr');
const localizer = momentLocalizer(moment);

const CTA = ({ CTAMessage, user }) => {
    const [open, setOpen] = useState(false);
    const [events, setEvents] = useState([]);
    const [availabilities, setAvailabilities] = useState([]);
    const [newEvent, setNewEvent] = useState({
        start: dayjs(),
        end: dayjs(),
        partOfDay: 'full-day',
        recurrence: { days: [], frequency: 'weekly' }
    });
    const [isRecurring, setIsRecurring] = useState(false);
    const [isRecurringOptions, setIsRecurringOptions] = useState(['weekly', 'biweekly']);
    const [dateError, setDateError] = useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setNewEvent({
            start: dayjs(),
            end: dayjs(),
            partOfDay: 'full-day',
            recurrence: { days: [], frequency: '' }
        });
        setIsRecurring(false);
    };

    const generateRecurringEvents = (event) => {
        const { start, end, part_of_day, recurrence } = event;
        const eventStart = moment(start).startOf('day');
        const eventEnd = moment(end).endOf('day');
        const title = "Disponible";
        const events = [];
        const daysOfWeek = recurrence.days;
    
        let shouldSkip = false;
    
        while (eventStart.isBefore(eventEnd)) {
            if (daysOfWeek.includes(eventStart.day())) {
                if (!shouldSkip) {
                const eventDetails = {
                    title,
                    start: eventStart.clone().startOf('day').toDate(),
                    end: eventStart.clone().endOf('day').toDate(),
                    allDay: part_of_day === 'full-day'
                };
        
                if (part_of_day === 'morning') {
                    eventDetails.start = eventStart.clone().set({ hour: 8, minute: 0 }).toDate();
                    eventDetails.end = eventStart.clone().set({ hour: 12, minute: 0 }).toDate();
                } else if (part_of_day === 'afternoon') {
                    eventDetails.start = eventStart.clone().set({ hour: 13, minute: 0 }).toDate();
                    eventDetails.end = eventStart.clone().set({ hour: 17, minute: 0 }).toDate();
                }
        
                events.push(eventDetails);
                }
            }
        
            eventStart.add(1, 'day');
    
            if (eventStart.week() !== moment(start).week() && eventStart.isoWeekday() === 1) {
                if (shouldSkip) {
                    shouldSkip = false;
                } else if (recurrence.frequency === 'biweekly') {
                    shouldSkip = true;
                    eventStart.add(0, 'week');
                }
            }
        }
        return events;
    };
    const generateSingleDayEvents = (event) => {
        const { start, end, part_of_day } = event;
        const eventStart = moment(start);
        const eventEnd = moment(end);
        const title = "Disponible";
        const events = [];

        while (eventStart.isBefore(eventEnd) || eventStart.isSame(eventEnd, 'day')) {
            if (part_of_day === 'morning') {
                events.push({
                title,
                start: eventStart.clone().set({ hour: 8, minute: 0 }).toDate(),
                end: eventStart.clone().set({ hour: 12, minute: 0 }).toDate(),
                allDay: false
                });
            } else if (part_of_day === 'afternoon') {
                events.push({
                title,
                start: eventStart.clone().set({ hour: 13, minute: 0 }).toDate(),
                end: eventStart.clone().set({ hour: 17, minute: 0 }).toDate(),
                allDay: false
                });
            } else {
                events.push({
                title,
                start: eventStart.clone().startOf('day').toDate(),
                end: eventStart.clone().endOf('day').toDate(),
                allDay: true
                });
            }
            eventStart.add(1, 'day');
        }

        return events;
    };

    const fetchEvents = async () => {
        try {
            const response = await axios.get(`${API_GATEWAY_URL}/availabilities/`, {
                headers: { Authorization: `Bearer ${user.access_token}` },
            });
            const allEvents = response.data.flatMap(event => {
                if (event.recurrence && (event.recurrence.frequency === 'weekly' || event.recurrence.frequency === 'biweekly')) {
                return generateRecurringEvents(event);
                } else {
                return generateSingleDayEvents(event);
                }
            });
            setEvents(allEvents);
            setAvailabilities(response.data.sort((a, b) => new Date(b.start) - new Date(a.start)));
        } catch (error) {
            console.error('Failed to fetch events', error);
        }
    };

    useEffect(() => {
    fetchEvents();
    }, [user]);

    useEffect(() => {
        const start = moment(newEvent.start);
        const end = moment(newEvent.end);
        const today = moment().startOf('day');

        if (start.isBefore(today)) {
            setDateError("La date de début ne peut pas être antérieure à aujourd'hui.");
        } else if (end.isBefore(start)) {
            setDateError("La date de fin ne peut pas être antérieure à la date de début.");
        } else {
            setDateError('');
        }

        setIsRecurringOptions(['weekly', 'biweekly']);

        if (newEvent.start !== '' && newEvent.end !== '') {
            setIsRecurring(true);
        } else {
            setIsRecurring(false);
        }
    }, [newEvent.start, newEvent.end]);

    const handleAddEvent = async () => {
        if (dateError) {
            Swal.fire('Erreur', dateError, 'error');
            return;
        }

        Swal.fire({
            title: 'Ajout de la disponibilité...',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        try {
            let startDate = new Date(newEvent.start);
            let endDate = new Date(newEvent.end);
            endDate.setHours(23, 59, 59, 999);

            const payload = {
                start: startDate.toISOString(),
                end: endDate.toISOString(),
                part_of_day: newEvent.partOfDay,
                allDay: newEvent.partOfDay === 'full-day',
                recurrence: isRecurring ? newEvent.recurrence : { days: [], frequency: '' }
            };
            await axios.post(`${API_GATEWAY_URL}/availabilities/`, payload, {
                headers: { Authorization: `Bearer ${user.access_token}` },
            });

            fetchEvents();

            setNewEvent({
                start: dayjs(),
                end: dayjs(),
                partOfDay: 'full-day',
                recurrence: { days: [], frequency: '' }
            });
            setIsRecurring(false);
            Swal.fire('Succès', 'La disponibilité a été ajoutée.', 'success');
        } catch (error) {
            Swal.fire('Erreur', 'Échec de l\'ajout de la disponibilité.', 'error');
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setNewEvent((prevEvent) => ({
                ...prevEvent,
                recurrence: {
                    ...prevEvent.recurrence,
                    days: checked ? [...prevEvent.recurrence.days, parseInt(value)] : prevEvent.recurrence.days.filter((day) => day !== parseInt(value)),
                },
            }));
        } else {
            setNewEvent((prevEvent) => ({
                ...prevEvent,
                [name]: value,
            }));
        }
    };

    return (
        <>
            <Button
                size='large'
                onClick={handleOpen}
                className="
                    group
                    relative
                    inline-flex
                    items-center
                    overflow-hidden
                    px-8 py-2
                    text-white
                    focus:outline-none
                    focus:ring-2"
                sx={{
                    backgroundColor: 'var(--clr-secondary-400)',
                    borderRadius: 'var(--br-button)',
                    position: 'relative',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: '-120%',
                        width: '200%',
                        height: '100%',
                        background: 'linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.2), transparent)',
                        transition: 'left 0.4s',
                    },
                    '&:hover': {
                        backgroundColor: 'var(--clr-secondary-400)',
                    },
                    '&:hover::before': {
                        left: '100%',
                    },
                }}
            >
                <span className="absolute -end-full transition-all group-hover:end-3">
                    <svg
                        className="size-5 rtl:rotate-180"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.5"
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                    </svg>
                </span>
                <span
                    className="transition-all group-hover:me-8"
                    style={{
                        color: 'var(--clr-white)',
                        fontFamily: 'var(--ff-button)',
                        fontWeight: 'var(--fw-semibold)',
                        fontSize: 'var(--fs-btn-extra-small)',
                    }}
                >
                    {CTAMessage}
                </span>
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
                <DialogTitle>Ajouter une disponibilité</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="normal">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Date de début"
                                value={newEvent.start}
                                onChange={(date) => setNewEvent({ ...newEvent, start: date })}
                                renderInput={(params) => <TextField {...params} error={Boolean(dateError)} helperText={dateError} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Date de fin"
                                value={newEvent.end}
                                onChange={(date) => setNewEvent({ ...newEvent, end: date })}
                                renderInput={(params) => <TextField {...params} error={Boolean(dateError)} helperText={dateError} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <FormLabel>Partie de la journée</FormLabel>
                        <RadioGroup
                            row
                            name="partOfDay"
                            value={newEvent.partOfDay}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="morning" control={<Radio />} label="Matin" />
                            <FormControlLabel value="afternoon" control={<Radio />} label="Après-midi" />
                            <FormControlLabel value="full-day" control={<Radio />} label="Journée entière" />
                        </RadioGroup>
                    </FormControl>
                    {isRecurring && (
                        <>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="recurrence-frequency-label">Fréquence</InputLabel>
                                <Select
                                    labelId="recurrence-frequency-label"
                                    name="recurrence.frequency"
                                    value={newEvent.recurrence.frequency}
                                    onChange={handleChange}
                                >
                                    {isRecurringOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option === 'weekly' ? 'Hebdomadaire' : 'Bihebdomadaire'}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl component="fieldset" fullWidth margin="normal">
                                <FormLabel component="legend">Jours de la semaine</FormLabel>
                                <FormGroup row>
                                    {[0, 1, 2, 3, 4, 5, 6].map((day) => (
                                        <FormControlLabel
                                            key={day}
                                            control={
                                                <Checkbox
                                                    checked={newEvent.recurrence.days.includes(day)}
                                                    onChange={handleChange}
                                                    name="recurrence.days"
                                                    value={day}
                                                />
                                            }
                                            label={moment().weekday(day).format('dddd')}
                                        />
                                    ))}
                                </FormGroup>
                            </FormControl>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} sx={{
                        fontWeight: 'var(--fw-medium)',
                        color: 'var(--clr-danger-500)',
                        '&:hover': {
                            backgroundColor: 'var(--clr-danger-400)',
                            color: 'var(--clr-white)',
                        },
                    }}>
                        Annuler
                    </Button>
                    <Button onClick={handleAddEvent} variant="contained" sx={{
                        fontWeight: 'var(--fw-medium)',
                        bgcolor: 'var(--clr-success-400)',
                        '&:hover': {
                            backgroundColor: 'var(--clr-success-500)',
                        },
                    }}>
                        Sauvegarder
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CTA;

