import React from 'react'

const Badge = ({badgeTitle}) => {
    return (
        <div className='flex place-content-center shadow-sm'
            style={{
                fontFamily: 'var(--ff-button)',
                fontSize: '0.675em',
                fontWeight: 'regular',
                color: 'var(--clr-primary-400)',
                border: '1.4px solid var(--clr-primary-400)',
                borderRadius: '1.5rem',
                padding: '.6rem 1rem',
            }}
        >
            {badgeTitle}
        </div>
    )
}

export default Badge;