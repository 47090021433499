import React, { useState, useEffect } from "react";
import { API_GATEWAY_URL } from "../constants";
import { useAuth } from "./AuthContext";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "./Layout";
import ProviderCard from "./ProviderCard";
import styles from "../styles/WorkerUpdateForm.css";
import Select from "react-select";
import LoadingSpinner from "./LoadingSpinner";

import Swal from "sweetalert2";

const ProviderWorkerUpdateForm = () => {
  const { user } = useAuth();
  const [phoneLocalNumber, setPhoneLocalNumber] = useState("");
  const [address, setAddress] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [bio, setBio] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [photo, setPhoto] = useState(null);
  const [removePhoto, setRemovePhoto] = useState(false);
  const [photoUrl, setPhotoUrl] = useState("");
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { workerId } = useParams();
  const [providerData, setProviderData] = useState(null); // State to store provider data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [provider, setProvider] = useState("");
  const [expertiseLevels, setExpertiseLevels] = useState({});
  const [allExpertises, setAllExpertises] = useState([]);
  const [selectedExpertises, setSelectedExpertises] = useState([]);

  const validateEmail = (email) =>
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email.toLowerCase()
    );
  const validatePhoneNumber = (number) => number.length >= 8;
  const validatePassword = (password) => password.length >= 8;
  const navigate = useNavigate();

  function getLevelFullText(levelCode) {
    const levelMap = {
      JR: "Junior",
      CF: "Confirmé", // "CF" mapped to "Confirmé"
      SR: "Senior",
    };
    return levelMap[levelCode] || levelCode; // Return the mapped value or the original code if not found
  }

  useEffect(() => {
    const fetchDataSequentially = async () => {
      // First, fetch expertises
      const fetchExpertises = async () => {
        try {
          const response = await fetch(`${API_GATEWAY_URL}/expertises/`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
            },
          });
          if (response.ok) {
            const allExpertisesData = await response.json();
            setAllExpertises(
              allExpertisesData.map((exp) => ({
                expertise_id: exp.id,
                expertise: exp.name,
                level: "JR", // Default level for all expertises
              }))
            );
            setExpertiseLevels(
              allExpertisesData.reduce((acc, curr) => {
                acc[curr.id] = "JR"; // Default level for all expertises
                return acc;
              }, {})
            );
          } else {
            throw new Error("Failed to fetch expertises");
          }
        } catch (error) {
          console.error("Error fetching all expertises:", error);
          setApiError("Unable to load expertises.");
        }
      };

      // Second, fetch worker data
      const fetchWorkerData = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(
            `${API_GATEWAY_URL}/worker-by-provider/${workerId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.access_token}`,
              },
            }
          );

          if (response.ok) {
            const data = await response.json();
            const workerData = data[0]; // Assuming data is an array and you take the first item
            setEmail(workerData.email || "");
            setPhoneLocalNumber(workerData.phone_number || "");
            setAddress(workerData.address || "");
            setLinkedin(workerData.linkedin || "");
            setBio(workerData.bio || "");
            setPhotoUrl(workerData.photo || "");
            setFirstName(workerData.first_name || "");
            setLastName(workerData.last_name || "");
            setProvider(
              workerData.provider ? workerData.provider.name : "No Provider"
            );

            const expertiseData = workerData.association_expertises.map(
              (e) => ({
                expertise_id: e.expertise_id,
                expertise: e.expertisename,
                level: e.level,
              })
            );
            setSelectedExpertises(expertiseData);
            setExpertiseLevels(
              expertiseData.reduce(
                (acc, exp) => ({
                  ...acc,
                  [exp.expertise_id]: exp.level,
                }),
                {}
              )
            );

            console.log("Fetched worker data with levels:", expertiseData);
          } else {
            throw new Error("Failed to fetch worker data");
          }
        } catch (error) {
          setApiError(error.message);
        } finally {
          setIsLoading(false);
        }
      };

      // Executing fetch operations sequentially
      await fetchExpertises(); // First fetch expertises
      await fetchWorkerData(); // Then fetch worker data after expertises have been fetched
      setLoading(false);
    };

    if (user && user.access_token && workerId) {
      fetchDataSequentially(); // Call the function that handles the sequential data fetch
    }
  }, [user.access_token, workerId]); // Dependencies array includes user.access_token and workerId

  const handleExpertiseChange = (selectedOptions) => {
    setSelectedExpertises(selectedOptions || []);
  };

  const handleLevelChange = (expertiseId, newLevel) => {
    setExpertiseLevels((prevLevels) => ({
      ...prevLevels,
      [expertiseId]: newLevel,
    }));

    // Update the selectedExpertises to reflect this change for consistent UI display
    setSelectedExpertises((prevExpertises) =>
      prevExpertises.map((exp) =>
        exp.expertise_id === expertiseId ? { ...exp, level: newLevel } : exp
      )
    );
  };

  const checkErrors = () => {
    const newErrors = {};
    if (!validateEmail(email)) {
      newErrors.email = "Email invalide.";
    }
    if (password && !validatePassword(password)) {
      newErrors.password =
        "Le mot de passe doit contenir au moins 8 caractères.";
    }
    if (password !== confirmPassword) {
      newErrors.confirmPassword = "Les mots de passe ne correspondent pas.";
    }
    if (!validatePhoneNumber(phoneLocalNumber)) {
      newErrors.phoneLocalNumber = "Numéro de téléphone invalide.";
    }
    if (!address) {
      newErrors.address = "Adresse requise.";
    }
    if (
      linkedin &&
      !/^(https?:\/\/)?([\w]+\.)?linkedin\.com\/.*$/.test(linkedin)
    ) {
      newErrors.linkedin = "URL LinkedIn invalide.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const fetchProviderData = async () => {
    if (user && user.access_token) {
      setLoading(true);
      try {
        const response = await fetch(`${API_GATEWAY_URL}/provider-profile/`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch provider data.");
        }

        const providerData = await response.json();
        setProviderData(providerData);
      } catch (err) {
        setError(err.message);
      } finally {
      }
    } else {
      setError("User not authenticated.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProviderData();
  }, [user]);

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <div style={styles.error}>Error: {error}</div>;
  }

  // Gestion de la soumission du formulaire
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!checkErrors()) {
      Swal.fire({
        title: "Erreur!",
        text: "Veuillez corriger les erreurs avant de soumettre.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    const expertiseData = selectedExpertises.map((exp) => ({
      expertise_id: exp.expertise_id,
      level: expertiseLevels[exp.expertise_id],
    }));

    const payload = {
      phone_number: phoneLocalNumber,
      address: address,
      linkedin: linkedin,
      bio: bio,
      association_expertises: expertiseData,
      // Ajouter d'autres champs si nécessaire
    };
    console.log("Submitting expertise data:", expertiseData); // Verify the structure here
    console.log("Final payload being sent to server:", JSON.stringify(payload));

    try {
      const response = await fetch(
        `${API_GATEWAY_URL}/worker-by-provider/${workerId}/`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        Swal.fire({
          title: "Succès!",
          text: "Profil mis à jour avec succès.",
          icon: "success",
          confirmButtonText: "Super",
        }).then(() => navigate("/provider-resources"));
      } else {
        throw new Error("Échec de la mise à jour du profil");
      }
    } catch (error) {
      Swal.fire({
        title: "Erreur!",
        text: "Erreur lors de la communication avec le serveur.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  // Rendu du formulaire
  return (
    <div className="PWUContainer">
      {providerData && <ProviderCard providerData={providerData} />}
      <form onSubmit={handleSubmit} className="PWUForm">
        <div className="PWUFormSection">
          <h2>Information de la ressource</h2>
          <div className="PWUFormField">
            <label className="PWULabel">Prénom:</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="Prénom"
              className="PWUInput"
              disabled={isLoading}
            />
            {errors.firstName && (
              <div className="PWUError">{errors.firstName}</div>
            )}
          </div>
          <div className="PWUFormField">
            <label className="PWULabel">nom:</label>

            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Nom"
              className="PWUInput"
              disabled={isLoading}
            />
            {errors.firstName && (
              <div className="PWUError">{errors.firstName}</div>
            )}
          </div>
          <div className="PWUFormField">
            <label className="PWULabel">Provider:</label>
            <input
              type="text"
              value={providerData.name}
              placeholder="Provider"
              className="PWUInput"
              disabled={true} // Makes the field read-only
            />
            {errors.provider && (
              <div className="PWUError">{errors.provider}</div>
            )}
          </div>
          <div className="PWUFormField">
            <label className="PWULabel">Numero:</label>
            <input
              type="text"
              value={phoneLocalNumber}
              onChange={(e) => setPhoneLocalNumber(e.target.value)}
              placeholder="Numéro de téléphone"
              className="PWUInput"
              disabled={isLoading}
            />
            {errors.phoneLocalNumber && (
              <div className="PWUError">{errors.phoneLocalNumber}</div>
            )}
          </div>
          <div className="PWUFormField">
            <label className="PWULabel">Address:</label>

            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Adresse"
              className="PWUInput"
              disabled={isLoading}
            />
            {errors.address && <div className="PWUError">{errors.address}</div>}
          </div>
          <Select
            isMulti
            options={allExpertises}
            getOptionLabel={(option) =>
              `${option.expertise} (${getLevelFullText(
                expertiseLevels[option.expertise_id] || "JR"
              )})`
            }
            getOptionValue={(option) => option.expertise_id}
            onChange={handleExpertiseChange}
            value={selectedExpertises.map((se) => ({
              ...se,
              label: `${se.expertise} (${getLevelFullText(se.level)})`, // Ensure label reflects the current state
              value: se.expertise_id,
            }))}
            placeholder="Select Expertises"
          />
          {selectedExpertises.map((expertise) => (
            <div
              key={expertise.expertise_id}
              style={styles.expertiseLevelContainer}
            >
              <div style={styles.expertiseLabel}>
                {`${expertise.expertise} (${getLevelFullText(
                  expertise.level
                )})`}
                :
              </div>
              <select
                style={styles.levelSelect}
                value={expertiseLevels[expertise.expertise_id]}
                onChange={(e) =>
                  handleLevelChange(expertise.expertise_id, e.target.value)
                }
              >
                <option value="JR">Junior</option>
                <option value="CF">Confirmé</option>
                <option value="SR">Senior</option>
              </select>
            </div>
          ))}
          <div className="PWUFormField">
            <label className="PWULabel">linkedin:</label>

            <input
              type="text"
              value={linkedin}
              onChange={(e) => setLinkedin(e.target.value)}
              placeholder="LinkedIn"
              className="PWUInput"
              disabled={isLoading}
            />
            {errors.linkedin && (
              <div className="PWUError">{errors.linkedin}</div>
            )}
          </div>
          <div className="PWUFormField">
            <label className="PWULabel">Bio:</label>

            <textarea
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              placeholder="Bio"
              className="PWUTextArea"
              disabled={isLoading}
            />
            {errors.bio && <div className="PWUError">{errors.bio}</div>}
          </div>
        </div>
        <div className="PWUFormSection">
          <h2>Information du compte</h2>

          <div className="PWUFormField">
            <label className="PWULabel">Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="PWUInput"
              disabled
            />
            {errors.email && <div className="PWUError">{errors.email}</div>}
          </div>
          <div className="PWUFormField">
            <label className="PWULabel">mot de passe :</label>

            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Nouveau mot de passe"
              className="PWUInput"
              disabled={isLoading}
            />
            {errors.password && (
              <div className="PWUError">{errors.password}</div>
            )}
          </div>
          <div className="PWUFormField">
            <label className="PWULabel">confirmer mot de passe :</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirmez le mot de passe"
              className="PWUInput"
              disabled={isLoading}
            />
            {errors.confirmPassword && (
              <div className="PWUError">{errors.confirmPassword}</div>
            )}
          </div>
        </div>

        <button type="submit" className="PWUButton" disabled={isLoading}>
          Mettre à jour le profil
        </button>
      </form>
    </div>
  );
};

export default ProviderWorkerUpdateForm;
