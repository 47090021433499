import React, { useState ,useEffect} from 'react';
import '../../index.css';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';

import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { API_GATEWAY_URL } from '../../constants.js';
import axios from 'axios';





const AvailabilityBtn = ({user}) => {
    const [open, setOpen] = useState(false);
    const [activeCard, setActiveCard] = useState(null);

    const handleOpen = () => setOpen(!open);
    const handleClose = () => setOpen(false);
    useEffect(() => {
        const fetchAvailability = async () => {
            try {
                const response = await axios.get(`${API_GATEWAY_URL}/freelancer_availability_hiring/`, {
                    headers: { Authorization: `Bearer ${user.access_token}` },
                });
                
                const savedChoice = response.data.choice;
                if (savedChoice === 'direct') {
                    setActiveCard(0);
                } else if (savedChoice === 'indirect') {
                    setActiveCard(1);
                } else {
                    setActiveCard(2);
                }
            } catch (error) {
                console.error('Error fetching availability:', error);
            }
        };
    
        fetchAvailability();
    }, [user.access_token]);
    /*const handleConfirm = () => {
        // Add your confirm action here
        setOpen(false);
    };*/
    const handleConfirm = async () => {
        try {
            const choice = activeCard === 0 ? 'direct' : activeCard === 1 ? 'indirect' : 'unavailable';
            const response = await axios.post(
                `${API_GATEWAY_URL}/freelancer_availability_hiring/`,
                { choice },
                { headers: { Authorization: `Bearer ${user.access_token}` } }
            );
            if (response.data.status === 'success') {
                setOpen(false);
            }
        } catch (error) {
            console.error('Error saving availability:', error);
        }
    };

    const handleCardClick = (index) => {
        console.log('Card clicked:', index); 
        setActiveCard(index);
    };

    return (
        <>
            <div
                className='flex flex-row items-center gap-2 relative cursor-pointer'
                onClick={handleOpen}
            >
                {/*<div className='hidden lg:block text-sm text-black'>
                    {activeCard === 0 ? 'Ouvert aux offres directes' : activeCard === 1 ? 'Ouvert aux offres indirectes' : 'Indisponible pour des emplois'}
                </div>*/}
                <div>
                    <WorkOutlineIcon className='relative' style={{ color: 'var(--clr-neutral-500)' }} />
                    <div
                        className='absolute bottom-0 right-0'
                        style={{
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            backgroundColor: activeCard === 0 ?
                                'var(--clr-success-400)' : activeCard === 1 ?
                                    'var(--clr-warning-400)' : 'var(--clr-danger-400)',
                        }}
                    >
                    </div>
                </div>
            </div>

            <Dialog open={open} onClose={handleClose}
                PaperProps={{
                    sx: {
                        borderRadius: 0,
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        maxWidth: 'md',
                        maxHeight: 'lg',
                    }
                }}
            >
                <DialogTitle
                    sx={{
                        fontFamily: "var(--ff-heading)",
                        fontWeight: "var(--fw-medium)",
                        fontSize: "1.2rem",
                    }}
                >
                    Mise à jour de la disponibilité pour des emplois
                </DialogTitle>
                <DialogContent>
                    <div
                        className="flex flex-row gap-2  my-3"
                        style={{ fontFamily: "var(--ff-body)" }}
                    >
                        <div
                            className={`card flex flex-col gap-3 rounded-lg py-4 px-6`}
                            onClick={() => handleCardClick(0)}
                            style={{
                                minWidth: '240px',
                                minHeight: '240px',
                                cursor: 'pointer',
                                border: activeCard === 0 ? '2px solid var(--clr-primary-300)' : '2px solid var(--clr-neutral-200)',
                            }}
                        >
                            <div className="relative">
                                <WorkOutlineIcon
                                    style={{
                                        color: activeCard === 0 ? 'var(--clr-primary-500)' : 'var(--clr-neutral-400)',
                                    }}
                                />
                                <div
                                    className='absolute bottom-0 left-4'
                                    style={{
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%',
                                        backgroundColor: 'var(--clr-success-400)'
                                    }}
                                >
                                </div>
                            </div>
                            <div>
                                <h6 className={`text-sm text-gray-500 ${activeCard === 0 ? 'text-primary' : ''}`}>
                                    Ouvert aux offres directes
                                </h6>
                                <div
                                    style={{
                                        fontSize: '0.85rem',
                                        maxWidth: '200px',
                                    }}
                                >
                                    Je suis activement à la recherche d'un nouveau poste de travail à distance, et j'accepterai toutes les missions qui me conviennent.
                                </div>
                            </div>
                        </div>
                        <div
                            className={`card flex flex-col gap-3 rounded-lg py-4 px-6`}
                            onClick={() => handleCardClick(1)}
                            style={{
                                minWidth: '240px',
                                minHeight: '240px',
                                cursor: 'pointer',
                                border: activeCard === 1 ? '2px solid var(--clr-primary-300)' : '2px solid var(--clr-neutral-200)',
                            }}
                        >
                            <div className="relative">
                                <WorkOutlineIcon
                                    style={{
                                        color: activeCard === 1 ? 'var(--clr-primary-500)' : 'var(--clr-neutral-400)',
                                    }}
                                />
                                <div
                                    className='absolute bottom-0 left-4'
                                    style={{
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%',
                                        backgroundColor: 'var(--clr-warning-400)'
                                    }}
                                >
                                </div>
                            </div>
                            <div>
                                <h6 className={`text-sm text-gray-500 ${activeCard === 1 ? 'text-primary' : ''}`}>
                                    Ouvert aux offres indirectes
                                </h6>
                                <div
                                    style={{
                                        fontSize: '0.85rem',
                                        maxWidth: '200px',
                                    }}
                                >
                                    Je suis activement à la recherche d'un nouveau poste de travail à distance, mais je sélectionnerai indirectement les missions qui me conviennent.
                                </div>
                            </div>
                        </div>
                        <div
                            className={`card flex flex-col  gap-3 rounded-lg py-4 px-6`}
                            onClick={() => handleCardClick(2)}
                            style={{
                                minWidth: '240px',
                                minHeight: '240px',
                                cursor: 'pointer',
                                border: activeCard === 2 ? '2px solid var(--clr-primary-300)' : '2px solid var(--clr-neutral-200)',
                            }}
                        >
                            <div className="relative">
                                <WorkOutlineIcon
                                    style={{
                                        color: activeCard === 2 ? 'var(--clr-primary-500)' : 'var(--clr-neutral-400)',
                                    }}
                                />
                                <div
                                    className='absolute bottom-0 left-4'
                                    style={{
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%',
                                        backgroundColor: 'var(--clr-danger-400)'
                                    }}
                                >
                                </div>
                            </div>
                            <div>
                                <h6 className={`text-sm text-gray-500 ${activeCard === 2 ? 'text-primary' : ''}`}>
                                    Indisponible pour des emplois
                                </h6>
                                <div
                                    style={{
                                        fontSize: '0.85rem',
                                        maxWidth: '200px',
                                    }}
                                >
                                    Je suis actuellement indisponible et ne cherche pas de nouveau poste de travail à distance.
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} sx={{
                        fontWeight: 'var(--fw-medium)',
                        color: 'var(--clr-danger-500)',
                        '&:hover': {
                            backgroundColor: 'var(--clr-danger-400)',
                            color: 'var(--clr-white)',
                        },
                    }}>
                        Annuler
                    </Button>
                    <Button onClick={handleConfirm} variant="contained" sx={{
                        fontWeight: 'var(--fw-medium)',
                        bgcolor: 'var(--clr-success-400)',
                        '&:hover': {
                            backgroundColor: 'var(--clr-success-500)',
                        },
                    }}>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AvailabilityBtn;

