import React, { useState, useEffect } from 'react';
import { useAuth } from '../../components/AuthContext';
import { useForm } from "react-hook-form"
import { API_GATEWAY_URL } from '../../constants';
import '../../index.css';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
    Dialog, DialogTitle, DialogContent,
    Button, TextField, Checkbox, FormControlLabel
} from '@mui/material';
import LoadingSpinner from '../../components/LoadingSpinner'; 
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { TroubleshootOutlined } from '@mui/icons-material';



const JobForm = ({user, handleCancel, setFreelancerWorkExperience }) => {
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false);

    const onSubmit = async (data) => { // Make the function async
        // Check if endDate is null
        const endDate = data.endDate ? dayjs(data.endDate).format('YYYY-MM-DD') : null;
        const newData = {
            description_de_poste: data.description,
            date_debut: dayjs(data.startDate).format('YYYY-MM-DD'),
            date_fin: endDate,
            nom_entreprise: data.companyName,
            poste_dans_entreprise: data.jobTitle,
            est_poste_actuel: isCurrentlyWorking,
        };
        try {
            // Send the updated data back to the backend
            await fetch(`${API_GATEWAY_URL}/experiences/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`
                },
                body: JSON.stringify(newData)
            });
            // Update the state with the new data
            setFreelancerWorkExperience(prevState => [...prevState, newData]);
        } catch (error) {
            console.error('Error:', error);
        }
        handleCancel();
    };

    const handleCheckboxChange = (event) => {
        setIsCurrentlyWorking(event.target.checked);
    };

    return (
        <form 
            className='flex flex-col gap-2'
            onSubmit={handleSubmit(onSubmit)}
        >
            <TextField
                label="Titre d'emploi"
                {...register('jobTitle', { required: 'Le titre d\'emploi est requis' })}
                error={!!errors.jobTitle}
                helperText={errors.jobTitle ? errors.jobTitle.message : ''}
                margin="normal"
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <div className="w-full flex flex-col gap-2 md:flex-row md:gap-0 md:justify-between md:items-center">
                        <DatePicker
                            views={['day', 'month', 'year']}
                            label="Date de début"
                            value={watch('startDate')}
                            onChange={(newValue) => setValue('startDate', newValue)}
                            required
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...register('startDate', { required: 'La date de début est requise' })}
                                    error={!!errors.startDate}
                                    helperText={errors.startDate ? errors.startDate.message : ''}
                                    margin="normal"
                                    fullWidth
                                />
                            )}
                        />
                        <DatePicker
                            views={['day','month','year']}
                            label="Date de fin"
                            value={watch('endDate')}
                            onChange={(newValue) => setValue('endDate', newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...register('endDate', {
                                        required: !isCurrentlyWorking && 'La date de fin est requise',
                                    })}
                                    error={!!errors.endDate}
                                    helperText={errors.endDate ? errors.endDate.message : ''}
                                    margin="normal"
                                    fullWidth
                                    disabled={isCurrentlyWorking}
                                />
                            )}
                        />
                    </div>
                </DemoContainer>
            </LocalizationProvider>
            <FormControlLabel
                control={
                    <Checkbox 
                        {...register('currentlyWorking')}
                        onChange={handleCheckboxChange}
                    />
                }
                label="Je suis actuellement un travailleur dans ce poste"
            />
            <TextField
                label="Nom de l'entreprise"
                {...register('companyName', { required: 'Le nom de l\'entreprise est requis' })}
                error={!!errors.companyName}
                helperText={errors.companyName ? errors.companyName.message : ''}
                margin="normal"
            />
            <div className="flex flex-col items-end">
                <TextField
                    fullWidth
                    label="Description"
                    {...register('description', { required: 'La description est requise' })}
                    error={!!errors.description}
                    helperText={errors.description ? errors.description.message : ''}
                    margin="normal"
                />
            </div>
            <div className="flex items-center gap-3 mt-3">
                <Button
                    variant="contained"
                    type="submit"
                    color='success'
                    sx={{
                        fontWeight: 'var(--fw-semibold)',
                        fontSize: '.8rem',
                    }}
                >
                    Sauvegarder
                </Button>
                <Button 
                    variant="text"
                    color='error'
                    sx={{
                        fontWeight: 'var(--fw-semibold)',
                        fontSize: '.8rem',
                    }}
                    onClick={handleCancel}
                >
                    Annuler
                </Button>
            </div>
        </form>
    );
}


const ProjectForm = ({ user, handleCancel, setFreelancerProjects }) => {
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false);

    const onSubmit = async (data) => {
        // Check if endDate is null
        const end_date = data.end_date ? dayjs(data.end_date).format('YYYY-MM-DD') : null;
        const newData = {
            title: data.title,
            description: data.description,
            start_date: dayjs(data.start_date).format('YYYY-MM-DD'),
            end_date: end_date,
            en_cours: end_date === null ? true : false,
            role_specifique: data.role_specifique,
        };
        console.log("new Data", newData);
        try {
            // Send the updated data back to the backend
            await fetch(`${API_GATEWAY_URL}/projects/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`
                },
                body: JSON.stringify(newData)
            });
            // Update the state with the new data
            setFreelancerProjects(prevState => [...prevState, newData]);
        } catch (error) {
            console.error('Error:', error);
        }
        handleCancel();
    };

    const handleCheckboxChange = (event) => {
        setIsCurrentlyWorking(event.target.checked);
    };

    return (
        <form 
            className='flex flex-col gap-2'
            onSubmit={handleSubmit(onSubmit)}
        >
            <TextField
                label="Nom du projet"
                {...register('title', { required: 'Le nom du projet est requis' })}
                error={!!errors.title}
                helperText={errors.title ? errors.title.message : ''}
                margin="normal"
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <div className="w-full flex flex-col gap-2 md:flex-row md:gap-0 md:justify-between md:items-center">
                        <DatePicker
                            views={['day', 'month', 'year']}
                            label="Date de début"
                            value={watch('start_date')}
                            onChange={(newValue) => setValue('start_date', newValue)}
                            required
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...register('start_date', { required: 'La date de début est requise' })}
                                    error={!!errors.start_date}
                                    helperText={errors.start_date ? errors.start_date.message : ''}
                                    margin="normal"
                                    fullWidth
                                />
                            )}
                        />
                        <DatePicker
                            views={['day','month','year']}
                            label="Date de fin"
                            value={watch('end_date')}
                            onChange={(newValue) => setValue('end_date', newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...register('end_date', {
                                        required: !isCurrentlyWorking && 'La date de fin est requise',
                                    })}
                                    error={!!errors.end_date}
                                    helperText={errors.end_date ? errors.end_date.message : ''}
                                    margin="normal"
                                    fullWidth
                                    disabled={isCurrentlyWorking}
                                />
                            )}
                        />
                    </div>
                </DemoContainer>
            </LocalizationProvider>
            <FormControlLabel
                control={
                    <Checkbox 
                        {...register('currentlyWorking')}
                        onChange={handleCheckboxChange}
                    />
                }
                label="Je suis actuellement entrain de travailler sur ce projet"
            />
            <div className="flex flex-col items-end">
                <TextField
                    fullWidth
                    label="Description"
                    {...register('description', { required: 'La description est requise' })}
                    error={!!errors.description}
                    helperText={errors.description ? errors.description.message : ''}
                    margin="normal"
                />
            </div>
            <div className="flex flex-col items-end">
                <TextField
                    fullWidth
                    label="Role spécifique"
                    {...register('role_specifique', { required: 'Le role est requis' })}
                    error={!!errors.role_specifique}
                    helperText={errors.role_specifique ? errors.role_specifique.message : ''}
                    margin="normal"
                />
            </div>

            <div className="flex items-center gap-3 mt-3">
                <Button
                    variant="contained"
                    type="submit"
                    color='success'
                    sx={{
                        fontWeight: 'var(--fw-semibold)',
                        fontSize: '.8rem',
                    }}
                >
                    Sauvegarder
                </Button>
                <Button 
                    variant="text"
                    color='error'
                    sx={{
                        fontWeight: 'var(--fw-semibold)',
                        fontSize: '.8rem',
                    }}
                    onClick={handleCancel}
                >
                    Annuler
                </Button>
            </div>
        </form>
    );
}

const AddJob = ({ user, open, handleClose, setFreelancerWorkExperience }) => {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Informations sur l'emploi</DialogTitle>
            <DialogContent>
                <JobForm 
                    user={user}
                    setFreelancerWorkExperience={setFreelancerWorkExperience}
                    handleCancel={handleClose}
                />
            </DialogContent>
        </Dialog>
    );
};

const AddProject = ({ open, handleClose, setFreelancerProjects }) => {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Informations sur le projet</DialogTitle>
            <DialogContent>
                <ProjectForm
                    user={useAuth().user}
                    handleCancel={handleClose}
                    setFreelancerProjects={setFreelancerProjects}
                />
            </DialogContent>
        </Dialog>
    );
};

const ProfessionalExperience = () => {
    const { user } = useAuth();
    const [freelancerWorkExperience, setFreelancerWorkExperience] = useState([]);
    const [freelancerProjects, setFreelancerProjects] = useState([]);
    const [openAddJob, setOpenAddJob] = useState(false);
    const [openAddProject, setOpenAddProject] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeSectionOne, setActiveSectionOne] = useState(1); 
    const [activeSectionTwo, setActiveSectionTwo] = useState(1);    
    
    useEffect(() => {
        const fetchFreelancerWorkExperience = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/experiences/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setFreelancerWorkExperience(data);
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerWorkExperience();
        }
    }, [user]);

    useEffect(() => {
        const fetchFreelancerProjects = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/projects/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    const projectsForm = data.map( projectInfos => {
                        return {
                            title: projectInfos.title,
                            start_date: projectInfos.start_date,
                            end_date: projectInfos.end_date,
                            currentlyWorking: projectInfos.en_cours,
                            role_specifique: projectInfos.role_specifique,
                            description: projectInfos.description,
                            technologie: projectInfos.technologie
                        }
                    })
                    console.log(projectsForm)
                    setFreelancerProjects(projectsForm);
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerProjects();
        }
    }, [user]);


    if (loading) {
        return <LoadingSpinner isLoading={true} />;
    };
    if (error) {
        return <div>Erreur: {error}</div>;
    };


    const handleButtonOneClick = () => {
        setActiveSectionOne(prevSection => prevSection === 0 ? 1 : 0);
    };
    const handleButtonTwoClick = () => {
        setActiveSectionTwo(prevSection => prevSection === 0 ? 1 : 0);
    };
    const handleClickOpenAddJob = () => {
        setOpenAddJob(true);
    };
    const handleCloseAddJob = () => {
        setOpenAddJob(false);
    };
    const handleClickOpenAddProject = () => {
        setOpenAddProject(true);
    }
    const handleCloseAddProject = () => {
        setOpenAddProject(false);
    }
    function handleDeleteJob(index) {
        console.log('Job deleted');
        let newArray = freelancerWorkExperience.filter(item => item.id !== index);
        setFreelancerWorkExperience(newArray);
    }
    function handleDeleteProject(index) {
        console.log('Project deleted');
    }


    return (
        <main 
            className="w-full flex flex-col gap-4 md:mt-4"
            style={{fontFamily: 'var(--ff-body)',}}
        >
            <h3 className='hidden md:block'>Experience Professionnel</h3>
            <div
                className='py-4 px-8 rounded-lg w-full border-2 border-gray-200'
            >
                <header
                    className='pb-3 pt-2 flex justify-between items-center'
                >
                    <h2 className='mb-2'>Travail</h2>
                    {
                        activeSectionOne ? (
                            <button 
                                className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100`}
                                onClick={handleButtonOneClick}
                                style={{
                                    width: '2rem',
                                    height: '2rem',
                                    border: '1px solid var(--clr-neutral-300)',
                                }}
                            >
                                <EditOutlinedIcon 
                                    className='-text--clr-secondary-500'
                                    style={{
                                        width: '1.2rem',
                                        height: '1.2rem',
                                    }}
                                />
                            </button>
                        ) : (
                            <button 
                                className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100`}
                                onClick={handleClickOpenAddJob}
                                style={{
                                    width: '2rem',
                                    height: '2rem',
                                    border: '1px solid var(--clr-secondary-500)',
                                }}
                            >
                                <span 
                                    style={{
                                        color: 'var(--clr-secondary-500)',
                                        fontSize: '1.6rem',
                                        fontWeight: 'var(--fw-semibold)',
                                    }}
                                >
                                    +
                                </span>
                            </button>
                        )
                    }
                </header>
                <AddJob user={user} open={openAddJob} handleClose={handleCloseAddJob} setFreelancerWorkExperience={setFreelancerWorkExperience} />
                {
                    freelancerWorkExperience.length > 0 ?  (
                        freelancerWorkExperience.map((job, index) => (
                            <section className='flex flex-col gap-2 mb-3'>
                                <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                                    <div 
                                        style={{
                                            fontFamily: 'var(--ff-body)',
                                            fontWeight: 'var(--fw-semibold)',
                                            fontSize: '1.2rem',
                                            color: 'var(--clr-primary-500)',
                                        }} 
                                    >
                                            {job.poste_dans_entreprise}
                                    </div>
                                    <div 
                                        style={{
                                            fontFamily: 'var(--ff-body)',
                                            fontWeight: 'var(--fw-medium)',
                                            fontSize: '1rem',
                                        }} 
                                    >
                                        {job.date_debut} <span><ArrowRightIcon style={{color: 'var(--clr-primary-500)'}}/></span> {job.est_poste_actuel ? 'Actuel' : job.date_fin}
                                    </div>
                                </div>
                                    <div 
                                        style={{
                                            fontFamily: 'var(--ff-body)',
                                            fontWeight: 'var(--fw-medium)',
                                            fontSize: '1rem',
                                            marginTop: '-.3rem',
                                            marginBottom: '.3rem',
                                        }} 
                                    >
                                        {job.nom_entreprise}
                                    </div>
                                    <div 
                                        style={{
                                            fontFamily: 'var(--ff-body)',
                                            fontWeight: 'var(--fw-regular)',
                                            fontSize: '.9rem',
                                        }} 
                                    >
                                        <ul className='list-disc list-inside'>
                                            <li>
                                                {
                                                    job.description_de_poste
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                    {
                                        !activeSectionOne ? (
                                            <Button 
                                                variant="text"
                                                color='error'
                                                sx={{
                                                    width: 'fit-content',
                                                    fontWeight: 'var(--fw-semibold)',
                                                    fontSize: '.8rem',
                                                    alignSelf: 'flex-end',
                                                }}
                                                onClick={() =>
                                                    handleDeleteJob(index)
                                                }
                                            >
                                                Supprimer
                                            </Button>
                                        ) : null
                                    }
                            </section>
                        ))
                    ) : <></>
                }
            </div>

            <div
                className='py-4 px-8 rounded-lg w-full border-2 border-gray-200'
            >
                <header
                    className='pb-3 pt-2 flex justify-between items-center'
                >
                    <h2 className='mb-2'>Projets</h2>
                    {
                        activeSectionTwo ? (
                            <button 
                                className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100`}
                                onClick={handleButtonTwoClick}
                                style={{
                                    width: '2rem',
                                    height: '2rem',
                                    border: '1px solid var(--clr-neutral-300)',
                                }}
                            >
                                <EditOutlinedIcon 
                                    className='-text--clr-secondary-500'
                                    style={{
                                        width: '1.2rem',
                                        height: '1.2rem',
                                    }}
                                />
                            </button>
                        ):(
                            <button 
                                className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100`}
                                onClick={handleClickOpenAddProject}
                                style={{
                                    width: '2rem',
                                    height: '2rem',
                                    border: '1px solid var(--clr-secondary-500)',
                                }}
                            >
                                <span 
                                    style={{
                                        color: 'var(--clr-secondary-500)',
                                        fontSize: '1.6rem',
                                        fontWeight: 'var(--fw-semibold)',
                                    }}
                                >
                                    +
                                </span>
                            </button>
                        )
                    }
                </header>
                <AddProject open={openAddProject} handleClose={handleCloseAddProject} setFreelancerProjects={setFreelancerProjects} />
                {
                    freelancerProjects != null ? (
                        freelancerProjects.map((project, index) => (
                            <section className='flex flex-col gap-2 mb-3'>
                                <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                                <div 
                                        style={{
                                            fontFamily: 'var(--ff-body)',
                                            fontWeight: 'var(--fw-semibold)',
                                            fontSize: '1.2rem',
                                            color: 'var(--clr-primary-500)',
                                        }} 
                                >
                                            {project.title}
                                    </div>
                                    <div 
                                        style={{
                                            fontFamily: 'var(--ff-body)',
                                            fontWeight: 'var(--fw-medium)',
                                            fontSize: '1rem',
                                        }} 
                                        >
                                        {project.start_date} <span><ArrowRightIcon style={{color: 'var(--clr-primary-500)'}}/></span> {project.currentlyWorking ? 'Actuel' : project.end_date}
                                    </div>
                                </div>
                                <div 
                                    style={{
                                        fontFamily: 'var(--ff-body)',
                                        fontWeight: 'var(--fw-regular)',
                                        fontSize: '.9rem',
                                        paddingLeft: '1rem',
                                    }} 
                                >
                                    <ul 
                                        className="flex flex-col"
                                        style={{ listStyleType: 'disc', paddingLeft: '20px' }}
                                    >
                                        <li 
                                            key={1}
                                            style={{
                                                fontFamily: 'var(--ff-body)',
                                                fontSize: '1rem',
                                                fontWeight: 'var(--fw-regular)',
                                            }}
                                        >
                                            {project.description}
                                        </li>
                                        <li 
                                            key={2}
                                            style={{
                                                fontFamily: 'var(--ff-body)',
                                                fontSize: '1rem',
                                                fontWeight: 'var(--fw-regular)',
                                            }}
                                        >
                                            {project.role_specifique}
                                        </li>
                                    </ul>
                                </div>
                                {
                                    !activeSectionTwo ? (
                                        <Button 
                                            variant="text"
                                            color='error'
                                            sx={{
                                                width: 'fit-content',
                                                fontWeight: 'var(--fw-semibold)',
                                                fontSize: '.8rem',
                                                alignSelf: 'flex-end',
                                            }}
                                            onClick={
                                                () => handleDeleteProject(index)
                                            }
                                        >
                                            Supprimer
                                        </Button>
                                    ) : null
                                    }
                            </section>
                        ))
                    ) : <></>
                }
            </div>
        </main>
    );
};

export default ProfessionalExperience;