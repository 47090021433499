import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import "../styles/LoadingSpinner.css";
import logo from "../assets/Logo.png";

const LoadingSpinner = ({ isLoading }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    let timeout;
    if (isLoading) {
      setShow(true);
    } else {
      timeout = setTimeout(() => setShow(false), 300); // Attend que la transition de fade-out soit terminée
    }
    return () => clearTimeout(timeout);
  }, [isLoading]);

  return (
    <div className={`loading-container ${show ? "show" : ""}`}>
      <div>
        <center>
          <Spinner animation="border" className="loading-spinner" />
        </center>
        <h className="font-body text-primary-800 max-2xl:text-fluid-md 2xl:text-fluid-lg">
          Veuillez patienter...
        </h>
        <center>
          <img src={logo} alt="Logo" className="logo" /> {/* Image du logo */}
        </center>
      </div>
    </div>
  );
};

export default LoadingSpinner;
