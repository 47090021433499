import React, { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  FaUser,
  FaTasks,
  FaPlus,
  FaClock,
  FaHourglassHalf,
  FaSignOutAlt,
  FaBell,
  FaQuestionCircle,
  FaBars,
} from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { RiDashboard3Fill } from "react-icons/ri";
import { MdFeedback } from "react-icons/md";
import Logo from "../assets/Logo.png";
import { useAuth } from "./AuthContext";
import { FaPen } from "react-icons/fa";
import { GrValidate } from "react-icons/gr";
import { RiFeedbackFill } from "react-icons/ri";

import { cleanupChatbot } from './ChatbotReact';

import { MdOutlinePendingActions,MdOutlinePayments ,MdOutlinePayment } from "react-icons/md";



const ClientCard = () => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  const navigate = useNavigate();
  const { logout } = useAuth();
  const [isSubmenuVisible, setIsSubmenuVisible] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isSubmenuPaiementVisibile, setIsSubmenuPaiementVisibile] = useState(false);

  const toggleSubmenuVisibility = () => setIsSubmenuVisible(!isSubmenuVisible);
  const toggleSubmenuPaiementVisibility = () => setIsSubmenuPaiementVisibile(!isSubmenuPaiementVisibile);
  const toggleSidebarVisibility = () => setIsSidebarVisible(!isSidebarVisible);

  const handleLogout = () => {
    cleanupChatbot(); // Clean up chatbot before logging out
    logout();
    navigateTo("/");
  };

  const navigateTo = (path) => {
    navigate(path);
    //window.location.reload(); // Force a page refresh
  };

  return (
    <div className="relative z-50 font-body text-fluid-xs text-primary-800">
      {/* Menu Icon for Smaller Screens */}
      <div
        className="fixed z-50 p-2 rounded-full cursor-pointer lg:hidden top-4 left-4 bg-primary-800"
        onClick={toggleSidebarVisibility}
      >
        <FaBars className="w-4 h-4 text-white" />
      </div>

      {/* Sidebar */}
      <div
        className={`sidebar bg-white  font-poppins p-4 h-screen flex flex-col fixed left-0 top-0 overflow-y-auto transition-transform duration-300 lg:w-80 ${
          isSidebarVisible ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0`}
      >
        <Link to="/" className="mb-6">
          <img src={Logo} alt="Logo" className="w-[80%]" />
        </Link>

        <div className="flex flex-col flex-grow ">
          <div className="mb-6">
            <h2 className="mb-4 font-bold max-2xl:text-fluid-md 2xl:text-fluid-lg ">
              MENU
            </h2>
            <button
              onClick={() => navigateTo("/client-Dashboard")}
              className={`sidebarbutton ${
                isActive("/client-Dashboard") ? "active" : ""
              }`}
            >
              <RiDashboard3Fill
                className="mr-2"
                style={{ fontSize: "1.25rem" }}
              />{" "}
              Tableau de bord
            </button>
            <button onClick={toggleSubmenuVisibility} className="sidebarbutton">
              <FaTasks className="mr-2" style={{ fontSize: "1.25rem" }} /> Mes
              demandes
            </button>
            {isSubmenuVisible && (
              <div className="ml-4">
                <button
                  onClick={() => navigateTo("/create-demande")}
                  className={`sidebarbutton ${
                    isActive("/create-demande") ? "active" : ""
                  }`}
                >
                  <FaPlus className="mr-2" style={{ fontSize: "1.25rem" }} />{" "}
                  Nouvelle demande
                </button>
                <button
                  onClick={() => navigateTo("/demandes-cours-redaction")}
                  className={`sidebarbutton ${
                    isActive("/demandes-cours-redaction") ? "active" : ""
                  }`}
                >
                  <FaPen className="mr-2" style={{ fontSize: "1.25rem" }} /> En
                  cours de rédaction
                </button>

                <button
                  onClick={() => navigateTo("/demandes-attente-matching")}
                  className={`sidebarbutton ${
                    isActive("/demandes-attente-matching") ? "active" : ""
                  }`}
                >
                  <FaClock className="mr-2" style={{ fontSize: "1.25rem" }} />{" "}
                  En attente de matching
                </button>
                <button
                  onClick={() => navigateTo("/demandes-en-attente-selection")}
                  className={`sidebarbutton ${
                    isActive("/demandes-en-attente-selection") ? "active" : ""
                  }`}
                >
                  <FaHourglassHalf
                    className="mr-2"
                    style={{ fontSize: "1.25rem" }}
                  />{" "}
                  En attente de sélection
                </button>
                <button
                  onClick={() => navigateTo("/demandes-en-cours")}
                  className={`sidebarbutton ${
                    isActive("/demandes-en-cours") ? "active" : ""
                  }`}
                >
                  <MdOutlinePendingActions
                    className="mr-2"
                    style={{ fontSize: "1.25rem" }}
                  />{" "}
                  En cours
                </button>
                <button
                  onClick={() => navigateTo("/demandes-en-evaluation")}
                  className={`sidebarbutton ${
                    isActive("/demandes-en-evaluation") ? "active" : ""
                  }`}
                >
                  <RiFeedbackFill
                    className="mr-2"
                    style={{ fontSize: "1.25rem" }}
                  />{" "}
                  À évaluer
                </button>
              </div>
            )}
            <button onClick={toggleSubmenuPaiementVisibility} className="sidebarbutton">
            <MdOutlinePayments className="mr-2"
                    style={{ fontSize: "1.25rem" }} /> Paiement
            </button>
            {isSubmenuPaiementVisibile && (
              <div className="ml-4">
                <button
                  onClick={() => navigateTo("/transactions")}
                  className={`sidebarbutton ${isActive("/transactions") ? "active" : ""}`}
                >
              <GrTransaction className="mr-2" style={{ fontSize: "1.25rem" }} /> 
                  Transactions
                </button>
                <button
                  onClick={() => navigateTo("/cards")}
                  className={`sidebarbutton ${isActive("/cards") ? "active" : ""}`}
                >
                <MdOutlinePayment className="mr-2" style={{ fontSize: "1.25rem" }}/>
                  Moyens de paiement
                </button>
              </div>
            )}

            <button
              onClick={() => navigateTo("/feedbacks")}
              className={`sidebarbutton ${
                isActive("/feedbacks") ? "active" : ""
              }`}
            >
              <MdFeedback className="mr-2" style={{ fontSize: "1.25rem" }} />{" "}
              Mes feedbacks
            </button>
            <button
              onClick={() => navigateTo("/aide-support")}
              className={`sidebarbutton ${
                isActive("/aide-support") ? "active" : ""
              }`}
            >
              <FaQuestionCircle
                className="mr-2"
                style={{ fontSize: "1.25rem" }}
              />{" "}
              Aide & Support
            </button>
          </div>

          {/* AUTRES Section */}
          <div>
            <h2 className="mb-4 font-bold max-2xl:text-fluid-md 2xl:text-fluid-lg">
              AUTRES
            </h2>
            <button
              onClick={() => navigateTo("/profil")}
              className={`sidebarbutton ${isActive("/profil") ? "active" : ""}`}
            >
              <FaUser className="mr-2" style={{ fontSize: "1.25rem" }} /> Mon
              profil
            </button>
            <button
              onClick={() => navigateTo("/notifications")}
              className={`sidebarbutton ${
                isActive("/notifications") ? "active" : ""
              }`}
            >
              <FaBell className="mr-2" style={{ fontSize: "1.25rem" }} /> Mes
              notifications
            </button>
            <button
              onClick={handleLogout}
              className="flex items-center w-full py-2 mt-auto text-white transition rounded-lg bg-secondary-400 hover:bg-secondary-500"
            >
              <FaSignOutAlt className="mr-2" style={{ fontSize: "1.25rem" }} />{" "}
              Se déconnecter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientCard;
