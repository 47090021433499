// ProviderWelcomePage.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import "../styles/ProviderWelcomePage.css";
import "../styles/ProviderCard.css";
import { Spinner } from "react-bootstrap";

import { API_GATEWAY_URL } from "../constants";
import Layout from "./Layout";
import ProviderCard from "./ProviderCard";
import LoadingSpinner from "./LoadingSpinner";

const ProviderWelcomePage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [providerData, setProviderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [workerCount, setWorkerCount] = useState(0);
  const [invitationCount, setInvitationCount] = useState(0);
  const [missionCount, setMissionCount] = useState(0);
  const [pendingValidationCount, setPendingValidationCount] = useState(0); // Placeholder for additional count
  const [completedMissionCount, setCompletedMissionCount] = useState(0); // Placeholder for additional count
  const [isProfileComplete, setIsProfileComplete] = useState(true);

  const fetchWorkerCount = async () => {
    if (user && user.access_token) {
      try {
        const response = await fetch(`${API_GATEWAY_URL}/provider-dashboard/`, {
          // Adjust provider_id as needed
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch worker count.");
        }

        const data = await response.json();
        setWorkerCount(data.nombre_employes); // Assumes data is an array of workers
        setInvitationCount(data.nombre_invitations);
        setMissionCount(data.nombre_missions);
      } catch (err) {
        setError(err.message);
      } finally {
      }
    } else {
      setError("User not authenticated.");
    }
  };
  useEffect(() => {
    const fetchProviderData = async () => {
      try {
        const response = await fetch(`${API_GATEWAY_URL}/provider-profile/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.access_token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setProviderData(data);
          setIsProfileComplete(data.nis && data.nif && data.nrc);
        } else {
          throw new Error("Failed to fetch provider data");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (user && user.access_token) {
      fetchProviderData();
    }
  }, [user]);
  useEffect(() => {
    fetchWorkerCount();
  }, [user]);
  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  const navigateToProfile = () => {
    navigate("/provider-profile");
  };

  const navigateToResources = () => {
    navigate("/provider-resources");
  };

  const navigateToInvite = () => {
    navigate("/invite-worker");
  };

  // Function to extract the initials
  const getInitials = (name) =>
    name
      .split(" ")
      .map((n) => n[0])
      .join("");

  return (
    <div className="pw-page-layout">
      {providerData && <ProviderCard providerData={providerData} />}

      <div className="missions-and-team-container">
        {!isProfileComplete && (
          <div className="alert-banner">
            <i className="fas fa-exclamation-circle"></i> Des informations sont
            manquantes dans votre profil.
            <button onClick={navigateToProfile} className="alert-button">
              Compléter mon profil
            </button>
          </div>
        )}
        <div className="section-container mission-section">
          <h2>Missions</h2>
          <div className="pw-additional-info">
            <div className="pw-card pw-card-mission">
              <div className="pw-card-title">Missions en cours</div>
              <div className="pw-card-count">{missionCount}</div>
            </div>
            <div className="pw-card pw-card-pending">
              <div className="pw-card-title">Demandes à valider</div>
              <div className="pw-card-count">{pendingValidationCount}</div>
            </div>
            <div className="pw-card pw-card-completed">
              <div className="pw-card-title">Missions terminées</div>
              <div className="pw-card-count">{completedMissionCount}</div>
            </div>
          </div>
        </div>
        <div className="section-container section-equipe">
          <h2>Équipe</h2>
          <div className="pw-additional-info">
            <div className="pw-card">
              <div className="pw-card-title">Nombre d'employés</div>
              <div className="pw-card-count">{workerCount}</div>
            </div>
            <div className="pw-card pw-card-equipe">
              <div className="pw-card-title">
                Invitations d’employés en attente
              </div>
              <div className="pw-card-count">{invitationCount}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderWelcomePage;
