import React, { useState } from 'react';
import { useAuth } from './AuthContext'; // Assurez-vous que ce chemin est correct
import Select from 'react-select';
import { useEffect } from 'react';
import { API_GATEWAY_URL } from '../constants';
import Layout from './Layout'; // Assuming Layout contains the navigation layer
import ProviderCard from './ProviderCard';
import styles from '../styles/WorkerInvite.css';
import LoadingSpinner from './LoadingSpinner';
const WorkerInviteForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [expertises, setExpertises] = useState([]);
  const [selectedExpertises, setSelectedExpertises] = useState([]);
  const [expertiseLevels, setExpertiseLevels] = useState({});
  const [selectedDomains, setSelectedDomains] = useState([]); // Pour stocker les domaines sélectionnés
  const [providerData, setProviderData] = useState(null); // State to store provider data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const { user } = useAuth(); // Utiliser useAuth pour accéder aux infos de l'utilisateur

  useEffect(() => {
    // Récupérer les expertises du prestataire
    const fetchExpertises = async () => {
      const response = await fetch(`${API_GATEWAY_URL}/expertises/`, {
        headers: {
          'Authorization': `Bearer ${user.access_token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setExpertises(data); // Suppose que le backend envoie un tableau d'objets avec { value, label }
      }
    };
    fetchExpertises();
  }, [user.access_token]);

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  useEffect(() => {
    if (selectedDomains.length > 0) {
      // Construisez une requête qui inclut tous les domaines sélectionnés
      const domainIds = selectedDomains.map(domain => domain.value);
      const fetchExpertisesForDomains = async () => {
        try {
          const response = await fetch(`${API_GATEWAY_URL}/expertises-by-domains/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // Ajoutez d'autres en-têtes si nécessaire, comme l'autorisation
            },
            body: JSON.stringify({ domain_ids: domainIds }),
          });
          if (response.ok) {
            const data = await response.json();
            setExpertises(data);
          } else {
            throw new Error('Failed to fetch expertises for selected domains');
          }
        } catch (error) {
          console.error('Error fetching expertises:', error);
          // Gérez l'erreur comme vous le souhaitez, par exemple en affichant un message d'erreur
        }
      };
      fetchExpertisesForDomains();
    } else {
      setExpertises([]); // Réinitialisez les expertises si aucun domaine n'est sélectionné
    }
  }, [selectedDomains]);
  const checkErrors = () => {
    const newErrors = {};
    if (!firstName) newErrors.firstName = 'Le prénom est requis.';
    if (!lastName) newErrors.lastName = 'Le nom est requis.';
    if (!validateEmail(email)) newErrors.email = 'L\'adresse email est invalide.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleExpertiseChange = (selectedOptions) => {
    setSelectedExpertises(selectedOptions);
    // Initialisez ou mettez à jour les niveaux pour les expertises sélectionnées
    const newLevels = { ...expertiseLevels };
    selectedOptions.forEach(option => {
      if (!(option.value in newLevels)) {
        // Attribuez un niveau par défaut, par exemple 'JR' pour Junior
        newLevels[option.value] = 'JR';
      }
    });
    setExpertiseLevels(newLevels);
  };

  // Gestion du changement de niveau pour une expertise spécifique
  const handleLevelChange = (expertiseId, level) => {
    setExpertiseLevels(prevLevels => ({
      ...prevLevels,
      [expertiseId]: level
    }));
  };
  const fetchProviderData = async () => {
    if (user && user.access_token) {
        setLoading(true);
        try {
            const response = await fetch(`${API_GATEWAY_URL}/provider-profile/`, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch provider data.');
            }

            const providerData = await response.json();
            setProviderData(providerData);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    } else {
        setError('User not authenticated.');
        setLoading(false);
    }
};
useEffect(() => {
  fetchProviderData();
}, [user]);

	  if (loading) {
	    return <LoadingSpinner isLoading={true} />;
	  };

if (error) {
  return <div style={styles.error}>Error: {error}</div>;
}

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Vérifiez si l'utilisateur est authentifié
    if (!user || !user.access_token) {
      setApiError('Utilisateur non authentifié.');
      return;
    }
  
    if (checkErrors()) {
      // Préparez les données d'expertise à envoyer
      const expertisesWithLevels = selectedExpertises.map(expertise => ({
        id: expertise.value,  // ID de l'expertise
        level: expertiseLevels[expertise.value]  // Niveau pour chaque expertise
      }));
  
      try {
        const response = await fetch(`${API_GATEWAY_URL}/invite-ressource/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.access_token}`
          },
          body: JSON.stringify({ 
            first_name: firstName, 
            last_name: lastName, 
            email: email, 
            expertise_data: expertisesWithLevels  // Envoi des données d'expertise
          }),
        });
        console.log(expertisesWithLevels)
        if (response.ok) {
          setSuccessMessage('Invitation envoyée avec succès.');
          // Réinitialiser les champs du formulaire
          setFirstName('');
          setLastName('');
          setEmail('');
          setSelectedExpertises([]);
          setExpertiseLevels({});
          setApiError('');
        } else {
          const errorData = await response.json();
          setApiError(errorData.message || 'Erreur lors de l\'envoi de l\'invitation.');
        }
      } catch (error) {
        setApiError('Erreur de réseau ou de serveur.');
      }
    }
  };

  return (
    <div className="WIcontentContainer">
      {providerData && (
          <ProviderCard providerData={providerData} />
      )}
      <div className="WIformContainer">
        <form onSubmit={handleSubmit} className="WIform">
          <h2>Entrez les coordonnées de votre ressource</h2> {/* Title for the form */}
          {apiError && <div className="WIapiError">{apiError}</div>}
          {successMessage && <div className="WIsuccessMessage">{successMessage}</div>}
  
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="Prénom"
            className="WIinput"
          />
          {errors.firstName && <div className="WIerror">{errors.firstName}</div>}
  
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Nom"
            className="WIinput"
          />
          {errors.lastName && <div className="WIerror">{errors.lastName}</div>}
  
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Adresse e-mail"
            className="WIinput"
          />
          <Select
            isMulti
            options={expertises.map(e => ({ value: e.id, label: e.name }))}
            className="WIbasic-multi-select"
            classNamePrefix="select"
            onChange={handleExpertiseChange}
          />
          <div className="WIexpertiseContainer">
            {selectedExpertises.map((expertise) => (
              <div key={expertise.value} className="WIexpertiseItem">
                <label>{expertise.label}: </label>
                <select
                  value={expertiseLevels[expertise.value]}
                  onChange={(e) => handleLevelChange(expertise.value, e.target.value)}
                  className="WIinput"
                >
                  <option value="JR">Junior</option>
                  <option value="CF">Confirmé</option>
                  <option value="SR">Senior</option>
                </select>
              </div>
            ))}
          </div>
  
          {errors.email && <div className="WIerror">{errors.email}</div>}
          
          <button type="submit" className="WIbutton">Envoyer</button>
        </form>
      </div>
      </div>
  );

};


export default WorkerInviteForm;
