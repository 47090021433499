import React from 'react';

function ErrorPage() {
    return (
        <div>
            <h1>Erreur lors de la vérification</h1>
            <p>Le lien de vérification est invalide ou a expiré. Veuillez demander un nouveau lien.</p>
            <a href="/">Retour à la page d'accueil</a>
        </div>
    );
}

export default ErrorPage;
