import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_GATEWAY_URL } from "../constants";
import AdminCard from "./admin/AdminCard"; // Import the AdminCard component
import "../styles/AdminDashboardPage.css";
import "../styles/AdminCard.css";
import "../styles/AdminClientRequestsTable.css";
import { useAuth } from "./AuthContext";
import LoadingSpinner from "./LoadingSpinner";

const AdminClientRequestsTable = () => {
  const navigate = useNavigate();
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  const STATUT_DEMANDE = {
    W: "En attente", // Waiting for admin validation
    WC: "En attente de validation", // Waiting for validation
    WS: "En attente de sélection des ressources par le client", // Waiting for resource selection by client
    WP: "En attente de confirmation du prestataire", // Waiting for provider confirmation
    WR: "En attente de paiement", // Waiting for payment
    C: "En cours", // In progress
    R: "En attente d'évaluation", // Waiting for evaluation
    F: "Clôturée", // Closed
  };

  const PROJECT_TYPE_CHOICES = {
    NI: "Nouvelle idée de projet",
    PR: "Projet en cours nécessitant plus de ressource",
    EX: "Aucun, je souhaite juste explorer les possibilités",
  };

  const EXPERTISE_LEVEL_CHOICES = {
    JR: "Junior",
    CF: "Confirmé",
    SR: "Sénior",
    EX: "Expert",
    LA: "Choisir plus tard",
  };

  const fetchClientDemandes = async () => {
    console.log(user);
    if (!user) {
      setError("Aucun utilisateur connecté");
      setLoading(false);
      navigate("/admin-login");
      return;
    }
    const token = user.access_token;
    try {
      const response = await axios.get(
        `${API_GATEWAY_URL}/admin-client-demandes/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const transformedData = response.data.map((demande) => ({
        ...demande,
        project_type_readable: PROJECT_TYPE_CHOICES[demande.projectType],
        expertise_level_readable:
          EXPERTISE_LEVEL_CHOICES[demande.expertiseLevel],
        statut_readable: STATUT_DEMANDE[demande.statut],
      }));
      console.log("Transformed data:", transformedData); // Log the transformed data
      setRequests(transformedData);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClientDemandes();
  }, []);

  const handleDelete = async (demandeId) => {
    Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Vous ne pourrez pas annuler cette action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
      cancelButtonText: "Annuler",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `${API_GATEWAY_URL}/client-demande/${demandeId}/`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
          );

          setRequests(requests.filter((demande) => demande.id !== demandeId));

          Swal.fire("Supprimé!", "Votre demande a été supprimée.", "success");
        } catch (err) {
          setError(err.message);
          Swal.fire(
            "Erreur!",
            "Une erreur s'est produite lors de la suppression de la demande.",
            "error"
          );
        }
      }
    });
  };

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  const columns = [
    { field: "id", headerName: "ID demande", width: 150 },
    { field: "client_name", headerName: "Nom du client", width: 150 },
    { field: "posteTitle", headerName: "Poste demandé", width: 150 },
    {
      field: "project_type_readable",
      headerName: "Type de Projet",
      width: 150,
    },
    {
      field: "expertise_level_readable",
      headerName: "Niveau d'Expertise",
      width: 150,
    },
    { field: "statut_readable", headerName: "Statut", width: 280 },
    { field: "date_debut", headerName: "Date de Début", width: 150 },
    { field: "date_fin", headerName: "Date de Fin", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => navigate(`/admin-request-detail/${params.row.id}`)}
          >
            Voir Détail
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="admin-requests-container">
      <div className="admin-card-container">
        <AdminCard />
      </div>
      <div className="data-grid-container">
        <DataGrid rows={requests} columns={columns} pageSize={10} />
      </div>
    </div>
  );
};

export default AdminClientRequestsTable;
