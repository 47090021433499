import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import "../styles/ClientDashboardPage.css";
import "../styles/ClientCard.css";
import LoadingSpinner from "./LoadingSpinner";
import Navbar from "./Navbarr";
import ClientCard from "./ClientCard";
import { API_GATEWAY_URL } from "../constants";

const ClientDashboardPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [clientData, setClientData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isProfileComplete, setIsProfileComplete] = useState(true);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await fetch(`${API_GATEWAY_URL}/client-profile/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.access_token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setClientData(data);
          console.log("data", data);
          // Uncomment and adjust the following line based on your actual profile completion logic
          // setIsProfileComplete(data.nis && data.nif && data.nrc);
        } else {
          throw new Error(
            "Erreur lors de la récupération des informations du profil client."
          );
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (user && user.access_token) {
      fetchClientData();
    }
  }, [user]);

  const navigateWithRefresh = (path) => {
    navigate(path);
    window.location.reload(); // Force a page refresh
  };

  const handleCountClick = (path) => {
    navigateWithRefresh(path);
  };

  const navigateToProfile = () => navigateWithRefresh("/client-Dashboard");
  const navigateToCreateDemande = () => navigateWithRefresh("/create-demande");
  const navigateToDemandeRedaction = () =>
    handleCountClick("/demandes-cours-redaction");
  const navigateToDemandeSelection = () =>
    handleCountClick("/demandes-en-attente-selection");
  const navigateToDemandeEvaluation = () =>
    handleCountClick("/demandes-en-evaluation");

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  return (
    <div className="pw-page-layout  flex border-red-800">
      <Navbar />
      {clientData && <ClientCard clientData={clientData} />}
      <div className="missions-and-team-container">
        {!isProfileComplete && (
          <div className="alert-banner">
            <i className="fas fa-exclamation-circle"></i> Des informations sont
            manquantes dans votre profil.
            <button onClick={navigateToProfile} className="alert-button">
              Compléter mon profil
            </button>
          </div>
        )}
        <div className=" bg-white text-primary-800 text-fluid-sm">
          <div className="font-display text-center font-bold max-2xl:text-fluid-md 2xl:text-fluid-lg p-6">
            Mes demandes
          </div>
          <div className="pw-additional-info font-body max-2xl:text-fluid-ms 2xl:text-fluid-sm">
            <div
              className="pw-card pw-card-info cursor-pointer"
              onClick={navigateToDemandeRedaction}
            >
              <div className="">Demandes en cours de rédaction</div>
              <div className="pw-card-count my-2">
                {clientData.CPT_PENDING_REDACTION}
              </div>
            </div>

            <div
              className="pw-card pw-card-pending cursor-pointer"
              onClick={navigateToDemandeSelection}
            >
              <div className="">
                Demandes en attente de sélection de ressources
              </div>
              <div className="pw-card-count my-2">
                {clientData.CPT_WAITING_FOR_SELECTION}
              </div>
            </div>

            <div
              className="pw-card pw-card-mission cursor-pointer"
              onClick={navigateToDemandeEvaluation}
            >
              <div className="">Missions en attente d'évaluation</div>
              <div className="pw-card-count my-2">{clientData.CPT_REVIEW}</div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

export default ClientDashboardPage;

/*import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import '../styles/ClientDashboardPage.css'; // Import the external CSS file
import '../styles/ClientCard.css'; // Import the external CSS file
import LoadingSpinner from './LoadingSpinner';
import Navbar from './Navbarr';
import ClientCard from './ClientCard';
import { API_GATEWAY_URL } from '../constants';

const ClientDashboardPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [clientData, setClientData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isProfileComplete, setIsProfileComplete] = useState(true);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await fetch(`${API_GATEWAY_URL}/client-profile/`, {
          method: 'GET',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.access_token}`
          },
        });
        
        if (response.ok) {
          const data = await response.json();
          setClientData(data);
          console.log("data", data);
          // Uncomment and adjust the following line based on your actual profile completion logic
          // setIsProfileComplete(data.nis && data.nif && data.nrc);
        } else {
          throw new Error('Erreur lors de la récupération des informations du profil client.');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (user && user.access_token) {
      fetchClientData();
    }
  }, [user]);

  const navigateWithRefresh = (path) => {
    navigate(path);
    window.location.reload(); // Force a page refresh
  };

  const handleCountClick = (path) => {
    navigateWithRefresh(path);
  };

  const navigateToProfile = () => navigateWithRefresh('/client-Dashboard');
  const navigateToCreateDemande = () => navigateWithRefresh('/create-demande');
  const navigateToDemandeRedaction = () => handleCountClick('/demandes-cours-redaction');
  const navigateToDemandeSelection = () => handleCountClick('/demandes-en-attente-selection');
  const navigateToDemandeEvaluation = () => handleCountClick('/demandes-en-evaluation');

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  return (
    <div className="pw-page-layout  flex border-red-800">
      <Navbar />
      {clientData && <ClientCard clientData={clientData} />}
      <div className="missions-and-team-container">
        {!isProfileComplete && (
          <div className="alert-banner">
            <i className="fas fa-exclamation-circle"></i> Des informations sont manquantes dans votre profil.
            <button onClick={navigateToProfile} className="alert-button">Compléter mon profil</button>
          </div>
        )}
        <div className="section-container mission-section bg-[#F5F5F5] text-primary-800 text-fluid-sm">
          <div className="font-display text-center font-bold text-fluid-md p-6">Mes demandes</div>
          <div className="pw-additional-info">
            <div className="pw-card pw-card-info" onClick={navigateToDemandeRedaction}>
              <div className="">Demandes en cours de rédaction</div>
              <div className="pw-card-count">{clientData.CPT_PENDING_REDACTION}</div>
            </div>
            
            <div className="pw-card pw-card-pending" onClick={navigateToDemandeSelection}>
              <div className="">Demandes en attente de sélection de ressources</div>
              <div className="pw-card-count">{clientData.CPT_WAITING_FOR_SELECTION}</div>
            </div>
            
            <div className="pw-card pw-card-mission" onClick={navigateToDemandeEvaluation}>
              <div className="">Missions en attente d'évaluation</div>
              <div className="pw-card-count">{clientData.CPT_REVIEW}</div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

export default ClientDashboardPage;
*/
