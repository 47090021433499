import React from 'react';
import Rating from '@mui/material/Rating';
import Avatar from '@mui/material/Avatar';
import '../../index.css';

const ClientFeedback = ({ clientName, rating, avatarIconPath, content }) => {
    return (
        <article className="rounded-lg py-2 px-4 border-1 lg:px-6 lg:p-8 sm:w-full lg:w-3/5 shadow-md">
            <div className="flex items-start gap-4 sm:gap-8">
                <div
                    aria-hidden="true"
                    style={{ width: 52, height: 52, borderRadius: '10%' }}
                >
                    <Avatar
                            alt={clientName}
                            src={avatarIconPath}
                            sx={{ width: 50, height: 50 ,borderRadius: '10%'}}
                    />
                </div>

                <div>
                    <div className="flex flex-row justify-between items-center">
                        {/* Client Name */}
                        <div className="mt-3 flex content-center text-neutral-900">
                            <p className="font-body font-semibold text-h6 flex items-center">{clientName}</p>
                        </div>
                        {/* Rating with stars */}
                        <Rating name="read-only" value={Number(rating)} readOnly />
                    </div>

                    <p className="mt-1 text-sm text-neutral-900">
                        {content}
                    </p>
                </div>
            </div>
        </article>
    );
};

export default ClientFeedback;