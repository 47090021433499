import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Form,
  Button,
  Card,
  Container,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { useAuth } from "./AuthContext";
import LoadingSpinner from "./LoadingSpinner";
import { API_GATEWAY_URL } from "../constants";
import Navbar from "./Navbarr";
import ClientCard from "./ClientCard";
import "../styles/CreateDemandePage.css";
import "../styles/Buttons.css";
import Swal from "sweetalert2";
import ClientCalendar from "./ClientCalendar";

const ModifyDemandePage = () => {
  const { demandeId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [demandeData, setDemandeData] = useState({
    posteTitle: "",
    selectedExpertises: [],
    projectType: "",
    missionStart: "",
    expertiseLevel: "",
    timeEngagement: "",
    // Initialize other fields as needed
    date_debut: "",
    date_fin: "",
    planification_type: "",
    nombre_demi_journees: 0,
    availabilities: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDemandeDetails = async () => {
      try {
        const response = await fetch(
          `${API_GATEWAY_URL}/client-demande/${demandeId}/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          // First attempt to read response as text to check for HTML/non-JSON responses
          const textResponse = await response.text();
          try {
            const data = JSON.parse(textResponse);
            setDemandeData(data);
            console.log("test", data);
          } catch (e) {
            throw new Error(
              `Failed to parse JSON, response was: ${textResponse.slice(
                0,
                200
              )}...`
            );
          }
        } else {
          const data = await response.json();
          setDemandeData(data);
          console.log("test", data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDemandeDetails();
  }, [demandeId, user.access_token]);

const [formErrors, setFormErrors] = useState({});

const validateDates = (dateDebut, dateFin) => {
  let errors = {};
  
  if (!dateDebut) {
    errors.dateDebut = "Veuillez sélectionner la date de début.";
  }
  if (!dateFin) {
    errors.dateFin = "Veuillez sélectionner la date de fin.";
  } else if (new Date(dateFin) < new Date(dateDebut)) {
    errors.dateFin = "La date de fin doit être supérieure ou égale à la date de début.";
  }
  
  return errors;
};

const handleSaveChanges = async () => {
  
  

  const formData = new FormData(document.querySelector('form'));
  const updatedData = Object.fromEntries(formData.entries());
  const dateDebut = updatedData.date_debut; // Récupérer la date de début
  const dateFin = updatedData.date_fin; // Récupérer la date de fin

  // Validation des dates
  const errors = validateDates(dateDebut, dateFin);
  if (Object.keys(errors).length > 0) {
    setFormErrors(errors); // Mettre à jour l'état avec les erreurs
    return; // Ne pas continuer si la validation échoue
  }

  if (updatedData.planification_type === "demi-journees") {
    const maxDemiJournees = calculateMaxDemiJournees(dateDebut, dateFin);
    if (updatedData.nombre_demi_journees < 1 || updatedData.nombre_demi_journees > maxDemiJournees) {
      setFormErrors({
        ...formErrors,
        nombre_demi_journees: `Le nombre de demi-journées doit être entre 1 et ${maxDemiJournees}.`
      });
      return;
    }
  }
  
  setFormErrors({});

  try {
    const response = await fetch(`${API_GATEWAY_URL}/client-demande/${demandeId}/`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${user.access_token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatedData)
    });

    if (!response.ok) {
      throw new Error("Échec de l'enregistrement des modifications");
    }

    //alert('Modifications enregistrées avec succès !');
    Swal.fire("Succès", "Modifications enregistrées avec succès !", "success");
    navigate('/demandes-cours-redaction'); // Navigate back to draft requests page
  } catch (err) {
    setError(err.message);
  }
};

const handleFormSubmit = async (event) => {
  event.preventDefault();
  
  const formData = new FormData(event.target);
  const updatedData = Object.fromEntries(formData.entries());
  updatedData.action = 'submit'; 

  const dateDebut = updatedData.date_debut; // Récupérer la date de début
  const dateFin = updatedData.date_fin; // Récupérer la date de fin

  // Validation des dates
  const errors = validateDates(dateDebut, dateFin);
  if (Object.keys(errors).length > 0) {
    setFormErrors(errors); // Mettre à jour l'état avec les erreurs
    return; // Ne pas continuer si la validation échoue
  }
  if (updatedData.planification_type === "demi-journees") {
    const maxDemiJournees = calculateMaxDemiJournees(dateDebut, dateFin);
    if (updatedData.nombre_demi_journees < 1 || updatedData.nombre_demi_journees > maxDemiJournees) {
      setFormErrors({
        ...formErrors,
        nombre_demi_journees: `Le nombre de demi-journées doit être entre 1 et ${maxDemiJournees}.`
      });
      return;
    }
  }
  
  setFormErrors({});
  try {
    const response = await fetch(`${API_GATEWAY_URL}/client-demande/${demandeId}/`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${user.access_token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatedData)
    });

      if (!response.ok) {
        const errorText = await response.text(); // Lire la réponse comme texte
        throw new Error(`Failed to update demande: ${errorText}`);
      }
      
    //alert('Demande updated successfully!');
    Swal.fire("Succès", "Demande soumise avec succès !", "success");
    navigate('/demandes-attente-matching');
  } catch (err) {
    setError(err.message);
  }
};


  if (loading) return <LoadingSpinner isLoading={true} />;
  if (error) return <div>Error: {error}</div>;

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
  
    // Formatage de la date au format yyyy-mm-dd pour l'utiliser dans l'attribut 'min'
    const yyyy = tomorrow.getFullYear();
    const mm = String(tomorrow.getMonth() + 1).padStart(2, '0'); // Mois au format 2 chiffres
    const dd = String(tomorrow.getDate()).padStart(2, '0'); // Jour au format 2 chiffres
    return `${yyyy}-${mm}-${dd}`;
  };

  const calculateMaxDemiJournees = (dateDebut, dateFin) => {
    if (!dateDebut || !dateFin) {
      return 0;
    }
    const start = new Date(dateDebut);
    const end = new Date(dateFin);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays * 2;
  };

  const handleplanification_typeChange = (e) => {
    setDemandeData({
      ...demandeData,
      planification_type: e.target.value,
      nombre_demi_journees: e.target.value === "demi-journees" ? demandeData.nombre_demi_journees : 0,
      availabilities: e.target.value === "detaillee" ? demandeData.availabilities : []
    });
  };

  const handlenombre_demi_journeesChange = (e) => {
    const value = parseInt(e.target.value);
    setDemandeData({
      ...demandeData,
      nombre_demi_journees: value
    });
  };

  const handleAvailabilityChange = (newAvailabilities) => {
    setDemandeData({
      ...demandeData,
      availabilities: newAvailabilities
    });
  };

  return (
    <>
      <div className="clientdemande-page-layout pw-page-layout flex bg-white min-height: 100vh ">
        <Navbar style={{ marginBottom: "20px" }} />{" "}
        {/* Adjust margin to lower the navbar */}
        <Container
          className="d-flex mt-4"
          style={{ height: "calc(100vh - 80px)", backgroundColor: "#FFFFFF" }}
        >
          <div className="me-4" style={{ flex: "1 0 25%" }}>
            {" "}
            {/* Sidebar/ClientCard area */}
            <ClientCard /> {/* ClientCard component */}
          </div>
          <div
            className="d-flex justify-content-start align-items-start"
            style={{ flex: "3 0 75%", marginLeft: "-60px" }}
          >
            <Card
              style={{
                width: "100%",
                maxWidth: "600px",
                backgroundColor: "white",
              }}
            >
              {" "}
              {/* Center the Card and limit its width */}
              <Card.Body className="!text-primary-800 font-body">
                <Card.Title className="flex justify-center ">
                  <strong>Modifier Demande</strong>
                </Card.Title>
                <Form onSubmit={handleFormSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <strong>Titre du poste</strong>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="posteTitle"
                      defaultValue={demandeData.posteTitle}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>
                      <strong>Niveau d'expertise</strong>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="expertiseLevel"
                      defaultValue={demandeData.expertiseLevel}
                    >
                      <option value="JR">Junior</option>
                      <option value="CF">Confirmé</option>
                      <option value="SR">Senior</option>
                      <option value="EX">Expert</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>
                      <strong>Type de projet</strong>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="projectType"
                      defaultValue={demandeData.projectType}
                    >
                      <option value="NI">Nouvelle idée de projet</option>
                      <option value="PR">
                        Projet en cours nécessitant plus de ressource
                      </option>
                      <option value="EX">
                        Aucun, je souhaite juste explorer les possibilités
                      </option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>
                      <strong>Début de mission</strong>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="missionStart"
                      defaultValue={demandeData.missionStart}
                    >
                      <option value="IM">
                        Immédiatement (le plus vite possible)
                      </option>
                      <option value="1T">De 1 à 2 semaines</option>
                      <option value="2W">Plus de 2 semaines</option>
                      <option value="LA">Je vais décider plus tard</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>
                      <strong>Engagement temporel</strong>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="timeEngagement"
                      defaultValue={demandeData.timeEngagement}
                    >
                      <option value="FP">
                        Temps plein (40 heures/semaine)
                      </option>
                      <option value="PT">
                        Temps partiel (moins de 40 heures/semaine)
                      </option>
                      <option value="HD">Par demi-journée</option>
                      <option value="LA">Je vais décider plus tard</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>
                      <strong>Date de Début</strong>
                    </Form.Label>
                    <Form.Control type="date" name="date_debut" defaultValue={demandeData.date_debut} min={getTomorrowDate()}/>
                    {formErrors.dateDebut && <p style={{ color: "red" }}>{formErrors.dateDebut}</p>}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                    <strong>Date de Fin</strong>
                    </Form.Label>
                    <Form.Control type="date" name="date_fin" defaultValue={demandeData.date_fin} min={getTomorrowDate()}/>
                    {formErrors.dateFin && <p style={{ color: "red" }}>{formErrors.dateFin}</p>}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label><strong>Type de planification</strong></Form.Label>
                    <Form.Control
                      as="select"
                      name="planification_type"
                      //defaultValue={demandeData.planification_type} 
                      value={demandeData.planification_type}
                      onChange={handleplanification_typeChange}
                    >
                      <option value="">Sélectionnez un type</option>
                      <option value="DJ">Planification par nombre de demi-journées</option>
                      <option value="DT">Planification détaillée</option>
                    </Form.Control>
                  </Form.Group>

                  {demandeData.planification_type === "DJ" && (
                    <Form.Group className="mb-3">
                      <Form.Label><strong>Nombre de demi-journées</strong></Form.Label>
                      <Form.Control
                        type="number"
                        name="nombre_demi_journees"
                        value={demandeData.nombre_demi_journees}
                        onChange={handlenombre_demi_journeesChange}
                        min="1"
                        max={calculateMaxDemiJournees(demandeData.date_debut, demandeData.date_fin)}
                      />
                      {formErrors.nombre_demi_journees && <p style={{ color: "red" }}>{formErrors.nombre_demi_journees}</p>}
                    </Form.Group>
                  )}

                  {demandeData.planification_type === "DT" && (
                    <Form.Group className="mb-3">
                      <ClientCalendar 
                        availabilities={demandeData.availabilities}
                        onAvailabilityChange={handleAvailabilityChange}
                      />
                    </Form.Group>
                  )}

                  <div className="d-flex justify-content-between">
                  <Button variant="primary" type="button" onClick={handleSaveChanges}>Sauvegarder les modifications</Button>
                  <Button variant="danger" type="submit">Soumission finale</Button>
                  </div>
                                
                </Form>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ModifyDemandePage;
