import React from "react";
import { Link } from "react-router-dom";
import "../../../styles/GuideInscriptionFreelance.css";
import "../../../index.css";
import "../../../styles/Buttons.css";
import "@fortawesome/fontawesome-free/css/all.css";

const GuideInscriptionFreelancePage = () => {
  return (
    <div className="loginbackgroundImage ">
      <div className="container">
        <div className="flex flex-col items-center justify-center max-sm:pt-8 sm:m-8">
          <div className=" mb-2 text-fluid-sm text-white font-display text-center">
            <h3>Comment mettre en avant votre profil ? :</h3>
            <p className="font-body">
              L'importance de la première impression est indéniable !
              Assurez-vous de créer un profil qui se distingue sur TeraTalenT.
            </p>
          </div>
          <div className="row align-items-stretch font-body ">
            <div className="col-md-4 col-sm-6 mb-4">
              <div className="GuideInscriptionFreelanceCard  h-100 d-flex flex-column justify-content-between">
                <i className="fas fa-clock   max-2xl:text-fluid-lg 2xl:text-fluid-xl mb-2"></i>
                <p className="">
                  Prenez le temps nécessaire pour créer un profil qui répond
                  parfaitement à vos attente.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-4">
              <div className="GuideInscriptionFreelanceCard h-100  d-flex flex-column justify-content-between">
                <i className="fas fa-link   max-2xl:text-fluid-lg 2xl:text-fluid-xl mb-2"></i>
                <p className="">
                  Augmentez votre crédibilité en connectant votre profil à vos
                  réseaux professionnels appropriés.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-4">
              <div className="GuideInscriptionFreelanceCard  h-100 d-flex flex-column justify-content-between">
                <i className="fas fa-file-alt   max-2xl:text-fluid-lg 2xl:text-fluid-xl mb-2"></i>
                <p className="">
                  Décrivez de manière précise vos compétences professionnelles
                  afin d'augmenter vos opportunités de travail.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-4 flex">
              <div className="GuideInscriptionFreelanceCard  d-flex flex-column justify-content-between">
                <i className="fas fa-portrait   max-2xl:text-fluid-lg 2xl:text-fluid-xl mb-2"></i>
                <p className="">
                  Mettez un visage sur votre nom ! Téléchargez une photo de
                  profil qui montre clairement votre visage.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-4">
              <div className="GuideInscriptionFreelanceCard  h-100 d-flex flex-column justify-content-between">
                <i className="fas fa-id-card   max-2xl:text-fluid-lg 2xl:text-fluid-xl mb-2"></i>
                <p className="">
                  Afin de garantir la sécurité de notre communauté, nous
                  pourrions vous demander de vérifier votre identité.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 mb-4">
              <div className="GuideInscriptionFreelanceCard h-100  d-flex flex-column justify-content-between">
                <i className="fas fa-address-card max-2xl:text-fluid-lg 2xl:text-fluid-xl mb-2"></i>
                <p className="">
                  Assurez-vous de fournir des informations précises sur votre
                  identité.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 mb-4">
              <div className="GuideInscriptionFreelanceCard  h-100 d-flex flex-column justify-content-between">
                <i className="fas fa-user-lock   max-2xl:text-fluid-lg 2xl:text-fluid-xl mb-2"></i>
                <p className="">
                  N'ouvrez pas plusieurs comptes. Utilisez votre compte
                  existant.
                </p>
              </div>
            </div>

            <div className="col-md-4  col-sm-6 mb-4">
              <div className="GuideInscriptionFreelanceCard  h-100 d-flex flex-column justify-content-between">
                <i className="fas fa-handshake   max-2xl:text-fluid-lg 2xl:text-fluid-xl mb-2"></i>
                <p className="">
                  Évitez de solliciter les membres pour travailler en dehors de
                  TeraTalenT.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 mb-4">
              <div className="GuideInscriptionFreelanceCard  h-100 d-flex flex-column justify-content-between">
                <i className="fas fa-comments-dollar   max-2xl:text-fluid-lg 2xl:text-fluid-xl mb-2"></i>
                <p className="">
                  Ne demandez pas à effectuer la communication ou le paiement en
                  dehors de TeraTalenT.
                </p>
              </div>
            </div>
          </div>

          <Link
            to="/inscription-freelancer"
            className="btn-red max-2xl:text-fluid-ms 2xl:text-fluid-md "
          >
            Continuer
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GuideInscriptionFreelancePage;
