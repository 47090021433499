import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
// import { setFormData, nextStep, prevStep, resetForm } from '../clientInscriptionFormSlice';
import "../../../styles/MultiStepForm.css";

import "../../../styles/navbarAndFooter.css";
import inscriptionClientImage from "../../../assets/inscriptionClientImage.jpg";
import MultiStepProviderForm from "./MultiStepProviderForm";
import Layout from "../../Layout";

const InscriptionProviderBodyComponent = () => {
  return (
    <div className="loginbackgroundImage">
      <div className="container-fluid m-10">
        <MultiStepProviderForm />
      </div>
    </div>
  );
};

export default InscriptionProviderBodyComponent;
