import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {  BUSINESS_PARTNER_URL , API_GATEWAY_URL } from '../constants';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import AdminCard from './admin/AdminCard';
import Swal from "sweetalert2";
import {  Button,Chip,Checkbox,Modal,Box,Typography,TextField,} from "@mui/material";



const Clients = () => {

    const navigate = useNavigate();
    const [requestDetail, setRequestDetail] = useState([]);
    const [requestDetail2, setRequestDetail2] = useState([]);
    const [error, setError] = useState(null); 
    const [clients, setClients] = useState([]); 
    const [emails, setEmails] = useState([]); 
    const [selectedEmail , setSelectedEmail] = useState(null);
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [entities, setEntities] = useState([]);
    const [selectedEntities, setSelectedEntities] = useState(null); 
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    


    const handleLinkClient = (prop) => {
      axios.post(`${BUSINESS_PARTNER_URL}/link-bpclient/`,  {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          'Content-Type': 'application/json',
        },
        props: prop
        } )
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Succès",
            text: "Justification validée avec succès!",
            confirmButtonText: "OK",
            customClass: {
              popup: 'swal2-front'
            }
          });
        
        })
        .catch((error) => {
          console.error(error
          );
       });
      } 
    const handleUnlinkClient = (prop) => {
      axios.delete(`${BUSINESS_PARTNER_URL}/unlink-bpclient/${prop["bpclient"]}`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          'Content-Type': 'application/json',
        },
        } )
        .then((response) => {
          setRequestDetail(response.data)
        
        })
        .catch((error) => {
          console.error(error);
        });
      };

      const handleUnlinkressource= (prop) => {
        axios.delete(`${BUSINESS_PARTNER_URL}/unlink-bpressource/${prop["bpclient"]}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            'Content-Type': 'application/json',
          },
          } )
          .then((response) => {
            setRequestDetail2(response.data)
          
          })
          .catch((error) => {
            console.error(error);
          });
        };


    const handleSelect = (entityId) => {
      setSelectedEntities(entityId.id_bp);
    };
    const handleEmailChange = (prop) => {
      setSelectedEmail(prop);
    };

    
    

  
    const handleClose = () => setOpen(false);

    const handleClose2 = () => setOpen2(false);
    const handleClose3 = () => setOpen3(false);


    useEffect(() => {
        const fetchFreelancers = async () => {
            try {
            if (!user) {
                setError('Aucun utilisateur connecté');
                setLoading(false);
                navigate('/admin-login');
                return;
            }
            const token = user.access_token;
            const response = await axios.get(`${BUSINESS_PARTNER_URL}/list-bp/`, {
                headers: {
                Authorization: `Bearer ${token}`,
                },
            });
            
              console.log(response.data);
              setEntities(response.data);
              
            } catch (err) {
            setError(err.message);
            } finally {
            
            setLoading(false);

            }
        };

       
        fetchFreelancers();
    }, [user]); 



    const filteredEmails = emails.filter((email) =>
      email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    const HandleClickClients = () => {
      setOpen(true);
      
      axios.get(`${BUSINESS_PARTNER_URL}/business-partner-clients/${selectedEntities}`, {
        headers: {
            Authorization: `Bearer ${user.access_token}`,
            'Content-Type': 'application/json',
          },
         
        })
        .then((response) => {
          setRequestDetail(response.data);
        
        })
        .catch((error) => {
          console.error(error);
        });
    };
    const HandleClickRessources = () => {
      setOpen3(true);
      
      axios.get(`${BUSINESS_PARTNER_URL}/business-partner-ressources/${selectedEntities}`, {
        headers: {
            Authorization: `Bearer ${user.access_token}`,
            'Content-Type': 'application/json',
          },
         
        })
        .then((response) => {
          setRequestDetail2(response.data);
        
        })
        .catch((error) => {
          console.error(error);
        });
    };


    

    const HandleClickClients2 = () => {
      setOpen2(true);
      
      axios.get(`${API_GATEWAY_URL}/clients-list/`, {
        headers: {
            Authorization: `Bearer ${user.access_token}`,
            'Content-Type': 'application/json',
          },
         
        })
        .then((response) => {
          setClients(response.data);
          // console.log(response.data);
          const emailList = response.data.map(item => item['email']);
          setEmails(emailList);

        
        })
        .catch((error) => {
          console.error(error);
        });
    };
  



    const columns = [
        {
          field: "select",
          headerName: "Sélectionné",
          width: 80,
          renderCell: (params) => (
            <Checkbox
              checked={selectedEntities=== (params.row.id_bp)}
              onChange={() => handleSelect(params.row)}
            />
          ),
        },
        { field: 'id_bp', headerName: 'ID', width: 150 },
        { field: 'name', headerName: 'Nom Complet', width: 200 },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'phone', headerName: 'Numéro de téléphone', width: 200 },
        { field: 'structure_juridique', headerName: 'Structure Juridique', width: 220 },
        { field: 'status', headerName: 'Statut', width: 220 },
        { field: 'rib', headerName: 'RIB', width: 220 },
        { field: 'entreprise', headerName: 'Entreprise', width: 220 },
        
         
          {
            field: 'delete',
            headerName: 'Supprimer',
            width: 150,
            renderCell: (params) => {
              const handleDelete = () => {
              
              };
        
              return (
                <div variant="contained"  className='rounded-[200px]  flex cursor-pointer items-center justify-center text-center bg-red-500 text-black h-10 w-16'onClick={handleDelete} >
                  Supprimer
                </div>
              );
            },
          },
        
      ];

    
    return(
        <>
        <AdminCard/>
        <div className='w-full flex justify-center items-center '>
        <div className="w-[75%] flex flex-col items-center justify-center ml-[18%]" >
          <div className='w-full flex justify-start'>
            <h2 className='text-[#10316B] font-poppins font-bold text-2xl mt-16 '>Partenaire Commercial</h2>
          </div>
          <div className='w-full flex justify-end space-x-2 mt-8'>
          <div className='bg-[#10316B] font-poppins   text-[#F4F4F4]   ease-in-out h-14 rounded-3xl flex items-center  justify-center text-center w-60 space-x-4 cursor-pointer' onClick={HandleClickClients2}>
               <span>Lier Clients</span>
            </div>
            <div className='bg-[#10316B] font-poppins   text-[#F4F4F4]   ease-in-out h-14 rounded-3xl flex items-center  justify-center text-center w-60 space-x-4 cursor-pointer' onClick={HandleClickClients}>
               <span>Délier Clients</span>
            </div>
            <div className='bg-[#10316B] font-poppins   text-[#F4F4F4]   ease-in-out h-14 rounded-3xl flex items-center  justify-center text-center w-60 space-x-4 cursor-pointer' >
               <span>Lier Ressource</span>
            </div>
            <div className='bg-[#10316B] font-poppins   text-[#F4F4F4]   ease-in-out h-14 rounded-3xl flex items-center  justify-center text-center w-60 space-x-4 cursor-pointer'onClick={HandleClickRessources} >
               <span>Délier Ressource</span>
            </div>
             <div className='bg-[#10316B] font-poppins   text-[#F4F4F4]   ease-in-out h-14 rounded-3xl flex items-center  justify-center text-center w-60 space-x-4 cursor-pointer' >
               <span>Nouveau Partenaire</span>
             </div>
          </div>
           <div className='w-full flex justify-start'>
              <div className='h-[600px] mt-6 w-full'> 
                <DataGrid
                 getRowId={(row) => row.id_bp} 
                 columns={columns}
                 rows={Array.isArray(entities) ? entities : []}
                 pageSize={10}
                 hideFooterSelectedRowCount
                 disableSelectionOnClick
                  />
               </div>
               <Modal open={open} onClose={handleClose} sx={{ zIndex: 999 }}>
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 700,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        outline: "none",
                        border: "2px solid #1976d2",
                        zIndex: 1000,
                      }}
                    >
                      {requestDetail.length === 0 ? (
                        <Typography variant="h5" component="h2" gutterBottom>
                          Aucun Client n'est lié à ce partenaire
                        </Typography>
                      ) : (
                        <Typography variant="h5" component="h2" gutterBottom>
                         Clients liés à ce partenaire
                        </Typography>
                      )}

                      {requestDetail.length > 0 &&
                        requestDetail.map((request , index) => (
                          <div key={request.id_bpclient}>
                            <Typography sx={{ mt: 2 }}>Client {index+1}</Typography>
                            <Typography sx={{ mt: 2 }}>Email: {request.email_client}</Typography>
                            <Typography sx={{ mt: 2 }}>
                              Date D'association: {request.association_date}
                            </Typography>
                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleUnlinkClient( {"bpclient": request.id_bpclient ,"bp" :request.bp} )} 
                                sx={{ textTransform: "none", fontWeight: "bold" }}
                              >
                                Délier le client
                              </Button>
                            </Box>
                          </div>
                        ))}
                    </Box>
                  </Modal>

                  <Modal open={open3} onClose={handleClose3} sx={{ zIndex: 999 }}>
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 700,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        outline: "none",
                        border: "2px solid #1976d2",
                        zIndex: 1000,
                      }}
                    >
                      {requestDetail.length === 0 ? (
                        <Typography variant="h5" component="h2" gutterBottom>
                          Aucune Ressource n'est lié à ce partenaire
                        </Typography>
                      ) : (
                        <Typography variant="h5" component="h2" gutterBottom>
                         Ressources liés à ce partenaire
                        </Typography>
                      )}

                      {requestDetail2.length > 0 &&
                        requestDetail2.map((request , index) => (
                          <div key={request.id_bpressource}>
                            <Typography sx={{ mt: 2 }}>Ressource {index+1}</Typography>
                            <Typography sx={{ mt: 2 }}>Email: {request.email_ressource}</Typography>
                            <Typography sx={{ mt: 2 }}>
                              Date D'association: {request.association_date}
                            </Typography>
                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleUnlinkressource( {"bpclient": request.id_bpressource ,"bp" :request.bp} )} 
                                sx={{ textTransform: "none", fontWeight: "bold" }}
                              >
                                Délier la ressource
                              </Button>
                            </Box>
                          </div>
                        ))}
                    </Box>
                  </Modal>


                  <Modal open={open2} onClose={handleClose2} sx={{ zIndex: 999 }}>
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 700,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        outline: "none",
                        border: "2px solid #1976d2",
                        zIndex: 1000,
                      }}
                    >
                      <Typography
                        variant="h5"
                        component="h2"
                        sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}
                      >
                        Sélectionnez le client par email
                      </Typography>

                      <Box sx={{ mt: 2, maxHeight: 400, overflowY: "auto" }}>
                        {/* Search Bar */}
                        <input
                          type="text"
                          placeholder="Rechercher..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          style={{
                            marginBottom: "16px",
                            padding: "10px",
                            border: "1px solid #1976d2",
                            borderRadius: "4px",
                            width: "100%",
                            outline: "none",
                          }}
                        />

                        {/* Email List */}
                        <ul style={{ paddingLeft: 0 }}>
                          {filteredEmails.length > 0 ? (
                            filteredEmails.map((email, index) => (
                              <li
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "12px",
                                  cursor: "pointer",
                                  padding: "8px",
                                  borderRadius: "4px",
                                  transition: "background-color 0.3s",
                                  "&:hover": {
                                    backgroundColor: "#f0f0f0",
                                  },
                                }}
                              >
                                <input
                                  type="checkbox"
                                  id={index}
                                  checked={selectedEmail === email}
                                  onChange={() => handleEmailChange(email)}
                                  style={{ cursor: "pointer" }}
                                />
                                <label
                                  htmlFor={index}
                                  style={{
                                    marginLeft: "12px",
                                    cursor: "pointer",
                                    flexGrow: 1,
                                  }}
                                >
                                  {email}
                                </label>
                              </li>
                            ))
                          ) : (
                            <li style={{ textAlign: "center", color: "gray" }}>
                              Aucune email trouvée
                            </li>
                          )}
                        </ul>
                      </Box>
                          
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleLinkClient( {"email": selectedEmail ,"bp_id" :selectedEntities} )} 
                                sx={{ textTransform: "none", fontWeight: "bold" }}
                              >
                                Lier le client
                              </Button>
                       </Box>
                    </Box>
                  </Modal>


                 </div>
        </div>
        </div>
        </>

    );

}

export default Clients