import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import Sidebar from "./Sidebar";
import Header from "./Header";

const ListeDemandes = () => {
  const [demandes, setDemandes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDemandes = async () => {
      setLoading(true);
      try {
        const bp_id = localStorage.getItem("bp_id");
        const response = await axios.get(
          `http://localhost:8000/apigatewayservice/business-partner/propositions/${bp_id}`
        );
        const propositions = response.data;

        const demandesData = propositions.map(
          (proposition) => proposition.demande_info
        );
        setDemandes(demandesData);
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 404:
              setError(
                "Demande non trouvée. Veuillez vérifier l'identifiant du partenaire."
              );
              break;
            case 400:
              setError("Requête invalide.");
              break;
            case 500:
              setError(
                "Erreur interne du serveur. Veuillez réessayer plus tard."
              );
              break;
            default:
              setError("Une erreur est survenue. Veuillez réessayer.");
          }
        } else if (error.request) {
          setError(
            "Aucune réponse du serveur. Veuillez vérifier votre connexion."
          );
        } else {
          setError(
            "Erreur lors de la configuration de la requête. Veuillez réessayer."
          );
        }
      } finally {
        setLoading(false);
      }
    };

    fetchDemandes();
  }, []);

  return (
    <div className="pw-page-layout font-body max-2xl:text-fluid-xs 2xl:text-fluid-sm text-primary-800">
      <Header />
      <div className="w-full flex flex-col"></div>
      <Sidebar />
      <div className="flex flex-col justify-center items-center">
        <div className="w-full max-w-screen-xl lg:px-8">
          <div className="flex justify-between items-center mb-6 mt-6">
            <h2 className="max-2xl:text-fluid-md 2xl:text-fluid-lg font-bold ml-4">
              Demandes Associées
            </h2>
          </div>
          <div>
            {loading ? (
              <div className="flex items-center justify-center py-20">
                <div className="loader ease-linear border-4 border-blue-950 border-t-transparent border-solid rounded-full animate-spin h-12 w-12"></div>
              </div>
            ) : error ? (
              <p className="text-red-500 text-center py-6">{error}</p>
            ) : (
              <Grid container spacing={2}>
                {demandes.map((demande) => (
                  <Grid item xs={12} sm={6} md={4} key={demande.id}>
                    <Card
                      sx={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "15px",
                        padding: 2,
                      }}
                    >
                      <CardContent>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ color: "gray" }}
                        >
                          {demande.posteTitle}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ fontWeight: "bold", color: "#6387BF" }}
                        >
                          {demande.projectType}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ color: "gray" }}
                        >
                          Compétences
                        </Typography>
                        <div>
                          {demande.selectedExpertises.length > 0 ? (
                            demande.selectedExpertises.map(
                              (competence, index) => (
                                <Button
                                  key={index}
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "#6387EF",
                                    color: "white",
                                    margin: "4px",
                                    pointerEvents: "none",
                                    borderRadius: "12px",
                                  }}
                                >
                                  {competence}
                                </Button>
                              )
                            )
                          ) : (
                            <Typography variant="body2" sx={{ color: "gray" }}>
                              Aucune compétence spécifiée
                            </Typography>
                          )}
                        </div>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ color: "gray", mt: 2 }}
                        >
                          Niveau d'expertise
                        </Typography>
                        <Typography variant="body2" sx={{ color: "black" }}>
                          {demande.expertiseLevel}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ color: "gray", mt: 2 }}
                        >
                          Statut
                        </Typography>
                        <Typography variant="body2" sx={{ color: "black" }}>
                          {demande.statut}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "16px",
                          }}
                        >
                          <div>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ color: "gray" }}
                            >
                              Date début
                            </Typography>
                            <Typography variant="body2" sx={{ color: "black" }}>
                              {demande.date_debut}
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ color: "gray" }}
                            >
                              Date fin
                            </Typography>
                            <Typography variant="body2" sx={{ color: "black" }}>
                              {demande.date_fin}
                            </Typography>
                          </div>
                        </div>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ color: "gray", mt: 2 }}
                        >
                          Description
                        </Typography>
                        <Typography variant="body2" sx={{ color: "black" }}>
                          {demande.description || "Aucune description fournie."}
                        </Typography>
                        <Button
                          variant="contained"
                          sx={{
                            mt: 2,
                            width: "100%",
                            backgroundColor: "#6387D1",
                            color: "white",
                            borderRadius: "10px",
                          }}
                        >
                          Consulter
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeDemandes;
