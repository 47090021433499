const appEnvironment = process.env.REACT_APP_ENVIRONMENT;

let API_GATEWAY_URL;
let LINKEDIN_CLIENT_ID;
let LINKEDIN_REDIRECT_URI;
let PROVIDER_SERVICE_URL;
let BUSINESS_PARTNER_URL;

if (appEnvironment === 'test') {
  API_GATEWAY_URL = 'https://jobmarketplace-apigateway-test.azurewebsites.net/apigatewayservice';
  LINKEDIN_CLIENT_ID ='775o858df8dshz';
  LINKEDIN_REDIRECT_URI="https://test.teratalent.com/linkedin-login-callback/";
  PROVIDER_SERVICE_URL = 'https://jobmarketplace-providerservice-test.azurewebsites.net/providerservice'; 
  BUSINESS_PARTNER_URL = 'https://jobmarketplace-businesspartnerservice-test.azurewebsites.net/businesspartnerservice';
  //add the provider service url for the test and production
} else if (appEnvironment === 'prod') {
  API_GATEWAY_URL = '????';
 
} else {
  // Default to the test URL for other values
  API_GATEWAY_URL = 'http://localhost:8000/apigatewayservice';
  PROVIDER_SERVICE_URL = 'http://localhost:8002/providerservice'; 
  BUSINESS_PARTNER_URL = 'http://localhost:8003/businesspartnerservice';
  LINKEDIN_CLIENT_ID ='775o858df8dshz';
  LINKEDIN_REDIRECT_URI="http://localhost:3000/linkedin-login-callback/";
}

export { API_GATEWAY_URL, LINKEDIN_CLIENT_ID, LINKEDIN_REDIRECT_URI, PROVIDER_SERVICE_URL, appEnvironment,BUSINESS_PARTNER_URL };