// Planning.js
import React, { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import "../styles/Planning.css";
import ProviderCard from "./ProviderCard";
import FreelancerCard from "./FreelancerCard";
import LoadingSpinner from "./LoadingSpinner";
import { API_GATEWAY_URL } from "../constants";
import AvailabilityCalendar from "./AvailabilityCalendar";

const Planning = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const url =
          user?.user_type === "provider"
            ? `${API_GATEWAY_URL}/provider-profile/`
            : `${API_GATEWAY_URL}/freelancer-profile/`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.access_token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUserData(data);
        } else {
          throw new Error("Failed to fetch user data");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (user && user.access_token) {
      fetchUserData();
    }
  }, [user]);

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  return (
    <div className="pw-page-layout">
      {userData && user.user_type === "provider" && (
        <ProviderCard providerData={userData} />
      )}
      {userData && user.user_type === "freelancer" && (
        <FreelancerCard freelancerData={userData} />
      )}
      <div className="missions-and-team-container">
        <div className="section-container mission-section">
          <h2>Planning des disponibilités</h2>
          <div className="pw-additional-info">
            <AvailabilityCalendar user={user} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Planning;
