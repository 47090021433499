// PaymentFormContent.jsx
import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  IbanElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { API_GATEWAY_URL } from "../../constants";
import { useEffect } from "react";
import { useAuth } from "../AuthContext";
import { GrStorage } from "react-icons/gr";
import { SiSepa } from "react-icons/si";
import { BsCreditCard2Front } from "react-icons/bs";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(
  "pk_test_51PwQxFCNATD2pskqyuq7r52HaRlzGmB9tbljsFpUoRvAg1yd26vyM738auB5jaNovSFRKbbTdI6N83tZpgH4oDyX00hRXSYxaM"
);

const PaymentFormContent = (props) => {
  console.log("Props:", props);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [success, setSuccess] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("saved");
  const [personalInfo, setPersonalInfo] = useState(props.personalInfo);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [authorizeDebit, setAuthorizeDebit] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const amount = 5000;
  const { user } = useAuth();
  const [userId, setUserId] = useState("");
  const [dataClient, setDataClient] = useState(null);
  const [cards, setCards] = useState([]);
  const [cardOptions, setCardOptions] = useState([]);
  const [selectedCard, setSelectedCard] = useState({ id: "", type: "" });
  const [stripe_id, setStripeId] = useState("");

  const ibanOptions = {
    supportedCountries: ["SEPA"],
    style: {
      base: {
        fontSize: "16px",
        color: "#32325d",
        "::placeholder": {
          color: "#A0A0A0",
        },
      },
      invalid: {
        color: "#fa755a",
      },
    },
  };


  useEffect(() => {
    const fetchData = async () => {
      if (!user) {
        console.log("User is not authenticated.");
        return;
      }

      try {
        const profileResponse = await fetch(
          `${API_GATEWAY_URL}/client-profile/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );

        if (!profileResponse.ok)
          throw new Error(
            "Erreur lors de la récupération des données du profil."
          );

        const profileData = await profileResponse.json();
        setDataClient(profileData);
        const user_id = profileData.user;
        setUserId(user_id);
        const cardsResponse = await fetch(
          `${API_GATEWAY_URL}/get-user-cards/?user=${user_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );

        if (!cardsResponse.ok)
          throw new Error("Erreur lors de la récupération des cartes.");

        const cardsData = await cardsResponse.json();
        setCards(cardsData);
      } catch (err) {
        console.error("Erreur:", err);
        setError(err.message);
        Swal.fire("Erreur", `${error}`, "error");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    if (selectedCard) {
      console.log("SelectedCard:", selectedCard);
      setStripeId(selectedCard.stripe_id);
    }
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      setError("Stripe.js n'est pas encore chargé. Veuillez réessayer.");
      setLoading(false);
      Swal.fire("Erreur", `${error}`, "error");
      return;
    }

    // Validation des cases à cocher
    if (paymentMethod === "sepa" && (!authorizeDebit || !acceptTerms)) {
      setError(
        "Vous devez autoriser le débit automatique et accepter les termes."
      );
      setLoading(false);
      Swal.fire("Erreur", `${error}`, "error");
      return;
    }

    try {
      // Créer un PaymentMethod
      let result;

      if (paymentMethod === "sepa") {
        const ibanElement = elements.getElement(IbanElement);
        result = await stripe.createPaymentMethod({
          type: "sepa_debit",
          sepa_debit: ibanElement,
          billing_details: {
            name: personalInfo.fullName,
            email: dataClient.email,
          },
        });
      } else if (paymentMethod === "card") {
        result = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: personalInfo.fullName,
            email: dataClient.email,
          },
        });
        
      } else if (paymentMethod === "saved") {
        result = {
          paymentMethod: {
            id: selectedCard.id,
          },
        };
      }
      if (result.error) {
        setError(result.error.message);
        setLoading(false);
        Swal.fire("Erreur", `${error}`, "error");
        return;
      }

      const paymentMethodId = result.paymentMethod.id;
      console.log("PaymentMethodId:", paymentMethodId);

      let response;
      let paymentIntentId;

      console.log("s", stripe_id);
      if (paymentMethod === "card" || selectedCard.type === "card") {
        response = await fetch(`${API_GATEWAY_URL}/create-payment-intent/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.access_token}`,
          },
          body: JSON.stringify({
            amount: amount,
            currency: "eur",
            payment_method_id: paymentMethodId,
            user: userId,
            stripe_id: selectedCard.stripe_id,
          }),
        });

        response = await response.json();
      } else if (paymentMethod === "sepa" || selectedCard.type === "sepa") {
        response = await fetch(`${API_GATEWAY_URL}/create-sepa-payment/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.access_token}`,
          },
          body: JSON.stringify({
            amount: amount,
            currency: "eur",
            payment_method_id: paymentMethodId,
            user_acceptance: true,
            user: userId,
            stripe_id: selectedCard.stripe_id,
            freelance_id: props.resource_id,
          }),
        });
        response = await response.json();
        const stat = response.paymentIntent.status;
        console.log("Statut:", stat);
        if (
          (paymentMethod === "sepa" ||
            (paymentMethod === "saved" && selectedCard.type === "sepa")) &&
          (stat === "pending" || stat === "success")
        ) {
          if (paymentMethod === "saved") {
            props.setPaymentMethod(selectedCard.type);
          } else {
            props.setPaymentMethod(paymentMethod);
          }
          setSuccess(true);
          setLoading(false);
          Swal.fire(
            "Sélectionné!",
            "Le profil a été sélectionné et le statut de la demande mis à jour.",
            "success"
          ).then(() => {
            props.onNext({ personalInfo: props.personalInfo });
          });
        } else if (paymentMethod === "sepa" && response.data.error) {
          setError(response.data.error.message);

          setLoading(false);
          Swal.fire(
            "Erreur",
            `Echec d'effectuer paiement SEPA, ${response.data.error.message}`,
            "error"
          );
        }
      }

      paymentIntentId = response.paymentIntent.id;

      props.setPaymentIntentId(paymentIntentId);

      if (paymentMethod === "card" || selectedCard.type === "card") {
        const returnUrl = window.location.href;

        // Confirmer le PaymentIntent côté backend avec return_url
        const confirmResponse = await axios.post(
          `${API_GATEWAY_URL}/confirm-payment-intent/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.access_token}`,
            },
            payment_intent_id: paymentIntentId,
            payment_method_id: paymentMethodId,
            return_url: returnUrl,
            freelance_id: props.resource_id,
            amount: amount,
          }
        );
        
        console.log(confirmResponse.data);
        console.log(confirmResponse);

        if (confirmResponse.data.requires_action) {
          const { payment_intent_client_secret } = confirmResponse.data;
          const { error: confirmError } = await stripe.confirmCardPayment(
            payment_intent_client_secret
          );

          //EN cas d'erreur
          if (confirmError) {
            setError(confirmError.message);
            Swal.fire("Erreur", `Echec de confirmation, ${error}`, "error");
          } else {
            console.log('props', props.personalInfo);
            Swal.fire(
              "Sélectionné!",
              "Le profil a été sélectionné et le statut de la demande mis à jour.",
              "success"
            ).then(() => {
              props.onNext({ personalInfo: props.personalInfo });
            
            });
          }
          setSuccess(true);
          console.log("Paiement réussi!");
        } else if (confirmResponse.status === 200) {
          
          Swal.fire(
            "Sélectionné!",
            "Le profil a été sélectionné et le statut de la demande mis à jour.",
            "success"
          ).then(() => {
            props.onNext({ personalInfo: props.personalInfo });
          });
        }
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      setError("Une erreur est survenue lors du traitement du paiement.");
      setLoading(false);
      Swal.fire("Erreur", `Echec de confirmation, ${error}`, "error");
    }
  };

  useEffect(() => {
    const cardOption = cards.map((card, index) => ({
      value: { id: card.token, type: card.type, stripe_id: card.stripe_id },
      label:
        card.type === "sepa" ? (
          <div className="flex items-center gap-4">
            <SiSepa size={20} className="text-blue-900" />
            <span>{"**** **** **** " + card.last4Digits}</span>
          </div>
        ) : (
          <div className="flex items-center gap-4">
            <BsCreditCard2Front size={20} className="text-blue-900" />
            <span>{"**** **** **** " + card.last4Digits}</span>
          </div>
        ),
    }));
    setCardOptions(cardOption);
  }, [cards]);

  useEffect(() => {
    console.log(selectedCard);
  }, [selectedCard]);

  const defaultOption = { value: "Choisir méthode", label: "Choisir méthode" };

  return (
    <form
      className="flex flex-col justify-between w-full h-full"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col justify-between w-full h-full pb-4">
        <div className="flex-grow">
          <div className="text-[#1e1e1e] text-lg font-bold font-Poppins">
            Informations de paiement
          </div>
          <div className="text-[#1e1e1e] text-xs font-normal font-Poppins mb-4">
            Toutes les transactions sont sécurisées et protégées
          </div>
          <div className="sm:pr-8">
            <div
              className={`w-full px-4 py-3 rounded border items-center justify-between inline-flex mb-2 ${
                paymentMethod === "saved"
                  ? "bg-[#eff5ff] border-[#0056b3]"
                  : "bg-white border-[#a0a0a0]"
              }`}
            >
              <div className="flex gap-4">
                <input
                  type="radio"
                  name="paymentMethod"
                  id="savedPayment"
                  checked={paymentMethod === "saved"}
                  onChange={() => setPaymentMethod("saved")}
                />
                <div className="text-[#1e1e1e] text-sm font-normal font-Poppins">
                  Méthode de paiement enregistrée
                </div>
              </div>
              <div className="flex gap-1">
                <GrStorage size={20} className="text-blue-900" />
              </div>
            </div>
            {paymentMethod === "saved" && (
              <div className="mt-2 mb-4">
                <label className="block text-[#1e1e1e] text-sm font-medium font-Poppins mb-2">
                  Choisir méthode de paiement
                </label>

                <Select
                  defaultValue={defaultOption}
                  options={cardOptions}
                  required
                  onChange={(option) => setSelectedCard(option.value)}
                  style={{
                    marginBottom: "0.5rem", // mb-2
                    width: "100%", // w-full
                    border: "1px solid #a0a0a0", // Default border
                    borderRadius: "0.375rem", // rounded-md
                    padding: "0.5rem 1rem", // px-3 py-2
                    transition: "border-color 0.2s ease",
                  }}
                ></Select>
              </div>
            )}
            {/* SEPA Payment */}
            <div
              className={`w-full px-4 py-3 rounded border items-center justify-between inline-flex ${
                paymentMethod === "sepa"
                  ? "bg-[#eff5ff] border-[#0056b3]"
                  : "bg-white border-[#a0a0a0]"
              }`}
            >
              <div className="flex gap-4">
                <input
                  type="radio"
                  name="paymentMethod"
                  id="sepaPayment"
                  checked={paymentMethod === "sepa"}
                  onChange={() => setPaymentMethod("sepa")}
                />
                <div className="text-[#1e1e1e] text-sm font-normal font-Poppins">
                  Prélèvement SEPA
                </div>
              </div>
              <div className="w-9 h-[22px] relative">
                <img
                  src={require("../../assets/paiement/sepa logo.svg").default}
                  alt="SEPA"
                />
              </div>
            </div>

            {/* SEPA Form */}
            {paymentMethod === "sepa" && (
              <div id="sepa-form">
                <div className="mt-4">
                  <label className="block text-[#1e1e1e] text-sm font-medium font-Poppins mb-2">
                    Numéro de compte (IBAN)
                  </label>
                  <div className="StripeElement mb-2 w-full border rounded-md px-3 py-2 hover:border-[#0056b3] focus:border-[#0056b3] focus:border-[1.5px]">
                    <IbanElement options={ibanOptions} />
                  </div>
                </div>
                <div className="flex items-center justify-start gap-2 mt-2">
                  <input
                    type="checkbox"
                    className="cursor-pointer"
                    checked={authorizeDebit}
                    onChange={() => setAuthorizeDebit(!authorizeDebit)}
                  />
                  <div className="text-[#1e1e1e] text-[12px] font-normal font-Poppins w-full">
                    Autoriser le débit automatique de votre compte bancaire
                  </div>
                </div>
                <div className="flex items-center justify-start gap-2 mt-1">
                  <input
                    type="checkbox"
                    className="cursor-pointer"
                    checked={acceptTerms}
                    onChange={() => setAcceptTerms(!acceptTerms)}
                  />
                  <div className="text-[#1e1e1e] text-[12px] font-normal font-Poppins">
                    Accepter nos <u>termes et conditions</u> ainsi que notre{" "}
                    <u>politique de confidentialité</u>.
                  </div>
                </div>
              </div>
            )}

            {/* Card Payment */}
            <div
              className={`mt-2 w-full px-4 py-3 rounded border items-center justify-between inline-flex ${
                paymentMethod === "card"
                  ? "bg-[#eff5ff] border-[#0056b3]"
                  : "bg-white border-[#a0a0a0]"
              }`}
            >
              <div className="flex gap-4">
                <input
                  type="radio"
                  name="paymentMethod"
                  id="cardPayment"
                  checked={paymentMethod === "card"}
                  onChange={() => setPaymentMethod("card")}
                />
                <div className="text-[#1e1e1e] text-sm font-normal font-Poppins">
                  Carte de crédit
                </div>
              </div>
              <div className="flex gap-1">
                <img
                  src={require("../../assets/paiement/Visaicon.svg").default}
                  alt="VISA"
                />
                <img
                  src={require("../../assets/paiement/mcIcon.svg").default}
                  alt="MasterCard"
                />
              </div>
            </div>
          </div>

          {/* Card Form */}
          {paymentMethod === "card" && (
            <div className="mt-2 sm:pr-8">
              <label className="block text-[#1e1e1e] text-sm font-medium font-Poppins mb-2">
                Numéro de carte
              </label>

              <div className="StripeElement mb-2 w-full border rounded-md px-3 py-2 hover:border-[#0056b3] focus:outline-[#0056b3]">
                <CardNumberElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#32325d",
                        "::placeholder": {
                          color: "#A0A0A0",
                        },
                      },
                      invalid: {
                        color: "#fa755a",
                      },
                    },
                  }}
                />
              </div>
              <div className="flex gap-2">
                <div className="w-full">
                  <label className="block text-[#1e1e1e] text-sm font-medium font-Poppins mb-2">
                    Date d'expiration
                  </label>
                  <div className="flex flex-row w-full StripeElement mb-2 border rounded-md px-3 py-2 hover:border-[#0056b3] focus:outline-[#0056b3] focus:border-[1.5px]">
                    <div className="w-full">
                      <CardExpiryElement
                        options={{
                          style: {
                            base: {
                              fontSize: "16px",
                              color: "#32325d",
                              "::placeholder": {
                                color: "#A0A0A0",
                              },
                            },
                            invalid: {
                              color: "#fa755a",
                            },
                          },
                        }}
                      />
                    </div>
                    <img
                      src={
                        require("../../assets/paiement/Calendar.svg").default
                      }
                      alt="Date"
                    />
                  </div>
                </div>
                <div className="w-full">
                  <label className="block text-[#1e1e1e] text-sm font-medium font-Poppins mb-2">
                    CVC
                  </label>
                  <div className="flex flex-row w-full StripeElement mb-2 border rounded-md px-3 py-2 hover:border-[#0056b3] focus:border-[#0056b3] focus:outline-none focus:border-[1.5px]">
                    <div className="w-full">
                      <CardCvcElement
                        options={{
                          style: {
                            base: {
                              fontSize: "16px",
                              color: "#32325d",
                              "::placeholder": {
                                color: "#A0A0A0",
                              },
                            },
                            invalid: {
                              color: "#fa755a",
                            },
                          },
                        }}
                      />
                    </div>
                    <img
                      src={
                        require("../../assets/paiement/Lock Closed.svg").default
                      }
                      alt="CVV"
                    />
                  </div>
                </div>
              </div>

              <div className="mb-2">
                <label className="block text-[#1e1e1e] text-sm font-medium font-Poppins mb-2">
                  Nom complet
                </label>
                <input
                  type="text"
                  className="w-full border rounded-md px-3 py-2 hover:border-[#0056b3] focus:border-[#0056b3] focus:outline-none focus:border-[1.5px] items-center"
                  placeholder="Nom complet"
                  required
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-between sm:pr-8">
        <button
          type="button"
          onClick={() => props.onBack()}
          className="hover:bg-[#ccdef1] w-40 h-10 px-5 py-2 bg-white border border-[#0056b3] text-[#0056b3] rounded-lg font-medium font-Poppins text-sm"
        >
          Retour
        </button>

        <button
          type="submit"
          disabled={loading}
          className="hover:bg-[#4179B5] w-40 h-10 px-5 py-2 bg-[#0056b3] rounded-lg text-white font-medium font-Poppins text-sm"
        >
          {loading ? "Processing..." : "Continuer"}
        </button>
      </div>

      {error && <div className="mt-2 text-red-500">{error}</div>}
      {success && <div className="mt-2 text-green-500">Paiement réussi!</div>}
    </form>
  );
};

export const PaymentForm = (props) => (
  <div className="h-full">
    <Elements stripe={stripePromise}>
      <PaymentFormContent
        onBack={props.onBack}
        onNext={props.onNext}
        personalInfo={props.personalInfo}
        resourceInfo={props.resourceInfo}
        resource_id={props.resource_id}
        setPaymentMethod={(info) => props.setPaymentMethod(info)}
        setPaymentIntentId={(info) => props.setPaymentIntentId(info)}
      />
    </Elements>
  </div>
);
