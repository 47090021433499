import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import AddClientForm from "./AddClientForm";
import "../../index.css";
import "../../styles/Buttons.css";

const ClientsTable = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const bp_id = localStorage.getItem("bp_id");
        const response = await axios.get(
          "http://localhost:8000/apigatewayservice/business-partner-clients/",
          {
            params: { bp_id },
          }
        );
        setClients(response.data);
      } catch (error) {
        setError(error.response ? error.response.data.error : error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  return (
    <div className="pw-page-layout font-body max-2xl:text-fluid-xs 2xl:text-fluid-sm text-primary-800">
      <Header />
      <div className="w-full flex flex-col  ">
        <Sidebar />
        <div className="flex flex-col justify-center items-center">
          <div className="w-full max-w-screen-xl lg:px-8">
            <div className="flex justify-between items-center mb-6 mt-6">
              <p className="max-2xl:text-fluid-md 2xl:text-fluid-lg font-bold ml-4">
                Clients
              </p>
              <button className="btn-red " onClick={() => setIsFormOpen(true)}>
                Ajouter client
              </button>

              {isFormOpen && (
                <AddClientForm
                  isOpen={isFormOpen}
                  onClose={() => setIsFormOpen(false)} // Close the modal
                />
              )}
            </div>
            <div className="bg-white rounded-lg border border-gray-300">
              {loading ? (
                <div className="flex items-center justify-center py-20">
                  <div className="loader ease-linear border-4 border-blue-950 border-t-transparent border-solid rounded-full animate-spin h-12 w-12"></div>
                </div>
              ) : error ? (
                <p className="text-red-500 text-center py-6">{error}</p>
              ) : (
                <table className="min-w-full table-auto">
                  <thead className="bg-gray-100 border-b border-gray-200">
                    <tr>
                      <th className="px-6 py-3 text-left bg-primary-800 border border-[#F0F0F0] text-white">
                        Nom complet
                      </th>
                      <th className="px-6 py-3 text-left bg-primary-800 border border-[#F0F0F0] text-white">
                        Email
                      </th>
                      <th className="px-6 py-3 text-left bg-primary-800 border border-[#F0F0F0] text-white">
                        Tel
                      </th>
                      <th className="px-6 py-3 text-left bg-primary-800 border border-[#F0F0F0] text-white">
                        Nom d'entreprise
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {clients.map((client, index) => (
                      <tr
                        key={index}
                        className="hover:bg-gray-50 transition-colors"
                      >
                        <td className="px-6 py-4 text-gray-700">
                          {client.clientName}
                        </td>
                        <td className="px-6 py-4 text-gray-700">
                          {client.email}
                        </td>
                        <td className="px-6 py-4 text-gray-700">
                          {client.phone_number}
                        </td>
                        <td className="px-6 py-4 text-gray-700">
                          {client.company_name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsTable;

/*import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { Card, Container, Row, Col, Button, Modal } from 'react-bootstrap';

const ClientsTable = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const bp_id = localStorage.getItem('bp_id');
        const response = await axios.get("http://localhost:8000/apigatewayservice/business-partner-clients/", {
          params: { bp_id }  
        });
        setClients(response.data); 
      } catch (error) {
        setError(error.response ? error.response.data.error : error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  return (
    <div className="CDP-page-layout flex font-body text-fluid-sm text-primary-800">
       
      <Header />
      <Sidebar />
      <Container >
        <div className="flex justify-between items-center mb-6 mt-6">
          <h2 className="text-fluid-lg font-bold mb-6 ml-4">Clients</h2>
          <button
            className="btn-red"
            onClick={() => navigate("/add-client-bp")}
          >
            Ajouter client
          </button>
        </div>
        <div className="bg-white rounded-lg border border-gray-300 ">
          {loading ? (
            <div className="flex items-center justify-center py-20">
              <div className="loader ease-linear border-4 border-blue-950 border-t-transparent border-solid rounded-full animate-spin h-12 w-12"></div>
            </div>
          ) : error ? (
            <p className="text-red-500 text-center py-6">{error}</p>
          ) : (
            <table className="min-w-full table-auto">
              <thead className="bg-gray-100 border-b border-gray-200">
                <tr>
                  <th className="px-6 py-3 text-left bg-primary-800 border border-[#F0F0F0] text-white">Nom complet</th>
                  <th className="px-6 py-3 text-left bg-primary-800 border border-[#F0F0F0] text-white">Email</th>
                  <th className="px-6 py-3 text-left bg-primary-800 border border-[#F0F0F0] text-white">Tel</th>
                  <th className="px-6 py-3 text-left bg-primary-800 border border-[#F0F0F0] text-white">Nom d'entreprise</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {clients.map((client, index) => (
                  <tr key={index} className="hover:bg-gray-50 transition-colors">
                    <td className="px-6 py-4 text-gray-700">{client.clientName}</td>
                    <td className="px-6 py-4 text-gray-700">{client.email}</td>
                    <td className="px-6 py-4 text-gray-700">{client.phone_number}</td>
                    <td className="px-6 py-4 text-gray-700">{client.company_name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </Container>
    </div>
  );
};

export default ClientsTable;
*/
