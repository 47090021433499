import React, { useState } from "react";
import { useEffect } from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Suspense, lazy } from "react";
import LoadingSpinner from "./components/LoadingSpinner";
import ProviderWelcomePage from "./components/ProviderWelcomePage";
import WorkerWelcomePage from "./components/WorkerWelcomePage";
import WorkerCreationForm from "./components/WorkerCreationForm";
import WorkerLogin from "./components/WorkerLogin";
import ProviderProfile from "./components/ProviderProfile";
import WorkerProfile from "./components/WorkerProfile";
import WorkerInviteForm from "./components/WorkerInviteForm";
import ProviderResources from "./components/ProviderResources";
import ProviderExpertises from "./components/ProviderExpertises";
import ProviderUpdateForm from "./components/ProviderUpdateForm";
import WorkerUpdateForm from "./components/WorkerUpdateForm";
import {
  API_GATEWAY_URL,
  LINKEDIN_CLIENT_ID,
  LINKEDIN_REDIRECT_URI,
  appEnvironment,
} from "./constants";
import { AuthProvider, useAuth } from "./components/AuthContext";
import LogoutButton from "./LogoutButton";
import SuccessPage from "./components/SuccessPage";
import ErrorPage from "./components/ErrorPage";
import VerifyPage from "./components/VerifyPage";
import VerifyClientPage from "./components/VerifyClientPage";
import ProviderContactAddPage from "./components/ProviderContactAddPage";
import WelcomeComponent from "./components/WelcomeComponent";
import TypeDeProfilsPage from "./components/Inscriptions/TypesProfiles";
import ClientDashboardPage from "./components/clientDashboardPage";
import GuideInscriptionFreelancePage from "./components/Inscriptions/freelancer/GuideInscriptionFreelance";
import FreelancerInscriptionBodyComponent from "./components/Inscriptions/freelancer/FreelancerInscriptionComponent";
import InscriptionClientBodyComponent from "./components/Inscriptions/client/ClientInscriptionComponent";
import InscriptionProviderBodyComponent from "./components/Inscriptions/provider/ProviderInscriptionComponent";
import GuideInscriptionProviderPage from "./components/Inscriptions/provider/GuideInscriptionProvider";
import ValidationMailPage from "./components/Inscriptions/ValidationMail";
import FreelancerValidationMailPage from "./components/Inscriptions/freelancer/FreelancerValidationMail";
import LoginPage from "./components/Login/LoginPage";
import LinkedinLoginCallback from "./components/Login/LinkedinLoginCallback";
import { Provider } from "react-redux";
import store from "./redux/store";
import { styles } from "./assets/styles";
import EditExpertisePage from "./components/EditExpertisePage";
import ProviderWorkerUpdateForm from "./components/ProviderWorkerUpdateForm";
import AddExpertisePage from "./components/AddExpertisePage";
import BusinessPartnerWelcomePage from "./components/BusinessPartnerWelcomePage";
import BusinessPartnerProfile from "./components/BusinessPartnerProfile";
import BusinessPartnerRequestForm from "./components/BusinessPartnerRequestForm";
import DashboardAdmin from "./components/dashboard/admin/Dashboard";
import DashboardAdminP2 from "./components/dashboard/admin/Dashboard-p2";
import DashboardAdminP3 from "./components/dashboard/admin/Dashboard-p3";
import FreelancerDashboardPage from "./components/FreelancerDashboardPage";
import WorkerValidationMailPage from "./components/Inscriptions/worker/WorkerValidationMail";
import BusinessPartnerSignUp from "./components/Inscriptions/business_partner/inscription_bp";
import ProviderValidationMailPage from "./components/Inscriptions/provider/ProviderValidationMail";
import ClientDemandesPage from "./components/clientDemandesPage";
import ModifyDemandePage from "./components/ModifyDemandePage";
import CreateDemandePage from "./components/CreateDemandePage";
import AdminLoginPage from "./components/admin/AdminLoginPage";
import AdminDashboardPage from "./components/admin/AdminDashboardPage";
import AdminClientRequestsTable from "./components/AdminClientRequestsTable";
import AdminProvidersTable from "./components/AdminProvidersTable";
import AdminRequestDetail from "./components/AdminRequestDetail";
import AdminRequestDetail2 from "./components/AdminRequestDetail2";
import ClientDemandesEnEvaluationPage from "./components/ClientDemandesEnEvaluationPage";
import ClientDemandesEnCoursPage from "./components/ClientDemandesEnCoursPage";
import Planning from "./components/Planning";
import SelectResourcesPage from "./components/SelectResourcesPage"; // Import the new component
import ProviderDemandesEnCoursPage from "./components/ProviderDemandesEnCoursPage";
import ProviderDemandesEnAttentePage from "./components/ProviderDemandesEnAttentePage";
import ProviderDemandesTermineesPage from "./components/ProviderDemandesTermineesPage";
import ClientAideSupportPage from "./components/AideSupport";
import DemandeAttenteSelection from "./components/DemandeAttenteSelection";
import Notifications from "./components/Notifications";
import Profil from "./components/Profil";
import DemandeAttenteMatching from "./components/DemandeAttenteMatching";
import FeedbackForm from "./components/FeedbackForm";
import Feedbacks from "./components/Feedbacks";

import DemandeCoursRedaction from './components/DemandeCoursRedaction';
import DashboardBP from './components/Dashboard_BP/Dashboard';
import Felicitation from './components/BusinessPartner/felicitation';
import RessourcesTable from './components/Dashboard_BP/RessourcesTable';
import DemanderRessource from './components/BusinessPartner/DemanderRessource';
import IaMatching from './components/IaMatching';
import ClientsTable from './components/Dashboard_BP/ClientsTable';
import MenuClientsProfils from './components/menuClientProfils' ;
import MenuBusinessPartner from './components/businessPartnerMenu';
import {
  Profile,
  Missions,
  Chat,
  TestsTechniques,
  AccountSettingsPage,
} from './containers'
import TestPage from './containers/freelancerWorkspace/TestPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EntityDetails from './components/EntityDetails';


import SuiviPaiement from "./components/PaiementClient/suiviEtat";


import AddClientForm from "./components/Dashboard_BP/AddClientForm";
import ListeDemandes from "./components/Dashboard_BP/ListeDemandes";
import "react-toastify/dist/ReactToastify.css";
import "./styles/Buttons.css";
import Checkout from "./components/PaiementClient/checkoutPage";
import TransactionsTable from "./components/PaiementClient/transactionsTable";
import Freelancersolde from "./components/PaiementClient/solde";
import TransactionsFreelancer from "./components/PaiementClient/TransactionsFreelancer";
import AdminExcel from "./components/PaiementClient/AdminExcel";
import CardsPage from "./components/PaiementClient/CardsPage";
import TicketDetail from "./components/TicketDetail";
const ClientCard = lazy(() => import("./components/ClientCard"));

const App = () => {
  useEffect(() => {
    // Listen for browser's back/forward navigation
    window.onpopstate = () => {
      window.location.reload(); // Force a full page refresh
    };

    // Cleanup the event listener on component unmount
    return () => {
      window.onpopstate = null;
    };
  }, []);
  const { user, userType } = useAuth();
  const [hoverIndex, setHoverIndex] = useState(-1);

  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(-1);
  };

  return (
    <Provider store={store}>
      <ToastContainer />
      <Router>
        <AuthProvider>
          <Routes>
            <Route
              path="/worker-login"
              element={<WorkerLogin apiGatewayUrl={API_GATEWAY_URL} />}
            />
            <Route path="/provider-welcome" element={<ProviderWelcomePage />} />
            <Route path="/worker-welcome" element={<WorkerWelcomePage />} />
            <Route
              path="/worker-signup/:token"
              element={<WorkerCreationForm />}
            />
            <Route
              path="/edit-expertise/:expertiseId"
              element={<EditExpertisePage />}
            />
            <Route
              path="/provider-profile-update"
              element={<ProviderUpdateForm />}
            />
            <Route path="/provider-profile" element={<ProviderProfile />} />
            <Route path="/provider-resources" element={<ProviderResources />} />
            <Route
              path="/provider-expertises"
              element={<ProviderExpertises />}
            />
            <Route path="/add-expertise" element={<AddExpertisePage />} />
            <Route
              path="/provider-add-contact"
              element={<ProviderContactAddPage />}
            />
            <Route path="/worker-profile" element={<WorkerProfile />} />
            <Route path="/verify/success" element={<SuccessPage />} />
            <Route path="/verify/error" element={<ErrorPage />} />
            <Route path="/verify/:token" element={<VerifyPage />} />
            <Route
              path="/verify-client/:token"
              element={<VerifyClientPage />}
            />
            <Route path="/invite-worker" element={<WorkerInviteForm />} />
            <Route
              path="/worker-profile-update"
              element={<WorkerUpdateForm />}
            />
            <Route
              path="/edit-worker/:workerId"
              element={<ProviderWorkerUpdateForm />}
            />
            <Route
              path="/businesspartner-welcome"
              element={<BusinessPartnerWelcomePage />}
            />
            <Route
              path="/businesspartner-profile"
              element={<BusinessPartnerProfile />}
            />
            <Route
              path="/business-partner-request"
              element={
                <BusinessPartnerRequestForm apiGatewayUrl={API_GATEWAY_URL} />
              }
            />
            <Route
              path="/business-partner-signup"
              element={<BusinessPartnerSignUp />}
            />{" "}
            <Route path="/" element={<WelcomeComponent />} />
            <Route
              path="/business-partner-signup/:bp_id"
              element={
                <BusinessPartnerSignUp apiGatewayUrl={API_GATEWAY_URL} />
              }
            />
            <Route path="/" element={<WelcomeComponent />} />
            <Route path="/type_de_profile" element={<TypeDeProfilsPage />} />
            <Route
              path="/inscription-client"
              element={<InscriptionClientBodyComponent />}
            />
            <Route
              path="/inscription-provider"
              element={<InscriptionProviderBodyComponent />}
            />
            <Route
              path="/guide-inscription-provider"
              element={<GuideInscriptionProviderPage />}
            />
            <Route path="/client-Dashboard" element={<ClientDashboardPage />} />
            <Route path="/client-demandes" element={<ClientDemandesPage />} />
            <Route path="/validation-email" element={<ValidationMailPage />} />
            <Route
              path="/logIn"
              element={
                <LoginPage
                  apiGatewayUrl={API_GATEWAY_URL}
                  linkedInClientId={LINKEDIN_CLIENT_ID}
                  linkedInRedirectUri={LINKEDIN_REDIRECT_URI}
                />
              }
            />
            <Route
              path="/linkedin-login-callback"
              element={
                <LinkedinLoginCallback apiGatewayUrl={API_GATEWAY_URL} />
              }
            />
            <Route
              path="/guide-inscription-freelancer"
              element={<GuideInscriptionFreelancePage />}
            />
            <Route
              path="/inscription-freelancer"
              element={<FreelancerInscriptionBodyComponent />}
            />
            <Route
              path="/freelancer-Dashboard"
              element={<Profile />}
            />
            <Route path="/admin-dashboard2" element={<DashboardAdmin />} />

            <Route path="/admin-dashboard/matching" element={<DashboardAdminP2 />} />
            <Route path="/admin-dashboard/liste-des-clients" element={<DashboardAdminP3 />} />
            <Route path="/freelancer-validation-email" element={<FreelancerValidationMailPage />} />
            <Route path="/worker-validation-email" element={<WorkerValidationMailPage />} />
            <Route path="/provider-validation-email" element={<ProviderValidationMailPage />} />
            <Route path="/modify-demande/:demandeId" element={<ModifyDemandePage />} />
            <Route path="/demandes-cours-redaction" element={<DemandeCoursRedaction />} />
            <Route path="/demandes-attente-matching" element={<DemandeAttenteMatching />} />
            <Route path="/admin/menu-clients-profils" element={<MenuClientsProfils />} />
            <Route path="/admin/menu-businesspartner" element={<MenuBusinessPartner />} />

            <Route path="/client-demandes" element={<ClientDemandesPage />} />
            <Route
              path="/demandes-en-attente-selection"
              element={<DemandeAttenteSelection />}
            />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/profil" element={<Profil />} />
            <Route
              path="/demandes-attente-matching"
              element={<DemandeAttenteMatching />}
            />
            <Route
              path="/demandes-en-cours"
              element={<ClientDemandesEnCoursPage />}
            />
            <Route
              path="/demandes-en-evaluation"
              element={<ClientDemandesEnEvaluationPage />}
            />
            <Route path="/aide-support" element={<ClientAideSupportPage />} />
            <Route path="/aide-support/:ticketId" element={<TicketDetail />} />
            <Route path="/create-demande" element={<CreateDemandePage />} />
            <Route
              path="/admin-login"
              element={<AdminLoginPage apiGatewayUrl={API_GATEWAY_URL} />}
            />
            <Route
              path="/admin/requests"
              element={<AdminClientRequestsTable />}
            />
            <Route path="/admin-Dashboard" element={<AdminDashboardPage />} />
            <Route path="/admin/providers" element={<AdminProvidersTable />} />


            <Route path="/admin-request-detail/:demandeId" element={<AdminRequestDetail2 />} />
            <Route path="/admin-request-detail2/:demandeId" element={<AdminRequestDetail2/>} />
            <Route path="/entity-details" element={<EntityDetails/>} />
            {/* ia filtrage */}
            {/* <Route path="/admin-request-detail2/iamatching/:demandeId" element={<IaMatching />} /> */}


            <Route path="/admin/retraits" element={<AdminExcel/>} />
            <Route
              path="/admin-request-detail/:demandeId"
              element={<AdminRequestDetail />}
            />

            <Route path="/planning" element={<Planning />} />
            <Route
              path="/select-resources/:demandeId"
              element={<SelectResourcesPage />}
            />
            {/* Ajoutez cette ligne */}
            <Route
              path="/client/propositions/:demandeId"
              element={<SelectResourcesPage />}
            />
            <Route
              path="/provider-missions/ongoing"
              element={<ProviderDemandesEnCoursPage />}
            />
            <Route
              path="/provider-missions/pending"
              element={<ProviderDemandesEnAttentePage />}
            />
            <Route
              path="/provider-missions/completed"
              element={<ProviderDemandesTermineesPage />}
            />
            <Route path="/profile" element={<Profile />} />
            <Route path="/missions" element={<Missions />} />
            <Route path="/chat" element={<Chat />} />

            <Route path="/teststechniques" element={<TestsTechniques />} />

            <Route path="/transactions-freelancer" element={<TransactionsFreelancer />} />

            <Route path="/account-settings" element={<AccountSettingsPage />} />

            <Route
              path="/evaluer-demande/:demandeId"
              element={<FeedbackForm />}
            />

            <Route path="/test-page" element={<TestPage />} />
            <Route path="/evaluer-demande/:demandeId" element={<FeedbackForm />} />

            <Route path="/feedbacks" element={<Feedbacks />} />
            <Route path="/dashboard-bp" element={<DashboardBP />} />
            <Route path="/felicitation" element={<Felicitation />} />
            <Route
              path="/ressourcesBP"
              element={<RessourcesTable apiGatewayUrl={API_GATEWAY_URL} />}
            />
            <Route
              path="/demanderRessource"
              element={<DemanderRessource apiGatewayUrl={API_GATEWAY_URL} />}
            />
            <Route path="/clients-bp" element={<ClientsTable />} />
            <Route path="/ressources-bp" element={<RessourcesTable />} />
            <Route path="/add-client-bp" element={<AddClientForm />} />
            <Route path="/liste-demandes" element={<ListeDemandes />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/transactions" element={<TransactionsTable />} />
            <Route path="/cards" element={<CardsPage />} />
            <Route path="/suivi" element={<SuiviPaiement/>} />
          </Routes>
        </AuthProvider>
      </Router>
    </Provider>
  );
};

export default App;

