import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { API_GATEWAY_URL, PROVIDER_SERVICE_URL } from "../constants";
import AdminCard from "./admin/AdminCard";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import {
  Button,
  Chip,
  Checkbox,
  Modal,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import Swal from "sweetalert2";
import iaicon from "../assets/IAicon.png";
import notificationsIcon from "../assets/notifications.png";
import settingsIcon from "../assets/settings.png";

const AdminRequestDetail2 = () => {
  const navigate = useNavigate();
  const { demandeId } = useParams();
  const [requestDetail, setRequestDetail] = useState(null);
  const [entities, setEntities] = useState([]);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [proposedResources, setProposedResources] = useState([]);
  const [expertises, setExpertises] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    const fetchRequestDetail = async () => {
      try {
        if (!user) {
          setError("Aucun utilisateur connecté");
          setLoading(false);
          navigate("/admin-login");
          return;
        }
        const token = user.access_token;
        const response = await axios.get(
          `${API_GATEWAY_URL}/admin-client-demandes/${demandeId}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setRequestDetail(response.data);
        console.log(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    const fetchEntities = async () => {
      try {
        if (!user) {
          setError("Aucun utilisateur connecté");
          setLoading(false);
          navigate("/admin-login");
          return;
        }
        const token = user.access_token;
        const response = await axios.get(
          `${API_GATEWAY_URL}/all-entities/${demandeId}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data);
        const providers = response.data.providers.map((provider) => ({
          ...provider,
          type: "Entreprise",
          expertises: provider.expertises || [],
          expertises_detail: provider.expertises_detail || [],
          id: provider.id,
        }));
        const workers = response.data.workers.map((worker) => ({
          ...worker,
          type: "Entreprise (employé)",
          name: `${worker.name}`,
          expertises: worker.expertises || [],
          expertises_detail: worker.expertises_detail || [],
          id: worker.id,
        }));
        const freelancers = response.data.freelancers.map((freelancer) => ({
          ...freelancer,
          type: "Freelancer",
          name: `${freelancer.name}`,
          expertises: freelancer.expertises || [],
          expertises_detail: freelancer.expertises_detail || [],
          id: freelancer.id,
        }));

        const allEntities = [...providers, ...workers, ...freelancers];
        setEntities(allEntities); // Sauvegarder les entités avant le filtrage
        setProposedResources(response.data.proposed_resources || []);
        setSelectedEntities(response.data.proposed_resources || []); // Initialiser selectedEntities
        console.log(
          "ressources déjà proposées : ",
          response.data.proposed_resources
        );
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchExpertises = async () => {
      try {
        const response = await axios.get(`${API_GATEWAY_URL}/expertises/`);
        setExpertises(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchRequestDetail();
    fetchEntities();
    fetchExpertises();
  }, [demandeId, user]);

  useEffect(() => {
    if (!requestDetail || !entities.length) return; // Vérifier si les données sont disponibles

    const getExpertiseMatchCount = (entityExpertises, selectedExpertises) => {
      const entityExpertiseIds = entityExpertises.map(
        (expertise) => expertise.id
      );
      return selectedExpertises.filter((expertiseId) =>
        entityExpertiseIds.includes(expertiseId)
      ).length;
    };

    const sortedEntities = entities.sort((a, b) => {
      const aMatchCount = getExpertiseMatchCount(
        a.expertises_detail,
        requestDetail.selectedExpertises
      );
      const bMatchCount = getExpertiseMatchCount(
        b.expertises_detail,
        requestDetail.selectedExpertises
      );

      // Trier par ordre décroissant (ceux avec plus de correspondances en haut)
      return bMatchCount - aMatchCount;
    });

    console.log("sortedEntities", sortedEntities);
    setEntitiesToDisplay(sortedEntities);
  }, [requestDetail, entities]);

  const handleSelect = (entityId) => {
    setSelectedEntities((prevSelected) => {
      const newSelected = prevSelected.includes(entityId)
        ? prevSelected.filter((id) => id !== entityId)
        : [...prevSelected, entityId];
      console.log("Selected Entities:", newSelected);
      return newSelected;
    });
  };

  const handleIaMatching = () => {
    axios
      .get(`${PROVIDER_SERVICE_URL}/ai-proposition/${demandeId}/`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      })
      .then((response) => {
        console.log(response.data.Data);
        const updatedEntities = entitiesToDisplay.map((entity) => {
          const match = response.data.Data.find(
            (item) => item.id === entity.id
          );
          if (match) {
            handleSelect(match.id)
            return {
              ...entity,
              proposedByIa: true,
              classement: match.classement,
              argument: match.argument,
              justification: match.justification,
            };
          }
          return entity;
        });

        // On trie les entités en fonction de la propriété proposedByIa
        const sortedEntities = updatedEntities.sort((a, b) => {
          if (a.proposedByIa && !b.proposedByIa) {
            return -1;
          }
          if (!a.proposedByIa && b.proposedByIa) {
            return 1;
          }
          if (a.proposedByIa && b.proposedByIa) {
            if (a.proposedById && b.proposedById) {
              return a.classement - b.classement; // Tri basé sur `classement`
            }
          }
          return 0;
        });
        setEntitiesToDisplay(sortedEntities);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getExpertiseNameById = (id) => {
    const expertise = expertises.find((exp) => exp.id === id);
    return expertise ? expertise.name : "";
  };

  const expertiseLevelMap = {
    Junior: "JR",
    Confirmé: "CF",
    Sénior: "SR",
    Expert: "SR",
  };

  const handleAssignResources = async () => {
    const selectedResources = selectedEntities.map(entityId => {
      const [type, id] = entityId.split('_');
      return { type, id };
    });

    console.log('Selected entities', selectedEntities); // Vérifiez les données dans la console

    Swal.fire({
      title: 'Assignation des ressources',
      text: 'Veuillez patienter...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const token = user.access_token;
      await axios.post(`${API_GATEWAY_URL}/admin-proposition/`, {
        demandeId,
        resources: selectedEntities
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json' // Assurez-vous que les données sont envoyées sous forme de JSON
        },
      });

      await axios.post(`${API_GATEWAY_URL}/update-demande-statut/${demandeId}/`, {
        statut: "WS"
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      Swal.fire({
        icon: 'success',
        title: 'Succès',
        text: 'Les ressources ont été assignées avec succès!',
        confirmButtonText: 'OK'
      });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: `Échec de l'assignation des ressources: ${err.message}`,
        confirmButtonText: 'OK'
      });
    }
  };

  // les fonctions et states de filtres

  const [showList, setShowList] = useState(false);
  const [selectedExpertises, setSelectedExpertises] = useState([]);
  const [entitiesToDisplay, setEntitiesToDisplay] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredExpertises = expertises.filter((expertise) =>
    expertise.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [showFiltersExpertise, setShowFiltersExpertise] = useState(true);
  const [showExpertiseOptions, setShowExpertiseOptions] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const columns = [
    {
      field: "select",
      headerName: "Sélectionné",
      width: 80,
      renderCell: (params) => (
        <Checkbox
          checked={selectedEntities.includes(params.row.id)}
          onChange={() => handleSelect(params.row.id)}
        />
      ),
    },
    { field: "name", headerName: "Nom", width: 150 },
    { field: "phone", headerName: "Numéro de téléphone", width: 150 },
    { field: "email", headerName: "Email", width: 220 },
    { field: "type", headerName: "Type", width: 100 },
    {
      field: "isAvailable",
      headerName: "Disponible",
      width: 100,
      renderCell: (params) => (
        <Chip
          label={params.row.isAvailable ? "Oui" : "Non"}
          style={{
            margin: 2,
            backgroundColor: params.row.isAvailable ? "#90ee90" : "#fff033",
          }}
        />
      ),
    },
    {
      field: "expertises",
      headerName: "Expertises",
      width: 500,
      renderCell: (params) => (
        <div>
          {params.row.expertises_detail &&
            params.row.expertises_detail.map((expDetail, index) => {
              const levels = ["JR", "CF", "SR", "EX"];
              const expertiseName = getExpertiseNameById(expDetail.id);
              const requestLevelCode =
                expertiseLevelMap[requestDetail.expertise_level_readable];
              const matched =
                requestDetail.selectedExpertises.includes(expDetail.id) &&
                levels.indexOf(expDetail.level) >=
                  levels.indexOf(requestLevelCode);

              let chipColor = "";
              if (matched) {
                chipColor = "#90ee90"; // Light green
              } else if (
                requestDetail.selectedExpertises.includes(expDetail.id)
              ) {
                chipColor = "#FFF033"; // Light yellow
              }

              return (
                <Chip
                  key={index}
                  label={`${expertiseName} - ${expDetail.level}`}
                  style={{ margin: 2, backgroundColor: chipColor }}
                />
              );
            })}
        </div>
      ),
    },
    {
      field: "proposedByIa",
      headerName: "Proposé",
      width: 180,
      renderCell: (params) =>
        params.row.proposedByIa ? (
          <Chip
            label="Proposé"
            style={{ margin: 2, backgroundColor: "#ADD8E6", cursor: "pointer" }}
            onClick={() => handleOpen(params.row)}
          />
        ) : (
          <Chip
            label="Non proposé"
            style={{ margin: 2, backgroundColor: "#fff033" , cursor: "pointer"}}
            onClick={() => handleOpen(params.row)}
          />
        ),
    },
    {
      field: "validation_type",
      headerName: "Type de validation",
      width: 180,
    },
    {
      field: "Nombre de missions",
      headerName: "Nb missions",
      width: 180,
      renderCell: (params) => {
        return (
          <div className="">
            <p className="">{params.row.missions.length} missions</p>
          </div>
        );
      },
    },
    {
      field: "details",
      headerName: "Détails",
      width: 150,
      renderCell: (params) => {
        const handleDetailsClick = () => {
          navigate("/entity-details", { state: { entity: params.row } });
        };

        return (
          <Button
            variant="contained"
            color="primary"
            onClick={handleDetailsClick}
          >
            Check Détails
          </Button>
        );
      },
    },
  ];

  const handleExpertiseChange = (expertise) => {
    const updatedExpertises = selectedExpertises.includes(expertise)
      ? selectedExpertises.filter((item) => item !== expertise)
      : [...selectedExpertises, expertise];

    setSelectedExpertises(updatedExpertises);
    if (updatedExpertises.length === 0) {
      setEntitiesToDisplay(entities);
    } else {
      const filteredEntities = entities.filter((entity) =>
        entity.expertises.some((expertise) => {
          // Extraire la partie avant " - " pour obtenir le nom de l'expertise
          const expertiseName = expertise.split(" - ")[0];
          // Comparer avec les expertises sélectionnées, en ignorant la casse
          return updatedExpertises.some(
            (selectedExpertise) => expertiseName === selectedExpertise["name"]
          );
        })
      );
      setEntitiesToDisplay(filteredEntities);
    }
  };

  //les fonctions et states de popup de filtrage par ai
  const [open, setOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const handleOpen = (entity) => {
    setSelectedEntity(entity);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleJustificationChange = (newJustification) => {
    setSelectedEntity({ ...selectedEntity, justification: newJustification });
  };

  const handleValidate = () => {
    if (!selectedEntity.justification) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Veuillez saisir une justification",
        confirmButtonText: "OK",
      });
    }else{
      if (!selectedEntity.proposedByIa) {
        selectedEntity.proposedByIa = true;
        handleSelect(selectedEntity.id)
      }
      setEntitiesToDisplay((prevEntitiesToDisplay) => {
        return prevEntitiesToDisplay.map((entity) =>
          entity.id === selectedEntity.id
            ? { ...entity, justification: selectedEntity.justification, proposedByIa : selectedEntity.proposedByIa }
            : entity
        );
      });
      console.log("Justification validée:", selectedEntity.justification);
      Swal.fire({
        icon: "success",
        title: "Succès",
        text: "Justification validée avec succès!",
        confirmButtonText: "OK",
        customClass: {
          popup: 'swal2-front'
        }
      });
      handleClose();
    }
  };

  return (
    <div className="bg-[#F4F4F4]  min-h-screen">
      <AdminCard />
      <div className="ml-[18%] w-[82%]">
        <div className="w-full flex justify-end">
          <div className="w-[20%] h-16 flex flex-row justify-around items-center mr-8">
            <div className="h-8 w-8 bg-white rounded-full flex justify-center items-center cursor-pointer">
              <img
                className="h-6 w-6"
                src={notificationsIcon}
                alt="notifications-icon"
              />
            </div>
            <div className="h-8 w-8 bg-white rounded-full flex justify-center items-center cursor-pointer">
              <img
                className="h-6 w-6 "
                src={settingsIcon}
                alt="settings-icon"
              />
            </div>
            <div className="h-[60%] w-20 flex flex-col items-end justify-evenly text-[12px]">
              <p className="text-[#10316B] ">Nabila A.</p>
              <p className="text-[#C1BBEB]">Admin</p>
            </div>
            <div className="h-8 w-8 bg-[#C1BBEB] rounded-full"></div>
          </div>
        </div>
        <h2 className=" text-[#10316B] ml-16 font-poppins  font-bold ">
          Détail de la demande
        </h2>
        <div className="bg-white rounded-3xl py-8 ml-16 mt-6 w-[70%]">
          {requestDetail && (
            <div className="flex justify-around">
              <div className="flex-row items-start justify-normal space-y-8 ">
                <p className="text-[#10316B] font-poppins text-sm font-bold ">
                  ID demande :{" "}
                  <span className="text-black font-normal">
                    {requestDetail.id}
                  </span>
                </p>
                <p className="text-[#10316B] font-poppins text-sm font-bold  ">
                  ID Client :{" "}
                  <span className="text-black font-normal">
                    {requestDetail.client}
                  </span>
                </p>
                <p className="text-[#10316B] font-poppins text-sm font-bold  ">
                  Nom Client :{" "}
                  <span className="text-black font-normal">
                    {requestDetail.clientname_readable}
                  </span>
                </p>
                <p className="text-[#10316B] font-poppins text-sm font-bold  ">
                  Post Demandé :{" "}
                  <span className="text-black font-normal">
                    {requestDetail.posteTitle}
                  </span>
                </p>
                <p className="text-[#10316B] font-poppins text-sm font-bold  ">
                  Type de Projet:{" "}
                  <span className="text-black font-normal">
                    {requestDetail.project_type_readable}
                  </span>
                </p>
              </div>
              <div className="flex-row items-start justify-normal space-y-8 ">
                <p className="text-[#10316B] font-poppins text-sm font-bold  ">
                  Niveau d'Expertise:{" "}
                  <span className="text-black font-normal">
                    {requestDetail.expertise_level_readable}
                  </span>
                </p>
                <p className="text-[#10316B] font-poppins text-sm font-bold  ">
                  Statut:{" "}
                  <span className="text-black font-normal">
                    {requestDetail.statut_readable}
                  </span>
                </p>
                <p className="text-[#10316B] font-poppins text-sm font-bold  ">
                  Date de Début:{" "}
                  <span className="text-black font-normal">
                    {requestDetail.date_debut}
                  </span>
                </p>
                <p className="text-[#10316B] font-poppins text-sm font-bold  ">
                  Date de Fin:{" "}
                  <span className="text-black font-normal">
                    {requestDetail.date_fin}
                  </span>
                </p>
                <p className="text-[#10316B] font-poppins text-sm font-bold  ">
                  Expertises sélectionnées:{" "}
                  <span className="text-black font-normal">
                    {requestDetail.selectedExpertises.map(
                      (expertiseId, index) => (
                        <Chip
                          key={index}
                          label={getExpertiseNameById(expertiseId)}
                          style={{ margin: 2 }}
                        />
                      )
                    )}
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-row justify-between items-center  w-full mt-4 px-8">
          <h2 className="text-[#10316B] font-poppins font-bold text-fluid-md">
            Profils
          </h2>
          <div className="flex flex-row  w-[35%]  justify-around items-center">
            <div className="relative">
              <div
                className="border-2 border-[#10316B] font-poppins text-[#10316B] ease-in-out rounded-3xl flex items-center justify-center text-center cursor-pointer px-6 py-2"
                onClick={() => setShowList(!showList)} // Toggle affichage de la liste
              >
                <span>Filtres</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-5 w-5 ml-2 transition-transform duration-300 ${
                    showList ? "rotate-180" : ""
                  }`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4.24 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 01.02-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>

              {/* Liste des expertises (affichée si showList est true) */}
              {showList && (
                <div className="absolute top-12 left-0 w-80 border mt-2 p-2 rounded-lg shadow-lg bg-white z-10">
                  <p className="font-bold mb-2 text-center">
                    Sélectionnez vos expertises :
                  </p>

                  {/* Barre de recherche */}
                  <input
                    type="text"
                    placeholder="Rechercher..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="mb-4 p-2 border outline-none rounded w-full"
                  />

                  <ul className="flex flex-col max-h-72 overflow-y-auto">
                    {filteredExpertises.length > 0 ? (
                      filteredExpertises.map((expertise) => (
                        <li
                          key={expertise.id}
                          className="flex items-center mb-2 hover:bg-gray-200 w-full cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            id={expertise.id}
                            checked={selectedExpertises.includes(expertise)}
                            onChange={() => handleExpertiseChange(expertise)}
                            className="cursor-pointer"
                          />
                          <label
                            className="cursor-pointer ml-2"
                            htmlFor={expertise.id}
                          >
                            {expertise.name}
                          </label>
                        </li>
                      ))
                    ) : (
                      <li className="text-center text-gray-500">
                        Aucune expertise trouvée
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>

            <div
              onClick={handleIaMatching}
              className="bg-[#10316B] font-poppins text-[#F4F4F4]   ease-in-out px-4 py-2 rounded-3xl flex items-center  justify-center text-center space-x-4 cursor-pointer"
            >
              <img src={iaicon} alt="ia-icon" />
              <span>Matching par IA</span>
            </div>

            <div
              onClick={handleAssignResources}
              className="bg-[#006400] font-poppins text-[#F4F4F4]   ease-in-out px-4 py-2 rounded-3xl flex items-center  justify-center text-center space-x-4 cursor-pointer"
            >
              <img src={iaicon} alt="ia-icon" />
              <span>Proposer les ressources</span>
            </div>			
	
          </div>
        </div>
        {/* here the table */}
        <div className="h-[600px]  mt-6 px-8">
          <DataGrid
            rows={entitiesToDisplay}
            columns={columns}
            pageSize={10}
            hideFooterSelectedRowCount
            disableSelectionOnClick
            sortingOrder={["desc", "asc"]}
          />
        </div>

        <Modal open={open} onClose={handleClose} sx={{ zIndex: 999 }}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 700,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              outline: "none", 
              border: "2px solid #1976d2",
              zIndex : 1000, 
            }}
          >
            {selectedEntity && (
              <>
                <Typography variant="h5" component="h2" gutterBottom>
                  Détails de l'entité
                </Typography>
                <Typography sx={{ mt: 2 }}>ID: {selectedEntity.id}</Typography>
                <Typography sx={{ mt: 2 }}>
                  Nom: {selectedEntity.name}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                  Classement: {selectedEntity.classement}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                  Argument: {selectedEntity.argument}
                </Typography>

                {/* Justification modifiable */}
                <Typography sx={{ mt: 2 }}>Justification:</Typography>

                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  value={selectedEntity.justification}
                  onChange={(e) => handleJustificationChange(e.target.value)}
                  sx={{ mt: 1, mb: 2 }}
                />
                {/* Bouton Valider */}
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleValidate}
                    sx={{ textTransform: "none", fontWeight: "bold" }} // Bouton avec du texte en minuscule et gras
                  >
                    Valider
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default AdminRequestDetail2;
