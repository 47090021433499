// Dashboard.js
import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import StatsCards from "./StatsCards";
import TopClientsTable from "./TopClientsTable";
import TopResourcesTable from "./TopResourcesTable";

const Dashboard = () => {
  return (
    <div className="pw-page-layout ">
      <Header />
      <div className="w-full flex flex-col p-4">
        <Sidebar />
        <div className="flex flex-col justify-center items-center">
          <StatsCards />
          <div className="mt-6 flex flex-wrap">
            <div className=" mr-4 mb-4">
              <TopClientsTable />
            </div>
            <div className=" ml-4">
              <TopResourcesTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
