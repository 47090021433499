import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "./Navbarr";
import ClientCard from "./ClientCard";
import { API_GATEWAY_URL } from "../constants";
import { useAuth } from "./AuthContext";
import "../index.css";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [viewedNotifications, setViewedNotifications] = useState(new Set());
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    // Fetch notifications from your backend API
    const fetchNotifications = async () => {
      try {
        const response = await fetch(`${API_GATEWAY_URL}/api/notifications/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.access_token}`, // Add authorization using the user's token
          },
        });

        if (response.ok) {
          const fetchedNotifications = await response.json();

          // Reverse the order of notifications
          const reversedNotifications = fetchedNotifications.reverse();
          setNotifications(reversedNotifications);

          // Retrieve viewed notifications from sessionStorage
          const savedViewedNotifications =
            JSON.parse(sessionStorage.getItem("viewedNotifications")) || [];
          setViewedNotifications(new Set(savedViewedNotifications));

          // If it's not the initial load, mark all notifications as viewed
          if (!isInitialLoad) {
            const newViewedNotifications = new Set(
              fetchedNotifications.map((notification) => notification.id)
            );
            sessionStorage.setItem(
              "viewedNotifications",
              JSON.stringify(Array.from(newViewedNotifications))
            );
            setViewedNotifications(newViewedNotifications);
          }

          setIsInitialLoad(false); // Set initial load to false
        } else {
          throw new Error("Error fetching notifications.");
        }
      } catch (error) {
        console.error("Error fetching notifications:", error.message);
      }
    };

    if (user && user.access_token) {
      fetchNotifications(); // Fetch notifications only if user and token are available
    }
  }, [isInitialLoad]);

  const handleNotificationClick = (id) => {
    if (!viewedNotifications.has(id)) {
      // Mark notification as viewed
      const updatedViewedNotifications = new Set(viewedNotifications).add(id);
      setViewedNotifications(updatedViewedNotifications);

      // Save updated viewed notifications to sessionStorage
      sessionStorage.setItem(
        "viewedNotifications",
        JSON.stringify(Array.from(updatedViewedNotifications))
      );
    }
  };

  /* Only for testing purposes
  const mockNotifications = [
    {
      id: 1,
      title: "Notification 1",
      content: "This is the content of notification 1.",
      created_at: "2024-09-16T14:30:00",
    },
    {
      id: 2,
      title: "Notification 2",
      content: "This is the content of notification 2.",
      created_at: "2024-09-16T15:00:00",
    },
    {
      id: 3,
      title: "Notification 3",
      content: "This is the content of notification 3.",
      created_at: "2024-09-16T16:00:00",
    },
  ];*/

  return (
    <div className="pw-page-layout">
      <Navbar />
      <div className="w-full flex flex-col p-4">
        <ClientCard />
        <div className="flex flex-col justify-center items-center text-primary-800 font-body">
          <h1 className="mt-4 mb-4  font-bold  max-2xl:text-fluid-md 2xl:text-fluid-lg">
            Mes Notifications
          </h1>
          {notifications.length > 0 ? (
            <ul className="flex flex-wrap gap-4 ml-11">
              {notifications.map((notification) => (
                <li
                  key={notification.id}
                  onClick={() => handleNotificationClick(notification.id)}
                  className={`mb-2 p-4 border rounded-lg cursor-pointer MediumShadowContainer max-2xl:text-fluid-ms 2xl:text-fluid-sm ${
                    viewedNotifications.has(notification.id)
                      ? "bg-white"
                      : "bg-yellow-100"
                  }`}
                >
                  <h2 className="max-2xl:text-fluid-md 2xl:text-fluid-lg font-semibold">
                    {notification.title}
                  </h2>
                  <p>{notification.content}</p>
                  <p className="">
                    {new Date(notification.created_at).toLocaleString()}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <p>Aucune notification disponible.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
