import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
// import { setFormData, nextStep, prevStep, resetForm } from '../clientInscriptionFormSlice';
import "../../../styles/FreelancerMultiStepForm.css";
import "../../../index.css";
import FreelancerMultiStepForm from "./FreelancerMultiStepForm";

const FreelancerInscriptionBodyComponent = () => {
  return (
    <div className="loginbackgroundImage">
      <div className="container-fluid m-10">
        <FreelancerMultiStepForm />
      </div>
    </div>
  );
};

export default FreelancerInscriptionBodyComponent;
