import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { API_GATEWAY_URL } from "../constants";
import { useAuth } from "./AuthContext";
import {
  Button,
  CircularProgress,
  Chip,
  Card,
  CardContent,
  CardActions,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import ClientCard from "./ClientCard"; // Importer le composant ClientCard
import LoadingSpinner from "./LoadingSpinner";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import Navbar from "./Navbarr";
import PaymentPanel from "./PaymentPanel";
import PaymentInfoPanel from "./PaymentInfoPanel";
import "../index.css";

const ResourceType = {
  PROVIDER: "Provider",
  WORKER: "Worker",
  FREELANCER: "Freelancer",
};

const ResourceTypeTranslation = {
  [ResourceType.PROVIDER]: "Prestataire",
  [ResourceType.WORKER]: "Prestataire (employé)",
  [ResourceType.FREELANCER]: "Freelance",
};

const translateResourceType = (type) => {
  return ResourceTypeTranslation[type] || type;
};

const SelectResourcesPage = () => {
  const { demandeId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expertises, setExpertises] = useState([]);
  const [clientData, setClientData] = useState(null);
  const [demande, setDemande] = useState(null); // Nouvel état pour stocker les informations de la demande

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await fetch(`${API_GATEWAY_URL}/client-profile/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.access_token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setClientData(data);
          console.log("Client data:", data);
        } else {
          throw new Error(
            "Erreur lors de la récupération des informations du profil client."
          );
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchDemande = async () => {
      try {
        const response = await axios.get(
          `${API_GATEWAY_URL}/client-demande/${demandeId}/`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );

        if (response.status === 200) {
          setDemande(response.data);
        } else {
          throw new Error(
            "Erreur lors de la récupération des informations de la demande."
          );
        }
      } catch (err) {
        setError(err.message);
      }
    };

    if (user && user.access_token) {
      fetchClientData();
      fetchDemande();
    }
  }, [user, demandeId]);

  useEffect(() => {
    const fetchPropositions = async () => {
      try {
        const token = user.access_token;
        const response = await axios.get(
          `${API_GATEWAY_URL}/client-propositions/${demandeId}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Flatten profiles from propositions
        const allProfiles = [];
        console.log("Propositions:", response.data);
        response.data.forEach((prop) => {
          prop.freelancers.forEach((f) =>
            allProfiles.push({
              ...f,
              type: ResourceType.FREELANCER,
              propositionId: prop.id,
            })
          );
          prop.providers.forEach((p) =>
            allProfiles.push({
              ...p,
              type: ResourceType.PROVIDER,
              propositionId: prop.id,
            })
          );
          prop.workers.forEach((w) =>
            allProfiles.push({
              ...w,
              type: ResourceType.WORKER,
              propositionId: prop.id,
            })
          );
        });
        setProfiles(allProfiles);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchExpertises = async () => {
      try {
        const response = await axios.get(`${API_GATEWAY_URL}/expertises/`);
        setExpertises(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchPropositions();
    fetchExpertises();
  }, [demandeId, user]);

  const handleSelect = async (id, type) => {
    const token = user.access_token;
    const resourceId = id.split("_")[1];
    console.log("Selected profile ID:", user);
  
    const data = {
      client_id: user.client_id,
      demande_id: demandeId,
      resource_id: resourceId,
      resource_type: type,
    };
    
    // Show the payment panel first
    PaymentPanel({
      onConfirm: () => {
        
        Swal.fire({
          title: "Chargement",
          text: "Veuillez patienter...",
          icon: "info",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
  
        axios
          .put(`${API_GATEWAY_URL}/select-resource/`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log("Success response:", response);
            setProfiles((prevProfiles) =>
              prevProfiles.map((profile) =>
                profile.id === id ? { ...profile, selected: true } : profile
              )
            );
            axios
              .post(
                `${API_GATEWAY_URL}/update-demande-statut-on-selection/${demandeId}/`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                }
              )
              
              .catch((error) => {
                Swal.fire(
                  "Erreur",
                  `Échec de la mise à jour du statut de la demande: ${error.message}`,
                  "error"
                );
              });
          })
          .catch((error) => {
            console.error("Error response:", error.response);
            Swal.fire(
              "Erreur",
              `Échec de la sélection de la ressource: ${error.message}`,
              "error"
            );
          })
          .finally(() => {

            Swal.close();
            navigate("/checkout", {
              state: {
                client_id: user.client_id,
                demande_id: demandeId,
                resource_id: resourceId,
                resource_type: type,
              },
            });
          } );
      },
      onCancel: () => {
        Swal.fire("Annulé", "L'opération a été annulée.", "info");
      },
    });
  };

  const getExpertiseNameById = (id) => {
    const expertise = expertises.find((exp) => exp.id === id);
    return expertise ? expertise.name : "";
  };

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="CDP-page-layout flex">
      <Navbar />
      <div className="CDP-content-container">
        {clientData && <ClientCard clientData={clientData} />}
        <div style={{ flex: 1, padding: "20px" }}>
          {demande && (
            <div className="CDPmission-section">
              <div className="MediumShadowContainer text-primary-800 text-fluid-xs font-body">
                <h>
                  <strong>Demande: {demande.posteTitle}</strong>
                </h>
                <br />
                <h>
                  <strong>Type de Projet:</strong>{" "}
                  {demande.project_type_readable}
                </h>
                <br />
                <h>
                  <strong>Niveau d'Expertise:</strong>{" "}
                  {demande.expertise_level_readable}
                </h>
                <br />
                <h>
                  <strong>Date de Début:</strong> {demande.date_debut}
                </h>
                <br />
                <h>
                  <strong>Date de Fin:</strong> {demande.date_fin}
                </h>
              </div>
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "12px",
              justifyContent: "center",
            }}
          >
            {profiles.map((profile) => (
              <Card
                key={profile.id}
                style={{
                  width: "280px",
                  margin: "12px",
                  padding: "16px",
                  backgroundColor: "#ffffff",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  transition: "transform 0.2s, box-shadow 0.2s",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0 6px 12px rgba(0, 0, 0, 0.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0 4px 8px rgba(0, 0, 0, 0.1)";
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold", marginBottom: "8px" }}
                  >
                    {profile.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <FaPhoneAlt style={{ marginRight: "8px" }} />
                    {profile.phone || "Non fourni"}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <IoMdMail style={{ marginRight: "8px" }} />
                    {profile.email || "Non fourni"}
                  </Typography>
                  <Typography variant="body2" style={{ marginBottom: "8px" }}>
                    {translateResourceType(profile.type)}
                  </Typography>
                  <Typography variant="body2" style={{ marginBottom: "8px" }}>
                    {profile.validation_type}
                  </Typography>
                  <div
                    style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}
                  >
                    {profile.expertises_detail &&
                      profile.expertises_detail.map((expDetail, index) => {
                        const expertiseName = getExpertiseNameById(
                          expDetail.id
                        );
                        return (
                          <Chip
                            key={index}
                            label={`${expertiseName} - ${expDetail.level}`}
                            style={{
                              margin: 2,
                              backgroundColor: "#e0f7fa",
                              color: "#00796b",
                            }}
                          />
                        );
                      })}
                  </div>
                </CardContent>
                <CardActions style={{ justifyContent: "center" }}>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      Swal.fire({
                        title: "Confirmation",
                        text: `Voulez-vous sélectionner : ${profile.name} ?`,
                        icon: "question",
                        showCancelButton: true,
                        confirmButtonText: "Oui",
                        cancelButtonText: "Non",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          handleSelect(profile.id, profile.type);
                        }
                      });
                    }}
                    style={{ backgroundColor: "#15803d", color: "#ffffff" }} // Tailwind's green-600 color code
                  >
                    Sélectionner ce profil
                  </Button>
                </CardActions>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectResourcesPage;
