import React, { useState, useEffect } from "react"; // Import useState and useEffect
import { useNavigate } from "react-router-dom";
import { FaBell, FaUserCircle, FaSignOutAlt } from "react-icons/fa";
import { useAuth } from "./AuthContext";
import { API_GATEWAY_URL } from "../constants";
import "../index.css";
import { cleanupChatbot } from './ChatbotReact';

import "../styles/Buttons.css";
const Navbarr = ({title}) => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [clientData, setClientData] = useState(null);
  const [loading, setLoading] = useState(true); // Add state for loading
  const [error, setError] = useState(null); // Add state for error

  const handleNewRequest = () => {
    navigate("/create-demande");
  };

  const handleLogout = () => {
    console.log("SE DECONNECTER");
    cleanupChatbot(); // Clean up chatbot before logging out
    logout();
    navigate("/");
  };

  const handleNotifications = () => {
    navigate("/notifications");
    window.location.reload(); // Reload the page on navigation
  };

  const handleProfile = () => {
    navigate("/profil");
    window.location.reload(); // Reload the page on navigation
  };

  useEffect(() => {
    const fetchClientData = async () => {
      setLoading(true);
      try {
        const profileResponse = await fetch(
          `${API_GATEWAY_URL}/client-profile/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );

        if (!profileResponse.ok)
          throw new Error(
            "Erreur lors de la récupération des données du profil."
          );

        const profileData = await profileResponse.json();
        console.log("data", profileData);
        setClientData(profileData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (user && user.access_token) {
      fetchClientData();
    }
  }, [user]);

  return (
    <nav className="flex items-center w-full px-6 py-3 -mt-6 bg-white border-b shadow-md ">
      <div className="flex items-center flex-1">
        {/* Client Name Section */}
        <div className="flex items-center">
          {loading ? (
            <span className="text-gray-500">...</span>
          ) : error ? (
            <span className="text-red-500">Erreur: {error}</span>
          ) : user && clientData ? (
            <h className="ml-20 font-bold text-primary-800 font-body max-2xl:text-fluid-sm 2xl:text-fluid-lg">
              Bienvenue,{" "}
              <span className="text-secondary-500 ">
                {clientData.clientName}
              </span>
            </h>
          ) : (
            <span className="text-red-500">Client Data Missing</span>
          )}
        </div>
      </div>
      <div className="flex items-center space-x-4 mr-7 ">
        <div className="relative max-sm:hidden">
          <button
            onClick={handleNewRequest}
            className="flex btn-red max-2xl:text-fluid-xs 2xl:text-fluid-sm sm:hidden"
          >
           {title}
          </button>
        </div>
        <button
          onClick={handleNotifications}
          className="p-1 transition-colors duration-300 bg-transparent text-primary-800 hover:text-primary-950"
        >
          <FaBell className="max-2xl:h-5 max-2xl:w-5 2xl:w-8 2xl:h-8" />
        </button>
        <button
          onClick={handleProfile}
          className="p-1 transition-colors duration-300 bg-transparent text-primary-800 hover:text-primary-950"
        >
          <FaUserCircle className="max-2xl:h-5 max-2xl:w-5 2xl:w-8 2xl:h-8" />
        </button>
        <button
          onClick={handleLogout}
          className="p-1 transition-colors duration-300 bg-transparent text-primary-800 hover:text-primary-950"
        >
          <FaSignOutAlt className="max-2xl:h-5 max-2xl:w-5 2xl:w-8 2xl:h-8" />
        </button>
      </div>
    </nav>
  );
};

export default Navbarr;
