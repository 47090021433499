import React from "react";

const TopClientsTable = () => {
  const clients = [
    {
      id: 1,
      nomComplet: "John Doe",
      nomEntreprise: "ABC Inc.",
      sommeConsommee: 1000,
    },
    {
      id: 2,
      nomComplet: "Jane Smith",
      nomEntreprise: "XYZ Corp.",
      sommeConsommee: 800,
    },
    {
      id: 3,
      nomComplet: "Bob Johnson",
      nomEntreprise: " DEF Ltd.",
      sommeConsommee: 1200,
    },
    {
      id: 4,
      nomComplet: "Alice Brown",
      nomEntreprise: "GHI Inc.",
      sommeConsommee: 900,
    },
    {
      id: 5,
      nomComplet: "Mike Davis",
      nomEntreprise: "JKL Corp.",
      sommeConsommee: 1100,
    },
    {
      id: 6,
      nomComplet: "Emily Chen",
      nomEntreprise: "MNO Ltd.",
      sommeConsommee: 1300,
    },
    {
      id: 7,
      nomComplet: "David Lee",
      nomEntreprise: "PQR Inc.",
      sommeConsommee: 1000,
    },
    {
      id: 8,
      nomComplet: "Sarah Taylor",
      nomEntreprise: "STU Corp.",
      sommeConsommee: 800,
    },
    {
      id: 9,
      nomComplet: "Kevin White",
      nomEntreprise: "VWX Ltd.",
      sommeConsommee: 1200,
    },
    {
      id: 10,
      nomComplet: "Lisa Nguyen",
      nomEntreprise: "YZA Inc.",
      sommeConsommee: 900,
    },
  ];

  return (
    <div className="font-body text-primary-800 max-2xl:text-fluid-xs 2xl:text-fluid-sm">
      <h2 className="max-2xl:text-fluid-md 2xl:text-fluid-lg font-bold  mb-4">
        Top 10 Clients
      </h2>

      <table
        className="w-full rounded-md overflow-hidden"
        style={{ borderRadius: 12 }}
      >
        <thead>
          <tr>
            <th
              className="px-4 py-2 bg-primary-800 border border-[#F0F0F0] text-white"
              style={{ borderTopLeftRadius: 12 }}
            >
              Nom Complet
            </th>
            <th className="px-4 py-2 bg-primary-800 border border-[#F0F0F0] text-white">
              Nom Entreprise
            </th>
            <th
              className="px-4 py-2 bg-primary-800 border border-[#F0F0F0] text-white"
              style={{ borderTopRightRadius: 12 }}
            >
              Somme Consommée
            </th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => (
            <tr key={client.id}>
              <td className="px-4 py-2 border border-[#F0F0F0]">
                {client.nomComplet}
              </td>
              <td className="px-4 py-2 border border-[#F0F0F0]">
                {client.nomEntreprise}
              </td>
              <td className="px-4 py-2 border border-[#F0F0F0]">
                {client.sommeConsommee}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopClientsTable;
