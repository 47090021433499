import React from "react";

const RessourcesTable = () => {
  const ressources = [
    { id: 1, nomRessource: "John Doe", tauxOccupation: 80, prixDAchat: 50000 },
    {
      id: 2,
      nomRessource: "Jane Smith",
      tauxOccupation: 60,
      prixDAchat: 40000,
    },
    {
      id: 3,
      nomRessource: "Michael Brown",
      tauxOccupation: 90,
      prixDAchat: 60000,
    },
    {
      id: 4,
      nomRessource: "Emily Johnson",
      tauxOccupation: 70,
      prixDAchat: 45000,
    },
    { id: 5, nomRessource: "David Lee", tauxOccupation: 85, prixDAchat: 55000 },
  ];

  return (
    <div className="font-body text-primary-800 max-2xl:text-fluid-xs 2xl:text-fluid-sm">
      <h2 className="max-2xl:text-fluid-md 2xl:text-fluid-lg font-bold mb-4">
        Top 10 Ressources
      </h2>

      <table
        className="w-full rounded-md overflow-hidden"
        style={{ borderRadius: 12 }}
      >
        <thead>
          <tr>
            <th
              className="px-4 py-2 bg-primary-800 border border-[#F0F0F0] text-white"
              style={{ borderTopLeftRadius: 12 }}
            >
              Nom Ressource
            </th>
            <th className="px-4 py-2 bg-primary-800 border border-[#F0F0F0] text-white">
              Taux d'occupation
            </th>
            <th
              className="px-4 py-2 bg-primary-800 border border-[#F0F0F0] text-white"
              style={{ borderTopRightRadius: 12 }}
            >
              Prix d'achat
            </th>
          </tr>
        </thead>
        <tbody>
          {ressources.map((ressource) => (
            <tr key={ressource.id}>
              <td className="px-4 py-2 border border-[#F0F0F0]">
                {ressource.nomRessource}
              </td>
              <td className="px-4 py-2 border border-[#F0F0F0]">
                {ressource.tauxOccupation}%
              </td>
              <td className="px-4 py-2 border border-[#F0F0F0]">
                {ressource.prixDAchat} €
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RessourcesTable;
