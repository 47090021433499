import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import authReducer from './slices/authentificationSlice';
//import clientDataReducer from './slices/clientDataSlice';

import clientInscriptionFormReducer from './slices/clientInscriptionFormSlice';

import freelancerInscriptionFormReducer from './slices/freelancerInscriptionFormSlice';

import providerInscriptionFormReducer from './slices/providerInscriptionFormSlice';


import authentificationReducer from './slices/authentificationSlice';
import validationEmailFormReducer from './slices/validationEmailFormSlice';



const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['apiError', 'successMessage'], // properties to exclude from persistence
};

const clientDataPersistConfig = {
  key: 'clientData',
  storage,
  blacklist: [], // properties to exclude from persistence
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
//const persistedClientDataReducer = persistReducer(clientDataPersistConfig, clientDataReducer);

const rootReducer = {
  auth: persistedAuthReducer,
  clientData: [],
  clientInscriptionForm: clientInscriptionFormReducer,

  freelancerInscriptionForm: freelancerInscriptionFormReducer,
  providerInscriptionForm: providerInscriptionFormReducer,


  validationEmailForm: validationEmailFormReducer,
  authentification: authentificationReducer,
};

const store = configureStore({
  reducer: 
    rootReducer
    // clientInscriptionForm: clientInscriptionFormReducer,
    // validationEmailForm: validationEmailFormReducer,
    // authentification: authentificationReducer,
});

export const persistor = persistStore(store);
export default store;
