const countriesWithContinent = [
    { name: "Afghanistan", abbreviation: "AF", timezone: "Asia/Kabul" },
    { name: "Afrique du Sud", abbreviation: "ZA", timezone: "Africa/Johannesburg" },
    { name: "Albanie", abbreviation: "AL", timezone: "Europe/Tirane" },
    { name: "Algérie", abbreviation: "DZ", timezone: "Africa/Algiers" },
    { name: "Allemagne", abbreviation: "DE", timezone: "Europe/Berlin" },
    { name: "Andorre", abbreviation: "AD", timezone: "Europe/Andorra" },
    { name: "Angola", abbreviation: "AO", timezone: "Africa/Luanda" },
    { name: "Antigua-et-Barbuda", abbreviation: "AG", timezone: "America/Antigua" },
    { name: "Arabie Saoudite", abbreviation: "SA", timezone: "Asia/Riyadh" },
    { name: "Argentine", abbreviation: "AR", timezone: "America/Argentina/Buenos_Aires" },
    { name: "Arménie", abbreviation: "AM", timezone: "Asia/Yerevan" },
    { name: "Australie", abbreviation: "AU", timezone: "Australia/Sydney" },
    { name: "Autriche", abbreviation: "AT", timezone: "Europe/Vienna" },
    { name: "Azerbaïdjan", abbreviation: "AZ", timezone: "Asia/Baku" },
    { name: "Bahamas", abbreviation: "BS", timezone: "America/Nassau" },
    { name: "Bahreïn", abbreviation: "BH", timezone: "Asia/Bahrain" },
    { name: "Bangladesh", abbreviation: "BD", timezone: "Asia/Dhaka" },
    { name: "Barbade", abbreviation: "BB", timezone: "America/Barbados" },
    { name: "Belgique", abbreviation: "BE", timezone: "Europe/Brussels" },
    { name: "Belize", abbreviation: "BZ", timezone: "America/Belize" },
    { name: "Bénin", abbreviation: "BJ", timezone: "Africa/Porto-Novo" },
    { name: "Bhoutan", abbreviation: "BT", timezone: "Asia/Thimphu" },
    { name: "Biélorussie", abbreviation: "BY", timezone: "Europe/Minsk" },
    { name: "Birmanie", abbreviation: "MM", timezone: "Asia/Yangon" },
    { name: "Bolivie", abbreviation: "BO", timezone: "America/La_Paz" },
    { name: "Bosnie-Herzégovine", abbreviation: "BA", timezone: "Europe/Sarajevo" },
    { name: "Botswana", abbreviation: "BW", timezone: "Africa/Gaborone" },
    { name: "Brésil", abbreviation: "BR", timezone: "America/Sao_Paulo" },
    { name: "Brunei", abbreviation: "BN", timezone: "Asia/Brunei" },
    { name: "Bulgarie", abbreviation: "BG", timezone: "Europe/Sofia" },
    { name: "Burkina Faso", abbreviation: "BF", timezone: "Africa/Ouagadougou" },
    { name: "Burundi", abbreviation: "BI", timezone: "Africa/Bujumbura" },
    { name: "Cambodge", abbreviation: "KH", timezone: "Asia/Phnom_Penh" },
    { name: "Cameroun", abbreviation: "CM", timezone: "Africa/Douala" },
    { name: "Canada", abbreviation: "CA", timezone: "America/Toronto" },
    { name: "Cap-Vert", abbreviation: "CV", timezone: "Atlantic/Cape_Verde" },
    { name: "République centrafricaine", abbreviation: "CF", timezone: "Africa/Bangui" },
    { name: "Chili", abbreviation: "CL", timezone: "America/Santiago" },
    { name: "Chine", abbreviation: "CN", timezone: "Asia/Shanghai" },
    { name: "Chypre", abbreviation: "CY", timezone: "Asia/Nicosia" },
    { name: "Colombie", abbreviation: "CO", timezone: "America/Bogota" },
    { name: "Comores", abbreviation: "KM", timezone: "Indian/Comoro" },
    { name: "Congo", abbreviation: "CG", timezone: "Africa/Brazzaville" },
    { name: "République Démocratique du Congo", abbreviation: "CD", timezone: "Africa/Kinshasa" },
    { name: "Corée du Sud", abbreviation: "KR", timezone: "Asia/Seoul" },
    { name: "Corée du Nord", abbreviation: "KP", timezone: "Asia/Pyongyang" },
    { name: "Costa Rica", abbreviation: "CR", timezone: "America/Costa_Rica" },
    { name: "Côte d'Ivoire", abbreviation: "CI", timezone: "Africa/Abidjan" },
    { name: "Croatie", abbreviation: "HR", timezone: "Europe/Zagreb" },
    { name: "Cuba", abbreviation: "CU", timezone: "America/Havana" },
    { name: "Danemark", abbreviation: "DK", timezone: "Europe/Copenhagen" },
    { name: "Djibouti", abbreviation: "DJ", timezone: "Africa/Djibouti" },
    { name: "Dominique", abbreviation: "DM", timezone: "America/Dominica" },
    { name: "République dominicaine", abbreviation: "DO", timezone: "America/Santo_Domingo" },
    { name: "Égypte", abbreviation: "EG", timezone: "Africa/Cairo" },
    { name: "Émirats arabes unis", abbreviation: "AE", timezone: "Asia/Dubai" },
    { name: "Équateur", abbreviation: "EC", timezone: "America/Guayaquil" },
    { name: "Érythrée", abbreviation: "ER", timezone: "Africa/Asmara" },
    { name: "Espagne", abbreviation: "ES", timezone: "Europe/Madrid" },
    { name: "Estonie", abbreviation: "EE", timezone: "Europe/Tallinn" },
    { name: "États-Unis", abbreviation: "US", timezone: "America/New_York" },
    { name: "Éthiopie", abbreviation: "ET", timezone: "Africa/Addis_Ababa" },
    { name: "Fidji", abbreviation: "FJ", timezone: "Pacific/Fiji" },
    { name: "Finlande", abbreviation: "FI", timezone: "Europe/Helsinki" },
    { name: "France", abbreviation: "FR", timezone: "Europe/Paris" },
    { name: "Gabon", abbreviation: "GA", timezone: "Africa/Libreville" },
    { name: "Gambie", abbreviation: "GM", timezone: "Africa/Banjul" },
    { name: "Géorgie", abbreviation: "GE", timezone: "Asia/Tbilisi" },
    { name: "Ghana", abbreviation: "GH", timezone: "Africa/Accra" },
    { name: "Grèce", abbreviation: "GR", timezone: "Europe/Athens" },
    { name: "Grenade", abbreviation: "GD", timezone: "America/Grenada" },
    { name: "Guatemala", abbreviation: "GT", timezone: "America/Guatemala" },
    { name: "Guinée", abbreviation: "GN", timezone: "Africa/Conakry" },
    { name: "Guinée-Bissau", abbreviation: "GW", timezone: "Africa/Bissau" },
    { name: "Guinée équatoriale", abbreviation: "GQ", timezone: "Africa/Malabo" },
    { name: "Guyana", abbreviation: "GY", timezone: "America/Guyana" },
    { name: "Haïti", abbreviation: "HT", timezone: "America/Port-au-Prince" },
    { name: "Honduras", abbreviation: "HN", timezone: "America/Tegucigalpa" },
    { name: "Hongrie", abbreviation: "HU", timezone: "Europe/Budapest" },
    { name: "Îles Marshall", abbreviation: "MH", timezone: "Pacific/Majuro" },
    { name: "Îles Salomon", abbreviation: "SB", timezone: "Pacific/Guadalcanal" },
    { name: "Inde", abbreviation: "IN", timezone: "Asia/Kolkata" },
    { name: "Indonésie", abbreviation: "ID", timezone: "Asia/Jakarta" },
    { name: "Iran", abbreviation: "IR", timezone: "Asia/Tehran" },
    { name: "Iraq", abbreviation: "IQ", timezone: "Asia/Baghdad" },
    { name: "Irlande", abbreviation: "IE", timezone: "Europe/Dublin" },
    { name: "Islande", abbreviation: "IS", timezone: "Atlantic/Reykjavik" },
    { name: "Israël", abbreviation: "IL", timezone: "Asia/Jerusalem" },
    { name: "Italie", abbreviation: "IT", timezone: "Europe/Rome" },
    { name: "Jamaïque", abbreviation: "JM", timezone: "America/Jamaica" },
    { name: "Japon", abbreviation: "JP", timezone: "Asia/Tokyo" },
    { name: "Jordanie", abbreviation: "JO", timezone: "Asia/Amman" },
    { name: "Kazakhstan", abbreviation: "KZ", timezone: "Asia/Almaty" },
    { name: "Kenya", abbreviation: "KE", timezone: "Africa/Nairobi" },
    { name: "Kirghizistan", abbreviation: "KG", timezone: "Asia/Bishkek" },
    { name: "Kiribati", abbreviation: "KI", timezone: "Pacific/Tarawa" },
    { name: "Koweït", abbreviation: "KW", timezone: "Asia/Kuwait" },
    { name: "Laos", abbreviation: "LA", timezone: "Asia/Vientiane" },
    { name: "Lesotho", abbreviation: "LS", timezone: "Africa/Maseru" },
    { name: "Lettonie", abbreviation: "LV", timezone: "Europe/Riga" },
    { name: "Liban", abbreviation: "LB", timezone: "Asia/Beirut" },
    { name: "Liberia", abbreviation: "LR", timezone: "Africa/Monrovia" },
    { name: "Libye", abbreviation: "LY", timezone: "Africa/Tripoli" },
    { name: "Liechtenstein", abbreviation: "LI", timezone: "Europe/Vaduz" },
    { name: "Lituanie", abbreviation: "LT", timezone: "Europe/Vilnius" },
    { name: "Luxembourg", abbreviation: "LU", timezone: "Europe/Luxembourg" },
    { name: "Macédoine du Nord", abbreviation: "MK", timezone: "Europe/Skopje" },
    { name: "Madagascar", abbreviation: "MG", timezone: "Indian/Antananarivo" },
    { name: "Malaisie", abbreviation: "MY", timezone: "Asia/Kuala_Lumpur" },
    { name: "Malawi", abbreviation: "MW", timezone: "Africa/Blantyre" },
    { name: "Maldives", abbreviation: "MV", timezone: "Indian/Maldives" },
    { name: "Mali", abbreviation: "ML", timezone: "Africa/Bamako" },
    { name: "Malte", abbreviation: "MT", timezone: "Europe/Malta" },
    { name: "Maroc", abbreviation: "MA", timezone: "Africa/Casablanca" },
    { name: "Îles Marshall", abbreviation: "MH", timezone: "Pacific/Majuro" },
    { name: "Maurice", abbreviation: "MU", timezone: "Indian/Mauritius" },
    { name: "Mauritanie", abbreviation: "MR", timezone: "Africa/Nouakchott" },
    { name: "Mexique", abbreviation: "MX", timezone: "America/Mexico_City" },
    { name: "Micronésie", abbreviation: "FM", timezone: "Pacific/Chuuk" },
    { name: "Moldavie", abbreviation: "MD", timezone: "Europe/Chisinau" },
    { name: "Monaco", abbreviation: "MC", timezone: "Europe/Monaco" },
    { name: "Mongolie", abbreviation: "MN", timezone: "Asia/Ulaanbaatar" },
    { name: "Monténégro", abbreviation: "ME", timezone: "Europe/Podgorica" },
    { name: "Mozambique", abbreviation: "MZ", timezone: "Africa/Maputo" },
    { name: "Namibie", abbreviation: "NA", timezone: "Africa/Windhoek" },
    { name: "Nauru", abbreviation: "NR", timezone: "Pacific/Nauru" },
    { name: "Népal", abbreviation: "NP", timezone: "Asia/Kathmandu" },
    { name: "Nicaragua", abbreviation: "NI", timezone: "America/Managua" },
    { name: "Niger", abbreviation: "NE", timezone: "Africa/Niamey" },
    { name: "Nigeria", abbreviation: "NG", timezone: "Africa/Lagos" },
    { name: "Norvège", abbreviation: "NO", timezone: "Europe/Oslo" },
    { name: "Nouvelle-Zélande", abbreviation: "NZ", timezone: "Pacific/Auckland" },
    { name: "Oman", abbreviation: "OM", timezone: "Asia/Muscat" },
    { name: "Ouganda", abbreviation: "UG", timezone: "Africa/Kampala" },
    { name: "Ouzbékistan", abbreviation: "UZ", timezone: "Asia/Tashkent" },
    { name: "Pakistan", abbreviation: "PK", timezone: "Asia/Karachi" },
    { name: "Palaos", abbreviation: "PW", timezone: "Pacific/Palau" },
    { name: "Palestine", abbreviation: "PS", timezone: "Asia/Gaza" },
    { name: "Panama", abbreviation: "PA", timezone: "America/Panama" },
    { name: "Papouasie-Nouvelle-Guinée", abbreviation: "PG", timezone: "Pacific/Port_Moresby" },
    { name: "Paraguay", abbreviation: "PY", timezone: "America/Asuncion" },
    { name: "Pays-Bas", abbreviation: "NL", timezone: "Europe/Amsterdam" },
    { name: "Pérou", abbreviation: "PE", timezone: "America/Lima" },
    { name: "Philippines", abbreviation: "PH", timezone: "Asia/Manila" },
    { name: "Pologne", abbreviation: "PL", timezone: "Europe/Warsaw" },
    { name: "Portugal", abbreviation: "PT", timezone: "Europe/Lisbon" },
    { name: "Qatar", abbreviation: "QA", timezone: "Asia/Qatar" },
    { name: "Roumanie", abbreviation: "RO", timezone: "Europe/Bucharest" },
    { name: "Royaume-Uni", abbreviation: "GB", timezone: "Europe/London" },
    { name: "Russie", abbreviation: "RU", timezone: "Europe/Moscow" },
    { name: "Rwanda", abbreviation: "RW", timezone: "Africa/Kigali" },
    { name: "Saint-Christophe-et-Niévès", abbreviation: "KN", timezone: "America/St_Kitts" },
    { name: "Sainte-Lucie", abbreviation: "LC", timezone: "America/St_Lucia" },
    { name: "Saint-Vincent-et-les-Grenadines", abbreviation: "VC", timezone: "America/St_Vincent" },
    { name: "Salomon", abbreviation: "SB", timezone: "Pacific/Guadalcanal" },
    { name: "Salvador", abbreviation: "SV", timezone: "America/El_Salvador" },
    { name: "Samoa", abbreviation: "WS", timezone: "Pacific/Apia" },
    { name: "Saint-Marin", abbreviation: "SM", timezone: "Europe/San_Marino" },
    { name: "Sao Tomé-et-Principe", abbreviation: "ST", timezone: "Africa/Sao_Tome" },
    { name: "Sénégal", abbreviation: "SN", timezone: "Africa/Dakar" },
    { name: "Serbie", abbreviation: "RS", timezone: "Europe/Belgrade" },
    { name: "Seychelles", abbreviation: "SC", timezone: "Indian/Mahe" },
    { name: "Sierra Leone", abbreviation: "SL", timezone: "Africa/Freetown" },
    { name: "Singapour", abbreviation: "SG", timezone: "Asia/Singapore" },
    { name: "Slovaquie", abbreviation: "SK", timezone: "Europe/Bratislava" },
    { name: "Slovénie", abbreviation: "SI", timezone: "Europe/Ljubljana" },
    { name: "Somalie", abbreviation: "SO", timezone: "Africa/Mogadishu" },
    { name: "Soudan", abbreviation: "SD", timezone: "Africa/Khartoum" },
    { name: "Soudan du Sud", abbreviation: "SS", timezone: "Africa/Juba" },
    { name: "Sri Lanka", abbreviation: "LK", timezone: "Asia/Colombo" },
    { name: "Suède", abbreviation: "SE", timezone: "Europe/Stockholm" },
    { name: "Suisse", abbreviation: "CH", timezone: "Europe/Zurich" },
    { name: "Suriname", abbreviation: "SR", timezone: "America/Paramaribo" },
    { name: "Swaziland", abbreviation: "SZ", timezone: "Africa/Mbabane" },
    { name: "Syrie", abbreviation: "SY", timezone: "Asia/Damascus" },
    { name: "Tadjikistan", abbreviation: "TJ", timezone: "Asia/Dushanbe" },
    { name: "Tanzanie", abbreviation: "TZ", timezone: "Africa/Dar_es_Salaam" },
    { name: "Tchad", abbreviation: "TD", timezone: "Africa/Ndjamena" },
    { name: "République tchèque", abbreviation: "CZ", timezone: "Europe/Prague" },
    { name: "Thaïlande", abbreviation: "TH", timezone: "Asia/Bangkok" },
    { name: "Timor oriental", abbreviation: "TL", timezone: "Asia/Dili" },
    { name: "Togo", abbreviation: "TG", timezone: "Africa/Lome" },
    { name: "Tonga", abbreviation: "TO", timezone: "Pacific/Tongatapu" },
    { name: "Trinité-et-Tobago", abbreviation: "TT", timezone: "America/Port_of_Spain" },
    { name: "Tunisie", abbreviation: "TN", timezone: "Africa/Tunis" },
    { name: "Turkménistan", abbreviation: "TM", timezone: "Asia/Ashgabat" },
    { name: "Turquie", abbreviation: "TR", timezone: "Europe/Istanbul" },
    { name: "Tuvalu", abbreviation: "TV", timezone: "Pacific/Funafuti" },
    { name: "Ukraine", abbreviation: "UA", timezone: "Europe/Kiev" },
    { name: "Uruguay", abbreviation: "UY", timezone: "America/Montevideo" },
    { name: "Vanuatu", abbreviation: "VU", timezone: "Pacific/Efate" },
    { name: "Vatican", abbreviation: "VA", timezone: "Europe/Vatican" },
    { name: "Venezuela", abbreviation: "VE", timezone: "America/Caracas" },
    { name: "Viêt Nam", abbreviation: "VN", timezone: "Asia/Ho_Chi_Minh" },
    { name: "Yémen", abbreviation: "YE", timezone: "Asia/Aden" },
    { name: "Zambie", abbreviation: "ZM", timezone: "Africa/Lusaka" },
    { name: "Zimbabwe", abbreviation: "ZW", timezone: "Africa/Harare" }
];

export default countriesWithContinent;