import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_GATEWAY_URL } from "../constants";
import { useAuth } from "./AuthContext";
import "../index.css";

const FeedbackCard = ({ feedback }) => {
  const [demande, setDemande] = useState(null);
  const { user } = useAuth();

  // useEffect(() => {
  //     console.log(feedback)
  //     // Fetch the demande details using the demande_id from the mission
  //     const fetchDemandeDetails = async () => {
  //         try {
  //             const response = await axios.get(`${API_GATEWAY_URL}/client-demande/${feedback.mission}/`, {
  //                 headers: {
  //                     'Authorization': `Bearer ${user.access_token}`
  //                 }
  //             });
  //             setDemande(response.data);
  //         } catch (error) {
  //             console.error("Échec de la récupération des détails de la demande :", error);
  //         }
  //     };

  //     fetchDemandeDetails();
  // }, [feedback.mission, user.access_token]);

  return (
    <div className="MediumShadowContainer flex-shrink-0 transition-transform transform hover:scale-105">
      <h4 className="max-2xl:text-fluid-md 2xl:text-fluid-lg font-bold mb-2 text-primary-800">
        Détails du retour
      </h4>
      <div className=" text-primary-800 max-2xl:text-fluid-xs 2xl:text-fluid-sm">
        <span>
          <strong>Note :</strong> {feedback.note}
        </span>
        <br />
        <span>
          <strong>Commentaire :</strong> {feedback.commentaire}
        </span>
        <br />
        <div>
          <span>
            <strong>Retour sur la personne :</strong>{" "}
            {feedback.feedback_personne}
          </span>
          <br />
          <span>
            <strong>Retour sur le travail :</strong> {feedback.feedback_work}
          </span>
          <br />
          <span>
            <strong>Retour sur la plateforme :</strong>{" "}
            {feedback.feedback_plateforme}
          </span>
          <br />
        </div>
      </div>
    </div>
  );
};

export default FeedbackCard;
