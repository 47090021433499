import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/fr';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import { API_GATEWAY_URL } from '../../src/constants.js';
import '../styles/AvailabilityCalendar.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

moment.locale('fr');
const localizer = momentLocalizer(moment);

const AvailabilityCalendar = ({ user }) => {
  const [events, setEvents] = useState([]);
  const [availabilities, setAvailabilities] = useState([]);
  const [newEvent, setNewEvent] = useState({
    start: '',
    end: '',
    partOfDay: 'full-day',
    recurrence: { days: [], frequency: 'weekly' }
  });

  const [isRecurring, setIsRecurring] = useState(false);
  const [isRecurringOptions, setIsRecurringOptions] = useState(['weekly', 'biweekly']);
  const [dateError, setDateError] = useState('');

  const generateRecurringEvents = (event) => {
    const { start, end, part_of_day, recurrence } = event;
    const eventStart = moment(start).startOf('day');
    const eventEnd = moment(end).endOf('day');
    const title = "Disponible";
    const events = [];
    const daysOfWeek = recurrence.days;

    let shouldSkip = false;

    while (eventStart.isBefore(eventEnd)) {
      if (daysOfWeek.includes(eventStart.day())) {
        if (!shouldSkip) {
          const eventDetails = {
            title,
            start: eventStart.clone().startOf('day').toDate(),
            end: eventStart.clone().endOf('day').toDate(),
            allDay: part_of_day === 'full-day'
          };

          if (part_of_day === 'morning') {
            eventDetails.start = eventStart.clone().set({ hour: 8, minute: 0 }).toDate();
            eventDetails.end = eventStart.clone().set({ hour: 12, minute: 0 }).toDate();
          } else if (part_of_day === 'afternoon') {
            eventDetails.start = eventStart.clone().set({ hour: 13, minute: 0 }).toDate();
            eventDetails.end = eventStart.clone().set({ hour: 17, minute: 0 }).toDate();
          }

          events.push(eventDetails);
        }
      }

      eventStart.add(1, 'day');

      if (eventStart.week() !== moment(start).week() && eventStart.isoWeekday() === 1) {
        if (shouldSkip) {
          shouldSkip = false;
        } else if (recurrence.frequency === 'biweekly') {
          shouldSkip = true;
          eventStart.add(0, 'week');
        }
      }
    }
    return events;
  };

  const generateSingleDayEvents = (event) => {
    const { start, end, part_of_day } = event;
    const eventStart = moment(start);
    const eventEnd = moment(end);
    const title = "Disponible";
    const events = [];

    while (eventStart.isBefore(eventEnd) || eventStart.isSame(eventEnd, 'day')) {
      if (part_of_day === 'morning') {
        events.push({
          title,
          start: eventStart.clone().set({ hour: 8, minute: 0 }).toDate(),
          end: eventStart.clone().set({ hour: 12, minute: 0 }).toDate(),
          allDay: false
        });
      } else if (part_of_day === 'afternoon') {
        events.push({
          title,
          start: eventStart.clone().set({ hour: 13, minute: 0 }).toDate(),
          end: eventStart.clone().set({ hour: 17, minute: 0 }).toDate(),
          allDay: false
        });
      } else {
        events.push({
          title,
          start: eventStart.clone().startOf('day').toDate(),
          end: eventStart.clone().endOf('day').toDate(),
          allDay: true
        });
      }
      eventStart.add(1, 'day');
    }

    return events;
  };

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${API_GATEWAY_URL}/availabilities/`, {
        headers: { Authorization: `Bearer ${user.access_token}` },
      });

      console.log(response.data);

      const allEvents = response.data.flatMap(event => {
        if (event.recurrence && (event.recurrence.frequency === 'weekly' || event.recurrence.frequency === 'biweekly')) {
          return generateRecurringEvents(event);
        } else {
          return generateSingleDayEvents(event);
        }
      });

      setEvents(allEvents);
      setAvailabilities(response.data.sort((a, b) => new Date(b.start) - new Date(a.start)));
    } catch (error) {
      console.error('Failed to fetch events', error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [user]);

  useEffect(() => {
    const start = moment(newEvent.start);
    const end = moment(newEvent.end);
    const today = moment().startOf('day');

    if (start.isBefore(today)) {
      setDateError("La date de début ne peut pas être antérieure à aujourd'hui.");
    } else if (end.isBefore(start)) {
      setDateError("La date de fin ne peut pas être antérieure à la date de début.");
    } else {
      setDateError('');
    }

    setIsRecurringOptions(['weekly', 'biweekly']);

    if (newEvent.start !== '' && newEvent.end !== '') {
      setIsRecurring(true);
    } else {
      setIsRecurring(false);
    }
  }, [newEvent.start, newEvent.end]);

  const handleAddEvent = async () => {
    if (dateError) {
      Swal.fire('Erreur', dateError, 'error');
      return;
    }

    Swal.fire({
      title: 'Ajout de la disponibilité...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      let startDate = new Date(newEvent.start);
      let endDate = new Date(newEvent.end);
      endDate.setHours(23, 59, 59, 999);

      const payload = {
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        part_of_day: newEvent.partOfDay,
        allDay: newEvent.partOfDay === 'full-day',
        recurrence: isRecurring ? newEvent.recurrence : { days: [], frequency: '' }
      };

      await axios.post(`${API_GATEWAY_URL}/availabilities/`, payload, {
        headers: { Authorization: `Bearer ${user.access_token}` },
      });

      fetchEvents();

      setNewEvent({
        start: '',
        end: '',
        partOfDay: 'full-day',
        recurrence: { days: [], frequency: '' }
      });
      setIsRecurring(false);
      Swal.fire('Succès', 'La disponibilité a été ajoutée.', 'success');
    } catch (error) {
      Swal.fire('Erreur', 'Échec de l\'ajout de la disponibilité.', 'error');
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setNewEvent(prevState => ({
        ...prevState,
        recurrence: {
          ...prevState.recurrence,
          days: checked
            ? [...prevState.recurrence.days, parseInt(value)]
            : prevState.recurrence.days.filter(day => day !== parseInt(value))
        }
      }));
    } else if (name === 'recurrence.frequency') {
      setNewEvent(prevState => ({
        ...prevState,
        recurrence: {
          ...prevState.recurrence,
          frequency: value
        }
      }));
    } else {
      setNewEvent(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleRecurrenceChange = (e) => {
    setIsRecurring(e.target.checked);
    if (!e.target.checked) {
      setNewEvent(prevState => ({
        ...prevState,
        recurrence: { days: [], frequency: '' }
      }));
    }
  };

  const handleDeleteAvailability = async (id) => {
    Swal.fire({
      title: 'Suppression de la disponibilité...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      await axios.delete(`${API_GATEWAY_URL}/availability-delete/${id}/`, {
        headers: { Authorization: `Bearer ${user.access_token}` },
      });
      fetchEvents();
      Swal.fire('Succès', 'La disponibilité a été supprimée.', 'success');
    } catch (error) {
      Swal.fire('Erreur', 'Échec de la suppression de la disponibilité.', 'error');
    }
  };

  const translatePartOfDay = (partOfDay) => {
    switch (partOfDay) {
      case 'morning':
        return 'Matin';
      case 'afternoon':
        return 'Après-midi';
      case 'full-day':
        return 'Journée entière';
      default:
        return partOfDay;
    }
  };

  const translateFrequency = (frequency) => {
    switch (frequency) {
      case 'weekly':
        return 'Hebdomadaire';
      case 'biweekly':
        return 'Bihebdomadaire';
      default:
        return frequency;
    }
  };

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        selectable
        views={['month', 'week', 'day', 'agenda']}
        onSelectSlot={({ start, end }) => setNewEvent({ ...newEvent, start: start.toISOString(), end: end.toISOString() })}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 700 }}
        messages={{
          next: "Suivant",
          previous: "Précédent",
          today: "Aujourd'hui",
          month: "Mois",
          week: "Semaine",
          day: "Jour",
          agenda: "Agenda",
          date: "Date",
          time: "Heure",
          event: "Événement",
          allDay: "Toute la journée",
          noEventsInRange: "Aucun événement dans cette plage",
          showMore: total => `+ ${total} plus`,
        }}
      />
      <div>
        <h3>Ajouter une nouvelle disponibilité</h3>
        {dateError && <p className="error">{dateError}</p>}
        <form>
          <div className="form-group">
            <label className="label-group">
              Date de début:
              <input
                type="date"
                name="start"
                value={newEvent.start}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="form-group">
            <label className="label-group">
              Date de fin:
              <input
                type="date"
                name="end"
                value={newEvent.end}
                onChange={handleChange}
              />
            </label>
          </div>
          <fieldset>
            <legend>Partie de la journée:</legend>
            <div className="form-group">
              <label>
                <input
                  type="radio"
                  name="partOfDay"
                  value="morning"
                  checked={newEvent.partOfDay === 'morning'}
                  onChange={handleChange}
                />
                Matin
              </label>
            </div>
            <div className="form-group">
              <label>
                <input
                  type="radio"
                  name="partOfDay"
                  value="afternoon"
                  checked={newEvent.partOfDay === 'afternoon'}
                  onChange={handleChange}
                />
                Après-midi
              </label>
            </div>
            <div className="form-group">
              <label>
                <input
                  type="radio"
                  name="partOfDay"
                  value="full-day"
                  checked={newEvent.partOfDay === 'full-day'}
                  onChange={handleChange}
                />
                Journée entière
              </label>
            </div>
          </fieldset>
          <fieldset>
            <legend>Choisir des jours de semaine:</legend>
            <div className="form-group">
              <label>
                <input
                  type="checkbox"
                  checked={isRecurring}
                  onChange={handleRecurrenceChange}
                />
                Choisir des jours de semaine
              </label>
            </div>
            {isRecurring && (
              <>
                <div className="form-group">
                  <label className="label-group">
                    Fréquence:
                    <select
                      name="recurrence.frequency"
                      value={newEvent.recurrence.frequency}
                      onChange={handleChange}
                      disabled={!isRecurring}
                    >
                      <option value="weekly">Hebdomadaire</option>
                      <option value="biweekly">Bihebdomadaire</option>
                    </select>
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      name="recurrence"
                      value="1"
                      checked={newEvent.recurrence.days.includes(1)}
                      onChange={handleChange}
                      disabled={!isRecurring}
                    />
                    Lundi
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      name="recurrence"
                      value="2"
                      checked={newEvent.recurrence.days.includes(2)}
                      onChange={handleChange}
                      disabled={!isRecurring}
                    />
                    Mardi
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      name="recurrence"
                      value="3"
                      checked={newEvent.recurrence.days.includes(3)}
                      onChange={handleChange}
                      disabled={!isRecurring}
                    />
                    Mercredi
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      name="recurrence"
                      value="4"
                      checked={newEvent.recurrence.days.includes(4)}
                      onChange={handleChange}
                      disabled={!isRecurring}
                    />
                    Jeudi
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      name="recurrence"
                      value="5"
                      checked={newEvent.recurrence.days.includes(5)}
                      onChange={handleChange}
                      disabled={!isRecurring}
                    />
                    Vendredi
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      name="recurrence"
                      value="6"
                      checked={newEvent.recurrence.days.includes(6)}
                      onChange={handleChange}
                      disabled={!isRecurring}
                    />
                    Samedi
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      name="recurrence"
                      value="7"
                      checked={newEvent.recurrence.days.includes(7)}
                      onChange={handleChange}
                      disabled={!isRecurring}
                    />
                    Dimanche
                  </label>
                </div>
              </>
            )}
          </fieldset>
          <button type="button" onClick={handleAddEvent}>Ajouter la disponibilité</button>
        </form>
      </div>
      <div style={{ marginTop: '20px' }}>
        <h3>Liste des disponibilités</h3>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date de début</TableCell>
                <TableCell>Date de fin</TableCell>
                <TableCell>Partie de la journée</TableCell>
                <TableCell>Récurrence</TableCell>
                <TableCell>Jours de la semaine</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {availabilities.map((availability) => (
                <TableRow key={availability.id}>
                  <TableCell>{moment(availability.start).format('LL')}</TableCell>
                  <TableCell>{moment(availability.end).format('LL')}</TableCell>
                  <TableCell>{translatePartOfDay(availability.part_of_day)}</TableCell>
                  <TableCell>{translateFrequency(availability.recurrence.frequency)}</TableCell>
                  <TableCell>{availability.recurrence.days.map(day => moment().day(day).format('dddd')).join(', ')}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="secondary" onClick={() => handleDeleteAvailability(availability.id)}>
                      Supprimer
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default AvailabilityCalendar;
