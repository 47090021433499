import React, { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import { API_GATEWAY_URL } from "../constants";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";
import styles from "../styles/ProviderExpertises.css";
import Swal from "sweetalert2";
import ProviderCard from "./ProviderCard";
import Select from "react-select";
import LoadingSpinner from "./LoadingSpinner";

const ProviderExpertises = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [expertises, setExpertises] = useState([]);
  const [providerData, setProviderData] = useState(null); // State to store provider data

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingExpertiseId, setEditingExpertiseId] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState("");
  const levelOptions = [
    { value: "JR", label: "Junior" },
    { value: "CF", label: "Confirmé" },
    { value: "SR", label: "Senior" },
  ];
  // Define levelMapping
  const levelMapping = {
    JR: "Junior",
    CF: "Confirmé",
    SR: "Senior",
  };
  const fetchProviderData = async () => {
    try {
      const response = await fetch(`${API_GATEWAY_URL}/provider-profile/`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch provider data.");
      }
      const data = await response.json();
      setProviderData(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  useEffect(() => {
    if (user && user.access_token) {
      fetchExpertises();
      fetchProviderData(); // Fetch provider data on component mount
    }
  }, [user]);

  const fetchExpertises = async () => {
    try {
      const response = await fetch(
        `${API_GATEWAY_URL}/expertises-by-provider2/`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setExpertises(data);
      } else {
        throw new Error("Failed to fetch expertises.");
      }
    } catch (err) {
      setError(err.message);
    } finally {
    }
  };

  const handleEditExpertise = (expertiseId, currentLevel) => {
    setEditingExpertiseId(expertiseId);
    setSelectedLevel(currentLevel);
  };

  const handleCancelEdit = () => {
    setEditingExpertiseId(null);
    setSelectedLevel("");
  };

  const handleLevelChange = async (level) => {
    if (!editingExpertiseId) {
      Swal.fire("Error", "No expertise selected for editing", "error");
      return;
    }

    try {
      const response = await fetch(
        `${API_GATEWAY_URL}/expertises-by-provider2/${editingExpertiseId}/`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ level: level }),
        }
      );
      if (!response.ok) {
        throw new Error("Echec de modification");
      }
      Swal.fire("Succès", "Expertise modifiée avec succès", "success");
      setEditingExpertiseId(null);
      setSelectedLevel("");
      fetchExpertises(); // Refresh the list of expertises
    } catch (err) {
      Swal.fire("Error", err.message, "error");
    }
  };

  useEffect(() => {
    fetchExpertises();
  }, [user]);

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <div style={styles.error}>Error: {error}</div>;
  }

  const handleAddExpertise = () => {
    navigate("/add-expertise");
  };

  const handleDeleteExpertise = async (expertiseId) => {
    if (!user || !user.access_token) {
      Swal.fire(
        "Erreur d'authentification",
        "L'utilisateur n'est pas authentifié",
        "error"
      );
      return;
    }

    // Dialogue de confirmation
    Swal.fire({
      title: "Êtes-vous sûr ?",
      text: "Vous ne pourrez pas revenir en arrière !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer l'expertise !",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteExpertise(expertiseId);
      }
    });
  };

  const deleteExpertise = async (expertiseId) => {
    try {
      const response = await fetch(
        `${API_GATEWAY_URL}/expertises-by-provider2/${expertiseId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || "Échec de la suppression de l'expertise"
        );
      }
      Swal.fire("Supprimé !", "Expertise supprimée avec succès.", "success");
      setExpertises(
        expertises.filter((exp) => exp.expertise_id !== expertiseId)
      );
    } catch (err) {
      Swal.fire("Erreur", err.message, "error");
    }
  };
  const levelToStars = (level) => {
    switch (level) {
      case "JR":
        return "★"; // Une étoile pour Junior
      case "CF":
        return "★★"; // Deux étoiles pour Confirmé
      case "SR":
        return "★★★"; // Trois étoiles pour Senior
      default:
        return ""; // Par défaut, pas d'étoiles
    }
  };
  return (
    <div className="PEcontainer">
      {providerData && <ProviderCard providerData={providerData} />}
      <div className="PEexpertisesList">
        <button onClick={handleAddExpertise} className="PEbutton PEaddButton">
          Ajouter une expertise
        </button>{" "}
        {/* Add Expertise button */}
        {expertises.length > 0 ? (
          expertises.map((expertise) => (
            <div
              key={expertise.expertise_id}
              className={`PEexpertiseCard ${
                editingExpertiseId === expertise.expertise_id
                  ? "editing"
                  : editingExpertiseId
                  ? "shrink"
                  : ""
              }`}
            >
              <div className="PEexpertiseCardHeader">
                <h3>{expertise.expertisename}</h3>
                <span className="PEexpertiseLevelIcon">
                  {levelToStars(expertise.level)}
                </span>
              </div>
              <p>Niveau: {levelMapping[expertise.level]}</p>
              {editingExpertiseId === expertise.expertise_id ? (
                <div className="PEeditContainer">
                  <p className="PEeditMessage">
                    Sélectionner un nouveau niveau d'expertise :
                  </p>
                  <button onClick={() => handleLevelChange("JR")}>
                    Junior ★
                  </button>
                  <button onClick={() => handleLevelChange("CF")}>
                    Confirmé ★★
                  </button>
                  <button onClick={() => handleLevelChange("SR")}>
                    Senior ★★★
                  </button>
                  <button onClick={handleCancelEdit}>Retour</button>{" "}
                  {/* Back button */}
                </div>
              ) : (
                <div className="PEbuttonContainer">
                  <button
                    onClick={() =>
                      handleEditExpertise(
                        expertise.expertise_id,
                        expertise.level
                      )
                    }
                    className="PEbutton PEeditButton"
                  >
                    Modifier
                  </button>
                  <button
                    onClick={() =>
                      handleDeleteExpertise(expertise.expertise_id)
                    }
                    className="PEbutton PEdeleteButton"
                  >
                    Supprimer
                  </button>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>Aucune expertise trouvée.</p>
        )}
      </div>
    </div>
  );
};
export default ProviderExpertises;
