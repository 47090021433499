// WorkerProfile.js
import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { API_GATEWAY_URL } from '../constants';

const WorkerProfile = () => {
  const navigate = useNavigate();
  const [workerData, setWorkerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    if (user && user.access_token && user.user_email) {
      const fetchWorkerData = async () => {
        try {
          const response = await fetch(`${API_GATEWAY_URL}/worker-profile/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.access_token}`
            },
          });

          if (response.ok) {
            const data = await response.json();
            console.log("Worker Data:", data); // Ajoutez cette ligne
            setWorkerData(data);
          } else {
            throw new Error('Failed to fetch worker data');
          }
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
      fetchWorkerData();
    } else {
      setError("User not authenticated");
      setLoading(false);
    }
  }, [user]);

  if (loading) {
    return <div>Chargement...</div>;
  }

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  const handleEditProfile = () => {
    navigate('/worker-profile-update');
  };
  
  return (
    <div style={styles.container}>
      {workerData && (
        <>
          <h2 style={styles.title}>Profil de la Ressource</h2>
          <p><strong>Nom:</strong> {workerData.last_name}</p>
          <p><strong>Prénom:</strong> {workerData.first_name}</p>
          <p><strong>Date de naissance:</strong> {workerData.ddn}</p>
          {workerData.provider_name && <p><strong>Entreprise actuelle:</strong> {workerData.provider_name}</p>}
          <p><strong>Téléphone:</strong> {workerData.phone_number}</p>
          <p><strong>Adresse:</strong> {workerData.address}</p>
          <p><strong>Expertises:</strong> {workerData && workerData.association_expertises ? (workerData.association_expertises.map(ae => ae.expertise_name).join(', ')) : 'Aucune expertise'}</p>      
          <p><strong>LinkedIn:</strong> <a href={workerData.linkedin} target="_blank" rel="noopener noreferrer">{workerData.linkedin}</a></p>
          <p><strong>Bio:</strong> {workerData.bio}</p>
          {workerData.photo_url && <center><img src={workerData.photo_url} alt="Photo du travailleur" style={styles.photo} /></center>}
		  <p><button onClick={handleEditProfile} style={styles.button}>Modifier le profil</button></p>
        </>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '600px',
    margin: 'auto',
    backgroundColor: '#f8f8f8',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  title: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '20px',
  },
  photo: {
    maxWidth: '100px',
    maxHeight: '100px',
    display: 'block',
    margin: '10px 0',
  },
  button: {
    backgroundColor: '#007BFF',
    color: '#fff',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
    transition: 'background-color 0.3s ease-in-out',
    width: '100%', // Assure que le bouton s'étend sur toute la largeur
  },
};

export default WorkerProfile;