// WorkerWelcomePage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const WorkerWelcomePage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleViewProfile = () => {
    navigate('/worker-profile');
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Bienvenue</h2>
      <p style={styles.message}>Bienvenue, {user && user.user_email}!</p>
      <button onClick={handleViewProfile} style={styles.button}>Voir Profil</button>
    </div>
  );
};


const styles = {
  container: {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    background: '#fff',
  },
  title: {
    textAlign: 'center',
    color: '#333',
  },
  message: {
    fontSize: '16px',
    color: '#555',
    textAlign: 'center',
  },
  button: {
    display: 'block',
    margin: '20px auto',
    padding: '10px 15px',
    backgroundColor: '#007BFF',
    color: '#fff',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
  },
};

export default WorkerWelcomePage;
