import React from "react";
import { Link } from "@mui/material";
import '../../index.css';


const Logo = () => {
    return (
        <Link href="#" underline="none"
            className="
            mr-4block
            cursor-pointer
            py-1.5
            leading-relaxed
            tracking-normal
            text-inherit
            antialiased"

            sx={{
                fontFamily: 'var(--ff-heading)',
                fontSize: 'var(--fs-h5)',
                fontWeight: 'var(--fw-bold)',
                color: 'var(--clr-neutral-900)',
                ":hover": {
                    color: 'var(--clr-primary-500)'
                }
            }}
        >
            TeraTalenT
        </Link>
    )
}

export default Logo;