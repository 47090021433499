import Olivier from "../assets/Olivier.webp";
import Nicolas from "../assets/Nicolas.webp";
import CarouselPartner from "./CarouselPartner";

const FeedbackPartner = () => {
  const profiles = [
    {
      imageSrc: Olivier,
      name: "Olivier HOUSER",
      jobTitle: "Président, Directeur technique",
      feedback: (
        <>
          En vue de la réalisation d’une documentation utilisateur pour un
          logiciel très technique, nous avons fait appel à TeraTalenT pour
          trouver un profil répondant aux différents critères suivants :
          <br />• Très bon niveau technique pour comprendre le logiciel et son
          environnement <br />• Aisance rédactionnelle et bon niveau grammatical
          et orthographique en français <br />• Sens de la pédagogie <br />• TeraTalenT
          nous a très rapidement proposé le profil d’Imène qui a répondu en tous
          points aux critères ci-dessus. <br />
          Elle a effectué sa première mission dans les temps impartis en livrant
          la documentation du logiciel de manière exhaustive, compréhensible, et
          pédagogique. Fort de ce premier succès, nous avons décidé de continuer
          avec Imène en lui confiant des missions de consultante technique en
          charge du paramétrage de certains dossiers clients, ce qu’elle réalise
          avec rigueur, compétence, détermination et efficacité. Nous sommes
          ainsi ravis de notre collaboration avec TeraTalenT.
        </>
      ),
    },

    {
      imageSrc: Nicolas,
      name: "Nicolas DELMARQUETTE",
      jobTitle: "Directeur technique",
      feedback:
        "Nous travaillons depuis maintenant 2 ans avec Extrinsec et je suis pleinement satisfait du service apporté. Les intervenants ont un bon niveau technique et sont soucieux de la qualité du service rendu. La présence d’un manager à proximité facilement joignable est un plus pour notre collaboration, cela facilite les temps de réaction en cas de difficulté. Je recommande les services d’Extrinsec.",
    },
  ];

  return (
    <div className="bg-white">
      <div className="min-h-screen  flex flex-col items-center justify-center max-2xl:px-5 2xl:px-32 py-16 text-primary-800">
        <div className="text-center">
          <h2 className="font-display text-fluid-xlg 2xl:text-fluid-xl font-bold ">
            Témoignages de Partenaires
          </h2>
          <p className="font-body  max-md:max-w-full text-fluid-sm 2xl:text-fluid-md mt-4 text-center">
            Découvrez les retours d'expérience de nos clients satisfaits qui ont
            trouvé des solutions efficaces et des collaborations fructueuses
            grâce à notre plateforme
          </p>
        </div>
        <CarouselPartner slides={profiles} />
      </div>
    </div>
  );
};
export default FeedbackPartner;
