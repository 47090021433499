import React, { useState } from 'react';
import { useAuth } from '../../components/AuthContext';
import { useNavigate } from 'react-router-dom';
import { API_GATEWAY_URL } from '../../constants';
import LoadingSpinner from '../../components/LoadingSpinner';

const SkillCard = ({ skill, statusMessage }) => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const [testData, setTestData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    
    // Early return if there's no access token
    if (!user.access_token) {
        return <p>Error: No access token found. Please log in.</p>;
    }


    
    const fetchTestData = async () => {
        setLoading(true);
        setError(null);
        
        try {
            const response = await fetch(`${API_GATEWAY_URL}/generate-test/`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`
                },
                body: JSON.stringify({
                    "expertise_name": skill.expertise_name,
                    "ddc_id": 2, 
                    "ddc_name": "Programming", 
                    "level": skill.level
                })
            });

            if (response.ok) {
                const data = await response.json();
                setTestData(data);
                navigate('/test-page', { state: { testData: data, testTime: data.test_time } });
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to generate the test.');
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleStartTest = () => {
        fetchTestData();
    };

    if (loading) {
        return <LoadingSpinner isLoading={true} />;
    }

    const getLevelLabel = (level) => {
        switch (level) {
            case 'JR':
                return 'Junior';
            case 'CF':
                return 'Confirmé';
            case 'SR':
                return 'Senior';
            default:
                return 'N/A'; 
        }
    };

    return (
        <div className="bg-white border border-gray-200 p-4 rounded-lg shadow-md w-60 flex flex-col justify-between" style={{ minHeight: '200px' }}>
            <div>
                <h3 className="text-lg font-semibold mb-2">{skill.expertise_name}</h3> 
                <p className="text-gray-700">Niveau: {getLevelLabel(skill.level)}</p>
                <p className={`text-gray-700 ${statusMessage.canStart ? '' : (parseFloat(statusMessage.message.split('Score: ')[1]) > 70 ? 'text-green-500' : 'text-red-500')}`}>
                    {statusMessage.canStart ? "" : (
                        <>
                            {statusMessage.message.split('Score: ')[0]}
                            <br />
                            Score: {parseFloat(statusMessage.message.split('Score: ')[1]).toFixed(2)}/100
                        </>
                    )}
                </p>
            </div>
            <div className="flex justify-center mt-4">
                <button
                    onClick={handleStartTest}
                    disabled={!statusMessage.canStart} 
                    className={`px-4 py-2 ${statusMessage.canStart ? 'bg-green-500' : 'bg-gray-300'} text-white rounded-lg hover:${statusMessage.canStart ? 'bg-green-600' : 'bg-gray-400'} transition-colors`}
                >
                    {statusMessage.canStart ? "Commencer le test" : "Test déjà passé"}
                </button>
            </div>

            {loading && <LoadingSpinner />}
            {error && <p className="text-red-500 mt-4">Error: {error}</p>}
        </div>
    );
};

export default SkillCard;
