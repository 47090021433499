import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  providerStep: 1,
  providerInscriptionFormData: {
    // Ajoutez ici les champs spécifiques au formulaire du fournisseur
    companyName: '',
    contactName: '',
    email: '',
    password: '',
    // Autres champs...
  }
};

export const providerInscriptionFormSlice = createSlice({
  name: 'providerInscriptionForm',
  initialState,
  reducers: {
    setProviderInscriptionFormData: (state, action) => {
      state.providerInscriptionFormData = { ...state.providerInscriptionFormData, ...action.payload };
    },
    providerNextStep: (state) => {
      state.providerStep++;
    },
    providerPrevStep: (state) => {
      state.providerStep--;
    },
    resetProviderInscriptionFormData: (state) => {
      state.providerStep = 1;
      state.providerInscriptionFormData = initialState.providerInscriptionFormData;
    },
    // Ajoutez d'autres réducteurs si nécessaire
  }
});

export const {
  setProviderInscriptionFormData,
  providerNextStep,
  providerPrevStep,
  resetProviderInscriptionFormData,
  // Exportez d'autres actions si vous en avez ajoutées
} = providerInscriptionFormSlice.actions;

export default providerInscriptionFormSlice.reducer;
