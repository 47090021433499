import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import LanguageIcon from '@mui/icons-material/Language';
import Logout from '@mui/icons-material/Logout';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import Person2SharpIcon from '@mui/icons-material/Person2Sharp';
import AssignmentSharpIcon from '@mui/icons-material/AssignmentSharp';
import MarkUnreadChatAltSharpIcon from '@mui/icons-material/MarkUnreadChatAltSharp';
import { useAuth } from '../AuthContext.js';

export default function AvatarIcon({ avatarIconPath, avatarIconAlt }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    //const [darkMode, setDarkMode] = React.useState(false);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
   /* const toggleDarkMode = () => {
        setDarkMode(!darkMode); 
        handleClose();
    };*/
    const handleLanguageChange = (lang) => {
        console.log(`Selected language: ${lang}`);
        handleClose();
    };

    const navigate = useNavigate();
    const goToSettings = () => {
        navigate('/account-settings');   
    }
    const goToProfile = () => {
        navigate('/profile');   
    }
    const goToMissions = () => {
        navigate('/missions');   
    }
    const goToChat = () => {
        navigate('/chat');   
    }
    const { logout } = useAuth();
    const handleLogout = () => {
        logout();
        navigate('/');
      };

    return (
        <React.Fragment>
        <div className="relative">
            <Tooltip title="Account">
            <IconButton
                onClick={handleClick}
                size="small"
                sx={{
                    ml: 2,
                    '&:hover': {
                        boxShadow: '0px 2px 1px rgba(38, 190, 165, 0.3)', // Box shadow with color #26BEA5
                    },
                }}
            >
                <Avatar
                    alt={avatarIconAlt}
                    src={`freelancer_profilePicture/${avatarIconPath}`}
                    sx={{ width: 52, height: 52 }}
                />
            </IconButton>
            </Tooltip>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 60,
                        height: 60,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
            {/* Menu items */}
            <div className='block md:hidden'>
                <MenuItem onClick={goToProfile}>
                    <ListItemIcon>
                    <Person2SharpIcon fontSize="small" sx={{ color: 'var(--clr-neutral-800)' }}/>
                    </ListItemIcon>
                    <span sx={{ color: 'var(--clr-neutral-900)' }}>Profile</span>
                </MenuItem>
                <MenuItem onClick={goToMissions}>
                    <ListItemIcon>
                    <AssignmentSharpIcon fontSize="small" sx={{ color: 'var(--clr-neutral-800)' }}/>
                    </ListItemIcon>
                    <span sx={{ color: 'var(--clr-neutral-900)' }}>Missions</span>
                </MenuItem>
                <MenuItem onClick={goToChat}>
                    <ListItemIcon>
                    <MarkUnreadChatAltSharpIcon fontSize="small" sx={{ color: 'var(--clr-neutral-800)' }}/>
                    </ListItemIcon>
                    <span sx={{ color: 'var(--clr-neutral-900)' }}>Chat</span>
                </MenuItem>
            </div>
            <MenuItem onClick={goToSettings}>
                <ListItemIcon>
                <Settings fontSize="small" sx={{ color: 'var(--clr-neutral-800)' }}/>
                </ListItemIcon>
                <span sx={{ color: 'var(--clr-neutral-900)' }}>Paramètres</span>
            </MenuItem>
            { /*<MenuItem onClick={toggleDarkMode}>
                <ListItemIcon>
                {darkMode ? <Brightness7Icon fontSize="small" sx={{ color: 'var(--clr-neutral-800)' }}/> : <Brightness4Icon fontSize="small" sx={{ color: 'var(--clr-neutral-800)' }}/>}
                </ListItemIcon>
                <span sx={{ color: 'var(--clr-neutral-900)' }}>{darkMode ? 'Light Mode' : 'Dark Mode'}</span>
            </MenuItem>*/}
            <MenuItem onClick={() => handleLanguageChange('French')}>
                <ListItemIcon>
                <LanguageIcon fontSize="small" sx={{ color: 'var(--clr-neutral-800)' }}/>
                </ListItemIcon>
                <span sx={{ color: 'var(--clr-neutral-900)' }}>Langue</span>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                <Logout fontSize="small" sx={{ color: 'var(--clr-danger-400)' }}/>
                </ListItemIcon>
                <span sx={{ color: 'var(--clr-neutral-900)' }}>Déconnexion</span>
            </MenuItem>
            </Menu>
        </div>
        </React.Fragment>
    );
}
