import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const FAQItem = ({ question, answer , isOpen, onToggle}) => {
 // const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="FAQClient">
      <div
        className="cursor-pointer flex items-center justify-between"
        //onClick={() => setIsOpen(!isOpen)}
        onClick={onToggle}
      >
        <span className="font-bold text-fluid-sm 2xl:text-fluid-md text-white font-body">
          {question}
        </span>
        <FontAwesomeIcon
          icon={isOpen ? faChevronUp : faChevronDown}
          className={`max-2xl:w-7 max-2xl:h-7 2xl:w-10 2xl:h-10 text-white transition-transform duration-300 ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </div>
      <div
        className={`overflow-hidden transition-all duration-300 ${
          isOpen ? "max-h-screen" : "max-h-0"
        }`}
        style={{ height: isOpen ? "auto" : 0 }}
      >
        <p className="text-white mt-2 text-fluid-sm 2xl:text-fluid-md font-body">
          {answer}
        </p>
      </div>
    </div>
  );
};

export default FAQItem;
