import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { API_GATEWAY_URL } from "../../constants";
import { useAuth } from "../AuthContext";
import LoadingSpinner from "../LoadingSpinner";
import { IoCheckmarkDone } from "react-icons/io5";
import { MdErrorOutline } from "react-icons/md";
import { MdRestartAlt } from "react-icons/md";
import { MdStart } from "react-icons/md";
import { useLocation } from "react-router-dom";

const SuiviPaiement = () => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [transactionStatus, setTransactionStatus] = useState([]);
    const location = useLocation();
  const { paymentIntentId } = location.state || {};
    
    const [transaction, setTransaction] = useState(null);

    useEffect(() => {
        const fetchTransactionStatus = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/getsuivi/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user.access_token}`,
                    },
                    body: JSON.stringify({
                        paymentIntentId: paymentIntentId
                    })
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            
                // Récupérer les données JSON de la réponse
                const data = await response.json();
                console.log('Transaction status:', data);
                setTransactionStatus(data); 
                const response2 = await fetch(`${API_GATEWAY_URL}/getsuivi/?payment_intent_id=${paymentIntentId}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user.access_token}`,
                    },
                });
                if (!response2.ok) {
                    throw new Error(`HTTP error! status: ${response2.status}`);
                }
                const data2 = await response2.json();
                console.log('Transaction data:', data2);
                setTransaction(data2); 
                // Mettre à jour l'état avec les données de transaction
                // Assurez-vous que `data` est un tableau

            } catch (error) {
                console.error('Error fetching transaction status:', error);
            } finally {
                setLoading(false); // Arrêter le chargement, peu importe le résultat
            }
        };

        fetchTransactionStatus();
    }, [paymentIntentId, user.access_token]);

    const getStatusIcon = (status) => {
        switch (status) {
            case 'success':
            return <IoCheckmarkDone className="text-green-500 w-[32px] h-[32px]" />;
            case 'failed':
            return <MdErrorOutline className="text-red-500 w-[32px] h-[32px]" />;
            case 'pending':
            return <MdRestartAlt className="text-yellow-500 w-[32px] h-[32px]"  />;
            case 'created':
            return <MdStart className="text-blue-800 w-[32px] h-[32px]" />;
            default:
            return <MdStart className="text-gray-500 w-[32px] h-[32px]" />;
        }

    };

    if (loading) {
        return <LoadingSpinner isLoading={loading} />; // Affiche un spinner pendant le chargement
    }

    const translateStatus = (status) => {
        switch (status) {
            case 'success':
                return 'Succès';
            case 'failed':
                return 'Échoué';
            case 'pending':
                return 'En attente';
            case 'created':
                return 'Créé';
            default:
                return status;
        }
    };
    const handleBackClick = () => {
        window.location.href = '/client-Dashboard';
      };
    
    return (
        <div className="flex flex-col gap-8 p-4">
            <div className="flex flex-row items-center gap-1">
          <img 
            src={require('../../assets/paiement/Arrow left.svg').default} 
            alt="retour" 
            className="w-[24px]" 
            onClick={handleBackClick} 
          />
          <span className="text-sm font-medium font-Poppins sm:text-md">
            Paiement
          </span>
        </div>
            <div className="flex gap-4 ">
                <span className="text-[#1e1e1e] text-lg font-medium font-Poppins">Montant</span>
                <span className="text-[#1e1e1e] text-2xl font-semibold font-Poppins">{transaction.amount} {transaction.currency}</span>
            </div>
            <div className="w-full flex justify-between items-center">
                <div className="w-full px-4 border-r border-[#dedede] flex-col justify-start items-start gap-2 inline-flex">
                    <div className="self-stretch text-black text-md font-normal font-Poppins">Ressources</div>
                    <div className="self-stretch text-black text-sm font-normal font-Poppins">ressources</div>
                </div>
                <div className="w-full px-4 border-r border-[#dedede] flex-col justify-start items-start gap-2 inline-flex">
                    <div className="self-stretch text-black text-lg font-normal font-Poppins">Moyen de paiement</div>
                    <div className="self-stretch text-black text-sm font-normal font-Poppins">{transaction.payment_method_type}</div>
                </div>
                <div className="w-full px-4 border-r border-[#dedede] flex-col justify-start items-start gap-2 inline-flex">
                    <div className="self-stretch text-black text-lg font-normal font-Poppins">Dernière date</div>
                    <div className="self-stretch text-black text-sm font-normal font-Poppins">{new Date(transactionStatus[transactionStatus.length - 1]?.timestamp).toLocaleString()}</div>
                </div>
            </div>
            <div className="w-full h-[1px] bg-[#dedede]"></div>
            <div>
                <div className="text-[#1e1e1e] text-md font-semibold font-Poppins">Cycle de vie de paiement</div>
                {transactionStatus.map((payment, index) => (
                    <div key={index} className="flex flex-row gap-2 mt-3">
                        <div>
                            {getStatusIcon(payment.status)}
                        </div>
                        <div className="text-[#1e1e1e] text-md font-medium font-Poppins">
                            <p>{new Date(payment.timestamp).toLocaleString()} : </p>
                            <p>État {translateStatus(payment.status)}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SuiviPaiement;
