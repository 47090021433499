import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Alert } from "react-bootstrap";
import { API_GATEWAY_URL } from "../constants";
import {  Modal} from "react-bootstrap";
import LoadingSpinner from "./LoadingSpinner";
import {
  setClientInscriptionFormData,
  nextStep,
  prevStep,
  resetClientInscriptionFormData,
} from "../redux/slices/clientInscriptionFormSlice";
import { FaPlus, FaTimes } from "react-icons/fa";
import axios from "axios";
import { useAuth } from "./AuthContext";
import Swal from "sweetalert2";
import "../styles/CreateDemandePage.css";
import ClientCard from "./ClientCard";
import Navbar from "./Navbarr";
import "../index.css";
import ClientCalendar from "./ClientCalendar";
import {
 
  FaSearch,
  
} from "react-icons/fa";


import "../styles/Buttons.css";

const projectTypes = {
  NI: "Nouvelle idée de projet",
  PR: "Projet en cours nécessitant plus de ressource",
  EX: "Aucun, je souhaite juste explorer les possibilités",
};

const missionStartOptions = {
  IM: "Immédiatement (le plus vite possible)",
  "1T": "De 1 à 2 semaines",
  "2W": "Plus de 2 semaines",
  LA: "Je vais décider plus tard",
};

const expertiseLevelOptions = {
  JR: "Junior",
  CF: "Confirmé",
  SR: "Sénior",
  EX: "Expert",
  LA: "Choisir plus tard",
};

const timeEngagementOptions = {
  FP: "Temps plein (40 heures/semaine)",
  PT: "Temps partiel (< 40 heures/semaine)",
  HD: "Par demi-journée",
  LA: "Je vais décider plus tard",
};

const expertiseLevelOptionsArray = Object.entries(expertiseLevelOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
);

const projectTypesArray = Object.entries(projectTypes).map(([key, value]) => ({
  value: key,
  label: value,
}));

const timeEngagementOptionsArray = Object.entries(timeEngagementOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
);

const missionStartOptionsArray = Object.entries(missionStartOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
);

const CreateDemandePage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { step, clientInscriptionFormData } = useSelector(
    (state) => state.clientInscriptionForm
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedExpertises, setSelectedExpertises] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiExpertises, setApiExpertises] = useState([]);
  const [metierAffiche, setMetierAffiche] = useState("");
  const [apiSearchExecuted, setApiSearchExecuted] = useState(false);
  const [originalApiExpertises, setOriginalApiExpertises] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [posteTitle, setPosteTitle] = useState("");
  const [expertises, setExpertises] = useState([]);

  const [planification_type, setPlanificationType] = useState("");
  const [nombreDemiJournees, setNombreDemiJournees] = useState(0);
  const [availabilities, setAvailabilities] = useState([]);

  //---------------------------------------------------------------------------
  const handlePlanificationTypeChange = (e) => {
    setPlanificationType(e.target.value);
  };

  const handleNombreDemiJourneesChange = (e) => {
    console.log("i,m here now");
    const value = parseInt(e.target.value);
   // const maxDemiJournees = calculateMaxDemiJournees();
    //setNombreDemiJournees(Math.min(value, maxDemiJournees));
    setNombreDemiJournees(value)
  };

  const calculateMaxDemiJournees = (date_debut,date_fin) => {
  
    if (!date_debut || !date_fin) {
      return 0;
    }
    const start = new Date(date_debut);
    const end = new Date(date_fin);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays * 2;
  };

  const handleAvailabilityChange = (newAvailabilities) => {
    setAvailabilities(newAvailabilities);
  };
  //---------------------------------------------------------------------------
  // Contenu du modal de chargement
  const LoadingModal = () => (
    <Modal
      show={isLoading}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="text-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Chargement...</span>
          </div>
          <p className="mt-2">Veuillez patienter...</p>
        </div>
      </Modal.Body>
    </Modal>
  );


  useEffect(() => {
    const fetchExpertisesList = async () => {
      try {
        const { data } = await axios.get(`${API_GATEWAY_URL}/expertises/`);
        setExpertises(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des expertises:", error);
      } finally {
      }
    };

    fetchExpertisesList();
  }, []);

  // Fonction pour convertir la réponse de l'API en un tableau JSON
  // Fonction pour convertir la réponse de l'API en un tableau JSON
  const convertResponseToJsonArray = (responseString) => {
    const lines = responseString.trim().split("\n");
    return lines.map((line) => JSON.parse(line));
  };

  const fetchExpertisesFromOpenAI = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${API_GATEWAY_URL}/query-openai/${posteTitle}`
      );
      const expertisesArray = data.split("\n").map((line) => JSON.parse(line));
      setApiExpertises(
        expertisesArray.map((exp) => ({ id: parseInt(exp.C), name: exp.N }))
      );
      console.log(apiExpertises);
      setOriginalApiExpertises(
        expertisesArray.map((exp) => ({ id: parseInt(exp.C), name: exp.N }))
      );
      setMetierAffiche(posteTitle);
      setApiSearchExecuted(true);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des expertises via OpenAI:",
        error
      );
    } finally {
      setIsLoading(false);
    }
  };
  
  
  const handlePosteTitleChange = (e) => {
    setPosteTitle(e.target.value);
    const { name, value } = e.target;

    formErrors.posteTitle = "";
    dispatch(setClientInscriptionFormData({ [name]: value }));
  };
  const handleAddExpertise = (expertise) => {
    const isAlreadyAdded = selectedExpertises.some(
      (item) => item.id === expertise.id
    );
    if (!isAlreadyAdded) {
      // Ajoutez un champ level à chaque expertise
      setSelectedExpertises([
        ...selectedExpertises,
        { ...expertise, level: "" },
      ]);
      dispatch(
        setClientInscriptionFormData({
          selectedExpertises: [
            ...selectedExpertises,
            { ...expertise, level: "" },
          ],
        })
      );

      // Mettre à jour apiExpertises
      setApiExpertises((prev) => {
        const next = prev.filter((item) => item.id !== expertise.id);
        if (next.length < 10 && expertises.length > 10 && !apiSearchExecuted) {
          const nextExpertise = expertises.find(
            (item) =>
              !selectedExpertises.some((exp) => exp.id === item.id) &&
              !next.some((exp) => exp.id === item.id)
          );
          if (nextExpertise) {
            next.push(nextExpertise);
          }
        }
        return next;
      });
    }
  };

  const handleRemoveExpertise = (id) => {
    const updatedExpertises = selectedExpertises.filter(
      (item) => item.id !== id
    );
    setSelectedExpertises(updatedExpertises);
    dispatch(
      setClientInscriptionFormData({ selectedExpertises: updatedExpertises })
    );

    // Mettre à jour apiExpertises
    const removedExpertise = expertises.find((item) => item.id === id);
    console.log(expertises);
    console.log(removedExpertise);
    if (removedExpertise) {
      // Ajoutez cette vérification
      setApiExpertises((prev) => {
        // Vérifie si l'expertise supprimée est déjà dans apiExpertises
        // ou si elle ne correspond pas au terme de recherche actuel
        if (
          prev.some((expertise) => expertise.id === removedExpertise.id) ||
          !removedExpertise.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ) {
          return prev; // Si c'est le cas, retourne apiExpertises sans modification
        } else {
          const next = [...prev, removedExpertise];
          next.sort((a, b) => a.id - b.id);
          return next.slice(0, 10);
        }
      });
    } else {
      console.log("testtt");
    }
  };

  const updateApiExpertises = (newSelected) => {
    // Recalculer les expertises à afficher après ajout ou suppression
    const remainingExpertises = expertises.filter(
      (exp) => !newSelected.some((sel) => sel.id === exp.id)
    );
    setApiExpertises(remainingExpertises.slice(0, 10));
  };

  const filteredExpertises = searchTerm
    ? expertises.filter((expertise) =>
        expertise.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : apiExpertises;

    const handleSearchChange = (e) => {
      const value = e.target.value;
      setSearchTerm(value);
  
      // Si la recherche est vide et que l'API OpenAI a été exécutée, réinitialiser les expertises affichées
      if (value === "" && apiSearchExecuted) {
        setApiExpertises(originalApiExpertises); // Réinitialiser à apiExpertisesList
      } else if (value === "" && !apiSearchExecuted) {
        setApiExpertises([]);
      }
      // Sinon, effectuer une recherche d'expertise
      else {
        const filtered = expertises.filter((expertise) =>
          expertise.name.toLowerCase().includes(value.toLowerCase())
        );
        console.log(filtered);
        console.log(expertises);
        setApiExpertises(value ? filtered.slice(0, 10) : expertises.slice(0, 10));
      }
    };
  
  //--------------------------------------------------------------------------
  //---------------------------------------------------------------------------

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(setClientInscriptionFormData({ [name]: value }));
  };

  const [formErrors, setFormErrors] = useState({})

  const handleNextStep = () => {
    let errors = {};
    if (
      step === 1 &&
      (!clientInscriptionFormData.posteTitle || !selectedExpertises.length)
    ) {
      errors = {
        posteTitle: !clientInscriptionFormData.posteTitle
          ? "Veuillez entrer le titre du poste recherché."
          : "",
        selectedExpertises: !selectedExpertises.length
          ? "Veuillez sélectionner au moins une expertise."
          : "",
      };
    }
    if (
      step === 2 &&
      (!clientInscriptionFormData.projectType ||
        !clientInscriptionFormData.missionStart)
    ) {
      errors = {
        projectType: !clientInscriptionFormData.projectType
          ? "Veuillez sélectionner le type de projet."
          : "",
        missionStart: !clientInscriptionFormData.missionStart
          ? "Veuillez sélectionner le délai de début de mission."
          : "",
      };
    }
    if (
      step === 3 &&
      (!clientInscriptionFormData.expertiseLevel ||
        !clientInscriptionFormData.timeEngagement)
    ) {
      errors = {
        expertiseLevel: !clientInscriptionFormData.expertiseLevel
          ? "Veuillez sélectionner le niveau d'expertise recherché."
          : "",
        timeEngagement: !clientInscriptionFormData.timeEngagement
          ? "Veuillez sélectionner le niveau d'engagement de temps."
          : "",
      };
    }
    
    /*if (Object.keys(errors).length > 0) {
      setErrorMessage("Veuillez remplir tous les champs requis.");
      return;
    }*/

      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);  
        setErrorMessage("Veuillez remplir tous les champs requis.");
        return;  
      }
      setErrorMessage(""); // Efface le message d'erreur
      setFormErrors({});   // Réinitialise les erreurs

    dispatch(nextStep());
  };

  useEffect(() => {
    return () => {
      dispatch(resetClientInscriptionFormData());
    };
  }, [dispatch]);




const validateDates = (dateDebut, dateFin) => {
  let errors = {};
  
  if (!dateDebut) {
    errors.dateDebut = "Veuillez sélectionner la date de début.";
  }
  if (!dateFin) {
    errors.dateFin = "Veuillez sélectionner la date de fin.";
  } else if (new Date(dateFin) < new Date(dateDebut)) {
    errors.dateFin = "La date de fin doit être supérieure ou égale à la date de début.";
  }
  
  return errors;
  };

  const [submittingButton, setSubmittingButton] = useState(null);

  const handleFormSubmit = async (status,event) => {
    event.preventDefault();
    if (isSubmitting) return;
     
    //------------------------------------------------------
    const dateDebut = document.querySelector('input[name="date_debut"]').value;
    const dateFin = document.querySelector('input[name="date_fin"]').value;
    const errors = validateDates(dateDebut, dateFin);
    if (Object.keys(errors).length > 0) {
    setFormErrors(errors); // Mettre à jour l'état avec les erreurs
    return; // Ne pas continuer si la validation échoue
    }

    if (planification_type === "demi-journees") {
      const maxDemiJournees = calculateMaxDemiJournees(dateDebut,dateFin); // Assurez-vous que cette fonction retourne le bon maximum
      if (nombreDemiJournees < 1) {
        errors.nombreDemiJournees = "Le nombre de demi-journées doit être supérieur ou égal à 1.";
      } else if (nombreDemiJournees > maxDemiJournees) {
        errors.nombreDemiJournees = `Le nombre de demi-journées ne peut pas dépasser ${maxDemiJournees}.`;
      }
  
      // Si des erreurs existent après validation des demi-journées
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return; // Ne pas continuer si la validation échoue
      }
    }
  
    setFormErrors({});
    
    
   
    //------------------------------------------------------
    setSubmittingButton(status);


    // Handle form submission here
    const formData = {
      posteTitle: clientInscriptionFormData.posteTitle,
      date_debut: document.querySelector('input[name="date_debut"]').value,
      date_fin: document.querySelector('input[name="date_fin"]').value,
      selectedExpertises: selectedExpertises.map((expertise) => expertise.id), // Only send the IDs
      timeEngagement: clientInscriptionFormData.timeEngagement,
      expertiseLevel: clientInscriptionFormData.expertiseLevel,
      projectType: clientInscriptionFormData.projectType,
      missionStart: clientInscriptionFormData.missionStart,
      statut: status, // Include the status in the request
      planification_type: planification_type === "demi-journees" ? "DJ" : "DT",  // Send 'DJ' or 'DT'
      nombreDemiJournees: planification_type === "demi-journees" ? nombreDemiJournees : null,
      availabilities: planification_type === "detaillee" ? availabilities : null,
    };
    console.log("TESTING FORM DATA ",formData);
    
    try {
      setIsSubmitting(true);
      const response = await fetch(`${API_GATEWAY_URL}/client-demandes/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          `Failed to create demande: ${errorData.detail || "Unknown error"}`
        );
      }

      Swal.fire({
        icon: "success",
        title: "Demande créée avec succès!",
        showConfirmButton: false,
        timer: 1500,
      });

      dispatch(resetClientInscriptionFormData());

      if (status === "WM") {
        navigate("/demandes-attente-matching");
      } else if (status === "RD") {
        navigate("/demandes-cours-redaction");
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: err.message,
      });
      setSubmittingButton(null); 
    } finally {
      setIsSubmitting(false);
      //setSubmittingButton(null);
    }
  };

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
  
    // Formatage de la date au format yyyy-mm-dd pour l'utiliser dans l'attribut 'min'
    const yyyy = tomorrow.getFullYear();
    const mm = String(tomorrow.getMonth() + 1).padStart(2, '0'); // Mois au format 2 chiffres
    const dd = String(tomorrow.getDate()).padStart(2, '0'); // Jour au format 2 chiffres
    return `${yyyy}-${mm}-${dd}`;
  };

  return (
    <div className="pw-page-layout">
      <Navbar />
      <div className="w-full flex flex-col p-4">
        <ClientCard />
        <div className="demandeclient-container ml-4 border-none md:px-28 justify-center mt-16">
          <div>
            <div className="MediumContainer mb-6 text-primary-800 max-2xl:text-fluid-ms 2xl:text-fluid-sm font-body">
              <h className="flex justify-center  text-primary-800 max-2xl:text-fluid-sm 2xl:text-fluid-md font-display font-bold">
                Créer une nouvelle demande
              </h>
              {loading && <LoadingSpinner isLoading={true} />}
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              <form onSubmit={handleFormSubmit} className="">
                {step === 1 && (
                  <>
                    <div className="mb-3">
                      <label className="font-bold">
                        Quel est le titre du poste / métier recherché?
                      </label>
                      <input
                        type="text"
                        name="posteTitle"
                        value={clientInscriptionFormData.posteTitle}
                        onChange={handlePosteTitleChange}
                        placeholder="Titre du poste / métier recherché"
                        className="form"
                        required
                      />{/*formErrors.posteTitle && (
                         <p style={{ color: "red" }}>{formErrors.posteTitle}</p>
                        )*/}
                     <button
                        onClick={fetchExpertisesFromOpenAI}
                        className="FullWidthBlueWhite mt-3 max-md:!text-fluid-xs md:text-fluid-ms"
                      >
                        Rechercher les compétences
                      </button>
                    
                      {/*----------------------------------------*/}
                      <div>
                  <div>
                    <label
                      htmlFor="posteTitle"
                      className="form-label mb-3 text-fluid-sm font-bold"
                    >
                      Quelles sont les principales compétences recherchées?
                    </label>
                    <div className="flex items-center form overflow-hidden">
                      <span className="">
                        <FaSearch />
                      </span>
                      <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        id="search"
                        className="w-full px-4 focus:outline-none"
                        placeholder="Recherche d'expertise..."
                        aria-describedby="searchButton"
                        required
                        //className="form"
                      />
                    </div>
                    {formErrors.selectedExpertises && (
                      <p style={{ color: "red" }}>
                        {formErrors.selectedExpertises}
                      </p>
                    )}

                    {selectedExpertises.length > 0 && (
                      <>
                        <label className="mt-3 mb-2">
                          Compétences sélectionnées:
                        </label>
                        <div className="expertisesSelectedListe mb-5">
                          {selectedExpertises.map((expertiseItem, index) => (
                            <div key={index} className="ms-3 mt-3">
                              <div className="expertiseselected">
                                <span>{expertiseItem.name}</span>
                                <FaTimes
                                  onClick={() =>
                                    handleRemoveExpertise(expertiseItem.id)
                                  }
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}

                    <label className="form-label mt-3 text-fluid-sm font-bold">
                      Compétences populaires pour : <b>{metierAffiche}</b>
                    </label>
                    <div className="expertisesFiltredListe mt-3">
                      {/* {apiExpertises.map((expertise, index) => (
				      <div style={{ cursor: 'pointer' }}  key={index} className='expertisefiltred' onClick={() => handleAddExpertise({id: expertise.id, name: expertise.name})} >
				        {expertise.name}
				        <FaPlus  className='ms-2 cursor-pointer'  />
				      </div>
				    ))} */}

                      {apiExpertises.map((expertise, index) => {
                        // Vérifie si l'expertise n'est pas déjà sélectionnée
                        const isNotSelected =
                          !clientInscriptionFormData.selectedExpertises.some(
                            (selectedExpertise) =>
                              selectedExpertise.name === expertise.name
                          );

                        // Si l'expertise n'est pas déjà sélectionnée, affichez-la
                        if (isNotSelected) {
                          return (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleAddExpertise({
                                  id: expertise.id,
                                  name: expertise.name,
                                })
                              }
                              className="expertisefiltred"
                              key={index}
                            >
                              {expertise.name}
                              <FaPlus className="ms-2 cursor-pointer" />
                            </div>
                          );
                        }

                        // Sinon, ne rien afficher
                        return null;
                      })}
                    </div>

                    {/* Modal de chargement */}
                    <LoadingModal />
                  </div>
                </div>
                      {/*----------------------------------------*/}
                    </div>
                    <button onClick={handleNextStep} className="Redbutton mt-3">
                      Suivant
                    </button>
                  </>
                )}
                {step === 2 && (
                  <>
                    <div className="mb-3">
                      <label className="font-bold">
                        Quel est le type de projet?
                      </label>
                      {projectTypesArray.map((type, index) => (
                        <div key={index} className="form-check text-fluid-sm">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="projectType"
                            id={`projectType-${index}`}
                            value={type.value} // Ici, assure-toi de passer `type.value` au lieu de `type`
                            checked={
                              clientInscriptionFormData.projectType ===
                              type.value
                            } // Ici aussi, compare avec `type.value`
                            onChange={handleChange}
                            required
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`projectType-${index}`}
                          >
                            {type.label}
                          </label>
                          {/*formErrors.projectType && (
                            <p style={{ color: "red" }}>{formErrors.projectType}</p>
                          )*/}
                        </div>
                      ))}
                    </div>
                    <div className="mb-3 ">
                      <label className="font-bold">
                        Dans quel délai commence la mission?
                      </label>
                      {missionStartOptionsArray.map((option, index) => (
                        <div key={index} className="form-check text-fluid-sm">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="missionStart"
                            id={`missionStart-${index}`}
                            value={option.value} // Utilise `option.value`
                            checked={
                              clientInscriptionFormData.missionStart ===
                              option.value
                            } // Compare avec `option.value`
                            onChange={handleChange}
                            required
                          />{/*formErrors.missionStart && (
                            <p style={{ color: "red" }}>{formErrors.missionStart}</p>
                          )*/}
                          <label
                            className="form-check-label"
                            htmlFor={`missionStart-${index}`}
                          >
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="demande-button-container">
                      <button
                        onClick={() => dispatch(prevStep())}
                        className="previousStep"
                      >
                        Précédent
                      </button>
                      <button onClick={handleNextStep} className="nextStep">
                        Suivant
                      </button>
                    </div>
                  </>
                )}
                {step === 3 && (
                  <>
                    <div className="mb-3">
                      <label className="font-bold">
                        Quel niveau d'expertise recherchez-vous?
                      </label>
                      {expertiseLevelOptionsArray.map((option, index) => (
                        <div key={index} className="form-check text-fluid-sm">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="expertiseLevel"
                            id={`expertiseLevel-${index}`}
                            value={option.value} // Utilise `option.value`
                            checked={
                              clientInscriptionFormData.expertiseLevel ===
                              option.value
                            } // Compare avec `option.value`
                            onChange={handleChange}
                            required
                          />{/*formErrors.expertiseLevel && (
                            <p style={{ color: "red" }}>{formErrors.expertiseLevel}</p>
                          )*/}
                          <label
                            className="form-check-label"
                            htmlFor={`expertiseLevel-${index}`}
                          >
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="mb-3">
                      <label className="font-bold">
                        Quel niveau d'engagement de temps recherchez-vous?
                      </label>
                      {timeEngagementOptionsArray.map((option, index) => (
                        <div key={index} className="form-check text-fluid-sm">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="timeEngagement"
                            id={`timeEngagement-${index}`}
                            value={option.value} // Utilise `option.value`
                            checked={
                              clientInscriptionFormData.timeEngagement ===
                              option.value
                            } // Compare avec `option.value`
                            onChange={handleChange}
                            required
                          />{/*formErrors.timeEngagement && (
                            <p style={{ color: "red" }}>{formErrors.timeEngagement}</p>
                          )*/}
                          <label
                            className="form-check-label"
                            htmlFor={`timeEngagement-${index}`}
                          >
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="demande-button-container">
                      <button
                        onClick={() => dispatch(prevStep())}
                        className="previousStep"
                      >
                        Précédent
                      </button>
                      <button onClick={handleNextStep} className="nextStep">
                        Suivant
                      </button>
                    </div>
                  </>
                )}
                {step === 4 && (
                  <>
                    <div className="mb-3">
                      <label className="font-bold">Date de Début</label>
                      <input
                        type="date"
                        name="date_debut"
                        className="form"
                        min={getTomorrowDate()} 
                        required
                      />{formErrors.dateDebut && (
                        <p style={{ color: "red" }}>{formErrors.dateDebut}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="font-bold">Date de Fin</label>
                      <input
                        type="date"
                        name="date_fin"
                        className="form"
                        min={getTomorrowDate()} 
                        required
                      />{formErrors.dateFin && (
                        <p style={{ color: "red" }}>{formErrors.dateFin}</p>
                      )}
                      <div className="mb-3">
                      <label className="font-bold">Type de planification</label>
                      <select
                        name="planification_type"
                        className="form"
                        value={planification_type}
                        onChange={handlePlanificationTypeChange}
                        required
                      >
                        <option value="">Sélectionnez un type</option>
                        <option value="demi-journees">Planification par nombre de demi-journées</option>
                        <option value="detaillee">Planification détaillée</option>
                      </select>
                    </div>
                    {planification_type === "demi-journees" && (
                      <div className="mb-3">
                        <label className="font-bold">Nombre de demi-journées</label>
                        <input
                          type="number"
                          name="nombreDemiJournees"
                          className="form"
                          value={nombreDemiJournees}
                          onChange={handleNombreDemiJourneesChange}
                          //min="1"
                          //max={calculateMaxDemiJournees()}
                          required
                        />{formErrors.nombreDemiJournees && (
                          <p style={{ color: "red" }}>{formErrors.nombreDemiJournees}</p>
                        )}
                      </div>
                    )}
                    {planification_type === "detaillee" && (
                      <div className="mb-3">
                        <ClientCalendar 
                          availabilities={availabilities}
                          onAvailabilityChange={handleAvailabilityChange}
                        />
                      </div>
                    )}
                    </div>
                    <div className="demande-button-container">
                      <button
                        onClick={() => dispatch(prevStep())}
                        className="previousStep"
                      >
                        Précédent
                      </button>
                      <button
                        type="button"
                        //onClick={() => handleFormSubmit("RD")}
                        onClick={(event) => handleFormSubmit("RD", event)}
                        className="btn-blue"
                        disabled={isSubmitting}
                      >
                         {submittingButton === "RD" && isSubmitting ? 'En cours...' : 'Enregistrer et continuer plus tard'}
                      </button>
                      <button
                        type="submit"
                        className="btn-red"
                        //onClick={() => handleFormSubmit("WM")}
                        onClick={(event) => handleFormSubmit("WM", event)}
                        disabled={isSubmitting}
                      >
                        {submittingButton === "WM" && isSubmitting ? 'En cours...' : 'Terminer'}
                      </button>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDemandePage;
