import React from "react";
import { Link } from "react-router-dom";
import "../../styles/typeProfiles.css";
import { FaUser, FaUserTie, FaHandshake, FaUsers } from "react-icons/fa";
import logo from "../../assets/logoWhite.png";
import "../../styles/Buttons.css";
import "../../index.css";

const TypeDeProfilsPage = () => {
  return (
    <div className="loginbackgroundImage">
      <div>
        <div className="pl-9 pt-7">
          <Link className="loginlogo " to="/">
            <img className="max-2xl:w-[200px] 2xl:w-[280px]" src={logo} />
          </Link>
          <div className="TPtypes-profile">
            <div className=" text-white">
              <h5 className="text-fluid-md font-body font-bold">
                Bienvenue sur TeraTalenT ! Rejoignez nous en tant que
              </h5>
            </div>
            <div className="TPcard-container text-primary-800">
              <div className="TPcard  w-[100%] md:w-[45%]">
                <div className>
                  <FaUser size={40} />
                  <h5 className=" ">Free-lance</h5>
                  <p className=" ">Je propose mes services.</p>
                  <div className=""> 
                    <Link
                      to="/guide-inscription-freelancer"
                      className="btn-red"
                    >
                      Créer mon Profil
                    </Link>
                  </div>
                </div>
              </div>
              <div className="TPcard w-[100%] md:w-[45%]">
                <div className="">
                  <FaUsers size={45} />
                  <h5 className=" ">Entreprise fournisseur de services</h5>
                  <p className=" ">
                    Je propose des services ou une équipe compétente.
                  </p>
                  <div className="">
                    <Link to="/inscription-provider" className="btn-red">
                      Créer mon Profil
                    </Link>
                  </div>
                </div>
              </div>

              <div className="TPcard w-[100%] md:w-[45%]">
                <div>
                  <FaUserTie size={40} />
                  <h5 className=" ">Client</h5>
                  <p className=" ">
                    Je recherche des talents pour mes projets.
                  </p>
                  <div>
                    <Link to="/inscription-client" className="btn-red">
                      Exprimer mon besoin
                    </Link>
                  </div>
                </div>
              </div>
              <div className="TPcard w-[100%] md:w-[45%]">
                <div>
                  <FaHandshake size={40} />
                  <h5 className=" ">Partenaire Business</h5>
                  <p className=" ">
                    Je souhaite intégrer le réseau de partenaires TeraTalenT.
                  </p>
                  <div className="">
                    <Link to="/business-partner-request" className="btn-red">
                      Nous contacter
                    </Link>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <Link to="/logIn" className="text-white">
                  J'ai déjà un compte
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TypeDeProfilsPage;
