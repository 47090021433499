import React, { useState, useEffect } from 'react';
import { useAuth } from '../../components/AuthContext';
import { API_GATEWAY_URL } from '../../constants';
import { useForm } from 'react-hook-form';
import Badge from './Badge';
import '../../index.css';
import LoadingSpinner from '../../components/LoadingSpinner'; 
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog, DialogTitle, DialogContent,
    Button, TextField, MenuItem
} from '@mui/material';







const AddTechnicalSkill = ({ user, open, handleClose, setFreelancerTechnicalSkills }) => {
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();

    const LEVELSOFSENIORITY = [
        { value: 'CF', label: 'Confirmé' },
        { value: 'JR', label: 'Junior' },
        { value: 'SR', label: 'Senior' },
    ];

    useEffect(() => {
        if (open) {
            reset({
                expertisename: '',
                level: ''
            });
        }
    }, [open, reset]);

    const onSubmit = async (data) => {
        try {
            console.log(data);
            
            const response = await fetch(`${API_GATEWAY_URL}/expertisesf/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                const responseData = await response.json();
                console.log(responseData);
                setFreelancerTechnicalSkills((prevSkills) => {
                    const index = prevSkills.findIndex(skill => skill.expertisename === responseData.expertisename);
                    if (index !== -1) {
                        const updatedSkills = [...prevSkills];
                        updatedSkills[index].expertisename = responseData.expertisename;
                        updatedSkills[index].level = responseData.level;
                        return updatedSkills;
                    } else {
                        return [...prevSkills, responseData];
                    }
                });
                handleClose();
            } else {
                throw new Error('Failed to update data');
            }
        } catch (err) {
            console.error('Error updating data:', err);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Ajouter une Compétence</DialogTitle>
            <DialogContent>
                <form className='flex flex-col gap-2' onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        label="Compétence"
                        {...register('expertisename', { required: 'Ajouter une compétence' })}
                        error={!!errors.expertisename}
                        helperText={errors.expertisename ? errors.expertisename.message : ''}
                        margin="normal"
                    />
                    <TextField
                        select
                        label="Niveau de compétence"
                        defaultValue="Confirmé"
                        {...register('level', { required: 'Le niveau de compétence est requis' })}
                        error={!!errors.level}
                        helperText={errors.level ? errors.level.message : ''}
                        margin="normal"
                    >
                        {LEVELSOFSENIORITY.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <div className="flex items-center gap-3 mt-3">
                        <Button
                            variant="contained"
                            type="submit"
                            color='success'
                            sx={{
                                fontWeight: 'var(--fw-semibold)',
                                fontSize: '.8rem',
                            }}
                        >
                            Sauvegarder
                        </Button>
                        <Button 
                            variant="text"
                            color='error'
                            sx={{
                                fontWeight: 'var(--fw-semibold)',
                                fontSize: '.8rem',
                            }}
                            onClick={handleClose}
                        >
                            Annuler
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};




const AddLanguages = ({ user, open, handleClose, setFreelancerLanguagesSkills }) => {
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    
    const LANGUAGES = [
        { code: 'AR', name: 'Arabe' },
        { code: 'FR', name: 'Français' },
        { code: 'AN', name: 'Anglais' },
        { code: 'ES', name: 'Espagnol' },
        { code: 'AL', name: 'Allemand' },
        { code: 'IT', name: 'Italien' },
        { code: 'PO', name: 'Portugais' },
        { code: 'RU', name: 'Russe' },
        { code: 'CH', name: 'Chinois' },
        { code: 'JA', name: 'Japonais' },
    ];

    const LANGUAGE_LEVELS = [
        { value: '1', label: 'Élémentaire' },
        { value: '2', label: 'Limité professionnel' },
        { value: '3', label: 'Professionnel complet' },
        { value: '4', label: 'Professionnel natif ou bilingue' },
    ];

    useEffect(() => {
        if (open) {
            reset({
                language: '',
                level: ''
            });
        }
    }, [open, reset]);

    const onSubmit = async (data) => {
        try {
            const response = await fetch(`${API_GATEWAY_URL}/languages/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`
                },
                body: JSON.stringify(data)
            });
            if (response.ok) {
                const data = await response.json();
                setFreelancerLanguagesSkills((prevSkills) => {
                    const index = prevSkills.findIndex(skill => skill.language === data.language);
                    if (index !== -1) {
                        // Update existing language
                        const updatedSkills = [...prevSkills];
                        updatedSkills[index] = data;
                        return updatedSkills;
                    } else {
                        // Add new language
                        return [...prevSkills, data];
                    }
                });
                handleClose();
            } else {
                throw new Error('Failed to update data');
            }
        } catch (err) {
            console.error('Error updating data:', err);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Ajouter une Langue</DialogTitle>
            <DialogContent>
                <form className='flex flex-col gap-2' onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        select
                        label="Langue"
                        defaultValue=""
                        {...register('language', { required: 'Ajouter une langue' })}
                        error={!!errors.language}
                        helperText={errors.language ? errors.language.message : ''}
                        margin="normal"
                    >
                        {LANGUAGES.map((lang) => (
                            <MenuItem key={lang.code} value={lang.code}>
                                {lang.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        label="Niveau"
                        defaultValue=""
                        {...register('level', { required: 'Ajouter un niveau' })}
                        error={!!errors.level}
                        helperText={errors.level ? errors.level.message : ''}
                        margin="normal"
                    >
                        {LANGUAGE_LEVELS.map((level) => (
                            <MenuItem key={level.value} value={level.value}>
                                {level.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <div className="flex items-center gap-3 mt-3">
                        <Button
                            variant="contained"
                            type="submit"
                            color='success'
                            sx={{
                                fontWeight: 'var(--fw-semibold)',
                                fontSize: '.8rem',
                            }}
                        >
                            Sauvegarder
                        </Button>
                        <Button 
                            variant="text"
                            color='error'
                            sx={{
                                fontWeight: 'var(--fw-semibold)',
                                fontSize: '.8rem',
                            }}
                            onClick={handleClose}
                        >
                            Annuler
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};



const AddRole = ({ user, open, handleClose, setFreelancerData }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async (data) => {
        try {
            // Fetch the current data from the backend
            const fetchCurrentData = async () => {
                const response = await fetch(`${API_GATEWAY_URL}/freelancer-profile/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    return await response.json();
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            };
            const currentData = await fetchCurrentData();
            const updatedData = { ...currentData, profilTitle: data.role };
            
            const response = await fetch(`${API_GATEWAY_URL}/update-freelancer/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`
                },
                body: JSON.stringify({ profilTitle: data.role })
            });
    
            if (response.ok) {
                const responseData = await response.json();
                setFreelancerData(responseData);
                handleClose();
            } else {
                throw new Error('Failed to update data');
            }
        } catch (err) {
            console.error('Error updating data:', err);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Ajouter un Poste Préféré</DialogTitle>
            <DialogContent>
                <form 
                    className='flex flex-col gap-2'
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <TextField
                        label="Poste Préféré"
                        {...register('role', { required: 'Ajouter un poste' })}
                        error={!!errors.role}
                        helperText={errors.role ? errors.role.message : ''}
                        margin="normal"
                    />
                    <div className="flex items-center gap-3 mt-3">
                        <Button
                            variant="contained"
                            type="submit"
                            color='success'
                            sx={{
                                fontWeight: 'var(--fw-semibold)',
                                fontSize: '.8rem',
                            }}
                        >
                            Sauvegarder
                        </Button>
                        <Button 
                            variant="text"
                            color='error'
                            sx={{
                                fontWeight: 'var(--fw-semibold)',
                                fontSize: '.8rem',
                            }}
                            onClick={handleClose}
                        >
                            Annuler
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};



const AddSeniority = ({ user, open, handleClose, setFreelancerData }) => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [niveauFreelance, setNiveauFreelance] = useState('');

    // Fetch current data when the component mounts or when `open` changes
    useEffect(() => {
        const fetchCurrentData = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/freelancer-profile/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setNiveauFreelance(data.niveauFreelance || '');
                    setValue('niveauFreelance', data.niveauFreelance || '');
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (open) {
            fetchCurrentData();
        }
    }, [open, setValue, user.access_token]);

    const onSubmit = async (data) => {
        try {
            const updatedData = { 
                ...data,
                niveauFreelance: data.niveauFreelance,
            };
            delete updatedData.niveau_freelance_readable;
            console.log(updatedData);
            const response = await fetch(`${API_GATEWAY_URL}/update-freelancer/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`
                },
                body: JSON.stringify(updatedData)
            });

            if (response.ok) {
                handleClose();
            } else {
                const errorData = await response.json();
                throw new Error(`Failed to update data: ${errorData.message || 'Unknown error'}`);
            }
        } catch (err) {
            console.error('Error updating data:', err);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Ajouter un Niveau</DialogTitle>
            <DialogContent>
                <form 
                    className='flex flex-col gap-2'
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <TextField
                        select
                        label="Niveau Freelance"
                        value={niveauFreelance}
                        onChange={(e) => {
                            setNiveauFreelance(e.target.value);
                            setValue('niveauFreelance', e.target.value);
                        }}
                        error={!!errors.niveauFreelance}
                        helperText={errors.niveauFreelance ? errors.niveauFreelance.message : ''}
                        margin="normal"
                    >
                        <MenuItem value="NONE">Je n'ai jamais réalisé de mission en freelance.</MenuItem>
                        <MenuItem value="MANY">J'ai déjà réalisé plusieurs missions en freelance.</MenuItem>
                        <MenuItem value="FULL">J'ai l'habitude de réaliser des missions en freelance.</MenuItem>
                    </TextField>
                    <div className="flex items-center gap-3 mt-3">
                        <Button
                            variant="contained"
                            type="submit"
                            color='success'
                            sx={{
                                fontWeight: 'var(--fw-semibold)',
                                fontSize: '.8rem',
                            }}
                        >
                            Sauvegarder
                        </Button>
                        <Button 
                            variant="text"
                            color='error'
                            sx={{
                                fontWeight: 'var(--fw-semibold)',
                                fontSize: '.8rem',
                            }}
                            onClick={handleClose}
                        >
                            Annuler
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};




const AddDescription = ({ user, open, handleClose, setFreelancerData }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const changeDescriptionInfos = async (newData) => {
        try {
            // Fetch the current data from the backend
            const fetchCurrentData = async () => {
                const response = await fetch(`${API_GATEWAY_URL}/freelancer-profile/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    return await response.json();
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            };
            const currentData = await fetchCurrentData();
            // Merge the current data with the new data
            const updatedData = { ...currentData, ...newData };
            // Create a new object with the updated data
            const newFreelancerDataInfos = {
                Address: updatedData.Address,
                birthdayDate: updatedData.birthdayDate,
                codePostal: updatedData.codePostal,
                country: updatedData.pays,
                ville: updatedData.ville,
                commune: updatedData.commune,
                creation_date: updatedData.creation_date,
                cv_url: updatedData.cv_url,
                description: updatedData.description,
                firstName: updatedData.nom,
                lastName: updatedData.prenom,
                email: updatedData.email,
                linkedin: updatedData.linkedin,
                niveauFreelance: updatedData.niveauFreelance,
                niveau_freelance_readable: updatedData.niveau_freelance_readable,
                phoneNumber: updatedData.numeroDeTelephone,
                profilTitle: updatedData.profilTitle,
                profile_picture_url: updatedData.profile_picture_url,
                user: updatedData.user,
            }
            // Send the updated data back to the backend
            const response = await fetch(`${API_GATEWAY_URL}/update-freelancer/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`
                },
                body: JSON.stringify(newFreelancerDataInfos)
            });
            if (response.ok) {
                const data = await response.json();
                setFreelancerData(data);
            } else {
                throw new Error('Failed to update data');
            }
        } catch (err) {
            console.error('Error updating data:', err);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Informations sur l'emploi</DialogTitle>
            <DialogContent>
                <form 
                    className='flex flex-col gap-2'
                    onSubmit={handleSubmit(changeDescriptionInfos)}
                >
                    <TextField
                        label="Description"
                        {...register('description', { required: 'Ajouter une description' })}
                        error={!!errors.description}
                        helperText={errors.description ? errors.description.message : ''}
                        multiline
                        rows={4}
                        margin="normal"
                    />
                    <div className="flex items-center gap-3 mt-3">
                        <Button
                            variant="contained"
                            type="submit"
                            color='success'
                            sx={{
                                fontWeight: 'var(--fw-semibold)',
                                fontSize: '.8rem',
                            }}
                        >
                            Sauvegarder
                        </Button>
                        <Button 
                            variant="text"
                            color='error'
                            sx={{
                                fontWeight: 'var(--fw-semibold)',
                                fontSize: '.8rem',
                            }}
                            onClick={handleClose}
                        >
                            Annuler
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};


const Skills = () => {
    const LANGUAGES = {
        'AR': 'Arabe',
        'FR': 'Français',
        'AN': 'Anglais',
        'ES': 'Espagnol',
        'AL': 'Allemand',
        'IT': 'Italien',
        'PO': 'Portugais',
        'RU': 'Russe',
        'CH': 'Chinois',
        'JA': 'Japonais',
    };
    const LANGUAGE_LEVELS = [
        ('1', 'Élémentaire'),
        ('2', 'Limité professionnel'),
        ('3', 'Professionnel complet'),
        ('4', 'Professionnel natif ou bilingue'),
    ]

    const [activeSectionOne, setActiveSectionOne] = useState(1); 
    const handleButtonOneClick = () => {
        setActiveSectionOne(prevSection => prevSection === 0 ? 1 : 0);
    };

    const { user } = useAuth();
    const [openAddTechnicalSkill, setOpenAddTechnicalSkill] = useState(false);
    const [freelancerTechnicalSkills, setFreelancerTechnicalSkills] = useState([]);
    const handleClickOpenAddTechnicalSkill = () => setOpenAddTechnicalSkill(true);
    const handleCloseAddTechnicalSkill = () => setOpenAddTechnicalSkill(false);

    const [openAddLanguages, setOpenAddLanguages] = useState(false);
    const [freelancerLanguagesSkills, setFreelancerLanguagesSkills] = useState([]);
    const handleClickOpenAddLanguages = () => setOpenAddLanguages(true);
    const handleCloseAddLanguages = () => setOpenAddLanguages(false);

    const [openAddDescription, setOpenAddDescription] = useState(false);
    const [openChangePost, setOpenChangePost] = useState(false);
    const [openChangeSeniority, setOpenChangeSeniority] = useState(false);
    const [freelancerData, setFreelancerData] = useState(null);
    const handleClickOpenChangeDescription = () => setOpenAddDescription(true);
    const handleCloseChangeDescription = () => setOpenAddDescription(false);
    const handleClickOpenChangePost = () => setOpenChangePost(true);
    const handleCloseChangePost = () => setOpenChangePost(false);
    const handleClickOpenChangeSeniority = () => setOpenChangeSeniority(true);
    const handleCloseChangeSeniority = () => setOpenChangeSeniority(false);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchFreelancerData = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/freelancer-profile/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setFreelancerData(data);
                } else {
                throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerData();
        }
    }, [user]);



    useEffect(() => {
        const fetchFreelancerLanguagesSkills = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/languages/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setFreelancerLanguagesSkills(data);
                } else {
                throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerLanguagesSkills();
        }
    }, [user]);



    useEffect(() => {
        const fetchFreelancerTechnicalSkills = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/expertisesf/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log(data);
                    setFreelancerTechnicalSkills(data);
                } else {
                throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerTechnicalSkills();
        }
    }, [user]);
    if (loading) {
        return <LoadingSpinner isLoading={true} />;
    };
    if (error) {
        return <div>Erreur: {error}</div>;
    };

    

    return (
        <main 
            className="w-full flex flex-col gap-4 md:mt-4"
            style={{fontFamily: 'var(--ff-body)',}}
        >
            <h3 className='hidden md:block'>Competences</h3>
            <div
                className='py-4 px-8 rounded-lg w-full border-2 border-gray-200'
            >
                <header
                    className='pb-3 pt-2 flex justify-between items-center'
                >
                    <h2 className='mb-0'>Vos competences</h2>
                    {
                        activeSectionOne ? (
                            <button 
                                className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100`}
                                onClick={handleButtonOneClick}
                                style={{
                                    width: '2rem',
                                    height: '2rem',
                                    border: '1px solid var(--clr-neutral-300)',
                                }}
                            >
                                <EditOutlinedIcon 
                                    className='-text--clr-secondary-500'
                                    style={{
                                        width: '1.2rem',
                                        height: '1.2rem',
                                    }}
                                />
                            </button>
                        ) : (
                            <button 
                                className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100`}
                                onClick={handleButtonOneClick}
                                style={{
                                    width: '2rem',
                                    height: '2rem',
                                    border: '1px solid var(--clr-neutral-300)',
                                }}
                            >
                                <CloseIcon 
                                    className='-text--clr-secondary-500'
                                    style={{
                                        width: '1.2rem',
                                        height: '1.2rem',
                                    }}
                                />
                            </button>
                        )
                    }
                </header>
                <section>
                    <div 
                        className='mb-4'
                        style={{
                            fontFamily: 'var(--ff-body)',
                        }} 
                    >
                        <div className="flex justify-between">
                            <div className="mb-1"
                                style={{fontWeight: 'var(--fw-semibold)',}}
                            >
                                Poste Preferé
                            </div>
                            {
                                !activeSectionOne ? (
                                    <button 
                                        className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100`}
                                        onClick={handleClickOpenChangePost}
                                        style={{
                                            width: '2rem',
                                            height: '2rem',
                                            border: '1px solid var(--clr-neutral-300)',
                                        }}
                                    >
                                        <EditOutlinedIcon 
                                            className='-text--clr-secondary-500'
                                            style={{
                                                width: '1.2rem',
                                                height: '1.2rem',
                                            }}
                                        />
                                    </button>
                                ) : null
                            }
                        </div>
                            <p 
                                style={{fontWeight: 'var(--fw-regular)'}}
                            >
                                {freelancerData.profilTitle}
                            </p>
                            {
                                openChangePost && <AddRole open={handleClickOpenChangePost} handleClose={handleCloseChangePost} setFreelancerData={setFreelancerData} user={user}/>
                            }
                    </div>
                    <div 
                        className='mb-4'
                        style={{
                            fontFamily: 'var(--ff-body)',
                        }} 
                    >
                        <div className="flex justify-between">
                            <div className="mb-1"
                                style={{fontWeight: 'var(--fw-semibold)',}}
                            >
                                Niveau freelance
                            </div>
                            {
                                !activeSectionOne ? (
                                    <button 
                                        className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100`}
                                        onClick={handleClickOpenChangeSeniority}
                                        style={{
                                            width: '2rem',
                                            height: '2rem',
                                            border: '1px solid var(--clr-neutral-300)',
                                        }}
                                    >
                                        <EditOutlinedIcon 
                                            className='-text--clr-secondary-500'
                                            style={{
                                                width: '1.2rem',
                                                height: '1.2rem',
                                            }}
                                        />
                                    </button>
                                ) : null
                            }
                        </div>
                        <p 
                            style={{fontWeight: 'var(--fw-regular)'}}
                        >
                            {freelancerData.niveau_freelance_readable}
                        </p>
                        {
                            openChangeSeniority && <AddSeniority user={user} open={handleClickOpenChangeSeniority} handleClose={handleCloseChangeSeniority} setFreelancerData={setFreelancerData}/>
                        }
                    </div>
                    <div 
                        className='mb-4'
                        style={{
                            fontFamily: 'var(--ff-body)',
                        }} 
                    >
                        <div className="flex justify-between">
                            <div className="mb-1"
                                style={{fontWeight: 'var(--fw-semibold)',}}
                            >
                                Description
                            </div>
                            {
                                !activeSectionOne ? (
                                    <button 
                                        className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100`}
                                        onClick={handleClickOpenChangeDescription}
                                        style={{
                                            width: '2rem',
                                            height: '2rem',
                                            border: '1px solid var(--clr-neutral-300)',
                                        }}
                                    >
                                        <EditOutlinedIcon 
                                            className='-text--clr-secondary-500'
                                            style={{
                                                width: '1.2rem',
                                                height: '1.2rem',
                                            }}
                                        />
                                    </button>
                                ) : null
                            }
                        </div>
                        <p 
                            style={{fontWeight: 'var(--fw-regular)', fontSize: '0.9rem'}}
                        >
                            {freelancerData.description}
                        </p>
                        {
                            openAddDescription && <AddDescription user={user} open={handleClickOpenChangeDescription} handleClose={handleCloseChangeDescription} setFreelancerData={setFreelancerData}/>
                        }
                    </div>
                    <div 
                        className='mb-4'
                        style={{
                            fontFamily: 'var(--ff-body)',
                        }} 
                    >
                        <div className="flex justify-between">
                            <div className="mb-2"
                                style={{fontWeight: 'var(--fw-semibold)',}}
                            >
                                Competences
                            </div>
                            {
                                !activeSectionOne ? (
                                    <button 
                                        className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100`}
                                        onClick={handleClickOpenAddTechnicalSkill}
                                        style={{
                                            width: '2rem',
                                            height: '2rem',
                                            border: '1px solid var(--clr-neutral-300)',
                                        }}
                                    >
                                        <EditOutlinedIcon 
                                            className='-text--clr-secondary-500'
                                            style={{
                                                width: '1.2rem',
                                                height: '1.2rem',
                                            }}
                                        />
                                    </button>
                                ) : null
                            }
                        </div>
                        {
                            openAddTechnicalSkill && 
                            <AddTechnicalSkill 
                                user={user}
                                open={openAddTechnicalSkill} 
                                handleClose={handleCloseAddTechnicalSkill} 
                                previousSkills={freelancerTechnicalSkills}
                                setFreelancerTechnicalSkills={setFreelancerTechnicalSkills}
                            />
                        }
                        <div className="flex gap-3 flex-wrap">
                            {
                                freelancerTechnicalSkills.map((skill) => {
                                    let badgeTitle = skill.expertisename + "-" + skill.level;
                                    return <Badge badgeTitle={badgeTitle} />;
                                })
                            }
                        </div>
                    </div>
                    <div 
                        className='mb-4'
                        style={{
                            fontFamily: 'var(--ff-body)',
                        }} 
                    >
                        <div className="flex justify-between">
                            <div className="mb-2"
                                style={{fontWeight: 'var(--fw-semibold)',}}
                            >
                                Langues
                            </div>
                            {
                                !activeSectionOne ? (
                                    <button 
                                        className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100`}
                                        onClick={handleClickOpenAddLanguages}
                                        style={{
                                            width: '2rem',
                                            height: '2rem',
                                            border: '1px solid var(--clr-neutral-300)',
                                        }}
                                    >
                                        <EditOutlinedIcon 
                                            className='-text--clr-secondary-500'
                                            style={{
                                                width: '1.2rem',
                                                height: '1.2rem',
                                            }}
                                        />
                                    </button>
                                ) : null
                            }
                        </div>
                        {
                            openAddLanguages && <AddLanguages user={user} open={handleClickOpenAddLanguages} handleClose={handleCloseAddLanguages} setFreelancerLanguagesSkills={setFreelancerLanguagesSkills} />
                        }
                        <div className="flex gap-3 flex-wrap">
                            {
                                freelancerLanguagesSkills.map((languageSkill) => {
                                    let badgeTitle = LANGUAGES[languageSkill.language] + "-" + LANGUAGE_LEVELS[languageSkill.level];
                                    return <Badge badgeTitle={badgeTitle} />;
                                })
                            }
                        </div>
                    </div>
                </section>
            </div>
        </main>
    );
};

export default Skills;
