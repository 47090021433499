import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import feedback from "../icons/feedback.png";

const Carousel = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const prevSlide = () => {
    setCurrentSlide((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentSlide((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Automatically change slide every 5 seconds (5000 milliseconds)
  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, 10000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [slides]);

  return (
    <div className="flex relative w-full mx-auto max-sm:px-0 lg:px-8">
      <div className="FeedBackClient">
        <div
          className="flex transition-transform duration-700"
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          {slides.map((profile, index) => (
            <div
              key={index}
              className="w-full flex-shrink-0 p-4 font-body text-primary-800"
            >
              <div className="flex max-md:flex-col">
                <img
                  src={profile.imageSrc}
                  alt={`${profile.name}'s photo`}
                  className="max-2xl:w-24 max-2xl:h-24 2xl:w-32 2xl:h-32 max-md:w-24 max-md:h-24 rounded-full object-cover mb-4"
                />
                <div className="flex flex-col md:m-6">
                  <h3 className="max-2xl:text-fluid-sm 2xl:text-fluid-lg font-bold">
                    {profile.name}
                  </h3>
                  <p className="max-2xl:text-fluid-sm 2xl:text-fluid-md font-semibold">
                    {profile.jobTitle}
                  </p>
                </div>
              </div>
              <p className="max-2xl:text-fluid-ms 2xl:text-fluid-md">
                {profile.feedback}
              </p>
            </div>
          ))}
        </div>

        <div>
          <img
            src={feedback}
            alt="quotation mark"
            className="absolute right-36 bottom-6 h-10 w-14 max-md:hidden rotate-180"
          />
        </div>
      </div>

      <button
        onClick={prevSlide}
        className="absolute top-1/2 transform -translate-y-1/2 left-4 max-md:-left-8 p-2 rounded-full bg-transparent"
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="h-10 w-10 text-white"
        />
      </button>
      <button
        onClick={nextSlide}
        className="absolute top-1/2 transform -translate-y-1/2 right-4 max-md:-right-8 p-2 rounded-full bg-transparent"
      >
        <FontAwesomeIcon
          icon={faChevronRight}
          className="h-10 w-10 text-white"
        />
      </button>

      <div className="absolute -bottom-10 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentSlide(index)}
            className={`w-1 h-2 max-md:w-0.5 max-md:h-0.5 rounded-full ${
              currentSlide === index ? "bg-secondary-500" : "bg-primary-50"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
