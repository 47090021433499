import React, { useState, useEffect } from 'react';
import { useAuth } from '../../components/AuthContext';
import LoadingSpinner from '../../components/LoadingSpinner'; 
import { API_GATEWAY_URL } from '../../constants';
import { useForm } from 'react-hook-form';
import '../../index.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
    Dialog, DialogTitle, DialogContent,
    Button, TextField, Checkbox, FormControlLabel, MenuItem
} from '@mui/material';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';




const diplomaTypes = [
    { value: 'Bac', label: 'Bac' },
    { value: 'Licence', label: 'Licence' },
    { value: 'Master', label: 'Master' },
    { value: 'Doctorat', label: 'Doctorat' },
    { value: 'Autre', label: 'Autre' },
];

const EducationForm = ({ user, handleCancel }) => {
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const [isCurrentlyStudying, setIsCurrentlyStudying] = useState(false);

    const onSubmit = async (data) => {
        try {
            // Vérifier si endDate est null
            const end_date = data.endDate ? dayjs(data.endDate).format('DD-MM-YYYY') : null;
            const newData = {
                ...data,
                en_cours: data.currentlyStudying,
                start_date: dayjs(data.startDate).format('DD-MM-YYYY'),
                end_date: end_date,
            };
            delete newData.currentlyStudying;
            delete newData.startDate;
            delete newData.endDate;
            
            console.log(newData);
            // Envoyer les données au backend
            await fetch(`${API_GATEWAY_URL}/degrees/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`
                },
                body: JSON.stringify(newData)
            });
        } catch (err) {
            console.error('Erreur lors de la mise à jour des données:', err);
        }
        handleCancel();
    };

    const handleCheckboxChange = (event) => {
        setIsCurrentlyStudying(event.target.checked);
    };

    return (
        <form 
            className='flex flex-col gap-2'
            onSubmit={handleSubmit(onSubmit)}
        >
            <TextField
                id="outlined-select-currency"
                select
                label="Diplome obtenu"
                {...register('diploma_type', { required: 'Le diplôme de la formation est requis' })}
                error={!!errors.diploma_type}
                helperText={errors.diploma_type ? errors.diploma_type.message : ''}
                margin="normal"
                variant="outlined"
            >
                {diplomaTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                label="Titre de la formation"
                {...register('field_of_study', { required: 'Le titre de la formation est requis' })}
                error={!!errors.field_of_study}
                helperText={errors.field_of_study ? errors.field_of_study.message : ''}
                margin="normal"
                fullWidth
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <div className="w-full flex flex-col gap-1 md:flex-row md:gap-0 md:justify-between md:items-center">
                        <DatePicker
                            views={['day', 'month', 'year']}
                            label="Date de début"
                            value={watch('startDate') || null} // Assurez-vous d'avoir une valeur par défaut
                            onChange={(newValue) => setValue('startDate', newValue)}
                            required
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...register('startDate', { required: 'La date de début est requise' })}
                                    error={!!errors.startDate}
                                    helperText={errors.startDate ? errors.startDate.message : ''}
                                    margin="normal"
                                    fullWidth
                                />
                            )}
                        />
                        <DatePicker
                            views={['day', 'month', 'year']}
                            label="Date de fin"
                            value={watch('endDate') || null} // Assurez-vous d'avoir une valeur par défaut
                            onChange={(newValue) => setValue('endDate', newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...register('endDate', {
                                        required: !isCurrentlyStudying && 'La date de fin est requise',
                                    })}
                                    error={!!errors.endDate}
                                    helperText={errors.endDate ? errors.endDate.message : ''}
                                    margin="normal"
                                    fullWidth
                                    disabled={isCurrentlyStudying}
                                />
                            )}
                        />
                    </div>
                </DemoContainer>
            </LocalizationProvider>
            <FormControlLabel
                control={
                    <Checkbox 
                        {...register('currentlyStudying')}
                        onChange={handleCheckboxChange}
                    />
                }
                label="Je suis actuellement un étudiant"
            />
            <TextField
                label="Nom de l'université/école"
                {...register('school_name', { required: 'Le nom de l\'université/école est requis' })}
                error={!!errors.school_name}
                helperText={errors.school_name ? errors.school_name.message : ''}
                margin="normal"
            />
            <TextField
                label="Localisation de l'université/école"
                {...register('school_location', { required: 'La localisation de l\'université/école est requise' })}
                error={!!errors.school_location}
                helperText={errors.school_location ? errors.school_location.message : ''}
                margin="normal"
                fullWidth
            />
            <div className="flex flex-col items-end">
                <TextField
                    fullWidth
                    label="Description"
                    {...register('description', { required: 'La description est requise' })}
                    error={!!errors.description}
                    helperText={errors.description ? errors.description.message : ''}
                    margin="normal"
                />
            </div>
            <div className="flex items-center gap-3 mt-3">
                <Button
                    variant="contained"
                    type="submit"
                    color='success'
                    onClick={handleSubmit(onSubmit)}
                    sx={{
                        fontWeight: 'var(--fw-semibold)',
                        fontSize: '.8rem',
                    }}
                >
                    Sauvegarder
                </Button>
                <Button 
                    variant="text"
                    color='error'
                    onClick={handleCancel}
                    sx={{
                        fontWeight: 'var(--fw-semibold)',
                        fontSize: '.8rem',
                    }}
                >
                    Annuler
                </Button>
            </div>
        </form>
    );
}

const AddEducation = ({ open, handleClose }) => {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Informations sur l'emploi</DialogTitle>
            <DialogContent>
                <EducationForm 
                    user={useAuth()}
                    handleCancel={handleClose}
                />
            </DialogContent>
        </Dialog>
    );
};







const platforms = [
    { value: 'Coursera', label: 'Coursera' },
    { value: 'Udemy', label: 'Udemy' },
    { value: 'edX', label: 'edX' },
    { value: 'Kaggle', label: 'Kaggle' },
    { value: 'Udacity', label: 'Udacity' },
    { value: 'Other', label: 'Autre' },
];

const CertificationForm = ({ user, handleCancel, setFreelancerCertificates }) => {
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const onSubmit = async (data) => {
        const issueDate = data.issueDate ? dayjs(data.issueDate).format('YYYY-MM-DD') : null;
        const newData = {
            name: data.certificateTitle,
            platform: data.platform,
            issueDate: issueDate,
            description: data.description,
            certificate_file: '/media/certificates/filename',
        };
        console.log(newData);
        try {
            await fetch(`${API_GATEWAY_URL}/certificates/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`
                },
                body: JSON.stringify(newData)
            });
            setFreelancerCertificates(prevState => [...prevState, newData]);
        } catch (error) {
            console.error('Error:', error);
        }
        handleCancel();
    };

    return (
        <form className='flex flex-col gap-2' onSubmit={handleSubmit(onSubmit)}>
            <TextField
                label="Nom de la Certification"
                {...register('certificateTitle', { required: 'Le nom de la certification est requis' })}
                error={!!errors.certificateTitle}
                helperText={errors.certificateTitle ? errors.certificateTitle.message : ''}
                margin="normal"
            />
            <TextField
                id="outlined-select-currency"
                select
                label="Plateforme"
                defaultValue="" // Set default value to an empty string
                {...register('platform', { required: 'Le nom de la plateforme est requis' })}
                error={!!errors.platform}
                helperText={errors.platform ? errors.platform.message : ''}
                margin="normal"
                variant="outlined"
            >
                {platforms.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    views={['day', 'month', 'year']}
                    label="Date d'émission"
                    value={watch('issueDate') || null} // Ensure a default value
                    onChange={(newValue) => setValue('issueDate', newValue)}
                    slotProps={{
                        textField: {
                            ...register('issueDate', {
                                required: 'La date d\'émission est requise',
                            }),
                            error: !!errors.issueDate,
                            helperText: errors.issueDate ? errors.issueDate.message : '',
                            margin: "normal",
                            fullWidth: true,
                        }
                    }}
                />
            </LocalizationProvider>
            <TextField
                fullWidth
                label="Description"
                {...register('description', { required: 'La description est requise' })}
                error={!!errors.description}
                helperText={errors.description ? errors.description.message : ''}
                margin="normal"
            />
            <div className="flex items-center gap-3 mt-3">
                <Button
                    variant="contained"
                    type="submit"
                    color='success'
                    onClick={handleSubmit(onSubmit)}
                    sx={{
                        fontWeight: 'var(--fw-semibold)',
                        fontSize: '.8rem',
                    }}
                >
                    Sauvegarder
                </Button>
                <Button
                    variant="text"
                    color='error'
                    onClick={handleCancel}
                    sx={{
                        fontWeight: 'var(--fw-semibold)',
                        fontSize: '.8rem',
                    }}
                >
                    Annuler
                </Button>
            </div>
        </form>
    );
};

const AddCertification = ({ open, handleClose, setFreelancerCertificates }) => {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Informations sur la certification</DialogTitle>
            <DialogContent>
                <CertificationForm 
                    user={useAuth()}
                    handleCancel={handleClose}
                    setFreelancerCertificates={setFreelancerCertificates}
                />
            </DialogContent>
        </Dialog>
    );
};



const EducationAndCertificates = ({educations, certifications}) => {
    const [activeSectionOne, setActiveSectionOne] = useState(1); 
    const [educationsList, setEducationsList] = useState(educations);
    const [certificationsList, setCertificationsList] = useState(certifications);
    const [activeSectionTwo, setActiveSectionTwo] = useState(1);
    const [openAddEducation, setOpenAddEducation] = useState(false);
    const [openAddCertification, setOpenAddCertification] = useState(false);

    const { user } = useAuth();
    const [freelancerEducation, setFreelancerEducation] = useState(null);
    const [freelancerCertificates, setFreelancerCertificates] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFreelancerEducation = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/degrees/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    const educationsForm = data.map( educationInfos => {
                        return {
                            diploma_type: educationInfos.diploma_type,
                            field_of_study: educationInfos.field_of_study,
                            start_date: educationInfos.start_date,
                            end_date: educationInfos.end_date,
                            school_name: educationInfos.school.name,
                            school_location: educationInfos.school.location,
                            description: educationInfos.description
                        }
                    })
                    setFreelancerEducation(educationsForm)
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerEducation();
        }
    }, [user]);




    useEffect(() => {
        const fetchFreelancerCertificates = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/certificates/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    const certificatesForm = data.map( certificateInfos => {
                        return {
                            title: certificateInfos.name,
                            platform: certificateInfos.platform,
                            date: certificateInfos.issue_date,
                            description: certificateInfos.description
                        }
                    })
                    setFreelancerCertificates(certificatesForm);
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerCertificates();
        }
    }, [user]);
    if (loading) {
        return <LoadingSpinner isLoading={true} />;
    };
    if (error) {
        return <div>Erreur: {error}</div>;
    };



    const handleButtonOneClick = () => {
        setActiveSectionOne(prevSection => prevSection === 0 ? 1 : 0);
    };
    const handleButtonTwoClick = () => {
        setActiveSectionTwo(prevSection => prevSection === 0 ? 1 : 0);
    };
    const handleClickOpenAddEducation = () => {
        setOpenAddEducation(true);
    }
    const handleClickOpenAddCertification = () => {
        setOpenAddCertification(true);
    }
    const handleCloseAddEducation = () => {
        setOpenAddEducation(false);
    };
    const handleCloseAddCertification = () => {
        setOpenAddCertification(false);
    };
    function handleDeleteEducation(index) {
        console.log('Job deleted');
        let newArray = educationsList.filter(item => item.id !== index);
        setEducationsList(newArray);
    }
    function handleDeleteCertification(index) {
        console.log('Certification deleted');
        let newArray = certificationsList.filter(item => item.id !== index);
        setCertificationsList(newArray);
    }

    return (
        <main 
            className="w-full flex flex-col gap-4 md:mt-4"
            style={{fontFamily: 'var(--ff-body)',}}
        >
            <h3 className='hidden md:block'>Education et Certifications</h3>
            <div
                className='py-4 px-8 rounded-lg w-full border-2 border-gray-200'
            >
                <header
                    className='pb-3 pt-2 flex justify-between items-center'
                >
                    <h2 className='mb-2'>Education</h2>
                    {
                        activeSectionOne ? (
                            <button 
                                className="flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100 "
                                onClick={handleButtonOneClick}
                                style={{
                                    width: '2rem',
                                    height: '2rem',
                                    border: '1px solid var(--clr-neutral-300)',
                                }}
                            >
                                <EditOutlinedIcon 
                                    className='-text--clr-secondary-500'
                                    style={{
                                        width: '1.2rem',
                                        height: '1.2rem',
                                    }}
                                />
                            </button>
                        ) : (
                            <button 
                                className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100`}
                                onClick={handleClickOpenAddEducation}
                                style={{
                                    width: '2rem',
                                    height: '2rem',
                                    border: '1px solid var(--clr-secondary-500)',
                                }}
                            >
                                <span 
                                    style={{
                                        color: 'var(--clr-secondary-500)',
                                        fontSize: '1.6rem',
                                        fontWeight: 'var(--fw-semibold)',
                                    }}
                                >
                                    +
                                </span>
                            </button>
                        )
                    }
                    
                </header>
                {
                    openAddEducation && <AddEducation open={openAddEducation} handleClose={handleCloseAddEducation}/>
                }
                {
                    freelancerEducation != null ? (
                        freelancerEducation.map((education, index) => (
                            <section className='flex flex-col gap-2 mb-3'>
                                <div className="flex flex-row items-center justify-between">
                                    <div 
                                        style={{
                                            fontFamily: 'var(--ff-body)',
                                            fontWeight: 'var(--fw-semibold)',
                                            fontSize: '1.2rem',
                                            color: 'var(--clr-primary-500)',
                                        }} 
                                    >
                                            {education.diploma_type} {education.field_of_study}
                                    </div>
                                    <div 
                                        style={{
                                            fontFamily: 'var(--ff-body)',
                                            fontWeight: 'var(--fw-medium)',
                                            fontSize: '1rem',
                                        }} 
                                        >
                                        {education.start_date} <span><ArrowRightIcon style={{color: 'var(--clr-primary-500)'}}/></span> {education.end_date}
                                    </div>
                                </div>
                                <div className="flex flex-row items-center justify-between">
                                    <div 
                                            style={{
                                                fontFamily: 'var(--ff-body)',
                                                fontWeight: 'var(--fw-medium)',
                                                fontSize: '1rem',
                                                marginTop: '-.3rem',
                                                marginBottom: '.3rem',
                                            }} 
                                    >
                                        {education.school_name}
                                    </div>
                                    <div 
                                            style={{
                                                fontFamily: 'var(--ff-body)',
                                                fontWeight: 'var(--fw-medium)',
                                                fontSize: '1rem',
                                                marginTop: '-.3rem',
                                                marginBottom: '.3rem',
                                            }} 
                                    >
                                        {education.school_location}
                                    </div>
                                </div>
                                <div 
                                    style={{
                                        fontFamily: 'var(--ff-body)',
                                        fontWeight: 'var(--fw-regular)',
                                        fontSize: '.9rem',
                                    }} 
                                >
                                    <ul className='list-disc list-inside'>
                                        <li>{education.description}</li>
                                    </ul>
                                </div>
                                {
                                    !activeSectionOne ? (
                                        <Button 
                                            variant="text"
                                            color='error'
                                            sx={{
                                                width: 'fit-content',
                                                fontWeight: 'var(--fw-semibold)',
                                                fontSize: '.8rem',
                                                alignSelf: 'flex-end',
                                            }}
                                            onClick={() =>
                                                handleDeleteEducation(index)
                                            }
                                        >
                                            Supprimer
                                        </Button>
                                    ) : null
                                }
                            </section>
                        ))
                    ) : (
                        <>
                        </>
                    )
                }
            </div>

            <div
                className='py-4 px-8 rounded-lg w-full border-2 border-gray-200'
            >
                <header
                    className='pb-3 pt-2 flex justify-between items-center'
                >
                    <h2 className='mb-2'>Certifications</h2>
                    {
                        activeSectionTwo ? (
                            <button 
                                className="flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100 "
                                onClick={handleButtonTwoClick}
                                style={{
                                    width: '2rem',
                                    height: '2rem',
                                    border: '1px solid var(--clr-neutral-300)',
                                }}
                            >
                                <EditOutlinedIcon 
                                    className='-text--clr-secondary-500'
                                    style={{
                                        width: '1.2rem',
                                        height: '1.2rem',
                                    }}
                                />
                            </button>
                        ) : (
                            <button 
                                className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100`}
                                onClick={handleClickOpenAddCertification}
                                style={{
                                    width: '2rem',
                                    height: '2rem',
                                    border: '1px solid var(--clr-secondary-500)',
                                }}
                            >
                                <span 
                                    style={{
                                        color: 'var(--clr-secondary-500)',
                                        fontSize: '1.6rem',
                                        fontWeight: 'var(--fw-semibold)',
                                    }}
                                >
                                    +
                                </span>
                            </button>
                        )
                    }
                </header>
                {
                    openAddCertification && <AddCertification open={openAddCertification} handleClose={handleCloseAddCertification} setFreelancerCertificates={setFreelancerCertificates}/>
                }
                {
                    freelancerCertificates != null ? (
                        freelancerCertificates.map((certification, index) => (
                            <section className='flex flex-col gap-2 mb-3'>
                                <div>
                                    <div className="flex flex-row items-center justify-between">
                                        <div 
                                            style={{
                                                fontFamily: 'var(--ff-body)',
                                                fontWeight: 'var(--fw-semibold)',
                                                fontSize: '1.2rem',
                                                color: 'var(--clr-primary-500)',
                                            }} 
                                        >
                                                {certification.title}
                                        </div>
                                        <div 
                                            style={{
                                                fontFamily: 'var(--ff-body)',
                                                fontWeight: 'var(--fw-medium)',
                                                fontSize: '.95rem',
                                            }} 
                                            >
                                            {certification.date}
                                        </div>
                                    </div>
                                    <div 
                                        style={{
                                            fontFamily: 'var(--ff-body)',
                                            fontWeight: 'var(--fw-medium)',
                                            fontSize: '1rem',
                                        }} 
                                    >
                                        {certification.platform}
                                    </div>
                                </div>
                                <ul className='list-disc list-inside'
                                    style={{
                                        fontFamily: 'var(--ff-body)',
                                        fontWeight: 'var(--fw-regular)',
                                        fontSize: '.9rem',
                                        paddingLeft: '1rem',
                                    }} 
                                >
                                    <li>{certification.description}</li>
                                </ul>
                                {
                                    !activeSectionTwo ? (
                                        <Button 
                                            variant="text"
                                            color='error'
                                            sx={{
                                                width: 'fit-content',
                                                fontWeight: 'var(--fw-semibold)',
                                                fontSize: '.8rem',
                                                alignSelf: 'flex-end',
                                            }}
                                            onClick={() =>
                                                handleDeleteCertification(index)
                                            }
                                        >
                                            Supprimer
                                        </Button>
                                    ) : null
                                }
                            </section>
                        ))
                    ) : (
                        <>
                        </>
                    )
                }
            </div>
        </main>
    );
};

export default EducationAndCertificates;