import React from 'react'

const ContenuEducation = ({educationTitle, dateDebut, dateFin, instituteName, instituteLocation, description}) => {
    return (
        <div className='flex flex-col'>
            <div>
                <div className="flex justify-between items-center">
                    <h3
                        style={{
                            fontFamily: 'var(--ff-heading)',
                            fontSize: 'var(--fs-h5)',
                            fontWeight: 'var(--fw-semibold)',
                            textTransform: 'capitalize',
                            color: 'var(--clr-primary-500)'
                        }}
                    >
                        {educationTitle}
                    </h3>
                    {
                        dateDebut || dateFin || (
                            <p
                                style={{
                                    fontFamily: 'var(--ff-body)',
                                    fontSize: '1rem',
                                    fontWeight: 'var(--fw-medium)',
                                    textTransform: 'capitalize',
                                }}
                            >
                                {dateDebut} - {dateFin}
                            </p>
                        )
                    }
                </div>
                <div className="flex justify-between items-center">
                    <p 
                        style={{
                            fontFamily: 'var(--ff-body)',
                            fontSize: '1rem',
                            fontWeight: 'var(--fw-medium)',
                            textTransform: 'uppercase',
                        }}
                    >
                        {instituteName}
                    </p>
                    {
                        instituteLocation && (
                            <p
                                style={{
                                    fontFamily: 'var(--ff-body)',
                                    fontSize: '1rem',
                                    fontWeight: 'var(--fw-medium)',
                                    textTransform: 'capitalize',
                                }}
                            >
                                {instituteLocation}
                            </p>
                        )
                    }
                </div>
            </div>
            <div className='pl-4'>
                <ul 
                    className="flex flex-col"
                    style={{
                        listStyleType: 'disc',
                        fontFamily: 'var(--ff-body)',
                        fontSize: '1rem',
                        fontWeight: 'var(--fw-regular)',
                    }}
                >
                    <li>{description}</li>
                </ul>
            </div>
        </div>
    )
}

export default ContenuEducation;