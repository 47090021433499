import React, { useEffect, useState } from 'react';

const ShapeComponent = ({ ShapePath }) => {
    const [displayShape, setDisplayShape] = useState(true);
    const [shapeWidth, setShapeWidth] = useState("70%");

    useEffect(() => {
        // Function to update background size based on window width
        const updateBackgroundSize = () => {
            if (window.innerWidth > 1024) {
                setDisplayShape(true);
                setShapeWidth("60%");
            } else if (window.innerWidth > 940) {
                setDisplayShape(true);
                setShapeWidth("58%");
            } else if (window.innerWidth > 767) {
                setDisplayShape(true);
                setShapeWidth("55%");
            } else {
                setDisplayShape(false);
            }
        };
        updateBackgroundSize();
        window.addEventListener('resize', updateBackgroundSize);
        return () => window.removeEventListener('resize', updateBackgroundSize);
    }, []);

    return (
        <div
            className='shape'
            style={{
                backgroundImage: `url(${ShapePath})`,
                backgroundRepeat: "no-repeat",
                display: displayShape ? "block" : "none",
                backgroundPosition: "top right",
                backgroundSize: "contain",
                position: "absolute",
                top: "-20%",
                right: 0,
                width: shapeWidth,
                height: "100%",
                zIndex: -999,
            }}
        >
        </div>
    );
};

export default ShapeComponent;