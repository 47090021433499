import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import { API_GATEWAY_URL } from '../../constants';
import { Menu } from '../../components';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { useNavigate } from 'react-router-dom';

import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css'; // Choose your desired theme
import 'codemirror/mode/javascript/javascript'; // Import the JavaScript mode
import { useAuth } from '../../components/AuthContext';
import ProgressBar from 'react-bootstrap/ProgressBar';
import LoadingSpinner from '../../components/LoadingSpinner';

import Modal from '../../components/freelancerWorkspaceComponents/Modal';
  
const TestPage = () => {

  const { user } = useAuth();

  const location = useLocation();
  const { testData, testTime } = location.state;
  const navigate = useNavigate();

  const [answers, setAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [testStarted, setTestStarted] = useState(false); 
  const [totalTestTime, setTotalTestTime] = useState(testTime); 
  
  const [isTimeUp, setIsTimeUp] = useState(false); 
  const [isAnswerProvided, setIsAnswerProvided] = useState(false);
  const [testSubmitted, setTestSubmitted] = useState(false);

  const [scoreDetails, setScoreDetails] = useState(null); 
  const [viewScore, setViewScore] = useState(false);  
  
  const [loading, setLoading] = useState(false);


  const [isModalOpen, setIsModalOpen] = useState(true); // Start with modal open if test hasn't started

  const [questions, setQuestions] = useState(testData?.questions || []); 

  useEffect(() => {
    if (testData?.questions) {
      setCurrentQuestionIndex(0);
      setQuestions(testData.questions);
      localStorage.setItem('testQuestions', JSON.stringify(testData.questions));
    }
  }, [testData]);

  useEffect(() => {
    const savedQuestions = JSON.parse(localStorage.getItem('testQuestions'));
    const savedQuestionIndex = localStorage.getItem('currentQuestionIndex');
    const savedAnswers = JSON.parse(localStorage.getItem('answers'));

    if (savedQuestions) {
      setQuestions(savedQuestions);
      //setTestStarted(true); 
    }

    if (savedQuestionIndex) {
      setCurrentQuestionIndex(Number(savedQuestionIndex)); 
      if (currentQuestionIndex>0) {setTestStarted(true); }

    }

    if (savedAnswers) {
      setAnswers(savedAnswers); 
    }

  }, []);

  useEffect(() => {
     localStorage.setItem('currentQuestionIndex', currentQuestionIndex);
  }, [currentQuestionIndex]);

  useEffect(() => {
    if (answers) {
      localStorage.setItem('answers', JSON.stringify(answers));
    }
  }, [answers]);

  const handleStartTest = () => {
    setCurrentQuestionIndex(0);

    setTestStarted(true);
    setIsModalOpen(false); // Close the modal when the test starts
  };
  const handleCloseModal = () => {
    setIsModalOpen(false); // Fermer le modal
    navigate('/teststechniques'); // Rediriger vers la page des tests techniques
  };


  useEffect(() => {
    
    let globalTimer;

    if (testStarted && totalTestTime > 0) {
      globalTimer = setInterval(() => {
        setTotalTestTime((prevTime) => {
          if (prevTime <= 1) {
            setIsTimeUp(true);
            clearInterval(globalTimer);
            return 0; 
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => clearInterval(globalTimer); // Clear interval on unmount or when dependencies change
  }, [testStarted, totalTestTime]);


  // Function to format time in minutes and seconds
  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes} minutes and ${seconds} seconds`;
  };


  useEffect(() => {
    // Check if there are any answers for the current question when navigating
    const currentAnswers = answers[currentQuestionIndex] || [];
  
    // Set isAnswerProvided based on whether there are answers for the current question
    setIsAnswerProvided(
      Array.isArray(currentAnswers) ? currentAnswers.length > 0 : !!currentAnswers
    );
  }, [currentQuestionIndex, answers]);
  
  const handleAnswerChange = (questionIndex, answer, questionType) => {
    if (!isTimeUp) {
      setAnswers((prevAnswers) => {
        let updatedAnswers;
  
        // Pour les questions à choix multiple (QCM - questionType === 1)
        if (questionType === 1) {
          const currentAnswers = prevAnswers[questionIndex] || [];
  
          if (currentAnswers.includes(answer)) {
            // Supprime la réponse si elle était déjà sélectionnée (décocher)
            updatedAnswers = currentAnswers.filter((a) => a !== answer);
          } else {
            // Ajoute la nouvelle réponse (cocher)
            updatedAnswers = [...currentAnswers, answer];
          }
  
          // Vérifie si une réponse a été fournie
          const isAnyAnswerProvided = updatedAnswers.length > 0;
          setIsAnswerProvided(isAnyAnswerProvided);
  
          updatedAnswers = {
            ...prevAnswers,
            [questionIndex]: updatedAnswers,
          };
  
        } else {
          // Pour les questions ouvertes ou de code (réponses textuelles)
          setIsAnswerProvided(!!answer); // Marque vrai s'il y a une entrée
          updatedAnswers = {
            ...prevAnswers,
            [questionIndex]: answer, // Enregistre la réponse sous forme de chaîne de caractères
          };
        }
  
        
        // saveToLocalStorage(currentQuestionIndex, updatedAnswers, questions);

        return updatedAnswers;
      });
    }
  };
  
  
  const handleNext = () => {
    if (isAnswerProvided) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1); // Move to the next question
     // setCurrentQuestionTime(0); // Reset time for the next question
      setIsTimeUp(false); // Reset time up flag
      setIsAnswerProvided(false); // Reset answer provided flag
    } else {
      alert('Please provide an answer before proceeding to the next question.');
    }
  };

  const handlePrevious = () => {
    // Move to the previous question if it's not the first question
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1); // Move to the previous question
     // setCurrentQuestionTime(0); // Reset time for the previous question
      setIsTimeUp(false); // Reset the "time up" flag
      setIsAnswerProvided(true); // Keep the answer provided state as true for the previous question
    }
  };
  const handleSubmit = () => {

    setLoading(true);

    console.log("Starting test submission...");
  
    // Log the entire testData for debugging
    console.log("Test data:", testData);
  
    // Prepare the data to send to the backend
    const userTestId = testData.test_id; // Assuming this ID is available in the test data
    console.log("User test ID:", userTestId);
  
    const userAnswers = Object.entries(answers).map(([questionIndex, userAnswer]) => {
      const question =  questions[questionIndex];
  
      // Log the question and user answer for each question
      console.log("Processing question:", question);
      console.log("User answer:", userAnswer);
  
      // Ensure the question ID and user answer are valid
      if (!question.question_archived_id || !userAnswer) {
        console.error("Invalid question ID or user answer", { question, userAnswer });
        alert("Invalid question data. Please try again.");
        return;
      }
  
      // Determine the question type
      if (question.question_type === 1) { // Assuming 1 is for QCM
        // Retrieve answer IDs from the selected options
        const selectedAnswerIds = question.possible_answers
          .filter((answer, index) => userAnswer.includes(answer.answer_id)) // Assuming userAnswer contains selected answer IDs
          .map(answer => answer.answer_id); // Get the answer_id for selected answers
  
        console.log("Selected answer IDs for QCM:", selectedAnswerIds); // Debugging log
  
        return {
          question_id: question.question_archived_id,  // Use the question ID from test data
          user_answer: selectedAnswerIds, // Use the selected answer IDs
        };
      } else {
        // For coding/open-ended questions, return the text answer
        return {
          question_id: question.question_archived_id,
          user_answer: userAnswer,  // Directly use the answer text
        };
      }
    });
  
    // Log the entire userAnswers array
    console.log("User answers:", userAnswers);
  
    // Ensure all answers are valid before proceeding
    if (userAnswers.some(answer => !answer)) {
      console.error("Invalid answers detected", userAnswers);
      alert("Some answers are invalid. Please review and try again.");
      return;
    }
  
    // Create the payload and log it
    const payload = {
      user_test: userTestId,
      user_answers: userAnswers,
    };
  
    console.log("Payload to send:", payload);
  
    // Make an API request to send the data to the backend
    fetch(`${API_GATEWAY_URL}/evaluate-test/`, { // Adjust the URL to your backend endpoint
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.access_token}`
      },
      body: JSON.stringify(payload),
    })
      .then(response => {
        console.log("Received response:", response);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(data => {
        console.log("Evaluation result data:", data);
  
        // Extract score and message from the response and update state
        setScoreDetails({
          score: data.score,
          message: data.message,
          successEvaluation: data.success_evaluation,
        });
        setTestSubmitted(true);
      })
      .catch(error => {
        console.error('Error submitting test:', error);
        alert('An error occurred while submitting your test. Please try again.');
      });
      setLoading(false);
      localStorage.removeItem('testState'); 
      localStorage.removeItem('testQuestions'); 
      localStorage.removeItem('answers'); 
      localStorage.removeItem('currentQuestionIndex'); 

  };

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  };
  
  
  

  if (!testData) {
    return <p>No test data available. Please try again.</p>; // Handle missing testData
  }

  // If the test hasn't started yet, show the start screen
  if (!testStarted) {
    return (
      
       <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
       <h2 className="text-xl font-bold mb-4">Durée totale du test</h2>
       <p className="mb-2">Vous répondrez aux questions une par une.</p>
       <p className="mb-2">Temps total pour le test: {formatTime(testTime)}</p>

       <button
         onClick={handleStartTest}
         className="mt-4 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition-colors"
       >
         Commencer le test
       </button>
     </Modal>
    );
  }
  // If the test has been submitted, show the "Test Finished" message
  if (testSubmitted) {
    return (
      <div className="p-4 text-center">
        <h2 className="text-xl font-bold mb-4">Test Terminé</h2>
        <p>Merci d'avoir complété le test !</p>
        <p>Vos réponses ont été soumises avec succès.</p>
        
        <button
          onClick={() => setViewScore(prev => !prev)}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
        >
          {viewScore ? "Masquer les Détails du Score" : "Voir le Score"}
        </button>
  
        {viewScore && (
          <div className="mt-4">
            <h3 className="text-lg font-bold">Détails du Score</h3>
            <p>Score : {scoreDetails.score}</p>
            <p>{scoreDetails.message}</p>
            <p>
              Statut : {scoreDetails.successEvaluation
                ? "Félicitations ! Vous avez réussi le test."
                : "Malheureusement, vous n'avez pas atteint le score requis."}
            </p>
          </div>
        )}
      </div>
    );
  }

  // Current question
  const currentQuestion = testData.questions[currentQuestionIndex];
  const totalQuestions = testData.questions.length;

  const progress = Math.round(((currentQuestionIndex + 1) / totalQuestions) * 100);
  return (
    <div className="p-6 space-y-6 max-w-4xl mx-auto">
      {/* Menu (Navbar) */}
      <Menu user={user} />

      {/* Test Content */}
    <div className="p-6 space-y-6 max-w-4xl mx-auto">
            {/* Timer Section */}
      <div className="flex justify-between mb-6 space-x-4">

      {/* Test Progress */}
      <div className="text-lg font-semibold text-gray-800">
        <h2 className="text-2xl font-bold">Progression du Test</h2>
        <p>Temps total pour le test : {formatTime(testTime)}</p>
        {/* <p>Temps restant : {formatTime(totalTestTime)}</p> */}
      </div>

      {/* Countdown Timer */}
      <div className="flex justify-center items-center">
        <CountdownCircleTimer
          isPlaying
          duration={testTime}
          colors={['#1E3A8A', '#F59E0B', '#EF4444', '#B91C1C']}
          colorsTime={[60, 30, 10, 0]}
          onComplete={() => setIsTimeUp(true)}
          size={120}
          strokeWidth={8}
        >
          {({ remainingTime }) => {
            const minutes = Math.floor(remainingTime / 60);
            const seconds = remainingTime % 60;
            return <span className="text-2xl font-bold">{`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}</span>;
          }}
        </CountdownCircleTimer>
      </div>
      </div>

      <div className="text-center mb-6 space-y-4"></div>
       <ProgressBar className="sticky top-0" now={progress} label={`${progress}%`} />

      {/* Question Section */}
      <div className={`bg-white border rounded-lg shadow-lg p-6 space-y-4 ${isTimeUp ? 'bg-red-100' : ''}`}>
        <h4 className="text-xl font-semibold text-gray-800">{currentQuestion.question_text}</h4>

        {/* Multiple-Choice (QCM) */}
        {currentQuestion.question_type === 1 && (
          <ul className="space-y-2">
            {currentQuestion.possible_answers.map((answer, idx) => (
              <li key={idx}>
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name={`question-${currentQuestionIndex}`}
                    value={answer.answer_text}
                    onChange={() => handleAnswerChange(currentQuestionIndex, answer.answer_id, currentQuestion.question_type)}
                    disabled={isTimeUp}
                    checked={Array.isArray(answers[currentQuestionIndex]) ? answers[currentQuestionIndex].includes(answer.answer_id) : false}
                    className={`h-5 w-5 rounded-full text-green-600 border-gray-300 ${isTimeUp ? 'opacity-50 cursor-not-allowed' : ''}`}
                  />
                  <span className="text-lg text-gray-700">{answer.answer_text}</span>
                </label>
              </li>
            ))}
          </ul>
        )}

        {/* Open-Ended Questions */}
        {currentQuestion.question_type === 3 && (
          <textarea
            className="border p-3 w-full rounded-lg focus:ring focus:ring-blue-300"
            rows="5"
            placeholder="Type your answer here"
            onChange={(e) => handleAnswerChange(currentQuestionIndex, e.target.value)}
            disabled={isTimeUp}
            value={answers[currentQuestionIndex] || ''}
          />
        )}

        {/* Coding Questions */}
        {currentQuestion.question_type === 2 && (
          <CodeMirror
            value={answers[currentQuestionIndex] || ''}
            options={{
              mode: 'javascript',
              theme: 'material',
              lineNumbers: true,
            }}
            onBeforeChange={(editor, data, value) => handleAnswerChange(currentQuestionIndex, value, 2)}
            className="border rounded-lg overflow-hidden"
            disabled={isTimeUp}
          />
        )}

        {/* Time Allowed for Each Question */}
        {currentQuestion.time_allowed && (
          <p className="text-gray-500 mt-4">Time allowed: {currentQuestion.time_allowed} seconds</p>
        )}
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-between mt-8 space-x-4">
        {currentQuestionIndex > 0 && (
          <button
            onClick={handlePrevious}
            className="px-6 py-2 rounded-lg bg-gray-600 text-white hover:bg-gray-700 transition"
          >
            Previous
          </button>
        )}
        {currentQuestionIndex < questions.length - 1 && (
          <button
            onClick={handleNext}
            className={`px-6 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition ${!isAnswerProvided ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={!isAnswerProvided}
          >
            Next
          </button>
        )}
        {currentQuestionIndex === questions.length - 1 && (
          <button
            onClick={handleSubmit}
            className="px-6 py-2 rounded-lg bg-green-600 text-white hover:bg-green-700 transition"
          >
            Submit Test
          </button>
        )}
      </div>
    </div>
  </div>
  );
};

export default TestPage;