import React from 'react'
import { useState } from 'react';
import Job from './Job';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const WorkExperienceSection = ({contenusJobs, contenusProjects}) => {
    const [isVisible, setIsVisible] = useState(false);
    const handleToggle = () => {
        setIsVisible(!isVisible);
    };

    return (
        <div className="flex flex-col gap-8">
            <div className="flex flex-row justify-between items-center">
                <h1 
                    className="mb-2"
                    style={{fontFamily: 'var(--ff-heading)', fontSize: 'var(--fs-h4)', fontWeight: 'var(--fw-bold)', textTransform: 'capitalize'}}
                >
                    <span style={{color: 'var(--clr-secondary-400)'}}>Experience </span>
                    Professionnelle
                </h1>
                <IconButton aria-label="arrow" onClick={handleToggle} sx={{
                    color: 'var(--clr-white)',
                    backgroundColor: 'var(--clr-secondary-300)',
                    borderRadius: '4px',
                    '&:hover': {
                        backgroundColor: 'var(--clr-secondary-400)',
                    },
                    '&:active': {
                        border: '1px solid var(--clr-secondary-500)',
                    }
                }}>
                    {isVisible ? <ArrowDownwardIcon /> : <ArrowForwardIcon />}
                </IconButton>
            </div>
            {isVisible && (
                <div className="flex flex-col gap-2">
                    {
                        contenusJobs.map((contenu, index) => (
                            <Job 
                                key={index}
                                jobTitle={contenu.jobTitle}
                                dateDebut={contenu.dateDebut}
                                dateFin={contenu.dateFin}
                                company={contenu.company}
                                responsibilities={contenu.responsibilities}
                                description={contenu.description}
                            />
                        ))
                    }
                    {
                        contenusProjects.map((contenu, index) => (
                            <Job 
                                key={index}
                                jobTitle={contenu.projectName}
                                dateDebut={contenu.dateDebut}
                                dateFin={contenu.dateFin}
                                responsibilities={contenu.projectResponsibilities}
                                description={contenu.description}
                            />
                        ))
                    }
                </div>
            )}
        </div>
    );
}
export default WorkExperienceSection