import React from "react";
import { Link } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const NavBarItem = ({ title, onClick }) => {
    return (
        <Link 
            onClick={onClick}
            underline="none"
            position={"relative"}
            sx={{
                fontFamily: 'var(--ff-heading)',
                fontSize: 'var(--fs-h6)',
                fontWeight: 'var(--fw-medium)',
                cursor: 'pointer',
                color: 'var(--clr-neutral-900)',
                ":hover": {
                    color: 'var(--clr-primary-500)'
                },
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '-70%',
                    left: 0,
                    width: '100%',
                    height: '2.5px',
                    backgroundColor: 'transparent',
                    transition: 'background-color 0.4s ease, transform 0.3s ease',
                    transform: 'scaleX(0)',
                },
                '&:hover::after': {
                    backgroundColor: 'currentColor',
                    transform: 'scaleX(1)',
                },
            }}
        >
            {title}
        </Link>
    )
}

const NavBar = ({items}) => {

    const navigate = useNavigate();
    const goToProfile = () => {
        navigate('/profile');   
    }

    const goToMissions = () => {
        navigate('/missions');   
    }

    const goToChat = () => {
        navigate('/chat');   
    }


    const goToTests = () => {
        navigate('/teststechniques');   
    };
    

    const goToTransactions = () => {
        navigate('/transactions-freelancer');
    }

    const getNavFunction = (index) => {
        if (index === 0) return goToProfile;
        if (index === 1) return goToMissions;
        if (index === 2) return goToChat;

        if (index === 3) return goToTests; // New function to go to 'Tests Techniques'
        if (index === 4) return goToTransactions;
		
    };

    return (
        <div className="hidden md:block">
            <ul className="flex flex-col gap-4 pl-0 my-2 md:mb-0 md:mt-0 md:flex-row md:items-start">
                {
                    items.map((item, index) => (
                        <li key={index} className="block h-full ">
                            <NavBarItem
                                title={item}
                                onClick={ getNavFunction(index) }
                            />
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default NavBar;