import React, { useState } from 'react';
import ClientFeedback from './ClientFeedback';
import ShowMoreReviewsBtn from './ShowMoreReviewsBtn';
import Rating from '@mui/material/Rating';
import '../../index.css';
import ProgressBar from './ProgressBar';

const ReviewsSection = ({averageRating, numberOfReviews, statsOfReviewsFiveStars, statsOfReviewsFourStars, statsOfReviewsThreeStars, statsOfReviewsTwoStars, statsOfReviewsOneStar, clientFeedbacksInfos}) => {
    const [visibleCount, setVisibleCount] = useState(3);
    const [showAll, setShowAll] = useState(false);

    const toggleElements = () => {
        if (showAll) {
            setVisibleCount(3);
            setShowAll(false);
        } else {
            if (visibleCount + 3 >= items.length) {
                setVisibleCount(items.length);
                setShowAll(true);
            } else {
                setVisibleCount(visibleCount + 3);
            }
        }
    };

    const items = Array.from({ length: clientFeedbacksInfos.length }, (_, i) => ({
        clientName: clientFeedbacksInfos[i]["clientName"],
        avatarIconPath: clientFeedbacksInfos[i]["avatarIconPath"],
        rating: Number(clientFeedbacksInfos[i]["rating"]),
        content: clientFeedbacksInfos[i]["content"],
    }));

    return (
        <div className="flex flex-col gap-8">
            <h1 
                    className="mb-2"
                    style={{fontFamily: 'var(--ff-heading)', fontSize: 'var(--fs-h4)', fontWeight: 'var(--fw-bold)', textTransform: 'capitalize'}}
                >
                    <span style={{color: 'var(--clr-secondary-400)'}}>Feedback </span>
                    Client
            </h1>

            <div className='firstPart flex flex-col sm:w-full md:w-4/6 lg:w-3/5'
                style={{
                    fontFamily: 'var(--ff-body)',
                    fontSize: 'var(--fs-p-small)',
                }}
            >
                <div className="total-stats flex flex-row justify-between">
                    <div className="average-rating">
                        <p>
                            <span
                                style={{
                                    fontWeight: 'var(--fw-regular)',
                                    marginRight: '3px',
                                }}
                            >
                                {numberOfReviews}
                            </span>
                            avis pour ce freelancer
                        </p>
                    </div>
                    <div className="total-reviews flex gap-1">
                        <Rating name="read-only" value={Math.floor(Number(averageRating))} readOnly />
                        <p
                            style={{
                                fontWeight: 'var(--fw-regular)',
                            }}
                        >
                            {averageRating}
                        </p>
                    </div>
                </div>
                <div className="stats flex flex-col justify-start items-stretch ml-3">
                    <div className="rating-stats w-full flex items-center justify-start gap-4">
                        <p className='my-auto' style={{fontWeight: 'var(--fw-medium)',}}>5 étoiles</p>
                        <ProgressBar value={ Number(statsOfReviewsFiveStars) * 100 / Number(numberOfReviews)} className='my-auto' /> 
                        <p className='my-auto'>( {statsOfReviewsFiveStars} )</p>
                    </div>
                    <div className="rating-stats w-full flex items-center justify-start gap-4">
                        <p className='my-auto' style={{fontWeight: 'var(--fw-medium)',}}>4 étoiles</p>
                        <ProgressBar value={Number(statsOfReviewsFourStars) * 100 / Number(numberOfReviews)} className='my-auto' /> 
                        <p className='my-auto'>( {statsOfReviewsFourStars} )</p>
                    </div>
                    <div className="rating-stats w-full flex items-center justify-start gap-4">
                        <p className='my-auto' style={{fontWeight: 'var(--fw-medium)',}}>3 étoiles</p>
                        <ProgressBar value={Number(statsOfReviewsThreeStars) * 100 / Number(numberOfReviews)} className='my-auto' /> 
                        <p className='my-auto'>( {statsOfReviewsThreeStars} )</p>
                    </div>
                    <div className="rating-stats w-full flex items-center justify-start gap-4">
                        <p className='my-auto' style={{fontWeight: 'var(--fw-medium)',}}>2 étoiles</p>
                        <ProgressBar value={Number(statsOfReviewsTwoStars) * 100 / Number(numberOfReviews)} className='my-auto' /> 
                        <p className='my-auto'>( {statsOfReviewsTwoStars} )</p>
                    </div>
                    <div className="rating-stats w-full flex items-center justify-start gap-4">
                        <p className='my-auto' style={{marginRight: "10px", fontWeight: 'var(--fw-medium)',}}>1 étoile</p>
                        <ProgressBar value={Number(statsOfReviewsOneStar) * 100 / Number(numberOfReviews)} className='my-auto' /> 
                        <p className='my-auto'>( {statsOfReviewsOneStar} )</p>
                    </div>
                </div>
            </div>

            <div className='flex flex-col gap-4'>
            {
                items.slice(0, visibleCount).map((item, index) => (
                    <ClientFeedback 
                        clientName={item.clientName}
                        avatarIconPath={item.avatarIconPath}
                        rating={item.rating}
                        content={item.content} 
                        key={index}
                    />
                ))
            }
            {
                items.length <= 3 ? null : (
                    <ShowMoreReviewsBtn 
                        onClick={toggleElements} 
                        CTAMessage={showAll ? 'Hide Reviews' : 'Show More Reviews'}
                    />
                )
            }
            </div>
        </div>
    );
};

export default ReviewsSection;