import React, { useState, useEffect } from "react";
import { useAuth } from "../AuthContext";
import { API_GATEWAY_URL } from "../../constants";
import { DataGrid } from '@mui/x-data-grid';
import { Tabs, Tab } from '@mui/material';
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";
import AdminCard from "../admin/AdminCard";

const AdminExcel = () => {
    const { user } = useAuth();
    const [retraits, setRetraits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentTab, setCurrentTab] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]); // Changed from selected to selectedRows for clarity

    const fetchBeneficiaries = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_GATEWAY_URL}/benificiaires/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setRetraits(data);
        } catch (error) {
            console.error('Error fetching beneficiaries:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (user) {
            fetchBeneficiaries();
        }
    }, [user]);

    const handleClick = () => {
        if (selectedRows.length === 0) {
            Swal.fire({
                icon: "warning",
                title: "Aucune sélection",
                text: "Veuillez sélectionner au moins un bénéficiaire",
            });
            return;
        }
    
        fetch(`${API_GATEWAY_URL}/excelbeneficiaries/`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${user.access_token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                selected_beneficiaries: selectedRows,
            })
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                const binaryString = atob(data.file_content);
                const bytes = new Uint8Array(binaryString.length);
                for (let i = 0; i < binaryString.length; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }
    
                const blob = new Blob([bytes], { type: data.mime_type });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = data.filename;
                link.click();
    
                // Update the state to mark the selected rows as treated
                setRetraits((prevRetraits) => 
                    prevRetraits.map((retrait) => 
                        selectedRows.includes(retrait.id) 
                        ? { ...retrait, etat: true } // Mark as treated
                        : retrait
                    )
                );
    
                // Clear selected rows after treatment
                setSelectedRows([]);
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    text: "Erreur lors de la génération du fichier Excel",
                });
            });
    };
    

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
        setSelectedRows([]); // Clear selections when changing tabs
    };

    const treatedRetraits = retraits.filter((retrait) => retrait.etat === true);
    const untreatedRetraits = retraits.filter((retrait) => retrait.etat === false);
    
    const columns = [
        { field: 'RIB', headerName: 'RIB', flex: 1 },
        { field: 'Devise', headerName: 'Devise', flex: 1 },
        { field: 'nom', headerName: 'Nom/Raison Sociale', flex: 1 },
        { field: 'Adresse', headerName: 'Adresse', flex: 1 },
        { field: 'label', headerName: 'Libellé', flex: 1 },
        { field: 'phone', headerName: 'Téléphone', flex: 1 },
        { field: 'email', headerName: 'E-mail', flex: 1 }
    ];

    return (
        <div className="pw-page-layout">
            <AdminCard />
            <div className='w-full space-y-10 p-2 sm:p-8 justify-start'>
                <div className="text-[#1e1e1e] text-2xl font-bold font-Poppins">Mes Transactions</div>
                <div className="px-6 py-1.5 bg-[#0056b3] rounded-[36px] justify-center items-center gap-6 inline-flex text-white text-base font-normal font-Poppins cursor-pointer" onClick={handleClick}>
                    Télécharger Fichier Excel ({selectedRows.length} sélectionnés)
                </div>
                
                <Tabs value={currentTab} onChange={handleTabChange}>
                    <Tab label="Retraits non traités" />
                    <Tab label="Retraits traités" />
                </Tabs>

                <div className="h-full p-1 mx-auto border border-[#a0a0a0] rounded-lg">
                    {loading ? (
                        <LoadingSpinner isLoading={loading} />
                    ) : (
                        <DataGrid
                            rows={currentTab === 1 ? treatedRetraits : untreatedRetraits}
                            columns={columns}
                            pageSize={10}
                            autoHeight
                            getRowId={(row) => row.id}
                            checkboxSelection={currentTab === 0}
                            onRowSelectionModelChange={(newSelection) => {
                                console.log('Selected IDs:', newSelection);
                                setSelectedRows(newSelection);
                            }}
                            selectionModel={selectedRows}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminExcel;