import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
// import { setFormData, nextStep, prevStep, resetForm } from '../clientInscriptionFormSlice';
import "../../../styles/MultiStepForm.css";

import "../../../styles/navbarAndFooter.css";
import inscriptionClientImage from "../../../assets/inscriptionClientImage.jpg";
import MultiStepForm from "./MultiStepForm";
import Layout from "../../Layout";

const InscriptionClientBodyComponent = () => {
  return (
    <div className="loginbackgroundImage">
      <div className="container-fluid m-10">
        <MultiStepForm />
      </div>
    </div>
  );
};

export default InscriptionClientBodyComponent;
/*         <Layout title='TeraTalenT | Inscription client' content='Inscription client'>
        <div className="container-fluid">
            <div className="row center-on-small">
             Colonne du formulaire 
                <div className="col-sm-12 col-md-10 g-5 form-col">
                    
                         
                        
                        <MultiStepForm />

                    
                </div>

                Colonne de l'image 
                <div className="col-sm-12 col-md-2 p-0 image-container hide-on-medium">
                    <img src={inscriptionClientImage} alt="Background Image" />
                </div>
            </div>
        </div>
        </Layout> */
