import React, { useState, useEffect } from 'react';
import { useAuth } from '../../components/AuthContext';
import { API_GATEWAY_URL } from '../../constants';
import LoadingSpinner from '../../components/LoadingSpinner'; 
import {
    Menu,
    MissionInfos,
    MissionsDashboard
} from '../../components';

let globalIncrement = 1;

const Missions = () => {
    const { user } = useAuth();
    const [freelancerDashboard, setFreelancerDashboard] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFreelancerDashboard = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/freelancerDashboard/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setFreelancerDashboard(data);
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerDashboard();
        }
    }, [user]);

    if (loading) {
        return <LoadingSpinner isLoading={true} />;
    }

    if (error) {
        return <div>Erreur: {error}</div>;
    }

    // Data transforming function
    function dataTransforming(data, status) {
        return data.map((mission) => ({
            id: globalIncrement++,
            mission: mission.posteTitle,
            client: mission.client_name,
            status: status,
            startDate: mission.date_debut,
            endDate: mission.date_fin,
            description: mission.description,
            type: status,
        }));
    }
    // Data transforming function for waiting missions
    function dataTransformingWaitingMissions(data, status) {
        console.log("Waiting missions", data);
        return data.map((mission) => ({
            id: globalIncrement++,
            mission: mission.posteTitle,
            client: mission.client_name,
            status: status,
            startDate: mission.date_debut,
            endDate: mission.date_fin,
            description: mission.description,
            type: status,
        }));
    }

    const transformedCurrentMissions = freelancerDashboard.current_missions?.nbre !== 0 ? dataTransforming(freelancerDashboard.current_missions.missions, 'En cours') : [];
    const transformedCompletedMissions = freelancerDashboard.completed_missions?.nbre !== 0 ? dataTransforming(freelancerDashboard.completed_missions.missions, 'Terminé') : [];
    const transformedNewMissions = freelancerDashboard.missions_en_attente?.nbre !== 0 ? dataTransformingWaitingMissions(freelancerDashboard.missions_en_attente.propositions, 'En attente') : [];

    const data = [...transformedCurrentMissions, ...transformedCompletedMissions, ...transformedNewMissions];

    return (
        <div className='container flex flex-col gap-8 py-3'>
            <Menu user={user} />
            <MissionInfos 
                finishedMissions={freelancerDashboard.completed_missions?.nbre}
                currentMissions={freelancerDashboard.current_missions?.nbre}
                newMissions={freelancerDashboard.missions_en_attente?.nbre}
            />
            <MissionsDashboard 
                data={data}
            />
        </div>
    );
}

export default Missions;