import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { login as reduxLogin } from '../../redux/slices/authentificationSlice';
import Layout from '../Layout';
import { useAuth } from '../AuthContext';
import axios from 'axios';

const LinkedinLoginCallback = ({ apiGatewayUrl }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { login: contextLogin } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const requestInProgress = localStorage.getItem('linkedinRequestInProgress');

    if (code && !requestInProgress) {
      localStorage.setItem('linkedinRequestInProgress', 'true'); // Set local storage flag
      axios.get(`${apiGatewayUrl}/linkedin-login-callback/?code=${code}`)
        .then(response => {
          if (response.status === 200 && response.data) {
            const data = response.data;
            contextLogin({ user: data, userType: data.user_type, email_validation: data.email_validation });
            dispatch(reduxLogin({ user: data, userType: data.user_type, email_validation: data.email_validation }));
            navigateBasedOnUserType(data.user_type, data);
          } else {
            setErrorMessage('Échec de la connexion: ' + response.data.message);
           
          }
        })
        .catch(error => {
          setErrorMessage('Erreur : ' + (error.response ? error.response.data.message : error.message));
        })
        .finally(() => {
          localStorage.removeItem('linkedinRequestInProgress'); // Clear the flag on completion
        });
    } else if (!code) {
      navigate('/logIn', { state: { error: 'Code manquant dans les paramètres de requête.' } });
    }
  }, [location.search, apiGatewayUrl, contextLogin, dispatch, navigate]);

  const navigateBasedOnUserType = (userType, data) => {
    switch (userType) {
      case 'provider':
       if (data.email_validation === 'OK') {
          navigate('/provider-welcome');  
        } else if (data.email_validation === 'KO') {
          navigate('/provider-validation-email', { state: { email: data.user_email } });
        }
        break;
        break;
      case 'worker':
       if (data.email_validation === 'OK') {
          navigate('/worker-welcome');  
        } else if (data.email_validation === 'KO') {
          navigate('/worker-validation-email', { state: { email: data.user_email } });
        }
        break;
      case 'client':
        console.log('Email validation status:', data.email_validation);
        if (data.email_validation === 'OK') {
          navigate('/client-Dashboard');
        } else if (data.email_validation === 'KO') {
          navigate('/validation-email', { state: { email: data.user_email } });
        }
        break;
      case 'freelancer':
        console.log('Email validation status:', data.email_validation);
        if (data.email_validation === 'OK') {
          // navigate('/freelancer-Dashboard');
          navigate('/profile');
        } else if (data.email_validation === 'KO') {
          navigate('/freelancer-validation-email', { state: { email: data.user_email } });
        }
        break;
      default:
        console.log('Unhandled user type:', userType);
        navigate('/');
        break;
    }
  };

  return (
    <Layout>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        {!localStorage.getItem('linkedinRequestInProgress') && (
          <div className="error-container" style={{ textAlign: 'center' }}>
            {errorMessage && <p>{errorMessage}</p>}
            <Spinner animation="border" role="status">
              <span className="sr-only">Chargement...</span>
            </Spinner>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default LinkedinLoginCallback;
