import React, {useState} from 'react';
import { Button } from '@mui/material';
import '../../index.css';



const ShowMoreReviewsBtn = ({ CTAMessage, onClick}) => {
    return (
        <Button
            onClick={onClick}
            className="
                inline-flex
                items-center
                text-white
                focus:outline-none
                focus:ring-2"
            sx={{
                backgroundColor: 'var(--clr-secondary-400)',
                borderRadius: 'var(--br-button)',
                '&:hover': {
                    backgroundColor: 'var(--clr-secondary-500)',
                },
                width: 'fit-content',
                padding: '.4rem .8rem',
            }}
        >
            <span
                style={{
                    color: 'var(--clr-white)',
                    fontFamily: 'var(--ff-button)',
                    fontWeight: 'var(--fw-medium)',
                    fontSize: 'var(--fs-btn-extra-small)',
                    textTransform: "capitalize"
                }}
            >
                {CTAMessage}
            </span>
        </Button>
    );
};

export default ShowMoreReviewsBtn;
