import React from "react";
import icon1 from "../../assets/icon1.svg";
import icon2 from "../../assets/icon2.svg";
import icon3 from "../../assets/icon3.svg";
import icon4 from "../../assets/icon4.svg";
import icon5 from "../../assets/icon5.svg";
import "../../index.css";
const StatsCards = () => {
  return (
    <div className="w-full max-2xl:text-fluid-ms 2xl:text-fluid-sm font-body">
      <div className="flex flex-wrap gap-4 p-4">
        <div className="MediumShadowContainer flex flex-row max-w-sm">
          <img
            src={icon1}
            alt="Icon 1"
            className="mr-4 max-2xl:h-16 2xl:h-28"
          />
          <div>
            <p>
              <strong>Gains financiers</strong>
            </p>
            <h className="font-bold">€15,2020</h>
          </div>
        </div>
        <div className="MediumShadowContainer flex flex-row max-w-sm">
          <img
            src={icon2}
            alt="Icon 2"
            className="mr-4 max-2xl:h-16 2xl:h-28"
          />
          <div>
            <p>
              <strong>Ressources engagées</strong>
            </p>
            <h className=" font-bold">112</h>
          </div>
        </div>
        <div className="MediumShadowContainer flex flex-row max-w-sm">
          <img
            src={icon3}
            alt="Icon 3"
            className="mr-4 max-2xl:h-16 2xl:h-28"
          />
          <div>
            <p>
              <strong>Missions en cours</strong>
            </p>
            <h className=" font-bold">232</h>
          </div>
        </div>
        <div className="MediumShadowContainer flex flex-row max-w-sm">
          <img
            src={icon4}
            alt="Icon 4"
            className="mr-4 max-2xl:h-16 2xl:h-28"
          />
          <div>
            <p>
              <strong>Total d'invitation clients</strong>
            </p>
            <h className=" font-bold">232</h>
          </div>
        </div>
        <div className="MediumShadowContainer max-w-sm flex items-center ">
          <img
            src={icon5}
            alt="Icon 5"
            className="mr-4 max-2xl:h-16 2xl:h-28"
          />
          <div>
            <p>
              <strong>Taux d’occupation des ressources</strong>
            </p>
            <h className=" font-bold">5.6%</h>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsCards;
