import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

import { Card, Container, Row, Col, ProgressBar } from 'react-bootstrap';
import LoadingSpinner from './LoadingSpinner';
import { API_GATEWAY_URL } from '../constants';
import ProviderCard from './ProviderCard';

const ProviderDemandesEnCoursPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [providerData, setProviderData] = useState(null);
  const [demandes, setDemandes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProviderData = async () => {
      setLoading(true);
      try {
        const profileResponse = await fetch(`${API_GATEWAY_URL}/provider-profile/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.access_token}`
          },
        });

        if (!profileResponse.ok) throw new Error('Erreur lors de la récupération des données du profil.');

        const profileData = await profileResponse.json();
        setProviderData(profileData);

        const demandesResponse = await fetch(`${API_GATEWAY_URL}/demandes_by_statut_and_ressource/?statut=C&type=provider&user=${user.user_email}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.access_token}`
          },
        });

        if (!demandesResponse.ok) throw new Error('Erreur lors de la récupération des demandes.');

        const demandesData = await demandesResponse.json();
        setDemandes(demandesData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (user && user.access_token) {
      fetchProviderData();
    }
  }, [user]);

  const calculateProgress = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const today = new Date();
    if (today < start) return 0;
    if (today > end) return 100;
    const total = end - start;
    const current = today - start;
    return Math.round((current / total) * 100);
  };

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  const renderDemandes = (demandes) => {
    return demandes.map(demande => (
      <Card key={demande.id} style={{ position: 'relative' }}>
        <Card.Body>
          <Card.Title>Poste demandé : {demande.posteTitle}</Card.Title>
          <Card.Text>
            <strong>Type de Projet:</strong> {demande.project_type_readable}<br/>
            <strong>Niveau d'Expertise:</strong> {demande.expertise_level_readable}<br/>
            <strong>Statut:</strong> {demande.statut_readable}<br/>
            <strong>Date de Début:</strong> {demande.date_debut}<br/>
            <strong>Date de Fin:</strong> {demande.date_fin}
          </Card.Text>
          <ProgressBar now={calculateProgress(demande.date_debut, demande.date_fin)} />
        </Card.Body>
      </Card>
    ));
  };

  return (
    <div className="CDP-page-layout">
      <div>
        {providerData && <ProviderCard providerData={providerData} />}
        <div className="CDPmission-section">
          <Container fluid>
            <h2>Demandes en Cours</h2>
            <Row>
              <Col className="CDPdemandes-column">
                <div className="CDPdemandes-cards-container">
                  {renderDemandes(demandes)}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ProviderDemandesEnCoursPage;
