import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { API_GATEWAY_URL } from '../constants';

function VerifyPage() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [isValidToken, setIsValidToken] = useState(null);

  useEffect(() => {
    fetch(`${API_GATEWAY_URL}/verify/${token}`)
      .then((response) => {
        if (response.ok) {
          setIsValidToken(true);
        } else {
          setIsValidToken(false);
        }
      })
      .catch(() => {
        setIsValidToken(false);
      });
  }, [token]);

  useEffect(() => {
    if (isValidToken === true) {
      // Make sure the path is exactly as defined in your Routes component
      navigate('/verify/success');
    } else if (isValidToken === false) {
      // Make sure the path is exactly as defined in your Routes component
      navigate('/verify/error');
    }
  }, [isValidToken, navigate]);

  return (
    <div>
      {isValidToken === null && <p>Vérification en cours...</p>}
    </div>
  );
}

export default VerifyPage;
