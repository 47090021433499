import React, {useState, useEffect} from 'react'
import '../../index.css'
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import countriesWithContinent from '../../data/countriesWithContinent';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import DataObjectIcon from '@mui/icons-material/DataObject';


const IntroProfile = ({firstName, lastName, jobTitle, province, country}) => {
    function findCountryTimezone(country) {
        // Example of finding the continent based on the country
        let timeZone = countriesWithContinent.find(c => c.abbreviation === country)?.timezone;
        if (!timeZone) {
            console.error(`Continent not found for country: ${country}`);
            return null; // Handle error or return default timezone
        }
        let countryTime = null;
        let date = new Date();
        let options = { timeZone: `${timeZone}`, hour: 'numeric', minute: 'numeric', hour12: true};
        countryTime= date.toLocaleString('en-US', options);
        return countryTime;
    }

    

    return (
        <div 
            className='flex flex-col'
            style={{
                fontFamily: 'var(--ff-body)',
            }}
        >
            <h1
                className='mb-2'
                style={{
                    fontSize: '1.5rem',
                    fontWeight: 'var(--fw-semibold)',
                    color: 'var(--clr-black)',
                }}
            >
                {firstName} {lastName}
            </h1>
            <h2
                className='flex items-center gap-1'
                style={{
                    fontSize: '1rem',
                    fontWeight: 'var(--fw-semibold)',
                    color: 'var(--clr-secondary-500)',
                }}
            >
                <DataObjectIcon />
                <span>{jobTitle}</span>
            </h2>
            <div className="flex flex-row justify-between w-4/5 md:w-4/5 lg:w-4/5">
                <div className="flex items-center gap-1">
                    <LocationOnIcon
                        style={{
                            color: 'var(--clr-neutral-700)',
                        }}
                    />
                    <span
                        style={{
                            fontSize: '0.875rem',
                            color: 'var(--clr-neutral-700)',
                            width: '100%',
                        }}
                    >
                        {countriesWithContinent.find(c => c.abbreviation === country)?.name}, {province}
                    </span>
                </div>
                <div className="flex items-center gap-1">
                    <AccessTimeFilledIcon
                        style={{
                            color: 'var(--clr-neutral-700)',
                        }}
                    />
                    <span
                        style={{
                            fontSize: '0.875rem',
                            color: 'var(--clr-neutral-700)',
                        }}
                    >
                        Il est actuellement {findCountryTimezone(country)} à {province}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default IntroProfile