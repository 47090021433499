

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';



import { useSelector, useDispatch } from 'react-redux';

const initialState = {
  user: null,
  userType: null,
};





const authentificationSlice = createSlice({
  name: 'authentification',
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload.user;
      state.userType = action.payload.userType;
      state.email_validation = action.payload.email_validation;
    },
    logout: (state, action) => {
      state.user = null;
      state.userType = null;
      state.email_validation = null;
    },
  },
});

export const {
  login,
  logout,
} = authentificationSlice.actions;

export default authentificationSlice.reducer;
