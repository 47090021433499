// FreelancerCard.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/FreelancerCard.css";
import { useAuth } from "./AuthContext";
import { Link, NavLink } from "react-router-dom";
import logoWhite from "../assets/logoWhite.png";

const FreelancerCard = ({ freelancerData }) => {
  console.log("freelancer data : ", freelancerData);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [isVisible, setIsVisible] = useState(true); // State to manage visibility

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const getInitials = (name) =>
    name
      .split(" ")
      .map((n) => n[0])
      .join("");
  const handleLogout = () => {
    logout();
    navigate("/");
  };
  const navigateToProfile = () => {
    navigate("/freelancer-Dashboard");
  };

  document.addEventListener("DOMContentLoaded", function () {
    const menuButton = document.querySelector(".hamburger-menu");
    const sideBar = document.querySelector(".PC-container");

    menuButton.addEventListener("click", function () {
      sideBar.classList.toggle("active");
    });
  });

  const navigateToDashboard = () => {
    navigate("/freelancer-Dashboard");
  };

  const navigateToDemandes = () => {
    navigate("/freelancer-missions");
  };

  const navigateToPlanning = () => {
    navigate("/planning");
  };

  return (
    <div className={`PC-container ${isVisible ? "" : "hidden"}`}>
      <Link className="img" to="#">
        <img
          width="120px"
          src={logoWhite}
          style={{ alignSelf: "flex-start" }}
        />
      </Link>
      <button
        className={`toggle-button ${isVisible ? "hide" : "show"}`}
        onClick={toggleVisibility}
      >
        <i
          className={`fas ${isVisible ? "fa-arrow-left" : "fa-arrow-right"}`}
        ></i>
      </button>

      {freelancerData && (
        <div>
          {/* Content remains the same */}
          <div className="pc-company-info-card">
            <div className="pc-initials-icon">
              {getInitials(freelancerData.firstName)}
            </div>
            <h3 className="pc-company-name">
              {freelancerData.firstName} {freelancerData.lastName}
            </h3>
            <p className="pc-status">Titre : {freelancerData.profilTitle}</p>
            <button onClick={navigateToProfile} className="pc-profile-button">
              <i className="fa fa-user"></i> Profil
            </button>
          </div>
          <div className="pc-company-info-footer">
            <button
              onClick={navigateToDashboard}
              className="pc-full-width-button"
            >
              <i className="fa fa-dashboard"></i> Tableau de bord
            </button>
            <button
              onClick={navigateToPlanning}
              className="pc-full-width-button"
            >
              <i className="fa fa-tasks"></i> Mes disponibilités
            </button>
            <button
              onClick={navigateToDemandes}
              className="pc-full-width-button"
            >
              <i className="fa fa-tasks"></i> Mes missions
            </button>
          </div>
          <button onClick={handleLogout} className="pc-logout-button">
            Déconnexion
          </button>
        </div>
      )}
    </div>
  );
};

export default FreelancerCard;
