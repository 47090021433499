import { Button, Chip, Checkbox } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {  API_GATEWAY_URL } from '../constants';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';




const Clients = () => {

    const navigate = useNavigate();
    const [requestDetail, setRequestDetail] = useState(null);
    const [error, setError] = useState(null); 
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [entities, setEntities] = useState([]);
    const [expertises, setExpertises] = useState([]);
    

  

    useEffect(() => {
        const fetchFreelancers = async () => {
            try {
            if (!user) {
                setError('Aucun utilisateur connecté');
                setLoading(false);
                navigate('/admin-login');
                return;
            }
            const token = user.access_token;
            const response = await axios.get(`${API_GATEWAY_URL}/clients-list/`, {
                headers: {
                Authorization: `Bearer ${token}`,
                },
            });
            
              console.log(response.data);
              setEntities(response.data);
              
            } catch (err) {
            setError(err.message);
            } finally {
            
            setLoading(false);

            }
        };

       
        fetchFreelancers();
    }, [user]); 



    const columns = [
   
        { field: 'name', headerName: 'Nom Entreprise', width: 150 },
        { field: 'phoneNumber', headerName: 'Numéro de téléphone', width: 200 },
        { field: 'email', headerName: 'Email', width: 250 },
        { field: 'clientName', headerName: 'Nom Client', width: 220 },
          {
            field: 'details',
            headerName: 'Détails',
            width: 150,
            renderCell: (params) => {
              const handleDetailsClick = () => {
                navigate('/entity-details', { state: { entity: params.row } });
              };
        
              return (
                <div variant="contained"  className='rounded-full  flex cursor-pointer items-center justify-center text-center bg-[#10316B] text-white h-10 w-16' onClick={handleDetailsClick}>
                  Détails
                </div>
              );
            },
          },
          {
            field: 'delete',
            headerName: 'Supprimer',
            width: 150,
            renderCell: (params) => {
              const handleDelete = () => {
              
              };
        
              return (
                <div variant="contained"  className='rounded-[200px]  flex cursor-pointer items-center justify-center text-center bg-red-500 text-black h-10 w-16'onClick={handleDelete} >
                  Supprimer
                </div>
              );
            },
          },
        
      ];

    
    return(
        <div className='w-full flex justify-center items-center '>
        <div className="w-[75%] flex flex-col items-center justify-center ml-[18%]" >
            <div className='w-full flex justify-start'>
            <h2 className='text-[#10316B] font-poppins font-bold text-2xl mt-16 '>Clients</h2>
            </div>
            
                <div className='w-full flex justify-end'>
                    <div className='bg-[#10316B] font-poppins   text-[#F4F4F4]   ease-in-out h-14 rounded-3xl flex items-center  justify-center text-center w-60 space-x-4 cursor-pointer' >
                        <span>Nouveau Client</span>
                     </div>
                </div>
                <div className='w-full flex justify-start'>
                    <div className='h-[600px] mt-6 w-full'> 
                        <DataGrid
                        columns={columns}
                        rows={Array.isArray(entities) ? entities : []}
                        pageSize={10}
                        hideFooterSelectedRowCount
                        disableSelectionOnClick
                    />
                   </div>
                 </div>
        </div>
        </div>


    );

}

export default Clients