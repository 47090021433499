import React from "react";
import { FaUser, FaUsers, FaTools, FaCog, FaSignOutAlt } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/Logo.png";
import { FaTasks, FaBars } from "react-icons/fa";
import { useState } from "react";
import { RiDashboard3Fill } from "react-icons/ri";
import { FaPen } from "react-icons/fa";
import { useAuth } from "../AuthContext";

const Sidebar = () => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  const [isSubmenuVisible, setIsSubmenuVisible] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const toggleSubmenuVisibility = () => setIsSubmenuVisible(!isSubmenuVisible);
  const toggleSidebarVisibility = () => setIsSidebarVisible(!isSidebarVisible);

  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  const { logout } = useAuth();
  const handleLogout = () => {
    logout();
    navigateTo("/");
  };

  return (
    <div className="relative font-body text-fluid-xs text-primary-800  z-50">
      {/* Menu Icon for Smaller Screens */}
      <div
        className="lg:hidden fixed top-4 left-4 z-50 p-2 bg-primary-800 cursor-pointer rounded-full"
        onClick={toggleSidebarVisibility}
      >
        <FaBars className="text-white " style={{ fontSize: "1.5rem" }} />
      </div>

      {/* Sidebar */}

      <div
        className={`sidebar bg-white  font-poppins p-4 h-screen flex flex-col fixed left-0 top-0 overflow-y-auto transition-transform duration-300 lg:w-80 ${
          isSidebarVisible ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0`}
      >
        <div className="mb-6">
          <img src={logo} alt="Logo" className="w-[80%] " />
        </div>

        <div className="flex flex-col flex-grow ">
          {/* MENU Section */}
          <div className="mb-6">
            <button
              onClick={() => navigateTo("/dashboard-bp")}
              className={`sidebarbutton ${
                isActive("/dashboard-bp") ? "active" : ""
              }`}
            >
              <RiDashboard3Fill
                className="mr-2"
                style={{ fontSize: "1.25rem" }}
              />
              Tableau de bord
            </button>

            <button
              onClick={() => navigateTo("/clients-bp")}
              className={`sidebarbutton ${
                isActive("/clients-bp") ? "active" : ""
              }`}
            >
              <FaUsers className="mr-2" style={{ fontSize: "1.25rem" }} />
              Clients
            </button>

            <button
              onClick={() => navigateTo("/ressourcesBP")}
              className={`sidebarbutton ${
                isActive("/ressourcesBP") ? "active" : ""
              }`}
            >
              <FaTools className="mr-2" style={{ fontSize: "1.25rem" }} />
              Ressources
            </button>

            <button
              onClick={() => navigateTo("/liste-demandes")}
              className={`sidebarbutton ${
                isActive("/liste-demandes") ? "active" : ""
              }`}
            >
              <FaTasks className="mr-2" style={{ fontSize: "1.25rem" }} />
              Demandes
            </button>

            <button
              onClick={() => navigateTo("#")}
              className={`sidebarbutton ${isActive("#") ? "active" : ""}`}
            >
              <FaUser className="mr-2" style={{ fontSize: "1.25rem" }} />
              Profil
            </button>

            <button
              onClick={() => navigateTo("#")}
              className={`sidebarbutton ${isActive("#") ? "active" : ""}`}
            >
              <FaCog className="mr-2" style={{ fontSize: "1.25rem" }} />
              Paramètre
            </button>

            <button
              onClick={handleLogout}
              className="w-full bg-secondary-400 text-white py-2 rounded-lg mt-auto hover:bg-secondary-500 transition flex items-center"
            >
              <FaSignOutAlt className="mr-2" style={{ fontSize: "1.25rem" }} />{" "}
              Se déconnecter
            </button>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default Sidebar;

/*
OLD CODE
import React from "react";
import { FaUser, FaUsers, FaTools, FaCog, FaSignOutAlt } from "react-icons/fa";
import { HiClipboardDocumentList } from "react-icons/hi2";
import { AiFillAppstore } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom"; 
import logo from '../../assets/Logo.png';

const Sidebar = () => {
  const location = useLocation(); 

  const isActive = (path) => location.pathname === path;

  return (
    
    <div className="w-64 h-screen bg-white p-6 flex flex-col justify-between shadow-md sticky top-0 left-0">
      <div className="-ml-6"> 
        <div className="mb-8">
          <img src={logo} alt="Logo" className="w-[170px] h-[35px] ml-4" />
        </div>
        <ul className="space-y-2">
          <li>
            <Link
              to="/dashboard-bp"
              className={`flex items-center px-4 py-3 font-bold -ml-6 pl-6 rounded-r-full ${isActive('/dashboard-bp') ? 'text-white bg-purple-bp' : 'text-purple-bp hover:text-indigo-600 hover:bg-indigo-50'}`}
            >
              <AiFillAppstore className="mr-3" />
              <span>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link
              to="/clients-bp"
              className={`flex items-center px-4 py-3 rounded-r-full -ml-6 pl-6 ${isActive('/clients-bp') ? 'text-white bg-purple-bp' : 'text-purple-bp hover:text-indigo-600 hover:bg-indigo-50'}`}
            >
              <FaUsers className="mr-3" />
              <span>Clients</span>
            </Link>
          </li>
          <li>
          <Link
              to="/ressourcesBP"
              className={`flex items-center px-4 py-3 rounded-r-full -ml-6 pl-6 ${isActive('/ressourcesBP') ? 'text-white bg-purple-bp' : 'text-purple-bp hover:text-indigo-600 hover:bg-indigo-50'}`}
            >
              <FaTools className="mr-3" />
              <span>Ressources</span>
        </Link>
          </li>
          <li>
          <Link
              to="/liste-demandes"
              className={`flex items-center px-4 py-3 rounded-r-full -ml-6 pl-6 ${isActive('/liste-demandes') ? 'text-white bg-purple-bp' : 'text-purple-bp hover:text-indigo-600 hover:bg-indigo-50'}`}
            >
              <HiClipboardDocumentList className="mr-3" />
              <span>Demandes</span>
              </Link>
          </li>
          <li>
            <a
              href="#"
              className="flex items-center px-4 py-3 text-purple-bp hover:text-indigo-600 hover:bg-indigo-50 rounded-r-full -ml-6 pl-6"
            >
              <FaUser className="mr-3" />
              <span>Profil</span>
            </a>
          </li>
          <li>
            <a
              href="#"
              className="flex items-center px-4 py-3 text-purple-bp hover:text-indigo-600 hover:bg-indigo-50 rounded-r-full -ml-6 pl-6"
            >
              <FaCog className="mr-3" />
              <span>Paramètre</span>
            </a>
          </li>
        </ul>
      </div>
      
      <div>
        <hr className="my-4 border-gray-200" />
        <button className="flex items-center w-full px-4 py-3 bg-slate-50 text-purple-bp hover:text-white hover:bg-purple-bp rounded-r-full -ml-6 pl-6">
          <FaSignOutAlt className="mr-3" />
          <span>Se Deconnecter</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;

*/
