import React, {useState, useEffect} from 'react';
import { API_GATEWAY_URL } from '../../constants';
import '../../index.css';

import Logo from './Logo'
import NavBar from './NavBar'
import AvatarIcon from './AvatarIcon'
import AvailabilityBtn from './AvailabilityBtn';

import profileImage from '../../assets/istockphoto-1495088043-612x612.jpg';

const Menu = ({imageId,user}) => {
    const [imageUrl, setImageUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        if (!imageId) {
            setImageUrl(profileImage); // Fallback if no imageId
            setLoading(false);
            return;
        }
    
        const fetchImageUrl = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/${imageId}/`);
                if (response.ok) {
                    const data = await response.json();
                    setImageUrl(data.image_url);
                } else {
                    setImageUrl(profileImage);  // Fallback image on error
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
    
        fetchImageUrl();
    }, [imageId]);
    

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;


    return (
        <div className="
            menu
            w-full
            max-w-screen-xl
            flex justify-between"
        >
            <div className="
                left-side
                flex items-center"
            >
                <Logo />
                <span className="
                    hidden
                    vertical-line
                    w-0.5
                    h-full
                    mx-4
                    bg-black/80
                    md:block"
                ></span>

                <NavBar items={['Profile', 'Missions', 'Chat', 'Tests Techniques', 'Transactions']} />
            </div>
            <div className='right-side flex gap-2 items-center'>
                <AvailabilityBtn user={user}/>
                <AvatarIcon avatarIconPath={imageUrl} avatarIconAlt={'profile'}/>
            </div>
        </div>
    )
}

export default Menu;