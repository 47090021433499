import React from "react";
import PropTypes from "prop-types";
import globe from "../icons/globe.png";
import rocket from "../icons/rocket.png";
import search from "../icons/search.png";
import "../index.css";
import "../styles/LandingPage.css";

// FeatureCard Component
const FeatureCard = ({ imageSrc, title, description }) => {
  return (
    <section className="Landingcard flex max-md:flex-col  items-center text-center grow w-full max-md:mt-4">
      <img
        loading="lazy"
        src={imageSrc}
        alt=""
        className="object-contain self-center aspect-[1.21] w-[130px] mr-4"
      />
      <p className="font-body font-bold text-primary-800 max-md:max-w-full text-fluid-sm 2xl:text-fluid-md mt-4">
        {description}
      </p>
    </section>
  );
};

// Define prop types for FeatureCard
FeatureCard.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

// FeatureSection Component
const features = [
  {
    imageSrc: globe,
    description:
      "Créez un profil en tant que freelance, client, partenaire ou entreprise",
  },
  {
    imageSrc: search,
    description:
      "Explorez les profils, recherchez des services ou proposez des collaborations.",
  },
  {
    imageSrc: rocket,
    description:
      "Échangez des services, discutez des projets et commencez à travailler ensemble",
  },
];

const ClientThirdSection = () => {
  return (
    <div className="flex flex-col items-center justify-center py-4 ">
      <div className="text-center ">
        <h2 className="font-display text-white  font-bold text-fluid-xlg 2xl:text-fluid-xl">
          Découvrez Notre Fonctionnement
        </h2>
        <p className="font-body text-white max-md:max-w-full text-fluid-sm 2xl:text-fluid-md mt-4">
          Notre plateforme simplifie la connexion entre freelances, clients,
          partenaires et entreprises
        </p>
      </div>
      <div className="px-16 max-sm:px-4 sm:px-32">
        <main className="w-full max-md:mt-10 max-md:max-w-full">
          <div className="flex max-2xl:gap-1 2xl:gap-3 flex-col">
            {features.map((feature, index) => (
              <div
                key={index}
                className="flex flex-col max-md:ml-0 max-md:w-full"
              >
                <FeatureCard
                  imageSrc={feature.imageSrc}
                  description={feature.description}
                />
              </div>
            ))}
          </div>
        </main>
      </div>
    </div>
  );
};

export default ClientThirdSection;
