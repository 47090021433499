import React, { useState, useEffect } from "react";
import { API_GATEWAY_URL } from "../../constants";
import ClientCard from "../ClientCard";
import Navbarr from "../Navbarr";
import { useAuth } from "../AuthContext";
import LoadingSpinner from "../LoadingSpinner";
import Modal from "react-modal";
import { PaymentInfoPopUp } from "./paimentInfoPopUp";
import { SiSepa as SepaLogo } from "react-icons/si";
import { BsCreditCard2Front as VisaLogo } from "react-icons/bs";

const CardsPage = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [cardToDelete, setCardToDelete] = useState(null);

  const handleClick = async () => {
    // Open the modal of adding a payment method
    setModalIsOpen(true);
  };
//******************************************* */
  const handleNext = async (paymentMethodId, paymentMethod, last4Digits,email) => {
    // Add the payment method to the user
    try {
      console.log(email)
      const response = await fetch(
        `${API_GATEWAY_URL}/payment-method/?user=${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.access_token}`,
          },
          body: JSON.stringify({
            payment_method_id: paymentMethodId,
            type: paymentMethod,
            last4Digits: last4Digits,
            email:email,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      await fetchData();

      setModalIsOpen(false);
      return response;
    } catch (error) {
      console.error("Error :", error);
      return error;
    }
  };

//******************************************* */
  const handleDelete = async () => {
    // Delete the payment method
    if (!cardToDelete) return;
    
    try {
      setLoadingDelete(true);
      
      const response = fetch(`${API_GATEWAY_URL}/delete-card/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({
          token: cardToDelete.token,
        }),
      })
      .then(async response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      
      

      await fetchData();
      
      setLoadingDelete(false);
      setConfirmDeleteIsOpen(false);
      setCardToDelete(null);
    }) 
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
    }
    catch(error){
      console.log(error)
    }}
//******************************************* */
  const fetchData = async () => {
    // Fetch the user's cards
    if (!user) {
      console.log("User is not authenticated.");
      return;
    }

    try {
      const profileResponse = await fetch(
        `${API_GATEWAY_URL}/client-profile/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );

      if (!profileResponse.ok)
        throw new Error(
          "Erreur lors de la récupération des données du profil."
        );

      const profileData = await profileResponse.json();
      const user_id = profileData.user;
      setUserId(user_id);

      const cardsResponse = await fetch(
        `${API_GATEWAY_URL}/get-user-cards/?user=${user_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );

      if (!cardsResponse.ok)
        throw new Error("Erreur lors de la récupération des cartes.");

      const cardsData = await cardsResponse.json();
      setCards(cardsData);
      console.log(cardsData)
    } catch (err) {
      console.error("Erreur:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
//******************************************* */
  useEffect(() => {
    // Fetch the data once the component mounts
    fetchData();
  }, []);
//******************************************* */


  return (
    <>
      <div className="pw-page-layout font-body max-2xl:text-fluid-xs 2xl:text-fluid-sm text-primary-800">
        <Navbarr title={"Ajouter"} />
        <div className="flex flex-col w-full">
          <ClientCard />
          {error && <div className="text-red-500">{error}</div>}

          <div className="flex flex-col items-center justify-center p-6">
            <div className="flex items-center justify-between w-full p-6 ml-8 pr-3">
              <h3 className="text-md font-semibold text-gray-800 font-Poppins">
                Moyens de paiement
              </h3>
              <div
                className="px-8 py-1.5 bg-[#0056b3] rounded-[36px] justify-center items-center gap-6 inline-flex text-white text-base font-normal font-Poppins cursor-pointer"
                onClick={handleClick}
              >
                Ajouter Moyen de paiement
              </div>
            </div>
            {loading ? (
              <LoadingSpinner isLoading={loading} />
            ) : (
              <div className="w-full space-y-4 pl-8">
                {cards.map((card) => (
                  <div
                    key={card.token}
                    className="w-full px-9 bg-[#f5f5f5] rounded-2xl justify-between items-center inline-flex py-4 shadow-md relative"
                  >
                    <div className="text-4xl font-semibold text-gray-800 font-Poppins">
                      {card.type === "sepa" ? (
                        <div className="flex flex-row-reverse items-center">
                          <div className="ml-4">SEPA</div>
                          <SepaLogo className="w-10 h-10 text-blue-900" />
                        </div>
                      ) : (
                        <div className="flex flex-row-reverse items-center">
                          <div className="ml-4">Carte</div>
                          <VisaLogo className="w-8 h-8 text-blue-900" />
                        </div>
                      )}
                    </div>
                    <div className="text-4xl font-semibold text-black font-Poppins">
                      {"**** **** **** " + card.last4Digits}
                    </div>

                    <div className="relative group">
                      <img
                        src={require("../../assets/paiement/Cross.svg").default}
                        onClick={() => {
                          setCardToDelete(card);
                          setConfirmDeleteIsOpen(true);
                        }}
                        alt="Delete"
                        className="cursor-pointer"
                      />
                      <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2">
                        Supprimer
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      /* Payment Info Modal */
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Retrait Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(5px)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "40%",
          },
        }}
      >
        <PaymentInfoPopUp
          onBack={() => {
            setModalIsOpen(false);
          }}
          onNext={(paymentMethodId, paymentMethod, last4Digits,email) =>
            handleNext(paymentMethodId, paymentMethod, last4Digits,email)
          }
        />
      </Modal>

      {/* Confirmation Delete Modal */}
      <Modal
        isOpen={confirmDeleteIsOpen}
        onRequestClose={() => setConfirmDeleteIsOpen(false)}
        contentLabel="Confirmation Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(5px)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <div className="flex flex-col p-4 rounded justify-center items-center">
          <h2 className="text-lg font-semibold">Confirmer la suppression</h2>
          <p className="mt-2">
            Êtes-vous sûr de vouloir supprimer{" "}
            {confirmDeleteIsOpen
              ? `"**** **** **** " ${cardToDelete.last4Digits}`
              : "ce moyen de paiement"}{" "}
          </p>
          <div className="w-full flex justify-between mt-6">
            <div
              onClick={() => setConfirmDeleteIsOpen(false)}
              className="px-4 py-2 mr-2 text-black bg-gray-200 hover:bg-gray-300 rounded cursor-pointer"
            >
              Annuler
            </div>
            <div
              onClick={handleDelete}
              className="px-4 py-2 text-white bg-red-700 hover:bg-red-800 rounded cursor-pointer"
            >
              {loadingDelete ? "En cours..." : "Supprimer"}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CardsPage;
