import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Layout from './Layout';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { API_GATEWAY_URL } from '../constants';

const levelOptions = [
    { value: 'JR', label: 'Junior' },
    { value: 'CF', label: 'Confirmé' },
    { value: 'SR', label: 'Senior' }
];

const EditExpertisePage = () => {
    const [expertise, setExpertise] = useState(null);
    const [selectedLevel, setSelectedLevel] = useState(''); // initialize with an appropriate value if needed
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { user } = useAuth();
    const navigate = useNavigate();
    const { expertiseId } = useParams(); // Assurez-vous que votre route est configurée pour passer 'expertiseId'

    useEffect(() => {
        if (user && user.access_token && expertiseId) {
            const fetchExpertiseDetails = async () => {
                console.log("Sending PUT Request with:", JSON.stringify({ ...expertise, level: selectedLevel }));

                try {
                    const response = await fetch(`${API_GATEWAY_URL}/expertises-by-provider2/${expertiseId}/`, {
                        method:'GET',
                        headers: {
                            'Authorization': `Bearer ${user.access_token}`,
                            'Content-Type': 'application/json'
                        },
                    });
                    console.log("Sending PUT Request with:", JSON.stringify({ ...expertise, level: selectedLevel }));

                    if (!response.ok) {
                        response.json().then(data => {
                            console.error('Error response from server:', data);
                            Swal.fire('Error', `Failed to update expertise: ${data.error}`, 'error');
                        });
                        return;
                    }
                    const data = await response.json();
                    setExpertise(data);
                    setSelectedLevel(data.level);
                } catch (err) {
                    setError(err.message);
                } finally {
                    setLoading(false);
                }
            };
            fetchExpertiseDetails();
        }
    }, [user, expertiseId]);

    if (loading) {
        return <Layout>Loading...</Layout>;
    }

    if (error) {
        return <Layout>Error: {error}</Layout>;
    }

    const handleLevelChange = (selectedOption) => {
        setSelectedLevel(selectedOption.value);
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!expertise || !selectedLevel) {
            Swal.fire('Error', 'Please select a level', 'error');
            return;
        }

        try {
            const response = await fetch(`${API_GATEWAY_URL}/expertises-by-provider2/${expertiseId}/`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${user.access_token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ expertise: expertise.id, level: selectedLevel })  // Ensure the key matches what backend expects
            });
            if (!response.ok) {
                throw new Error('Failed to update expertise');
            }
            Swal.fire('Success', 'Expertise updated successfully', 'success');
            navigate('/expertises');
        } catch (err) {
            Swal.fire('Error', err.message, 'error');
        }
    };


    return (
        <Layout>
            <div>
                <h1>Edit Expertise</h1>
                <form onSubmit={handleSubmit}>
                    <div>{expertise ? expertise.name : 'Loading...'}</div>
                    <Select
                        options={levelOptions}
                        onChange={handleLevelChange}
                        value={levelOptions.find(option => option.value === selectedLevel)}
                        placeholder="Select Level"
                    />
                    <button type="submit">Update Expertise</button>
                </form>
            </div>
        </Layout>
    );
};

export default EditExpertisePage;
