import React, { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import { API_GATEWAY_URL } from "../constants";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";
import ProviderCard from "./ProviderCard";
import styles from "../styles/ProviderWorker.css";
import Swal from "sweetalert2";
import LoadingSpinner from "./LoadingSpinner";
import logo from "../assets/Logo.png";

const ProviderWorkers = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [workers, setWorkers] = useState([]);
  const [providerData, setProviderData] = useState(null); // State to store provider data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const colors = [
    "#007bff",
    "#28a745",
    "#dc3545",
    "#ffc107",
    "#17a2b8",
    "#6f42c1",
    "#fd7e14",
    "#e83e8c",
    "#20c997",
    "#6610f2",
    "#d63384",
    "#0dcaf0",
    "#7952b3",
    "#198754",
    "#0d6efd",
    "#6c757d",
    "#fd7e14",
    "#dc3545",
    "#f8f9fa",
    "#6c757d",
  ];
  const navigateToInvite = () => {
    navigate("/invite-worker");
  };

  const fetchWorkersAndProvider = async () => {
    if (user && user.access_token) {
      setLoading(true);
      try {
        const responses = await Promise.all([
          fetch(`${API_GATEWAY_URL}/workers-by-provider/`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              "Content-Type": "application/json",
            },
          }),
          fetch(`${API_GATEWAY_URL}/provider-profile/`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              "Content-Type": "application/json",
            },
          }),
        ]);

        if (!responses[0].ok || !responses[1].ok) {
          throw new Error("Failed to fetch data.");
        }

        const workersData = await responses[0].json();
        const providerData = await responses[1].json();

        setWorkers(workersData);
        setProviderData(providerData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    } else {
      setError("User not authenticated.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkersAndProvider();
  }, [user]);

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <div style={styles.error}>Error: {error}</div>;
  }

  const editWorker = (workerId) => {
    console.log("Edit worker", workerId);
    navigate(`/edit-worker/${workerId}`);
  };
  const getInitials = (firstName, lastName) => {
    return firstName[0]; // Just the first initial of the first name
  };
  const getWorkerColor = (workerId) => {
    const storedColor = localStorage.getItem(`workerColor-${workerId}`);
    if (storedColor) {
      return storedColor;
    } else {
      const color = colors[Math.floor(Math.random() * colors.length)];
      localStorage.setItem(`workerColor-${workerId}`, color);
      return color;
    }
  };

  const handleDeleteWorker = async (workerId) => {
    const result = await Swal.fire({
      title: "Êtes-vous sûr de vouloir supprimer ce travailleur ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(
          `${API_GATEWAY_URL}/delete-worker/${workerId}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          setWorkers((prevWorkers) =>
            prevWorkers.filter((worker) => worker.id !== workerId)
          );
          Swal.fire(
            "Supprimé !",
            "Le travailleur a été supprimé avec succès.",
            "success"
          );
        } else {
          throw new Error("Failed to delete the worker.");
        }
      } catch (err) {
        setError("Error deleting worker: " + err.message);
        Swal.fire(
          "Erreur !",
          `Une erreur est survenue lors de la suppression du travailleur : ${err.message}`,
          "error"
        );
      }
    }
  };

  return (
    <div className="providerRessourceContainer">
      {providerData && <ProviderCard providerData={providerData} />}
      <div className="workersList">
        {workers.length > 0 ? (
          workers.map((worker) => (
            <div key={worker.id} className="workerCard">
              <div
                className="avatar"
                style={{ backgroundColor: getWorkerColor(worker.id) }}
              >
                {getInitials(worker.first_name, worker.last_name)}
              </div>
              <div className="workerInfo">
                <p>
                  <strong>Nom:</strong> {worker.last_name}
                </p>
                <p>
                  <strong>Prénom:</strong> {worker.first_name}
                </p>
                <p>
                  <strong>Email:</strong> {worker.email}
                </p>
                <p>
                  <strong>Expertises:</strong>{" "}
                  {worker.association_expertises
                    .map((ae) => `${ae.expertisename} (${ae.level})`)
                    .join(", ")}
                </p>
                <div>
                  <button
                    onClick={() => editWorker(worker.id)}
                    className="providerButton"
                  >
                    Modifier
                  </button>
                  <button
                    onClick={() => handleDeleteWorker(worker.id)}
                    className="deleteButton"
                  >
                    Supprimer
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>Vous n'avez aucun employé</p>
        )}
        <div onClick={navigateToInvite} className="addResourceCard">
          <span>Ajouter une ressource </span>
        </div>
      </div>
    </div>
  );
};

export default ProviderWorkers;
