import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { API_GATEWAY_URL } from '../constants';


const BusinessPartnerProfile = () => {
  const navigate = useNavigate();
  const [businesspartnerData, setBusinessPartnerData] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

      const fetchBusinessPartnerData = async () => {
        try {
          const response = await fetch(`${API_GATEWAY_URL}/businesspartner-profile/`, {
            method: 'GET',
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.access_token}`
            },
          });

          if (response.ok) {
            const data = await response.json();
            setBusinessPartnerData(data);
          } else {
            throw new Error('Failed to fetch businesspartner data');
          }
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };

  const handleDeleteContact = async (contactId) => {
    try {
      const response = await fetch(`${API_GATEWAY_URL}/businesspartner-contact-delete/${contactId}/`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${user.access_token}`
        }
      });

      if (response.ok) {
        setSuccessMessage("Contact supprimé avec succès.");
        fetchBusinessPartnerData(); // Recharge les données du prestataire
      } else {
        throw new Error('Échec de la suppression du contact');
      }
    } catch (error) {
      setError("Erreur lors de la suppression du contact: " + error.message);
      setSuccessMessage('');
    }
  };

const displayContacts = (contacts, contactType) => {
  const filteredContacts = contacts.filter(c => c.type === contactType);

  if (filteredContacts.length === 0) {
    return <p>Aucun</p>;
  }

  return filteredContacts.map((contact) => (
    <div key={contact.id} style={styles.contactItem}>
      <span>{contact.first_name} {contact.last_name} - {contact.email} - {contact.phone_number}</span>
      <button 
        onClick={() => handleDeleteContact(contact.id)} 
        style={styles.smallButton}
      >
        Supprimer
      </button>
    </div>
  ));
};

  const navigateToAddContact = () => {
	navigate('/businesspartner-add-contact');
  };




  useEffect(() => {
    if (user && user.access_token && user.user_email) {
      fetchBusinessPartnerData();
    } else {
      setError("User not authenticated");
      setLoading(false);
    }
  }, [user]);







  if (loading) {
    return <div>Chargement...</div>;
  }

  if (error) {
    return <div>Erreur: {error}</div>;
  }
  const handleEditProfile = () => {
    navigate('/businesspartner-profile-update');
  };


  return (
    <div style={styles.container}>
      {businesspartnerData && (
        <>
          <h2 style={styles.title}>Profil BusinessPartner</h2>
          <p><strong>Nom de l'entreprise:</strong> {businesspartnerData.name}</p>
          <p><strong>SIRET:</strong> {businesspartnerData.siren}</p>
          <p><strong>Statut de l'entreprise:</strong> {businesspartnerData.company_status}</p>
          <p><strong>Adresse actuelle:</strong> {businesspartnerData.address}</p>
          {/* Conditionnellement afficher la nouvelle adresse et le libellé "non vérifié" */}
          {businesspartnerData.is_new_address_defined && !businesspartnerData.is_new_address_verified && (
            <p>
              <strong>Nouvelle adresse (en cours de vérification):</strong> {businesspartnerData.new_address}
            </p>
          )}


          <h3>Contacts Administratifs</h3>
          {displayContacts(businesspartnerData.contacts, 'A')}

          <h3>Contacts Techniques</h3>
          {displayContacts(businesspartnerData.contacts, 'T')}
		  
		  <center>
          <button onClick={navigateToAddContact} style={styles.smallGreenButton}>
            Ajouter un contact
			
          </button>
		  </center><br/><br/>
          {businesspartnerData.logo_url && <center><img src={businesspartnerData.logo_url} alt="Logo de l'entreprise" style={styles.logo} /></center>}
		  <p><button onClick={handleEditProfile} style={styles.button}>Modifier le profil</button></p>
        </>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '600px',
    margin: 'auto',
    backgroundColor: '#f8f8f8',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  title: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '20px',
  },
  logo: {
    maxWidth: '100px',
    maxHeight: '100px',
    display: 'block',
    margin: '10px 0',
  },
  button: {
    backgroundColor: '#007BFF',
    color: '#fff',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
    transition: 'background-color 0.3s ease-in-out',
    width: '100%', // Assure que le bouton s'étend sur toute la largeur
  },
  smallButton: {
    backgroundColor: 'red',
    color: 'white',
    padding: '5px 10px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '12px',
    marginLeft: '10px', // Ajoutez un peu d'espace entre le texte et le bouton
  },
  contactItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  smallGreenButton: {
    backgroundColor: 'green',
    color: 'white',
    padding: '5px 10px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '12px',
    marginTop: '10px',
  },
  error: {
    color: 'red',
    textAlign: 'center',
  },
  success: {
    color: 'green',
    textAlign: 'center',
  },
};

export default BusinessPartnerProfile;
