
import Dashbord from '../../assets/dashboard.svg'
import Client from '../../assets/client.svg'
import Provider from '../../assets/provider.svg'
import React, { useState } from 'react';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import logoWhite from '../../assets/logoWhite.png'; 
const AdminCard = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  //get the acteul location
  const location = useLocation(); 
  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const getInitials = (name) => name.split(' ').map((n) => n[0]).join('');

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const navigateToDashboard = () => {
    if (location.pathname !== '/admin-Dashboard') {
      navigate('/admin-Dashboard');
    }
  };

  const navigateToClientRequests = () => {
    if (location.pathname !== '/admin/requests') {
      navigate('/admin/requests');
    }
  };

  const navigateToProviders = () => {
    if (location.pathname !== '/admin/providers') {
      navigate('/admin/providers');
    }
  };
  const navigateToMenuClientsProfils = () =>{
    if (location.pathname !== '/admin/menu-clients-profils') {
      navigate('/admin/menu-clients-profils');
    }

  };

  const navigateToMenuBusinessPartners = () =>{
    if (location.pathname !== '/admin/menu-businesspartner') {
      navigate('/admin/mmenu-businesspartner');
    }

  };
  
    const navigateToRetraits = () => {
    navigate("/admin/retraits");
  } ;

  // Vérifier si la route actuelle est dans `/admin/requests` ou `admin-request-detail/:demande_id`
  const isRequestRouteActive =
    location.pathname.startsWith('/admin/requests') || location.pathname.startsWith('/admin-request-detail');

  return (
    <div className=" bg-[#10316B] w-[18%] h-full fixed left-0 top-0">
      <div className='flex items-center justify-center mt-4'>
        <img  width='130px' src={logoWhite} className=''/>

      </div>

      <div className='mt-8 flex flex-col items-center justify-around h-[35%]  w-full '>
          <NavLink
            to="/admin-Dashboard"
            className={({ isActive }) =>
              `no-underline flex flex-row justify-center items-center w-full rounded-lg h-16 ${
                isActive ? 'text-[#1C4D88] bg-white' : 'text-[#FFFF] hover:text-black hover:bg-white'
              } `
            }
            onClick={navigateToDashboard}
          >
            
            <svg className="w-5 h-5 transition duration-75" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
              <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
            </svg>
            <span className="ms-3">Tableau de bord</span>
          </NavLink>
          <NavLink
            to="/admin/requests"
            className={({ isActive }) =>
              `no-underline flex flex-row justify-center items-center w-full rounded-lg group h-16 ${
                isRequestRouteActive ? 'text-[#1C4D88] bg-white' : 'text-[#FFFF] hover:text-black hover:bg-white'
              }`
            }
            onClick={navigateToClientRequests}
          >
            <svg className="w-7 h-7 transition duration-75" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
              <path d="M-19,12a2,2,0,0,0,2-2,2,2,0,0,0-2-2,2,2,0,0,0-2,2A2,2,0,0,0-19,12Zm0-3a1,1,0,0,1,1,1,1,1,0,0,1-1,1,1,1,0,0,1-1-1A1,1,0,0,1-19,9Zm2.5,4h-5A2.5,2.5,0,0,0-24,15.5,1.5,1.5,0,0,0-22.5,17h7A1.5,1.5,0,0,0-14,15.5,2.5,2.5,0,0,0-16.5,13Zm1,3h-7a.5.5,0,0,1-.5-.5A1.5,1.5,0,0,1-21.5,14h5A1.5,1.5,0,0,1-15,15.5.5.5,0,0,1-15.5,16ZM-8,3.5v5A2.5,2.5,0,0,1-10.5,11h-2.793l-1.853,1.854A.5.5,0,0,1-15.5,13a.489.489,0,0,1-.191-.038A.5.5,0,0,1-16,12.5v-2a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5v.793l1.146-1.147A.5.5,0,0,1-13.5,10h3A1.5,1.5,0,0,0-9,8.5v-5A1.5,1.5,0,0,0-10.5,2h-7A1.5,1.5,0,0,0-19,3.5v3a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5v-3A2.5,2.5,0,0,1-17.5,1h7A2.5,2.5,0,0,1-8,3.5Zm-6.854,4.354-2-2a.5.5,0,0,1,0-.708.5.5,0,0,1,.708,0L-14.5,6.793l2.646-2.647a.5.5,0,0,1,.708,0,.5.5,0,0,1,0,.708l-3,3A.5.5,0,0,1-14.5,8,.5.5,0,0,1-14.854,7.854Z" transform="translate(24 -1)" />
            </svg>
            <span className="ms-3">Demandes des clients</span>
          </NavLink>
          <NavLink
            to="/admin/providers"
            className={({ isActive }) =>
              `no-underline flex flex-row justify-center items-center w-full rounded-lg group h-16 ${
                isActive ? 'text-[#1C4D88] bg-white' : 'text-[#FFFF] hover:text-black hover:bg-white'
              }`
            }
            onClick={navigateToProviders}
          >
            <svg className="w-5 h-5 transition duration-75" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20"><path d="M8.92 4.615a.25.25 0 0 0-.157-.115L3.25 3.25a.25.25 0 0 0-.316.195l-.643 4.5a.25.25 0 0 0 .222.284l2.926.366a5.506 5.506 0 0 1 3.293-3.98l.188-1.367ZM15 2.75h-5.746l-.235 1.703A5.5 5.5 0 0 1 15 9.672V3a.25.25 0 0 0-.25-.25Zm2.1 6.334a5.47 5.47 0 0 1 .657 1.677.25.25 0 0 0 .243.196h1.478a.25.25 0 0 0 .243-.306 8.002 8.002 0 0 0-3.85-5.188v4.108a5.5 5.5 0 0 1 1.229-.487ZM7.5 10a4.5 4.5 0 1 0 9 0 4.5 4.5 0 0 0-9 0ZM1.75 15.25h.536a.25.25 0 0 1 .244.197l.263 1.182a2 2 0 0 0 1.955 1.621H14.25a2 2 0 0 0 1.955-1.621l.263-1.182a.25.25 0 0 1 .244-.197h.536a.25.25 0 0 1 .25.25v1.286c0 1.044-.847 1.89-1.89 1.89H3.39c-1.044 0-1.89-.846-1.89-1.89V15.5a.25.25 0 0 1 .25-.25Z"/></svg>
            <span className="ms-3">Prestataires</span>
          </NavLink>

          <NavLink
            to="/admin/menu-clients-profils"
            className={({ isActive }) =>
              `no-underline flex flex-row justify-center items-center w-full rounded-lg group h-16 ${
                isActive ? 'text-[#1C4D88] bg-white' : 'text-[#FFFF] hover:text-black hover:bg-white'
              }`
            }
            onClick={navigateToMenuClientsProfils}
          >
            <svg className="w-5 h-5 transition duration-75" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20"><path d="M8.92 4.615a.25.25 0 0 0-.157-.115L3.25 3.25a.25.25 0 0 0-.316.195l-.643 4.5a.25.25 0 0 0 .222.284l2.926.366a5.506 5.506 0 0 1 3.293-3.98l.188-1.367ZM15 2.75h-5.746l-.235 1.703A5.5 5.5 0 0 1 15 9.672V3a.25.25 0 0 0-.25-.25Zm2.1 6.334a5.47 5.47 0 0 1 .657 1.677.25.25 0 0 0 .243.196h1.478a.25.25 0 0 0 .243-.306 8.002 8.002 0 0 0-3.85-5.188v4.108a5.5 5.5 0 0 1 1.229-.487ZM7.5 10a4.5 4.5 0 1 0 9 0 4.5 4.5 0 0 0-9 0ZM1.75 15.25h.536a.25.25 0 0 1 .244.197l.263 1.182a2 2 0 0 0 1.955 1.621H14.25a2 2 0 0 0 1.955-1.621l.263-1.182a.25.25 0 0 1 .244-.197h.536a.25.25 0 0 1 .25.25v1.286c0 1.044-.847 1.89-1.89 1.89H3.39c-1.044 0-1.89-.846-1.89-1.89V15.5a.25.25 0 0 1 .25-.25Z"/></svg>
            <span className="ms-3">Menu Clients Profils</span>
          </NavLink>

          <NavLink
            to="/admin/menu-businesspartner"
            className={({ isActive }) =>
              `no-underline flex flex-row justify-center items-center w-full rounded-lg group h-16 ${
                isActive ? 'text-[#1C4D88] bg-white' : 'text-[#FFFF] hover:text-black hover:bg-white'
              }`
            }
            onClick={navigateToMenuBusinessPartners}
          >
            <svg className="w-5 h-5 transition duration-75" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20"><path d="M8.92 4.615a.25.25 0 0 0-.157-.115L3.25 3.25a.25.25 0 0 0-.316.195l-.643 4.5a.25.25 0 0 0 .222.284l2.926.366a5.506 5.506 0 0 1 3.293-3.98l.188-1.367ZM15 2.75h-5.746l-.235 1.703A5.5 5.5 0 0 1 15 9.672V3a.25.25 0 0 0-.25-.25Zm2.1 6.334a5.47 5.47 0 0 1 .657 1.677.25.25 0 0 0 .243.196h1.478a.25.25 0 0 0 .243-.306 8.002 8.002 0 0 0-3.85-5.188v4.108a5.5 5.5 0 0 1 1.229-.487ZM7.5 10a4.5 4.5 0 1 0 9 0 4.5 4.5 0 0 0-9 0ZM1.75 15.25h.536a.25.25 0 0 1 .244.197l.263 1.182a2 2 0 0 0 1.955 1.621H14.25a2 2 0 0 0 1.955-1.621l.263-1.182a.25.25 0 0 1 .244-.197h.536a.25.25 0 0 1 .25.25v1.286c0 1.044-.847 1.89-1.89 1.89H3.39c-1.044 0-1.89-.846-1.89-1.89V15.5a.25.25 0 0 1 .25-.25Z"/></svg>
            <span className="ms-3">Menu des Business Partners </span>
          </NavLink>

          <NavLink
            to="/admin/retraits"
            className={({ isActive }) =>
              `no-underline flex flex-row justify-center items-center w-full rounded-lg group h-16 ${
                isActive ? 'text-[#1C4D88] bg-white' : 'text-[#FFFF] hover:text-black hover:bg-white'
              }`
            }
            onClick={navigateToRetraits}
          >
            <svg className="w-5 h-5 transition duration-75" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20"><path d="M8.92 4.615a.25.25 0 0 0-.157-.115L3.25 3.25a.25.25 0 0 0-.316.195l-.643 4.5a.25.25 0 0 0 .222.284l2.926.366a5.506 5.506 0 0 1 3.293-3.98l.188-1.367ZM15 2.75h-5.746l-.235 1.703A5.5 5.5 0 0 1 15 9.672V3a.25.25 0 0 0-.25-.25Zm2.1 6.334a5.47 5.47 0 0 1 .657 1.677.25.25 0 0 0 .243.196h1.478a.25.25 0 0 0 .243-.306 8.002 8.002 0 0 0-3.85-5.188v4.108a5.5 5.5 0 0 1 1.229-.487ZM7.5 10a4.5 4.5 0 1 0 9 0 4.5 4.5 0 0 0-9 0ZM1.75 15.25h.536a.25.25 0 0 1 .244.197l.263 1.182a2 2 0 0 0 1.955 1.621H14.25a2 2 0 0 0 1.955-1.621l.263-1.182a.25.25 0 0 1 .244-.197h.536a.25.25 0 0 1 .25.25v1.286c0 1.044-.847 1.89-1.89 1.89H3.39c-1.044 0-1.89-.846-1.89-1.89V15.5a.25.25 0 0 1 .25-.25Z"/></svg>
            <span className="ms-3">Retraits </span>
          </NavLink>
      
          
         
      </div>
      <div className='pb-4'>
          <NavLink
            to="/"
            className=" no-underline flex flex-row justify-center items-center w-full rounded-lg group h-14  text-[#FFFF] hover:text-black hover:bg-red-400 "
            
            onClick={handleLogout}
          >
            <span className="ms-3">Deconnexion</span>
          </NavLink>

          </div>
    </div>
  );
};

export default AdminCard;