import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const cleanupChatbot = () => {
  console.log("CHATBOT CLEAN");
  
  if (window.BotpressWebChat) {
    // Close the webchat programmatically
    window.BotpressWebChat.close(); 
  }

  const existingWidget = document.querySelector('#bp-webchat');
  if (existingWidget) {
    existingWidget.remove();
  }

  const scripts = document.querySelectorAll('script[src="https://cdn.botpress.cloud/webchat/v2.1/inject.js"], script[src="https://mediafiles.botpress.cloud/8dc4efc5-4a76-44f6-af4e-9a2b40aa99e2/webchat/v2.1/config.js"]');
  scripts.forEach(script => script.remove());
};

const ChatbotReact = () => {
  const location = useLocation();

  

  useEffect(() => {
    // Function to load a script
    const loadScript = (src, onLoad) => {
      const existingScript = document.querySelector(`script[src="${src}"]`);
      if (existingScript) return; // If script already exists, do not add it again

      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = onLoad;
      document.body.appendChild(script);
      return script; // Return the script element for future reference
    };

    // Function to initialize Botpress Webchat
    const initializeWebchat = () => {
      if (window.BotpressWebChat) {
        window.BotpressWebChat.init({
          botId: '8dc4efc5-4a76-44f6-af4e-9a2b40aa99e2',
          hostUrl: 'https://cdn.botpress.cloud',
        });
      }
    };

    // Cleanup function to remove the bot widget and scripts
   

    // Handle visibility of the bot icon and loading of scripts
    if (location.pathname === '/aide-support') {
      // Show the bot icon
      // Load inject script and config script
      loadScript('https://cdn.botpress.cloud/webchat/v2.1/inject.js', () => {
        loadScript('https://mediafiles.botpress.cloud/8dc4efc5-4a76-44f6-af4e-9a2b40aa99e2/webchat/v2.1/config.js', initializeWebchat);
      });
    } else {
      // Hide the bot icon and clean up
      cleanupChatbot();
    }

    // Cleanup on component unmount or path change
    return cleanupChatbot();

  }, [location.pathname]);

  return null; // Render nothing or a placeholder if needed
};

export default ChatbotReact;
