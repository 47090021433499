// Importations nécessaires
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import "../../styles/AdminLoginPage.css";
import { login as reduxLogin } from "../../redux/slices/authentificationSlice";
import Layout from "../Layout";
import { useAuth } from "../AuthContext";
import logo from "../../assets/Logo.png";

const AdminLoginPage = ({
  apiGatewayUrl,
  linkedInClientId,
  linkedInRedirectUri,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { login: contextLogin } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${linkedInClientId}&redirect_uri=${encodeURIComponent(
    linkedInRedirectUri
  )}&scope=openid%20profile%20email`;

  const handleLogin = async () => {
    setIsSubmitting(true); // Commencer le chargement
    const url = `${apiGatewayUrl}/admin-login/`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username: email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("data : ", data);
        localStorage.setItem("access_token", data.access_token); // Stockage du token JWT
        contextLogin({ user: data, userType: data.user_type });
        dispatch(
          reduxLogin({
            user: data,
            userType: data.user_type,
            email_validation: "OK",
          })
        );
        navigate("/admin-Dashboard");
      } else {
        const errorData = await response.json();
        setError(
          errorData.message ||
            "Identifiants incorrects ou problème avec le serveur."
        );
      }
    } catch (error) {
      console.log("Fetch error:", error); // Added console.log for better error tracking
      setError("Erreur technique. Veuillez réessayer plus tard.");
    } finally {
      setIsSubmitting(false); // Arrêter le chargement
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin();
  };

  return (
    <div className="loginbackgroundImage">
      <div className="container-fluid">
        <Link className="loginlogo" to="/">
          <img width="280px" src={logo} />{" "}
          {/* Ensure the logo path is correct */}
        </Link>
        <div className="Logincontainer">
          <div className="Loginform-container">
            <h2 className="logintext-center  mb-5">Connexion Administrateur</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleSubmit} className="form-custom">
              <div className="form-group mb-4">
                <input
                  type="email"
                  className="Loginform-control"
                  placeholder="Adresse email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="password"
                  className="Loginform-control"
                  placeholder="Mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="loginform-group btn-center mb-5">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Accéder"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLoginPage;
