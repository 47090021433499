import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import StarsIcon from '@mui/icons-material/Stars';

const Card = ({title, content}) => {
    return (
        <div className="group relative block h-fit lg:h-fit w-fit">
            <span className="absolute inset-0 border-2 border-dashed -border--clr-primary-300"></span>

            <div className="max-w-96 relative flex transform items-end border-2 -border--clr-primary-300 -bg--clr-neutral-50 transition-transform group-hover:-translate-x-2 group-hover:-translate-y-2">
                <div className="p-4 !pt-0 sm:p-6 lg:p-8">
                    {
                        title === "Je suis à la recherche de ..." ? (
                            <>
                                <SearchIcon sx={{ fontSize: '5em', color: 'var(--clr-primary-500)' }} />
                                <h2 className="mt-4 text-2xl font-semibold sm:text-2xl">Je suis actuellement à la recherche de...</h2>
                            </>
                        )
                        : title === "Préférences personnelles en matière d'emploi ..." ? (
                            <>
                                <HelpOutlineIcon sx={{ fontSize: '5em', color: 'var(--clr-primary-500)' }} />
                                <h2 className="mt-4 text-2xl font-semibold sm:text-2xl">Préférences personnelles en matière d'emploi...</h2>
                            </>
                        ) : (<>
                                <StarsIcon sx={{ fontSize: '5em', color: 'var(--clr-primary-500)' }} />
                                <h2 className="mt-4 text-2xl font-semibold sm:text-2xl">La chose incroyable que j'ai faite...</h2>
                            </>
                        )
                    }
                    <p className='w-60 font-medium'>
                        <span>... </span>
                        {content}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Card;