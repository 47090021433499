

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  step: 1,
  clientInscriptionFormData: {
    posteTitle: '',
    // experticesDem: [],
    selectedExpertises: [],
    projectType: '',
    missionStart: '',
    expertiseLevel: '',
    // permanentResource: '',
    timeEngagement:'',
    clientName: '',
    companyName: '',
    phoneNumber: '',
    country : 'FR',
    email: '',
    password: '',
    // confirmPassword: ''
  }
};

export const clientInscriptionFormSlice = createSlice({
  name: 'clientInscriptionForm',
  initialState,
  reducers: {
    setClientInscriptionFormData: (state, action) => {

      state.clientInscriptionFormData = { ...state.clientInscriptionFormData, ...action.payload };
    },
    nextStep: state => {
      state.step++;
    },
    prevStep: state => {
      state.step--;
    },
    resetClientInscriptionFormData: state => {
      state.step = 1;
      state.clientInscriptionFormData = {
        posteTitle: '',
        // experticesDem: [],
        selectedExpertises: [],
        email: '',
        password: '',
        projectType: '',
        missionStart: '',
        expertiseLevel: '',
        // permanentResource: '',
        timeEngagement:'',
        clientName: '',
        companyName: '',
        phoneNumber: '',
        country: '',
        // confirmPassword: ''

      };
    }
  }
});

export const { setClientInscriptionFormData, nextStep, prevStep, resetClientInscriptionFormData } = clientInscriptionFormSlice.actions;

export default clientInscriptionFormSlice.reducer;
