import React from 'react';
import { useState } from 'react';
import AdminCard from './admin/AdminCard';
import Freelancers from './freelancersTable';
import Providers from './providersTable';
import Workers from './workersTable';
import Clients from './clientsTable';


const Dashboard = () => {

  const [isAgents,setIsAgents] = useState('1')
 
  
  return (
    <div className=''>
      <AdminCard />
      <div className='w-full flex justify-center items-center mt-16'>
                <div className="w-[60%] flex flex-col items-center justify-center " >
                     <div className='w-full flex justify-start'>
                        <div className={`h-12 w-32 ${isAgents === '1' ? 'bg-[#10316B]' : 'bg-[#D9D9D9]'} rounded-l-full rounded-r-none cursor-pointer flex items-center justify-center text-center `} onClick={()=>setIsAgents('1')}>
                             <span className={`  ${isAgents === '1'? 'text-white' : 'text-black'}    `}>Freelancers</span>
                        </div>
                        <div className={`h-12 w-32 ${isAgents === '3' ? 'bg-[#10316B]' : 'bg-[#D9D9D9]'} rounded-l-none rounded-r-none cursor-pointer flex items-center justify-center text-center `} onClick={()=>setIsAgents('3')}>
                             <span className={`  ${isAgents === '3'? 'text-white' : 'text-black'}    `}>workers</span>
                        </div>
                        <div className={`h-12 w-32 ${isAgents === '4' ? 'bg-[#10316B]' : 'bg-[#D9D9D9]'} rounded-l-none rounded-r-none cursor-pointer flex items-center justify-center text-center `} onClick={()=>setIsAgents('4')}>
                             <span className={`  ${isAgents === '4'? 'text-white' : 'text-black'}    `}>Clients</span>
                        </div>
                         <div className={`h-12 w-32 ${isAgents === '2'? 'bg-[#10316B]' : 'bg-[#D9D9D9]'} rounded-r-full rounded-l-none cursor-pointer flex items-center justify-center text-center`} onClick={()=>setIsAgents('2')}>
                             <span className={`  ${isAgents === '2'? 'text-white' : 'text-black'}    `}>Providers</span>
                        </div>
                        
              </div>

                </div>
     </div>    
         
      {isAgents === '1' && <Freelancers/>}   
      {isAgents === '2' && <Providers/>} 
      {isAgents === '3' && <Workers/>}
      {isAgents === '4' && <Clients/>}
      


      
    </div>
  
  )
}  

export default Dashboard;