import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import background from "../../../assets/imageLogin.jpg";
import Logo from "../../../assets/logoWhite.png";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  

const BusinessPartnerSignUp = ({ apiGatewayUrl }) => {
  const { bp_id } = useParams(); // Récupérer bp_id depuis l'URL
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    nomComplet: '',
    email: '',
    phone: '',
    domaineCompetence1: '',
    domaineCompetence2: '',
    entreprise: 'RDSI',
    structureJuridique: 'SARL',
    rib: '',
    rib2: '',
    password: '',
    status: 'active', // Valeur par défaut pour le statut
  });

  // Récupérer les données via l'API lors du chargement du composant
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiGatewayUrl}/get-businesspartner-info/?id_bp=${bp_id}`);
        if (response.ok) {
          const data = await response.json();
          setFormData(prevData => ({
            ...prevData,
            nomComplet: data.nom + ' ' + data.prenom,
            email: data.email || '',
            phone: data.phone || '',
            domaineCompetence1: data.domaineCompetence1 || '',
            domaineCompetence2: data.domaineCompetence2 || '',
          }));
        } else {
          toast.error("Erreur lors de la récupération des données");
        }
      } catch (error) {
        toast.error("Une erreur est survenue lors de la récupération des données");
      }
    };

    fetchData();
  }, [apiGatewayUrl, bp_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Vérifier que les RIB correspondent
    if (formData.rib !== formData.rib2) {
      toast.error("Les RIB ne correspondent pas !");
      return;
    }

    // Deuxième fetch pour l'inscription
    try {
      const response = await fetch(`${apiGatewayUrl}/complete-registration/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id_bp: bp_id,
          password: formData.password,
          structure_juridique: formData.structureJuridique,
          status: formData.status,
          competence1: Number(formData.domaineCompetence1), // Assurez-vous que ce soit un entier
          competence2: Number(formData.domaineCompetence2), // Assurez-vous que ce soit un entier
          rib: formData.rib,
          entreprise: formData.entreprise,
        }),
      });

      if (response.ok) {
        // Stocker le bp_id dans le localStorage
        localStorage.setItem('bp_id', bp_id);

        toast.success("Inscription complétée avec succès !");
        navigate("/dashboard-bp"); // Naviguer vers l'espace businessPartner
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || "Erreur lors de l'inscription");
      }
    } catch (error) {
      toast.error("Une erreur est survenue lors de l'inscription");
    }
  };

  return (
    <div className="flex h-screen overflow-hidden text-black">
        <div
          className="w-full md:w-1/2 bg-cover bg-center relative"
          style={{ background: `url(${background})` }}
        >
          <div className="absolute top-8 left-8">
            <img src={Logo} alt="Logo" className="w-30 h-10" /> {/* Larger Logo */}
          </div>
          <div className="absolute inset-0 flex flex-col justify-center px-8 md:px-12">
            <h1 className="text-3xl md:text-4xl font-bold mb-4">
              Partenaire Business, bienvenue sur <span className="text-white">TeraTalenT</span> !
            </h1>
            <p className="text-sm md:text-lg leading-relaxed">
              Nous vous félicitons d'avoir été accepté en tant que l'un de nos partenaires de confiance. À cette étape, 
              nous vous invitons à remplir ce formulaire afin de compléter la création de votre compte. Veuillez fournir les informations 
              demandées pour nous aider à mieux vous connaître et faciliter notre collaboration.
              <br /><br />
             <span  className="text-white">Merci et nous nous réjouissons de travailler avec vous !</span> 
            </p>
          </div>
        </div>
      <div className="w-full md:w-1/2 flex items-center justify-center bg-white overflow-y-auto pt-96">
        <form className="w-full max-w-md p-6 md:px-8" onSubmit={handleSubmit}>
          <h2 className="text-2xl md:text-3xl font-bold mb-6 text-gray-800">Créer votre compte</h2>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nomComplet">
              Nom Complet <span className="text-red-500">*</span>
            </label>
            <input
              id="nomComplet"
              name="nomComplet"
              type="text"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
              value={formData.nomComplet}
              readOnly
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email <span className="text-red-500">*</span>
            </label>
            <input
              id="email"
              name="email"
              type="email"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
              value={formData.email}
              readOnly
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
              Téléphone <span className="text-red-500">*</span>
            </label>
            <input
              id="phone"
              name="phone"
              type="text"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
              value={formData.phone}
              readOnly
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="domaineCompetence1">
              Domaine de compétence 1 <span className="text-red-500">*</span>
            </label>
            <select
              id="domaineCompetence1"
              name="domaineCompetence1"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
              value={formData.domaineCompetence1}
              onChange={handleChange}
              required
            >
              <option value="">Sélectionner</option>
              <option value="1">Cyber Sécurité</option>
              <option value="2">Business Intelligence</option>
              <option value="3">Intelligence artificielle</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="domaineCompetence2">
              Domaine de compétence 2 <span className="text-red-500">*</span>
            </label>
            <select
              id="domaineCompetence2"
              name="domaineCompetence2"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
              value={formData.domaineCompetence2}
              onChange={handleChange}
              required
            >
              <option value="">Sélectionner</option>
              <option value="1">Cyber Sécurité</option>
              <option value="2">Business Intelligence</option>
              <option value="3">Intelligence artificielle</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="entreprise">
              Nom d'entreprise <span className="text-red-500">*</span>
            </label>
            <input
              id="entreprise"
              name="entreprise"
              type="text"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
              value={formData.entreprise}
              onChange={handleChange}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="structureJuridique">
              Structure Juridique <span className="text-red-500">*</span>
            </label>
            <select
              id="structureJuridique"
              name="structureJuridique"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
              value={formData.structureJuridique}
              onChange={handleChange}
              required
            >
              <option value="SARL">SARL</option>
              <option value="SAS">SAS</option>
              <option value="SA">SA</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="rib">
              RIB <span className="text-red-500">*</span>
            </label>
            <input
              id="rib"
              name="rib"
              type="text"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
              value={formData.rib}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="rib2">
              Confirmer le RIB <span className="text-red-500">*</span>
            </label>
            <input
              id="rib2"
              name="rib2"
              type="text"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
              value={formData.rib2}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Mot de passe <span className="text-red-500">*</span>
            </label>
            <input
              id="password"
              name="password"
              type="password"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>

          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Compléter l'inscription
            </button>
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default BusinessPartnerSignUp;
