import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { API_GATEWAY_URL } from "../constants";
import { useAuth } from "./AuthContext";
import axios from "axios";
import Navbar from "./Navbarr";
import ClientCard from "./ClientCard";

const TicketDetail = () => {
  const { user } = useAuth();
  const { ticketId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const [loading, setLoading] = useState(true);
  const chatEndRef = useRef(null);

  const fetchMessages = async () => {
    try {
      const response = await axios.get(
        `${API_GATEWAY_URL}/api/tickets/${ticketId}/messages`,
        {
          headers: { Authorization: `Bearer ${user.access_token}` },
        }
      );
      setMessages(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des messages :", error);
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = async () => {
    const formData = new FormData();
    formData.append("ticket", ticketId);
    formData.append("content", newMessage);
    if (selectedFile) {
      formData.append("file", selectedFile);
    }

    try {
      const response = await axios.post(
        `${API_GATEWAY_URL}/api/tickets/${ticketId}/messages/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = await response.data;
      handleSendMessage(data);
      setNewMessage("");
      setSelectedFile(null);
    } catch (error) {
      console.error("Erreur réseau", error);
    }
  };

  const handleSendMessage = async (messageData) => {
    setMessages((prev) => [...prev, messageData]);
    setNewMessage("");
    setSelectedFile(null);
    scrollToBottom();
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    fetchMessages();
  }, [ticketId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const getFileUrl = (filePath) => {
    console.log("File path:", `${API_GATEWAY_URL}${filePath}`);
    return `${API_GATEWAY_URL}${filePath}`;
  };

  const handleFileDownload = async (file) => {
    try {
      const access_token = localStorage.getItem("access_token");
      const response = await axios.get(getFileUrl(file), {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        responseType: "blob",
      });
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));

      const a = document.createElement("a");
      a.href = fileURL;
      a.download = file;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(fileURL);
    } catch (error) {
      console.error("Erreur lors du téléchargement du fichier:", error);
      alert("Une erreur s'est produite lors du téléchargement du fichier.");
    }
  };

  if (loading) return <p>Chargement des messages...</p>;

  return (
    <div className="pw-page-layout ">
      <Navbar />
      <ClientCard />
      <div className="chat-container flex flex-col ml-8 h-screen bg-gray-100 w-full px-16">
        <h1 className="text-xl font-bold mb-4">
          Détails du Ticket #{ticketId}
        </h1>
        <div className="messages-list flex-1 overflow-y-auto space-y-4 p-4 bg-white rounded-lg shadow-lg">
          <div className="flex flex-col space-y-4">
            {messages.map((msg) => (
              <div
                key={msg.id || msg.timestamp}
                className={`message-item p-3 rounded-lg max-w-md ${
                  msg.is_admin
                    ? "bg-gray-300 self-start text-left"
                    : "bg-blue-500 text-white self-end text-right"
                }`}
                style={{ width: "fit-content", maxWidth: "60%" }}
              >
                <p className="mb-2">{msg.content}</p>
                {msg.file && (
                  <button
                    class="bg-transparent underline focus:outline-none"
                    onClick={() => {
                      handleFileDownload(msg.file);
                    }}
                  >
                    Télécharger le Fichier
                  </button>
                )}
                <p className="text-xs text-gray-200 mt-2">
                  {new Date(msg.timestamp).toLocaleString()}
                </p>
              </div>
            ))}
          </div>

          <div ref={chatEndRef} />
        </div>

        <div className="message-input flex items-center mt-4 p-2 bg-white rounded-lg shadow-lg">
          <input
            type="text"
            className="flex-1 p-2 border rounded-lg focus:outline-none"
            placeholder="Écrivez un message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          />
          <input type="file" className="ml-2" onChange={handleFileChange} />
          <button
            onClick={sendMessage}
            className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-lg"
          >
            Envoyer
          </button>
        </div>
      </div>
    </div>
  );
};

export default TicketDetail;
