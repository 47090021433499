import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API_GATEWAY_URL } from '../constants';
import { useAuth } from './AuthContext';
import LoadingSpinner from './LoadingSpinner';
import AdminCard from './admin/AdminCard';
import '../styles/AdminDashboardPage.css';
import '../styles/AdminCard.css';

const AdminProvidersTable = () => {
  const navigate = useNavigate();
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  const fetchProviders = async () => {
    console.log(user);
    if (!user) {
      setError('Aucun utilisateur connecté');
      setLoading(false);
      navigate('/admin-login');
      return;
    }
    const token = user.access_token;
    try {
      const response = await axios.get(`${API_GATEWAY_URL}/admin-provider-requests/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProviders(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  const handleDelete = async (providerId) => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: "Vous ne pourrez pas annuler cette action!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer!',
      cancelButtonText: 'Annuler',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${API_GATEWAY_URL}/providers/${providerId}/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          });

          setProviders(providers.filter((provider) => provider.id !== providerId));

          Swal.fire('Supprimé!', 'Le fournisseur a été supprimé.', 'success');
        } catch (err) {
          setError(err.message);
          Swal.fire('Erreur!', 'Une erreur s\'est produite lors de la suppression du fournisseur.', 'error');
        }
      }
    });
  };

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Nom', width: 150 },
    { field: 'address', headerName: 'Adresse', width: 150 },
    { field: 'city', headerName: 'Ville', width: 150 },
    { field: 'country', headerName: 'Pays', width: 150 },
    { field: 'phone', headerName: 'Téléphone', width: 150 },
    { field: 'website', headerName: 'Site Web', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width:300,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(`/modify-provider/${params.row.id}`)}
          >
            Modifier
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDelete(params.row.id)}
          >
            Supprimer
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="admin-dashboard">
      <div className="admin-dashboard-card">
        <AdminCard />
      </div>
      <div className="admin-dashboard-content">
        <div style={{ height: 600, width: '100%' }}>
          <DataGrid rows={providers} columns={columns} pageSize={10} />
        </div>
      </div>
    </div>
  );
};

export default AdminProvidersTable;
