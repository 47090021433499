import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useAuth } from "../AuthContext";
import { API_GATEWAY_URL } from "../../constants";
import LoadingSpinner from "../LoadingSpinner";
import Swal from "sweetalert2";
Modal.setAppElement("#root"); // Set the root element for accessibility

function FreelancerSolde() {
  const { user } = useAuth();
  const [solde, setSolde] = useState(null);
  const [currency, setCurrency] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false); // State to handle modal visibility
  const [rib, setRib] = useState("");
  const [label, setLabel] = useState("");
  const [ribSaved, setRibSaved] = useState(false);
  const [edit, setEdit] = useState(false);

  // handle click on sauvegarder to update rib value in portefeuille
  const handleSave = async () => {
    try {
      console.log("Save");
      if (rib === "") {
        setError("Veuillez saisir votre RIB");
        Swal.fire("Erreur", `${error}`, "error");
        return;
      }
      const response = await fetch(`${API_GATEWAY_URL}/solde/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({
          rib: rib,
        }),
      });
      if (!response.ok) {
        if (response.status === 401) {
          setError("Pas autorisé, veuillez vous reconnecter.");
          Swal.fire("Erreur", `${error}`, "error");
        } else {
          setError("pas capable de sauvegarder le RIB");
          Swal.fire("Erreur", `${error}`, "error");
        }
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log(data);
      setRibSaved(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRetrait = async () => {
    try {
      console.log("Retrait");
      if (!rib || !label) {
        setError("Veuillez saisir votre RIB et libellé");
        Swal.fire("Erreur", `${error}`, "error");
        return;
      }
      const response = await fetch(`${API_GATEWAY_URL}/retrait/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({
          devise: solde,
          rib: rib,
          label: label,
        }),
      });
      if (!response.ok) {
        if (response.status === 401) {
          setError("Pas autorisé, veuillez vous reconnecter.");
          Swal.fire("Erreur", `${error}`, "error");
        } else {
          setError("Pas capable de retirer le solde");
          Swal.fire("Erreur", `${error}`, "error");
        }
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log(data);
      setSolde(0);
      setModalIsOpen(false); // Close the modal on successful retrait
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let isMounted = true;
    console.log("User:", user);
    console.log("Access Token:", user ? user.access_token : "No access token");

    const fetchSolde = async () => {
      try {
        const response = await fetch(`${API_GATEWAY_URL}/solde/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.access_token}`,
          },
        });

        if (!response.ok) {
          if (response.status === 401) {
            setError("Accès non autorisé, veuillez vous reconnecter.");
            Swal.fire("Erreur", `${error}`, "error");
          } else {
            setError("Pas capable de récupérer les données du solde");
            Swal.fire("Erreur", `${error}`, "error");
          }
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log(data);

        setSolde(data.solde);
        setCurrency(data.currency);
        if (!data.currency) {
          setCurrency("DZD");
        }
        setRib(data.rib);
        if (data.rib) {
          setRibSaved(true);
        }
      } catch (error) {
        if (isMounted) {
          setError(error.message);
          Swal.fire("Erreur", `${error}`, "error");
          console.error(error);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchSolde();

    return () => {
      isMounted = false;
    };
  }, [user]);

  return (
    <div className="solde-container w-full">
      {loading ? (
        <LoadingSpinner isLoading={loading} />
      ) : (
        <div className="p-4">
          <div className="w-full flex-row gap-8 mb-2.5 ">
            <div className="w-full flex-col justify-start items-start gap-8 flex">
              {ribSaved && !edit ? (
                <div className=" w-full flex justify-between self-stretch text-black text-xl font-medium font-Poppins p-10 bg-slate-200 rounded-md">
                  <div>
                    <div className="self-stretch text-black text-xl font-medium font-Poppins">
                      RIB
                    </div>
                    <div className="text-gray-800 text-2xl font-bold">
                      {rib}
                    </div>
                  </div>
                  <div
                    className="px-6 py-1.5 bg-[#0056b3] rounded-lg justify-center items-center gap-6 inline-flex"
                    onClick={() => setEdit(true)}
                  >
                    <div className="text-white text-base font-normal font-Poppins">
                      Modifier
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-full flex justify-between p-10 items-center  bg-slate-200 rounded-md">
                  <div>
                    <label className="self-stretch text-black text-xl font-normal font-Poppins">
                      RIB
                    </label>
                    <input
                      type="text"
                      className="border rounded-md px-3 py-2 hover:border-[#0056b3] focus:outline-[#0056b3]"
                      placeholder="Entrer votre RIB"
                      value={rib}
                      onChange={(e) => setRib(e.target.value)}
                    />
                  </div>
                  <div className="flex gap-4 mt-4">
                    <button
                      className="bg-[#0056b3] px-6 py-1.5 text-white rounded-lg"
                      onClick={handleSave}
                    >
                      Sauvegarder
                    </button>
                    {/* Only show "Annuler" when ribSaved is true and edit is true */}
                    {ribSaved && (
                      <button
                        className="bg-gray-300 px-6 py-1.5 text-black rounded-lg"
                        onClick={() => setEdit(false)}
                      >
                        Annuler
                      </button>
                    )}
                  </div>
                </div>
              )}
              <div className="w-full flex flex-row justify-between p-10 bg-slate-200 rounded-md items-center">
                <div>
                  <div className="self-stretch text-black text-xl font-medium font-Poppins">
                    Solde
                  </div>

                  {solde !== null ? (
                    <div className="self-stretch text-black text-xl font-bold font-Poppins">
                      {solde} {currency}
                    </div>
                  ) : (
                    <p>Solde non existant</p>
                  )}
                </div>
                <div
                  className=" px-6 py-1.5 bg-[#0056b3] rounded-lg justify-center items-center gap-6 inline-flex ml-4"
                  onClick={() => setModalIsOpen(true)}
                >
                  <div className="text-white text-base font-normal font-Poppins">
                    Retirer les fonds
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Retrait Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(5px)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            height: "60%",
          },
        }}
      >
        <div className="flex flex-col justify-center items-center w-full h-full gap-8">
          <img
            src={require("../../assets/paiement/Cross.svg").default}
            alt="cross"
            className="absolute top-2 right-2"
            onClick={() => setModalIsOpen(false)}
          />
          <div className="text-center text-black text-xl font-medium font-Poppins">
            Veuillez saisir votre RIB et libellé{" "}
          </div>
          <form className="flex flex-col gap-8 w-full">
            <input
              type="text"
              className="w-full border rounded-md px-3 py-2 hover:border-[#0056b3] focus:outline-[#0056b3]"
              placeholder="Libellé "
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
          </form>
          <div className="flex flex-row-reverse w-full justify-between p-8 ">
            <div
              className="px-6 py-1.5 bg-[#0056b3] rounded-lg justify-center items-center gap-6 inline-flex text-white text-base font-normal font-Poppins"
              onClick={handleRetrait}
            >
              Continuer
            </div>
            <div
              className="px-6 py-1.5 text-[#0056b3] border border-[#0056b3] rounded-lg justify-center items-center gap-6 inline-flex bg-white text-base font-normal font-Poppins"
              onClick={() => setModalIsOpen(false)}
            >
              Annuler
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default FreelancerSolde;
