import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/fr';
import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import '../../index.css';

const currentDay = dayjs();

function ServerDay(props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const mission = highlightedDays.find(highlightedDay =>
        day.isSame(highlightedDay.day, 'day')
    );

    let badgeContent = '';
    let bgColor = 'transparent';

    if (mission) {
        if (mission.part_of_day === 'Matin') {
            badgeContent = '🅜';
            bgColor = 'var(--clr-danger-50)';
        } else if (mission.part_of_day === 'Après-midi') {
            badgeContent = '🅐';
            bgColor = 'var(--clr-warning-50)';
        } else if (mission.part_of_day === 'Journée entière') {
            badgeContent = '🅟';
            bgColor = 'var(--clr-primary-50)';
        }
    }

    return (
        <Badge
            key={day.toString()}
            overlap="circular"
            badgeContent={badgeContent}
            sx={{
                bgcolor: bgColor,
                borderRadius: '50%',
                width: 'min-content',
                height: 'min-content',
            }}
        >
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
        </Badge>
    );
}

moment.locale('fr');
const localizer = momentLocalizer(moment);

// Function to transform backend data including recurrence
const transformAvailabilityData = (data) => {
    if (!Array.isArray(data)) {
        return [];
    }

    let transformedData = [];

    data.forEach(item => {
        const startDate = dayjs(item.start).startOf('day');
        const endDate = dayjs(item.end).endOf('day');
        const frequency = item.recurrence?.frequency;
        const days = item.recurrence?.days || [];
        const untilDate = dayjs(item.recurrence?.until);

        if (frequency === 'weekly') {
            let current = startDate;
            while (current.isBefore(untilDate) || current.isSame(untilDate)) {
                if (days.includes(current.format('dddd').toLowerCase())) {
                    transformedData.push({
                        day: current,
                        part_of_day: item.part_of_day === 'morning' ? 'Matin' :
                                    item.part_of_day === 'afternoon' ? 'Après-midi' :
                                    'Journée entière'
                    });
                }
                current = current.add(1, 'day');
            }
        } else {
            transformedData.push({
                day: startDate,
                part_of_day: item.part_of_day === 'morning' ? 'Matin' :
                            item.part_of_day === 'afternoon' ? 'Après-midi' :
                            'Journée entière'
            });
        }
    });
    return transformedData;
};

export default function DateCalendarServerRequest({ AvailabilityDays }) {
    const [isLoading, setIsLoading] = useState(false);
    const [highlightedDays, setHighlightedDays] = useState([]);
    const [selectedDate, setSelectedDate] = useState(dayjs()); 
    
    const fetchHighlightedDays = () => {
        setIsLoading(true);
        setTimeout(() => {
            const allHighlightedDays = transformAvailabilityData(AvailabilityDays);
            setHighlightedDays(allHighlightedDays);
            setIsLoading(false);
        }, 500);
    };
    
    useEffect(() => {
        fetchHighlightedDays();
        return () => {
            // Cleanup function if necessary
        };
    }, [AvailabilityDays]);
    
    const handleMonthChange = (date) => {
        // Handle month change if needed
    };
    
    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };
    
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
                value={selectedDate} // Controlled value
                onChange={handleDateChange} // Handle date change
                style={{ border: '2px solid var(--clr-neutral-700)' }}
                loading={isLoading}
                onMonthChange={handleMonthChange}
                slots={{
                    day: ServerDay,
                }}
                slotProps={{
                    day: {
                        highlightedDays,
                        sx: {
                            marginLeft: '1px',
                            marginTop: '2px',
                        }
                    },
                }}
            />
        </LocalizationProvider>
    );
}







