import React from 'react';
import '../../index.css';

const ProgressBar = ({ value }) => {
    return (
        <div className='w-1/3'>
            <span
                role="progressbar"
                aria-labelledby="ProgressLabel"
                aria-valuenow={value}
                aria-valuemin="0"
                aria-valuemax="100"
                className="block rounded-sm bg-gray-50 border -border--clr-primary-400 w-full"
            >
                <span className="block h-2 rounded-sm -bg--clr-primary-400" style={{ width: `${value}%` }}></span>
            </span>
        </div>
    );
};

export default ProgressBar;
