import React, { useState } from 'react';
import { API_GATEWAY_URL } from '../constants';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';

const ProviderContactAddPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneCountryCode, setPhoneCountryCode] = useState('+213');
  const [phoneLocalNumber, setPhoneLocalNumber] = useState('');
  const [contactType, setContactType] = useState('');
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});

  const validatePhoneNumber = (number) => {
    const pattern = /^\+?\d{10,15}$/;
    return pattern.test(number);
  };

  const checkErrors = () => {
    const newErrors = {};
    if (!phoneLocalNumber || !validatePhoneNumber(phoneCountryCode + phoneLocalNumber)) {
      newErrors.phoneLocalNumber = 'Numéro de téléphone invalide.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!checkErrors()) return;

    const fullPhoneNumber = phoneCountryCode + phoneLocalNumber;

    try {
      const response = await fetch(`${API_GATEWAY_URL}/provider-contact-create/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
        body: JSON.stringify({
          type: contactType,
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone_number: fullPhoneNumber,
        }),
      });
      if (response.ok) {
        navigate('/provider-profile');
      } else {
        throw new Error('Erreur lors de l\'ajout du contact');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Ajouter un contact</h2>
      {error && <p style={styles.error}>{error}</p>}
      <form onSubmit={handleSubmit} style={styles.form}>
        <label style={styles.label}>
          Type de contact:
          <select value={contactType} onChange={(e) => setContactType(e.target.value)} style={styles.input}>
            <option value="">Sélectionnez le type</option>
            <option value="A">Administratif</option>
            <option value="T">Technique</option>
          </select>
        </label>

        <label style={styles.label}>
          Prénom:
          <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} style={styles.input} />
        </label>

        <label style={styles.label}>
          Nom:
          <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} style={styles.input} />
        </label>

        <label style={styles.label}>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} style={styles.input} />
        </label>

        <div style={styles.phoneContainer}>
          <select value={phoneCountryCode} onChange={(e) => setPhoneCountryCode(e.target.value)} style={styles.phoneCountryCode}>
            {/* Ici, vous pouvez lister d'autres indicatifs téléphoniques */}
            <option value="+213">+213 (Algérie)</option>
            <option value="+33">+33 (France)</option>
          </select>
          <input 
            type="text" 
            value={phoneLocalNumber} 
            onChange={(e) => setPhoneLocalNumber(e.target.value)} 
            placeholder="Numéro de téléphone local" 
            style={styles.phoneLocalNumber} 
          />
        </div>
        {errors.phoneLocalNumber && <div style={styles.error}>{errors.phoneLocalNumber}</div>}
		{errors.phoneLocalNumber && <div style={styles.error}>{errors.phoneLocalNumber}</div>}

        <button type="submit" style={styles.button}>Ajouter le contact</button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '600px',
    margin: 'auto',
    backgroundColor: '#f8f8f8',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  title: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    marginBottom: '15px',
  },
  input: {
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    width: '100%',
  },
  button: {
    backgroundColor: '#007BFF',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    textAlign: 'center',
  },
  phoneContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0',
  },

  phoneCountryCode: {
    flex: 1, // Ajustez selon le besoin pour l'indicatif téléphonique
    padding: '10px',
    margin: '0 10px 0 0',
    borderRadius: '4px',
    border: '1px solid #ddd',
  },

  phoneLocalNumber: {
    flex: 3, // Ajustez selon le besoin pour le numéro de téléphone local
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ddd',
  },

};

export default ProviderContactAddPage;
