import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import NavBarComponent from "./NavBar_and_Footer/NavBarComponent";
import FooterComponent from "./NavBar_and_Footer/FooterComponent";
import ClientCard from "./ClientCard";

const Layout = ({ title, content, children }) => (
  <>
    <Helmet>
      <title>TeraTalenT</title>
      <meta name="description" content={content} />
    </Helmet>
    <Navbar />

    <div>{children}</div>
    <FooterComponent />
  </>
);

export default Layout;
