// FooterComponent.jsx
import React from "react";
import "../../styles/navbarAndFooter.css";
import Logo from "../../assets/Logo2.png";
import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";

const FooterComponent = () => {
  return (
    <footer>
      <div className="flex flex-col  px-16 max-sm:px-4 sm:px-32 py-3">
        <div className="flex flex-row flex-wrap justify-between  font-medium  max-w-full">
          <div>
            <img
              loading="lazy"
              src={Logo}
              alt="TeraTalenT Logo"
              className="object-contain max-w-full max-2xl:w-[200px] 2xl:w-[280px]"
            />
            <p className="font-body text-primary-800 text-base text-fluid-md">
              Votre partenaire technologique de confiance
            </p>
          </div>

          <div flex>
            {/* Information */}
            <div className="flex flex-col text-fluid-md space-y-2">
              <p className="font-display font-bold text-primary-800 ">
                Contactez nous
              </p>
              <p className="font-body font-regular text-primary-800 ">
                1234 Rue ABCD, Paris, France
              </p>
              <p className="font-body font-regular text-primary-800">
                contact@teratalent.com
              </p>
              <p className="font-body font-regular text-primary-800 ">
                +33 000 000 000
              </p>
            </div>
            {/* Social Media */}
            <div className="flex space-x-4 pt-3">
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook className="text-primary-800 max-sm:h-8 max-sm:w-8 h-12 w-12" />
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter className="text-primary-800 max-sm:h-8 max-sm:w-8 h-12 w-12" />
              </a>
              <a
                href="https://www.linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin className="text-primary-800 max-sm:h-8 max-sm:w-8 h-12 w-12" />
              </a>
            </div>
          </div>
        </div>
        {/*footer copyright */}
        <div className="flex flex-col text-fluid-sm">
          <hr className="mt-5 w-full border-t-1  max-md:max-w-full border-primary-800" />
          <p className="text-center  font-display font-medium text-fluid-xs text-primary-800">
            © {new Date().getFullYear()} TeraTalenT - Tous droits réservés
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
