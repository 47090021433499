import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/ProviderCard.css';
import { useAuth } from './AuthContext';
import { Link } from 'react-router-dom';
import logoWhite from '../assets/logoWhite.png';

const ProviderCard = ({ providerData }) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  
  const [isVisible, setIsVisible] = useState(true);
  const [showMissionSubmenu, setShowMissionSubmenu] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const toggleMissionSubmenu = () => {
    setShowMissionSubmenu(!showMissionSubmenu);
  };

  const getInitials = (name) => name.split(' ').map((n) => n[0]).join('');
  
  const handleLogout = () => {
    logout();
    navigate('/');
  };
  
  const navigateToProfile = () => {
    navigate('/provider-profile');
  };

  const navigateToResources = () => {
    navigate('/provider-resources');
  };
  
  const navigateToExpertises = () => {
    navigate('/provider-expertises');
  };

  const navigateToDashboard = () => {
    navigate('/provider-welcome');
  };


  const navigateToPendingMissions = () => {
    navigate('/provider-missions/pending');
  };

  const navigateToOngoingMissions = () => {
    navigate('/provider-missions/ongoing');
  };

  const navigateToCompletedMissions = () => {
    navigate('/provider-missions/completed');
  };
    const navigateToPlanning = () => {
    navigate('/planning');
  };

  const getValidationType = (providerData) => {
    if (providerData.direct_validation) {
      return 'Validation directe';
    }
    if (providerData.deferred_validation) {
      return 'Validation différée';
    }
    return 'Type de validation non défini';
  };

  const getSchemaType = (providerData) => {
    if (providerData.schemaressource) {
      return 'Schéma Ressource';
    }
    if (providerData.schemaexpertise) {
      return 'Schéma Expertise';
    }
    return 'Type de schéma non défini';
  };

  return (
    <div className={`PC-container ${isVisible ? "" : "hidden"}`}>
      <Link className='img' to='#'>
        <img width='200px' src={logoWhite} style={{ alignSelf: 'flex-start' }}/> 
      </Link>
      <button className={`toggle-button ${isVisible ? "hide" : "show"}`} onClick={toggleVisibility}>
        <i className={`fas ${isVisible ? "fa-arrow-left" : "fa-arrow-right"}`}></i>
      </button>

      {providerData && (
        <div>
          <div className="pc-company-info-card">
            <div className="pc-initials-icon">{getInitials(providerData.name)}</div>
            <h3 className="pc-company-name">{providerData.name}</h3>
            <p className="pc-status">Statut: {providerData.provider_status}</p>
            <p className="pc-size">Effectif: {providerData.size}</p>
            <p className="pc-validation-type">{getValidationType(providerData)}</p>
            <p className="pc-schema-type">{getSchemaType(providerData)}</p>
            <button onClick={navigateToProfile} className="pc-profile-button">
              <i className="fa fa-user"></i> Profil
            </button>
          </div>
          <div className="pc-company-info-footer">
            <button onClick={navigateToDashboard} className="pc-full-width-button">
              <i className="fa fa-dashboard"></i> Tableau de bord
            </button>
              <button onClick={navigateToPlanning} className="pc-full-width-button">
                <i className="fa fa-brain"></i> Disponibilités
            </button>

            <button onClick={toggleMissionSubmenu} className="pc-full-width-button">
              <i className="fa fa-tasks"></i> Missions
            </button>
            {showMissionSubmenu && (
              <div className="pc-submenu">
                <button onClick={navigateToPendingMissions} className="pc-submenu-button">
                  Missions à valider
                </button>
                <button onClick={navigateToOngoingMissions} className="pc-submenu-button">
                  Missions en cours
                </button>
                <button onClick={navigateToCompletedMissions} className="pc-submenu-button">
                  Missions terminées
                </button>
              </div>
            )}
            <button onClick={navigateToResources} className="pc-full-width-button">
              <i className="fa fa-users"></i> Équipe
            </button>
            <button onClick={navigateToExpertises} className="pc-full-width-button">
              <i className="fa fa-brain"></i> Expertises
            </button>
          </div>
          <button onClick={handleLogout} className="pc-logout-button">
            Déconnexion
          </button>
        </div>
      )}
    </div>
  );
};

export default ProviderCard;
