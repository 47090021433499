import React from 'react'
import Badge from './Badge';

const Badges = ({ badgeTitles = [] }) => {
    return (
        <div className="flex gap-3 flex-wrap w-full">
            {badgeTitles.length > 0 ? (
                badgeTitles.map((badge, index) => (
                    <Badge 
                        key={index}
                        badgeTitle={`${badge.expertisename} - ${badge.level}`}
                    />
                ))
            ) : (
                <></>
            )}
        </div>
    );
}

export default Badges;