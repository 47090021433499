// PaymentFormContent.jsx
import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  IbanElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { API_GATEWAY_URL } from "../../constants";
import { useEffect } from "react";
import { useAuth } from "../AuthContext";

const stripePromise = loadStripe("pk_test_51PwQxFCNATD2pskqyuq7r52HaRlzGmB9tbljsFpUoRvAg1yd26vyM738auB5jaNovSFRKbbTdI6N83tZpgH4oDyX00hRXSYxaM");

const PaymentFormContent = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [success, setSuccess] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("sepa");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [authorizeDebit, setAuthorizeDebit] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [dataClient, setDataClient] = useState(null);
  const amount = 5000;
  const {user} = useAuth();
  const [userId, setUserId] = useState('')
  const [fullName, setFullName] = useState('')
  const[email,setEmail]=useState('')

  const ibanOptions = {
    supportedCountries: ["SEPA"],
    style: {
      base: {
        fontSize: "16px",
        color: "#32325d",
        "::placeholder": {
          color: "#A0A0A0",
        },
      },
      invalid: {
        color: "#fa755a",
      },
    },
  };

  useEffect(() => {
    const fetchData = async () => {

      if (!user) {
        console.log("User is not authenticated.");
        return;
      }

      try {
        const profileResponse = await fetch(
          `${API_GATEWAY_URL}/client-profile/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );


        if (!profileResponse.ok)
          throw new Error(
            "Erreur lors de la récupération des données du profil."
          );

        const profileData = await profileResponse.json();
        setDataClient(profileData)
        const user_id = profileData.user
        setUserId(user_id)
        setEmail(profileData.email)
        setLoading(false)
        
      } catch (err) {
        console.log(err.massege)
      }
    }

    fetchData()

  }, [user])

  const handleSubmit = async (event) => {
    console.log(email)
    event.preventDefault();
    setLoading(true);
    setError(null);
  
    if (!stripe || !elements) {
      setError("Stripe.js n'est pas encore chargé. Veuillez réessayer.");
      setLoading(false);
      return;
    }
  
    // Validation des cases à cocher
    if (paymentMethod === "sepa" && (!authorizeDebit || !acceptTerms)) {
      setError("Vous devez autoriser le débit automatique et accepter les termes.");
      setLoading(false);
      return;
    }
  
    try {
      let result;
      let last4Digits;
  
      if (paymentMethod === "sepa") {
        const ibanElement = elements.getElement(IbanElement);
        result = await stripe.createPaymentMethod({
          type: "sepa_debit",
          sepa_debit: ibanElement,
          billing_details: {
            name: fullName,
            email: dataClient.email,
          },
        });
  
        if (result.paymentMethod && result.paymentMethod.sepa_debit) {
          last4Digits = result.paymentMethod.sepa_debit.last4; 
        }
  
      } else if (paymentMethod === "card") {
        result = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: fullName,
            email: dataClient.email,
          },
        });
  
        if (result.paymentMethod && result.paymentMethod.card) {
          last4Digits = result.paymentMethod.card.last4; // Extract last 4 digits of card
        }
      }
  
      if (result.error) {
        setError(result.error.message);
        setLoading(false);
        return;
      }
  
      const paymentMethodId = result.paymentMethod.id;
      console.log("paymentmethodid ",paymentMethodId)
      console.log(email)
      props.onNext(paymentMethodId, paymentMethod, last4Digits,email);
    } catch (err) {
      setError("Une erreur s'est produite lors du traitement du paiement.");
      setLoading(false);
    }
  };
  
  return (
    <form
      className="flex flex-col w-full h-full justify-center items-center gap-4"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col justify-between w-full h-full">
        <div className="flex-grow">
          <div className="text-[#1e1e1e] text-lg font-bold font-Poppins">
            Informations de paiement
          </div>
          <div className="text-[#1e1e1e] text-xs font-normal font-Poppins mb-4">
            Toutes les transactions sont sécurisées et protégées
          </div>
          <div className="sm:pr-8">
            {/* SEPA Payment */}
            <div
              className={`w-full px-4 py-3 rounded border items-center justify-between inline-flex ${paymentMethod === "sepa"
                  ? "bg-[#eff5ff] border-[#0056b3]"
                  : "bg-white border-[#a0a0a0]"
                }`}
            >
              <div className="flex gap-4">
                <input
                  type="radio"
                  name="paymentMethod"
                  id="sepaPayment"
                  checked={paymentMethod === "sepa"}
                  onChange={() => setPaymentMethod("sepa")}
                />
                <div className="text-[#1e1e1e] text-sm font-normal font-Poppins">
                  Prélèvement SEPA
                </div>
              </div>
              <div className="w-9 h-[22px] relative">
                <img src={require('../../assets/paiement/sepa logo.svg').default} alt="SEPA" />
              </div>
            </div>

            {/* SEPA Form */}
            {paymentMethod === "sepa" && (
              <div id="sepa-form">
                <div className="mt-4">
                  <label className="block text-[#1e1e1e] text-sm font-medium font-Poppins mb-2">
                    Numéro de compte (IBAN)
                  </label>
                  <div className="StripeElement mb-2 w-full border rounded-md px-3 py-2 hover:border-[#0056b3] focus:border-[#0056b3] focus:border-[1.5px]">
                    <IbanElement options={ibanOptions} />
                  </div>
                </div>
                <div className="mb-2">
                <label className="block text-[#1e1e1e] text-sm font-medium font-Poppins mb-2">
                  Nom complet
                </label>
                <input
                  type="text"
                  className="w-full border rounded-md px-3 py-2 hover:border-[#0056b3] focus:border-[#0056b3] focus:outline-none focus:border-[1.5px] items-center"
                  placeholder="Nom complet"
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
              </div>
                <div className="flex items-center justify-start gap-2 mt-2">
                  <input
                    type="checkbox"
                    className="cursor-pointer"
                    checked={authorizeDebit}
                    onChange={() => setAuthorizeDebit(!authorizeDebit)}
                  />
                  <div className="text-[#1e1e1e] text-[12px] font-normal font-Poppins w-full">
                    Autoriser le débit automatique de votre compte bancaire
                  </div>
                </div>
                <div className="flex items-center justify-start gap-2 mt-1">
                  <input
                    type="checkbox"
                    className="cursor-pointer"
                    checked={acceptTerms}
                    onChange={() => setAcceptTerms(!acceptTerms)}
                  />
                  <div className="text-[#1e1e1e] text-[12px] font-normal font-Poppins">
                    Accepter nos <u>termes et conditions</u> ainsi que notre{" "}
                    <u>politique de confidentialité</u>.
                  </div>
                </div>
              </div>
            )}

            {/* Card Payment */}
            <div
              className={`mt-2 w-full px-4 py-3 rounded border items-center justify-between inline-flex ${paymentMethod === "card"
                  ? "bg-[#eff5ff] border-[#0056b3]"
                  : "bg-white border-[#a0a0a0]"
                }`}
            >
              <div className="flex gap-4">
                <input
                  type="radio"
                  name="paymentMethod"
                  id="cardPayment"
                  checked={paymentMethod === "card"}
                  onChange={() => setPaymentMethod("card")}
                />
                <div className="text-[#1e1e1e] text-sm font-normal font-Poppins">
                  Carte de crédit
                </div>
              </div>
              <div className="flex gap-1">
                <img src={require('../../assets/paiement/Visaicon.svg').default} alt="VISA" />
                <img src={require('../../assets/paiement/mcIcon.svg').default} alt="MasterCard" />
              </div>
            </div>
          </div>

          {/* Card Form */}
          {paymentMethod === "card" && (
            <div className="mt-2 sm:pr-8">
              <label className="block text-[#1e1e1e] text-sm font-medium font-Poppins mb-2">
                Numéro de carte
              </label>

              <div className="StripeElement mb-2 w-full border rounded-md px-3 py-2 hover:border-[#0056b3] focus:outline-[#0056b3]">
                <CardNumberElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#32325d",
                        "::placeholder": {
                          color: "#A0A0A0",
                        },
                      },
                      invalid: {
                        color: "#fa755a",
                      },

                    },
                  }}
                />
              </div>
              <div className="flex gap-2">
                <div className="w-full">
                  <label className="block text-[#1e1e1e] text-sm font-medium font-Poppins mb-2">
                    Date d'expiration
                  </label>
                  <div className="flex flex-row w-full StripeElement mb-2 border rounded-md px-3 py-2 hover:border-[#0056b3] focus:outline-[#0056b3] focus:border-[1.5px]">
                    <div className="w-full">
                      <CardExpiryElement
                        options={{
                          style: {
                            base: {
                              fontSize: "16px",
                              color: "#32325d",
                              "::placeholder": {
                                color: "#A0A0A0",
                              },
                            },
                            invalid: {
                              color: "#fa755a",
                            },
                          },
                        }}
                      />
                    </div>
                    <img src={require('../../assets/paiement/Calendar.svg').default} alt="Date" />
                  </div>
                </div>
                <div className="w-full">
                  <label className="block text-[#1e1e1e] text-sm font-medium font-Poppins mb-2">
                    CVC
                  </label>
                  <div className="flex flex-row w-full StripeElement mb-2 border rounded-md px-3 py-2 hover:border-[#0056b3] focus:border-[#0056b3] focus:outline-none focus:border-[1.5px]">
                    <div className="w-full">
                      <CardCvcElement
                        options={{
                          style: {
                            base: {
                              fontSize: "16px",
                              color: "#32325d",
                              "::placeholder": {
                                color: "#A0A0A0",
                              },
                            },
                            invalid: {
                              color: "#fa755a",
                            },
                          },
                        }}
                      />
                    </div>
                    <img src={require('../../assets/paiement/Lock Closed.svg').default} alt="CVV" />
                  </div>
                </div>
              </div>

              <div className="mb-2">
                <label className="block text-[#1e1e1e] text-sm font-medium font-Poppins mb-2">
                  Nom complet
                </label>
                <input
                  type="text"
                  className="w-full border rounded-md px-3 py-2 hover:border-[#0056b3] focus:border-[#0056b3] focus:outline-none focus:border-[1.5px] items-center"
                  placeholder="Nom complet"
                  required
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-between mt-4 sm:pr-8 ">
          <button
            type="button"
            onClick={() => props.onBack()}
            className="hover:bg-[#ccdef1] w-45 h-10 px-5 py-2 bg-white border border-[#0056b3] text-[#0056b3] rounded-lg font-normal font-Poppins text-sm"
          >
            Retour
          </button>

          <button
            type="submit"
            disabled={loading}
            className="hover:bg-[#4179B5] w-45 h-10 px-5 py-2 bg-[#0056b3] flex justify-center items-center rounded-lg text-center text-white font-normal font-Poppins text-sm"
          >
            {loading ? "En cours..." : "Continuer"}
          </button>
        </div>
      </div>
      {error && <div className="mt-2 text-red-500">{error}</div>}
      {success && <div className="mt-2 text-green-500">Ajout réussi!</div>}
    </form>
  );
};

export const PaymentInfoPopUp = (props) => (
  <Elements stripe={stripePromise}>
    <PaymentFormContent onBack={props.onBack} onNext={props.onNext}  />
  </Elements>
);
