import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import AdminCard from "./admin/AdminCard";
import defaultImage from "../assets/default.png";
import AvailabilityCalendarDetails from "./AvailabilityCalendarDetails";
import Swal from "sweetalert2";

const EntityDetails = () => {
  const location = useLocation();
  const { entity } = location.state || {};
  const [formData, setFormData] = useState(entity);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const entityType = entity.type;
  // const imageSrc = entityType === "Freelancer" ? formData.profilePicture ? `http://localhost:8002${formData.profilePicture}` : defaultImage : entityType==="Entreprise (employé)" ? formData.photo ? `http://localhost:8002${formData.photo}` : defaultImage :  defaultImage;
  const imageSrc = formData.profilePicture
    ? `http://localhost:8002${formData.profilePicture}`
    : defaultImage;
  return (
    <div className="bg-[#F4F4F4]  min-h-screen">
      <AdminCard />
      <div className="ml-[18%] w-[82%] p-8">
        <h2 className=" text-[#10316B] mb-2 font-poppins  font-bold ">
          informations personnelles
        </h2>
        <div className="bg-white flex flex-row justify-around mb-4 rounded-2xl py-8">
          {/* Première colonne */}
          <div className="w-[42%]">
            <div className="mb-4">
              <label className="mb-1 text-blue-bp font-bold text-lg">
                Type de l'entité
              </label>
              <input
                type="text"
                name="type"
                value={formData.type}
                // onChange={handleChange}
                disabled
                className="ml-2 w-full bg-gray-form p-2 rounded-xl outline-none"
              />
            </div>
            <div className="mb-4">
              <label className="mb-1 text-blue-bp font-bold text-lg">
                Nom complet
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="ml-2 w-full bg-gray-form p-2 rounded-xl outline-none"
              />
            </div>
            <div className="mb-4">
              <label className="mb-1 text-blue-bp font-bold text-lg">
                Pays
              </label>
              <input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleChange}
                className="ml-2 w-full bg-gray-form p-2 rounded-xl outline-none"
              />
            </div>
            <div className="mb-4">
              <label className="mb-1 text-blue-bp font-bold text-lg">
                Ville
              </label>
              <input
                type="text"
                name="ville"
                value={
                  entityType === "Freelancer" || entityType === "Entreprise"
                    ? formData.ville
                    : "Non disponible"
                }
                onChange={handleChange}
                className="ml-2 w-full bg-gray-form p-2 rounded-xl outline-none"
              />
            </div>
            <div className="">
              <label className="mb-1 text-blue-bp font-bold text-lg">
                Adresse
              </label>
              <input
                type="text"
                name="Address"
                value={formData.Address}
                onChange={handleChange}
                className="ml-2 w-full bg-gray-form p-2 rounded-xl outline-none"
              />
            </div>
          </div>
          {/* Deuxième colonne */}
          <div className="w-[42%]">
            <div className="mb-4">
              <label className="mb-1 text-blue-bp font-bold text-lg">
                Photo
              </label>
              <div className="w-32 h-32 ">
                <img src={imageSrc} alt="photo" className="w-32 h-32" />
              </div>
            </div>
            <div className="mb-4">
              <label className="mb-1 text-blue-bp font-bold text-lg">
                Email
              </label>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="ml-2 w-full bg-gray-form p-2 rounded-xl outline-none"
                disabled
              />
            </div>
            <div className="mb-4">
              <label className="mb-1 text-blue-bp font-bold text-lg">
                Numero de Téléphone
              </label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="ml-2 w-full bg-gray-form p-2 rounded-xl outline-none"
              />
            </div>
            <div className="">
              <label className="mb-1 text-blue-bp font-bold text-lg">
                Date de naissance
              </label>
              <input
                type="date"
                name="birthdayDate"
                value={formData.birthdayDate}
                onChange={handleChange}
                className="ml-2 w-full bg-gray-form p-2 rounded-xl outline-none"
              />
            </div>
          </div>
        </div>
        <h2 className=" text-[#10316B] mb-2 font-poppins  font-bold ">
          informations Professionelles
        </h2>
        {/* content for freelancers  */}
        {entityType === "Freelancer" && (
          <div className="bg-white mb-4 rounded-2xl py-8 ">
            <div className=" flex flex-row justify-around ">
              {/* Première colonne */}
              <div className="w-[42%]">
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Titre du profile
                  </label>
                  <input
                    type="text"
                    name="profilTitle"
                    value={formData.profilTitle}
                    onChange={handleChange}
                    className="ml-2 w-full bg-gray-form p-2 rounded-xl outline-none"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Expertises
                  </label>
                  <div className="ml-2">
                    {formData.expertises && formData.expertises.length > 0 ? (
                      <div className="flex flex-wrap gap-2">
                        {formData.expertises.map((expertise, index) => (
                          <div key={index} className="bg-gray-form p-2 rounded">
                            {expertise}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>Pas d'expertises</p>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Niveau Freelance
                  </label>
                  <input
                    type="text"
                    name="niveauFreelance"
                    value={formData.niveauFreelance}
                    onChange={handleChange}
                    className="ml-2 w-full bg-gray-form p-2 rounded-xl outline-none"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Type de validation
                  </label>
                  <input
                    type="text"
                    name="validation_type"
                    value={
                      formData.validation_type
                        ? formData.validation_type
                        : "Non disponible"
                    }
                    onChange={handleChange}
                    className="ml-2 w-full bg-gray-form p-2 rounded-xl outline-none"
                  />
                </div>
              </div>
              {/* Deuxième colonne */}
              <div className="w-[42%]">
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Description
                  </label>
                  <textarea
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    className="ml-2 w-[100%] h-36 bg-gray-form p-2 rounded-xl outline-none"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Langues
                  </label>
                  <div className="ml-2">
                    {formData.langues && formData.langues.length > 0 ? (
                      <div className="space-y-2">
                        {formData.langues.map((langue, index) => (
                          <div key={index} className="bg-gray-form p-2 rounded">
                            {langue}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>Pas de langues</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className=" w-[90%]">
                <p className=" text-[#10316B] mb-2 font-poppins  font-bold ">
                  Experiences Professionels
                </p>
                {formData.experiences.length > 0 ? (
                  formData.experiences.map((experience, index) => (
                    <div
                      key={index}
                      className="border bg-gray-form p-4 mt-2 rounded-lg shadow-md"
                    >
                      <div className="grid grid-cols-1 md:grid-cols-2">
                        <div className="">
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Date début :
                            </span>{" "}
                            {experience.date_debut}
                          </p>
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Date fin :
                            </span>{" "}
                            {experience.date_fin}
                          </p>
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Entreprise :
                            </span>{" "}
                            {experience.nom_entreprise}
                          </p>
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Poste dans l'entreprise:
                            </span>{" "}
                            {experience.poste_dans_entreprise}
                          </p>
                        </div>
                        <div className="">
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Description :
                            </span>{" "}
                            {experience.description_de_poste}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>Pas d'expériences</div>
                )}
              </div>
            </div>
            <div className="flex justify-center items-center mt-8">
              <div className=" w-[90%]">
                <p className=" text-[#10316B] mb-2 font-poppins  font-bold ">
                  Missions Réalisées
                </p>
                {formData.missions.length > 0 ? (
                  formData.missions.map((mission, index) => (
                    <div
                      key={index}
                      className="border bg-gray-form p-4 mt-2 rounded-lg shadow-md"
                    >
                      <div className="grid grid-cols-1 md:grid-cols-2">
                        <div className="">
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              id :{" "}
                            </span>{" "}
                            {mission.id}
                          </p>
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Schema Expertise :{" "}
                            </span>{" "}
                            {mission.schemaexpertise ? "True" : "false"}
                          </p>
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Schema Ressource :{" "}
                            </span>{" "}
                            {mission.schemaressource ? "true" : "false"}
                          </p>
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Mission en cours :{" "}
                            </span>{" "}
                            {mission.encours ? "true" : "false"}
                          </p>
                        </div>
                        <div className="">
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Demande :
                            </span>{" "}
                            {mission.demande.toString()}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>Pas de missions</div>
                )}
              </div>
            </div>
          </div>
        )}
        {/* content for Entreprise (employé) */}
        {entityType === "Entreprise (employé)" && (
          <div className="bg-white mb-4 rounded-2xl py-8 ">
            <div className=" flex flex-row justify-around ">
              {/* Première colonne */}
              <div className="w-[42%]">
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Provider
                  </label>
                  <input
                    type="text"
                    name="provider"
                    value={formData.provider}
                    onChange={handleChange}
                    className="ml-2 w-full bg-gray-form p-2 rounded-xl outline-none"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Expertises
                  </label>
                  <div className="ml-2">
                    {formData.expertises && formData.expertises.length > 0 ? (
                      <div className="flex flex-wrap gap-2">
                        {formData.expertises.map((expertise, index) => (
                          <div key={index} className="bg-gray-form p-2 rounded">
                            {expertise}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>Pas d'expertises</p>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Linkedin profile
                  </label>
                  <input
                    type="text"
                    name="linkedin"
                    value={formData.linkedin}
                    onChange={handleChange}
                    className="ml-2 w-full bg-gray-form p-2 rounded-xl outline-none"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Type de validation
                  </label>
                  <input
                    type="text"
                    name="validation_type"
                    value={
                      formData.validation_type
                        ? formData.validation_type
                        : "Non disponible"
                    }
                    onChange={handleChange}
                    className="ml-2 w-full bg-gray-form p-2 rounded-xl outline-none"
                  />
                </div>
              </div>
              {/* Deuxième colonne */}
              <div className="w-[42%]">
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Description
                  </label>
                  <textarea
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    className="ml-2 w-[100%] h-36 bg-gray-form p-2 rounded-xl outline-none"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Langues
                  </label>
                  <div className="ml-2">
                    {formData.langues && formData.langues.length > 0 ? (
                      <div className="space-y-2">
                        {formData.langues.map((langue, index) => (
                          <div key={index} className="bg-gray-form p-2 rounded">
                            {langue}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>Pas de langues</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className=" w-[90%]">
                <p className=" text-[#10316B] mb-2 font-poppins  font-bold ">
                  Experiences Professionels
                </p>
                {formData.experiences.length > 0 ? (
                  formData.experiences.map((experience, index) => (
                    <div
                      key={index}
                      className="border bg-gray-form p-4 mt-2 rounded-lg shadow-md"
                    >
                      <div className="grid grid-cols-1 md:grid-cols-2">
                        <div className="">
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Date début :
                            </span>{" "}
                            {experience.date_debut}
                          </p>
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Date fin :
                            </span>{" "}
                            {experience.date_fin}
                          </p>
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Entreprise :
                            </span>{" "}
                            {experience.nom_entreprise}
                          </p>
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Poste dans l'entreprise:
                            </span>{" "}
                            {experience.poste_dans_entreprise}
                          </p>
                        </div>
                        <div className="">
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Description :
                            </span>{" "}
                            {experience.description_de_poste}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>Pas d'expériences</div>
                )}
              </div>
            </div>
            <div className="flex justify-center items-center mt-8">
              <div className=" w-[90%]">
                <p className=" text-[#10316B] mb-2 font-poppins  font-bold ">
                  Missions Réalisées
                </p>
                {formData.missions.length > 0 ? (
                  formData.missions.map((mission, index) => (
                    <div
                      key={index}
                      className="border bg-gray-form p-4 mt-2 rounded-lg shadow-md"
                    >
                      <div className="grid grid-cols-1 md:grid-cols-2">
                        <div className="">
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              id :{" "}
                            </span>{" "}
                            {mission.id}
                          </p>
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Schema Expertise :{" "}
                            </span>{" "}
                            {mission.schemaexpertise ? "True" : "false"}
                          </p>
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Schema Ressource :{" "}
                            </span>{" "}
                            {mission.schemaressource ? "true" : "false"}
                          </p>
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Mission en cours :{" "}
                            </span>{" "}
                            {mission.encours ? "true" : "false"}
                          </p>
                        </div>
                        <div className="">
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Demande :
                            </span>{" "}
                            {mission.demande.toString()}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>Pas de missions</div>
                )}
              </div>
            </div>
          </div>
        )}
        {/* content for Entreprise*/}
        {entityType === "Entreprise" && (
          <div className="bg-white mb-4 rounded-2xl py-8 ">
            <div className=" flex flex-row justify-around ">
              {/* Première colonne */}
              <div className="w-[42%]">
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Domaine d'activité
                  </label>
                  <input
                    type="text"
                    name="activity_domain"
                    value={formData.activity_domain}
                    onChange={handleChange}
                    className="ml-2 w-full bg-gray-form p-2 rounded-xl outline-none"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Domaines
                  </label>
                  <div className="ml-2">
                    {formData.domaines && formData.domaines.length > 0 ? (
                      <div className="flex flex-wrap gap-2">
                        {formData.domaines.map((domaine, index) => (
                          <div key={index} className="bg-gray-form p-2 rounded">
                            {domaine["name"]}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>Pas de domaines</p>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Expertises
                  </label>
                  <div className="ml-2">
                    {formData.expertises && formData.expertises.length > 0 ? (
                      <div className="flex flex-wrap gap-2">
                        {formData.expertises.map((expertise, index) => (
                          <div key={index} className="bg-gray-form p-2 rounded">
                            {expertise}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>Pas d'expertises</p>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Statut
                  </label>
                  <input
                    type="text"
                    name="provider_status"
                    value={formData.provider_status}
                    onChange={handleChange}
                    className="ml-2 w-full bg-gray-form p-2 rounded-xl outline-none"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Type de validation
                  </label>
                  <input
                    type="text"
                    name="validation_type"
                    value={
                      formData.validation_type
                        ? formData.validation_type
                        : "Non disponible"
                    }
                    onChange={handleChange}
                    className="ml-2 w-full bg-gray-form p-2 rounded-xl outline-none"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Langues
                  </label>
                  <div className="ml-2">
                    {formData.langues && formData.langues.length > 0 ? (
                      <div className="space-y-2">
                        {formData.langues.map((langue, index) => (
                          <div key={index} className="bg-gray-form p-2 rounded">
                            {langue}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>Pas de langues</p>
                    )}
                  </div>
                </div>
              </div>
              {/* Deuxième colonne */}
              <div className="w-[42%]">
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Prénom du manager
                  </label>
                  <input
                    type="text"
                    name="managerFirstName"
                    value={formData.managerFirstName}
                    onChange={handleChange}
                    className="ml-2 w-[100%] bg-gray-form p-2 rounded-xl outline-none"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Nom du manager
                  </label>
                  <input
                    type="text"
                    name="manager_name"
                    value={formData.manager_name}
                    onChange={handleChange}
                    className="ml-2 w-[100%] bg-gray-form p-2 rounded-xl outline-none"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Téléphone de l'entreprise
                  </label>
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="ml-2 w-[100%] bg-gray-form p-2 rounded-xl outline-none"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Téléphone du manager
                  </label>
                  <input
                    type="text"
                    name="manager_phone"
                    value={formData.manager_phone}
                    onChange={handleChange}
                    className="ml-2 w-[100%] bg-gray-form p-2 rounded-xl outline-none"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Size
                  </label>
                  <input
                    type="text"
                    name="size"
                    value={formData.size}
                    onChange={handleChange}
                    className="ml-2 w-[100%] bg-gray-form p-2 rounded-xl outline-none"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-blue-bp font-bold text-lg">
                    Site web
                  </label>
                  <input
                    type="text"
                    name="website"
                    value={formData.website}
                    onChange={handleChange}
                    className="ml-2 w-[100%] bg-gray-form p-2 rounded-xl outline-none"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center mt-8">
              <div className=" w-[90%]">
                <p className=" text-[#10316B] mb-2 font-poppins  font-bold ">
                  Missions Réalisées
                </p>
                {formData.missions.length > 0 ? (
                  formData.missions.map((mission, index) => (
                    <div
                      key={index}
                      className="border bg-gray-form p-4 mt-2 rounded-lg shadow-md"
                    >
                      <div className="grid grid-cols-1 md:grid-cols-2">
                        <div className="">
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              id :{" "}
                            </span>{" "}
                            {mission.id}
                          </p>
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Schema Expertise :{" "}
                            </span>{" "}
                            {mission.schemaexpertise ? "True" : "false"}
                          </p>
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Schema Ressource :{" "}
                            </span>{" "}
                            {mission.schemaressource ? "true" : "false"}
                          </p>
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Mission en cours :{" "}
                            </span>{" "}
                            {mission.encours ? "true" : "false"}
                          </p>
                        </div>
                        <div className="">
                          <p>
                            <span className="text-[#10316B] font-semibold">
                              Demande :
                            </span>{" "}
                            {mission.demande.toString()}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>Pas de missions</div>
                )}
              </div>
            </div>
          </div>
        )}
        <h2 className=" text-[#10316B] mb-4 font-poppins  font-bold ">
          Disponibilité
        </h2>
        <div className="bg-white mb-4 rounded-2xl p-8">
          <AvailabilityCalendarDetails
            availabilitiesUser={formData.availabilities}
          />
        </div>
      </div>
      <div className="flex justify-center items-center mb-8">
        <button
          onClick={async () => {
            console.log(formData);
            const response = await fetch(
              "http://localhost:8002/providerservice/update-entity/",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
              }
            );
            if (response.ok) {
              Swal.fire({
                icon: "success",
                title: "Succès",
                text: "Les informations ont été mises à jour avec succès",
              });
            }
            const data = await response.json();
            console.log(data);
          }}
          className="bg-[#10316B] text-white rounded-xl px-8 py-2 ml-[18%] mb-8"
        >
          Enregistrer les modifications
        </button>
      </div>
    </div>
  );
};

export default EntityDetails;
