import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  emailVerificationCode: '',
  userType: 'client', // 'client' ou 'provider'
};

const validationEmailFormSlice = createSlice({
  name: 'validationEmailForm',
  initialState,
  reducers: {
    setEmailVerificationCode: (state, action) => {
      state.emailVerificationCode = action.payload.code;
      state.userType = action.payload.userType;
    },
    resetEmailVerificationCode: (state) => {
      state.emailVerificationCode = '';
      state.userType = 'client';
    },
  },
});

export const { setEmailVerificationCode, resetEmailVerificationCode } = validationEmailFormSlice.actions;

export default validationEmailFormSlice.reducer;
