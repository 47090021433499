import React from 'react';
import DataTable from 'react-data-table-component';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import { useAuth } from '../../components/AuthContext';
import { API_GATEWAY_URL } from '../../constants';

// Handle mission rejection
const handleMissionRejected = (user, index, setMissions) => {
    setMissions(prevMissions => prevMissions.filter(mission => mission.id !== index));
    console.log('Mission Rejected', index);
    // Additional API call or logic to handle mission rejection
};

// Handle mission acceptance
const handleMissionAccepted = async (user, index, setMissions) => {
    try {
        const response = await fetch(`${API_GATEWAY_URL}/validerProposition/2/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.access_token}`
            },
            body: JSON.stringify({ action: 'accepter' }),
        });
        if (response.ok) {
            const data = await response.json();
            setMissions(prevMissions =>
                prevMissions.map(mission =>
                    mission.id === index ? { ...mission, status: 'En Cours', type: 'En Cours' } : mission
                )
            );
        } else {
            throw new Error('Failed to update data');
        }
    } catch (err) {
        console.error(err);
    }
};

// Expanded component
const ExpandedComponent = ({ user, data, setMissions }) => (
    <div 
        className="py-3 px-4 border border-gray-300 rounded-0 bg-neutral-50 shadow-lg"
        style={{
            fontFamily: 'var(--ff-body)',
            fontSize: 'var(--fs-body)',
            fontWeight: 'var(--fw-body)',
            color: 'var(--clr-neutral-900)',
        }}
    >
        <div className="flex flex-col justify-start">
            <div>
                <h2 
                    className="text-lg fw-semibold"
                    style={{
                        color: 'var(--clr-primary-500)',
                        textDecoration: 'underline',
                        fontSize: '1rem',
                    }}
                >Nom de la mission :</h2>
                <p style={{fontSize:'.9rem'}}>{data.mission}</p>
            </div>
            <div>
                <h2 
                    className="text-lg fw-semibold"
                    style={{
                        color: 'var(--clr-primary-500)',
                        textDecoration: 'underline',
                        fontSize: '1rem',
                    }}
                >Description de la mission :</h2>
                <p style={{fontSize:'.9rem'}}>{data.description}</p>
            </div>
            <div>
                <h2 
                    className="text-lg fw-semibold"
                    style={{
                        color: 'var(--clr-primary-500)',
                        textDecoration: 'underline',
                        fontSize: '1rem',
                    }}
                >
                    Période de la mission :
                </h2>
                <p style={{fontSize:'.9rem'}}>{data.startDate} - {data.endDate}</p>
            </div>
            <div>
                <h2 
                    className="text-lg fw-semibold"
                    style={{
                        color: 'var(--clr-primary-500)',
                        textDecoration: 'underline',
                        fontSize: '1rem',
                    }}
                >
                    Type de la mission :
                </h2>
                <p style={{fontSize:'.9rem'}}>{data.type}</p>
            </div>
            {
                data.status === 'En attente' ? (
                    <div className='flex gap-3 justify-end items-center'>
                        <Button
                            variant="outlined"
                            color='error'
                            onClick={() => handleMissionRejected(user, data.id, setMissions)}
                            sx={{
                                fontWeight: 'var(--fw-semibold)',
                                fontSize: '.8rem',
                                width: 'fit-content',
                                alignSelf: 'flex-end',
                            }}
                        >
                            Refuser la mission
                        </Button>
                        <Button
                            variant="contained"
                            color='success'
                            onClick={() => handleMissionAccepted(user, data.id, setMissions)}
                            sx={{
                                fontWeight: 'var(--fw-semibold)',
                                fontSize: '.8rem',
                                width: 'fit-content',
                                alignSelf: 'flex-end',
                            }}
                        >
                            Accepter la mission
                        </Button>
                    </div>
                ) : null
            }
        </div>
    </div>
);

const conditionalCellStyle = (cellValue, status) => {
    let backgroundColor = '';

    if (status === 'En cours' && cellValue === 'En cours') {
        backgroundColor = 'var(--clr-secondary-100)';
    } else if (status === 'Terminé' && cellValue === 'Terminé') {
        backgroundColor = 'var(--clr-danger-200)';
    } else if (status === 'En attente' && cellValue === 'En attente') {
        backgroundColor = 'var(--clr-warning-200)';
    }

    return { backgroundColor };
};

const conditionalCellStyleColor = (cellValue, status) => {
    let color = '';

    if (status === 'En cours' && cellValue === 'En cours') {
        color = '#14804A';
    } else if (status === 'Terminé' && cellValue === 'Terminé') {
        color = 'var(--clr-neutral-800)';
    } else if (status === 'En attente' && cellValue === 'En attente') {
        color = '#5A6376';
    }

    return { color };
};

const CustomDateComponent = ({ date }) => {
    return (
        <div 
            className="flex justify-center items-center clr-gray-700 bg-gray-50 px-3 py-1 rounded-md fw-medium"
        >
            {date}
        </div>
    );
};

const columns = [
    {
        name: '#',
        selector: row => row.id,
        sortable: true,
        hide: 'md',
        style: {
            fontStyle: 'var(--ff-body)',
        },
    },
    {
        name: 'Mission',
        selector: row => row.mission,
        sortable: true,
        style: {
            fontStyle: 'var(--ff-body)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            grow: 1,
        },
    },
    {
        name: 'Client',
        selector: row => row.client,
        sortable: true,
        style: {
            fontStyle: 'var(--ff-body)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
    },
    {
        name: 'Status',
        selector: row => row.status,
        cell: row => (
            <div 
                style={{
                    ...conditionalCellStyle(row.status, row.status),
                    ...conditionalCellStyleColor(row.status, row.status),
                    fontStyle: 'var(--ff-body)',
                    padding: '4px 8px',
                    borderRadius: '3px',
                    width: '6.8rem',
                    textAlign: 'center',
                    fontWeight: 'var(--fw-semibold)',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <div 
                    className="rectangle w-2 h-2"
                    style={{
                        backgroundColor: conditionalCellStyleColor(row.status, row.status).color,
                        borderRadius: '30%',
                        marginInline: '8px 8px',
                    }}
                ></div>
                {row.status}
            </div>
        ),
    },
    {
        name: 'Période',
        selector: (row) => (
            <div className="flex items-center">
                <CustomDateComponent date={row.startDate} />
                <span className="mx-2"> <ArrowForwardIosIcon fontSize="4px"/> </span>
                <CustomDateComponent date={row.endDate} />
            </div>
        ),
        sortable: true,
        hide: 'md',
        style: {
            fontStyle: 'var(--ff-body)',
        },
    },
];

const customHead = {
    headCells: {
        style: {
            fontFamily: 'var(--ff-heading)',
            fontSize: '1rem',
            fontWeight: 'var(--fw-semibold)',
            backgroundColor: '#F7F9FC',
            color: 'var(--clr-neutral-950)',
        },
    },
};

const customNoDataComponent = () => (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
        textAlign: 'center',
        fontFamily: 'var(--ff-body)',
        fontSize: '1.2rem',
        fontWeight: 'var(--fw-medium)',
    }}>
        Vous avez aucune mission pour l'instant.
    </div>
);

const MissionsDashboard = ({ data }) => {
    const [missions, setMissions] = React.useState(data);
    const { user } = useAuth();
    console.log("missions ", missions);

    return (
        <div className="table-container">
            <DataTable
                columns={columns}
                data={missions}
                customStyles={customHead}
                expandableRows
                expandOnRowClicked
                expandableRowsHideExpander
                expandableRowsComponent={({ data }) => <ExpandedComponent user={user} data={data} setMissions={setMissions} />}
                pagination={true}
                highlightOnHover={true}
                pointerOnHover={true}
                fixedHeader={true}
                noDataComponent={customNoDataComponent()}
            />
        </div>
    );
};

export default MissionsDashboard;
