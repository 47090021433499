import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/BusinessPartnerRequestForm.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logoWhite.png";
import { Link } from "react-router-dom";
import "../styles/LoginPage.css";
import "../styles/Buttons.css";

const BusinessPartnerRequestForm = ({ apiGatewayUrl }) => {
  const [formData, setFormData] = useState({
    prenom: "",
    nom: "",
    email: "",
    numero_de_telephone: "",
    message: "",
    subject: "",
  });

  const navigate = useNavigate(); // Hook pour la redirection

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, numero_de_telephone: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.numero_de_telephone ||
      formData.numero_de_telephone.length < 5
    ) {
      toast.warn("Veuillez entrer un numéro de téléphone valide.");
      return;
    }
    try {
      const response = await fetch(
        `${apiGatewayUrl}/businesspartner-request/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        const responseData = await response.json();
        toast.success("Demande envoyée avec succès !");
        setFormData({
          prenom: "",
          nom: "",
          email: "",
          numero_de_telephone: "",
          message: "",
          subject: "",
        }); // Réinitialiser le formulaire

        // Redirection vers la page de félicitations
        navigate("/felicitation");
      } else {
        const errorData = await response.json();
        if (errorData.message) {
          toast.warn(errorData.message);
        } else {
          toast.error(
            "Une erreur est survenue lors de l'envoi de votre demande."
          );
        }
      }
    } catch (error) {
      toast.error("Une erreur est survenue lors de l'envoi de votre demande.");
    }
  };

  return (
    <div className="loginbackgroundImage">
      <Link className="loginlogo pl-[72px] pt-7" to="/">
        <img className="max-2xl:w-[200px] 2xl:w-[280px]" src={logo} />
      </Link>
      <div className="container ">
        <div className="row justify-content-center p-3">
          <div className="overlay">
            <div className="w-100 ">
              <h2 className="p-2 mb-2 text-primary-800 font-bold font-display text-fluid-lg ">
                Demande de partenariat
              </h2>
              <form onSubmit={handleSubmit} className="font-body">
                <div className="mb-4">
                  <input
                    type="text"
                    name="nom"
                    className="form"
                    placeholder="Nom"
                    value={formData.nom}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="text"
                    name="prenom"
                    className="form"
                    placeholder="Prénom"
                    value={formData.prenom}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="email"
                    name="email"
                    className="form"
                    placeholder="E-mail"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  {/* Phone number Input */}
                  <PhoneInput
                    country={"fr"}
                    onlyCountries={["fr"]}
                    value={formData.numero_de_telephone}
                    onChange={handlePhoneChange}
                    className="form"
                    containerStyle={{
                      borderRadius: "30px",
                    }}
                    inputStyle={{
                      border: "0px",
                      fontSize: "23px",
                      width: "100%",
                      outline: "none",
                      boxShadow: "none", // to remove any focus ring or shadow
                    }}
                    buttonStyle={{
                      background: "transparent",
                      border: "0px",
                      borderRadius: "30px",
                    }}
                    dropdownStyle={{
                      border: "1px solid #ccc", // Add border to dropdown if needed
                      borderRadius: "8px", // Adjust border radius for dropdown
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Optional shadow
                    }}
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="text"
                    name="subject"
                    className="form"
                    placeholder="Objet du message "
                    value={formData.subject}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <textarea
                    name="message"
                    className="form textarea"
                    placeholder="Décrivez votre proposition ou votre demande de partenariat ici..."
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
                <button type="submit" className="Redbutton">
                  Envoyer
                </button>
              </form>

              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessPartnerRequestForm;
