import React from 'react';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Button, Link } from '@mui/material';

const VisitLinks = ({linkedinLink, githubLink}) => {
    return (
        <div className="flex">
        <div className='flex flex-row'>
            <Button
                size='medium'
                className="
                    group
                    relative
                    inline-flex
                    items-center
                    overflow-hidden
                    px-8 py-2
                    text-white
                    focus:outline-none
                    focus:ring-2"
                sx={{
                    backgroundColor: 'var(--clr-secondary-400)',
                    border: 1.5,
                    borderColor: 'var(--clr-neutral-500)',
                    borderRadius: 'var(--br-button)',
                    textTransform: 'none',
                    '&:hover': {
                    backgroundColor: 'var(--clr-secondary-400)',
                    },
                }}
            >
                <span
                    style={{
                    color: 'var(--clr-white)',
                    fontFamily: 'var(--ff-button)',
                    fontWeight: 'var(--fw-medium)',
                    fontSize: '1.1rem',
                    }}
                >
                    Visit my
                </span>
            </Button>
            {/*<Link
                href={githubLink}
                underline="none"
                className='flex self-center h-full'
                target="_blank"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0 8px',
                    backgroundColor: 'var(--clr-secondary-50)',
                    border: 1.5,
                    borderLeft: 0,
                    borderColor: 'var(--clr-neutral-500)',
                    '&:hover': {
                        backgroundColor: 'var(--clr-secondary-100)',
                    },
                }}
            >
                <GitHubIcon sx={{ color: 'var(--clr-neutral-900)', fontSize: '2rem' }} />
            </Link>*/}
            <Link
                href={linkedinLink}
                underline="none"
                className='flex self-center h-full'
                target="_blank"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0 8px',
                    backgroundColor: 'var(--clr-secondary-50)',
                    border: 1.5,
                    borderLeft: 0,
                    borderColor: 'var(--clr-neutral-500)',
                    '&:hover': {
                        backgroundColor: 'var(--clr-secondary-100)',
                    },
                }}
            >
                <LinkedInIcon sx={{ color: 'var(--clr-neutral-900)', fontSize: '2rem' }} />
            </Link>
        </div>
        </div>
    );
}

export default VisitLinks;
