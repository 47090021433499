import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux/slices/authentificationSlice";
import "../styles/navbarAndFooter.css";
import logo from "../assets/Logo.png";
import { FaBars } from "react-icons/fa6";
import "../index.css";
import { useRef, useEffect } from "react";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, userType, email_validation } = useAuth();
  const [hoverIndex, setHoverIndex] = useState(-1);
  const { logout } = useAuth();
  const [activeItem, setActiveItem] = useState("acceuil");
  const navRef = useRef(null);
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      setActiveItem(sectionId);
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleScroll = () => {
    const sections = document.querySelectorAll("section");

    for (const section of sections) {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.clientHeight;

      if (
        window.scrollY >= sectionTop &&
        window.scrollY < sectionTop + sectionHeight
      ) {
        setActiveItem(section.id);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log(user, userType, email_validation); // For debugging purposes

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  {
    /* Logout button */
  }
  const authLinks = (
    <>
      <li className="flex flex-col items-center max-lg:mt-6 lg:gap-8 lg:mr-24">
        <a
          className="btn-red max-2xl:w-44 w-52"
          to="/type_de_profile"
          onClick={handleLogout}
        >
          Déconnexion
        </a>
      </li>
    </>
  );

  {
    /* To make the navigation bar responsive */
  }
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="fixed w-full shadow-md  bg-white  text-red-800 font-body z-50 max-2xl:text-fluid-ms 2xl:text-fluid-md">
      <nav className="flex items-center justify-between max-2xl:h-20 2xl:h-28 lg:gap-0">
        {/* Logo section */}
        <div className="z-10 max-xl:ml-6 xl:ml-24 max-lg:mt-2 lg:mt-0 py-1 max-xs:ml-1 max-xs:p-0 ">
          <Link to="/">
            <img className="max-sm:w-[150px] w-[290px]" src={logo} alt="Logo" />
          </Link>
        </div>
        <div
          className={`fixed  z-50 max-lg:bg-white w-full flex flex-col py-3 font-bold lg:static lg:flex-row lg:justify-between transition-transform transform ${
            isMenuOpen ? "-top-full" : "top-16"
          }`}
        >
          {/* Guest Navbar */}
          {!user && (
            <>
              <ul className="flex flex-col  justify-center items-center max-2xl:gap-4 2xl:gap-6 lg:flex-row p-0 m-0 flex-grow px-4">
                <li className="">
                  <NavLink
                    className="nav-item lg:ml-6"
                    onClick={() => scrollToSection("acceuil")}
                  >
                    Accueil
                  </NavLink>
                </li>
                <li className="">
                  <NavLink
                    className="nav-item"
                    onClick={() => scrollToSection("services")}
                  >
                    Services
                  </NavLink>
                </li>
                <li className=" whitespace-nowrap">
                  <NavLink
                    className="nav-item"
                    onClick={() => scrollToSection("apropos")}
                  >
                    À propos
                  </NavLink>
                </li>
                <li className="">
                  <NavLink
                    className="nav-item"
                    onClick={() => scrollToSection("feedback")}
                  >
                    Feedback
                  </NavLink>
                </li>
                <li className="">
                  <NavLink
                    className="nav-item"
                    onClick={() => scrollToSection("faq")}
                  >
                    FAQ
                  </NavLink>
                </li>
              </ul>
              <div className="flex flex-col items-center max-2xl:gap-2 2xl:gap-6 max-lg:mt-6 lg:flex-row right-0 max-xl:mr-6 xl:mr-24 ">
                <NavLink
                  className="btn-blue max-2xl:w-44 w-52 whitespace-nowrap btn-sans-serif"
                  to="/logIn"
                >
                  Se connecter
                </NavLink>
                <NavLink
                  className="btn-red max-2xl:w-44 w-52 btn-sans-serif"
                  to="/type_de_profile"
                >
                  S'inscrire
                </NavLink>
              </div>
            </>
          )}

          {/* Client Navbar */}
          {user && userType === "client" && email_validation === "OK" && (
            <>
              <ul className="flex flex-col  justify-center items-center max-lg:gap-4 lg:gap-6 lg:flex-row p-0 m-0 flex-grow px-4 ">
                <li className="">
                  <NavLink className="nav-item lg:ml-6" to="/">
                    Accueil
                  </NavLink>
                </li>
                <li className="">
                  <NavLink
                    className="nav-item"
                    onClick={() => scrollToSection("services")}
                  >
                    Services
                  </NavLink>
                </li>
                <li className=" whitespace-nowrap">
                  <NavLink
                    className="nav-item"
                    onClick={() => scrollToSection("apropos")}
                  >
                    À propos
                  </NavLink>
                </li>
                <li className="">
                  <NavLink
                    className="nav-item"
                    onClick={() => scrollToSection("feedback")}
                  >
                    Feedback
                  </NavLink>
                </li>
                <li className="">
                  <NavLink
                    className="nav-item"
                    onClick={() => scrollToSection("faq")}
                  >
                    FAQ
                  </NavLink>
                </li>
              </ul>
              <div className="flex flex-col items-center max-lg:mt-6 lg:flex-row">
                <NavLink
                  className="btn-blue max-2xl:w-44 w-52 whitespace-nowrap lg:mr-8"
                  to="/client-Dashboard"
                >
                  Espace Client
                </NavLink>
              </div>
            </>
          )}

          {/* Businesspartner Navbar */}
          {user && userType === "businesspartner" && (
            <li className="nav-item">
              <NavLink
                className="btn-blue w-60 whitespace-nowrap lg:mr-8"
                to="/"
              >
                Espace Partenaire Business
              </NavLink>
            </li>
          )}

          {user && userType === "provider" && email_validation === "OK" && (
            <li className="nav-item">
              <NavLink className="nav-link" to="/provider-profile">
                Espace Entreprise fournisseur de services
              </NavLink>
            </li>
          )}
          {user && userType === "freelancer" && email_validation === "OK" && (
            <ul className="flex flex-col justify-center items-center max-lg:gap-4 lg:gap-6 lg:flex-row p-0 m-0 flex-grow px-4">
              <li className="flex items-center justify-center">
                <NavLink className="nav-link" to="/freelancer-Dashboard">
                  Espace Free-lance
                </NavLink>
              </li>
            </ul>
          )}
          {user && userType === "worker" && email_validation === "OK" && (
            <li className="nav-item">
              <NavLink className="nav-link" to="/worker-welcome">
                Espace Collaborateur fournisseur de services
              </NavLink>
            </li>
          )}

          {/* Logout button */}
          {user && authLinks}
        </div>
        <div className="lg:hidden mr-8 lg:mr-12">
          <FaBars
            className="fa-solid fa-2xl cursor-pointer"
            onClick={toggleMenu}
          />
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
