import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import Layout from "./Layout";
import HeroSectionPartner from "./HeroSectionPartner";
import ServicesPartner from "./ServicesPartner";
import FAQPartner from "./FAQPartner";
import FeedbackPartner from "./FeedBackPArtner";
import FAQPartner2 from "./FAQPartner2";
import { Link } from "react-router-dom";

const BusinessPartnerWelcomePage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleViewProfile = () => {
    navigate("/Businesspartner-profile");
  };

  const url = "https://test.teratalent.com"; // Remplacez par l'URL de votre site
  console.log("User:", user);

  return (
    <div className="redLandingImage">
      <Layout title="TeraTalenT" content="Accueil">
        {/* Test button to toggle between the two landing pages*/}
        <Link to="/">
          <button className="absolute bg-blue-300 mt-32 right-5 w-16 h-16 rounded-full">
            Test
          </button>
        </Link>

        <section id="acceuil">
          <HeroSectionPartner />
        </section>
        <section id="services">
          <ServicesPartner />
        </section>
        <section id="apropos">
          <FAQPartner />
        </section>
        <section id="feedback">
          {" "}
          <FeedbackPartner />
        </section>
        <section id="faq">
          <FAQPartner2 />
        </section>
      </Layout>
    </div>
  );

  /*<div style={styles.container}>
      <h2 style={styles.title}>Bienvenue</h2>
      <p style={styles.message}>Bienvenue, {user && user.user_email}!</p>
      <button onClick={handleViewProfile} style={styles.button}>Voir Profil</button>

    </div>*/
};

const styles = {
  container: {
    maxWidth: "400px",
    margin: "0 auto",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    background: "#fff",
  },
  title: {
    textAlign: "center",
    color: "#333",
  },
  message: {
    fontSize: "16px",
    color: "#555",
    textAlign: "center",
  },
  button: {
    display: "block",
    margin: "20px auto",
    padding: "10px 15px",
    backgroundColor: "#007BFF",
    color: "#fff",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
  },
};

export default BusinessPartnerWelcomePage;
