import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Alert, Spinner, Modal } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { API_GATEWAY_URL } from "../constants";
import Navbar from "./Navbarr";
import { useAuth } from "./AuthContext";
import "../styles/CreateDemandePage.css";
import "../index.css";
import ClientCard from "./ClientCard";
import LoadingSpinner from "./LoadingSpinner";
import "../styles/Buttons.css";

const Profil = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [clientData, setClientData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    clientName: "",
    email: "",
    phoneNumber: "",
  });
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchClientData = async () => {
      setLoading(true);
      try {
        const profileResponse = await axios.get(
          `${API_GATEWAY_URL}/client-profile/`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );

        setClientData(profileResponse.data);
        setFormData({
          clientName: profileResponse.data.clientName,
          email: profileResponse.data.email,
          phoneNumber: profileResponse.data.phoneNumber,
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (user && user.access_token) {
      fetchClientData();
    }
  }, [user]);

  const handleEditToggle = () => setIsEditing(!isEditing);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true); // Début du processus de sauvegarde
    try {
      if (formData.email !== clientData.email) {
        // Email is changed, so send request to generate and send OTP
        // const otpResponse = await axios.put(`${API_GATEWAY_URL}/client-profile/`, formData, {
        //   headers: {
        //     'Authorization': `Bearer ${user.access_token}`
        //   },
        // });
        // if (otpResponse.data.message.includes('OTP sent to new email address')) {
        //   setShowOtpModal(true);  // Show OTP modal if OTP is sent
        // }
      } else {
        await axios.put(`${API_GATEWAY_URL}/client-profile/`, formData, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        });
        Swal.fire("Success", "Profil mis à jour avec succès !", "success");
        setIsEditing(false);
      }
    } catch (err) {
      Swal.fire("Error", "Échec de la mise à jour du profil", "error");
    }finally {
      setIsSaving(false); // Fin du processus de sauvegarde
    }
  };

  // const handleOtpSubmit = async () => {
  //   try {
  //     const otpResponse = await axios.post(`${API_GATEWAY_URL}/verify-otp/`, {
  //       email: formData.email,
  //       otp: otp
  //     });
  //     if (otpResponse.data.message === 'OTP verified successfully') {
  //       Swal.fire('Success', 'Email updated successfully!', 'success');
  //       setShowOtpModal(false);
  //       // Update clientData with new email if necessary
  //       setClientData({ ...clientData, email: formData.email });
  //     } else {
  //       Swal.fire('Error', 'Invalid OTP or email.', 'error');
  //     }
  //   } catch (err) {
  //     Swal.fire('Error', 'Failed to verify OTP.', 'error');
  //   }
  // };

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }

  return (
    <div className="pw-page-layout flex bg-white ">
      <Navbar />
      <div className="w-1/4 min-w-[250px]">
        <ClientCard />
      </div>
      <div className="fixed right-0 w-3/4 font-body mt-6">
        {loading ? (
          <Spinner animation="border" />
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : clientData ? (
          <div className="">
            <div className="max-2xl:text-fluid-ms 2xl:text-fluid-sm text-primary-800 mt-24 MediumContainer">
              {isEditing ? (
                <form onSubmit={handleFormSubmit}>
                  <div className="form-group">
                    <label htmlFor="clientName">
                      <strong>Client Name</strong>
                    </label>
                    <input
                      type="text"
                      name="clientName"
                      value={formData.clientName}
                      onChange={handleInputChange}
                      className="form"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">
                      <strong>Email</strong>
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="form"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phoneNumber">
                      <strong>Phone Number</strong>
                    </label>
                    <input
                      type="text"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                      className="form"
                    />
                  </div>
                  <div className="flex gap-3 mt-3">
                    <button
                      variant="primary"
                      className="nextStep"
                      type="submit"
                      disabled={isSaving}
                    >
                      {isSaving ? "En cours..." : "Enregistrer les modifications"}
                    </button>
                    <button
                      variant="secondary"
                      className="previousStep"
                      onClick={handleEditToggle}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              ) : (
                <div>
                  <p>
                    <strong>Client Name:</strong> {clientData.clientName}
                  </p>
                  <hr />
                  <p>
                    <strong>Name:</strong> {clientData.name}
                  </p>
                  <hr />
                  <p>
                    <strong>Email:</strong> {clientData.email}
                  </p>
                  <hr />
                  <p>
                    <strong>Numéro de téléphone:</strong>{" "}
                    {clientData.phoneNumber}
                  </p>
                  <button className="Redbutton mt-3" onClick={handleEditToggle}>
                     Modifier
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <Alert variant="danger">Client Data Missing</Alert>
        )}
      </div>

      {/* OTP Modal
      <Modal show={showOtpModal} onHide={() => setShowOtpModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Verify OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formOtp">
              <Form.Label>Enter OTP sent to your new email</Form.Label>
              <Form.Control
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" onClick={handleOtpSubmit}>
              Verify OTP
            </Button>
          </Form>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default Profil;
