import { useState } from "react";
import FAQItem from "./FAQItem";

const FAQPageOne = () => {
  const faqData = [
    {
      question: "Qu’est-ce que c’est ?",
      answer:
        "TeraTalenT est une plateforme qui propose des consultants IT de toutes spécialités, offrant aux entreprises françaises de l’IT un accès à un vivier de ressources techniques qualifiées à moindre coût et à la demande.",
    },
    {
      question: "A qui ça s’adresse ?",
      answer:
        "TeraTalenT est une plateforme destinée à l'ensemble des entreprises françaises de l'IT, quel que soit leurs disciplines. Qu'il s'agisse de petites, moyennes ou grandes structures, nos consultants sont en mesure de s'intégrer facilement à tout type d'environnement.",
    },
    {
      question:
        "Quelle est la différence entre TeraTalenT et les autres plateformes de Freelance ?",
      answer: (
        <>
          Contrairement aux autres plateformes de freelance, TeraTalenT se
          positionne comme un véritable partenaire.
          <br />
          • Nous adoptons une approche de prestataire direct, plutôt qu’un
          simple intermédiaire.
          <br />• Nos intervenants sont soit des entreprises partenaires, soit
          des freelances sélectionnés avec soin, garantissant ainsi une
          meilleure fiabilité des services offerts
        </>
      ),
    },
    {
      question: "Quels profils IT proposez-vous ?",
      answer:
        "TeraTalenT propose des profils IT variés, allant du développeur Full Stack à l’ingénieur DevOps, en passant par des data analystes et des experts en cybersécurité. ",
    },
    {
      question: "Y-a-t-il un engagement pour utiliser vos services ?",
      answer:
        "Non, il n'y a aucun engagement requis. Vous pouvez mobiliser nos consultants IT selon vos besoins, sans aucune obligation à long terme. Que vous ayez un projet ponctuel ou un besoin continu, vous êtes libre de choisir la durée de mise à disposition qui vous convient.",
    },
    {
      question: "Comment ça marche ?",
      answer:(
        <>Commencez par soumettre vos besoins sur notre plateforme en ligne, en précisant le niveau d’expertise requis et la durée souhaitée.<br/>Nous traiterons votre demande et identifierons les profils les plus appropriés parmi notre réseau d'experts en moins de 48 heures.<br/>Vous aurez ensuite la possibilité de choisir le consultant qui correspond le mieux à vos attentes.<br/>La mission pourra démarrer à la date que vous aurez fixée.
        </>
      )
    },
  ];

  const [openIndex, setOpenIndex] = useState(null); // Nouveau state pour suivre l'index ouvert

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Ouvre ou ferme l'accordéon
  };

  // To split the data into two halves
  const firstHalf = faqData.slice(0, Math.ceil(faqData.length / 2));
  const secondHalf = faqData.slice(Math.ceil(faqData.length / 2));

  return (
    <div className="flex flex-col  bg-white items-center justify-center py-10 2xl:py-20">
      <div className="text-center ">
        <h2 className="font-display text-primary-800 font-bold text-fluid-xlg 2xl:text-fluid-xl">
          À propos de TeraTalenT
        </h2>
        <p className="font-body text-primary-800 max-md:max-w-full  text-fluid-sm 2xl:text-fluid-md mx-11">
          Rejoignez une communauté en pleine croissance et découvrez ce que nous pouvons accomplir ensemble !
        </p>
      </div>
      <div className="flex flex-wrap  max-md:flex-col px-16 max-sm:px-4 lg:px-32 space-x-6 max-md:space-x-0 items-stretch">
        <div className="flex-1 flex flex-col">
          {firstHalf.map((item, index) => (
            <FAQItem
              key={index}
              question={item.question}
              answer={item.answer}
              isOpen={openIndex === index}
              onToggle={() => handleToggle(index)}
            />
          ))}
        </div>
        <div className="flex-1 flex flex-col">
          {secondHalf.map((item, index) => (
            <FAQItem
              key={index}
              question={item.question}
              answer={item.answer}
              isOpen={openIndex === index + firstHalf.length} // Ajustement de l'index pour la deuxième moitié
              onToggle={() => handleToggle(index + firstHalf.length)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQPageOne;
