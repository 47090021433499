import { useState } from "react";

const Accordion = ({ question, answer, isOpen, onToggle }) => {
  //const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className="flex flex-col font-body text-fluid-sm xl:text-fluid-md text-primary-800">
      <div>
        <button
          className="flex justify-between w-full bg-transparent font-bold text-fluid-md text-primary-800 "
          //onClick={() => setAccordionOpen(!accordionOpen)}
          onClick={onToggle}
        >
          <span className="font-bold max-2xl:text-fluid-ms 2xl:text-fluid-md">
            {question}
          </span>
          {isOpen ? <span>-</span> : <span>+</span>}
        </button>
      </div>
      <div
        className={`grid overflow-hidden transition-all duration-1000 ease-in-out px-3
            ${
              isOpen
                ? "grid-rows-[1fr] opacity-100"
                : "grid-rows-[0fr] opacity-0"
            }`}
      >
        <span className="overflow-hidden max-2xl:text-fluid-ms 2xl:text-fluid-md">
          {answer}
        </span>
      </div>
      <hr className="w-full border-t-1  max-md:max-w-full border-primary-800 " />
    </div>
  );
};

export default Accordion;
