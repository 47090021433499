import React, { useState, useEffect } from 'react';
import { useAuth } from '../../components/AuthContext';
import { API_GATEWAY_URL } from '../../constants';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Menu } from '../../components';
import SkillCard from '../../components/freelancerWorkspaceComponents/SkillCard';

const TechnicalTestPage = () => {
    const { user } = useAuth();
    const [testDetails, setTestDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
		console.log("test affichage user")
		console.log(user)
        const fetchTestDetails = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/details-test/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setTestDetails(data); // assuming data is an array
                    console.log('Détails des tests récupérés:', data);
                } else {
                    throw new Error('Erreur lors de la récupération des détails de test.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (user && user.access_token) {
            fetchTestDetails();
        }
    }, [user]);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <div>Erreur: {error}</div>;
    }

    const getTestStatus = (skillId) => {
        const test = testDetails.find((test) => test.expertise_id === skillId);
        if (test) {
            if (test.message === "Le test n'a pas encore été passé") {
                return { canStart: true, message: "Commencer le test" };
            } else {
                // Formater le score avec deux chiffres après la virgule
                const formattedScore = test.score.toFixed(2);
                return { canStart: false, message: `${test.message} Score: ${formattedScore}/100` };
            }
        }
        return { canStart: true, message: "Commencer le test" };
    };    

    console.log('Test Details for rendering:', testDetails);

    return (

        <div style={{width: '100%', paddingBlock: '12px'}}>
            <div className='container flex flex-col gap-4'>
                <Menu user={user}/>
                <div className='section-one flex flex-col items-center gap-4 lg:flex-row lg:items-start lg:justify-between mt-3'>
                    <h2 className="text-2xl font-bold mb-4">Vos tests techniques :</h2>
                    <div className="flex flex-wrap gap-6 justify-center">
                        {testDetails.length > 0 ? (
                            testDetails.map((test) => {
                                const statusMessage = getTestStatus(test.expertise_id);
                                return (
                                    <SkillCard 
                                        key={test.expertise_id} 
                                        skill={{ expertise_id: test.expertise_id, expertise_name: test.expertise_name, level: test.level }}
                                        statusMessage={statusMessage} 
                                    />
                                );
                            })
                        ) : (
                            <div className="text-gray-500">Aucun test disponible.</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TechnicalTestPage;