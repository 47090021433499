import React, { useState,useEffect } from "react";
import Calendar from "react-calendar";
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import '../styles/ClientCalendar.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Checkbox,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormGroup,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';

const ClientCalendar = ({ availabilities, onAvailabilityChange }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    // Initialiser le calendrier avec les disponibilités existantes
    setSelectedDate(new Date());
  }, [availabilities]);

  const handleAvailabilityChange = (newAvailability) => {
    const updatedAvailabilities = [...availabilities, newAvailability];
    onAvailabilityChange(updatedAvailabilities);
  };
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
    partOfDay: "morning",
    recurrence: [],
    selectDays: false,
    recurrenceType: "hebdomadaire",
  });

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const newAvailability = {
        start: formData.startDate,
        end: formData.endDate,
        part_of_day: formData.partOfDay,
        recurrence: {
          days: formData.recurrence,
          frequency: formData.selectDays ? formData.recurrenceType : '',
        },
      };
      handleAvailabilityChange(newAvailability);

      Swal.fire('Succès', 'Disponibilité ajoutée.', 'success');
    } catch (error) {
      Swal.fire('Erreur', 'Échec de l\'ajout de la disponibilité.', 'error');
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const tileClassName = ({ date }) => {
    const dateAvailabilities = availabilities.filter(avail => {
      const startDate = new Date(avail.start);
      const endDate = new Date(avail.end);
      if (date < startDate || date > endDate) return false;
      if (!avail.recurrence.days.length) return true;

      const dayOfWeek = date.getDay();
      if (avail.recurrence.frequency === "bihebdomadaire" && !isBiweekly(date, avail.start)) {
        return false; // Si ce n'est pas une semaine valide pour la bihebdomadaire
        }
        return avail.recurrence.days.includes(dayOfWeek);
    });

    if (dateAvailabilities.length === 0) return "unavailableClient";
    
    const hasMorning = dateAvailabilities.some(avail => avail.part_of_day === "morning");
    const hasAfternoon = dateAvailabilities.some(avail => avail.part_of_day === "afternoon");
    const hasFullDay = dateAvailabilities.some(avail => avail.part_of_day === "full-day");

    if (hasFullDay || (hasMorning && hasAfternoon)) {
      return "availability-boxClient full-dayClient";
    } else if (hasMorning) {
      return "availability-boxClient morningClient";
    } else if (hasAfternoon) {
      return "availability-boxClient afternoonClient";
    }

    return "unavailableClient";
  };
  function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }
  
  function isBiweekly(date, startDate) {
    const currentWeek = getWeekNumber(date);
    const startWeek = getWeekNumber(new Date(startDate));
    
    // Si la différence de semaines entre la date courante et la date de début est paire, c'est bihebdomadaire
    return (currentWeek - startWeek) % 2 === 0;
  }
  
  return (
    <div>
      {/*<div className="calendar-container">*/}
        <Calendar
          onChange={setSelectedDate}
          value={selectedDate}
          tileClassName={tileClassName}
          className="custom-calendarClient"
          showNeighboringMonth={true}
        />
      {/*</div>*/}

      <div className="form-container">
        <FormControl fullWidth margin="normal">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date de début"
              value={dayjs(formData.startDate)}
              onChange={(date) => handleInputChange({ target: { name: 'startDate', value: date.toISOString() } })}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date de fin"
              value={dayjs(formData.endDate)}
              onChange={(date) => handleInputChange({ target: { name: 'endDate', value: date.toISOString() } })}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <FormLabel>Partie de la journée</FormLabel>
          <RadioGroup
            row
            name="partOfDay"
            value={formData.partOfDay}
            onChange={handleInputChange}
          >
            <FormControlLabel value="morning" control={<Radio />} label="Matin" />
            <FormControlLabel value="afternoon" control={<Radio />} label="Après-midi" />
            <FormControlLabel value="full-day" control={<Radio />} label="Journée entière" />
          </RadioGroup>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.selectDays}
                onChange={handleInputChange}
                name="selectDays"
              />
            }
            label="Choisir les jours"
          />
        </FormControl>
        {formData.selectDays && (
          <>
            <FormControl fullWidth margin="normal">
              <InputLabel id="recurrence-type-label">Récurrence</InputLabel>
              <Select
                labelId="recurrence-type-label"
                name="recurrenceType"
                value={formData.recurrenceType}
                onChange={handleInputChange}
              >
                <MenuItem value="hebdomadaire">Hebdomadaire</MenuItem>
                <MenuItem value="bihebdomadaire">Bihebdomadaire</MenuItem>
              </Select>
            </FormControl>
            <FormControl component="fieldset" fullWidth margin="normal">
              <FormLabel component="legend">Jours de la semaine</FormLabel>
              <FormGroup row>
                {["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"].map((day, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={formData.recurrence.includes(index)}
                        onChange={(e) => {
                          const { checked, value } = e.target;
                          setFormData((prevData) => ({
                            ...prevData,
                            recurrence: checked
                              ? [...prevData.recurrence, parseInt(value)]
                              : prevData.recurrence.filter((d) => d !== parseInt(value)),
                          }));
                        }}
                        value={index}
                      />
                    }
                    label={day}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </>
        )}
        <Button onClick={handleFormSubmit} variant="contained" sx={{ mt: 2 }}>
          Ajouter la disponibilité
        </Button>
      </div>

      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date Début</TableCell>
              <TableCell>Date Fin</TableCell>
              <TableCell>Partie de la Journée</TableCell>
              <TableCell>Récurrence</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {availabilities.map((avail, index) => (
              <TableRow key={index}>
                <TableCell>{dayjs(avail.start).format('DD-MM-YYYY')}</TableCell>
                <TableCell>{dayjs(avail.end).format('DD-MM-YYYY')}</TableCell>
                <TableCell>{avail.part_of_day}</TableCell>
                <TableCell>{avail.recurrence.days.length > 0 ? avail.recurrence.frequency : "N/A"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ClientCalendar;
