import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useAuth } from "./AuthContext";
import { API_GATEWAY_URL } from "../constants";
import { useParams } from "react-router-dom";
import styles from "../styles/workerCreationForm.css";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";

function getLevelFullText(levelCode) {
  const levelMap = {
    JR: "Junior",
    CF: "Confirmé", // "CF" mapped to "Confirmé"
    SR: "Senior",
  };
  return levelMap[levelCode] || levelCode; // Return the mapped value or the original code if not found
}
const WorkerCreationForm = () => {
  const { token } = useParams(); // Récupère le token du chemin
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    photo: null,
    linkedin: "",
    bio: "",
    selectedExpertises: [],
  });
  const [expertises, setExpertises] = useState([]);
  const [apiError, setApiError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { user } = useAuth(); // Utiliser useAuth pour accéder aux infos de l'utilisateur
  const [expertiseLevels, setExpertiseLevels] = useState({});
  const [allExpertises, setAllExpertises] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchAllExpertises() {
      try {
        const response = await fetch(`${API_GATEWAY_URL}/expertises/`);
        if (response.ok) {
          const allExpertisesData = await response.json();
          setAllExpertises(
            allExpertisesData.map((exp) => ({
              value: exp.id,
              label: exp.name,
              level: "JR", // Default level for all expertises
            }))
          );
          setExpertiseLevels(
            allExpertisesData.reduce((acc, curr) => {
              acc[curr.id] = "JR"; // Default level for all expertises
              return acc;
            }, {})
          );
          // After expertises are loaded, fetch invitation info if a token is present
          if (token) {
            await fetchInvitationInfo();
          }
        } else {
          throw new Error("Failed to fetch expertises");
        }
      } catch (error) {
        console.error("Error fetching all expertises:", error);
        setApiError("Unable to load expertises.");
      }
    }

    async function fetchInvitationInfo() {
      try {
        const response = await fetch(
          `${API_GATEWAY_URL}/provider-invitation-info/${token}`
        );
        if (response.ok) {
          const invitationData = await response.json();
          setFormData((prevFormData) => ({
            ...prevFormData,
            firstName: invitationData.first_name,
            lastName: invitationData.last_name,
            email: invitationData.email,
            selectedExpertises: invitationData.expertises.map((e) => ({
              value: e.id,
              label: e.name,
              level: e.level,
            })),
          }));
          const levels = {};
          invitationData.expertises.forEach((e) => {
            levels[e.id] = getLevelFullText(e.level);
          });
          setExpertiseLevels(levels);
        } else {
          throw new Error("Failed to fetch invitation information");
        }
      } catch (error) {
        console.error("Error fetching invitation information:", error);
        setApiError("Unable to load invitation data.");
      } finally {
        setLoading(false);
      }
    }

    fetchAllExpertises();
  }, [token]); // Dependency array includes token to re-run if token changes

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      photo: event.target.files[0],
    }));
  };

  const handleExpertiseChange = (selectedOptions) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedExpertises: selectedOptions || [],
    }));

    const newLevels = { ...expertiseLevels };
    selectedOptions.forEach((option) => {
      if (!(option.value in newLevels)) {
        newLevels[option.value] = "JR"; // Set default to 'Junior' if not previously set
      }
    });
    setExpertiseLevels(newLevels);
  };

  const handleLevelChange = (expertiseId, newLevel) => {
    setExpertiseLevels((prevLevels) => ({
      ...prevLevels,
      [expertiseId]: newLevel,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData();
    data.append("first_name", formData.firstName);
    data.append("last_name", formData.lastName);
    data.append("email", formData.email);
    data.append("password", formData.password);
    data.append("linkedin", formData.linkedin || "");
    data.append("bio", formData.bio || "");
    data.append("token", token); // Assurez-vous que le serveur traite ce champ

    if (formData.photo) {
      data.append("photo", formData.photo);
    }
    const expertiseDataForApi = JSON.stringify(
      formData.selectedExpertises.map((expertise) => ({
        id: expertise.value,
        level: expertiseLevels[expertise.value],
      }))
    );

    const expertiseDataForApi2 = JSON.stringify(
      formData.selectedExpertises.map((expertise) => {
        let levelCode = expertiseLevels[expertise.value]; // Obtient le niveau actuel
        // Convertit les descriptions en codes
        if (levelCode === "Junior") {
          levelCode = "JR";
        } else if (levelCode === "Confirmé") {
          levelCode = "CF";
        } else if (levelCode === "Senior") {
          levelCode = "SR";
        }

        return {
          id: expertise.value,
          level: levelCode, // Utilise le code approprié
        };
      })
    );

    data.append("expertise_data", expertiseDataForApi2);

    try {
      const response = await fetch(`${API_GATEWAY_URL}/register-worker/`, {
        method: "POST",
        headers: {},
        body: data,
      });

      if (response.ok) {
        Swal.fire({
          title: "Succès!",
          text: "Votre inscription a été effectué avec succès, bienvenue dans votre équipe !",
          icon: "success",
          confirmButtonText: "Super",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/worker-validation-email", {
              state: { email: formData.email },
            });
          }
        });
      } else {
        const errorData = await response.json();
        Swal.fire({
          title: "Erreur!",
          text: errorData.message || "Une erreur est survenue.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Erreur!",
        text: "Erreur de réseau ou du serveur.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }
  if (apiError) {
    return (
      <div style={{ padding: "20px", textAlign: "center", fontSize: "20px" }}>
        {apiError}
      </div>
    );
  }
  return (
    <div className="WCbackgroundImage">
      <div className="WCoverlay">
        <h2>Inscription du membre de l’équipe</h2>
        <form onSubmit={handleSubmit} className="WCcontainer">
          <div className="column column-info">
            <input
              type="text"
              name="firstName"
              className="WCinput"
              value={formData.firstName}
              onChange={handleInputChange}
              placeholder="Prénom"
              required
            />
            <input
              type="text"
              name="lastName"
              className="WCinput"
              value={formData.lastName}
              onChange={handleInputChange}
              placeholder="Nom de Famille"
              required
            />
            <input
              type="file"
              name="photo"
              className="WCinput"
              onChange={handleFileChange}
            />
            <input
              type="text"
              name="linkedin"
              className="WCinput"
              value={formData.linkedin}
              onChange={handleInputChange}
              placeholder="Profil LinkedIn URL"
            />
            <textarea
              name="bio"
              className="WCinput"
              value={formData.bio}
              onChange={handleInputChange}
              placeholder="Biographie Courte"
            />
          </div>
          <div className="column column-expertise">
            <Select
              classNamePrefix="WCselect"
              isMulti
              options={allExpertises}
              getOptionLabel={(option) =>
                `${option.label} (${getLevelFullText(
                  expertiseLevels[option.value] || "JR"
                )})`
              }
              getOptionValue={(option) => option.value}
              onChange={handleExpertiseChange}
              value={formData.selectedExpertises}
              placeholder="Sélectionnez les Expertises"
            />
            {formData.selectedExpertises.map((expertise) => (
              <div key={expertise.value} className="expertiseLevelContainer">
                <div className="expertiseLabel">{expertise.label}:</div>
                <select
                  className="levelSelect"
                  value={expertiseLevels[expertise.value]}
                  onChange={(e) =>
                    handleLevelChange(expertise.value, e.target.value)
                  }
                >
                  <option value="Junior">Junior</option>
                  <option value="Confirmé">Confirmé</option>
                  <option value="Senior">Senior</option>
                </select>
              </div>
            ))}
          </div>
          <div className="column column-auth">
            <input
              type="email"
              name="email"
              className="WCinput"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email"
              required
              readOnly
            />
            <input
              type="password"
              name="password"
              className="WCinput"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Mot de passe"
            />
            {apiError && <p>{apiError}</p>}
            <button type="submit" className="WCbutton">
              Enregistrer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WorkerCreationForm;
