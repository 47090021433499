import React, { useState, useEffect } from "react";
import axios from "axios";
import FeedbackCard from "./FeedbackCard";
import { API_GATEWAY_URL } from "../constants";
import { useAuth } from "./AuthContext";
import Navbarr from "./Navbarr";
import ClientCard from "./ClientCard";
import { Card, Container, Row, Col, Modal } from "react-bootstrap";

const Feedbacks = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    // Fetch feedbacks when the component loads
    fetchFeedbacks();
  }, []);

  const fetchFeedbacks = async () => {
    try {
      const response = await axios.get(`${API_GATEWAY_URL}/feedback-client/`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      setFeedbacks(response.data);
    } catch (error) {
      console.error("Failed to fetch feedbacks:", error);
    }
  };

  /* Only for testing purposes 
  const mockFeedbacks = [
    {
      id: 1,
      title: "Great Service!",
      commentaire:
        "I had an amazing experience with the platform. The support was excellent!",
      feedback_plateforme: "John Doe",
      date: "2024-09-16",
    },
    {
      id: 2,
      title: "Highly Recommended",
      commentaire:
        "The platform is easy to use and the matching process was smooth.",
      feedback_plateforme: "Jane Smith",
      date: "2024-09-15",
    },
    {
      id: 3,
      title: "Needs Improvement",
      commentaire:
        "Some features are a bit slow, but overall a decent platform.",
      feedback_plateforme: "Bob Johnson",
      date: "2024-09-14",
    },
  ];*/

  return (
    <div className="pw-page-layout">
      <Navbarr />
      <div className="w-full flex flex-col p-4">
        <ClientCard />
        <div className="flex flex-col justify-center items-center space-y-3">
          <Row>
            <Col className="CDPdemandes-column">
              <div className="CDPdemandes-cards-container">
                {feedbacks.map((feedback) => (
                  <FeedbackCard key={feedback.id} feedback={feedback} />
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Feedbacks;
