import InvoiceSummary from './invoicesummary';
import { ProgressBar } from './progressBar';
import { useState, useEffect } from 'react';
import { PersonalForm } from './personalInfoform';
import { SuccessScreen } from './succesScreen';
import { PaymentForm } from './paymentform';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { API_GATEWAY_URL } from '../../constants';  
import LoadingSpinner from '../LoadingSpinner';

function Checkout() {
  const location = useLocation();
  const[resource_id, setResource_id]=useState(location.state.resource_id);
  console.log("Resource ID:", resource_id);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const [userId, setUserId] = useState(null);
  const [resourceInfo, setResourceInfo] = useState(null);
  const [step, setStep] = useState(0);
  const [personalInfo, setPersonalInfo] = useState({
    fullName: '',
    companyName: '',
    address: '',
    phone: '',
    tva: '',
    rememberMe: false,
  });
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentIntentId, setPaymentIntentId] = useState('');

  useEffect(() => {
    const fetchResourceInfo = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_GATEWAY_URL}/get-resource-selected/?resource_id=${location.state.resource_id}&demande_id=${location.state.demande_id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) throw new Error("Erreur lors de la récupération des informations de la ressource.");
        
        const data = await response.json();
        setResourceInfo(data);
        console.log("Resource Info:", data);
      } catch (error) {
        console.error("Erreur:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchResourceInfo();
  }, []);

  const onNext = (props) => {
    console.log('props:', props);
    setStep((prevStep) => prevStep + 50);
    if (props) {
      setPersonalInfo(props.personalInfo);
    }
    console.log(props);
  };

  const onBack = () => {
    setStep((prevStep) => prevStep - 50);
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!user) return;

      setLoading(true);
      try {
        const profileResponse = await fetch(`${API_GATEWAY_URL}/client-profile/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.access_token}`,
          },
        });
        if (!profileResponse.ok) throw new Error("Erreur lors de la récupération des données du profil.");

        const profileData = await profileResponse.json();
        const user_id = profileData.user;
        setUserId(user_id);

        const userinfoResponse = await fetch(`${API_GATEWAY_URL}/user-information/?user=${user_id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.access_token}`,
          },
        });
        if (!userinfoResponse.ok) throw new Error("Erreur lors de la récupération des informations de l'utilisateur.");

        const userinfoData = await userinfoResponse.json();
        console.log(userinfoData); // Ensure you receive the correct data

        // Since userinfoData is an array, we need to access the first item
        if (userinfoData && userinfoData.length > 0) {
          const userInfo = userinfoData[0];
          setPersonalInfo({
            fullName: userInfo.nom, 
            companyName: userInfo.entreprise,
            address: userInfo.adresse,
            phone: userInfo.telephone,
            tva: userInfo.tva,
            rememberMe: true,
          });
        } else {
          console.log("Aucune donnée utilisateur trouvée.");
        }
      } catch (err) {
        console.error('Erreur:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [user]);

  useEffect(() => {
    if (personalInfo) {
      console.log("Updated personalInfo:", personalInfo);
    }
  }, [personalInfo]);

  useEffect(() => {
    if (userId) {
      console.log("userId a été mis à jour :", userId);
    }
  }, [userId]); 

  return (
    <div className="flex flex-col-reverse w-full min-h-screen p-4 md:flex-row">
      {loading && <LoadingSpinner isLoading={loading} />}
      <div className="flex flex-col flex-1">
        <ProgressBar step={step} />
        {step === 0 && (
          <PersonalForm onNext={onNext} personalInfo={personalInfo} user={userId} />
        )}
        {step === 50 && (
          <PaymentForm
            onNext={onNext}
            onBack={onBack}
            personalInfo={personalInfo}
            resourceInfo={resourceInfo}
            resource_id={resource_id}
            setPaymentMethod={(paymentMethod) => setPaymentMethod(paymentMethod)}
            setPaymentIntentId={(paymentIntentId) =>
              setPaymentIntentId(paymentIntentId)
            }
          />
        )}
        {step === 100 && <SuccessScreen method={paymentMethod} personalInfo={personalInfo } amount={'5000'} paymentIntentId={paymentIntentId}/>}
      </div>
      <div className="flex-shrink-0 h-auto md:w-1/3 md:h-full">
      {resourceInfo && (
        <InvoiceSummary personalInfo={personalInfo} resourceInfo={resourceInfo} amount={'5000'} />
      )}
      </div>
    </div>
  );
}

export default Checkout;
