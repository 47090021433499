import React from 'react';
import Card from './Card';
import "../../index.css";

const Cards = ({cardsContent}) => {
    return (
        <div className='px-2 py-2 flex flex-col gap-3 items-start md:flex-row md:gap-5 lg:gap-7 overflow-x-scroll no-scrollbar'>
            <Card title={cardsContent[0].title} content={cardsContent[0].content} />
            <Card title={cardsContent[1].title} content={cardsContent[1].content} />
            <Card title={cardsContent[2].title} content={cardsContent[2].content} />
        </div>
    );
};

export default Cards;