import React from 'react'
import backgroundImage from '../../assets/imageLogin.jpg'
import logo from '../../assets/Logo.png'


const felicitation = () => {
    return (
        <div
          className="relative w-screen h-screen bg-cover bg-center"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="absolute top-0 left-0 m-4">
        <img src={logo} alt="Logo" className="h-9 w-auto" />
      </div>
      <div className="flex flex-col items-center justify-center h-full space-y-4">
        <div className="text-[#013C5E] text-4xl font-bold">
        Félicitations, votre pré-inscription a été envoyée avec succès
        </div>
        <div className="text-white text-2xl">
        Vous devriez recevoir très bientôt un e-mail confirmant la création de votre compte 
        </div>
      </div>
          {/* Ton contenu ici */}
        </div>
      );
}

export default felicitation