import React from 'react';
import { useNavigate } from 'react-router-dom';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const TestIntro = ({ onStartQuiz, expertises }) => {
    const navigate = useNavigate();

    const MotivationSection = ({ onStartQuiz }) => {
        return (
            <div className="flex flex-col items-center justify-center h-full text-center border-2 -border--clr-primary-300 bg-neutral-50 p-8 shadow-lg transition-transform group-hover:translate-x-2 group-hover:translate-y-2">
                <h1 className="text-4xl font-bold text-black mb-4">
                    Prêt à booster votre carrière ?
                </h1>
                <p className="text-gray-800 text-lg mb-6">
                    Répondez à ces tests pour démontrer vos compétences et améliorer vos chances d'obtenir des opportunités excitantes !
                </p>
                <button
                    onClick={onStartQuiz}
                    className="flex items-center justify-center gap-2 py-2 px-4 text-white text-xl font-semibold transition-colors"
                    style={{ 
                        backgroundColor: 'var(--clr-secondary-400)', 
                        transition: 'background-color 0.3s ease',
                        borderRadius: '0px' 
                    }}
                    onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--clr-primary-600)'}
                    onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--clr-secondary-400)'}
                >
                    <PlayArrowIcon sx={{ fontSize: '1.5em' }} />
                    Démarrer les tests
                </button>
            </div>
        );
    };

    const ExpertisesSection = ({ expertises }) => {
        if (!expertises || expertises.length === 0) {
            return <p>Aucune expertise disponible</p>;
        }

        return (
            <div className="flex flex-col mt-8">
                {expertises.map((expertise, index) => (
                    <div key={index} className="flex items-center p-2 shadow-sm mb-2">
                        <CheckCircleIcon sx={{ fontSize: '1.5em', color: 'var(--clr-primary-500)' }} />
                        <div className="ml-2">
                            <h1
                                className="mb-2"
                                style={{
                                    fontFamily: 'var(--ff-button)',
                                    fontSize: '0.8em',
                                    fontWeight: 'regular',
                                }}
                            >
                                {expertise.expertisename}
                            </h1>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const handleStartQuiz = () => {
        navigate('/teststechniques');
    };

    return (
        <div className="container mx-auto py-12">
            <div className="flex flex-col lg:flex-row lg:gap-6 min-h-full">
                <div className="lg:w-2/3 flex-grow">
                    {/* Pass onStartQuiz to MotivationSection */}
                    <MotivationSection onStartQuiz={onStartQuiz} />
                </div>
                <div className="lg:w-1/3 flex-grow mt-8 lg:mt-0">
                    <h2 className="text-2xl font-bold text-center mb-6">
                        Testez-vous sur :
                    </h2>
                    {/* Pass expertises to ExpertisesSection */}
                    <ExpertisesSection expertises={expertises} />
                </div>
            </div>
        </div>
    );
};

export default TestIntro;
