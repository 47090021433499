// AdminDashboardPage.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import "../../styles/AdminDashboardPage.css"; // Import the external CSS file
import "../../styles/AdminCard.css"; // Import the external CSS file
import { Spinner } from "react-bootstrap";
import axios from "axios"; // Import axios

import { API_GATEWAY_URL } from "../../constants";
import Layout from "../Layout";
import AdminCard from "./AdminCard"; // Import the new component
import LoadingSpinner from "../LoadingSpinner";
import Swal from "sweetalert2";

const AdminDashboardPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [workerCount, setWorkerCount] = useState(0);
  const [invitationCount, setInvitationCount] = useState(0);
  const [missionCount, setMissionCount] = useState(0);
  const [pendingValidationCount, setPendingValidationCount] = useState(0); // Placeholder for additional count
  const [completedMissionCount, setCompletedMissionCount] = useState(0); // Placeholder for additional count
  const [isProfileComplete, setIsProfileComplete] = useState(true);
  const [pendingRequestCount, setPendingRequestCount] = useState(0); // State to hold the count of pending requests
  // for the model's management
  const [options, setOptions] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [currentModelID, setCurrentModelID] = useState(null);

  const [config, setConfig] = useState({
    ia_client_search: true,
    ia_profile_search: true,
    cv_extraction_enabled: true,
    linkedin_extraction_enabled: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      console.log(user);
      if (!user) {
        setError("Aucun utilisateur connecté");
        setLoading(false);
        navigate("/admin-login");
        return;
      }
      const token = user.access_token;

      try {
        // Fetch client requests
        const response = await axios.get(
          `${API_GATEWAY_URL}/admin-client-demandes/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Filter requests that are pending
		const pendingRequests = response.data.filter(
		  (demande) => demande.statut.startsWith("W")
		);
        setPendingRequestCount(pendingRequests.length);

        // Other fetch operations here...
        axios
          .get(`${API_GATEWAY_URL}/llm-parameters/get-all-llms`)
          .then((response) => {
            setOptions(response.data["llms"]);
            const activeModel = response.data["llms"].find(
              (model) => model.actif_model
            );
            if (activeModel) {
              setSelectedModel(activeModel.model_name);
              setSelectedVersion(activeModel.model_version);
              setCurrentModelID(activeModel.model_id);
            }
            // setLoading(false);
          })
          .catch((error) => {
            setError("An error occurred while fetching the LLMs");
            // setLoading(false);
          });
        //get actual global config
        axios
          .get(`${API_GATEWAY_URL}/config/`)
          .then((response) => {
            setConfig(response.data);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching config:", error);
            setError("Failed to load configuration");
            setLoading(false);
          });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setConfig((prevConfig) => ({ ...prevConfig, [name]: checked }));
  };

  const handleSave = () => {
    axios
      .put(`${API_GATEWAY_URL}/config/`, config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Succès",
          text: "Modifications validées avec succès!",
          confirmButtonText: "OK",
          customClass: {
            popup: "swal2-front",
          },
        });
      })
      .catch((error) => {
        console.error("Error saving config:", error);
        alert("Failed to save configuration");
      });
  };

  // Function to extract the initials
  const getInitials = (name) =>
    name
      .split(" ")
      .map((n) => n[0])
      .join("");

  // Managing the Models

  const handle_model_change = async (event) => {
    const newModelName = event.target.value;
    setSelectedModel(newModelName);
    setSelectedVersion(null);
    const id = getModelIdByName(newModelName);
    if (id) {
      setCurrentModelID(id);
    }
  };

  const handle_version_change = async (event) => {
    const newVersion = event.target.value;
    setSelectedVersion(newVersion);
    const id = getModelIdByVersion(newVersion);
    if (id) {
      setCurrentModelID(id);
    }
  };

  const handleSetCurrentModel = async (event) => {
    setLoading(true);
    setError(null);
    try {
      const result = await axios.get(
        `${API_GATEWAY_URL}/llm-parameters/get-preferred-llm`
      );
      setSelectedModel(result.data["model_name"]);
      setSelectedVersion(result.data["model_version"]);
      if (getModelIdByName(result.data["model_name"])) {
        setCurrentModelID(getModelIdByName(result.data["model_name"]));
      } else {
        setCurrentModelID(getModelIdByVersion(result.data["model_version"]));
      }
    } catch (err) {
      setError("An error occurred while changin the LLM");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitModel = async (event) => {
    setLoading(true);
    setError(null);
    try {
      const result = await axios.post(
        `${API_GATEWAY_URL}/llm-parameters/update-actif-llm`,
        {
          activated_id: currentModelID,
        }
      );
    } catch (err) {
      setError("An error occurred while changin the LLM");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getModelIdByName = (modelName) => {
    const selectedOption = options.find(
      (option) =>
        option.model_name === modelName && option.model_version === null
    );
    return selectedOption ? selectedOption.model_id : null;
  };

  const getModelIdByVersion = (modelVersion) => {
    const selectedOption = options.find(
      (option) =>
        option.model_name === selectedModel &&
        option.model_version === modelVersion
    );
    return selectedOption ? selectedOption.model_id : null;
  };

  const uniqueModels = [...new Set(options.map((option) => option.model_name))];
  const versions = options
    .filter(
      (option) =>
        option.model_name === selectedModel && option.model_version !== null
    )
    .map((option) => option.model_version);

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }

  return (
    <div className="pw-page-layout">
      <AdminCard />
      <div className="missions-and-team-container">
        <div className="section-container mission-section">
          <h2>Mes demandes</h2>
          <div className="pw-additional-info">
            <div className="pw-card pw-card-info">
              <div className="pw-card-title">Demandes en attente</div>
              <div className="pw-card-count">{pendingRequestCount}</div>{" "}
              {/* Display the pending requests count */}
            </div>
            <div className="pw-card pw-card-pending">
              <div className="pw-card-title">Demandes en cours</div>
              <div className="pw-card-count"></div>
            </div>
            <div className="pw-card pw-card-pending">
              <div className="pw-card-title">
                Demandes en attente d'évaluation
              </div>
              <div className="pw-card-count"></div>
            </div>
            <div className="pw-card pw-card-mission">
              <div className="pw-card-title">Demandes terminées</div>
              <div className="pw-card-count"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[100%] w-[100%]  flex flex-col md:flex-row justify-around items-center">
        {/* controll for the global config */}
        <div>
          <h2 className="mb-4">Configuration Globale : </h2>
          <form>
            <div>
              <label>
                <input
                  type="checkbox"
                  className="mb-2"
                  name="ia_client_search"
                  checked={config.ia_client_search}
                  onChange={handleChange}
                />
                Activer la recherche de compétences par l'IA (Client)
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  name="ia_profile_search"
                  className="mb-2"
                  checked={config.ia_profile_search}
                  onChange={handleChange}
                />
                Activer la recherche de compétences par l'IA (autres profiles)
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  name="cv_extraction_enabled"
                  className="mb-2"
                  checked={config.cv_extraction_enabled}
                  onChange={handleChange}
                />
                Activer l'extraction de données à partir des CV
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  name="linkedin_extraction_enabled"
                  className="mb-4"
                  checked={config.linkedin_extraction_enabled}
                  onChange={handleChange}
                />
                Activer l'extraction de données à partir de LinkedIn
              </label>
            </div>
            <button
              className="bg-indigo-600"
              type="button"
              onClick={handleSave}
            >
              Save Configuration
            </button>
          </form>
        </div>
        {/* LLM slection section */}
        <form className="ml-200 bg-gray-50 rounded-lg shadow-md p-4">
          <div className="mb-4">
            <label htmlFor="model-select" className="pw-card-title block mb-2">
              Select Model
            </label>
            <select
              id="model-select"
              name="model"
              value={selectedModel || ""}
              onChange={handle_model_change}
              className="mt-1 block w-full pl-3 pr-10 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">Select a model</option>
              {uniqueModels.map((model) => (
                <option key={model} value={model}>
                  {model}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="version-select"
              className="pw-card-title block mb-2"
            >
              Select Version
            </label>
            <select
              id="version-select"
              name="version"
              value={selectedVersion || ""}
              onChange={handle_version_change}
              disabled={!selectedModel || versions.length === 0}
              className="mt-1 block w-full pl-3 pr-10 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-gray-100 disabled:cursor-not-allowed"
            >
              <option value="">Select a version</option>
              {versions.map((version) => (
                <option key={version} value={version}>
                  {version}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <button
              type="button"
              className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
              onClick={handleSubmitModel}
              disabled={
                (selectedModel && versions.length > 0 && !selectedVersion) ||
                !selectedModel
              }
            >
              Apply Changese
            </button>
          </div>
          <div className="mb-4">
            <button
              type="button"
              className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleSetCurrentModel}
            >
              Set to current
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminDashboardPage;
