import React, {useState, useEffect} from 'react';
import { useForm } from 'react-hook-form';
import '../../index.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog, DialogTitle, DialogContent,
    Button, TextField
} from '@mui/material';

import { API_GATEWAY_URL } from '../../constants';
import { useAuth } from '../../components/AuthContext';
import { set } from 'date-fns';




const RechercheDeForm = ({ user, handleCancel, best_project, work_preference, setLookingFor }) => {
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const onSubmit = async (data) => {
        try {
            const response = await fetch(`${API_GATEWAY_URL}/preferences/`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`
                },
                body: JSON.stringify(
                    {
                        looking_for: data.rechercheDe,
                        best_project: best_project,
                        work_preference: work_preference
                    }
                )
            });
            if (response.ok) {
                setLookingFor(data.rechercheDe);
                handleCancel();
                return await response.json();
            } else {
                throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
            }
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <form 
            className='flex flex-col gap-2'
            onSubmit={handleSubmit(onSubmit)}
        >
            <TextField
                label="Je suis à la recherche de ..."
                {...register('rechercheDe', { required: 'Ajouter une description' })}
                error={!!errors.rechercheDe}
                helperText={errors.rechercheDe ? errors.rechercheDe.message : ''}
                margin="normal"
            />
            <div className="flex items-center gap-3 mt-3">
                <Button
                    variant="contained"
                    type="submit"
                    color='success'
                    sx={{
                        fontWeight: 'var(--fw-semibold)',
                        fontSize: '.8rem',
                    }}
                >
                    Sauvegarder
                </Button>
                <Button 
                    variant="text"
                    color='error'
                    sx={{
                        fontWeight: 'var(--fw-semibold)',
                        fontSize: '.8rem',
                    }}
                    onClick={handleCancel}
                >
                    Annuler
                </Button>
            </div>
        </form>
    );
}

const AddRechercheDe= ({ user, open, handleClose, best_project, work_preference, setLookingFor }) => {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>À la recherche de </DialogTitle>
            <DialogContent>
                <RechercheDeForm 
                    user={user}
                    handleCancel={handleClose}
                    best_project={best_project}
                    work_preference={work_preference}
                    setLookingFor={setLookingFor}
                />
            </DialogContent>
        </Dialog>
    );
};

const PersonalJobPreferencesForm = ({ user, lookingFor, bestProject, handleCancel, setPersonalJobPreferences }) => {
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const onSubmit = async (data) => {
        try {
            const response = await fetch(`${API_GATEWAY_URL}/preferences/`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`
                },
                body: JSON.stringify(
                    {
                        looking_for: lookingFor,
                        best_project: bestProject,
                        work_preference: data.personalJobPreferences
                    }
                )
            });
            if (response.ok) {
                setPersonalJobPreferences(data.personalJobPreferences);
                handleCancel();
                return await response.json();
            } else {
                throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
            }
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <form
            className='flex flex-col gap-2'
            onSubmit={handleSubmit(onSubmit)}
        >
            <TextField
                label="Préférences personnelles en matière d'emploi ..."
                {...register('personalJobPreferences', { required: 'Ajouter une description' })}
                error={!!errors.personalJobPreferences}
                helperText={errors.personalJobPreferences ? errors.personalJobPreferences.message : ''}
                margin="normal"
            />
            <div className="flex items-center gap-3 mt-3">
                <Button
                    variant="contained"
                    type="submit"
                    color='success'
                    sx={{
                        fontWeight: 'var(--fw-semibold)',
                        fontSize: '.8rem',
                    }}
                >
                    Sauvegarder
                </Button>
                <Button
                    variant="text"
                    color='error'
                    sx={{
                        fontWeight: 'var(--fw-semibold)',
                        fontSize: '.8rem',
                    }}
                    onClick={handleCancel}
                >
                    Annuler
                </Button>
            </div>
        </form>
    );
};

const AddPersonalJobPreferences = ({ user, lookingFor, bestProject, open, handleClose, setPersonalJobPreferences }) => {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Préférences personnelles en matière d'emploi</DialogTitle>
            <DialogContent>
                <PersonalJobPreferencesForm
                    handleCancel={handleClose}
                    user = {user}
                    lookingFor = {lookingFor}
                    bestProject = {bestProject}
                    setPersonalJobPreferences = {setPersonalJobPreferences}
                />
            </DialogContent>
        </Dialog>
    );
};

const AmazingThingIDidForm = ({ user, lookingFor, workPreference, handleCancel, setAmazingThingIDid }) => {
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const onSubmit = async (data) => {
        try {
            const response = await fetch(`${API_GATEWAY_URL}/preferences/`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`
                },
                body: JSON.stringify(
                    {
                        looking_for: lookingFor,
                        best_project: data.best_project,
                        work_preference: workPreference
                    }
                )
            });
            if (response.ok) {
                setAmazingThingIDid(data.best_project);
                handleCancel();
                return await response.json();
            } else {
                throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
            }
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <form
            className='flex flex-col gap-2'
            onSubmit={handleSubmit(onSubmit)}
        >
            <TextField
                label="La chose incroyable que j'ai faite ..."
                {...register('best_project', { required: 'Ajouter une description' })}
                error={!!errors.best_project}
                helperText={errors.best_project ? errors.best_project.message : ''}
                margin="normal"
            />
            <div className="flex items-center gap-3 mt-3">
                <Button

                    variant="contained"
                    type="submit"
                    color='success'
                    sx={{
                        fontWeight: 'var(--fw-semibold)',
                        fontSize: '.8rem',
                    }}
                >
                    Sauvegarder
                </Button>
                <Button
                    variant="text"
                    color='error'
                    sx={{
                        fontWeight: 'var(--fw-semibold)',
                        fontSize: '.8rem',
                    }}
                    onClick={handleCancel}
                >
                    Annuler
                </Button>
            </div>
        </form>
    );
};  

const AddAmazingThingIDid = ({ user, lookingFor, workPreference, open, handleClose, setAmazingThingIDid }) => {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>La chose incroyable que j'ai faite</DialogTitle>
            <DialogContent>
                <AmazingThingIDidForm
                    user={user}
                    lookingFor={lookingFor}
                    workPreference={workPreference}
                    handleCancel={handleClose}
                    setAmazingThingIDid={setAmazingThingIDid}
                />
            </DialogContent>
        </Dialog>
    );
};


const Preferences = () => {
    const [activeSectionOne, setActiveSectionOne] = useState(1); 
    const [openRechercheDe, setOpenRechercheDe] = useState(false);
    const [openPersonalJobPreferences, setOpenPersonalJobPreferences] = useState(false);
    const [openAmazingThingIDid, setOpenAmazingThingIDid] = useState(false);

    const { user } = useAuth();
    const [lookingFor, setLookingFor] = useState('');
    const [personalJobPreferences, setPersonalJobPreferences] = useState('');
    const [amazingThingIDid, setAmazingThingIDid] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleButtonOneClick = () => {
        setActiveSectionOne(prevSection => prevSection === 0 ? 1 : 0);
    };

    const handleRechercheDeOpen = () => setOpenRechercheDe(true);
    const handleRechercheDeClose = () => setOpenRechercheDe(false);

    const handlePersonalJobPreferencesOpen = () => setOpenPersonalJobPreferences(true);
    const handlePersonalJobPreferencesClose = () => setOpenPersonalJobPreferences(false);

    const handleAmazingThingIDidOpen = () => setOpenAmazingThingIDid(true);
    const handleAmazingThingIDidClose = () => setOpenAmazingThingIDid(false);


    useEffect(() => {
        const fetchFreelancerData = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/preferences/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log('Freelancer Preferences:', data);
                    setLookingFor(data.looking_for);
                    setPersonalJobPreferences(data.work_preference);
                    setAmazingThingIDid(data.best_project);
                    console.log(lookingFor, personalJobPreferences, amazingThingIDid);
                } else {
                throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerData();
        }
    }, [user]);

    return (
        <main 
            className="w-full flex flex-col gap-4 mt-4"
            style={{fontFamily: 'var(--ff-body)',}}
        >
            <h3 className='hidden md:block'>Réglages des Preferences</h3>
            <div
                className='py-4 px-8 rounded-lg w-full border-2 border-gray-200'
            >
                <header
                    className='pb-3 pt-2 flex justify-between items-center'
                >
                    <h2 className='mb-2'>Preference de travail</h2>
                    {
                        activeSectionOne ? (
                            <button 
                                className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100`}
                                onClick={handleButtonOneClick}
                                style={{
                                    width: '2rem',
                                    height: '2rem',
                                    border: '1px solid var(--clr-neutral-300)',
                                }}
                            >
                                <EditOutlinedIcon 
                                    className='-text--clr-secondary-500'
                                    style={{
                                        width: '1.2rem',
                                        height: '1.2rem',
                                    }}
                                />
                            </button>
                        ) : (
                            <button 
                                className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100`}
                                onClick={handleButtonOneClick}
                                style={{
                                    width: '2rem',
                                    height: '2rem',
                                    border: '1px solid var(--clr-neutral-300)',
                                }}
                            >
                                <CloseIcon 
                                    className='-text--clr-secondary-500'
                                    style={{
                                        width: '1.2rem',
                                        height: '1.2rem',
                                    }}
                                />
                            </button>
                        )
                    }
                </header>
                <section className='flex flex-col gap-2 mb-3'>
                    <div 
                        className='mb-4'
                        style={{
                            fontFamily: 'var(--ff-body)',
                        }} 
                    >
                        <div className="flex justify-between">
                            <div 
                                style={{
                                    fontFamily: 'var(--ff-body)',
                                    fontWeight: 'var(--fw-semibold)',
                                    fontSize: '1.2rem',
                                    color: 'var(--clr-primary-500)',
                                }} 
                            >
                                    Je suis actuellement à la recherche de...
                            </div>
                            {
                                !activeSectionOne ? (
                                    <button 
                                        className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100`}
                                        onClick={handleRechercheDeOpen}
                                        style={{
                                            width: '2rem',
                                            height: '2rem',
                                            border: '1px solid var(--clr-neutral-300)',
                                        }}
                                    >
                                        <EditOutlinedIcon 
                                            className='-text--clr-secondary-500'
                                            style={{
                                                width: '1.2rem',
                                                height: '1.2rem',
                                            }}
                                        />
                                    </button>
                                ) : null
                            }
                        </div>
                        {
                            openRechercheDe && < AddRechercheDe user={user} open={openRechercheDe} handleClose={handleRechercheDeClose} best_project={amazingThingIDid} work_preference={personalJobPreferences} setLookingFor={setLookingFor} />
                        }
                            <div 
                                style={{
                                    fontFamily: 'var(--ff-body)',
                                    fontWeight: 'var(--fw-medium)',
                                    fontSize: '1rem',
                                }} 
                            >
                                ... {lookingFor}
                            </div>                            
                    </div>
                    <div 
                        className='mb-4'
                        style={{
                            fontFamily: 'var(--ff-body)',
                        }} 
                    >
                        <div className="flex justify-between">
                            <div 
                                style={{
                                    fontFamily: 'var(--ff-body)',
                                    fontWeight: 'var(--fw-semibold)',
                                    fontSize: '1.2rem',
                                    color: 'var(--clr-primary-500)',
                                }} 
                            >
                                    Préférences personnelles en matière d'emploi ...
                            </div>
                            {
                                !activeSectionOne ? (
                                    <button 
                                        className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100`}
                                        onClick={handlePersonalJobPreferencesOpen}
                                        style={{
                                            width: '2rem',
                                            height: '2rem',
                                            border: '1px solid var(--clr-neutral-300)',
                                        }}
                                    >
                                        <EditOutlinedIcon 
                                            className='-text--clr-secondary-500'
                                            style={{
                                                width: '1.2rem',
                                                height: '1.2rem',
                                            }}
                                        />
                                    </button>
                                ) : null
                            }
                        </div>
                        <div 
                            style={{
                                fontFamily: 'var(--ff-body)',
                                fontWeight: 'var(--fw-medium)',
                                fontSize: '1rem',
                                marginTop: '-.3rem',
                                marginBottom: '.3rem',
                            }} 
                        >
                            ... {personalJobPreferences}
                        </div>
                        {
                            openPersonalJobPreferences && <AddPersonalJobPreferences user={user} open={openPersonalJobPreferences} handleClose={handlePersonalJobPreferencesClose} lookingFor={lookingFor} bestProject={amazingThingIDid} setPersonalJobPreferences={setPersonalJobPreferences} />
                        }
                    </div>
                    <div 
                        className='mb-4'
                        style={{
                            fontFamily: 'var(--ff-body)',
                        }} 
                    >
                        <div className="flex justify-between">
                            <div 
                                style={{
                                    fontFamily: 'var(--ff-body)',
                                    fontWeight: 'var(--fw-semibold)',
                                    fontSize: '1.2rem',
                                    color: 'var(--clr-primary-500)',
                                }} 
                            >
                                    La chose incroyable que j'ai faite ...
                            </div>
                            {
                                !activeSectionOne ? (
                                    <button 
                                        className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100`}
                                        onClick={handleAmazingThingIDidOpen}
                                        style={{
                                            width: '2rem',
                                            height: '2rem',
                                            border: '1px solid var(--clr-neutral-300)',
                                        }}
                                    >
                                        <EditOutlinedIcon 
                                            className='-text--clr-secondary-500'
                                            style={{
                                                width: '1.2rem',
                                                height: '1.2rem',
                                            }}
                                        />
                                    </button>
                                ) : null
                            }
                        </div>
                        <div 
                            style={{
                                fontFamily: 'var(--ff-body)',
                                fontWeight: 'var(--fw-medium)',
                                fontSize: '1rem',
                                marginTop: '-.3rem',
                                marginBottom: '.3rem',
                            }} 
                        >
                            ... {amazingThingIDid}
                        </div>
                        {
                            openAmazingThingIDid && <AddAmazingThingIDid user={user} open={openAmazingThingIDid} handleClose={handleAmazingThingIDidClose} lookingFor={lookingFor} workPreference={personalJobPreferences} setAmazingThingIDid={setAmazingThingIDid}/>
                        }
                    </div>
                </section>
            </div>
        </main>
    )
}

export default Preferences;