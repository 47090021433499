import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import Modal from "react-modal";
import axios from 'axios';
import Swal from 'sweetalert2';
import '../../styles/FreelancerCalendar.css';
import { API_GATEWAY_URL } from '../../constants.js';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Checkbox,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormGroup,
  FormLabel,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';



const FreelancerCalendar = ({ user }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availabilities, setAvailabilities] = useState([]);
  const [currentMissions, setCurrentMissions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
    partOfDay: "morning",
    recurrence: [],
    selectDays: false,
    recurrenceType: "hebdomadaire",
  });

  // Fetch availabilities from backend
  const fetchAvailabilities = async () => {
    try {
      const response = await axios.get(`${API_GATEWAY_URL}/availabilities/`, {
        headers: { Authorization: `Bearer ${user.access_token}` },
      });
      setAvailabilities(response.data);
    } catch (error) {
      console.error('Failed to fetch availabilities', error);
    }
  };

  // Fetch current missions from backend
  const fetchCurrentMissions = async () => {
    try {
      const response = await axios.get(`${API_GATEWAY_URL}/freelancerDashboard/`, {
        headers: { Authorization: `Bearer ${user.access_token}` },
      });
      setCurrentMissions(response.data.current_missions.missions);
    } catch (error) {
      console.error('Failed to fetch current missions', error);
    }
  };

  useEffect(() => {
    fetchAvailabilities();
    fetchCurrentMissions();
  }, [user]);
  
  // Fonction pour calculer le numéro de la semaine dans l'année
function getWeekNumber(date) {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

// Fonction pour vérifier si la semaine est bihebdomadaire
function isBiweekly(date, startDate) {
  const currentWeek = getWeekNumber(date);
  const startWeek = getWeekNumber(new Date(startDate));
  
  // Si la différence de semaines entre la date courante et la date de début est paire, c'est bihebdomadaire
  return (currentWeek - startWeek) % 2 === 0;
}


  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const newAvailability = {
        start: formData.startDate,
        end: formData.endDate,
        part_of_day: formData.partOfDay,
        recurrence: {
          days: formData.recurrence,
          frequency: formData.selectDays ? formData.recurrenceType : '',
        },
      };
      await axios.post(`${API_GATEWAY_URL}/availabilities/`, newAvailability, {
        headers: { Authorization: `Bearer ${user.access_token}` },
      });
      
      fetchAvailabilities(); // Refresh availabilities
      
      closeModal();
      Swal.fire('Succès', 'Disponibilité ajoutée.', 'success');
    } catch (error) {
      Swal.fire('Erreur', 'Échec de l\'ajout de la disponibilité.', 'error');
    }
  };

  const handleDeleteAvailability = async (id) => {
    try {
      await axios.delete(`${API_GATEWAY_URL}/availability-delete/${id}/`, {
        headers: { Authorization: `Bearer ${user.access_token}` },
      });
      
      // Mettre à jour l'état local des disponibilités
      setAvailabilities(prevAvailabilities => 
        prevAvailabilities.filter(avail => avail.id !== id)
      );
      
      // Afficher le message de succès
      Swal.fire({
        title: 'Succès',
        text: 'Disponibilité supprimée.',
        icon: 'success',
        confirmButtonText: 'OK',
        customClass: {
          container: 'swal-overlay',
          popup: 'swal-popup',
          confirmButton: 'swal-button'
        }
      });
    } catch (error) {
      console.error('Erreur lors de la suppression de la disponibilité', error);
      Swal.fire({
        title: 'Erreur',
        text: 'Échec de la suppression de la disponibilité.',
        icon: 'error',
        confirmButtonText: 'OK',
        customClass: {
          container: 'swal-overlay',
          popup: 'swal-popup',
          confirmButton: 'swal-button'
        }
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const isDateInAvailability = (date, avail) => {
    const startDate = new Date(avail.start);
    const endDate = new Date(avail.end);
    if (date < startDate || date > endDate) return false;
    if (!avail.recurrence.days.length) return true;

    const dayOfWeek = date.getDay();
    // Si la récurrence est bihebdomadaire, on vérifie les semaines
    if (avail.recurrence.frequency === "bihebdomadaire" && !isBiweekly(date, avail.start)) {
    return false; // Si ce n'est pas une semaine valide pour la bihebdomadaire
    }
    return avail.recurrence.days.includes(dayOfWeek);
  };

  const isDateInMission = (date) => {
    return currentMissions.some(mission => {
      const startDate = new Date(mission.date_debut);
      const endDate = new Date(mission.date_fin);
      return date >= startDate && date <= endDate;
    });
  };

  const tileClassName = ({ date }) => {
    const dateAvailabilities = availabilities.filter(avail => isDateInAvailability(date, avail));

    if (isDateInMission(date)) {
      return "occupied";
    }
    
    if (dateAvailabilities.length === 0) return "unavailable";
    
    const hasMorning = dateAvailabilities.some(avail => avail.part_of_day === "morning");
    const hasAfternoon = dateAvailabilities.some(avail => avail.part_of_day === "afternoon");
    const hasFullDay = dateAvailabilities.some(avail => avail.part_of_day === "full-day");
  
    if (hasFullDay || (hasMorning && hasAfternoon)) {
      return "availability-box full-day";
    } else if (hasMorning) {
      return "availability-box morning";
    } else if (hasAfternoon) {
      return "availability-box afternoon";
    }
    
    return "unavailable";
  };

  return (
    <div>
      <div className="calendar-container">
      <Calendar
        onChange={setSelectedDate}
        value={selectedDate}
        /*tileContent={tileContent}*/
        tileClassName={tileClassName} 
        className="custom-calendar"
        showNeighboringMonth={true}
      />

      <button onClick={openModal} className="btn-modify">Modifier votre planning</button>
      </div>

      <Dialog open={isModalOpen} onClose={closeModal} fullWidth maxWidth='sm'>
  <DialogTitle>Ajouter une disponibilité</DialogTitle>
  <DialogContent>
  <FormControl fullWidth margin="normal">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Date de début"
        value={dayjs(formData.startDate)}
        onChange={(date) => handleInputChange({ target: { name: 'startDate', value: date.toISOString() } })}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  </FormControl>
  <FormControl fullWidth margin="normal">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Date de fin"
        value={dayjs(formData.endDate)}
        onChange={(date) => handleInputChange({ target: { name: 'endDate', value: date.toISOString() } })}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  </FormControl>
  <FormControl fullWidth margin="normal">
    <FormLabel>Partie de la journée</FormLabel>
    <RadioGroup
      row
      name="partOfDay"
      value={formData.partOfDay}
      onChange={handleInputChange}
    >
      <FormControlLabel value="morning" control={<Radio />} label="Matin" />
      <FormControlLabel value="afternoon" control={<Radio />} label="Après-midi" />
      <FormControlLabel value="full-day" control={<Radio />} label="Journée entière" />
    </RadioGroup>
  </FormControl>
  <FormControl fullWidth margin="normal">
    <FormControlLabel
      control={
        <Checkbox
          checked={formData.selectDays}
          onChange={handleInputChange}
          name="selectDays"
        />
      }
      label="Choisir les jours"
    />
  </FormControl>
  {formData.selectDays && (
    <>
      <FormControl fullWidth margin="normal">
        <InputLabel id="recurrence-type-label">Récurrence</InputLabel>
        <Select
          labelId="recurrence-type-label"
          name="recurrenceType"
          value={formData.recurrenceType}
          onChange={handleInputChange}
        >
          <MenuItem value="hebdomadaire">Hebdomadaire</MenuItem>
          <MenuItem value="bihebdomadaire">Bihebdomadaire</MenuItem>
        </Select>
      </FormControl>
      <FormControl component="fieldset" fullWidth margin="normal">
        <FormLabel component="legend">Jours de la semaine</FormLabel>
        <FormGroup row>
          {["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"].map((day, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={formData.recurrence.includes(index)}
                  onChange={(e) => {
                    const { checked, value } = e.target;
                    setFormData((prevData) => ({
                      ...prevData,
                      recurrence: checked
                        ? [...prevData.recurrence, parseInt(value)]
                        : prevData.recurrence.filter((d) => d !== parseInt(value)),
                    }));
                  }}
                  value={index}
                />
              }
              label={day}
            />
          ))}
        </FormGroup>
      </FormControl>
    </>
  )}
  <TableContainer component={Paper}>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Date Début</TableCell>
        <TableCell>Date Fin</TableCell>
        <TableCell>Partie de la Journée</TableCell>
        <TableCell>Récurrence</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
  {availabilities.map((avail) => (
    <TableRow key={avail.id}>
      <TableCell>{dayjs(avail.start).format('DD-MM-YYYY')}</TableCell>
      <TableCell>{dayjs(avail.end).format('DD-MM-YYYY')}</TableCell>

      <TableCell>{avail.part_of_day}</TableCell>
      <TableCell>{avail.recurrence.days.length > 0 ? avail.recurrence.frequency : "N/A"}</TableCell>
      <TableCell>
      <Button
          onClick={() => handleDeleteAvailability(avail.id)}
          color="error"
          sx={{
            backgroundColor: 'var(--clr-danger-300)',  // Set your desired background color
            color: 'var(--clr-white)',                 // Set the text color
            '&:hover': {
              backgroundColor: 'var(--clr-danger-500)',  // Change color on hover
            },
          }}
        >
          Supprimer
        </Button>
      </TableCell>
    </TableRow>
  ))}
</TableBody>
  </Table>
</TableContainer>
  </DialogContent>
  
  <DialogActions>
    <Button onClick={closeModal} sx={{
      fontWeight: 'var(--fw-medium)',
      color: 'var(--clr-danger-500)',
      '&:hover': {
        backgroundColor: 'var(--clr-danger-400)',
        color: 'var(--clr-white)',
      },
    }}>
      Retour
    </Button>
    <Button onClick={handleFormSubmit} variant="contained" sx={{
      fontWeight: 'var(--fw-medium)',
      bgcolor: 'var(--clr-success-400)',
      '&:hover': {
        backgroundColor: 'var(--clr-success-500)',
      },
    }}>
      Ajouter la disponibilité
    </Button>
  </DialogActions>
</Dialog>
    </div>
  );
};

export default FreelancerCalendar;
