import React, { useState, useEffect } from 'react';
import { useAuth } from '../../components/AuthContext';
import { useNavigate } from 'react-router-dom';
import { API_GATEWAY_URL } from '../../constants';
import dayjs from 'dayjs';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoadingSpinner from '../../components/LoadingSpinner'; 
import ShapePath from '../../assets/customShape.svg';
import FreelancerCalendar from './FreelancerCalendar';
import 
{
    Menu, 
    IntroProfile,
    ProfileDescription,  
    Cards, 
    Badges,  
    Calendar,
    CTA, 
    ReviewsSection, 
    WorkExperienceSection, 
    EducationCertificates,
    VisitLinks,
    ShapeComponent,
} from '../../components';

import TestIntro from '../../components/freelancerWorkspaceComponents/TestIntro.js';



const getInitials = (name) => name.split(' ').map((n) => n[0]).join('');

const ProfileContent = ({ 
    user,
    freelancerData,
    freelancerSkills,

    freelancerAvailabilityDays,

    freelancerWorkExperience,
    freelancerProjects,

    freelancerEducation,
    freelancerCertificates,

    lookingFor,
    personalJobPreferences,
    amazingThingIDid,

    freelancerLinks,
    

}) => {
    const navigate = useNavigate();
    const { logout } = useAuth();

    const handleLogout = () => {
        logout();
        navigate('/');
    };


    const handleStartQuiz = () => {
        navigate('/teststechniques'); 
    };




    const contenusJobs = Array.isArray(freelancerWorkExperience)
        ? freelancerWorkExperience.map((experience) => ({
            jobTitle: experience.poste_dans_entreprise,
            company: experience.nom_entreprise,
            dateDebut: experience.date_debut,
            dateFin: experience.date_fin,
            responsibilities: [experience.description_de_poste]
        }))
        : [];
    const contenusProjects = Array.isArray(freelancerProjects)
        ? freelancerProjects.map((project) => ({
            projectName: project.title,
            dateDebut: project.start_date,
            dateFin: project.end_date,
            projectResponsibilities: [project.description, project.role_specifique]
        }))
        : [];

       
    
    const contenusEducation = Array.isArray(freelancerEducation)
        ? freelancerEducation.map((education) => ({
            educationTitle: `${education.diploma_type} ${education.field_of_study}`,
            dateDebut: education.start_date,
            dateFin: education.end_date,
            instituteName: education.school_name,
            instituteLocation: education.school_location, 
            description: education.description
        }))
        : [];
    const contenusCertifications = Array.isArray(freelancerCertificates)
        ? freelancerCertificates.map((certificate) => ({
            certificationName: certificate.title,
            dateFin: certificate.date,
            certificationInstitute: certificate.platform,
            certificationLocation: '',
            certificationDescription: certificate.description
        }))
        : [];
    
    
    const freelancerLinksData = Array.isArray(freelancerLinks)
        ? freelancerLinks.reduce((acc, link) => {
            if (link.type === 'LINKEDIN') {
                acc.linkedinLink = link.url;
            } else if (link.type === 'GITHUB') {
                acc.githubLink = link.url;
            }
            return acc;
        }, {})
        : {};
    const { linkedinLink = '', githubLink = '' } = freelancerLinksData;


    return (
        <div 
            style={{width: '100%', paddingBlock: '12px'}}
        >
            <div 
                className='container flex flex-col gap-4'
            >
                <Menu imageId={freelancerData.profilePicture} user={user}/>
                <div className='section-one flex flex-col items-center gap-4 lg:flex-row lg:items-start lg:justify-between mt-3'>
                    <div className="flex flex-col gap-2">
                        <IntroProfile 
                            firstName= {freelancerData.firstName}
                            lastName= {freelancerData.lastName}
                            jobTitle= {freelancerData.profilTitle}
                            country= {freelancerData.country}
                            province= {freelancerData.ville}
                        />
                        <ProfileDescription 
                            description= {freelancerData.description}
                        />
                        <Badges 
                            badgeTitles={freelancerSkills || []}
                        />
                       
                    </div>
                    <div className='flex flex-col items-end gap-2'>
                        {console.log("freelancerAvailabilityDays : " ,freelancerAvailabilityDays)}
                        {/*<Calendar 
                            AvailabilityDays = {freelancerAvailabilityDays}
                        />
                        <CTA CTAMessage={"Modifiez votre planning"} user={user}/>*/}
                         <FreelancerCalendar user={user}/>
                        
                    </div>
                    </div>
                    <TestIntro onStartQuiz={handleStartQuiz} expertises={freelancerSkills} />
                    <Cards
                        cardsContent={[
                            {
                                title: "Je suis à la recherche de ...",
                                content: lookingFor
                            },
                            {
                                title: "Préférences personnelles en matière d'emploi ...",
                                content: personalJobPreferences
                            },
                            {
                                title: "La chose incroyable que j'ai faite ...",
                                content: amazingThingIDid
                            }
                        ]}
                    />

                    <div className="section-two flex flex-col gap-4 my-4">
                        <WorkExperienceSection
                            contenusJobs={contenusJobs}
                            contenusProjects={contenusProjects}
                        />

                        <EducationCertificates
                            contenusEducation={contenusEducation}
                            contenusCertifications={contenusCertifications}
                        />
                        <ReviewsSection 
                            averageRating={4.5}
                            numberOfReviews={20}
                            statsOfReviewsFiveStars={10}
                            statsOfReviewsFourStars={5}
                            statsOfReviewsThreeStars={2}
                            statsOfReviewsTwoStars={2}
                            statsOfReviewsOneStar={1}
                            clientFeedbacksInfos= {
                                [
                                    {
                                        clientName: 'John Doe',
                                        avatarIconPath: 'https://www.w3schools.com/howto/img_avatar.png',
                                        rating: 4,
                                        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                                    },
                                    {
                                        clientName: 'John Doe',
                                        avatarIconPath: 'https://www.w3schools.com/howto/img_avatar.png',
                                        rating: 4,
                                        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                                    },
                                    {
                                        clientName: 'John Doe',
                                        avatarIconPath: 'https://www.w3schools.com/howto/img_avatar.png',
                                        rating: 4,
                                        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                                    },
                                    {
                                        clientName: 'John Doe',
                                        avatarIconPath: 'https://www.w3schools.com/howto/img_avatar.png',
                                        rating: 4,
                                        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                                    },
                                ]
                            }
                        />
                    </div>
                    <VisitLinks 
                        linkedinLink={linkedinLink}
                        githubLink={githubLink}
                    />

            </div>
            <ShapeComponent ShapePath={ShapePath}/>
        </div>
    )
}


const Profile = () => {
    const { user } = useAuth();

    const [freelancerData, setFreelancerData] = useState(null);
    const [freelancerSkills, setFreelancerSkills] = useState(null);
    // ImageComponent(freelancerData.profilePicture)
    const [freelancerAvailabilityDays, setFreelancerAvailabilityDays] = useState(null);
    
    const [freelancerWorkExperience, setFreelancerWorkExperience] = useState(null);
    const [freelancerProjects, setFreelancerProjects] = useState(null);

    const [freelancerEducation, setFreelancerEducation] = useState(null);
    const [freelancerCertificates, setFreelancerCertificates] = useState(null);
    
    const [freelancerLinks, setFreelancerLinks] = useState(null);
    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [lookingFor, setLookingFor] = useState();
    const [personalJobPreferences, setPersonalJobPreferences] = useState();
    const [amazingThingIDid, setAmazingThingIDid] = useState();

    useEffect(() => {
        const fetchFreelancerData = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/freelancer-profile/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setFreelancerData(data);
                } else {
                throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerData();
        }
        const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds
        if (user.access_token && user.access_token.exp > currentTime) {
            console.log("Token is valid, making the request...");
        } else {
            console.log("Token has expired, please log in again.");
            return;
        }
    }, [user]);




    useEffect(() => {
        const fetchFreelancerSkills = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/expertisesf/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setFreelancerSkills(data);
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerSkills();
        }
    }, [user]);
    
    

    useEffect(() => {
        const fetchFreelancerAvailabilityDays = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/availabilities/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setFreelancerAvailabilityDays(data);
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerAvailabilityDays();
        }
    }, [user]);




    useEffect(() => {
        const fetchFreelancerWorkExperience = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/experiences/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setFreelancerWorkExperience(data);
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerWorkExperience();
        }
    }, [user]);




    useEffect(() => {
        const fetchFreelancerProjects = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/projects/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    const projectsForm = data.map( projectInfos => {
                        return {
                            title: projectInfos.title,
                            start_date: projectInfos.start_date,
                            end_date: projectInfos.end_date,
                            role_specifique: projectInfos.role_specifique,
                            description: projectInfos.description,
                            technologie: projectInfos.technologie
                        }
                    })
                    setFreelancerProjects(projectsForm);
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerProjects();
        }
    }, [user]);




    useEffect(() => {
        const fetchFreelancerEducation = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/degrees/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    const educationsForm = data.map( educationInfos => {
                        return {
                            diploma_type: educationInfos.diploma_type,
                            field_of_study: educationInfos.field_of_study,
                            start_date: educationInfos.start_date,
                            end_date: educationInfos.end_date,
                            school_name: educationInfos.school.name,
                            school_location: educationInfos.school.location,
                            description: educationInfos.description
                        }
                    })
                    setFreelancerEducation(educationsForm)
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerEducation();
        }
    }, [user]);




    useEffect(() => {
        const fetchFreelancerCertificates = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/certificates/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    const certificatesForm = data.map( certificateInfos => {
                        return {
                            title: certificateInfos.name,
                            platform: certificateInfos.platform,
                            date: certificateInfos.issue_date,
                            description: certificateInfos.description
                        }
                    })
                    setFreelancerCertificates(certificatesForm);
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerCertificates();
        }
    }, [user]);




    useEffect(() => {
        const fetchFreelancerLinks = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/links/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    const linksForm = data.map( LinkInfos => {
                        return {
                            type: LinkInfos.type,
                            url: LinkInfos.url
                        }
                    })
                    setFreelancerLinks(linksForm);
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerLinks();
        }
    }, [user]);




    useEffect(() => {
        const fetchFreelancerData = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/preferences/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setLookingFor(data.looking_for);
                    setPersonalJobPreferences(data.work_preference);
                    setAmazingThingIDid(data.best_project);
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerData();
        }
    }, [user]);




    if (loading) {
        return <LoadingSpinner isLoading={true} />;
    };
    if (error) {
        return <div>Erreur: {error}</div>;
    };
    return (
        <div>
            {   freelancerData && <ProfileContent 
                    user={user}
                    freelancerData={freelancerData}
                    freelancerSkills={freelancerSkills}

                    freelancerAvailabilityDays={freelancerAvailabilityDays}

                    freelancerWorkExperience={freelancerWorkExperience}
                    freelancerProjects={freelancerProjects}

                    freelancerEducation={freelancerEducation}
                    freelancerCertificates={freelancerCertificates}

                    lookingFor={lookingFor}
                    personalJobPreferences={personalJobPreferences}
                    amazingThingIDid={amazingThingIDid}

                    freelancerLinks={freelancerLinks}
                /> 
            }
            
        </div>
    )
}

export default Profile;


