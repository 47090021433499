import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Spinner, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmailVerificationCode,
  resetEmailVerificationCode,
} from "../../../redux/slices/validationEmailFormSlice";
import {
  setFreelancerInscriptionFormData,
  resetFreelancerInscriptionFormData,
} from "../../../redux/slices/freelancerInscriptionFormSlice";
import "../../../styles/validationMailPage.css";
import { API_GATEWAY_URL } from "../../../constants";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { login as reduxLogin } from "../../../redux/slices/authentificationSlice";
import logo from "../../../assets/logoWhite.png";
import { Link } from "react-router-dom";
import "../../../index.css";
import "../../../styles/Buttons.css";
const FreelancerValidationMailPage = () => {
  const { emailVerificationCode } = useSelector(
    (state) => state.validationEmailForm
  );
  const { freelancerInscriptionFormData } = useSelector(
    (state) => state.freelancerInscriptionForm
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(Array(6).fill(""));
  const inputRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [timer, setTimer] = useState(60);
  const location = useLocation();
  const [codeSent, setCodeSent] = useState(false);
  const email = location.state?.email; // Récupération de l'email passé via state
  const { user, userType, email_validation } = useAuth();
  const [currentUser, setCurrentUser] = useState(user);
  const [currentUserType, setCurrentUserType] = useState(userType);
  const { login: contextLogin } = useAuth();

  useEffect(() => {
    // Vérifie si un email est disponible dès le chargement du composant
    const emailToCheck = email && !freelancerInscriptionFormData.email;
    if (emailToCheck) {
      const performAsyncOperation = async () => {
        await resendCode(); // Attendez que resendCode soit terminé
        // Ajoutez ici le traitement à exécuter après resendCode
        setMessage("");
      };

      performAsyncOperation(); // Exécutez la fonction asynchrone
    }
  }, [freelancerInscriptionFormData.email, email]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const resendCode = async () => {
    setLoading(true);
    setMessage("");
    setMessageType("info");
    setTimer(60);

    try {
      const response = await fetch(
        `${API_GATEWAY_URL}/resend-email-code-freelancer/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: freelancerInscriptionFormData.email || email || "",
          }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        setMessage(data.message || "Code envoyé avec succès.");
        setMessageType("success");
      } else {
        setMessage(data.message || "Échec de l'envoi du code.");
        setMessageType("error");
      }
    } catch (error) {
      setMessage("Une erreur est survenue.");
      setMessageType("error");
    }

    setLoading(false);
  };

  const handleChange = (index, value) => {
    if (isNaN(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value !== "" && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };
  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      inputRefs.current[index - 1].focus();
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");
    setMessageType("info");

    const enteredOtp = otp.join("");
    dispatch(setEmailVerificationCode(enteredOtp));

    try {
      const formData = new FormData();
      formData.append("emailVerificationCode", enteredOtp);
      formData.append("email", freelancerInscriptionFormData.email || email);

      const response = await fetch(`${API_GATEWAY_URL}/freelancer-verify/`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (response.ok) {
        setMessage(data.message || "Code vérifié avec succès.");
        setMessageType("success");
        dispatch(resetEmailVerificationCode());
        dispatch(resetFreelancerInscriptionFormData());
        setOtp(Array(6).fill(""));

        await new Promise((r) => setTimeout(r, 2000));
        if (user && user.access_token && user.user_email) {
          contextLogin({
            user: currentUser,
            userType: currentUserType,
            email_validation: "OK",
          });
          dispatch(
            reduxLogin({
              user: currentUser,
              userType: currentUserType,
              email_validation: "OK",
            })
          );

          navigate("/profile");
        } else {
          navigate("/logIn");
        }
      } else {
        setMessage(data.message || "Une erreur est survenue");
        setMessageType("error");
      }
    } catch (error) {
      setMessage("Une erreur est survenue.");
      setMessageType("error");
    }
    setLoading(false);
  };

  return (
    <div className="loginbackgroundImage">
      <Link className="loginlogo max-sm:m-2 ml-[72px] mb-6" to="/">
        <img className="max-2xl:w-[200px] 2xl:w-[280px]" src={logo} />
      </Link>
      <div className="container-fluid m-10 row center-on-small">
        <div className="form-col">
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <div className="m-0 p-0  text-center text-primary-800">
                <h2 className="font-display font-bold">
                  Merci pour votre inscription.
                </h2>
                <p className="font-body text-fluid-sm mt-3">
                  Vous êtes sur le point de terminer votre inscription. Veuillez
                  compléter la dernière étape.
                </p>
                <label className="font-body  mb-3 d-block mx-auto text-fluid-sm">
                  Veuillez saisir le code envoyé à votre adresse e-mail:
                </label>
                <div className="otp-container mb-3 mx-auto justify-content-center">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      className="otp-input"
                      maxLength="1"
                      pattern="[0-9]"
                      value={digit}
                      onChange={(e) => handleChange(index, e.target.value)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      onFocus={(e) => e.target.select()}
                      ref={(ref) => (inputRefs.current[index] = ref)}
                    />
                  ))}
                </div>
              </div>
              {message && (
                <Alert variant={messageType === "error" ? "danger" : "success"}>
                  {message}
                </Alert>
              )}
              <div className="flex flex-col mt-5 text-fluid-sm">
                <button
                  variant="primary"
                  type="submit"
                  className="SignUpButton text-fluid-sm break-words"
                  disabled={loading}
                >
                  Confirmer l'inscription
                </button>
                <button
                  variant="secondary"
                  onClick={resendCode}
                  className="bg-transparent text-primary-800 font-bold"
                  disabled={timer > 0}
                >
                  Envoyer un nouveau code par e-mail ({timer})
                </button>
              </div>
            </form>
            {loading && (
              <div className="text-center" style={{ marginTop: "30px" }}>
                <Spinner animation="border" />
                <span>{message}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreelancerValidationMailPage;
