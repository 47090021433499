import React, { useState, useEffect } from 'react';
import { useAuth } from '../../components/AuthContext';
import { useForm } from "react-hook-form"
import { API_GATEWAY_URL } from '../../constants';
import countriesWithContinent from '../../data/countriesWithContinent';
import '../../index.css';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { TextField, Button } from "@mui/material";
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import LoadingSpinner from '../../components/LoadingSpinner'; 
import { current } from '@reduxjs/toolkit';


const findTimeZone = (country) => {
    let timeZone = countriesWithContinent.find(c => c.abbreviation === country)?.timezone;
    if (!timeZone) {
        console.error(`Continent not found for country: ${country}`);
        return null;
    }
    return timeZone;
}
function formatNumber(number) {
    const numberString = number.toString();
    const firstThree = numberString.slice(0, 3);
    const rest = numberString.slice(3);
    return `+${firstThree} ${rest}`;
}


const CompteForm = ({ onSubmit, handleButtonOneClick, freelancerData, linkedinLink, githubLink }) => {
    const { 
        register,
        handleSubmit, 
        formState: { errors } 
    } = useForm({
        defaultValues: {
            nom: freelancerData.firstName,
            prenom: freelancerData.lastName,
            email: freelancerData.email,
            linkedin: linkedinLink || '',
            github: githubLink || '',
        }
    });
    return (
        <form 
            className='flex flex-col gap-2'
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="flex justify-between items-center">
                <TextField
                    className='basis-5/12'
                    label="Nom"
                    {...register('nom', { required: 'Le nom est requis' })}
                    error={!!errors.nom}
                    helperText={errors.nom ? errors.nom.message : ''}
                    margin="normal"
                />
                <TextField
                    className='basis-5/12'
                    label="Prenom"
                    {...register('prenom', { required: 'Le prénom est requis' })}
                    error={!!errors.prenom}
                    helperText={errors.prenom ? errors.prenom.message : ''}
                    margin="normal"
                />
            </div>
            <TextField
                className='w-5/12'
                label="Email"
                {...register('email', { 
                    required: 'L\'email est requis',
                    pattern: {
                        value: /^\S+@\S+$/i,
                        message: 'Email invalide'
                    }
                })}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ''}
                margin="normal"
            />
            <TextField
                label="Lien vers LinkedIn"
                {...register('linkedin', {
                    required: 'Le lien LinkedIn est requis',
                    pattern: {
                    value: /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/i,
                    message: 'Lien LinkedIn invalide'
                    }
                })}
                error={!!errors.linkedin}
                helperText={errors.linkedin ? errors.linkedin.message : ''}
                margin="normal"
            />
            <TextField
                label="Lien vers GitHub"
                {...register('github', {
                    required: 'Le lien GitHub est requis',
                    pattern: {
                    value: /^(https?:\/\/)?(www\.)?github\.com\/.*$/i,
                    message: 'Lien GitHub invalide'
                    }
                })}
                error={!!errors.github}
                helperText={errors.github ? errors.github.message : ''}
                margin="normal"
            />
            <div className="flex items-center gap-3 mt-3">
                <Button
                    variant="contained"
                    type="submit"
                    color='success'
                    sx={{
                        fontWeight: 'var(--fw-semibold)',
                        fontSize: '.8rem',
                    }}
                >
                    Sauvegarder
                </Button>
                <Button 
                    variant="text"
                    color='error'
                    sx={{
                        fontWeight: 'var(--fw-semibold)',
                        fontSize: '.8rem',
                    }}
                    onClick={handleButtonOneClick}
                >
                    Annuler
                </Button>
            </div>
        </form>
    );
}



const LocationForm = ({ onSubmit, handleButtonTwoClick, freelancerData }) => {
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            fuseauHoraire: findTimeZone(freelancerData.country),
            pays: freelancerData.country,
            commune: freelancerData.commune,
            ville: freelancerData.ville,
            numeroDeTelephone: formatNumber(freelancerData.phoneNumber),
        }
    });
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
                fullWidth
                label="Fuseau Horaire"
                unselectable='on'
                {...register('fuseauHoraire', { required: 'Le fuseau horaire est requis' })}
                error={!!errors.fuseauHoraire}
                helperText={errors.fuseauHoraire ? errors.fuseauHoraire.message : ''}
                margin="normal"
            />
            <div className="flex justify-between items-center">
                <TextField
                    className='w-3/12'
                    label="Pays"
                    {...register('pays', { required: 'Le pays est requis' })}
                    error={!!errors.pays}
                    helperText={errors.pays ? errors.pays.message : ''}
                    margin="normal"
                />
                <TextField
                    className='w-3/12'
                    label="Commune"
                    {...register('commune', { required: 'La commune est requise' })}
                    error={!!errors.commune}
                    helperText={errors.commune ? errors.commune.message : ''}
                    margin="normal"
                />
                <TextField
                    className='w-3/12'
                    label="Ville"
                    {...register('ville', { required: 'La ville est requise' })}
                    error={!!errors.ville}
                    helperText={errors.ville ? errors.ville.message : ''}
                    margin="normal"
                />
            </div>
            <TextField
                fullWidth
                label="Numéro de téléphone"
                {...register('numeroDeTelephone', {
                    required: 'Le numéro de téléphone est requis',
                    pattern: {
                        value: /^(\+?\d{1,4}[\s-]?)?(\(?\d{1,3}\)?[\s-]?)?(\d{1,4}[\s-]?)?(\d{1,4}[\s-]?)?(\d{1,9})$/,
                        message: 'Numéro de téléphone invalide'
                    }
                })}
                error={!!errors.numeroDeTelephone}
                helperText={errors.numeroDeTelephone ? errors.numeroDeTelephone.message : ''}
                margin="normal"
            />
            <div className="flex items-center gap-3 mt-3">
                <Button
                    variant="contained"
                    type="submit"
                    color='success'
                    sx={{
                        fontWeight: 'var(--fw-semibold)',
                        fontSize: '.8rem',
                    }}
                >
                    Sauvegarder
                </Button>
                <Button 
                    variant="text"
                    color='error'
                    sx={{
                        fontWeight: 'var(--fw-semibold)',
                        fontSize: '.8rem',
                    }}
                    onClick={handleButtonTwoClick}
                >
                    Annuler
                </Button>
            </div>
        </form>
    );
};


const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
}));



const PersonalInformation = () => {
    const { user } = useAuth();
    const [freelancerData, setFreelancerData] = useState(null);
    const [freelancerLinks, setFreelancerLinks] = useState(null);
    const [informationsCompte, setInformationsCompte] = useState(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [activeSectionOne, setActiveSectionOne] = useState(1); 
    const [activeSectionTwo, setActiveSectionTwo] = useState(1);


    const handleButtonOneClick = () => {
        setActiveSectionOne(prevSection => prevSection === 0 ? 1 : 0);
    };
    const handleButtonTwoClick = () => {
        setActiveSectionTwo(prevSection => prevSection === 0 ? 1 : 0);
    };
    const handleFormCompteSubmit = (data) => {
        changeFreelancerData(data);
    };
    const handleFormLocationSubmit = (data) => {
        changeLocationData(data);
    };
    const handleChangePassword = () => {
        console.log('Change password');
    }


    useEffect(() => {
        const fetchFreelancerData = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/freelancer-profile/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setFreelancerData(data);
                } else {
                throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerData();
        }
    }, [user]);



    const changeFreelancerData = async (newData) => {
        try {
            // Fetch the current data from the backend
            const fetchCurrentData = async () => {
                const response = await fetch(`${API_GATEWAY_URL}/freelancer-profile/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    return await response.json();
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            };
            const currentData = await fetchCurrentData();
            // Merge the current data with the new data
            const updatedData = { ...currentData, ...newData };
            // Create a new object with the updated data
            const newFreelancerDataInfos = {
                Address: updatedData.Address,
                birthdayDate: updatedData.birthdayDate,
                codePostal: updatedData.codePostal,
                country: updatedData.country,
                ville: updatedData.ville,
                commune: updatedData.commune,
                creation_date: updatedData.creation_date,
                cv_url: updatedData.cv_url,
                description: updatedData.description,
                firstName: updatedData.nom,
                lastName: updatedData.prenom,
                email: updatedData.email,
                linkedin: updatedData.linkedin,
                niveauFreelance: updatedData.niveauFreelance,
                niveau_freelance_readable: updatedData.niveau_freelance_readable,
                phoneNumber: updatedData.phoneNumber,
                profilTitle: updatedData.profilTitle,
                profile_picture_url: updatedData.profile_picture_url,
                user: updatedData.user,
            }
            // Send the updated data back to the backend
            const response = await fetch(`${API_GATEWAY_URL}/update-freelancer/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`
                },
                body: JSON.stringify(newFreelancerDataInfos)
            });
            if (response.ok) {
                const data = await response.json();
                setFreelancerData(data);
            } else {
                throw new Error('Failed to update data');
            }


            const fetchFreelancerLinks = async () => {
                const response = await fetch(`${API_GATEWAY_URL}/links/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    return await response.json();
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            }

            const currentLinks = await fetchFreelancerLinks();
            // Update local link data if there are changes
            if (newData.linkedin !== currentLinks[0].url) {
                currentLinks[0].url = newData.linkedin;
            }
            if (newData.github !== currentLinks[1].url) {
                currentLinks[1].url = newData.github;
            }

            // Send the updated links back to the backend
            const responseLink1 = await fetch(`${API_GATEWAY_URL}/link/${currentLinks[0].id}/`, {
                method: 'PUT',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`
                },
                body: JSON.stringify(currentLinks[0])
            });

            const responseLink2 = await fetch(`${API_GATEWAY_URL}/link/${currentLinks[1].id}/`, {
                method: 'PUT',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`
                },
                body: JSON.stringify(currentLinks[1])
            });
            // Check if both PUT requests were successful
            if (responseLink1.ok && responseLink2.ok) {
                // Fetch the updated links from the backend to reflect changes
                const updatedLinks = await fetchFreelancerLinks();
                setFreelancerLinks(updatedLinks);
            } else {
                // Handle errors if PUT requests failed
                throw new Error('Failed to update data');
            }
        } catch (err) {
            console.error('Error updating data:', err);
            setError(err.message);
        }
    };



    const changeLocationData = async (newData) => {
        try {
            // Fetch the current data from the backend
            const fetchCurrentData = async () => {
                const response = await fetch(`${API_GATEWAY_URL}/freelancer-profile/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    return await response.json();
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            };
            const currentData = await fetchCurrentData();
            // Merge the current data with the new data
            const updatedData = { ...currentData, ...newData };
            // Create a new object with the updated data
            const newFreelancerDataInfos = {
                Address: updatedData.Address,
                birthdayDate: updatedData.birthdayDate,
                codePostal: updatedData.codePostal,
                country: updatedData.pays,
                ville: updatedData.ville,
                commune: updatedData.commune,
                creation_date: updatedData.creation_date,
                cv_url: updatedData.cv_url,
                description: updatedData.description,
                firstName: updatedData.nom,
                lastName: updatedData.prenom,
                email: updatedData.email,
                linkedin: updatedData.linkedin,
                niveauFreelance: updatedData.niveauFreelance,
                niveau_freelance_readable: updatedData.niveau_freelance_readable,
                phoneNumber: updatedData.numeroDeTelephone,
                profilTitle: updatedData.profilTitle,
                profile_picture_url: updatedData.profile_picture_url,
                user: updatedData.user,
            }
            // Send the updated data back to the backend
            const response = await fetch(`${API_GATEWAY_URL}/update-freelancer/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`
                },
                body: JSON.stringify(newFreelancerDataInfos)
            });
            if (response.ok) {
                const data = await response.json();
                setFreelancerData(data);
            } else {
                throw new Error('Failed to update data');
            }
        } catch (err) {
            console.error('Error updating data:', err);
            setError(err.message);
        }
    };





    useEffect(() => {
        const fetchFreelancerLinks = async () => {
            try {
                const response = await fetch(`${API_GATEWAY_URL}/links/`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    const linksForm = data.map( LinkInfos => {
                        return {
                            type: LinkInfos.type,
                            url: LinkInfos.url
                        }
                    })
                    setFreelancerLinks(linksForm);
                } else {
                    throw new Error('Erreur lors de la récupération des informations du profil freelancer.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (user && user.access_token) {
            fetchFreelancerLinks();
        }
    }, [user]);    
    const freelancerLinksData = Array.isArray(freelancerLinks)
    ? freelancerLinks.reduce((acc, link) => {
        if (link.type === 'LINKEDIN') {
            acc.linkedinLink = link.url;
        } else if (link.type === 'GITHUB') {
            acc.githubLink = link.url;
        }
        return acc;
    }, {})
    : {};
    const { linkedinLink = '', githubLink = '' } = freelancerLinksData;


    if (loading) {
        return <LoadingSpinner isLoading={true} />;
    };
    if (error) {
        return <div>Erreur: {error}</div>;
    };



    return (
        <main 
            className="w-full flex flex-col gap-4 md:mt-4"
            style={{fontFamily: 'var(--ff-body)',}}
        >
            <h3 className='hidden md:block'>Informations Personnelles</h3>
            <div
                className='py-4 px-8 rounded-lg w-full border-2 border-gray-200'
            >
                <header
                    className='pb-3 pt-2 flex justify-between items-center'
                >
                    <h2 className='mb-0'>Compte</h2>
                    <button 
                        className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100 ${!activeSectionOne ? 'hidden' : ''}`}
                        onClick={handleButtonOneClick}
                        style={{
                            width: '2rem',
                            height: '2rem',
                            border: '1px solid var(--clr-neutral-300)',
                        }}
                    >
                        <EditOutlinedIcon 
                            className='-text--clr-secondary-500'
                            style={{
                                width: '1.2rem',
                                height: '1.2rem',
                            }}
                        />
                    </button>
                </header>
            
                {
                    activeSectionOne ? (
                        <section>
                            <div 
                                className='mb-4'
                                style={{
                                    fontFamily: 'var(--ff-body)',
                                }} 
                            >
                                <div className="mb-1"
                                    style={{fontWeight: 'var(--fw-semibold)',}}
                                >
                                    Nom et Prénom
                                </div>
                                <p 
                                    style={{fontWeight: 'var(--fw-regular)'}}
                                >
                                    {freelancerData.firstName} {freelancerData.lastName}
                                </p>
                            </div>
                            <div 
                                className='mb-4'
                                style={{
                                    fontFamily: 'var(--ff-body)',
                                }} 
                            >
                                <div className="mb-1"
                                    style={{fontWeight: 'var(--fw-semibold)',}}
                                >
                                    Email
                                </div>
                                <p 
                                    style={{fontWeight: 'var(--fw-regular)'}}
                                >
                                    {freelancerData.email}
                                </p>
                            </div>
                            <div 
                                className='mb-4'
                                style={{
                                    fontFamily: 'var(--ff-body)',
                                }} 
                            >
                                <div className="mb-1"
                                    style={{fontWeight: 'var(--fw-semibold)',}}
                                >
                                    Lien vers le compte
                                </div>
                                <p 
                                    style={{fontWeight: 'var(--fw-regular)'}}
                                >
                                    <span style={{fontWeight: 'var(--fw-medium)'}} >LinkedIn:</span> {linkedinLink}<br />
                                    <span style={{fontWeight: 'var(--fw-medium)'}} >Github:</span> {githubLink}
                                </p>
                            </div>
                            <div className='-text--clr-danger-500 cursor-pointer'
                                style={{fontWeight: 'var(--fw-semibold)',}}
                                onClick={handleChangePassword}
                            >
                                Changer mon mot de passe
                            </div>
                        </section>
                    ) : (
                        <CompteForm 
                            onSubmit={handleFormCompteSubmit}
                            handleButtonOneClick={handleButtonOneClick}
                            freelancerData={freelancerData}
                            linkedinLink={linkedinLink}
                            githubLink={githubLink}
                        />
                    )
                }
            </div>


            <div
                className='py-4 px-8 rounded-lg w-full border-2 border-gray-200'
            >
                <header
                    className='pb-3 pt-2 flex justify-between items-center'
                >
                    <h2 className='mb-0'>Localisation</h2>
                    <button 
                        className={`flex items-center justify-center p-1 rounded-full bg-gray-50 hover:bg-neutral-100 ${!activeSectionTwo ? 'hidden' : ''}`}                        
                        onClick={handleButtonTwoClick}
                        style={{
                            width: '2rem',
                            height: '2rem',
                            border: '1px solid var(--clr-neutral-300)',
                        }}
                    >
                        <EditOutlinedIcon 
                            className='-text--clr-secondary-500'
                            style={{
                                width: '1.2rem',
                                height: '1.2rem',
                            }}
                        />
                    </button>
                </header>
            {
                activeSectionTwo ? (
                    <section>
                        <div 
                            className='mb-4'
                            style={{
                                fontFamily: 'var(--ff-body)',
                            }} 
                        >
                            <div className="mb-1"
                                style={{fontWeight: 'var(--fw-semibold)',}}
                            >
                                Fuseau Horaire
                            </div>
                            <p 
                                style={{fontWeight: 'var(--fw-regular)'}}
                            >
                                {findTimeZone(freelancerData.country)}
                            </p>
                        </div>
                        <div 
                            className='mb-4'
                            style={{
                                fontFamily: 'var(--ff-body)',
                            }} 
                        >
                            <div className="mb-1"
                                style={{fontWeight: 'var(--fw-semibold)',}}
                            >
                                Adresse
                            </div>
                            <p 
                                style={{fontWeight: 'var(--fw-regular)'}}
                            >
                                {freelancerData.ville}, {freelancerData.commune},<br />
                                {freelancerData.country}
                            </p>
                        </div>
                        <div 
                            className='mb-4'
                            style={{
                                fontFamily: 'var(--ff-body)',
                            }} 
                        >
                            <div className="mb-1"
                                style={{fontWeight: 'var(--fw-semibold)',}}
                            >
                                Numero de telephone
                            </div>
                            <p 
                                style={{fontWeight: 'var(--fw-regular)'}}
                            >
                                {formatNumber(freelancerData.phoneNumber)}
                            </p>
                        </div>
                    </section>
                ) : (
                    <LocationForm 
                        user={user}
                        changeLocationData={changeLocationData}
                        freelancerData={freelancerData}
                        onSubmit={handleFormLocationSubmit}
                        handleButtonTwoClick={handleButtonTwoClick}
                    />
                )
            }
            </div>


            <div
                className='py-4 px-8 rounded-lg w-full border-2 border-gray-200'
            >
                <header
                    className='pb-3 pt-2 flex justify-between items-center'
                >
                    <h2 className='mb-0'>Autres</h2>
                </header>
                <section>
                    <div 
                        className='mb-4 flex items-center gap-4'
                        style={{
                            fontFamily: 'var(--ff-body)',
                        }} 
                    >
                        <div className="mb-1"
                            style={{fontWeight: 'var(--fw-semibold)',}}
                        >
                            Recevoire des notifications via email
                        </div>
                        <IOSSwitch defaultChecked/>
                    </div> 
                </section>
            </div>

        </main>
    );
};

export default PersonalInformation;