// MultiStepForm.js

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Spinner, Alert } from "react-bootstrap";
import {
  setClientInscriptionFormData,
  nextStep,
  prevStep,
  resetClientInscriptionFormData,
} from "../../../redux/slices/clientInscriptionFormSlice";
import "../../../styles/MultiStepForm.css";
import $ from "jquery";
// import 'select2';
import Select from "react-select";
import "select2/dist/css/select2.min.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  FaPlus,
  FaTimes,
  FaSearch,
  FaCheckCircle,
  FaCircle,
  FaTrash,
  FaLinkedin,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import { SlArrowRight } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_GATEWAY_URL } from "../../../constants";
import _ from "lodash";
import {
  CountryDropdownl,
  RegionDropdown,
} from "react-country-region-selector"; // Importer directement les composants nécessaires
import { Link } from "react-router-dom";
import logo from "../../../assets/logoWhite.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countryList from "country-list";
import "../../../index.css";
import "../../../styles/Buttons.css";

const projectTypes = {
  NI: "Nouvelle idée de projet",
  PR: "Projet en cours nécessitant plus de ressource",
  EX: "Aucun, je souhaite juste explorer les possibilités",
};

const missionStartOptions = {
  IM: "Immédiatement (le plus vite possible)",
  "1T": "De 1 à 2 semaines",
  "2W": "Plus de 2 semaines",
  LA: "Je vais décider plus tard",
};

const expertiseLevelOptions = {
  JR: "Junior",
  CF: "Confirmé",
  SR: "Sénior",
  EX: "Expert",
  LA: "Choisir plus tard",
};

const timeEngagementOptions = {
  FP: "Temps plein (40 heures/semaine)",
  PT: "Temps partiel (< 40 heures/semaine)",
  HD: "Par demi-journée",
  LA: "Je vais décider plus tard",
};

const permanentResourceOptions = ["Oui", "Non, pas pour le moment"];

const expertiseLevelOptionsArray = Object.entries(expertiseLevelOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
);

const projectTypesArray = Object.entries(projectTypes).map(([key, value]) => ({
  value: key,
  label: value,
}));

const timeEngagementOptionsArray = Object.entries(timeEngagementOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
);

const missionStartOptionsArray = Object.entries(missionStartOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
);

const MultiStepForm = () => {
  const dispatch = useDispatch();
  const { step, clientInscriptionFormData } = useSelector(
    (state) => state.clientInscriptionForm
  );
  const totalSteps = 4; // Nombre total d'étapes dans votre formulaire
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedExpertises, setSelectedExpertises] = useState([]);
  const navigate = useNavigate();
  const [posteTitle, setPosteTitle] = useState("");
  const [apiExpertises, setApiExpertises] = useState([]); // Pour stocker les expertises de l'API
  const [expertises, setExpertises] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Nouvel état pour suivre l'état de chargement
  const [metierAffiche, setMetierAffiche] = useState(""); // Pour stocker le métier utilisé pour la dernière recherche réussie
  const [apiSearchExecuted, setApiSearchExecuted] = useState(false); // Pour suivre si la recherche a été effectuée
  const [originalApiExpertises, setOriginalApiExpertises] = useState([]); // Pour stocker les expertises originales de l'API
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const countries = require("country-list"); // Assure-toi que cette fonction retourne la liste complète des pays
  const [config, setConfig] = useState({
    ia_client_search: true,
    ia_profile_search: true,
    cv_extraction_enabled: true,
    linkedin_extraction_enabled: true,
  });
  const countryOptions = [
    { code: "FR", name: "France" },
    { code: "BE", name: "Belgique" },
    { code: "CH", name: "Suisse" },
    { code: "LU", name: "Luxembourg" },
  ];
  useEffect(() => {
    const fetchExpertisesList = async () => {
      try {
        const { data } = await axios.get(`${API_GATEWAY_URL}/expertises/`);
        setExpertises(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des expertises:", error);
      } finally {
      }
    };

    fetchExpertisesList();
    //get actual global config
    axios
      .get(`${API_GATEWAY_URL}/config/`)
      .then((response) => {
        setConfig(response.data);
      })
      .catch((error) => {
        console.error("Error fetching config:", error);
        setErrorMessage("Failed to load configuration");
      });
  }, []);

  // Fonction pour convertir la réponse de l'API en un tableau JSON
  // Fonction pour convertir la réponse de l'API en un tableau JSON
  const convertResponseToJsonArray = (responseString) => {
    const lines = responseString.trim().split("\n");
    return lines.map((line) => JSON.parse(line));
  };

  const fetchExpertisesFromOpenAI = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${API_GATEWAY_URL}/query-openai/${posteTitle}`
      );
      const expertisesArray = data.split("\n").map((line) => JSON.parse(line));
      setApiExpertises(
        expertisesArray.map((exp) => ({ id: parseInt(exp.C), name: exp.N }))
      );
      console.log(apiExpertises);
      setOriginalApiExpertises(
        expertisesArray.map((exp) => ({ id: parseInt(exp.C), name: exp.N }))
      );
      setMetierAffiche(posteTitle);
      setApiSearchExecuted(true);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des expertises via OpenAI:",
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handlePosteTitleChange = (e) => {
    setPosteTitle(e.target.value);
    const { name, value } = e.target;

    formErrors.posteTitle = "";
    dispatch(setClientInscriptionFormData({ [name]: value }));
  };

  // Gestion de l'appui sur la touche "Entrée"
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchExpertisesFromOpenAI();
      e.preventDefault(); // Empêche le formulaire de se soumettre
    }
  };

  // Contenu du modal de chargement
  const LoadingModal = () => (
    <Modal
      show={isLoading}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="text-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Chargement...</span>
          </div>
          <p className="mt-2">Veuillez patienter...</p>
        </div>
      </Modal.Body>
    </Modal>
  );

  const [formErrors, setFormErrors] = useState({
    posteTitle: "",
    selectedExpertises: "",
    projectType: "",
    missionStart: "",
    expertiseLevel: "",
    timeEngagement: "",
    clientName: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    country: "",
    password: "",
  });

  const validatePassword = (password) => {
    // Vérifie si le mot de passe a au moins 8 caractères, au moins une lettre majuscule, au moins un chiffre, et au moins un caractère spécial
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    dispatch(setClientInscriptionFormData({ [name]: value }));
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Handle form submission here
    console.log("Form data:", clientInscriptionFormData);

    // Validation des champs pour l'étape 4
    let errors = {};
    if (step === 4) {
      const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/; // Inclut les lettres accentuées et espaces
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!clientInscriptionFormData.clientName) {
        errors.clientName = "Veuillez entrer votre nom.";
      } else if (!nameRegex.test(clientInscriptionFormData.clientName)) {
        errors.clientName =
          "Le nom ne doit contenir que des caractères alphabétiques.";
      }

      if (!clientInscriptionFormData.companyName) {
        errors.companyName = "Veuillez entrer le nom de votre entreprise.";
      }

      if (!clientInscriptionFormData.email) {
        errors.email = "Veuillez entrer votre email.";
      } else if (!emailRegex.test(clientInscriptionFormData.email)) {
        errors.email = "Veuillez entrer une adresse e-mail valide.";
      }
      /*if (!clientInscriptionFormData.email) {
        errors.email = "Veuillez entrer votre email.";
      }*/
      if (!clientInscriptionFormData.country) {
        errors.country = "Veuillez entrer votre pays.";
      }
      if (!clientInscriptionFormData.phoneNumber) {
        errors.phoneNumber = "Veuillez entrer votre numéro de téléphone.";
      }
      if (!clientInscriptionFormData.password) {
        errors.password = "Veuillez entrer votre mot de passe.";
      } else {
        // Valide le mot de passe
        const isValidPassword = validatePassword(
          clientInscriptionFormData.password
        );
        if (!isValidPassword) {
          errors.password =
            "Le mot de passe doit contenir au moins 8 caractères, dont une lettre majuscule, un chiffre et un caractère spécial.";
        }
      }
    }
    // Si des erreurs sont détectées, arrêtez la soumission et affichez les erreurs
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    const apiURL = `${API_GATEWAY_URL}/signUp-client/`;
    console.log(apiURL);

    setIsSubmitting(true);
    // *************************
    try {
      const formData = new FormData();
      // for (const key in clientInscriptionFormData) {
      //   formData.append(key, clientInscriptionFormData[key]);
      // }

      formData.append("posteTitle", clientInscriptionFormData.posteTitle);
      const expertisesIds = clientInscriptionFormData.selectedExpertises.map(
        (expertise) => expertise.id
      );

      formData.append("selectedExpertises", JSON.stringify(expertisesIds));
      formData.append("projectType", clientInscriptionFormData.projectType);
      formData.append("missionStart", clientInscriptionFormData.missionStart);
      formData.append(
        "expertiseLevel",
        clientInscriptionFormData.expertiseLevel
      );
      formData.append(
        "timeEngagement",
        clientInscriptionFormData.timeEngagement
      );
      formData.append("clientName", clientInscriptionFormData.clientName);
      formData.append("companyName", clientInscriptionFormData.companyName);
      formData.append("phoneNumber", clientInscriptionFormData.phoneNumber);
      formData.append("country", clientInscriptionFormData.country);
      formData.append("email", clientInscriptionFormData.email);
      formData.append("password", clientInscriptionFormData.password);

      const response = await fetch(`${API_GATEWAY_URL}/signUp-client/`, {
        method: "POST",
        body: formData,
      });

      // Convertissez la réponse en JSON
      const data = await response.json();
      if (response.ok) {
        console.log("Data sent successfully to Django.");
        // dispatch(resetClientInscriptionFormData()); // Réinitialise le formulaire après la soumission
        navigate("/validation-email"); // Réinitialise le formulaire après une réponse réussie
      } else {
        console.error("Failed to send data to Django.");
        console.error(data.message);
        setErrorMessage(data.message);
      }
    } catch (error) {
      setErrorMessage("Erreur technique.");
      console.log("Erreur technique");
      console.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
    // ***********************

    // dispatch(resetClientInscriptionFormData()); // Réinitialise le formulaire après la soumission
    // navigate('/validation-email');
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (step < totalSteps) {
        handleNextStep();
      }
      if (step == totalSteps) {
        document.getElementById("submitButton").click();
      }
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Si la recherche est vide et que l'API OpenAI a été exécutée, réinitialiser les expertises affichées
    if (value === "" && apiSearchExecuted) {
      setApiExpertises(originalApiExpertises); // Réinitialiser à apiExpertisesList
    } else if (value === "" && !apiSearchExecuted) {
      setApiExpertises([]);
    }
    // Sinon, effectuer une recherche d'expertise
    else {
      const filtered = expertises.filter((expertise) =>
        expertise.name.toLowerCase().includes(value.toLowerCase())
      );
      console.log(filtered);
      console.log(expertises);
      setApiExpertises(value ? filtered.slice(0, 10) : expertises.slice(0, 10));
    }
  };

  const handleCountryChange = (event) => {
    // Extraire la valeur du code pays depuis l'élément select
    const country = event.target.value;
    dispatch(setClientInscriptionFormData({ country: country }));
    console.log("Country code:", countries.getCode(country));
  };

  const handlePhoneNumberChange = (value, country) => {
    dispatch(setClientInscriptionFormData({ ["phoneNumber"]: value }));
  };

  const handleAddExpertise = (expertise) => {
    const isAlreadyAdded = selectedExpertises.some(
      (item) => item.id === expertise.id
    );
    if (!isAlreadyAdded) {
      // Ajoutez un champ level à chaque expertise
      setSelectedExpertises([
        ...selectedExpertises,
        { ...expertise, level: "" },
      ]);
      dispatch(
        setClientInscriptionFormData({
          selectedExpertises: [
            ...selectedExpertises,
            { ...expertise, level: "" },
          ],
        })
      );

      // Mettre à jour apiExpertises
      setApiExpertises((prev) => {
        const next = prev.filter((item) => item.id !== expertise.id);
        if (next.length < 10 && expertises.length > 10 && !apiSearchExecuted) {
          const nextExpertise = expertises.find(
            (item) =>
              !selectedExpertises.some((exp) => exp.id === item.id) &&
              !next.some((exp) => exp.id === item.id)
          );
          if (nextExpertise) {
            next.push(nextExpertise);
          }
        }
        return next;
      });
    }
  };

  const handleRemoveExpertise = (id) => {
    const updatedExpertises = selectedExpertises.filter(
      (item) => item.id !== id
    );
    setSelectedExpertises(updatedExpertises);
    dispatch(
      setClientInscriptionFormData({ selectedExpertises: updatedExpertises })
    );

    // Mettre à jour apiExpertises
    const removedExpertise = expertises.find((item) => item.id === id);
    console.log(expertises);
    console.log(removedExpertise);
    if (removedExpertise) {
      // Ajoutez cette vérification
      setApiExpertises((prev) => {
        // Vérifie si l'expertise supprimée est déjà dans apiExpertises
        // ou si elle ne correspond pas au terme de recherche actuel
        if (
          prev.some((expertise) => expertise.id === removedExpertise.id) ||
          !removedExpertise.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ) {
          return prev; // Si c'est le cas, retourne apiExpertises sans modification
        } else {
          const next = [...prev, removedExpertise];
          next.sort((a, b) => a.id - b.id);
          return next.slice(0, 10);
        }
      });
    } else {
      console.log("testtt");
    }
  };

  const updateApiExpertises = (newSelected) => {
    // Recalculer les expertises à afficher après ajout ou suppression
    const remainingExpertises = expertises.filter(
      (exp) => !newSelected.some((sel) => sel.id === exp.id)
    );
    setApiExpertises(remainingExpertises.slice(0, 10));
  };

  const filteredExpertises = searchTerm
    ? expertises.filter((expertise) =>
        expertise.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : apiExpertises;

  // errors

  const handleNextStep = () => {
    let errors = {};
    if (step === 1) {
      if (!posteTitle.trim()) {
        errors.posteTitle = "Veuillez entrer le titre du poste recherché.";
      }

      if (!clientInscriptionFormData.selectedExpertises.length) {
        errors.selectedExpertises =
          "Veuillez sélectionner au moins une expertise.";
      }
    }
    // Validation des champs pour l'étape 2
    if (step === 2) {
      console.log("Veuillez sélectionner le type de projet.");
      if (!clientInscriptionFormData.projectType) {
        errors.projectType = "Veuillez sélectionner le type de projet.";
      }

      if (!clientInscriptionFormData.missionStart) {
        errors.missionStart =
          "Veuillez sélectionner le délai de début de mission.";
      }
    }

    if (step === 3) {
      if (!clientInscriptionFormData.expertiseLevel) {
        errors.expertiseLevel =
          "Veuillez sélectionner le niveau d'expertise recherché.";
      }

      if (!clientInscriptionFormData.timeEngagement) {
        errors.timeEngagement =
          "Veuillez sélectionner le niveau d'engagement de temps.";
      }
    }

    // Si des erreurs sont détectées, arrêtez la progression et affichez les erreurs
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Si aucune erreur, passez à l'étape suivante
    dispatch(nextStep());
  };

  return (
    <div className="flex flex-col justify-center items-center   ">
      <Link className="loginlogo ml-[70px] mb-6" to="/">
        <img className="max-2xl:w-[200px] 2xl:w-[280px]" src={logo} />
      </Link>
      {isSubmitting && (
        <Modal
          show={true}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="text-center">
              <Spinner animation="border" />
              <p className="mt-2">Veuillez patienter...</p>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <div className="MediumContainer font-body text-primary-800 ">
        <div className="row  pt-3 m-0 ">
          <div className="col-md-9 col-sm-12 step-title  d-flex flex-wrap align-items-center  justify-content-start mb-3 ">
            <span className="step-title me-5">
              {step === 1 ? (
                <FaCircle
                  className="step-icon"
                  style={{ fill: "#AF1B21", fontSize: "25px" }}
                />
              ) : step > 1 ? (
                <FaCheckCircle
                  className="step-icon"
                  style={{ fill: "#AF1B21", fontSize: "25px" }}
                />
              ) : (
                <FaCircle
                  className="step-icon"
                  style={{ fill: "gray", fontSize: "25px" }}
                />
              )}{" "}
              Mon Besoin <SlArrowRight style={{ fontSize: "10px" }} />
            </span>

            <span className="step-title me-5">
              {step === 2 ? (
                <FaCircle
                  className="step-icon"
                  style={{ fill: "#AF1B21", fontSize: "25px" }}
                />
              ) : step > 2 ? (
                <FaCheckCircle
                  className="step-icon"
                  style={{ fill: "#AF1B21", fontSize: "25px" }}
                />
              ) : (
                <FaCircle
                  className="step-icon"
                  style={{ fill: "gray", fontSize: "25px" }}
                />
              )}{" "}
              Mon Projet <SlArrowRight style={{ fontSize: "10px" }} />
            </span>

            <span className="step-title me-5">
              {step === 3 ? (
                <FaCircle
                  className="step-icon"
                  style={{ fill: "#AF1B21", fontSize: "25px" }}
                />
              ) : step > 3 ? (
                <FaCheckCircle
                  className="step-icon"
                  style={{ fill: "#AF1B21", fontSize: "25px" }}
                />
              ) : (
                <FaCircle
                  className="step-icon "
                  style={{ fill: "gray", fontSize: "25px" }}
                />
              )}{" "}
              Mes précisions <SlArrowRight style={{ fontSize: "10px" }} />
            </span>

            <span className="step-title">
              {step === 4 ? (
                <FaCircle
                  className="step-icon"
                  style={{ fill: "#AF1B21", fontSize: "25px" }}
                />
              ) : step > 4 ? (
                <FaCheckCircle
                  className="step-icon"
                  style={{ fill: "#AF1B21", fontSize: "25px" }}
                />
              ) : (
                <FaCircle
                  className="step-icon"
                  style={{ fill: "gray", fontSize: "25px" }}
                />
              )}{" "}
              Mes coordonnées <SlArrowRight style={{ fontSize: "10px" }} />
            </span>

            {/* Titre et icône pour chaque étape */}
          </div>

          <div className="col-md-3 ms-auto col-sm-6 d-flex flex-column align-items-end justify-content-center mb-3 ">
            {/* <label className='ProgressBar-label' style={{fontSize:'13px'}} >Pourcentage accompli: {(step / totalSteps) * 100}  %</label> */}
            <ProgressBar className="w-75" now={(step / totalSteps) * 100} />
          </div>
        </div>

        <div className=" max-2xl:text-fluid-ms 2xl:text-fluid-sm ">
          <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
            {step === 1 && (
              <>
                <h2 className="font-bold max-2xl:text-fluid-sm 2xl:text-fluid-md">
                  Nous sommes ravis de vous accueillir sur TeraTalenT !
                </h2>
                <p className="  ">
                  Avant de commencer votre voyage avec nous, prenez un instant
                  pour nous partager vos besoins en services informatiques.
                  Votre contribution à ce formulaire nous aidera à personnaliser
                  votre expérience et à vous connecter avec les prestataires les
                  mieux adaptés à vos attentes.
                </p>

                <div className="mb-3">
                  <label
                    htmlFor="posteTitle"
                    className="form-label    font-bold"
                  >
                    Quel est le titre du poste / métier recherché?
                  </label>
                  <input
                    type="text"
                    className="form mt-3"
                    name="posteTitle"
                    value={clientInscriptionFormData.posteTitle}
                    onChange={handlePosteTitleChange}
                    onKeyPress={handleKeyPress}
                    placeholder="Titre du poste / métier recherché"
                  />
                  {config.ia_client_search && (
                    <button
                      onClick={fetchExpertisesFromOpenAI}
                      className="SearchButton my-2"
                    >
                      Rechercher les compétences
                    </button>
                  )}

                  {formErrors.posteTitle && (
                    <p style={{ color: "red" }}>{formErrors.posteTitle}</p>
                  )}
                  <div className="">
                    <p>
                      <a>Exemple de titre:</a>{" "}
                    </p>
                    <p className="ps-2">
                      - Développeur Python.
                      <br />- IT Project Manager.
                      <br />- Coach Agile
                    </p>
                  </div>
                </div>

                <div>
                  <div>
                    <label
                      htmlFor="posteTitle"
                      className="form-label mb-3   font-bold"
                    >
                      Quelles sont les principales compétences recherchées?
                    </label>
                    <div className="flex items-center form overflow-hidden">
                      <span className="">
                        <FaSearch />
                      </span>
                      <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        id="search"
                        className="w-full px-4 focus:outline-none"
                        placeholder="Recherche d'expertise..."
                        aria-describedby="searchButton"
                        required
                      />
                    </div>
                    {formErrors.selectedExpertises && (
                      <p style={{ color: "red" }}>
                        {formErrors.selectedExpertises}
                      </p>
                    )}

                    {selectedExpertises.length > 0 && (
                      <>
                        <label className="mt-3 mb-2">
                          Compétences sélectionnées:
                        </label>
                        <div className="expertisesSelectedListe mb-5 ">
                          {selectedExpertises.map((expertiseItem, index) => (
                            <div key={index} className="ms-3 mt-3">
                              <div className="expertiseselected">
                                <span>{expertiseItem.name}</span>
                                <FaTimes
                                  onClick={() =>
                                    handleRemoveExpertise(expertiseItem.id)
                                  }
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}

                    <label className="form-label mt-3    font-bold">
                      Compétences populaires pour : <b>{metierAffiche}</b>
                    </label>
                    <div className="expertisesFiltredListe mt-3">
                      {/* {apiExpertises.map((expertise, index) => (
				      <div style={{ cursor: 'pointer' }}  key={index} className='expertisefiltred' onClick={() => handleAddExpertise({id: expertise.id, name: expertise.name})} >
				        {expertise.name}
				        <FaPlus  className='ms-2 cursor-pointer'  />
				      </div>
				    ))} */}

                      {apiExpertises.map((expertise, index) => {
                        // Vérifie si l'expertise n'est pas déjà sélectionnée
                        const isNotSelected =
                          !clientInscriptionFormData.selectedExpertises.some(
                            (selectedExpertise) =>
                              selectedExpertise.name === expertise.name
                          );

                        // Si l'expertise n'est pas déjà sélectionnée, affichez-la
                        if (isNotSelected) {
                          return (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleAddExpertise({
                                  id: expertise.id,
                                  name: expertise.name,
                                })
                              }
                              className="expertisefiltred"
                              key={index}
                            >
                              {expertise.name}
                              <FaPlus className="ms-2 cursor-pointer" />
                            </div>
                          );
                        }

                        // Sinon, ne rien afficher
                        return null;
                      })}
                    </div>

                    {/* Modal de chargement */}
                    <LoadingModal />
                  </div>
                </div>

                <button className="Redbutton " onClick={handleNextStep}>
                  Suivant
                </button>
              </>
            )}

            {step === 2 && (
              <>
                <div className="mb-3 ">
                  <label className="form-label mb-0    font-bold">
                    Quel est le type de projet?
                  </label>
                  {formErrors.projectType && (
                    <p style={{ color: "red" }}>{formErrors.projectType}</p>
                  )}
                  <div className="ps-5 mt-3">
                    {projectTypesArray.map((type, index) => (
                      <div key={index} className="form-check   ">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="projectType"
                          id={`projectType-${index}`}
                          value={type.value} // Ici, assure-toi de passer `type.value` au lieu de `type`
                          checked={
                            clientInscriptionFormData.projectType === type.value
                          } // Ici aussi, compare avec `type.value`
                          onChange={handleChange}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`projectType-${index}`}
                        >
                          {type.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="mb-3 mt-5">
                  <label className="form-label mb-0    font-bold">
                    Dans quel delai commence la mission?
                  </label>
                  {formErrors.missionStart && (
                    <p style={{ color: "red" }}>{formErrors.missionStart}</p>
                  )}
                  <div className="ps-5 mt-3">
                    {missionStartOptionsArray.map((option, index) => (
                      <div key={index} className="form-check   ">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="missionStart"
                          id={`missionStart-${index}`}
                          value={option.value} // Utilise `option.value`
                          checked={
                            clientInscriptionFormData.missionStart ===
                            option.value
                          } // Compare avec `option.value`
                          onChange={handleChange}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`missionStart-${index}`}
                        >
                          {option.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="d-grid gap-2 d-flex justify-content-md-end mt-5">
                  <button
                    className="previousStep max-2xl:!text-fluid-ms"
                    onClick={() => dispatch(prevStep())}
                  >
                    Précédent
                  </button>{" "}
                  <button
                    className="nextStep max-2xl:!text-fluid-ms"
                    onClick={handleNextStep}
                  >
                    Suivant
                  </button>
                </div>
              </>
            )}

            {step === 3 && (
              <>
                <div className="mb-3 ">
                  <label className="form-label    font-bold">
                    Quel niveau d'expertise recherchez-vous?
                  </label>
                  {formErrors.expertiseLevel && (
                    <p style={{ color: "red" }}>{formErrors.expertiseLevel}</p>
                  )}
                  <div className="ps-5">
                    {expertiseLevelOptionsArray.map((option, index) => (
                      <div key={index} className="form-check   ">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="expertiseLevel"
                          id={`expertiseLevel-${index}`}
                          value={option.value} // Utilise `option.value`
                          checked={
                            clientInscriptionFormData.expertiseLevel ===
                            option.value
                          } // Compare avec `option.value`
                          onChange={handleChange}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`expertiseLevel-${index}`}
                        >
                          {option.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                {/* <div className="mb-3 mt-5">
                <label className="form-label">Est-ce que ce projet donne lieu à une ressource permanente?</label>
                <div className='ps-5'>
                  {permanentResourceOptions.map((option, index) => (
                    <div key={index} className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="permanentResource"
                        id={`permanentResource-${index}`}
                        value={option}
                        checked={clientInscriptionFormData.permanentResource === option}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor={`permanentResource-${index}`}>
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
              </div> */}

                <div className="mb-3 mt-5 ">
                  <label className="form-label    font-bold">
                    Quel niveau d'engagement de temps recherchez-vous?
                  </label>
                  {formErrors.timeEngagement && (
                    <p style={{ color: "red" }}>{formErrors.timeEngagement}</p>
                  )}
                  {/* Ajoutez le champ pour le niveau d'engagement de temps */}
                  <div className="ps-5">
                    {timeEngagementOptionsArray.map((option, index) => (
                      <div key={index} className="form-check   ">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="timeEngagement"
                          id={`timeEngagement-${index}`}
                          value={option.value} // Utilise `option.value`
                          checked={
                            clientInscriptionFormData.timeEngagement ===
                            option.value
                          } // Compare avec `option.value`
                          onChange={handleChange}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`timeEngagement-${index}`}
                        >
                          {option.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="d-grid gap-2 d-flex justify-content-md-end mt-5">
                  <button
                    className="previousStep max-2xl:!text-fluid-ms"
                    onClick={() => dispatch(prevStep())}
                  >
                    Précédent
                  </button>{" "}
                  <button
                    className="nextStep max-2xl:!text-fluid-ms"
                    onClick={handleNextStep}
                  >
                    Suivant
                  </button>
                </div>
              </>
            )}

            {step === 4 && (
              <>
                <div className="mb-3">
                  <label htmlFor="clientName" className="font-bold ">
                    Votre nom
                  </label>

                  <input
                    type="text"
                    className="form"
                    id="clientName"
                    name="clientName"
                    value={clientInscriptionFormData.clientName}
                    onChange={handleChange}
                  />
                  {formErrors.clientName && (
                    <p style={{ color: "red" }}>{formErrors.clientName}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="companyName" className="font-bold">
                    Nom de l'entreprise
                  </label>

                  <input
                    type="text"
                    className="form"
                    id="companyName"
                    name="companyName"
                    value={clientInscriptionFormData.companyName}
                    onChange={handleChange}
                  />
                  {formErrors.companyName && (
                    <p style={{ color: "red" }}>{formErrors.companyName}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="font-bold">
                    Email
                  </label>

                  <input
                    type="email"
                    className="form"
                    id="email"
                    name="email"
                    value={clientInscriptionFormData.email}
                    onChange={handleChange}
                  />
                  {formErrors.email && (
                    <p style={{ color: "red" }}>{formErrors.email}</p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="country" className="font-bold">
                    Pays
                  </label>
                  <select
                    id="country"
                    name="country"
                    value={clientInscriptionFormData.country}
                    onChange={handleCountryChange}
                    required
                    className="form"
                    defaultValue="FR"
                  >
                    <option value="" disabled>
                      Sélectionnez un pays
                    </option>
                    {countryOptions.map((option) => (
                      <option key={option.code} value={option.code}>
                        {option.name}
                      </option>
                    ))}
                  </select>

                  {formErrors.country && (
                    <p style={{ color: "red" }}>{formErrors.country}</p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="phoneNumber" className="font-bold">
                    Numéro de téléphone
                  </label>

                  <div>
                    <PhoneInput
                      key={clientInscriptionFormData.country}
                      country={
                        clientInscriptionFormData.country?.toLowerCase() ?? "fr"
                      }
                      value={clientInscriptionFormData.phoneNumber}
                      onChange={handlePhoneNumberChange}
                      name="phoneNumber"
                      inputClass="form-control w-100"
                      placeholder=""
                      className="form"
                      containerStyle={{
                        borderRadius: "30px",
                      }}
                      inputStyle={{
                        border: "0px",
                        fontSize: "23px",
                        width: "100%",
                        outline: "none",
                        boxShadow: "none", // to remove any focus ring or shadow
                      }}
                      buttonStyle={{
                        background: "transparent",
                        border: "0px",
                        borderRadius: "30px",
                      }}
                      dropdownStyle={{
                        border: "1px solid #ccc", // Add border to dropdown if needed
                        borderRadius: "8px", // Adjust border radius for dropdown
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Optional shadow
                      }}
                    />
                  </div>
                  {formErrors.phoneNumber && (
                    <p style={{ color: "red" }}>{formErrors.phoneNumber}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="font-bold">
                    Mot de passe
                  </label>
                  <div style={{ position: "relative" }}>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      className="form"
                      id="password"
                      name="password"
                      value={clientInscriptionFormData.password}
                      onChange={handleChange}
                      style={{ paddingRight: "2.5rem" }}
                    />
                    <span
                      className="password-toggle mx-4"
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "8px",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                  {formErrors.password && (
                    <p style={{ color: "red" }}>{formErrors.password}</p>
                  )}
                </div>

                {/* <div className="mb-3">
              <label htmlFor="confirmPassword" className="form-label">Confirmation de mot de passe</label>
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                name="confirmPassword"
                value={clientInscriptionFormData.confirmPassword}
                onChange={handleChange}
              />
            </div> */}

                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

                <div className="d-grid gap-2 d-flex justify-content-md-end mt-5">
                  <button
                    className="previousStep me-md-2"
                    onClick={() => dispatch(prevStep())}
                  >
                    Précedent
                  </button>
                  <button
                    id="submitButton"
                    className="nextStep"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Enregistrer
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default MultiStepForm;
