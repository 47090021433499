const styles = {

     container: {
      maxWidth: '800px',
      margin: 'auto',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#ffffff',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      minHeight: '100vh',
    },
    header: {
      textAlign: 'center',
      marginBottom: '20px',
      backgroundColor: '#4CAF50',
      padding: '10px',
      borderRadius: '8px',
      color: 'white',
    },
    title: {
      margin: '0',
      fontSize: '28px',
      fontWeight: 'bold',
      color: '#fff',
    },
    environment: {
      color: '#fff',
      fontSize: '16px',
      margin: '5px 0',
    },
    apiUrl: {
      color: '#fff',
      fontSize: '16px',
      margin: '5px 0',
    },
    nav: {
      background: '#e91e63',
      padding: '10px',
      borderRadius: '5px',
      marginBottom: '20px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
    },
    navList: {
      listStyle: 'none',
      padding: '0',
      margin: '0',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      width: '100%',
    },
    navItem: {
      margin: '5px 10px',
      paddingTop: '5px',  
      paddingBottom: '5px', 
    },
    navLink: {
      textDecoration: 'none',
      color: 'white',
      padding: '8px 15px',
      borderRadius: '5px',
      backgroundColor: '#e91e63',
      margin: '0 5px',
      transition: 'background-color 0.3s',
    },
    navLinkHover: {
      textDecoration: 'none',
      color: 'white',
      padding: '8px 15px',
      borderRadius: '5px',
      backgroundColor: '#ff4081',
      margin: '0 5px',
      transition: 'background-color 0.3s',
    },
    welcomeContainer: {
      textAlign: 'center',
      padding: '20px',
    },
    welcomeImage: {
      maxWidth: '100%',      // Assurez-vous que l'image ne dépasse pas la largeur de son conteneur
      height: 'auto',       // Gardez le ratio hauteur/largeur original de l'image
      display: 'block',     // Les images sont inline par défaut, block permet un meilleur contrôle
      margin: '0 auto',     // Centrer l'image horizontalement
    },
  };

  export {styles};