import React, { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { API_GATEWAY_URL } from "../constants";
import Layout from "./Layout";
import ProviderCard from "./ProviderCard";
import "../styles/ProviderProfile.css";
import LoadingSpinner from "./LoadingSpinner";

const ProviderProfile = () => {
  const navigate = useNavigate();
  const [providerData, setProviderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const { user } = useAuth();

  const fetchProviderData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_GATEWAY_URL}/provider-profile/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setProviderData(data);
      } else {
        throw new Error("Failed to fetch provider data");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteContact = async (contactId) => {
    try {
      const response = await fetch(
        `${API_GATEWAY_URL}/provider-contact-delete/${contactId}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );

      if (response.ok) {
        setSuccessMessage("Contact supprimé avec succès.");
        fetchProviderData(); // Recharge les données du prestataire
      } else {
        throw new Error("Échec de la suppression du contact");
      }
    } catch (error) {
      setError("Erreur lors de la suppression du contact: " + error.message);
      setSuccessMessage("");
    }
  };

  const displayContacts = (contacts, contactType) => {
    const filteredContacts = contacts.filter((c) => c.type === contactType);

    if (filteredContacts.length === 0) {
      return <p>Aucun</p>;
    }

    return filteredContacts.map((contact) => (
      <div key={contact.id}>
        <span>
          {contact.first_name} {contact.last_name} - {contact.email} -{" "}
          {contact.phone_number}
        </span>
        <button onClick={() => handleDeleteContact(contact.id)}>
          Supprimer
        </button>
      </div>
    ));
  };
  const navigateToResources = () => {
    navigate("/provider-resources"); // Assure-toi que le chemin est correct et configuré dans ton router
  };
  const navigateToAddContact = () => {
    navigate("/provider-add-contact");
  };
  useEffect(() => {
    if (user && user.access_token && user.user_email) {
      fetchProviderData();
    } else {
      setError("User not authenticated");
      setLoading(false);
    }
  }, [user]);

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  const handleEditProfile = () => {
    navigate("/provider-profile-update");
  };

  return (
    <div className="provider-profile-container">
      {providerData && <ProviderCard providerData={providerData} />}
      <div className="profile-details">
        <h2>Profil de l’entreprise</h2>
        <div className="profile-cell">
          <h3>Informations de l'entreprise</h3>
          <p>Nom de l'entreprise: {providerData.name}</p>
          <p>Nis de l'entreprise: {providerData.nis}</p>
          <p>Nif de l'entreprise: {providerData.nif}</p>
          <p>NRC de l'entreprise: {providerData.nrc}</p>
          <p>Statut: {providerData.provider_status}</p>
          <p>Effectif de l'entreprise: {providerData.size}</p>
        </div>
        <div className="profile-cell">
          <h3>Emplacement</h3>
          <p>Pays: {providerData.country}</p>
          <p>Code postal: {providerData.postalCode}</p>
          <p>Ville: {providerData.city}</p>
          <p>Commune: {providerData.commune}</p>
        </div>
        <div className="profile-cell">
          <h3>Contact</h3>
          <p>Téléphone de l'entreprise: {providerData.phone}</p>
        </div>
        <div className="profile-cell">
          <h3>Contact du gérant</h3>
          <p>Prénom du gérant: {providerData.managerFirstName}</p>
          <p>Nom du gérant: {providerData.manager_name}</p>
          <p>Téléphone du gérant: {providerData.manager_phone}</p>
          <p>Email du gérant: {providerData.manager_email}</p>
        </div>
        {providerData.logo_url && (
          <div className="profile-logo">
            <img src={providerData.logo_url} alt="Logo de l'entreprise" />
          </div>
        )}
        <button onClick={handleEditProfile} className="profile-edit-button">
          Modifier le profil
        </button>
      </div>
    </div>
  );
};

export default ProviderProfile;
