import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import "../../index.css";
import "../../styles/Buttons.css";

const AddClientForm = ({ isOpen, onClose }) => {
  // Move hooks outside the conditional
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    phone_number: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Only render the form if `isOpen` is true
  if (!isOpen) return null;

  const handleChange = (e) => {
    setErrorMessage("");
    setSuccessMessage("");
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    const bp_id = localStorage.getItem("bp_id"); // Assuming bp_id is stored in localStorage

    try {
      const response = await axios.post(
        `http://localhost:8000/apigatewayservice/business-partner/send-invitation/${bp_id}/`,
        formData
      );

      if (response.status === 201) {
        setSuccessMessage(response.data.message);
        // Optionally reset form data
        setFormData({
          nom: "",
          prenom: "",
          email: "",
          phone_number: "",
        });
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        if (typeof errorData === "object") {
          const errorMessages = Object.values(errorData).flat().join(" ");
          setErrorMessage(errorMessages);
        } else {
          setErrorMessage("An error occurred while processing the request.");
        }
      } else {
        setErrorMessage("An error occurred while processing the request.");
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="MediumContainer  max-w-lg relative">
        <button
          className="absolute top-2 bg-transparent right-2 text-gray-500"
          onClick={onClose}
        >
          <IoCloseOutline size={30} />
        </button>
        <h2 className="font-bold text-fluid-md text-primary-800 font-display">
          Ajoutez un nouveau client
        </h2>

        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
        {successMessage && (
          <p className="text-green-500 mb-4">{successMessage}</p>
        )}

        <form onSubmit={handleSubmit}>
          <div>
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="nom"
            >
              Nom <span className="text-red-500">*</span>
            </label>
            <input
              id="nom"
              type="text"
              value={formData.nom}
              onChange={handleChange}
              className="form"
              placeholder="Doe"
              required
            />
          </div>
          <div className="">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="prenom"
            >
              Prenom <span className="text-red-500">*</span>
            </label>
            <input
              id="prenom"
              type="text"
              value={formData.prenom}
              onChange={handleChange}
              className="form"
              placeholder="John"
              required
            />
          </div>
          <div className="">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email <span className="text-red-500">*</span>
            </label>
            <input
              id="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              className="form"
              placeholder="JohnDoe@gmail.com"
              required
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="phone_number"
            >
              Phone <span className="text-red-500">*</span>
            </label>
            <input
              id="phone_number"
              type="text"
              value={formData.phone_number}
              onChange={handleChange}
              className="form"
              placeholder="0724934563"
            />
          </div>

          <div className="flex flex-row gap-1 mb-3 ">
            <button type="button" className="previousStep" onClick={onClose}>
              Annuler
            </button>

            <button type="submit" className="nextStep">
              Ajouter client
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddClientForm;

/*import React, { useState } from "react";
import Logo from "../../assets/logoWhite.png";
import { useNavigate } from "react-router-dom";
import axios from "axios"; 

const AddClientForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    phone_number: ""  // Changed from 'phone' to 'phone_number'
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    setErrorMessage("");
    setSuccessMessage("");
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    setErrorMessage("");
  setSuccessMessage("");

    e.preventDefault();
    console.log("Form Data:", formData);
    try {
      const bp_id = '1'; 
      const response = await axios.post(
        `http://localhost:8000/apigatewayservice/business-partner/send-invitation/${bp_id}/`,
        formData
      );
  
      if (response.status === 201) {
        setSuccessMessage(response.data.message);
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        // Extracting structured error messages
        const errorData = error.response.data;
        if (typeof errorData === 'object') {
          const errorMessages = Object.values(errorData).flat().join(' '); // Combine all error messages
          setErrorMessage(errorMessages);
        } else {
          setErrorMessage("An error occurred while processing the request.");
        }
      } else {
        setErrorMessage("An error occurred while processing the request.");
      }
    }
  };


  return (
    <div className="min-h-screen bg-[#7D9DCC] flex items-start justify-center pt-16">
      <div className="absolute top-8 left-8">
        <img src={Logo} alt="Logo" className="w-30 h-10" />
      </div>
      <div className="w-full md:w-1/2 flex items-center justify-center">
        <form onSubmit={handleSubmit} className="w-full max-w-md p-6 md:px-8 bg-white rounded-lg shadow-md">
          <h2 className="text-xl md:text-xl font-bold mb-6 text-gray-900">Ajoutez un nouveau client</h2>

          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          {successMessage && <p className="text-green-500">{successMessage}</p>}

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nom">
              Nom <span className="text-red-500">*</span>
            </label>
            <input
              id="nom"
              type="text"
              value={formData.nom}
              onChange={handleChange}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-400"
              placeholder="Doe"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="prenom">
              Prenom <span className="text-red-500">*</span>
            </label>
            <input
              id="prenom"
              type="text"
              value={formData.prenom}
              onChange={handleChange}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-400"
              placeholder="John"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email <span className="text-red-500">*</span>
            </label>
            <input
              id="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-400"
              placeholder="JohnDoe@gmail.com"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone_number">
              Phone
            </label>
            <input
              id="phone_number"  // Changed from 'phone' to 'phone_number'
              type="text"
              value={formData.phone_number}  // Changed from 'formData.phone' to 'formData.phone_number'
              onChange={handleChange}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-400"
              placeholder="0724934563"
            />
          </div>
          <button
            type="submit"
            className="bg-blue-950 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mb-2"
          >
            Ajouter client
          </button>
          <button
            type="button"
            className="bg-transparent hover:bg-gray-100 text-gray-700 hover:text-gray-900 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full border border-gray-300 hover:shadow-md"
            onClick={() => navigate("/clients-bp")} 
          >
            Annuler
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddClientForm;
*/
