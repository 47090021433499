import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { toast } from "react-toastify"; // Pour afficher les notifications de succès ou d'erreur
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import DemanderRessource from "../BusinessPartner/DemanderRessource";
import "../../index.css";
import "../../styles/Buttons.css";

const bp_id = localStorage.getItem("bp_id");
console.log(bp_id);
//const bp_id = 1; // ID du Business Partner (test)

const ResourcesTable = ({ apiGatewayUrl }) => {
  const [resources, setResources] = useState([]);
  const navigate = useNavigate();

  const [isFormOpen, setIsFormOpen] = useState(false);
  // Récupérer les données des ressources via l'API lors du chargement du composant
  useEffect(() => {
    const fetchResources = async () => {
      try {
        const response = await fetch(
          `${apiGatewayUrl}/businesspartner/${bp_id}/resources/`
        );
        if (response.ok) {
          const data = await response.json();
          // Vous pouvez personnaliser les champs récupérés ici, ou conserver les valeurs par défaut
          setResources(data);
        } else {
          toast.error("Erreur lors de la récupération des ressources");
        }
      } catch (error) {
        toast.error(
          "Une erreur est survenue lors de la récupération des ressources"
        );
      }
    };

    fetchResources();
  }, []);

  // Champs constants : numéro de téléphone, statut, date d'association (vous pouvez les modifier selon les besoins)
  const constantTel = "123-456-7890";
  const constantStatus = "Disponible";
  const constantPrice = "534 $";

  return (
    <div className="pw-page-layout font-body max-2xl:text-fluid-xs 2xl:text-fluid-sm text-primary-800">
      <Header />
      <div className="w-full flex flex-col">
        <Sidebar />
        <div className="flex flex-col justify-center items-center">
          <div className="w-full max-w-screen-xl lg:px-8">
            <div className="flex justify-between items-center mb-6 mt-6">
              <p className="max-2xl:text-fluid-md 2xl:text-fluid-lg font-bold ml-4">
                Ressources
              </p>
              <button className="btn-red" onClick={() => setIsFormOpen(true)}>
                Demander ressource
              </button>

              {isFormOpen && (
                <DemanderRessource
                  isOpen={isFormOpen}
                  onClose={() => setIsFormOpen(false)} // Close the modal
                />
              )}
            </div>
            <div className="bg-white rounded-lg border border-gray-300 ">
              <table className="min-w-full table-auto">
                <thead>
                  <tr className="bg-gray-100 text-left">
                    <th className="px-4 py-3 bg-primary-800 border border-[#F0F0F0] text-white">
                      Nom Complet
                    </th>
                    <th className="px-4 py-3 bg-primary-800 border border-[#F0F0F0] text-white">
                      Email
                    </th>
                    <th className="px-4 py-3 bg-primary-800 border border-[#F0F0F0] text-white">
                      Tel
                    </th>
                    <th className="px-4 py-3 bg-primary-800 border border-[#F0F0F0] text-white">
                      Prix d'achat
                    </th>
                    <th className="px-4 py-3 bg-primary-800 border border-[#F0F0F0] text-white">
                      Disponibilité
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {resources.map((resource, index) => (
                    <tr key={index} className="text-left border-b">
                      <td className="px-4 py-3">{resource.name}</td>
                      <td className="px-4 py-3">{resource.email}</td>
                      <td className="px-4 py-3">{constantTel}</td>{" "}
                      {/* Numéro de téléphone constant */}
                      <td className="px-4 py-3">{constantPrice}</td>{" "}
                      {/* Date d'association constante */}
                      <td className="px-4 py-3">
                        <span
                          className={`px-3 py-1 rounded-full text-white ${
                            constantStatus === "Disponible"
                              ? "bg-green-700"
                              : "bg-red-700"
                          }`}
                        >
                          {constantStatus} {/* Statut constant */}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourcesTable;
