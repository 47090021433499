import React from 'react'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import AddTaskIcon from '@mui/icons-material/AddTask';
import '../../index.css'

const MissionInfos = ({currentMissions, finishedMissions, newMissions}) => {
    return (
        <div className="flex flex-col md:flex-row md:justify-between md:flex-wrap">
            <div 
                className='flex gap-2 items-center sm:justify-center md:justify-center mb-2' 
                style={{
                    backgroundColor: 'var(--clr-neutral-50)',
                    padding: '.7em 1.5em',
                    border: '2px solid var(--clr-primary-200)',
                    flexBasis: '1/3'
                }}
            >
                <AssignmentOutlinedIcon 
                    sx={{
                        color: 'var(--clr-white)',
                        width: '2.125em',
                        height: '2.125em',
                        backgroundColor: 'var(--clr-primary-400)',
                        borderRadius: '50%',
                        border: '3px solid var(--clr-primary-200)',
                        padding: '.4em'
                    }}
                />
                <div
                    className='text-center'
                    style={{
                        color: 'var(--clr-primary-500)',
                        fontSize: '1.125em',
                        fontWeight: 'var(--fw-medium)',
                        fontFamily: 'var(--ff-body)',
                        textTransform: 'capitalize',
                        width: 'max-content'
                    }}
                >
                    missions en cours : <span>{currentMissions}</span>
                </div>
            </div>
            <div 
                className='flex gap-2 items-center sm:justify-center md:justify-center mb-2' 
                style={{
                    backgroundColor: 'var(--clr-neutral-50)',
                    padding: '.7em 1.5em',
                    border: '2px solid var(--clr-primary-200)',
                    flexBasis: '1/3'
                }}
            >
                <AddTaskIcon 
                    sx={{
                        color: 'var(--clr-white)',
                        width: '2.125em',
                        height: '2.125em',
                        backgroundColor: 'var(--clr-primary-400)',
                        borderRadius: '50%',
                        border: '3px solid var(--clr-primary-200)',
                        padding: '.4em'
                    }}
                />
                <div
                    className='text-center'
                    style={{
                        color: 'var(--clr-primary-500)',
                        fontSize: '1.125em',
                        fontWeight: 'var(--fw-medium)',
                        fontFamily: 'var(--ff-body)',
                        textTransform: 'capitalize',
                        width: 'max-content'
                    }}
                >
                    nouvelles missions : <span>{newMissions}</span>
                </div>
            </div>
            <div 
                className='flex gap-2 items-center sm:justify-center md:justify-center mb-2' 
                style={{
                    backgroundColor: 'var(--clr-neutral-50)',
                    padding: '.7em 1.5em',
                    border: '2px solid var(--clr-primary-200)',
                    flexBasis: '1/3'
                }}
            >
                <TaskAltIcon 
                    sx={{
                        color: 'var(--clr-white)',
                        width: '2.125em',
                        height: '2.125em',
                        backgroundColor: 'var(--clr-primary-400)',
                        borderRadius: '50%',
                        border: '3px solid var(--clr-primary-200)',
                        padding: '.4em'
                    }}
                />
                <div className='text-center'
                    style={{
                        color: 'var(--clr-primary-500)',
                        fontSize: '1.125em',
                        fontWeight: 'var(--fw-medium)',
                        fontFamily: 'var(--ff-body)',
                        textTransform: 'capitalize',
                        width: 'max-content'
                    }}
                >
                    missions terminées : <span>{finishedMissions}</span>
                </div>
            </div>
        </div>
    )
}

export default MissionInfos;