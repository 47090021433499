import React, {useState} from 'react';
import { PersonalInformation, Skills, ProfessionalExperience, EducationAndCertificates, SideMenuSettings, Preferences, Menu } from '../../components';
import { useAuth } from '../../components/AuthContext';
const AccountSettingsPage = () => {
    const [selectedSection, setSelectedSection] = useState('personalInformation');
    const { user } = useAuth();


    return (
        <div className='container flex flex-col sm:py-2 md:py-6 sm:gap-4 md:gap-12'>
            <Menu user={user} />
            <div className="container py-3 md:py-5 flex gap-4">
                <SideMenuSettings onSelectSection={setSelectedSection} />
                <div className='w-full'>
                    { selectedSection === 'personalInformation' && <PersonalInformation />}
                    { selectedSection === 'professionalExperience' && <ProfessionalExperience />}
                    { selectedSection === 'educationAndCertificates' && <EducationAndCertificates />}
                    { selectedSection === 'skills' && <Skills />}
                    { selectedSection === 'preferences' && <Preferences /> }
                </div>
            </div>
        </div>
    );
};

export default AccountSettingsPage;