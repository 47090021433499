import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import Select from "react-select";
import Swal from "sweetalert2";
import { API_GATEWAY_URL } from "../constants";
import LoadingSpinner from "./LoadingSpinner";
import ProviderCard from "./ProviderCard"; // Import the new component

import "../styles/AddExpertisePage.css";

const AddExpertisePage = () => {
  const [expertises, setExpertises] = useState([]);
  const [selectedExpertise, setSelectedExpertise] = useState(null);
  const [level, setLevel] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [providerData, setProviderData] = useState(null);

  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.access_token) {
      const fetchExpertises = async () => {
        try {
          const response = await fetch(`${API_GATEWAY_URL}/expertises/`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
            },
          });
          if (!response.ok) {
            throw new Error("Échec de la récupération des expertises");
          }
          const data = await response.json();
          setExpertises(
            data.map((exp) => ({ value: exp.id, label: exp.name }))
          );
        } catch (err) {
          setError(err.message);
        } finally {
        }
      };
      fetchExpertises();
    } else {
      setError("Utilisateur non authentifié.");
    }
  }, [user]);

  const handleExpertiseChange = (selectedOption) => {
    setSelectedExpertise(selectedOption);
  };

  const handleLevelChange = (e) => {
    setLevel(e.target.value);
  };
  const fetchProviderData = async () => {
    try {
      const response = await fetch(`${API_GATEWAY_URL}/provider-profile/`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch provider data.");
      }
      const data = await response.json();
      setProviderData(data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (user && user.access_token) {
      fetchProviderData(); // Fetch provider data on component mount
    }
  }, [user]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedExpertise || !level) {
      Swal.fire(
        "Erreur",
        "Veuillez sélectionner une expertise et un niveau",
        "error"
      );
      return;
    }

    if (!user || !user.access_token) {
      Swal.fire(
        "Erreur d’authentification",
        "Utilisateur non authentifié",
        "error"
      );
      return;
    }

    try {
      const response = await fetch(
        `${API_GATEWAY_URL}/expertises-by-provider2/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            expertise: selectedExpertise.value,
            level: level,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Échec de l’ajout de l’expertise");
      }
      Swal.fire("Succès", "Expertise ajoutée avec succès", "success");
      navigate("/provider-expertises"); // Rediriger vers la page principale ou le tableau de bord
    } catch (err) {
      Swal.fire("Erreur", err.message, "error");
    }
  };

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <div className="loading-error-container">Erreur : {error}</div>;
  }

  return (
    <div className="add-expertise-container">
      {providerData && <ProviderCard providerData={providerData} />}
      <div className="add-expertise-container2">
        <h1>Ajouter une nouvelle expertise</h1>
        <form onSubmit={handleSubmit} className="add-expertise-form">
          <Select
            classNamePrefix="select-expertise"
            options={expertises}
            onChange={handleExpertiseChange}
            value={selectedExpertise}
            placeholder="Sélectionner l'expertise"
          />
          <select
            value={level}
            onChange={(e) => setLevel(e.target.value)}
            className="select-expertise"
            disabled={!selectedExpertise}
          >
            <option value="">Sélectionner le niveau</option>
            <option value="JR">Junior</option>
            <option value="CF">Confirmé</option>
            <option value="SR">Senior</option>
          </select>
          <button type="submit">Ajouter l'expertise</button>
        </form>
      </div>
    </div>
  );
};

export default AddExpertisePage;
