import React from "react";
import profile from "../../assets/profile.png";
import notification from "../../assets/notification.png";
import { useNavigate } from "react-router-dom";
import { FaBell, FaSignOutAlt } from "react-icons/fa";
import { useAuth } from "./../AuthContext";

const Header = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <nav className="bg-white  border-b shadow-md w-full flex items-center px-6 py-3 -mt-6">
      <div className="flex-1 flex items-center">
        {/* Name Section */}
        <div className="flex items-center">
          <h1 className="text-primary-800 font-body font-bold ml-20 max-2xl:text-fluid-sm 2xl:text-fluid-lg">
            Bienvenue, <span className="text-secondary-500 ">John Doe</span>
          </h1>
        </div>
      </div>

      <div className="flex items-center space-x-4 mr-7">
        <img src={profile} alt="Profile" className="w-10 h-10 rounded-full" />

        <button
          onClick=""
          className="text-primary-800 bg-transparent hover:text-primary-950 transition-colors duration-300 p-1"
        >
          <FaBell className="max-2xl:h-5 max-2xl:w-5" />
        </button>

        <button
          onClick={handleLogout}
          className="text-primary-800 bg-transparent hover:text-primary-950 transition-colors duration-300 p-1"
        >
          <FaSignOutAlt className="max-2xl:h-5 max-2xl:w-5" />
        </button>
      </div>
    </nav>
  );
};

export default Header;

/*
 OLD CODE
import React from "react";
import profile from "../../assets/profile.png"
import notification from "../../assets/notification.png"
const Header = () => {
  return (
    <div className="flex justify-between items-center mb-4">
      <div>
        <h1 className="text-2xl font-bold">Espace Business Partner</h1>
        <p>Bienvenue, John Doe</p>
      </div>
      <div className="flex items-center">
        <div className="relative mr-4">
          <span className="absolute right-0 top-0 bg-red-500 text-white rounded-full text-xs w-3 h-3 flex items-center justify-center"></span>      
          <button className="text-gray-600 bg-white" >
            🔔
          </button>
      </div>
        <img src={profile} alt="Profile" className="w-10 h-10 rounded-full" />
      </div>
    </div>
  );
};

export default Header;
*/
