import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

// Hook personnalisé pour gérer la connexion
const useLogin = (apiGatewayUrl, login) => {
  const navigate = useNavigate();

  const handleLogin = async (username, password, setError) => {
    try {
      const response = await fetch(`${apiGatewayUrl}/worker-login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        const { user_email, access_token } = data;

        login({ user: { user_email, access_token }, userType: 'worker' });
        navigate('/worker-welcome');
      } else {
        setError('Identifiants incorrects.');
      }
    } catch (error) {
      setError('Erreur technique. Veuillez réessayer plus tard.');
    }
  };

  return { handleLogin };
};

// Composant de connexion pour Worker
const WorkerLogin = ({ apiGatewayUrl }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { handleLogin } = useLogin(apiGatewayUrl, useAuth().login);

  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin(username, password, setError);
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Espace Ressource</h2>
      {error && <div style={styles.error}>{error}</div>}
      <form style={styles.form} onSubmit={handleSubmit}>
        <div style={styles.inputGroup}>
          <label style={styles.label}>
            Adresse e-mail
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={styles.input}
            />
          </label>
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>
            Mot de passe
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={styles.input}
            />
          </label>
        </div>
        <button type="submit" style={styles.button}>
          Se connecter
        </button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    background: '#fff',
  },
  title: {
    textAlign: 'center',
    color: '#333',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
  },
  inputGroup: {
    width: '100%', // Ajout pour aligner les champs de saisie
  },
  label: {
    display: 'block', // Changé en 'block' pour un meilleur alignement
    margin: '10px 0',
    fontSize: '16px',
    color: '#555',
    width: '100%',
  },
  input: {
    padding: '8px',
    fontSize: '14px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    width: '100%', // Assure que les champs de saisie s'étendent sur toute la largeur
    boxSizing: 'border-box', // Garantit que le padding est inclus dans la largeur
  },
  button: {
    backgroundColor: '#007BFF',
    color: '#fff',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
    transition: 'background-color 0.3s ease-in-out',
    width: '100%', // Assure que le bouton s'étend sur toute la largeur
  },
  error: {
    backgroundColor: '#FFD2D2',
    color: '#D8000C',
    padding: '10px',
    marginBottom: '20px',
    border: '1px solid #D8000C',
    borderRadius: '5px',
    textAlign: 'center',
  },
};

export default WorkerLogin;

