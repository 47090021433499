import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { API_GATEWAY_URL } from "../../constants";
import { useAuth } from "../AuthContext";
import FreelancerSolde from "./solde";
import { ReactComponent as DownloadIcon } from "../../assets/paiement/download.svg";

const TransactionsFreelancer = () => {
  const [resources, setResources] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const { user } = useAuth();

  useEffect(() => {
    const fetchTransactions = async () => {
      console.log("Fetching transactions...", user.user_email );
      try {
        const response = await fetch(
          `${API_GATEWAY_URL}/get-resource-transactions/?resource_email=${user.user_email}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.access_token}`,
            },
           
          }
        );
        const data = await response.json();
        console.log("Data:", data);
        if (data && Array.isArray(data)) {
          setResources(data);
        } else {
          console.error("Unexpected data format:", data);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchTransactions();
  }, [user]);

  const columns = [
    { field: "id", headerName: "Référence", width: 150 },
    { field: "nom", headerName: "Client", width: 200 },
    { field: "amount", headerName: "Prix (€)", width: 150 },
    {field: "currency", headerName: "Devise", width: 150},
    { field: "transaction_date", headerName: "Date", width: 150 },
    {
      field: "download",
      headerName: "Facture",
      width: 100,
      renderCell: () => (
        <div className=" text-gray-600 hover:text-gray-900 w-[20px] cursor-pointer">
          <DownloadIcon fill="currentColor" className="text-gray-600 group-hover:text-black" />
          
        </div>
      ),
    },
  ];

  return (
    <div className="justify-start">
      <div className="text-white lg:text-fluid-md font-bold font-Poppins xs:text-fluid-sm bg-[#344567] sm:p-10 xs:px-2 xs:py-6">
        Mes Transactions
      </div>
      <div className="flex flex-row items-center justify-between w-full p-1 pr-6 mb-2">
        <FreelancerSolde />
      </div>
      <div className="font-Poppins font-bold text-xl px-10">Table de Transactions</div>
      <div className="sm:p-10 xs:px-2 xs:py-4">
        <div className="h-[400px] w-full">
          <DataGrid
            rows={resources}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            disableSelectionOnClick
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionsFreelancer;
