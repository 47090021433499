import React, {useState} from 'react'
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import '../../index.css';

const Card = ({iconName, cardName, onClick, isSelected})=>{
    return(
        <div 
            className={`
                flex
                items-center
                justify-start
                space-x-4
                cursor-pointer
                px-3 py-3
                rounded-md
                -text--clr-neutral-600
                hover:bg-blue-100
                hover:-text--clr-primary-500
                ${isSelected ? 'border-l-4 -border--clr-primary-500' : ''}
            `}
                
            style={{
                fontFamily: 'var(--ff-body)',
                fontWeight: 'var(--fw-medium)',
            }}
            onClick={onClick}
        >
            {iconName }
            <div 
                className="hidden md:flex md:items-center md:capitalize"
                style={{
                    '&:hover': {
                        fontWeight: 'var(--fw-bold)',
                    }
                }}
            >
                {cardName}
            </div>
        </div>
    )
} 

const SideMenuSettings = ({onSelectSection}) => {
    const [selectedSection, setSelectedSection] = useState('personalInformation');

    const handleSelectSection = (sectionName) => {
        setSelectedSection(sectionName);
    };

    return (
        <div className='w-1/4 h-fit rounded-lg px-0 py-0 md:px-4 md:py-3 -bg--clr-white'>
            <Card 
                iconName={<PortraitOutlinedIcon style={{fontFamily: 'var(--ff-body)',fontWeight: 'var(--fw-semibold)',width: '30px',height: '30px',}} />}
                cardName='Informations Personnelles' 
                onClick={()=>{
                    onSelectSection('personalInformation')
                    handleSelectSection('personalInformation')
                }}
                isSelected={selectedSection === 'personalInformation'}
                />
            <Card 
                iconName={<WorkOutlineOutlinedIcon style={{fontFamily: 'var(--ff-body)',fontWeight: 'var(--fw-semibold)',width: '30px',height: '30px',}}/>}
                cardName='Experience Professionnelle'
                onClick={()=>{
                    onSelectSection('professionalExperience')
                    handleSelectSection('professionalExperience')
                }}
                isSelected={selectedSection === 'professionalExperience'}
                />
            <Card 
                iconName={<DocumentScannerOutlinedIcon style={{fontFamily: 'var(--ff-body)',fontWeight: 'var(--fw-semibold)',width: '30px',height: '30px',}}/>}
                cardName='Education et Certifications'
                onClick={()=>{
                    onSelectSection('educationAndCertificates')
                    handleSelectSection('educationAndCertificates')
                }}
                isSelected={selectedSection === 'educationAndCertificates'}
                />
            <Card 
                iconName={<TerminalOutlinedIcon style={{fontFamily: 'var(--ff-body)',fontWeight: 'var(--fw-semibold)',width: '30px',height: '30px',}}/>}
                cardName='Compétences'
                onClick={()=>{
                    onSelectSection('skills')
                    handleSelectSection('skills')
                }}
                isSelected={selectedSection === 'skills'}
                />
            <Card
                iconName={<SettingsSuggestIcon style={{fontFamily: 'var(--ff-body)',fontWeight: 'var(--fw-semibold)',width: '30px',height: '30px',}}/>}
                cardName='Preferences'
                onClick={()=>{
                    onSelectSection('preferences')
                    handleSelectSection('preferences')
                }}
                isSelected={selectedSection === 'preferences'}
                />

        </div>
    )
}

export default SideMenuSettings