import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { API_GATEWAY_URL } from '../constants';
import AdminCard from './admin/AdminCard';
import '../styles/AdminDashboardPage.css';
import '../styles/AdminCard.css';
import '../styles/AdminRequestDetail.css';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import { Button, Chip, Checkbox } from '@mui/material';
import Swal from 'sweetalert2';

const AdminRequestDetail = () => {
  const navigate = useNavigate();
  const { demandeId } = useParams();
  const [requestDetail, setRequestDetail] = useState(null);
  const [entities, setEntities] = useState([]);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [proposedResources, setProposedResources] = useState([]);
  const [expertises, setExpertises] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    const fetchRequestDetail = async () => {
      try {
        if (!user) {
          setError('Aucun utilisateur connecté');
          setLoading(false);
          navigate('/admin-login');
          return;
        }
        const token = user.access_token;
        const response = await axios.get(`${API_GATEWAY_URL}/admin-client-demandes/${demandeId}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRequestDetail(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    const fetchEntities = async () => {
      try {
        if (!user) {
          setError('Aucun utilisateur connecté');
          setLoading(false);
          navigate('/admin-login');
          return;
        }
        const token = user.access_token;
        const response = await axios.get(`${API_GATEWAY_URL}/all-entities/${demandeId}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response.data);
        const providers = response.data.providers.map((provider) => ({
          ...provider,
          type: 'Entreprise',
          expertises: provider.expertises || [],
          expertises_detail: provider.expertises_detail || [],
          id: provider.id
        }));
        const workers = response.data.workers.map((worker) => ({
          ...worker,
          type: 'Entreprise (employé)',
          name: `${worker.name}`,
          expertises: worker.expertises || [],
          expertises_detail: worker.expertises_detail || [],
          id: worker.id
        }));
        const freelancers = response.data.freelancers.map((freelancer) => ({
          ...freelancer,
          type: 'Freelancer',
          name: `${freelancer.name}`,
          expertises: freelancer.expertises || [],
          expertises_detail: freelancer.expertises_detail || [],
          id: freelancer.id
        }));

        const allEntities = [...providers, ...workers, ...freelancers];
        setEntities(allEntities);
        setProposedResources(response.data.proposed_resources || []);
        setSelectedEntities(response.data.proposed_resources || []); // Initialiser selectedEntities
        console.log("ressources déjà proposées : ", response.data.proposed_resources);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchExpertises = async () => {
      try {
        const response = await axios.get(`${API_GATEWAY_URL}/expertises/`);
        setExpertises(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchRequestDetail();
    fetchEntities();
    fetchExpertises();
  }, [demandeId, user, navigate]);

  const handleSelect = (entityId) => {
    setSelectedEntities((prevSelected) => {
      const newSelected = prevSelected.includes(entityId)
        ? prevSelected.filter(id => id !== entityId)
        : [...prevSelected, entityId];
      console.log('Selected Entities:', newSelected);
      return newSelected;
    });
  };

  const handleAssignResources = async () => {
    const selectedResources = selectedEntities.map(entityId => {
      const [type, id] = entityId.split('_');
      return { type, id };
    });

    console.log('Selected entities', selectedEntities); // Vérifiez les données dans la console

    Swal.fire({
      title: 'Assignation des ressources',
      text: 'Veuillez patienter...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const token = user.access_token;
      await axios.post(`${API_GATEWAY_URL}/admin-proposition/`, {
        demandeId,
        resources: selectedEntities
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json' // Assurez-vous que les données sont envoyées sous forme de JSON
        },
      });

      await axios.post(`${API_GATEWAY_URL}/update-demande-statut/${demandeId}/`, {
        statut: "WS"
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      Swal.fire({
        icon: 'success',
        title: 'Succès',
        text: 'Les ressources ont été assignées avec succès!',
        confirmButtonText: 'OK'
      });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: `Échec de l'assignation des ressources: ${err.message}`,
        confirmButtonText: 'OK'
      });
    }
  };

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }
  if (error) {
    return <div>Erreur: {error}</div>;
  }

  const getExpertiseNameById = (id) => {
    const expertise = expertises.find(exp => exp.id === id);
    return expertise ? expertise.name : '';
  };

  const expertiseLevelMap = {
    'Junior': 'JR',
    'Confirmé': 'CF',
    'Sénior': 'SR',
    'Expert': 'SR'
  };

  const columns = [
    {
      field: 'select',
      headerName: 'Proposé au client',
      width: 150,
      renderCell: (params) => (
        <Checkbox
          checked={selectedEntities.includes(params.row.id)}
          onChange={() => handleSelect(params.row.id)}
        />
      ),
    },
    { field: 'name', headerName: 'Nom', width: 200 },
    { field: 'phone', headerName: 'numéro de téléphone', width: 200 },
    { field: 'email', headerName: 'Email', width: 230 },
    { field: 'type', headerName: 'Type', width: 180 },
    { field: 'validation_type', headerName: 'Type de validation', width: 180 },
    {
      field: 'expertises',
      headerName: 'Expertises',
      width: 500,
      renderCell: (params) => (
        <div>
          {params.row.expertises_detail && params.row.expertises_detail.map((expDetail, index) => {
            const expertiseName = getExpertiseNameById(expDetail.id);
            const requestLevelCode = expertiseLevelMap[requestDetail.expertise_level_readable];
            const matched = requestDetail.selectedExpertises.includes(expDetail.id) && expDetail.level === requestLevelCode;

            let chipColor = '';
            if (matched) {
              chipColor = '#90ee90'; // Light green
            } else if (requestDetail.selectedExpertises.includes(expDetail.id)) {
              chipColor = '#FFF033'; // Light yellow
            } else {
              chipColor = ''; // Default
            }

            return (
              <Chip
                key={index}
                label={`${expertiseName} - ${expDetail.level}`}
                style={{ margin: 2, backgroundColor: chipColor }}
              />
            );
          })}
        </div>
      ),
      sortComparator: (v1, v2, param1, param2) => {
        const countColors = (expertises) => {
          const counts = { green: 0, yellow: 0, default: 0 };
          expertises.forEach(exp => {
            if (exp.props && exp.props.style && exp.props.style.backgroundColor === '#90ee90') counts.green += 1;
            else if (exp.props && exp.props.style && exp.props.style.backgroundColor === '#FFF033') counts.yellow += 1;
            else counts.default += 1;
          });
          return counts;
        };

        const requestLevelCode = expertiseLevelMap[requestDetail.expertise_level_readable];

        const counts1 = countColors((param1?.row?.expertises_detail || []).map((exp) => ({
          props: { style: { backgroundColor: exp.level === requestLevelCode ? '#90ee90' : (requestDetail.selectedExpertises.includes(exp.id) ? '#FFF033' : '') } }
        })));
        const counts2 = countColors((param2?.row?.expertises_detail || []).map((exp) => ({
          props: { style: { backgroundColor: exp.level === requestLevelCode ? '#90ee90' : (requestDetail.selectedExpertises.includes(exp.id) ? '#FFF033' : '') } }
        })));

        if (counts1.green !== counts2.green) return counts2.green - counts1.green;
        if (counts1.yellow !== counts2.yellow) return counts2.yellow - counts1.yellow;
        return counts2.default - counts1.default;
      }
    },
  ];

  return (
    <div className="admin-requestDetail-container">
      <div className="adminDetail-card-container">
        <AdminCard />
      </div>
      <div className="detail-content">
        <div className="request-card">
          <h1>Détail de la demande</h1>
          {requestDetail && (
            <div>
              <p><strong>ID demande:</strong> {requestDetail.id}</p>
              <p><strong>Client:</strong> {requestDetail.client} - {requestDetail.clientname_readable}</p>
              <p><strong>Poste demandé:</strong> {requestDetail.posteTitle}</p>
              <p><strong>Type de Projet:</strong> {requestDetail.project_type_readable}</p>
              <p><strong>Niveau d'Expertise:</strong> {requestDetail.expertise_level_readable}</p>
              <p><strong>Statut:</strong> {requestDetail.statut_readable}</p>
              <p><strong>Date de Début:</strong> {requestDetail.date_debut}</p>
              <p><strong>Date de Fin:</strong> {requestDetail.date_fin}</p>
              <p><strong>Expertises sélectionnées:</strong></p>
              {requestDetail.selectedExpertises.map((expertiseId, index) => (
                <Chip key={index} label={getExpertiseNameById(expertiseId)} style={{ margin: 2 }} />
              ))}
            </div>
          )}
        </div>
        <h2>Liste de toutes les entités</h2>
        <div style={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={entities}
            columns={columns}
            pageSize={10}
            hideFooterSelectedRowCount
            disableSelectionOnClick
            sortingOrder={['desc', 'asc']}
          />
        </div>
        {requestDetail && requestDetail.statut.startsWith('W') && (
          <Button variant="contained" color="primary" onClick={handleAssignResources}>
            Proposer les ressources
          </Button>
        )}
      </div>
    </div>
  );
};

export default AdminRequestDetail;

