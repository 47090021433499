// import { BiHouseDoor, BiSpeedometer2, BiJournalText, BiBootstrap, BiGrid, BiPeople } from 'react-icons/bi';
import { FaExchangeAlt, FaHome, FaTachometerAlt, FaUserCog,FaBuilding,FaUserFriends, FaBook, FaBootstrap, FaTh, FaUsers, FaSearch, FaBars, FaBell, FaEnvelope, FaUser ,FaCog,FaList,FaSignOutAlt} from 'react-icons/fa';


import { Dropdown } from 'react-bootstrap';
import '../../../styles/Dashboard.css'; // Import du fichier CSS

import { useMediaQuery } from 'react-responsive';
import { Chart } from 'chart.js/auto';
import { useState, useEffect, useRef } from 'react';
import { Bar, Line } from 'react-chartjs-2';

function DashboardAdmin() {
  const [isSubMenu1Open, setSubMenu1Open] = useState(false);
  const [isSubMenu2Open, setSubMenu2Open] = useState(false);
  const [isSubMenu3Open, setSubMenu3Open] = useState(false);
  const [isSubMenu4Open, setSubMenu4Open] = useState(false);
  const [isSubMenu5Open, setSubMenu5Open] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 }); // Détection d'un écran de taille maximale de 768px
  
  const dataHistogram = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [{
      label: 'Prestations en cours',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
      borderColor: 'rgb(255, 99, 132)',
      borderWidth: 1
    }]
  };

  const dataPolygon = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [{
      label: 'Prestations en retard',
      data: [3, 5, 2, 12, 15, 7],
      // fill: true,
      backgroundColor: 'rgba(54, 162, 235, 0.5)',
      borderColor: 'rgb(54, 162, 235)',
      tension: 0.4
    }]
  };



  return (
    <div className="container-fluid">
      <div className="row flex-nowrap">
        <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-sidebar dashboard-sidebar">
          <div className="d-flex flex-column  px-3 pt-2 text-white min-vh-100">
            <a href="/" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
             
              <span className="ms-1 d-sm-none">Dashboard </span>
              <span className="ms-1 d-none d-sm-inline">Tableau de bord</span>
            </a>
            <ul className="nav nav-pills flex-column mb-sm-auto mb-0 " id="menu">
              <li className="nav-item ">
                <a href="#" className="nav-link align-middle px-0"   >
                <FaExchangeAlt /> Matching 
                  {/* <span className="ms-1 d-none d-sm-inline">Matching</span> */}

                  
                </a>
                
              </li>
              <li>
                <a href="#submenu1" onClick={() => setSubMenu1Open(!isSubMenu1Open)} className="nav-link px-0 align-middle " >
                  <FaUsers /> client
                  {/* <span className="ms-1 d-none d-sm-inline">client</span>  */}
                </a>
                <ul className={`collapse ${isSubMenu1Open ? 'show' : ''} nav flex-column ms-1 liste-nav-link`} id="submenu1" data-bs-parent="#menu">
                  <li className="w-100">
                    <a href="#" className="nav-link px-0"> Crée<span className="d-none d-sm-inline"> un client</span></a>
                  </li>
                  <li className="w-100">
                    <a href="#" className="nav-link px-0"> Liste<span className="d-none d-sm-inline"> des client</span></a>
                  </li>
                  <li>
                    <a href="#" className="nav-link px-0"> Demande<span className="d-none d-sm-inline"> de client </span> </a>
                  </li>
                </ul>
              </li>
              
              <li>
                <a href="#submenu2" onClick={() => setSubMenu2Open(!isSubMenu2Open)}  className="nav-link px-0 align-middle ">
                  <FaUserFriends /> Provider 
                  {/* <span className="ms-1 d-none d-sm-inline">Provider</span> */}
                </a>
                <ul className={`collapse ${isSubMenu2Open ? 'show' : ''} nav flex-column ms-1 liste-nav-link`} id="submenu2" data-bs-parent="#menu">
                  <li className="w-100">
                    <a href="#" className="nav-link px-0"> Créer <span className="d-none d-sm-inline"> un provider</span></a>
                  </li>
                  
                </ul>
              </li>
              <li>
                <a href="#submenu3" onClick={() => setSubMenu3Open(!isSubMenu3Open)} className="nav-link px-0 align-middle ">
                  <FaBuilding />
                   <span className="ms-1 d-sm-none">BP </span>
                   <span className="ms-1 d-none d-sm-inline">Business Partner</span>
                </a>
                <ul className={`collapse ${isSubMenu3Open ? 'show' : ''} nav flex-column ms-1 liste-nav-link`} id="submenu3" data-bs-parent="#menu">
                  <li className="w-100">
                    <a href="#" className="nav-link px-0"> Créer <span className="d-none d-sm-inline"> un BP</span></a>
                  </li>
                  <li>
                    <a href="#" className="nav-link px-0"> Affecter <span className="d-none d-sm-inline"> la ressource au Bp</span></a>
                  </li>
                  
                </ul>
              </li>
              <li>
                <a href="#submenu4" onClick={() => setSubMenu4Open(!isSubMenu4Open)} className="nav-link px-0 align-middle ">
                  <FaUserCog /> Freelancer
                  {/* <span className="ms-1 d-none d-sm-inline">Freelancer</span> */}
                </a>
                <ul className={`collapse ${isSubMenu4Open ? 'show' : ''} nav flex-column ms-1 liste-nav-link`} id="submenu4" data-bs-parent="#menu">
                  <li className="w-100">
                    <a href="#" className="nav-link px-0"> Créer <span className="d-none d-sm-inline">un freelancer</span></a>
                  </li>
                  
                  
                </ul>
              </li>
              <li>
              <a href="#submenu5" onClick={() => setSubMenu5Open(!isSubMenu5Open)} className="nav-link px-0 align-middle ">
                  <FaCog  /> Parametres
                  {/* <span className="ms-1 d-none d-sm-inline">Parametres</span> */}
                </a>
                <ul className={`collapse ${isSubMenu5Open ? 'show' : ''} nav flex-column ms-1 liste-nav-link`} id="submenu5" data-bs-parent="#menu">
                  <li className="w-100">
                    <a href="#" className="nav-link px-0"> Information  <span className="d-none d-sm-inline">de l'admin</span></a>
                  </li>
                  <li>
                    <a href="#" className="nav-link px-0"> Gestions <span className="d-none d-sm-inline">des comptes</span></a>
                  </li>
                  
                </ul>
              </li>
              
            </ul>
            <hr />
            <Dropdown className="pb-4">
              <Dropdown.Toggle variant="dark" id="dropdown-basic">
                <img src="https://github.com/mdo.png" alt="hugenerd" width="30" height="30" className="rounded-circle" />
                <span className="d-none d-sm-inline mx-1">user</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                
                <Dropdown.Item href="#">Profile</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#">Sign out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>




        

      <div className="col py-3 left-page">
       
        <div className="row">
          <div className="col-sm-4 mb-3">
            <div className="card text-center">
              <div className="card-body">
                <h5 className="card-title">Matching</h5>
                <div className="rounded-circle bg-success text-white mb-3" style={{ width: '50px', height: '50px', lineHeight: '50px', margin: '0 auto' }}>3</div>
               
              </div>
            </div>
          </div>
          <div className="col-sm-4 mb-3">
            <div className="card text-center">
              <div className="card-body">
                <h5 className="card-title">provider en attente de validation</h5>
                <div className="rounded-circle bg-primary text-white mb-3" style={{ width: '50px', height: '50px', lineHeight: '50px', margin: '0 auto' }}>1</div>
                
                
              </div>
            </div>
          </div>
          <div className="col-sm-4 mb-3">
            <div className="card text-center">
              <div className="card-body">
                <h5 className="card-title">Freelancer en attente de validation</h5>
                <div className="rounded-circle bg-secondary text-white mb-3" style={{ width: '50px', height: '50px', lineHeight: '50px', margin: '0 auto' }}>2</div>
                
                
              </div>
            </div>
          </div>
          <div className="col-sm-4 mb-3">
            <div className="card text-center">
              <div className="card-body">
                <h5 className="card-title">Business partner</h5>
                <div className="rounded-circle bg-warning text-white mb-3" style={{ width: '50px', height: '50px', lineHeight: '50px', margin: '0 auto' }}>4</div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 mb-3">
            <div className="card text-center">
              <div className="card-body">
                <h5 className="card-title">Client </h5>
                <div className="rounded-circle bg-danger text-white mb-3" style={{ width: '50px', height: '50px', lineHeight: '50px', margin: '0 auto' }}>5</div>
               
                
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Polygone des effectifs (Prestation en retard/monitoring)</h5>
                <Line data={dataPolygon} />
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Histogramme (Prestation en cours/monitoring)</h5>
                <Bar data={dataHistogram} />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      </div>
    </div>
  );
}

export default DashboardAdmin;