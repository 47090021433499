import React from "react";

export const ProgressBar = ({ step }) => {
  if (!step) {
    step = 0;
  }
  const widthStyle = { width: `${step}%`, transition: 'width 0.5s ease-in-out' };

  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <div className="mb-6 sm:mb-0 sm:pr-4">
      <div className="flex flex-row items-center justify-between w-full p-1 pr-6 mb-2">
        <div className="flex flex-row items-center gap-1">
          <img 
            src={require('../../assets/paiement/Arrow left.svg').default} 
            alt="retour" 
            className="w-[24px]" 
            onClick={handleBackClick} 
          />
          <span className="text-sm font-medium font-Poppins sm:text-md">
            Paiement
          </span>
        </div>
        <img 
          src={require('../../assets/paiement/logo.svg').default} 
          alt="logo" 
          className="w-[100px] sm:w-[150px]" 
        />
      </div>
      <div className=" w-full h-1 relative bg-[#eeeeee] rounded-sm mb-2">
        <div
          className="h-1 left-0 top-0 absolute rounded-sm bg-[#0056b3]"
          style={widthStyle}
        />
      </div>
    </div>
  );
};
