import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import "../../styles/LoginPage.css";
import { login as reduxLogin } from "../../redux/slices/authentificationSlice";
import { useAuth } from "../AuthContext";
import logo from "../../assets/logoWhite.png";
import { BsLinkedin } from "react-icons/bs";
import "../../index.css";
import "../../styles/Buttons.css";

const LoginPage = ({
  apiGatewayUrl,
  linkedInClientId,
  linkedInRedirectUri,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { login: contextLogin } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${linkedInClientId}&redirect_uri=${encodeURIComponent(
    linkedInRedirectUri
  )}&scope=openid%20profile%20email`;
  const handleLogin = async () => {
    setIsSubmitting(true); // Commencer le chargement
    const url = `${apiGatewayUrl}/universal-login/`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username: email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("data : ", data);

        // Stocker le token et le bp_id dans le local storage
        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem("bp_id", data.bp_id);

        contextLogin({
          user: data,
          userType: data.user_type,
          email_validation: data.email_validation,
        });
        dispatch(
          reduxLogin({
            user: data,
            userType: data.user_type,
            email_validation: data.email_validation,
          })
        );

        switch (data.user_type) {
          case "provider":
            if (data.email_validation === "OK") {
              navigate("/provider-welcome");
            } else if (data.email_validation === "KO") {
              navigate("/provider-validation-email", {
                state: { email: data.user_email },
              });
            }
            break;
          case "worker":
            if (data.email_validation === "OK") {
              navigate("/worker-welcome");
            } else if (data.email_validation === "KO") {
              navigate("/worker-validation-email", {
                state: { email: data.user_email },
              });
            }
            break;
          case "client":
            console.log("Email validation status:", data.email_validation);
            if (data.email_validation === "OK") {
              navigate("/client-Dashboard");
            } else if (data.email_validation === "KO") {
              navigate("/validation-email", {
                state: { email: data.user_email },
              });
            }
            break;
          case "freelancer":
            console.log("Email validation status:", data.email_validation);
            if (data.email_validation === "OK") {
              // navigate('/freelancer-Dashboard');
              navigate("/profile");
            } else if (data.email_validation === "KO") {
              navigate("/freelancer-validation-email", {
                state: { email: data.user_email },
              });
            }
            break;
          case "BusinessPartner":
            console.log("Email validation status:", data.email_validation);
            navigate("/dashboard-bp");
            break;
          default:
            console.log("Unhandled user type:", data.user_type);
            break;
        }
      } else {
        const errorData = await response.json();
        setError(
          errorData.message ||
            "Identifiants incorrects ou problème avec le serveur."
        );
      }
    } catch (error) {
      console.log("Fetch error:", error); // Added console.log for better error tracking
      setError("Erreur technique. Veuillez réessayer plus tard.");
    } finally {
      setIsSubmitting(false); // Arrêter le chargement
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin();
  };

  return (
    <body className="loginbackgroundImage ">
      <div className="mb-12">
        <div className="pl-6 pt-7">
          <Link className="loginlogo " to="/">
            <img className="max-2xl:w-[200px] 2xl:w-[280px]" src={logo} />
          </Link>
        </div>
        <div className="flex flex-col items-center justify-center ">
          <div className="Logincontainer ">
            <div className="Loginform-container sm:mx-[100px] lg:mx-[200px] xl:mx-[300px] 2xl:mx-[450px]">
              <h2 className="font-bold font-display text-primary-800 text-center sm:text-fluid-xl xl:text-fluid-lg 2xl:text-fluid-xl">
                Connexion
              </h2>
              {error && <div className="alert alert-danger">{error}</div>}
              <form onSubmit={handleSubmit} className="form-custom font-body">
                <div className="form-group mb-1">
                  <input
                    type="email"
                    className="form"
                    placeholder="Adresse email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group mb-1">
                  <input
                    type="password"
                    className="form"
                    placeholder="Mot de passe"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="loginform-group btn-center mb-1">
                  <button
                    type="submit"
                    className="Redbutton"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Accéder à mon espace"
                    )}
                  </button>
                </div>
                <div className="loginseparator">OU</div>
                <a href={linkedInAuthUrl} className="no-underline mb-3">
                  <div className="Bluebutton flex items-center justify-center ">
                    <BsLinkedin size={25} />
                    <span className="ml-2">Connectez-vous avec LinkedIn</span>
                  </div>
                </a>
              </form>
              <div className="flex flex-col items-center ">
                <Link className="text-primary-800" to="#">
                  Mot de passe oublié ?
                </Link>
                <Link className="text-primary-800 " to="/type_de_profile">
                  Créer un compte
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default LoginPage;
