import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import ClientCard from './ClientCard';
import Navbar from './Navbarr';
import { API_GATEWAY_URL } from '../constants';
import { useAuth } from './AuthContext';

const FeedbackForm = () => {
    const { demandeId } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();

    const [missionRating, setMissionRating] = useState(5);
    const [resourceRating, setResourceRating] = useState(5);
    const [platformRating, setPlatformRating] = useState(5);
    const [missionFeedback, setMissionFeedback] = useState('');
    const [resourceFeedback, setResourceFeedback] = useState('');
    const [platformFeedback, setPlatformFeedback] = useState('');
    const [comment, setComment] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate the comment if any rating is less than 3
        if ((missionRating < 3 || resourceRating < 3 || platformRating < 3) && !comment) {
            Swal.fire('Erreur', 'Un commentaire est requis pour une note inférieure à 3.', 'error');
            return;
        }

        // Prepare the data to be sent
        const formData = {
            mission: demandeId,
            mission_note: missionRating,
            resource_note: resourceRating,
            platform_note: platformRating,
            commentaire: comment,
            feedback_personne: resourceFeedback,
            feedback_work: missionFeedback,
            feedback_plateforme: platformFeedback,
        };

        try {
            // Log the token and data being sent
            console.log("Submitting feedback with token:", user.access_token);
            console.log("Form Data:", formData);

            // Make the API call to submit the feedback
            const response = await fetch(`${API_GATEWAY_URL}/feedback-client/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${user.access_token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            // Check if the response status indicates an error
            if (!response.ok) {
                const errorData = await response.text();  // Use text() to capture HTML content
                console.error("Response Error Data:", errorData);
                throw new Error(`Failed to submit feedback: ${errorData}`);
            }

            // If successful, update the status of the demande
            const updateResponse = await axios.post(
                `${API_GATEWAY_URL}/update-demande-statut-on-evaluation/${demandeId}/`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${user.access_token}`
                    }
                }
            );

            // Check if the status update was successful
            if (updateResponse.status === 200) {
                Swal.fire('Succès', 'Votre feedback a été soumis et le statut de la demande a été mis à jour avec succès.', 'success');
                navigate('/demandes-en-cours');
            } else {
                throw new Error('Failed to update the demande status');
            }

        } catch (err) {
            // Handle error cases, show error message and set the error state
            console.error("Submission error:", err);
            setError(err.message);
            Swal.fire('Erreur', `Une erreur est survenue: ${err.message}`, 'error');
        }
    };

    return (
        <div className="CDP-page-layout flex flex-col min-h-screen">
            <Navbar />
            <div className="flex flex-1 items-start justify-start p-6 space-x-6">
                {/* ClientCard Container */}
                <div className="w-96">
                    <ClientCard />
                </div>
                {/* Feedback Form Container */}
                <div className="flex-grow" style={{ width: 'calc(150%)', marginLeft: '2rem' }}>
                    <div className="p-6 bg-white shadow-lg rounded-lg">
                        <h1 className="text-2xl font-bold text-gray-800 mb-6">Évaluation de la Demande</h1>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            
                            {/* Évaluation de la mission */}
                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2">Évaluation de la mission (sur 5 étoiles) *</label>
                                <div className="flex items-center space-x-1 mb-4">
                                    {[1, 2, 3, 4, 5].map(star => (
                                        <span
                                            key={star}
                                            className={`text-2xl cursor-pointer transition-colors ${star <= missionRating ? 'text-yellow-500' : 'text-gray-300'}`}
                                            onClick={() => setMissionRating(star)}
                                        >
                                            &#9733;
                                        </span>
                                    ))}
                                </div>
                                <textarea
                                    value={missionFeedback}
                                    onChange={(e) => setMissionFeedback(e.target.value)}
                                    required
                                    className="w-full h-40 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
                                    placeholder="Décrivez votre évaluation de la mission..."
                                />
                            </div>

                            {/* Évaluation de la ressource */}
                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2">Évaluation de la ressource (sur 5 étoiles) *</label>
                                <div className="flex items-center space-x-1 mb-4">
                                    {[1, 2, 3, 4, 5].map(star => (
                                        <span
                                            key={star}
                                            className={`text-2xl cursor-pointer transition-colors ${star <= resourceRating ? 'text-yellow-500' : 'text-gray-300'}`}
                                            onClick={() => setResourceRating(star)}
                                        >
                                            &#9733;
                                        </span>
                                    ))}
                                </div>
                                <textarea
                                    value={resourceFeedback}
                                    onChange={(e) => setResourceFeedback(e.target.value)}
                                    className="w-full h-40 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
                                    placeholder="Évaluation de la ressource..."
                                />
                            </div>

                            {/* Évaluation de la plateforme */}
                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2">Évaluation de la plateforme (sur 5 étoiles) *</label>
                                <div className="flex items-center space-x-1 mb-4">
                                    {[1, 2, 3, 4, 5].map(star => (
                                        <span
                                            key={star}
                                            className={`text-2xl cursor-pointer transition-colors ${star <= platformRating ? 'text-yellow-500' : 'text-gray-300'}`}
                                            onClick={() => setPlatformRating(star)}
                                        >
                                            &#9733;
                                        </span>
                                    ))}
                                </div>
                                <textarea
                                    value={platformFeedback}
                                    onChange={(e) => setPlatformFeedback(e.target.value)}
                                    className="w-full h-40 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
                                    placeholder="Évaluation de la plateforme..."
                                />
                            </div>

                            {/* Comment */}
                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2">Commentaire (obligatoire si note inférieure à 3)</label>
                                <textarea
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    className="w-full h-40 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
                                    placeholder="Votre commentaire..."
                                />
                            </div>

                            <button type="submit" className="w-full py-2 px-4 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-colors">
                                Confirmer
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeedbackForm;
