// src/components/InvoiceSummary.js
import React from "react";
function InvoiceSummary(props) {
  
  console.log("props", props);
 
  return (
    <div className="md:bg-[#2B3A59] md:p-6 w-full rounded-lg h-full md:h-full">
      <h2 className="md:text-xl text-lg font-semibold mb-4 font-Poppins text-[#B64148]">
        Facture
      </h2>
      <div>
        <div className="w-full mb-2 flex-col justify-start items-start gap-0.5 inline-flex">
          <div className="md:text-white text-[#7b7b7b] text-xs md:text-sm font-medium font-Poppins">
            Nom de ressource
          </div>
          <div className="self-stretch md:text-white text-[#1e1e1e] text-xs md:text-sm font-normal font-Poppins">
            {props.resourceInfo.lastName}   {props.resourceInfo.firstName}
          </div>
        </div>
        <div className="flex gap-6 mb-4">
          <div className="flex-col justify-start items-start gap-0.5 inline-flex">
            <div className="self-stretch md:text-white text-[#7b7b7b] text-xs md:text-sm font-medium font-Poppins">
              Date Début
            </div>
            <div className="self-stretch md:text-white text-[#1e1e1e] text-xs md:text-sm font-normal font-Poppins">
              {props.resourceInfo.details.date_debut}
            </div>
          </div>

          <div className="flex-col justify-start items-start gap-0.5 inline-flex">
            <div className="self-stretch md:text-white text-[#7b7b7b] text-xs md:text-sm font-medium font-Poppins">
              Date Fin
            </div>
            <div className="self-stretch md:text-white text-[#1e1e1e] text-xs md:text-sm font-normal font-Poppins">
            {props.resourceInfo.details.date_fin}
            </div>
          </div>
        </div>
        <div className="mb-4">
          <div className="w-full px-6 py-4 text-white bg-[#2B3A59] md:bg-white rounded-lg flex-col justify-start items-start gap-0.5 inline-flex">
            <div className="self-stretch md:text-[#7b7b7b]  text-xs md:text-sm font-medium font-Poppins">
              Prix à payer
            </div>
            <div className="self-stretch md:text-[#1e1e1e]  text-lg md:text-2xl font-semibold font-Poppins">
              {props.amount} €
            </div>
          </div>
        </div>
      </div>
      <div className="hidden text-sm md:block">
        <div className="mb-2">
          <div className="flex items-center mb-1">
            <img
              alt="secure"
              src={require("../../assets/paiement/Check.svg").default}
              className="w-7 h-7"
            ></img>
            <div className="md:text-white text-[#1e1e1e] text-lg font-medium font-Poppins">
              Paiement
            </div>
          </div>
          <div className="md:text-white text-[#7b7b7b] text-sm font-normal font-Poppins">
            On vous assure des transactions faciles et sécurisés en utilisant
            Stripe pour le traitement et mécanismes de sécurité robustes
          </div>
        </div>
        <div>
          <div className="flex items-center mb-1">
            <img
              alt="secure"
              src={require("../../assets/paiement/Check.svg").default}
              className="w-7 h-7"
            ></img>
            <span className="md:text-white text-[#1e1e1e] text-lg font-medium font-Poppins">
              Factures
            </span>
          </div>
          <div className="md:text-white text-[#7b7b7b] text-sm font-normal font-Poppins">
            Vos factures seront disponibles à consulter, télécharger ou bien
            envoyé par email tout en respectant le format légale d’une facture
            européenne
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceSummary;
