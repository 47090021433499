import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProgressBar } from "react-bootstrap";
import "../../../index.css";
import "../../../styles/Buttons.css";
import {
  setProviderInscriptionFormData,
  providerNextStep,
  providerPrevStep,
} from "../../../redux/slices/providerInscriptionFormSlice";
import { CountryDropdown } from "react-country-region-selector";
import "../../../styles/ProviderMultiStepForm.css";
import { API_GATEWAY_URL } from "../../../constants";
import Select from "react-select";
import { SlArrowRight } from "react-icons/sl";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import group from "../../../icons/group.png";
import teamwork from "../../../icons/teamwork.png";
import { isValidPhoneNumber } from "react-phone-number-input";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/logoWhite.png";

const ProviderMultiStepForm = () => {
  const dispatch = useDispatch();
  const [showResourceForm, setShowResourceForm] = useState(false);
  const [showExpertiseForm, setShowExpertiseForm] = useState(false);
  const [selectedExpertises, setSelectedExpertises] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [providerExpertises, setProviderExpertises] = useState([]);
  const [providerExpertiseLevels, setProviderExpertiseLevels] = useState({});
  const { providerStep, providerInscriptionFormData } = useSelector(
    (state) => state.providerInscriptionForm
  );
  const totalProviderSteps = 4;
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedSchema, setSelectedSchema] = useState(null);
  const [activeForm, setActiveForm] = useState(null);
  const [activeInvitation, setActiveInvitation] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [activeContact, setActiveContact] = useState(null);
  const [activeContactIndex, setActiveContactIndex] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [directValidation, setDirectValidation] = useState(false);
  const [deferredValidation, setDeferredValidation] = useState(false);
  const navigate = useNavigate();

  const IT_POSITIONS = [
    { value: "manageur", label: "Manageur " },
    { value: "developpeur", label: "Développeur" },
    { value: "chef_de_projet", label: "Chef de projet" },
    { value: "architecte_logiciel", label: "Architecte logiciel" },
    { value: "analyste", label: "Analyste" },
    { value: "ingenieur_reseau", label: "Ingénieur réseau" },
    { value: "administrateur_systeme", label: "Administrateur système" },
    { value: "support_technique", label: "Support technique" },
    // Ajoutez d'autres postes si nécessaire
  ];
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [providerInfo, setProviderInfo] = useState({
    name: "",
    companyName: "",
    contactName: "",
    email: "",
    provider_status: "",
    size: "",
    website: "",
    address: "",
    postalCode: "",
    city: "",
    commune: "",
    country: "Algérie", // Set default country as Algeria
    phone: "",
    manager_name: "",
    managerFirstName: "",
    manager_phone: "",
    manager_email: "",
  });
  const validatePhoneNumber = (value, fieldName) => {
    let error = "";
    if (value && !isValidPhoneNumber(value)) {
      error = "Numéro de téléphone invalide.";
    }
    setFormErrors((prevErrors) => ({ ...prevErrors, [fieldName]: error }));
  };
  const handleIncludeInEmailNotifications = (index, isChecked) => {
    const updatedContacts = contacts.map((contact, i) => {
      if (i === index) {
        return { ...contact, include_in_email_notifications: isChecked };
      }
      return contact;
    });
    setContacts(updatedContacts);
  };
  const expertiseDataToSend = providerExpertises.map((expertise) => {
    const levelLabel = providerExpertiseLevels[expertise.value] || "Junior";
    const levelCode =
      levelLabel === "Junior"
        ? "JR"
        : levelLabel === "Confirmé"
        ? "CF"
        : levelLabel === "Senior"
        ? "SR"
        : "JR";
    return {
      id: expertise.value,
      level: levelCode,
    };
  });
  const [contacts, setContacts] = useState([]);

  const validateStep1 = () => {
    const errors = {};
    if (!providerInfo.name.trim()) {
      errors.name = "Veuillez entrer le nom de l’entreprise.";
    }
    if (!providerInfo.provider_status) {
      errors.provider_status = "Veuillez sélectionner le statut juridique.";
    }
    if (!providerInfo.size) {
      errors.size = "Veuillez sélectionner la taille de l’entreprise.";
    }
    if (!providerInfo.address.trim()) {
      errors.address = "Veuillez entrer l’adresse postale.";
    }
    if (!providerInfo.city.trim()) {
      errors.city = "La ville est obligatoire.";
    }
    if (!providerInfo.commune.trim()) {
      errors.commune = "La commune est obligatoire.";
    }
    // Since country is always set and read-only, we can skip validating it
    // if (!providerInfo.country) {
    //   errors.country = 'Veuillez sélectionner le pays.';
    // }
    if (providerInfo.phone && !isValidPhoneNumber(providerInfo.phone)) {
      errors.phone = "Numéro de téléphone invalide.";
    }
    const ManagerNameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/;

    if (!providerInfo.manager_name.trim()) {
      errors.manager_name = "Veuillez entrer le nom du gérant.";
    } else if (!ManagerNameRegex.test(providerInfo.manager_name.trim())) {
      errors.manager_name =
        "Le nom ne doit contenir que des caractères alphabétiques.";
    }

    const ManagerFirstNameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/;

    if (!providerInfo.managerFirstName.trim()) {
      errors.managerFirstName = "Veuillez entrer le prénom du gérant.";
    } else if (
      !ManagerFirstNameRegex.test(providerInfo.managerFirstName.trim())
    ) {
      errors.managerFirstName =
        "Le prénom ne doit contenir que des caractères alphabétiques.";
    }

    if (!isValidPhoneNumber(providerInfo.manager_phone)) {
      errors.manager_phone = "Numéro de téléphone du gérant invalide.";
    }
    if (
      !providerInfo.manager_email.trim() ||
      !/\S+@\S+\.\S+/.test(providerInfo.manager_email)
    ) {
      errors.manager_email =
        "Veuillez entrer une adresse email valide pour le gérant.";
    }

    return errors;
  };
  const validateContact = (contact) => {
    const errors = {};
    const firstNameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/;
    const lastNameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/;
    if (!contact.firstName.trim()) {
      errors.firstName = "Le prénom est obligatoire.";
    } else if (!firstNameRegex.test(contact.firstName.trim())) {
      errors.firstName =
        "Le prénom ne doit contenir que des caractères alphabétiques.";
    }

    if (!contact.lastName.trim()) {
      errors.lastName = "Le nom est obligatoire.";
    } else if (!lastNameRegex.test(contact.lastName.trim())) {
      errors.lastName =
        "Le nom ne doit contenir que des caractères alphabétiques.";
    }

    if (!contact.email.trim() || !emailRegex.test(contact.email)) {
      errors.email = "Adresse email invalide.";
    }
    if (!contact.phone.trim() || !isValidPhoneNumber(contact.phone)) {
      errors.phone = "Numéro de téléphone invalide.";
    }
    if (!contact.position.trim()) {
      errors.position = "Le poste est obligatoire.";
    }
    return errors;
  };
  const steps = [
    "Inscription de l'entreprise",
    "Ajouter un nouveau contact",
    "Mes Expertises",
    "Création de compte",
  ];
  useEffect(() => {
    const fetchExpertises = async () => {
      const response = await fetch(`${API_GATEWAY_URL}/expertises/`);
      if (response.ok) {
        const data = await response.json();
        setSelectedExpertises(
          data.map((expertise) => ({
            value: expertise.id,
            label: expertise.name,
          }))
        );
      }
    };
    fetchExpertises();
  }, []);
  useEffect(() => {
    if (
      providerInfo.managerFirstName ||
      providerInfo.manager_name ||
      providerInfo.manager_email ||
      providerInfo.manager_phone
    ) {
      setContacts([
        {
          firstName: providerInfo.managerFirstName || "",
          lastName: providerInfo.manager_name || "",
          email: providerInfo.manager_email || "",
          phone: providerInfo.manager_phone || "",
          position: "Gérant",
        },
      ]);
    }
  }, [
    providerInfo.managerFirstName,
    providerInfo.manager_name,
    providerInfo.manager_email,
    providerInfo.manager_phone,
  ]);
  const handleSchemaSelection = (schemaType) => {
    // Check if there are any data in the current schema
    const hasData =
      (schemaType === "resource" && providerExpertises.length > 0) ||
      (schemaType === "expertise" && invitations.length > 0);

    if (hasData) {
      // Prompt the user to confirm schema switch if there's existing data
      if (
        window.confirm(
          "Êtes-vous sûr de changer de schéma ? Cela supprimera toutes vos modifications actuelles."
        )
      ) {
        switchSchema(schemaType);
      }
    } else {
      // If no data to lose, just switch schemas directly
      switchSchema(schemaType);
    }
  };

  const switchSchema = (schemaType) => {
    setSelectedSchema(schemaType);
    if (schemaType === "resource") {
      setShowResourceForm(true);
      setShowExpertiseForm(false);
      // Clear all data related to the 'expertise' schema
      setProviderExpertises([]);
      setProviderExpertiseLevels({});
    } else {
      setShowResourceForm(false);
      setShowExpertiseForm(true);
      // Clear all data related to the 'resource' schema
      setInvitations([]);
    }
  };
  const handleAddInvitation = () => {
    const newInvitationIndex = invitations.length;
    setInvitations([
      ...invitations,
      {
        firstName: "",
        lastName: "",
        email: "",
        selectedExpertises: [],
        expertiseLevels: {},
      },
    ]);
    setActiveInvitation(newInvitationIndex); // Set the new invitation as active
  };

  const handleProviderExpertiseChange = (selectedOptions) => {
    setProviderExpertises(selectedOptions);
  };

  const handleProviderExpertiseLevelChange = (expertiseId, level) => {
    setProviderExpertiseLevels((prevLevels) => ({
      ...prevLevels,
      [expertiseId]: level,
    }));
  };
  const handleRemoveInvitation = (index) => {
    const newInvitations = invitations.filter((_, i) => i !== index);
    setInvitations(newInvitations);
    if (activeInvitation === index) {
      setActiveInvitation(null);
    } else if (activeInvitation > index) {
      setActiveInvitation(activeInvitation - 1);
    }
  };
  const handleInvitationChange = (index, event, field) => {
    const updatedInvitations = invitations.map((invitation, i) => {
      if (i === index) {
        if (Array.isArray(event)) {
          return { ...invitation, [field]: event };
        } else {
          return { ...invitation, [field]: event.target.value };
        }
      }
      return invitation;
    });
    setInvitations(updatedInvitations);
  };

  const handleExpertiseLevelChange = (invitationIndex, expertiseId, level) => {
    setInvitations(
      invitations.map((invitation, index) => {
        if (index === invitationIndex) {
          return {
            ...invitation,
            expertiseLevels: {
              ...invitation.expertiseLevels,
              [expertiseId]: level,
            },
          };
        }
        return invitation;
      })
    );
  };
  const validatePassword = (password) => {
    if (password.length < 8) {
      return "Le mot de passe doit contenir au moins 8 caractères.";
    }
    if (!/[A-Z]/.test(password)) {
      return "Le mot de passe doit contenir au moins une majuscule.";
    }
    if (!/[0-9]/.test(password)) {
      return "Le mot de passe doit contenir au moins un chiffre.";
    }
    if (!/[^A-Za-z0-9]/.test(password)) {
      return "Le mot de passe doit contenir au moins un caractère spécial (ex: !@#$).";
    }
    return null;
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(`Input changed: ${name} = ${value}`); // Debugging input changes

    if (name === "password") {
      const errors = validatePassword(value);
      if (errors) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          password: errors,
        }));
        setIsPasswordValid(false); // Le mot de passe est invalide
      } else {
        const newErrors = { ...formErrors };
        delete newErrors.password;
        setFormErrors(newErrors);
        setIsPasswordValid(true); // Le mot de passe est valide
      }
    }

    setProviderInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (
      [
        "manager_name",
        "managerFirstName",
        "manager_phone",
        "manager_email",
      ].includes(name)
    ) {
      const contactField = {
        manager_name: "lastName",
        managerFirstName: "firstName",
        manager_phone: "phone",
        manager_email: "email",
      }[name];

      setContacts((prevContacts) => {
        const newContacts = [...prevContacts];
        if (newContacts[0]) {
          newContacts[0] = { ...newContacts[0], [contactField]: value };
        } else {
          newContacts[0] = { [contactField]: value, position: "Gérant" };
        }
        console.log(`Updated contacts[0]:`, newContacts[0]); // Debugging contact update
        return newContacts;
      });
    }
  };
  const handleValidationChange = (type) => {
    if (type === "direct") {
      setDirectValidation(!directValidation);
      setDeferredValidation(false);
    } else {
      setDeferredValidation(!deferredValidation);
      setDirectValidation(false);
    }
  };
  const handleNext = async (e) => {
    e.preventDefault();
    let errors = {};

    // Validation de l'étape actuelle
    if (providerStep === 1) {
      errors = validateStep1();
    } else if (providerStep === 2) {
      // Validation de chaque contact
      contacts.forEach((contact, index) => {
        const contactErrors = validateContact(contact);
        if (Object.keys(contactErrors).length > 0) {
          errors[`contact-${index}`] = contactErrors;
        }
      });
    } else if (providerStep === 3) {
      // Validation de l'étape 3
      if (!selectedSchema) {
        errors.schema = "Veuillez sélectionner un schéma.";
      } else if (selectedSchema === "resource" && invitations.length === 0) {
        errors.invitations = "Veuillez ajouter au moins un employé.";
      } else if (
        selectedSchema === "expertise" &&
        providerExpertises.length === 0
      ) {
        errors.expertises = "Veuillez sélectionner au moins une expertise.";
      }
    }

    // Vérification s'il y a des erreurs de validation
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Passer à l'étape suivante si aucune erreur de validation n'est trouvée
    dispatch(providerNextStep());
  };

  const handlePrev = () => {
    if (providerStep > 1) {
      dispatch(providerPrevStep());
    }
  };

  const handleResourceFormClick = () => {
    setShowResourceForm(true);
    setShowExpertiseForm(false);
  };

  const handleExpertiseFormClick = () => {
    setShowResourceForm(false);
    setShowExpertiseForm(true);
  };

  const emailRegex = /\S+@\S+\.\S+/;

  const handleContactChange = (index, e) => {
    const { name, value } = e.target ? e.target : { name: e, value: e.target };
    const updatedContacts = contacts.map((contact, i) => {
      if (i === index) {
        return { ...contact, [name]: value };
      }
      return contact;
    });
    setContacts(updatedContacts);

    // Validate phone number for contacts as needed
    if (name === "phone") {
      validatePhoneNumber(value, `contact-${index}-phone`);
    }
  };
  const handleContactClick = (index) => {
    setActiveContactIndex(activeContactIndex === index ? null : index);
  };

  const loadContacts = (data) => {
    const loadedContacts = data.map((contact) => ({
      ...contact,
      include_in_email_notifications:
        contact.include_in_email_notifications || false, // Ensure default is false if undefined
    }));
    setContacts(loadedContacts);
  };

  // When adding a new contact
  const handleAddContact = () => {
    const newContact = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      position: "",
      include_in_email_notifications: false, // Default to false for new contacts
    };
    setContacts([...contacts, newContact]);
    setActiveContactIndex(contacts.length); // Focus on the new contact
  };

  const handleToggleActiveContact = (index) => {
    // Agrandit ou réduit le formulaire de contact cliqué
    setActiveContactIndex(index);
  };
  const handleRemoveContact = (index) => {
    const newContacts = contacts.filter(
      (_, contactIndex) => contactIndex !== index
    );
    setContacts(newContacts);
    if (activeForm >= index) {
      // Ajuster l'index du formulaire actif si nécessaire
      setActiveForm((prevActiveForm) =>
        prevActiveForm > 0 ? prevActiveForm - 1 : null
      );
    }
  };

  const handleCountryChange = (val) => {
    setProviderInfo({ ...providerInfo, country: val });

    // Réinitialiser l'erreur pour le champ 'country'
    setFormErrors({ ...formErrors, country: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isPasswordValid) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Le mot de passe n'est pas valide.",
      });
      return;
    }
    if (providerStep === 4) {
      const formattedContacts = contacts.map((contact) => ({
        first_name: contact.firstName,
        last_name: contact.lastName,
        email: contact.email,
        phone_number: contact.phone,
        position: contact.position,
        include_in_email_notifications: contact.include_in_email_notifications,
      }));

      const fullDataToSend = {
        ...providerInfo,
        contacts: formattedContacts,
        expertise_data: providerExpertises.map((expertise) => {
          const levelLabel =
            providerExpertiseLevels[expertise.value] || "Junior";
          const levelCode =
            levelLabel === "Junior"
              ? "JR"
              : levelLabel === "Confirmé"
              ? "CF"
              : levelLabel === "Senior"
              ? "SR"
              : "JR";
          return {
            id: expertise.value,
            level: levelCode,
          };
        }),
        direct_validation: directValidation,
        deferred_validation: deferredValidation,
        is_final_step: true,
        schema: selectedSchema, // Ajoutez cette ligne pour envoyer le schéma sélectionné
      };

      try {
        const response = await fetch(`${API_GATEWAY_URL}/register-provider/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
          body: JSON.stringify(fullDataToSend),
        });

        if (response.ok) {
          const data = await response.json();
          Swal.fire({
            icon: "success",
            title: "Succès",
            text: "Fournisseur créé avec succès !",
          }).then((result) => {
            if (result.isConfirmed) {
              setTimeout(() => {
                navigate("/provider-validation-email", {
                  state: { email: fullDataToSend.email },
                });
              }, 100);
            }
          });
        } else {
          const errorData = await response.json();
          Swal.fire({
            icon: "error",
            title: "Erreur",
            text:
              errorData.error ||
              "Une erreur est survenue lors de la soumission.",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text:
            error.message || "Une erreur est survenue lors de la soumission.",
        });
      }
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (providerStep < totalProviderSteps) {
        handleNext(e);
      } 
      if (providerStep == totalProviderSteps) {
        document.getElementById("submitButton").click();
      }
    }
  };

  return (
    <div className="flex flex-col justify-center items-center ">
      <Link className="loginlogo ml-[70px] mb-6" to="/">
        <img className="max-2xl:w-[200px] 2xl:w-[280px]" src={logo} />
      </Link>
      <div className="MediumContainer font-body text-primary-800 ">
        <div className="row provider-step-row w-100 p-0 m-0">
          <div className="provider-progress-container my-4">
            {steps.map((step, index) => (
              <div
                key={index}
                className={`provider-progress-step ${
                  providerStep > index ? "provider-step-success" : ""
                } ${providerStep === index + 1 ? "provider-step-current" : ""}`}
              >
                <div className="provider-step-indicator">{index + 1}</div>
                <div className="provider-step-label">{step}</div>
                {index < steps.length - 1 && (
                  <div className="provider-step-line"></div>
                )}
              </div>
            ))}
          </div>
        </div>
        <ProgressBar
          className="w-100 my-2 provider-custom-progress-bar"
          now={(providerStep / totalProviderSteps) * 100}
          variant="warning"
        />

        {successMessage && (
          <div className="alert alert-success">{successMessage}</div>
        )}
        {errorMessage && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}
        <form
          onSubmit={handleSubmit}
          onKeyDown={handleKeyDown}
          className="font-body text-primary-800 max-2xl:text-fluid-ms 2xl:text-fluid-sm "
        >
          {providerStep === 1 && (
            <>
              <h4 className=" text-primary-800 font-bold">
                Inscription de l'entreprise
              </h4>
              <div className="provider-form-group">
                <label htmlFor="companyName" className="font-bold">
                  Nom de l’entreprise:
                </label>
                <input
                  type="text"
                  className="form"
                  id="companyName"
                  name="name"
                  value={providerInfo.name}
                  onChange={handleInputChange}
                />
                {formErrors.name && (
                  <p style={{ color: "red" }}>{formErrors.name}</p>
                )}
              </div>
              <div className="provider-form-group">
                <label htmlFor="providerStatus" className="font-bold">
                  Statut juridique:
                </label>
                <select
                  className="form"
                  id="providerStatus"
                  name="provider_status"
                  value={providerInfo.provider_status}
                  onChange={handleInputChange}
                >
                  <option value="">Sélectionnez le statut</option>
                  <option value="SARL">SARL</option>
                  <option value="EURL">EURL</option>
                  <option value="SPA">SPA</option>
                  <option value="SNC">SNC</option>
                  <option value="Personne Physique">Personne Physique</option>
                </select>
                {formErrors.provider_status && (
                  <p style={{ color: "red" }}>{formErrors.provider_status}</p>
                )}
              </div>
              <div className="provider-form-group">
                <label htmlFor="companySize" className="font-bold">
                  Effectif de l'entreprise :
                </label>
                <select
                  className="form"
                  id="companySize"
                  name="size"
                  value={providerInfo.size}
                  onChange={handleInputChange}
                >
                  <option value="">
                    Sélectionner l'effectif de l'entreprise
                  </option>
                  <option value="1-9">1-9</option>
                  <option value="10-49">10-49</option>
                  <option value=">50">plus de 50</option>
                </select>
                {formErrors.size && (
                  <p style={{ color: "red" }}>{formErrors.size}</p>
                )}
              </div>
              <div className="provider-form-group">
                <label htmlFor="website" className="font-bold">
                  Site web:
                </label>
                <input
                  type="url"
                  className="form"
                  id="website"
                  name="website"
                  value={providerInfo.website}
                  onChange={handleInputChange}
                />
              </div>

              <div className="provider-form-group">
                <label htmlFor="address" className="font-bold">
                  Adresse postale:
                </label>
                <input
                  type="text"
                  className="form"
                  id="address"
                  name="address"
                  value={providerInfo.address}
                  onChange={handleInputChange}
                />
                {formErrors.address && (
                  <p style={{ color: "red" }}>{formErrors.address}</p>
                )}
              </div>
              <div className="provider-form-row">
                <div className="provider-form-group col-md-2">
                  <label htmlFor="postalCode" className="font-bold">
                    Code postal:
                  </label>
                  <input
                    type="text"
                    className="form"
                    id="postalCode"
                    name="postalCode"
                    value={providerInfo.postalCode}
                    onChange={handleInputChange}
                  />
                  {formErrors.postalCode && (
                    <p style={{ color: "red" }}>{formErrors.postalCode}</p>
                  )}
                </div>

                <div className="provider-form-group col-md-4">
                  <label htmlFor="commune" className="font-bold">
                    Commune:
                  </label>
                  <input
                    type="text"
                    className="form"
                    id="commune"
                    name="commune"
                    value={providerInfo.commune}
                    onChange={handleInputChange}
                    required
                  />
                  {formErrors.commune && (
                    <p style={{ color: "red" }}>{formErrors.commune}</p>
                  )}
                </div>

                <div className="provider-form-group col-md-4">
                  <label htmlFor="city" className="font-bold">
                    Ville:
                  </label>
                  <input
                    type="text"
                    className="form"
                    id="city"
                    name="city"
                    value={providerInfo.city}
                    onChange={handleInputChange}
                    required
                  />
                  {formErrors.city && (
                    <p style={{ color: "red" }}>{formErrors.city}</p>
                  )}
                </div>
              </div>
              <div className="provider-form-group">
                <label htmlFor="country" className="font-bold">
                  Pays:
                </label>
                <input
                  type="text"
                  className="form"
                  id="country"
                  name="country"
                  value="Algérie" // Set the value directly to Algeria
                  readOnly // This makes the field unmodifiable
                />
              </div>
              <div className="provider-form-group">
                <label htmlFor="phone" className="font-bold">
                  Numéro de téléphone de l'entreprise:
                </label>
                <PhoneInput
                  international
                  onlyCountries={["DZ"]}
                  country="DZ"
                  placeholder="Entrez le numéro de téléphone de l'entreprise"
                  value={providerInfo.phone}
                  onChange={(value) => {
                    setProviderInfo({ ...providerInfo, phone: value });
                    validatePhoneNumber(value, "phone");
                  }}
                  className="form"
                />
                {formErrors.phone && (
                  <p style={{ color: "red" }}>{formErrors.phone}</p>
                )}
              </div>
              <h5 className="font-bold max-2xl:text-fluid-sm 2xl:text-fluid-md ">
                Coordonnées du gérant
              </h5>
              <div className="provider-form-group">
                <label htmlFor="managerName" className="font-bold">
                  Nom du gérant:
                </label>
                <input
                  type="text"
                  className="form"
                  id="managerName"
                  name="manager_name"
                  value={providerInfo.manager_name}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.manager_name && (
                  <p style={{ color: "red" }}>{formErrors.manager_name}</p>
                )}
              </div>

              <div className="provider-form-group">
                <label htmlFor="managerFirstName" className="font-bold">
                  Prénom du gérant:
                </label>
                <input
                  type="text"
                  className="form"
                  id="managerFirstName"
                  name="managerFirstName"
                  value={providerInfo.managerFirstName}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.managerFirstName && (
                  <p style={{ color: "red" }}>{formErrors.managerFirstName}</p>
                )}
              </div>

              <div className="provider-form-group">
                <label htmlFor="managerPhone" className="provider-form-label">
                  Numéro de téléphone du gérant:
                </label>
                <PhoneInput
                  international
                  defaultCountry="DZ"
                  placeholder="Entrez le numéro de téléphone du gérant"
                  value={providerInfo.manager_phone}
                  onChange={(value) => {
                    setProviderInfo({ ...providerInfo, manager_phone: value });
                    validatePhoneNumber(value, "manager_phone");
                  }}
                  className="form"
                  containerStyle={{
                    borderRadius: "30px",
                  }}
                  inputStyle={{
                    border: "0px",
                    fontSize: "23px",
                    width: "100%",
                    outline: "none",
                    boxShadow: "none", // to remove any focus ring or shadow
                  }}
                  buttonStyle={{
                    background: "transparent",
                    border: "0px",
                    borderRadius: "30px",
                  }}
                  dropdownStyle={{
                    border: "1px solid #ccc", // Add border to dropdown if needed
                    borderRadius: "8px", // Adjust border radius for dropdown
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Optional shadow
                  }}
                />
                {formErrors.manager_phone && (
                  <p style={{ color: "red" }}>{formErrors.manager_phone}</p>
                )}
              </div>
              <div className="provider-form-group">
                <label htmlFor="managerEmail" className="font-bold">
                  Adresse email du gérant:
                </label>
                <input
                  type="email"
                  className="form"
                  id="managerEmail"
                  name="manager_email"
                  value={providerInfo.manager_email}
                  onChange={handleInputChange}
                />
                {formErrors.manager_email && (
                  <p style={{ color: "red" }}>{formErrors.manager_email}</p>
                )}
              </div>
            </>
          )}
          {providerStep === 2 && (
            <>
              <h className="font-bold text-center max-2xl:text-fluid-sm 2xl:text-fluid-md ">
                Bonjour {providerInfo.managerFirstName}{" "}
                {providerInfo.manager_name}, voulez-vous ajouter d'autres
                représentants que nous pourrons contacter ?
              </h>
              {contacts.map((contact, index) => (
                <div
                  key={index}
                  className={`provider-contact-form my-2 ${
                    index === activeContactIndex
                      ? ""
                      : "provider-contact-form-reduced"
                  }`}
                  onClick={() => handleToggleActiveContact(index)}
                >
                  <h className="font-bold ">
                    Contact {index + 1} {index === 0 ? "(Gérant)" : ""}
                  </h>
                  <div className="provider-form-group">
                    <label className="font-bold">Prénom :</label>
                    <input
                      type="text"
                      className="form"
                      readOnly={index === 0}
                      value={contact.firstName}
                      onChange={(e) => handleContactChange(index, e)}
                      name="firstName"
                    />
                  </div>
                  <div className="provider-form-group">
                    <label className="font-bold">Nom :</label>
                    <input
                      type="text"
                      className="form"
                      readOnly={index === 0}
                      value={contact.lastName}
                      onChange={(e) => handleContactChange(index, e)}
                      name="lastName"
                    />
                  </div>
                  <div className="provider-form-group">
                    <label className="font-bold">Email :</label>
                    <input
                      type="email"
                      className="form"
                      readOnly={index === 0}
                      value={contact.email}
                      onChange={(e) => handleContactChange(index, e)}
                      name="email"
                    />
                  </div>
                  <div className="provider-form-group">
                    <label className="font-bold">Téléphone :</label>
                    <input
                      type="text"
                      className="form"
                      readOnly={index === 0}
                      value={contact.phone}
                      onChange={(e) => handleContactChange(index, e)}
                      name="phone"
                    />
                  </div>
                  {index > 0 && (
                    <div className="provider-form-group">
                      <label
                        htmlFor={`position-${index}`}
                        className="provider-form-label"
                      >
                        Poste :
                      </label>
                      <select
                        className="form"
                        id={`position-${index}`}
                        name="position"
                        value={contact.position}
                        onChange={(e) => handleContactChange(index, e)}
                      >
                        <option value="">Sélectionnez un poste</option>
                        {IT_POSITIONS.map((position) => (
                          <option key={position.value} value={position.value}>
                            {position.label}
                          </option>
                        ))}
                      </select>
                      {formErrors[`contact-${index}`]?.position && (
                        <p className="provider-form-error">
                          {formErrors[`contact-${index}`].position}
                        </p>
                      )}
                    </div>
                  )}
                  <div className="provider-form-group">
                    <label className="font-bold">
                      Inclure dans les notifications par e-mail :
                    </label>
                    <input
                      type="checkbox"
                      checked={contact.include_in_email_notifications}
                      onChange={(e) =>
                        handleIncludeInEmailNotifications(
                          index,
                          e.target.checked
                        )
                      }
                      className="transform scale-150"
                    />
                  </div>

                  {index > 0 && (
                    <button
                      className="btn-red"
                      onClick={(e) => {
                        e.stopPropagation(); // Stop click event from propagating to parent elements
                        handleRemoveContact(index);
                      }}
                      style={{ marginTop: "10px" }}
                    >
                      Retirer ce contact
                    </button>
                  )}
                </div>
              ))}
              <button className="btn-red my-3" onClick={handleAddContact}>
                Ajouter un contact
              </button>
            </>
          )}
          {providerStep === 3 && (
            <>
              <div className="provider-schema-selection-buttons">
                <div
                  onClick={() => handleSchemaSelection("resource")}
                  className={`provider-schema-button ${
                    selectedSchema === "resource"
                      ? "provider-schema-button-active"
                      : "provider-schema-button-inactive"
                  }`}
                >
                  <img src={group} alt="Ressource" />
                  <span>Proposez directement vos employés qualifiés</span>
                </div>
                <div
                  onClick={() => handleSchemaSelection("expertise")}
                  className={`provider-schema-button ${
                    selectedSchema === "expertise"
                      ? "provider-schema-button-active"
                      : "provider-schema-button-inactive"
                  }`}
                >
                  <img src={teamwork} alt="Expertise" />
                  <span className="">Offrez vos compétences spécialisées.</span>
                </div>
              </div>
              {formErrors.schema && (
                <p className="provider-form-error">{formErrors.schema}</p>
              )}

              <div className="provider-resource-expertise-forms">
                {showResourceForm && (
                  <>
                    <div className="provider-add-invitation-container"></div>
                    {invitations.map((invitation, index) => (
                      <div key={index} className="provider-invitation-item">
                        <button
                          onClick={() =>
                            setActiveInvitation(
                              activeInvitation === index ? null : index
                            )
                          }
                          className={`provider-accordion-button ${
                            activeInvitation === index
                              ? "provider-accordion-button-active"
                              : ""
                          }`}
                        >
                          {invitation.firstName || invitation.lastName
                            ? `${invitation.firstName} ${invitation.lastName}`
                            : `Invitation ${index + 1}`}
                        </button>
                        {activeInvitation === index && (
                          <div className="provider-invitation-form">
                            <div className="provider-form-group">
                              <label htmlFor={`invitation-firstName-${index}`}>
                                Prénom :
                              </label>
                              <input
                                type="text"
                                className="form"
                                id={`invitation-firstName-${index}`}
                                value={invitation.firstName}
                                onChange={(e) =>
                                  handleInvitationChange(index, e, "firstName")
                                }
                                placeholder="Prénom"
                              />
                            </div>
                            <div className="provider-form-group">
                              <label htmlFor={`invitation-lastName-${index}`}>
                                Nom :
                              </label>
                              <input
                                type="text"
                                className="form"
                                id={`invitation-lastName-${index}`}
                                value={invitation.lastName}
                                onChange={(e) =>
                                  handleInvitationChange(index, e, "lastName")
                                }
                                placeholder="Nom"
                              />
                            </div>
                            <div className="provider-form-group">
                              <label htmlFor={`invitation-email-${index}`}>
                                Email :
                              </label>
                              <input
                                type="email"
                                className="form"
                                id={`invitation-email-${index}`}
                                value={invitation.email}
                                onChange={(e) =>
                                  handleInvitationChange(index, e, "email")
                                }
                                placeholder="Email"
                              />
                            </div>
                            <div className="provider-form-group">
                              <label>Expertises :</label>
                              <Select
                                isMulti
                                options={selectedExpertises}
                                className="form"
                                classNamePrefix="provider-select"
                                value={invitation.selectedExpertises}
                                onChange={(selectedOptions) =>
                                  handleInvitationChange(
                                    index,
                                    selectedOptions,
                                    "selectedExpertises"
                                  )
                                }
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    border: "none", // Remove the border
                                    boxShadow: "none", // Remove the default box shadow
                                  }),
                                }}
                              />
                            </div>
                            {invitation.selectedExpertises.map(
                              (expertise, expertiseIndex) => (
                                <div
                                  className="provider-expertise-level-select"
                                  key={`expertise-${expertiseIndex}`}
                                >
                                  <label
                                    htmlFor={`expertise-level-${expertise.value}-${index}`}
                                  >
                                    {expertise.label} Niveau :
                                  </label>
                                  <select
                                    className="provider-form-control"
                                    id={`expertise-level-${expertise.value}-${index}`}
                                    value={
                                      invitation.expertiseLevels[
                                        expertise.value
                                      ] || "Junior"
                                    }
                                    onChange={(e) =>
                                      handleExpertiseLevelChange(
                                        index,
                                        expertise.value,
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="Junior">Junior</option>
                                    <option value="Confirmé">Confirmé</option>
                                    <option value="Senior">Senior</option>
                                  </select>
                                </div>
                              )
                            )}
                            <button
                              className="btn-red"
                              onClick={() => handleRemoveInvitation(index)}
                            >
                              Retirer cette invitation
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                    {formErrors.invitations && (
                      <p className="provider-form-error">
                        {formErrors.invitations}
                      </p>
                    )}
                    <button onClick={handleAddInvitation} className="btn-blue">
                      Ajouter un employé
                    </button>
                  </>
                )}
                {showExpertiseForm && (
                  <>
                    <h className="font-bold max-2xl:text-fluid-sm 2xl:text-fluid-md ">
                      Schéma des compétences
                    </h>
                    <Select
                      isMulti
                      options={selectedExpertises}
                      className="form"
                      classNamePrefix="provider-select"
                      value={providerExpertises}
                      onChange={handleProviderExpertiseChange}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: "none", // Remove the border
                          boxShadow: "none", // Remove the default box shadow
                        }),
                      }}
                    />
                    {providerExpertises.map((selectedExpertise) => (
                      <div
                        className="provider-expertise-level-select"
                        key={selectedExpertise.value}
                      >
                        <label>{selectedExpertise.label}:</label>
                        <select
                          className="form"
                          value={
                            providerExpertiseLevels[selectedExpertise.value] ||
                            ""
                          }
                          onChange={(e) =>
                            handleProviderExpertiseLevelChange(
                              selectedExpertise.value,
                              e.target.value
                            )
                          }
                        >
                          <option value="">Sélectionnez le niveau</option>
                          <option value="Junior">Junior</option>
                          <option value="Confirmé">Confirmé</option>
                          <option value="Senior">Senior</option>
                        </select>
                      </div>
                    ))}
                    {formErrors.expertises && (
                      <p className="provider-form-error">
                        {formErrors.expertises}
                      </p>
                    )}
                  </>
                )}
              </div>
            </>
          )}
          {providerStep === 4 && (
            <>
              <h className="font-bold max-2xl:text-fluid-sm 2xl:text-fluid-md ">
                Création de compte
              </h>
              <div className="provider-form-group">
                <label htmlFor="email" className="font-bold">
                  Adresse mail:
                </label>
                <input
                  type="email"
                  className="form"
                  id="email"
                  name="email"
                  value={providerInfo.email || ""}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="font-bold">
                <label htmlFor="password" className="provider-form-label">
                  Mot de passe:
                </label>
                <div className="password-input-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form"
                    id="password"
                    name="password"
                    value={providerInfo.password || ""}
                    onChange={handleInputChange}
                    required
                    placeholder="Entrez votre mot de passe"
                  />

                  <span
                    className="password-toggle mx-4"
                    onClick={togglePasswordVisibility}
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "8px",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                {!formErrors.password && (
                  <span className="password-validation-message">
                    Le mot de passe doit contenir au moins 8 caractères, une
                    majuscule, un chiffre et un caractère spécial.
                  </span>
                )}
                {formErrors.password && (
                  <span className="password-error-message">
                    {formErrors.password}
                  </span>
                )}
              </div>
              <div className="provider-form-group">
                <label className="provider-form-label">Diffusion:</label>
                <div className="provider-form-radio-group">
                  <label className="provider-form-radio-label">
                    <input
                      type="radio"
                      name="validation"
                      checked={directValidation}
                      onChange={() => handleValidationChange("direct")}
                    />
                    Diffusion directe
                  </label>
                  <label className="provider-form-radio-label">
                    <input
                      type="radio"
                      name="validation"
                      checked={deferredValidation}
                      onChange={() => handleValidationChange("deferred")}
                    />
                    Diffusion différée
                  </label>
                </div>
              </div>
            </>
          )}

          <div className="provider-form-navigation">
            {providerStep > 1 && (
              <button className="previousStep mr-2" onClick={handlePrev}>
                Précédent
              </button>
            )}

            {providerStep < totalProviderSteps && (
              <button className="Redbutton" onClick={handleNext}>
                Suivant
              </button>
            )}

            {providerStep === totalProviderSteps && (
              <button className="Redbutton" type="submit" id="submitButton">
                Terminer
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
export default ProviderMultiStepForm;
