import React, { useState, useEffect } from "react";
import { Form, Card } from "react-bootstrap";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
} from "@mui/material";
import Swal from "sweetalert2";
import Navbar from "./Navbarr";
import ChatbotReact from "./ChatbotReact";
import { useAuth } from "./AuthContext";
import { API_GATEWAY_URL } from "../constants";
import ClientCard from "./ClientCard";
import "./AideSupport.css";
import "../index.css";
import "../styles/Buttons.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CATEGORIES = [
  "Problème technique",
  "Demande information",
  "Payement",
  "Autre",
];
const PRIORITIES = ["Urgent", "Normal", "Peu Urgent"];

const AideSupport = () => {
  const navigate = useNavigate(); 
  const [tickets, setTickets] = useState([]);
  const { user } = useAuth();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [newTicket, setNewTicket] = useState({
    title: "",
    description_probleme: "",
    categorie: "",
    priorite: "",
    fichier: null,
  });
  const [currentTicket, setCurrentTicket] = useState(null);

  useEffect(() => {
    fetchTickets();
  }, []);

  const fetchTickets = async () => {
    try {
      const response = await fetch(`${API_GATEWAY_URL}/api/tickets/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      const data = await response.json();
      setTickets(data);
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTicket({
      ...newTicket,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setNewTicket({
      ...newTicket,
      fichier: e.target.files[0],
    });
  };

  const handleCreateTicket = async () => {
    console.log("Creating ticket:", newTicket);
    if (
      !newTicket.title ||
      !newTicket.description_probleme ||
      !newTicket.categorie ||
      !newTicket.priorite
    ) {
      setErrorMessage("Tous les champs sauf le fichier doivent être remplis.");
      return;
    }
    const formData = new FormData();
    formData.append("title", newTicket.title);
    formData.append("description_probleme", newTicket.description_probleme);
    formData.append("categorie", newTicket.categorie);
    formData.append("priorite", newTicket.priorite);
    if (newTicket.fichier) {
      formData.append("fichier", newTicket.fichier);
    }

    try {
      const response = await fetch(`${API_GATEWAY_URL}/api/tickets/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
        body: formData,
      });
      if (response.ok) {
        Swal.fire("Succès", "Ticket créé avec succès", "success");
        setShowCreateModal(false);
        setNewTicket({
          title: "",
          description_probleme: "",
          categorie: "",
          priorite: "",
          fichier: null,
        });
        fetchTickets();
      } else {
        throw new Error("Échec de la création du ticket");
      }
    } catch (error) {
      console.error("Error creating ticket:", error);
      Swal.fire("Erreur", "Échec de la création du ticket", "error");
    }
  };

  const handleCloseSnackbar = () => {
    setErrorMessage("");
  };

  const handleEditTicket = async () => {
    console.log(newTicket);
    if (
      !newTicket.title ||
      !newTicket.description_probleme ||
      !newTicket.categorie ||
      !newTicket.priorite
    ) {
      setErrorMessage("Tous les champs sauf le fichier doivent être remplis.");
      return;
    }
    const formData = new FormData();
    formData.append("title", newTicket.title);
    formData.append("description_probleme", newTicket.description_probleme);
    formData.append("categorie", newTicket.categorie);
    formData.append("priorite", newTicket.priorite);
    formData.append("etat", "en attente");
    if (newTicket.fichier) {
      formData.append("fichier", newTicket.fichier);
    }

    const url = `${API_GATEWAY_URL}/api/tickets/${currentTicket.id}/`;
    console.log(`PUT URL: ${url}`);

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
        body: formData,
      });

      if (response.ok) {
        Swal.fire("Succès", "Ticket mis à jour avec succès", "success");
        setShowEditModal(false);
        setNewTicket({
          title: "",
          description_probleme: "",
          categorie: "",
          priorite: "",
          fichier: null,
        });
        fetchTickets();
      } else {
        const errorText = await response.text();
        throw new Error(`Échec de la mise à jour du ticket : ${errorText}`);
      }
    } catch (error) {
      console.error("Error updating ticket:", error);
      Swal.fire("Erreur", "Échec de la mise à jour du ticket", "error");
    }
  };

  const markTicketAsResolved = async (ticketId) => {
    try {
      const response = await fetch(
        `${API_GATEWAY_URL}/api/tickets/${ticketId}/resolve/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      if (response.ok) {
        Swal.fire("Succès", "Ticket marqué comme résolu", "success");
        fetchTickets();
      } else {
        throw new Error("Échec du marquage du ticket comme résolu");
      }
    } catch (error) {
      console.error("Error marking ticket as resolved:", error);
      Swal.fire("Erreur", "Échec du marquage du ticket comme résolu", "error");
    }
  };

  const getFileUrl = (filePath) => {
    console.log("File path:", `${API_GATEWAY_URL}${filePath}`);
    return `${API_GATEWAY_URL}${filePath}`;
  };

  const handleOpenEditModal = (ticket) => {
    setCurrentTicket(ticket);
    setNewTicket({
      title: ticket.title,
      description_probleme: ticket.description_probleme,
      categorie: ticket.categorie,
      priorite: ticket.priorite,
      fichier: null,
    });
    setShowEditModal(true);
  };

  const handleFileDownload = async (ticket) => {
    try {
      const access_token = localStorage.getItem("access_token");
      const response = await axios.get(getFileUrl(ticket.fichier), {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        responseType: "blob",
      });
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));

      const a = document.createElement("a");
      a.href = fileURL;
      a.download = ticket.fichier;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(fileURL);
    } catch (error) {
      console.error("Erreur lors du téléchargement du fichier:", error);
      alert("Une erreur s'est produite lors du téléchargement du fichier.");
    }
  };

  return (
    <div className="pw-page-layout">
      <Navbar />
      <div className="w-full flex flex-col p-4 ">
        <ClientCard />
        <div className="flex flex-col justify-center items-center">
          <button
            onClick={() => setShowCreateModal(true)}
            className="nextStep mb-3"
          >
            Besoin d'Aide ? Créez un Ticket de support ici !
          </button>
          <h2 className="text-primary-800 font-body font-bold max-2xl:text-fluid-sm 2xl:text-fluid-md">
            Mes Tickets de Support
          </h2>
        </div>

        <div className="d-flex flex-wrap justify-center ">
          {tickets.map((ticket) => (
            <div key={ticket.id} className="mb-4 mr-4 MediumShadowContainer">
              <Card.Body>
                <div className="text-fluid-xs font-body text-primary-800">
                  <Card.Title>
                    <h className="font-bold text-fluid-md">Ticket</h>
                  </Card.Title>
                  <Card.Text>
                    <strong>Titre :</strong> {ticket.title}
                    <br />
                    <strong>Description :</strong> {ticket.description_probleme}
                    <br />
                    <strong>Catégorie :</strong> {ticket.categorie}
                    <br />
                    <strong>Priorité :</strong> {ticket.priorite}
                    <br />
                    <strong>Statut :</strong> {ticket.etat}
                    <br />
                  </Card.Text>
                  {ticket.fichier && (
                    <button
                      onClick={() => {
                        handleFileDownload(ticket);
                      }}
                    >
                      Télécharger le Fichier
                    </button>
                  )}
                  <div className="d-flex mt-2">
                    <button
                      onClick={() => handleOpenEditModal(ticket)}
                      className="mr-2 btn-red"
                    >
                      Modifier
                    </button>
                    <button
                      onClick={() => markTicketAsResolved(ticket.id)}
                      className="btn-blue"
                    >
                      Marquer comme Résolu
                    </button>
                    <button
                      onClick={() => navigate(`/aide-support/${ticket.id}`)}
                      className="ml-2 btn-red"
                    >
                      Détails
                    </button>
                  </div>
                </div>
              </Card.Body>
            </div>
          ))}
        </div>

        {/* Modal pour créer un Ticket */}
        <Modal
          open={showCreateModal}
          onClose={() => setShowCreateModal(false)}
          sx={{ zIndex: 999 }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 700,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              outline: "none",
              border: "2px solid #1976d2",
              zIndex: 1000,
            }}
          >
            <Typography variant="h5" component="h2" gutterBottom>
              Créer un Ticket
            </Typography>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel htmlFor="formTitle" shrink>
                <strong>Titre</strong>
              </InputLabel>
              <TextField
                id="formTitle"
                type="text"
                placeholder="Entrez le titre"
                name="title"
                value={newTicket.title}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ marginTop: 2 }}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel htmlFor="formDescription" shrink>
                <strong>Description</strong>
              </InputLabel>
              <TextField
                id="formDescription"
                multiline
                rows={4}
                placeholder="Entrez la description"
                name="description_probleme"
                value={newTicket.description_probleme}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ marginTop: 2 }}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel htmlFor="formCategory" shrink>
                <strong>Catégorie</strong>
              </InputLabel>
              <Select
                id="formCategory"
                name="categorie"
                value={newTicket.categorie}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ marginTop: 2 }}
              >
                <MenuItem value="">
                  <em>Sélectionner une catégorie</em>
                </MenuItem>
                {CATEGORIES.map((category, index) => (
                  <MenuItem key={index} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel htmlFor="formPriority" shrink>
                <strong>Priorité</strong>
              </InputLabel>
              <Select
                id="formPriority"
                name="priorite"
                value={newTicket.priorite}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ marginTop: 2 }}
              >
                <MenuItem value="">
                  <em>Sélectionner une priorité</em>
                </MenuItem>
                {PRIORITIES.map((priority, index) => (
                  <MenuItem key={index} value={priority}>
                    {priority}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <input
                type="file"
                onChange={handleFileChange}
                className="form"
                style={{ marginTop: 16 }}
              />
            </FormControl>

            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <Button
                sx={{
                  backgroundColor: "#af1b21",
                  color: "#fff",
                  border: "none",
                  padding: "10px 25px",
                  fontWeight: "bold",
                  fontFamily: "Nunito",
                  borderRadius: "40px",
                  cursor: "pointer",
                  display: "inline-block",
                  textAlign: "center",
                  textDecoration: "none",
                  transition: "background-color 0.3s ease",
                  outline: "none",
                  "&:hover": {
                    backgroundColor: "#8f1318",
                    color: "#ffffff",
                  },
                }}
                onClick={() => setShowCreateModal(false)}
              >
                Fermer
              </Button>
              <Button
                sx={{
                  backgroundColor: "#273655",
                  color: "white",
                  border: "none",
                  padding: "10px 25px",
                  fontWeight: "bold",
                  fontFamily: "Nunito",
                  borderRadius: "40px",
                  cursor: "pointer",
                  display: "inline-block",
                  textAlign: "center",
                  textDecoration: "none",
                  transition: "background-color 0.3s ease",
                  outline: "none",
                  "&:hover": {
                    backgroundColor: "#162138",
                    color: "#ffffff",
                  },
                }}
                onClick={handleCreateTicket}
              >
                Créer le Ticket
              </Button>
            </Box>
          </Box>
        </Modal>

        {/* Snackbar pour afficher les messages d'erreur */}
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={errorMessage}
        />

        {/* Modal pour modifier un Ticket */}
        <Modal
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          sx={{ zIndex: 999 }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 700,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              outline: "none",
              border: "2px solid #1976d2",
              zIndex: 1000,
            }}
          >
            <Typography variant="h5" component="h2" gutterBottom>
              Modifier le Ticket
            </Typography>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel htmlFor="formTitle" shrink>
                <strong>Titre</strong>
              </InputLabel>
              <TextField
                id="formTitle"
                placeholder="Entrez le titre"
                name="title"
                value={newTicket.title}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ marginTop: 2 }}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel htmlFor="formDescription" shrink>
                <strong>Description</strong>
              </InputLabel>
              <TextField
                id="formDescription"
                multiline
                rows={4}
                placeholder="Entrez la description"
                name="description_probleme"
                value={newTicket.description_probleme}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ marginTop: 2 }}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel htmlFor="formCategory" shrink>
                <strong>Catégorie</strong>
              </InputLabel>
              <Select
                id="formCategory"
                name="categorie"
                value={newTicket.categorie}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ marginTop: 2 }}
              >
                <MenuItem value="">
                  <em>Sélectionner une catégorie</em>
                </MenuItem>
                {CATEGORIES.map((category, index) => (
                  <MenuItem key={index} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel htmlFor="formPriority" shrink>
                <strong>Priorité</strong>
              </InputLabel>
              <Select
                id="formPriority"
                name="priorite"
                value={newTicket.priorite}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ marginTop: 2 }}
              >
                <MenuItem value="">
                  <em>Sélectionner une priorité</em>
                </MenuItem>
                {PRIORITIES.map((priority, index) => (
                  <MenuItem key={index} value={priority}>
                    {priority}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <input
                type="file"
                onChange={handleFileChange}
                className="form"
                style={{ marginTop: 16 }}
              />
            </FormControl>

            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <Button
                sx={{
                  backgroundColor: "#af1b21",
                  color: "#fff",
                  border: "none",
                  padding: "10px 25px",
                  fontWeight: "bold",
                  fontFamily: "Nunito",
                  borderRadius: "40px",
                  cursor: "pointer",
                  display: "inline-block",
                  textAlign: "center",
                  textDecoration: "none",
                  transition: "background-color 0.3s ease",
                  outline: "none",
                  "&:hover": {
                    backgroundColor: "#8f1318",
                    color: "#ffffff",
                  },
                }}
                onClick={() => setShowEditModal(false)}
              >
                Fermer
              </Button>
              <Button
                sx={{
                  backgroundColor: "#273655",
                  color: "white",
                  border: "none",
                  padding: "10px 25px",
                  fontWeight: "bold",
                  fontFamily: "Nunito",
                  borderRadius: "40px",
                  cursor: "pointer",
                  display: "inline-block",
                  textAlign: "center",
                  textDecoration: "none",
                  transition: "background-color 0.3s ease",
                  outline: "none",
                  "&:hover": {
                    backgroundColor: "#162138",
                    color: "#ffffff",
                  },
                }}
                onClick={handleEditTicket}
              >
                Enregistrer les Modifications
              </Button>
            </Box>
          </Box>
        </Modal>

        <ChatbotReact />
      </div>
    </div>
  );
};

export default AideSupport;
